import React, { useState, useEffect } from 'react'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import api from '../../../utils/api'

const SelectCentroDocumental = ({ value, onChange, initial=[] }) => {
  const [items, setItems] = useState([])
  const getItems = async () => {
    const { data } = await api.fetchGetRequest('api/documentos/centro-documental')
    setItems(data.map(i => ({
      ...i,
      CodigoCentroDocumental: `CD-${i.AbreviaturaDivision}-${i.Macroproceso.AbreviaturaProceso}-${i.Correlativo}`
    })))
  }
  useEffect(() => {
    getItems()
  }, [])
  useEffect(() => {
    if (items.length && initial.length) {
      const ids = initial.map(i => i.Id)
      const selected = items.filter(i => ids.includes(i.IdCentroDocumental))
      onChange(selected)
    }
  }, [items, initial])
  return <Autocomplete
    multiple
    options={items}
    getOptionLabel={o =>
      o.CodigoCentroDocumental ? `${o.CodigoCentroDocumental} - ${o.Ubicacion}` : ""
    }
    value={value}
    onChange={(ev, value) => {
      onChange(value)
    }}
    defaultValue=""
    renderInput={(params) => (
      <TextField
        {...params}
        variant="standard"
        label="Ubicación actual (centro documental)"
      />
    )}
  />
}

export default SelectCentroDocumental
