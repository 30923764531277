import React from "react";
import { makeStyles, Paper, Tooltip } from "@material-ui/core";
import {
  Notifications,
  Assignment,
  People,
  Apps,
  Assessment,
  Add,
  HeadsetMic,
  ContactSupportRounded,
  ContactSupportSharp,
} from "@material-ui/icons";
import CardGestion from "./card-gestion";

const useStyles = makeStyles((theme) => ({
  filaContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  gestionContainer: {
    display: "flex",
    flex: 1,
    padding: "0 0.3rem",
    "&:first-child": {
      paddingLeft: 0,
    },
    "&:last-child": {
      paddingRight: 0,
    },
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "0.3rem",
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  crearIcon: {
    // width: '100%',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 0,
    padding: "0 0.5rem",
    borderRadius: 10,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
    },
  },
}));

const GestionesToolbar = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.filaContainer}>
      <div className={classes.gestionContainer}>
        <CardGestion
          titulo="Creadas por mí"
          cantidad={props.creados}
          color="#03c9d7"
          iconComponent={<Notifications style={{ color: "white" }} />}
          action={() => props.push("/listaavisos/C")}
        />
      </div>
      <div className={classes.gestionContainer}>
        <CardGestion
          titulo="Tareas pendientes"
          cantidad={props.pendientes}
          color="#fb9678"
          iconComponent={<Assignment style={{ color: "white" }} />}
          action={() => props.push("/listatareaspendientes/")}
        />
      </div>
      <div className={classes.gestionContainer}>
        <CardGestion
          titulo="Compartidas conmigo"
          color="#fec90f"
          iconComponent={<People style={{ color: "white" }} />}
          action={() => props.push("/superfiltro/sharedWithMe")}
        />
      </div>
      <div className={classes.gestionContainer}>
        <CardGestion
          titulo="Filtros avanzados"
          color="#00c292"
          iconComponent={<Assessment style={{ color: "white" }} />}
          action={() => props.push("/superfiltro")}
        />
      </div>
      <Tooltip title="Nueva gestión">
        <Paper
          onClick={props.nuevaGestion}
          className={classes.crearIcon}
          style={{ backgroundColor: "#69BD4B" }}
        >
          <Add fontSize={"large"} style={{ color: "white" }} />
        </Paper>
      </Tooltip>
      <Tooltip title="Soporte">
        <Paper
          onClick={props.soporte}
          className={classes.crearIcon}
          style={{ backgroundColor: "#202e3c", marginLeft: "0.3rem" }}
        >
          <HeadsetMic fontSize={"large"} style={{ color: "white" }} />
        </Paper>
        
      </Tooltip>
      {props.showPanelSoporte &&(
        <Tooltip title="Panel de soporte">
        <Paper
          onClick={props.panelSoporte}
          className={classes.crearIcon}
          style={{ backgroundColor: "#202e3c", marginLeft: "0.3rem" }}
        >
          <ContactSupportSharp fontSize={"large"} style={{ color: "white" }} />
        </Paper>
        
      </Tooltip>
      )}
      
    </div>
  );
};

export default GestionesToolbar;
