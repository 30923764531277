import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";

import Layout from "../../layout/containers/Layout";
import AddButton from "../../helpers/components/add-button-float";
import TablaEventos from "../components/tabla-eventos";
import { validaPermisoPaginaActual } from "../../utils/helpers";
import { isSignedIn } from "../../utils/auth";
import API from "../../utils/api";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
});

class ListadoEventos extends Component {
  state = {
    data: [],
  };

  async componentDidMount() {
    //CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
    const llaveAgrupacion = "Menu.Principal.Web";
    const llavePagina = "Menu.Principal.Web.Notificaciones";
    await validaPermisoPaginaActual(llaveAgrupacion, llavePagina);
    //FIN DE CONSULTA DE PERMISOS

    this.obtenerEventos();
  }

  obtenerEventos = () => {
    API.fetchGetRequest("api/calendarioEvento").then((response) => {
      const { data } = response;
      this.setState({ data });
    });
  };

  editarEvento = (id) => {
    this.props.history.push(`/eventos-calendario/${id}/editar`);
  };

  eliminarEvento = async (id) => {
    const session = await isSignedIn();
    const data = { usuario: session["userName"] };
    API.post(`/api/calendarioEvento/eliminar/${id}`, data)
      .then((res) => {
        toast.success("Se ha eliminado este evento");
        this.obtenerEventos();
      })
      .catch((error) => {
        toast.error("Error al eliminar el evento, intentelo de nuevo.");
      });
  };

  render() {
    const { classes } = this.props;
    return (
      <Layout titulo="Eventos de calendario">
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12} sm={12}>
            <TablaEventos
              data={this.state.data}
              editarEvento={this.editarEvento}
              eliminarEvento={this.eliminarEvento}
            />
          </Grid>
        </Grid>

        <AddButton
          onClick={() => this.props.history.push("/eventos-calendario/crear")}
        />
        <ToastContainer />
      </Layout>
    );
  }
}

const ListadoEventosContainer = withStyles(styles)(ListadoEventos);
export default withRouter(ListadoEventosContainer);
