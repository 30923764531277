import React, { Component } from "react";
import DataGrid, {
  Column,
  Pager,
  Paging,
  Sorting,
  ColumnFixing,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Grouping,
  GroupPanel,
  Export,
  Selection,
  Editing,
  Button,
} from "devextreme-react/data-grid";
import CheckBox from "devextreme-react/check-box";
import SelectBox from "devextreme-react/select-box";
// import Button from "devextreme-react/button";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
// import { exportDataGrid as exportDataGridToPdf } from "devextreme/pdf_exporter";
// import ExcelJS from "exceljs";
// import { Workbook } from 'exceljs';
// import saveAs from "file-saver";
// import { exportDataGrid } from "devextreme/excel_exporter";

import DevexpressPercentColumn from "./devexpress-percent-column";
import "./devexpress-grid-style.css";
import { Grid } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import ConfirmDialog from "../../ConfirmDialog/ConfirmDialog";

class DevExpressDataGridReserva extends Component {
  constructor(props) {
    super(props);

    this.applyFilterTypes = [
      {
        key: "auto",
        name: "Immediately",
      },
      {
        key: "onClick",
        name: "On Button Click",
      },
    ];

    this.state = {
      positionDisableSorting: false,
      showFilterRow: true,
      showHeaderFilter: true,
      currentFilter: this.applyFilterTypes[0].key,
      autoExpandAll: true,
      confirmOpen: false,
      confirmText: "¿Confirma que desea remover este registro?",
      reservaIdEliminar: null,
    };

    this.dataGrid = null;

    this.onPositionSortingChanged = this.onPositionSortingChanged.bind(this);

    this.orderHeaderFilter = this.orderHeaderFilter.bind(this);
    this.onShowFilterRowChanged = this.onShowFilterRowChanged.bind(this);
    this.onShowHeaderFilterChanged = this.onShowHeaderFilterChanged.bind(this);
    this.onCurrentFilterChanged = this.onCurrentFilterChanged.bind(this);

    this.onAutoExpandAllChanged = this.onAutoExpandAllChanged.bind(this);

    this.onExporting = this.onExporting.bind(this);
    this.cargarDatos = this.cargarDatos.bind(this);
    this.remover = this.remover.bind(this);
  }

  exportGrid() {
    const doc = new jsPDF();
    const dataGrid = this.dataGridRef.current.instance;

    // exportDataGridToPdf({
    //   jsPDFDocument: doc,
    //   component: dataGrid,
    // }).then(() => {
    //   doc.save("AppILU.pdf");
    // });
  }

  cerrarConfirm = () => {
    this.setState({ confirmOpen: false });
  };

  showConfirm = (e) => {
    const reservaId = e.row.data.Id;
    this.setState({ confirmOpen: true, reservaIdEliminar: reservaId });
  };

  render() {
    {
      console.log("filas", this.props.filas);
    }
    return (
      <React.Fragment>
        {/* <Button
          id="exportButton"
          icon="exportpdf"
          text="Export to PDF"
          onClick={this.exportGrid}
        /> */}
        <DataGrid
          dataSource={this.props.filas}
          //   defaultColumns={this.props.columns}
          showBorders={true}
          ref={(ref) => (this.dataGrid = ref)}
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
          onExporting={this.onExporting}
        >
          <FilterRow
            visible={this.state.showFilterRow}
            applyFilter={this.state.currentFilter}
          />
          <HeaderFilter visible={this.state.showHeaderFilter} />
          <GroupPanel visible={true} />
          <Grouping autoExpandAll={this.state.autoExpandAll} />
          <SearchPanel visible={true} width={240} placeholder="Search..." />
          <Selection mode="multiple" />
          <Editing
            mode="row"
            useIcons={true}
            allowUpdating={false}
            allowDeleting={false}
          />

          {/* <Column type="buttons" width={50}>
            <Button
              hint="Ir a la gestión"
              icon="link"
              visible={true}
              onClick={this.navegarGestion}
            />
          </Column> */}
          <Column
            dataField="Id"
            caption={"Reserva #"}
            dataType="number"
            //width={230}
            //fixed={true}
          />
          <Column
            dataField="CodigoAviso"
            caption={"Gestión #"}
            dataType="number"
            //width={230}
            //fixed={true}
          />

          <Column
            dataField="HabitacionNombre"
            caption={"Habitación"}
            dataType="string"
            //width={230}
            //fixed={true}
          />

          <Column
            dataField="FechaInicio"
            caption={"Fecha Inicio"}
            dataType="date"
            format="d/M/yyyy, HH:mm"
            //width={230}
            //fixed={true}
          />

          <Column
            dataField="FechaFin"
            caption={"Fecha Fin"}
            dataType="date"
            format="d/M/yyyy, HH:mm"
            //width={230}
            //fixed={true}
          />

          <Column
            dataField="Checkout"
            caption={"Fecha Checkout"}
            dataType="date"
            format="d/M/yyyy, HH:mm"
            //width={230}
            //fixed={true}
          />

          <Column
            dataField="usuarioSolicitante"
            caption={"Solicitante"}
            dataType="string"
            //width={230}
            //fixed={true}
          />

          <Column
            dataField="usuarioAutoriza"
            caption={"Usuario Autoriza"}
            dataType="string"
            //width={230}
            //fixed={true}
          />

          <Column
            dataField="NombreCompletoUsuario"
            caption={"Usuario Hotel Autoriza"}
            dataType="string"
            //width={230}
            //fixed={true}
          />

          <Column
            dataField="Observaciones"
            caption={"Observaciones"}
            dataType="string"
            //width={230}
            //fixed={true}
          />

          <Column type="buttons" width={50}>
            <Button
              hint="Edit"
              icon="edit"
              visible={true}
              onClick={this.cargarDatos}
            />
          </Column>
          <Column type="buttons" width={50}>
            <Button
              hint="Delete"
              icon="trash"
              visible={true}
              onClick={this.showConfirm}
            />
          </Column>

          {/* <Column
            dataField="CodigoUsuario"
            caption={"Código usuario."}
            dataType="number"
            //width={230}
            //fixed={true}
          /> */}

          {/* <Column
            dataField="UsuariosAsignados"
            caption="Usuarios Asignados"
            cellRender={this.listarUsuarios}
          >
            <HeaderFilter visible={false} />
            <FilterRow visible={false} />
          </Column> */}

          {/* <Column dataField="Colaborador" caption="Colaborador">
            <HeaderFilter allowSearch={true} />
          </Column> */}

          <ColumnFixing enabled={true} />
          <Sorting mode="multiple" />
          <Paging defaultPageSize={10} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[5, 10, 20]}
            showInfo={true}
          />
          <Export enabled={true} allowExportSelectedData={true} />
        </DataGrid>
        {/* <div className="options">
          <div className="caption">Options</div>
          <div className="option">
            <CheckBox
              text="Disable Sorting for the Position Column"
              value={this.state.positionDisableSorting}
              onValueChanged={this.onPositionSortingChanged}
            />
          </div>
        </div> */}
        <div className="options">
          <div className="caption">Options</div>
          <div className="option">
            <span>Apply Filter </span>
            <SelectBox
              items={this.applyFilterTypes}
              value={this.state.currentFilter}
              onValueChanged={this.onCurrentFilterChanged}
              valueExpr="key"
              displayExpr="name"
              disabled={!this.state.showFilterRow}
            />
          </div>
          <div className="option">
            <CheckBox
              text="Filter Row"
              value={this.state.showFilterRow}
              onValueChanged={this.onShowFilterRowChanged}
            />
          </div>
          <div className="option">
            <CheckBox
              text="Header Filter"
              value={this.state.showHeaderFilter}
              onValueChanged={this.onShowHeaderFilterChanged}
            />
          </div>
          <div className="option">
            <CheckBox
              text="Expand All Groups"
              value={this.state.autoExpandAll}
              onValueChanged={this.onAutoExpandAllChanged}
            />
          </div>
        </div>
        <ConfirmDialog
          id="confirmar"
          keepMounted
          open={this.state.confirmOpen}
          onClose={this.cerrarConfirm}
          value=""
          texto={this.state.confirmText}
          okfunction={this.remover}
        />
      </React.Fragment>
    );
  }

  onPositionSortingChanged() {
    this.setState({
      positionDisableSorting: !this.state.positionDisableSorting,
    });

    this.dataGrid.instance.columnOption(5, "sortOrder", void 0);
  }

  calculateFilterExpression(value, selectedFilterOperations, target) {
    let column = this;
    if (target === "headerFilter" && value === "weekends") {
      return [[getOrderDay, "=", 0], "or", [getOrderDay, "=", 6]];
    }
    return column.defaultCalculateFilterExpression.apply(this, arguments);
  }

  listarUsuarios(list) {
    list = list.value;
    if (Array.isArray(list)) {
      return (
        <Grid>
          {list.map((item, i) => (
            <div>
              <Chip
                label={item}
                size="small"
                color="primary"
                style={{ marginLeft: 5 }}
              />
              <br />
              <br />
            </div>
          ))}
        </Grid>
      );
    }
  }

  orderHeaderFilter(data) {
    data.dataSource.postProcess = (results) => {
      results.push({
        text: "Weekends",
        value: "weekends",
      });
      return results;
    };
  }

  onShowFilterRowChanged(e) {
    this.setState({
      showFilterRow: e.value,
    });
    this.clearFilter();
  }
  onShowHeaderFilterChanged(e) {
    this.setState({
      showHeaderFilter: e.value,
    });
    this.clearFilter();
  }
  onCurrentFilterChanged(e) {
    this.setState({
      currentFilter: e.value,
    });
  }
  clearFilter() {
    this.dataGrid.instance.clearFilter();
  }

  onAutoExpandAllChanged() {
    this.setState({
      autoExpandAll: !this.state.autoExpandAll,
    });
  }

  //   onExporting(e) {
  //     const workbook = new ExcelJS.Workbook();
  //     const worksheet = workbook.addWorksheet("Main sheet");

  //     exportDataGrid({
  //       component: e.component,
  //       worksheet: worksheet,
  //       autoFilterEnabled: true,
  //     }).then(() => {
  //       workbook.xlsx.writeBuffer().then((buffer) => {
  //         saveAs(
  //           new Blob([buffer], { type: "application/octet-stream" }),
  //           "DataGrid.xlsx"
  //         );
  //       });
  //     });
  //     e.cancel = true;
  //   }

  onExporting(e) {
    // const workbook = new Workbook();
    // const worksheet = workbook.addWorksheet("Main sheet");
    // exportDataGrid({
    //   component: e.component,
    //   worksheet: worksheet,
    // }).then(function () {
    //   workbook.xlsx.writeBuffer().then(function (buffer) {
    //     saveAs(
    //       new Blob([buffer], { type: "application/octet-stream" }),
    //       "DataGrid.xlsx"
    //     );
    //   });
    // });
    // e.cancel = true;
  }

  cargarDatos(e) {
    const reservaId = e.row.data.Id;
    console.log("reservaId", reservaId);
    this.props.cargarDatosEdicion(reservaId);
  }

  remover(reservaId) {
    console.log("reservaId", reservaId);
    this.props.remover(this.state.reservaIdEliminar);
  }
}

function getOrderDay(rowData) {
  return new Date(rowData.OrderDate).getDay();
}

export default DevExpressDataGridReserva;
