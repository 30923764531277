import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Mensaje from "../../helpers/components/message";
import Chip from "@material-ui/core/Chip";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: "center",
    color: theme.palette.text.secondary
  }
});

function PasoDosFormulario(props) {
  const { classes } = props;
  let gruposSeleccionadosString = [];

  console.log("grupos disponibles", props.gruposDisponibles);

  for (let [key, value] of Object.entries(props.gruposSeleccionados)) {
    console.log("Valor a buscar", key);
    if (value) {
      let grupoSelect = props.gruposDisponibles.find(
        grupo => grupo.CodigoGrupo == key
      ).Nombre;
      gruposSeleccionadosString.push(
        <>
          <Chip label={grupoSelect} />
          &nbsp;
        </>
      );
    }
  }

  console.log(gruposSeleccionadosString);

  return (
    <div className="FormularioContainer">
      <div className={classes.root}>
        <Grid container spacing={24}>
          <Grid item xs={3}>
            <Typography variant="subheading" gutterBottom>
              Titulo:
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography variant="body2" gutterBottom>
              {props.titulo}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subheading" gutterBottom>
              Descripción:
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography variant="body2" gutterBottom>
              {props.descripcion}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subheading" gutterBottom>
              Cuerpo:
            </Typography>
          </Grid>
          <Grid item xs={9}>
            {props.cuerpo.split("\n").map((item, i) => {
              return <p key={i}>{item}</p>;
            })}
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subheading" gutterBottom>
              Grupos:
            </Typography>
          </Grid>
          <Grid item xs={9}>
            {gruposSeleccionadosString}
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subheading" gutterBottom>
              Fecha:
            </Typography>
          </Grid>
          <Grid item xs={9}>
            {props.seleccionoAhora ? (
              <>
                <Mensaje>Esta notificación se enviará cuando guarde.</Mensaje>
              </>
            ) : (
              <Typography variant="body2" gutterBottom>
                {props.fecha}
              </Typography>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default withStyles(styles)(PasoDosFormulario);
