import React from 'react';
import { Dialog, DialogTitle, List, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import UserAvatar from '../../helpers/components/user-avatar';

const ModalAsignados = ({ open, handleClose, usuarios }) => {
    
    const getIniciales = (nombre) => {
        const _nombre = nombre.toUpperCase()
        return `${_nombre[0]}${_nombre[1]}`
    }

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle id="simple-dialog-title">Usuarios asignados a esta tarea</DialogTitle>
            <List style={{ marginBottom: '1rem' }}>
                {usuarios.map((item) => (
                    <ListItem key={item.Usuario.CodigoUsuario}>
                        <ListItemAvatar>
                            <UserAvatar
                                iniciales={getIniciales(item.Usuario.UsuarioAD)}
                                userName={item.Usuario.Usuario}
                                nombre={item.Usuario.NombreCompletoUsuario}
                                isAsignado={true}
                                imagenPerfil={item.Usuario.ImagenPerfil}
                            />
                        </ListItemAvatar>
                        <ListItemText primary={item.Usuario.NombreCompletoUsuario} secondary={item.Usuario.Usuario} />
                    </ListItem>
                ))}
                {!usuarios.length && (
                    <ListItem key={1}>
                        <ListItemAvatar>
                            <UserAvatar
                                iniciales={'NA'}
                                userName={'NA'}
                                nombre={'NA'}
                                isAsignado={false}
                                imagenPerfil={null}
                            />
                        </ListItemAvatar>
                        <ListItemText primary={'No Asignado'} secondary={''} />
                    </ListItem>
                )}
            </List>
        </Dialog>
    )
}

export default ModalAsignados;
