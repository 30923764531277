import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Backdrop,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  TextField,
  Paper,
  Tooltip,
  Modal,
  Fade,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
} from "@material-ui/core";

import BackdropModal from "@material-ui/core/Backdrop";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import {
  Edit,
  Delete,
  ViewList,
  Add,
  PlayCircleFilled,
} from "@material-ui/icons";

import { green } from "@material-ui/core/colors";
import { NavLink } from "react-router-dom";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import DataGrid, {
  Column,
  Pager,
  Paging,
  Sorting,
  ColumnFixing,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Grouping,
  GroupPanel,
  Export,
  Selection,
  Editing,
  Scrolling,
  LoadPanel,
} from "devextreme-react/data-grid";

import API from "../../utils/api";
import { isSignedIn, signOut } from "../../utils/auth";
import {
  validaPermisoPaginaActual,
  verificaPermisoPaginaActual,
} from "../../utils/helpers";
import { ToastContainer, toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import "devextreme/dist/css/dx.material.teal.light.compact.css";
import { Link } from "react-router-dom";

const width_proportion = "100%";
const titleColor = "#2B3C4D";

const useStyles = makeStyles((theme) => ({
  table: {
    width: "100%",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 210,
  },
  textFieldDireccion: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 230,
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  greenAvatar: {
    fontSize: "2.5em",
    color: "#fff",
    // borderStyle:'solid',
    // borderWidth:'1 !important',
    // borderColor:'#3f51b5 !important',
    backgroundColor: green[500],
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  paperCard: {
    padding: 20,
    width: "100%",
  },
  formControlSelects: {
    // margin: theme.spacing(1),
    // minWidth: 230,

    marginBottom: 10,
    marginTop: 10,
    //width: "100%",
    marginLeft: 30,
  },
  formControlSelect: {
    // margin: theme.spacing(1),
    width: width_proportion,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  rootTabla: {
    width: "100%",
    marginTop: theme.spacing.unit * 1,
    overflowX: "auto",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paperModal: {
    position: "absolute",
    //width: "70%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const PuestoLaboral = (props) => {
  const classes = useStyles();
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors: fieldsErrors },
    register,
  } = useForm();

  let applyFilterTypes = [
    {
      key: "auto",
      name: "Immediately",
    },
    {
      key: "onClick",
      name: "On Button Click",
    },
  ];

  const [userName, setUserName] = useState("");
  const [open, setOpen] = useState(false);
  const [cargandoMaestros, setCargandoMaestros] = useState(false);
  const [puestoLaboral, setPuestoLaboral] = useState([]);
  const [maestroNivelPuestoLaboral, setMaestroNivelPuestoLaboral] = useState(
    []
  );
  const [codigoPuestoLaboral, setCodigoPuestoLaboral] = useState(null);
  const [codigoNivelPuestoLaboral, setCodigoNivelPuestoLaboral] = useState(0);
  const [puestoLaboralNombre, setPuestoLaboralNombre] = useState("");

  const [numeroVersion, setNumeroVersion] = useState("");
  const [fechaCreacion, setFechaCreacion] = useState(new Date());

  const [maestroTipoCambioPuestoLaboral, setMaestroTipoCambioPuestoLaboral] =
    useState([]);
  const [
    valueAutoCompleteTipoCambioPuestoLaboral,
    setValueAutoCompleteTipoCambioPuestoLaboral,
  ] = useState(null);
  const [codigoTipoCambioPuestoLaboral, setCodigoTipoCambioPuestoLaboral] =
    useState(0);

  const [maestroEstadoSolicitud, setMaestroEstadoSolicitud] = useState([]);
  const [
    valueAutoCompleteEstadoSolicitud,
    setValueAutoCompleteEstadoSolicitud,
  ] = useState(null);
  const [codigoEstadoSolicitud, setEstadoSolicitud] = useState(0);

  const [maestroPuestoLaboralSolicitud, setMaestroPuestoLaboralSolicitud] =
    useState([]);
  const [codigoPuestoLaboralSolicitud, setCodigoPuestoLaboralSolicitud] =
    useState(0);

  const [
    valueAutoCompleteNivelPuestoLaboral,
    setValueAutoCompleteNivelPuestoLaboral,
  ] = useState(null);

  const [nombreCorto, setNombreCorto] = useState("");
  const [grado, setGrado] = useState(null);

  const [codigoSubNivelPuestoLaboral, setCodigoSubNivelPuestoLaboral] =
    useState(null);
  const [maestroSubNivelPuestoLaboral, setMaestroSubNivelPuestoLaboral] =
    useState([]);
  const [
    valueAutoCompleteSubNivelPuestoLaboral,
    setValueAutoCompleteSubNivelPuestoLaboral,
  ] = useState(null);

  const [
    codigoClasificacionPuestoLaboral,
    setCodigoClasificacionPuestoLaboral,
  ] = useState(null);
  const [
    maestroClasificacionPuestoLaboral,
    setMaestroClasificacionPuestoLaboral,
  ] = useState([]);
  const [
    valueAutoCompleteClasificacionPuestoLaboral,
    setValueClasificacionSubNivelPuestoLaboral,
  ] = useState(null);

  const [positionDisableSorting, setPositionDisableSorting] = useState(false);
  const [showFilterRow, setShowFilterRow] = useState(true);
  const [showHeaderFilter, setShowHeaderFilter] = useState(true);
  const [currentFilter, setCurrentFilter] = useState(applyFilterTypes[0].key);
  const [autoExpandAll, setAutoExpandAll] = useState(true);

  const [buscando, setBackDrop] = useState(false);

  const [dialogoAbierto, abrirDialogo] = useState(false);
  const [dialogoAbiertoEditar, abrirDialogoEditar] = useState(false);
  const [dialogoAbiertoEliminarSolicitud, abrirDialogoEliminarSolicitud] =
    useState(false);
  const [dialogoAbiertoIniciarSolicitud, setDialogoAbiertoIniciarSolicitud] =
    useState(false);
  const [editarCompleto, setEditarCompleto] = useState(false);

  const handleCerrarBackDrop = () => {
    setBackDrop(false);
  };
  const handleAbrirBackDrop = () => {
    setBackDrop(true);
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  useEffect(() => {
    const cargar = async () => {
      // CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
      const llaveAgrupacion = "Menu.Principal.Web";
      const llavePagina = "Menu.Principal.Web.PuestoLaboral";
      await validaPermisoPaginaActual(llaveAgrupacion, llavePagina);

      const llave = "GESPILU";
      const llaveEditarCompleto = "GESPILU.PuestoLaboral.EditarCompleto";
      let permisoEditarCompleto = await verificaPermisoPaginaActual(
        llave,
        llaveEditarCompleto
      );
      setEditarCompleto(permisoEditarCompleto);
      // FIN DE CONSULTA DE PERMISOS

      handleAbrirBackDrop();

      await cargarMaestroPuestoLaboral();
      await cargarMaestroNivelPuestoLaboral();
      await cargarMaestroPuestoLaboralSolicitud();
      await cargarMaestroClasificacionPuestoLaboral();
      handleCerrarBackDrop();
    };

    cargar();
  }, [cargandoMaestros]);

  useEffect(() => {
    const cargar = async () => {
      if (codigoNivelPuestoLaboral !== null && codigoNivelPuestoLaboral > 0) {
        handleAbrirBackDrop();

        await cargarMaestroSubNivelPuestoLaboral();

        handleCerrarBackDrop();
      }
    };

    cargar();
  }, [codigoNivelPuestoLaboral]);

  const cargarMaestroPuestoLaboral = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/PuestoLaboral/";

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setPuestoLaboral(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cargarMaestroPuestoLaboralSolicitud = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri =
        "api/PuestoLaboral/Bitacora/" + props.puestoLaboral.CodigoPuestoLaboral;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setMaestroPuestoLaboralSolicitud(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cargarMaestroNivelPuestoLaboral = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/NivelPuestoLaboral/";

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setMaestroNivelPuestoLaboral(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cargarMaestroSubNivelPuestoLaboral = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/SubNivelPuestoLaboral/" + codigoNivelPuestoLaboral;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setMaestroSubNivelPuestoLaboral(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cargarMaestroClasificacionPuestoLaboral = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/ClasificacionPuestoLaboral/";

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setMaestroClasificacionPuestoLaboral(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const guardarDato = async () => {
    const data = {
      CodigoPuestoLaboral: codigoPuestoLaboral,
      CodigoNivelPuestoLaboral: codigoNivelPuestoLaboral,
      PuestoLaboral: puestoLaboralNombre,
      // NumeroVersion: numeroVersion,
      FechaCreacion: fechaCreacion,
      CodigoSubNivelPuestoLaboral: codigoSubNivelPuestoLaboral,
      CodigoClasificacionPuestoLaboral: codigoClasificacionPuestoLaboral,
      NombreCorto: nombreCorto,
      Grado: grado,
      CodigoEstadoSolicitud: 1,
      CodigoTipoCambioPuestoLaboral: codigoTipoCambioPuestoLaboral,
      Activo: true,
      Eliminado: false,
      UsuarioInserto: userName,
      FechaInserto: "",
      UsuarioModifico: "",
      FechaModifico: "",
    };

    console.log(data);
    // dataFicha.usuarioInserto = "prueba";
    try {
      handleCerrarDialogoIniciarSolicitud();
      handleAbrirBackDrop();
      let vIsSignedIn = await isSignedIn();
      if (vIsSignedIn.response) {
        let request;

        request = await API.post("api/PuestoLaboralSolicitud/", data);
        if (request.status !== 200) {
          toast.error(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        } else {
          if (request.data.response) {
            toast.success("Se guardó exitosamente");

            //await cargarMaestroPuestoLaboral();
            await cargarMaestroPuestoLaboralSolicitud();

            setPuestoLaboralNombre("");
            setNumeroVersion("");
            setFechaCreacion(new Date());
            setCodigoPuestoLaboral(null);
            setCodigoNivelPuestoLaboral(0);

            setValueAutoCompleteNivelPuestoLaboral(null);

            setCodigoSubNivelPuestoLaboral(null);
            setCodigoClasificacionPuestoLaboral(null);
            setGrado(null);
            setNombreCorto("");

            setValueAutoCompleteSubNivelPuestoLaboral(null);
            setValueClasificacionSubNivelPuestoLaboral(null);
          } else {
            toast.error("Error.- " + request.data.mensaje);
          }
        }
      }
    } catch (e) {
      console.log("EXCEPTION: " + e);
      toast.error(
        "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
      );
    } finally {
      handleCloseModal();
      handleCerrarBackDrop();
    }
  };

  const cuandoHayError = async (data) => {
    toast.info(
      "Existen algunos campos con error, por favor valida el formulario",
      {
        position: "top-right",
        autoClose: 3000, // Esta en milisegundos 3 segundos
      }
    );
  };

  const handleDateChangeFechaCreacion = (date) => {
    setFechaCreacion(date);
  };

  const onExporting = async (e) => {};

  const handleAbrirDialogoEliminar = (id) => {
    abrirDialogo(true);

    setCodigoPuestoLaboral(id);
  };

  const handleAbrirDialogoEliminarSolicitud = (id) => {
    abrirDialogoEliminarSolicitud(true);

    setCodigoPuestoLaboralSolicitud(id);
  };

  const handleCerrarDialogo = () => {
    limpiarCampos();
    abrirDialogo(false);
  };

  const handleAbrirDialogoEditar = (id) => {
    abrirDialogoEditar(true);

    setCodigoPuestoLaboral(id);
  };

  const handleCerrarDialogoEditar = () => {
    abrirDialogoEditar(false);
  };

  const handleAbrirDialogoIniciarSolicitud = (id) => {
    setDialogoAbiertoIniciarSolicitud(true);

    setCodigoPuestoLaboralSolicitud(id);
  };

  const handleCerrarDialogoIniciarSolicitud = () => {
    setDialogoAbiertoIniciarSolicitud(false);
  };

  const quitarProyecto = async (pId) => {
    const proyectosExcluidos = puestoLaboral.filter(
      (elemento) => elemento.CodigoPuestoLaboral !== pId
    );

    console.log("Quitar" + proyectosExcluidos);

    try {
      handleAbrirBackDrop();
      let vIsSignedIn = await isSignedIn();
      if (vIsSignedIn.response) {
        let uri = "api/PuestoLaboral/Quitar/" + pId;

        const request = await API.fetchGetRequest(uri);

        if (request.statusCode !== 200) {
          toast.error(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        } else {
          if (request.data.response) {
            toast.success("Se quitó el registro con éxito");

            // await cargarMaestroPuestoLaboral();
            await cargarMaestroPuestoLaboralSolicitud();
            setPuestoLaboralNombre("");
            setNumeroVersion("");
            setFechaCreacion(new Date());
            setCodigoPuestoLaboral(null);
            setCodigoNivelPuestoLaboral(0);

            setValueAutoCompleteNivelPuestoLaboral(null);

            setCodigoSubNivelPuestoLaboral(null);
            setCodigoClasificacionPuestoLaboral(null);
            setGrado(null);
            setNombreCorto("");

            setValueAutoCompleteSubNivelPuestoLaboral(null);
            setValueClasificacionSubNivelPuestoLaboral(null);
          } else {
            toast.error("Error.- " + request.data.mensaje);
          }
        }
      }
    } catch (error) {
      console.log("Error en  eliminación de proyectos" + error);
    } finally {
      handleCerrarBackDrop();
      handleCerrarDialogo();
    }
  };

  const editarProyecto = async (pId) => {
    handleCerrarDialogoEditar();
    handleAbrirBackDrop();

    const proyectosExcluidos = puestoLaboral.filter(
      (elemento) => elemento.CodigoPuestoLaboral === pId
    );

    console.log("Editar el proyecto" + proyectosExcluidos);

    try {
      handleOpenModal();
      setCodigoTipoCambioPuestoLaboral(2); //edición
      setCodigoPuestoLaboral(pId);
      setPuestoLaboralNombre(proyectosExcluidos[0].PuestoLaboral);
      setNumeroVersion(proyectosExcluidos[0].NumeroVersion);
      setFechaCreacion(proyectosExcluidos[0].FechaCreacion);
      setCodigoNivelPuestoLaboral(
        proyectosExcluidos[0].CodigoNivelPuestoLaboral
      );

      const itemNivelPuestoLaboral = maestroNivelPuestoLaboral.filter(
        (elemento) =>
          elemento.CodigoNivelPuestoLaboral ===
          proyectosExcluidos[0].CodigoNivelPuestoLaboral
      );
      setValueAutoCompleteNivelPuestoLaboral(itemNivelPuestoLaboral[0]);

      setCodigoSubNivelPuestoLaboral(
        proyectosExcluidos[0].CodigoSubNivelPuestoLaboral
      );
      const itemSubNivelPuestoLaboral = maestroSubNivelPuestoLaboral.filter(
        (elemento) =>
          elemento.CodigoSubNivelPuestoLaboral ===
          proyectosExcluidos[0].CodigoSubNivelPuestoLaboral
      );
      setValueAutoCompleteNivelPuestoLaboral(itemSubNivelPuestoLaboral[0]);
      setCodigoClasificacionPuestoLaboral(
        proyectosExcluidos[0].CodigoClasificacionPuestoLaboral
      );
      const itemClasificacionPuestoLaboral =
        maestroClasificacionPuestoLaboral.filter(
          (elemento) =>
            elemento.CodigoClasificacionPuestoLaboral ===
            proyectosExcluidos[0].CodigoClasificacionPuestoLaboral
        );
      setValueClasificacionSubNivelPuestoLaboral(
        itemClasificacionPuestoLaboral[0]
      );
      setGrado(proyectosExcluidos[0].Grado);
      setNombreCorto(proyectosExcluidos[0].NombreCorto);
    } catch (error) {
      console.log("Error en  edición de proyectos" + error);
    }

    handleCerrarBackDrop();

    //alert("Quitar recurso del proyecto" + codigoRecurso);
  };

  const iniciarSolicitud = async (pId) => {
    const data = {
      CodigoPuestoLaboralBitacoraDescriptor: pId,
      CodigoPuestoLaboral: 0,
      Descripcion: 0,
      Activo: true,
      Eliminado: false,
      UsuarioInserto: userName,
      FechaInserto: "",
      UsuarioModifico: "",
      FechaModifico: "",
    };

    console.log(data);
    // dataFicha.usuarioInserto = "prueba";
    try {
      handleAbrirBackDrop();
      let vIsSignedIn = await isSignedIn();
      if (vIsSignedIn.response) {
        let request;

        request = await API.post("api/PuestoLaboral/Bitacora/Iniciar", data);
        if (request.status !== 200) {
          toast.error(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        } else {
          if (request.data.response) {
            toast.success("Se guardó exitosamente");

            handleCerrarDialogoIniciarSolicitud();

            //await cargarMaestroPuestoLaboral();
            await cargarMaestroPuestoLaboralSolicitud();
          } else {
            toast.error("Error.- " + request.data.mensaje);
          }
        }
      }
    } catch (e) {
      console.log("EXCEPTION: " + e);
      toast.error(
        "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
      );
    } finally {
      handleCerrarDialogoIniciarSolicitud();
      handleCerrarBackDrop();
    }
  };

  const cellOpciones = (rowInfo) => {
    return (
      <>
        <Tooltip
          title="Descriptor de Proyectos"
          style={{ marginRight: 5, cursor: "pointer" }}
        >
          <NavLink
            onClick={(e) => {
              if (false) {
                toast.warning(
                  "No tienes permisos para gestionar los anexos el proyecto",
                  {
                    position: "top-right",
                    pauseOnHover: false,
                    autoClose: 3000,
                  }
                );
                e.preventDefault();
              }
              //     props.history.push(`/proyecto-editar/${rowInfo.data.id}`);
            }}
            to={`/puesto-laboral-descriptor/${rowInfo.data.CodigoPuestoLaboral}`}
          >
            <ViewList className={classes.sizeIcons} />
          </NavLink>
        </Tooltip>
        {editarCompleto && (
          <React.Fragment>
            <Tooltip
              title="Editar"
              style={{ marginRight: 10, cursor: "pointer" }}
            >
              <Edit
                style={{ color: "#337ab7", cursor: "pointer" }}
                onClick={() =>
                  handleAbrirDialogoEditar(rowInfo.data.CodigoPuestoLaboral)
                }
              />
            </Tooltip>
            <Tooltip
              title="Eliminar"
              style={{ marginRight: 10, cursor: "pointer" }}
            >
              <Delete
                style={{ color: "#337ab7", cursor: "pointer" }}
                onClick={() =>
                  handleAbrirDialogoEliminar(rowInfo.data.CodigoPuestoLaboral)
                }
              />
            </Tooltip>
          </React.Fragment>
        )}
      </>
    );
  };

  const cellOpcionesSolicitud = (rowInfo) => {
    return (
      <>
        <Tooltip
          title="Iniciar solicitud"
          style={{ marginRight: 10, cursor: "pointer" }}
        >
          <PlayCircleFilled
            style={{ color: "#337ab7", cursor: "pointer" }}
            onClick={() =>
              handleAbrirDialogoIniciarSolicitud(
                rowInfo.data.CodigoPuestoLaboralBitacoraDescriptor
              )
            }
          />
        </Tooltip>
        {/* <Tooltip
          title="Eliminar"
          style={{ marginRight: 10, cursor: "pointer" }}
        >
          <Delete
            style={{ color: "#337ab7", cursor: "pointer" }}
            onClick={() =>
              handleAbrirDialogoEliminar(
                rowInfo.data.CodigoPuestoLaboralSolicitud
              )
            }
          />
        </Tooltip> */}
      </>
    );
  };

  const handleOnChangeNivelPuestoLaboral = async (event, newValue) => {
    handleAbrirBackDrop();
    if (newValue !== null) {
      setCodigoNivelPuestoLaboral(newValue.CodigoNivelPuestoLaboral);
      setValueAutoCompleteNivelPuestoLaboral(newValue);
    }
    handleCerrarBackDrop();
  };

  const handleOnChangeSubNivelPuestoLaboral = async (event, newValue) => {
    handleAbrirBackDrop();
    if (newValue !== null) {
      setCodigoSubNivelPuestoLaboral(newValue.CodigoSubNivelPuestoLaboral);
      setValueAutoCompleteSubNivelPuestoLaboral(newValue);
    }
    handleCerrarBackDrop();
  };

  const handleOnChangeClasificacionPuestoLaboral = async (event, newValue) => {
    handleAbrirBackDrop();
    if (newValue !== null) {
      setCodigoClasificacionPuestoLaboral(
        newValue.CodigoClasificacionPuestoLaboral
      );
      setValueClasificacionSubNivelPuestoLaboral(newValue);
    }
    handleCerrarBackDrop();
  };

  const limpiarCampos = () => {
    setPuestoLaboralNombre("");
    setNumeroVersion("");
    setFechaCreacion(new Date());
    setCodigoPuestoLaboral(null);
    setCodigoNivelPuestoLaboral(0);

    setValueAutoCompleteNivelPuestoLaboral(null);

    setCodigoSubNivelPuestoLaboral(null);
    setCodigoClasificacionPuestoLaboral(null);
    setGrado(null);
    setNombreCorto("");

    setValueAutoCompleteSubNivelPuestoLaboral(null);
    setValueClasificacionSubNivelPuestoLaboral(null);
  };

  const handleNuevaPlaza = () => {
    limpiarCampos();
    handleOpenModal();
    setCodigoTipoCambioPuestoLaboral(1); //nuevo
  };

  const cellTemplateUnidadOrganizacion = (rowInfo) => {
    return (
      <>
        <Tooltip
          title={`Ir a gestión:`}
          style={{ marginRight: 10, cursor: "pointer" }}
        >
          <Link to={`/gestion/${rowInfo.data.Referencia}`}>
            {rowInfo.data.Referencia}
          </Link>
        </Tooltip>
      </>
    );
  };
  return (
    <React.Fragment>
      <Backdrop className={classes.backdrop} open={buscando}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer />

      {/* Dialogo para iniciar solicitud */}
      <Dialog
        open={dialogoAbiertoIniciarSolicitud}
        onClose={handleCerrarDialogoIniciarSolicitud}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Iniciar Solicitud</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro que desea iniciar la solicitud para iniciar este
            registro?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCerrarDialogoIniciarSolicitud}
            color="secondary"
          >
            Cancelar
          </Button>
          <Button
            onClick={() => iniciarSolicitud(codigoPuestoLaboralSolicitud)}
            color="primary"
          >
            Iniciar
          </Button>
        </DialogActions>
      </Dialog>

      <Paper className={classes.rootTabla}>
        <Typography variant={"h6"}>Bitácora</Typography>
        <DataGrid
          dataSource={maestroPuestoLaboralSolicitud}
          //   defaultColumns={this.props.columns}
          showBorders={true}
          rowAlternationEnabled={true}
          //ref={(ref) => (dataGrid = ref)}
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
          onExporting={onExporting}
          className={classes.table}
        >
          <FilterRow visible={showFilterRow} applyFilter={currentFilter} />
          <HeaderFilter visible={showHeaderFilter} />
          <GroupPanel visible={false} />
          <Grouping autoExpandAll={autoExpandAll} />
          <SearchPanel visible={false} width={240} placeholder="Search..." />
          {/* <Selection mode="multiple" /> */}
          <LoadPanel
            enabled={true}
            shadingColor="rgba(0,0,0,0.4)"
            showIndicator={true}
            shading={true}
            showPane={true}
            closeOnOutsideClick={false}
          />
          <Editing
            mode="row"
            useIcons={true}
            allowUpdating={false}
            allowDeleting={false}
          />
          <Column
            caption="Opciones"
            allowFiltering={false}
            allowSorting={false}
            cellRender={cellOpcionesSolicitud}
          />
          <Column
            dataField="Referencia"
            caption="# Gestion"
            cellRender={cellTemplateUnidadOrganizacion}
          />
          <Column
            dataField="CodigoPuestoLaboralSolicitud"
            caption="Código DB"
            dataType="number"
            visible={false}
          />
          {/* <Column
            dataField="CodigoPuestoLaboral"
            caption="Código Puesto Laboral "
            dataType="number"
          /> */}

          <Column dataField="Descripcion" caption="Descripción" />
          <Column dataField="ValorAnterior" caption="Valor Anterior " />
          <Column dataField="ValorNuevo" caption="Valor Nuevo " />
          <Column
            dataField="FechaCambio"
            caption="Fecha Cambio"
            dataType="date"
            format="dd/MM/yyyy"
          />

          <ColumnFixing enabled={true} />
          <Sorting mode="multiple" />
          <Paging defaultPageSize={20} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[20, 50, 100]}
            showInfo={true}
          />
          <Export enabled={true} allowExportSelectedData={true} />
          <Scrolling columnRenderingMode="virtual" />
        </DataGrid>
      </Paper>
    </React.Fragment>
  );
};

export default PuestoLaboral;
