import Api from "../../utils/api";
import { isSignedIn } from "../../utils/auth";
import axios from "axios";

export const getPDFBase64 = async (repo, filename) => {
  const session = await isSignedIn();
  const baseApi = Api.baseApi();

  try {
    const { data } = await axios({
      url: `${baseApi}api/media/descargar?key=${repo}&fileName=${filename}`,
      method: "GET",
      headers: { Authorization: "bearer " + session.accessToken },
      responseType: "blob",
    });
    const reader = new FileReader();
    reader.readAsDataURL(data);
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  } catch (error) {
    console.log(error);
    return null;
  }
};
export const getPDFSolicitudBase64 = async (
  idSolicitud,
  previsualizar = false
) => {
  const session = await isSignedIn();
  const baseApi = Api.baseApi();
  const url = !previsualizar
    ? `${baseApi}api/documentos/solicitud/descargar-documento/${idSolicitud}`
    : `${baseApi}api/documentos/solicitud/previsualizar-documento-firmado/${idSolicitud}`;
  try {
    const { data } = await axios({
      url,
      method: !previsualizar ? "POST" : "GET",
      headers: { Authorization: "bearer " + session.accessToken },
      responseType: "blob",
    });
    const reader = new FileReader();
    reader.readAsDataURL(data);
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  } catch (error) {
    throw 'Error al obtener archivo'
  }
};
export const getPDFCorrelativoBase64 = async (proceso, correlativo) => {
  const session = await isSignedIn();
  const baseApi = Api.baseApi();
  const url = `${baseApi}api/documentos/descargar-documento-oficial-por-codigo/proceso/${proceso}/${correlativo}`
  try {
    const { data } = await axios({
      url,
      method: "GET",
      headers: { Authorization: "bearer " + session.accessToken },
      responseType: "blob",
    });
    const reader = new FileReader();
    reader.readAsDataURL(data);
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  } catch (error) {
    throw 'Error al obtener archivo'
  }
};
export const getPlantillaBase64 = async (idPlantilla) => {
  const session = await isSignedIn();
  const baseApi = Api.baseApi();

  try {
    const { data } = await axios({
      url: `${baseApi}api/documentos/plantilla/descargar/${idPlantilla}`,
      method: "GET",
      headers: { Authorization: "bearer " + session.accessToken },
      responseType: "blob",
    });
    const reader = new FileReader();
    reader.readAsDataURL(data);
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  } catch (error) {
    console.log(error);
    return null;
  }
};
export const getReporteXLSX = async (tipo) => {
  const session = await isSignedIn();
  const baseApi = Api.baseApi();
  const urls = {
    'comentarios': 'comentarios-por-documento',
    'datos': 'datos-de-documentos'
  }
  try {
    const { data } = await axios({
      url: `${baseApi}api/documentos/reporte/${urls[tipo]}`,
      method: "GET",
      headers: { Authorization: "bearer " + session.accessToken },
      responseType: "blob",
    });
    const reader = new FileReader();
    reader.readAsDataURL(data);
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      }
    })
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};
