import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import EditIcon from "@material-ui/icons/Edit";
import Chip from "@material-ui/core/Chip";

//Own components
import TableTitle from "../../helpers/components/table-title";
import TableDescription from "../../helpers/components/table-description";
import Link from "../../helpers/components/link";

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto"
  },
  table: {
    minWidth: 700
  }
});

function SimpleTable(props) {
  const { classes, data } = props;

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: "5%" }}>ID</TableCell>
            <TableCell style={{ width: "15%" }}>Usuario</TableCell>
            <TableCell style={{ width: "25%" }}>Nombre</TableCell>
            <TableCell style={{ width: "25%" }}>Correo electrónico</TableCell>
            <TableCell style={{ width: "10%" }}>
              Autenticación Windows
            </TableCell>
            <TableCell style={{ width: "10%" }}>Rol</TableCell>
            {/* <TableCell style={{ width: "20%" }}>Opciones</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, i) => (
            <TableRow
              key={i}
              onClick={() => props.redirectToUsuarioDetail(row.CodigoUsuario)}
              style={{ cursor: "pointer" }}
            >
              <TableCell component="th" scope="row">
                {row.CodigoUsuario}
              </TableCell>
              <TableCell>
                <TableTitle>{row.Usuario}</TableTitle>
                {
                  // <br />
                  //<TableDescription>{row.Descripcion}</TableDescription>
                }
              </TableCell>
              <TableCell component="th" scope="row">
                {row.NombreCompletoUsuario}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.CorreoElectronico}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.AutenticacionWindows ? "Si" : "No "}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.Roles.map((r, i) => (
                  <Chip
                    label={r}
                    key={i}
                    style={{
                      color: "white",
                      backgroundColor: "#2196F3",
                      margin: 1
                    }}
                  />
                ))}
              </TableCell>
              {/* <TableCell>
                <Link
                  onClick={() => props.editItem(row)}
                  icon={<EditIcon fontSize="small" />}
                  title=""
                />
              </TableCell> */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}

export default withStyles(styles)(SimpleTable);
