import React from 'react';
import 'devextreme/dist/css/dx.light.css';
import { exportDataGrid } from 'devextreme/excel_exporter';
import Excel from 'exceljs/dist/es5/exceljs.browser';
import saveAs from 'file-saver';
import Visibility from '@material-ui/icons/Visibility';
//import ODataStore from 'devextreme/data/odata/store';
import { Tooltip } from '@material-ui/core';
import DataGrid, {
  Column,
  ColumnChooser,
  Editing,
  Export,
  FilterRow,
  Format,
  Grouping,
  GroupItem,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  SearchPanel,
  Summary
} from 'devextreme-react/data-grid';
//Import de ejemplo de DevExtrem DataGrid
//import DiscountCell from './DiscountCell.js';
//import jssPreset from '@material-ui/styles/jssPreset';
//import { Item } from 'devextreme-react/accordion';

const pageSizes = [10, 25, 50, 100];

const cellAction=(rowInfo,action)=>{
  return (
  <Tooltip title="Ver">
    <Visibility onClick={()=>{
      action.function(rowInfo.data)
    }}>{action.text}</Visibility></Tooltip>
  )
}
class DevGrid extends React.Component {
  constructor(props) {
    super(props);
    console.log('-----------------------------')
    console.log(props.data);
    console.log(props.actions)
    this.state = {
      collapsed: false
    };
    this.onContentReady = this.onContentReady.bind(this);
    this.excelName = props.excelName
  }
  onExporting(e,excelName) {
    console.log("excel")
    console.log(excelName)
    if(excelName!==undefined){
      
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('Hoja 1');
    exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        customizeCell: function(options) {
            const excelCell = options;
            excelCell.font = { name: 'Arial', size: 12 };
            excelCell.alignment = { horizontal: 'left' };
        } 
    }).then(function() {
        workbook.xlsx.writeBuffer()
            .then(function(buffer) {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${excelName}.xlsx`);
            });
    });
  }
    e.cancel = true;
}
  render() {
    return (
      <DataGrid
        id={this.props.id}
        dataSource={this.props.data}
        allowColumnReordering={true}
        showBorders={true}
        onExporting={(e)=>this.onExporting(e,this.props.excelName)}
        onContentReady={this.onContentReady}
        autoExpandAll={true}
      >
          {console.log(this.props.excelName)}
        <Export enabled={true}/>
        <HeaderFilter visible={true} />
        <GroupPanel visible={true} 
          autoExpandAll={true}
        />
        <FilterRow visible={true} />
        <SearchPanel visible={true} highlightCaseSensitive={true} />
        <Grouping autoExpandAll={false} />
        <ColumnChooser
                    enabled={true}
                    mode="dragAndDrop" 
                />
        {
            this.props.info===undefined?
            null:
            this.props.info.map(h=> 
            <Column 
                dataField={h.dataField} 
                dataType={h.dataType}
                groupIndex = {h.group}
                visible={h.visible}
                printable = {h.printable}
                type={h.type}
                alignment="left"               
                format = {h.dataType==='date'?'dd/MM/yyyy':h.format} 
                caption={h.caption}
                width={h.width}>{h.type?null:<Format type={h.type} precision={h.precision}/>}</Column>)
        }
        <Editing
              useIcons={true}
            />
        {
          this.props.actions===undefined?null:(
            this.props.actions.map(a=>
              <Column cellRender={(info)=>cellAction(info,a)} width={a.width}/>)
            )
        }
        {
          this.props.cellRender ===undefined?null:
          <Column cellRender={(info)=>this.props.cellRender(info)} width={this.props.actionsWidth}/>
        }
        {
          this.props.info===undefined?
          null:
          (
            <Summary>
            {
              this.props.info.filter(i=>i.summaryType!==undefined).map(item=>(
                <GroupItem
                  column={item.dataField}
                  summaryType = {item.summaryType}
                  displayFormat = {item.displayFormat}
                />)
              )
            }
            </Summary>
          )
        }
        <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} />
        <Paging defaultPageSize={this.props.defaultPageSize===undefined?10:this.props.defaultPageSize} />
      </DataGrid>
    );
  }

  onContentReady(e) {
    if (!this.state.collapsed) {
      e.component.expandRow(['EnviroCare']);
      this.setState({
        collapsed: true
      });
    }
  }
}

export default DevGrid;
