import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";

import {
  Check,
  AssignmentReturned,
  TransferWithinAStation,
  Delete,
} from "@material-ui/icons";

import DataGrid, {
  Column,
  Pager,
  Paging,
  Sorting,
  ColumnFixing,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Grouping,
  GroupPanel,
  Export,
  Selection,
  Editing,
  Scrolling,
  RowDragging,
} from "devextreme-react/data-grid";

// import BackdropModal from "@material-ui/core/Backdrop";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { green } from "@material-ui/core/colors";

import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Paper,
  Tooltip,
  Modal,
  Fade,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
} from "@material-ui/core";

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import API from "../../utils/api";
import { isSignedIn, signOut } from "../../utils/auth";
import { validaPermisoPaginaActual } from "../../utils/helpers";
import { ToastContainer, toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import "devextreme/dist/css/dx.material.teal.light.compact.css";
import { LoadPanel } from "devextreme-react";

const width_proportion = "100%";
const titleColor = "#2B3C4D";
let datagridEmpleadosSCILU;

const useStyles = makeStyles((theme) => ({
  table: {
    width: "100%",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 210,
  },
  textFieldDireccion: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 230,
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  greenAvatar: {
    fontSize: "2.5em",
    color: "#fff",
    // borderStyle:'solid',
    // borderWidth:'1 !important',
    // borderColor:'#3f51b5 !important',
    backgroundColor: green[500],
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  paperCard: {
    padding: 20,
    width: "100%",
  },
  formControlSelects: {
    // margin: theme.spacing(1),
    // minWidth: 480,

    marginBottom: 10,
    marginTop: 10,
    width: "90%",
    marginLeft: 20,
  },

  formControlSelects2: {
    // margin: theme.spacing(1),
    minWidth: 480,

    marginBottom: 10,
    marginTop: 10,
    // width: "90%",
    marginLeft: 20,
  },

  formControlSelect: {
    // margin: theme.spacing(1),
    width: width_proportion,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  rootTabla: {
    width: "100%",
    marginTop: theme.spacing.unit * 1,
    overflowX: "auto",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paperModal: {
    position: "absolute",
    width: "70%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const MovimientoPersonal = (props) => {
  const classes = useStyles();
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors: fieldsErrors },
    register,
  } = useForm();

  let applyFilterTypes = [
    {
      key: "auto",
      name: "Immediately",
    },
    {
      key: "onClick",
      name: "On Button Click",
    },
  ];

  function validarRangoDeFechas(date) {
    var hoy = new Date();
    var fechaAnterior = new Date(new Date().setDate(hoy.getDate() - 30));
    var fechaPosterior = new Date(new Date().setDate(hoy.getDate() + 45));
    return !(date > fechaAnterior && date < fechaPosterior);
  }

  const [codigoNivelOrganizacion, setCodigoNivelOrganizacion] = useState(1);
  const [maestroGerencia, setMaestroGerencia] = useState([]);
  const [textoNivelOrganizacion, setTextoNivelOrganizacion] = useState("");

  const [registroActual, setRegistroActual] = useState({});

  const [codigoUnidadOrganizacion, setCodigoUnidadOrganizacion] = useState(0);

  const [userName, setUserName] = useState("");

  const [openPL, setOpenPL] = useState(false);

  // const [organizacionHijos, setOrganizacionHijos] = useState([]);

  const [maestroTipoTurno, setMaestroTipoTurno] = useState([]);

  const [codigoGerencia, setCodigoGerencia] = useState(0);

  const [showFilterRow, setShowFilterRow] = useState(true);
  const [showHeaderFilter, setShowHeaderFilter] = useState(true);
  const [currentFilter, setCurrentFilter] = useState(applyFilterTypes[0].key);
  const [autoExpandAll, setAutoExpandAll] = useState(true);

  const [buscando, setBackDrop] = useState(false);

  const [codigoTipoTurno, setCodigoTipoTurno] = useState(0);
  const [valueAutoCompleteTipoTurno, setValueAutoCompleteTipoTurno] =
    useState(null);

  const [codigoTipoMovimiento, setCodigoTipoMovimiento] = useState(0);
  const [maestroJefeInmediato, setMaestroJefeInmediato] = useState([]);
  const [dpiJefeInmediato, setDpiJefeInmediato] = useState("");
  const [valueAutoCompleteJefeInmediato, setValueAutoCompleteJefeInmediato] =
    useState(null);

  const [maestroPlazas, setMaestroPlazas] = useState([]);
  const [codigoPlazaLaboral, setCodigoPlazaLaboral] = useState(0);
  const [valueAutoCompletePlazaLaboral, setValueAutoCompletePlazaLaboral] =
    useState(null);

  const [maestroTipoObjetoSAP, setMaestroTipoObjetoSAP] = useState([]);
  const [codigoTipoObjetoSAP, setCodigoTipoObjetoSAP] = useState(0);
  const [valueAutoCompleteTipoObjetoSAP, setValueAutoCompleteTipoObjetoSAP] =
    useState(null);

  const [maestroObjetoSAP, setMaestroObjetoSAP] = useState([]);
  const [objetoSAP, setObjetoSAP] = useState("");
  const [textoObjetoSAP, setTextoObjetoSAP] = useState("");
  const [valueAutoCompleteObjetoSAP, setValueAutoCompleteObjetoSAP] =
    useState(null);

  const [fechaEjecucion, setFechaEjecucion] = useState(new Date());
  const [comentario, setComentario] = useState("");
  const [
    listadoEmpleadoAsignadoPorUnidad,
    setListadoEmpleadoAsignadoPorUnidad,
  ] = useState([]);

  const [dialogoAbierto, abrirDialogo] = useState(false);

  const [selectedEmployeeNames, setSelectedEmployeeNames] = useState(
    "No hay empleados seleccionados"
  );

  const [empleadosSeleccion, setempleadosSeleccion] = useState([]);

  const [listadoEmpleadoNoAsignado, setListadoEmpleadoNoAsignado] = useState(
    []
  );

  const [valueAutoCompleteGerencia, setValueAutoCompleteGerencia] =
    useState(null);

  //const [valido, setValido] = useState(false);

  const handleCerrarBackDrop = () => {
    setBackDrop(false);
  };
  const handleAbrirBackDrop = () => {
    setBackDrop(true);
  };

  const handleOpenModalPuestoLaboral = () => {
    setOpenPL(true);
  };

  const handleCloseModalPuestoLaboral = () => {
    setOpenPL(false);
  };

  useEffect(() => {
    const cargar = async () => {
      // CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
      // FIN DE CONSULTA DE PERMISOS

      handleAbrirBackDrop();

      await cargarMaestroGerencia();
      //await cargarEmpleadoAsignado();
      await cargarMaestroTipoObjetoSAP();
      await cargarMaestroTipoTurno();
      await MaestroJefeInmediato();
      await cargarEmpleadoAsignadoPorUnidadOrganizacion();
      handleCerrarBackDrop();
    };

    cargar();
  }, [props.codigoUnidadOrganizacion]);

  useEffect(() => {
    const cargar = async () => {
      handleAbrirBackDrop();

      if (codigoPlazaLaboral > 0) {
        await MaestroJefeInmediato();
        setValueAutoCompleteJefeInmediato(null);
        setDpiJefeInmediato("");
      }

      handleCerrarBackDrop();
    };

    cargar();
  }, [codigoPlazaLaboral]);

  useEffect(() => {
    const cargar = async () => {
      handleAbrirBackDrop();

      if (codigoTipoObjetoSAP > 0) {
        await cargarMaestroObjetoSAP();
        setValueAutoCompleteObjetoSAP(null);
        setObjetoSAP("");
        setTextoObjetoSAP("");
      }

      handleCerrarBackDrop();
    };

    cargar();
  }, [codigoTipoObjetoSAP]);

  useEffect(() => {
    const cargar = async () => {
      if (registroActual !== undefined || registroActual !== null) {
        handleAbrirBackDrop();
        // await cargaOrganizacionHijos();
        await cargarPlazas();

        handleCerrarBackDrop();
      }
    };

    cargar();
  }, [registroActual]);

  useEffect(() => {
    let data = maestroGerencia;
    if (data.length > 0) {
      var nivelActual = data.find(
        (f) => f.CodigoNivelOrganizacion === codigoNivelOrganizacion
      );

      if (nivelActual !== undefined || nivelActual !== null) {
        setTextoNivelOrganizacion(nivelActual.NivelOrganizacion);
      }
    }
  }, [codigoNivelOrganizacion]);

  const cargarEmpleadoAsignadoPorUnidadOrganizacion = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri =
        "api/PlazaLaboral/AsignadosPorUnidadOrganizacion/" +
        props.codigoUnidadOrganizacion;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setListadoEmpleadoAsignadoPorUnidad(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
      toast.error("Ha ocurrido un error: " + e);
    } finally {
      handleCerrarBackDrop();
    }
  };
  const cargarMaestroGerencia = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/Empleado/MaestroGerencia";

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setMaestroGerencia(request.data.data);
      }
    } catch (e) {
      console.log(e);
      toast.error("Ha ocurrido un error: " + e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cargarMaestroTipoTurno = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/TipoTurno/";

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setMaestroTipoTurno(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
      toast.error("Ha ocurrido un error: " + e);
      toast.error("Ha ocurrido un error: " + e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cargarMaestroTipoObjetoSAP = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/TipoObjetoSAP/";

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setMaestroTipoObjetoSAP(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
      toast.error("Ha ocurrido un error: " + e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cargarMaestroObjetoSAP = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/TipoObjetoSAP/" + codigoTipoObjetoSAP;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        if (request.data.response) {
          setMaestroObjetoSAP(request.data.ficha);
        } else {
          setMaestroObjetoSAP([]);
          toast.error(
            "Hubo un error al cargar la información de los objetos SAP." +
              request.data.mensaje
          );
        }
      }
    } catch (e) {
      console.log(e);

      toast.error("Ha ocurrido un error: " + e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cargarPlazas = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/EmpleadoSolicitudMovimiento/PlazasParaTraslado/";

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setMaestroPlazas(request.data.ficha);
        console.log(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
      toast.error("Ha ocurrido un error: " + e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const verificarEmpleado = async (pDPI) => {
    try {
      handleAbrirBackDrop();

      let uri = "api/EmpleadoSolicitudMovimiento/ComprobarEmpleado/";

      const request = await API.post(uri, pDPI);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        return request.data;
      }
    } catch (e) {
      console.log(e);
      toast.error("Ha ocurrido un error: " + e);
      return false;
    } finally {
      handleCerrarBackDrop();
    }
  };

  const guardarDato = async () => {
    const data = {
      ListadoEmpleado: empleadosSeleccion,
      DPIJefeInmediato: dpiJefeInmediato,
      CodigoTurno: codigoTipoTurno,
      CodigoTipoMovimientoPersonal: codigoTipoMovimiento,
      CodigoEstadoMovimientoPersonal: 1,
      CodigoPlazaLaboral: codigoPlazaLaboral,
      FechaEjecución: fechaEjecucion,
      Referencia: 0,
      Activo: true,
      Eliminado: false,
      UsuarioInserto: userName,
      FechaInserto: "",
      UsuarioModifico: "",
      FechaModifico: "",
      CodigoTipoObjetoSAP: codigoTipoObjetoSAP,
      TipoObjetoSAP: objetoSAP,
      TextoObjetoSAP: textoObjetoSAP,
      Comentario: comentario,
    };

    console.log(data);
    // dataFicha.usuarioInserto = "prueba";
    try {
      handleAbrirBackDrop();
      let vIsSignedIn = await isSignedIn();
      if (vIsSignedIn.response) {
        let request;

        request = await API.post("api/EmpleadoSolicitudMovimiento/", data);
        if (request.status !== 200) {
          toast.error(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        } else {
          toast.success("Se guardó exitosamente");

          await props.actualizarListaSolicitudes();

          setCodigoGerencia(0);
          setCodigoUnidadOrganizacion(0);

          deseleccionarItemsEnGridEmpleados();

          setComentario("");
        }
      }
    } catch (e) {
      console.log("EXCEPTION: " + e);
      toast.error(
        "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
      );
    } finally {
      handleCerrarBackDrop();
      handleCloseModalPuestoLaboral();
    }
  };

  const MaestroJefeInmediato = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri =
        "api/EmpleadoJefeInmediato/PorPlazaLaboral/" + codigoPlazaLaboral;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setMaestroJefeInmediato(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
      toast.error("Ha ocurrido un error: " + e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cuandoHayError = async (data) => {
    toast.info(
      "Existen algunos campos con error, por favor valida el formulario",
      {
        position: "top-right",
        autoClose: 3000, // Esta en milisegundos 3 segundos
      }
    );
  };

  const onExporting = async (e) => {};

  const handleCerrarDialogo = () => {
    abrirDialogo(false);
  };

  const handleOnChangeGerencia = async (event, newValue) => {
    handleAbrirBackDrop();
    if (newValue !== null) {
      setCodigoGerencia(newValue.CodigoGerencia);
      setValueAutoCompleteGerencia(newValue);
    }
    handleCerrarBackDrop();
  };

  const handleAbrirDialogoConfirmacion = async () => {
    console.log(datagridEmpleadosSCILU.getSelectedRowsData());
    setempleadosSeleccion(datagridEmpleadosSCILU.getSelectedRowsData());

    const valido = await verificarEmpleado(
      datagridEmpleadosSCILU.getSelectedRowsData()
    );

    if (valido.response) {
      handleOpenModalPuestoLaboral();

      setCodigoTipoMovimiento(3);

      setValueAutoCompleteTipoTurno(null);
      setCodigoTipoTurno(null);

      setValueAutoCompletePlazaLaboral(null);
      setCodigoPlazaLaboral(null);

      setValueAutoCompleteJefeInmediato(null);
      setDpiJefeInmediato("");
    } else {
      toast.info(
        "Hay empleados que actualmente estan en una solicitud de movimiento: " +
          valido.ficha,
        {
          position: "top-right",
          autoClose: 4000, // Esta en milisegundos 3 segundos
        }
      );
    }
  };

  const handleAbrirDialogoAlta = async () => {
    console.log(datagridEmpleadosSCILU.getSelectedRowsData());
    setempleadosSeleccion(datagridEmpleadosSCILU.getSelectedRowsData());

    const valido = await verificarEmpleado(
      datagridEmpleadosSCILU.getSelectedRowsData()
    );

    if (valido.response) {
      handleOpenModalPuestoLaboral();

      setCodigoTipoMovimiento(4);

      setValueAutoCompleteTipoTurno(null);
      setCodigoTipoTurno(null);

      setValueAutoCompletePlazaLaboral(null);
      setCodigoPlazaLaboral(null);

      setValueAutoCompleteJefeInmediato(null);
      setDpiJefeInmediato("");

      setComentario("");
    } else {
      toast.info(
        "Hay empleados que actualmente estan en una solicitud de movimiento: " +
          valido.ficha,
        {
          position: "top-right",
          autoClose: 4000, // Esta en milisegundos 3 segundos
        }
      );
    }
  };

  // const handleAbrirDialogoBaja = (fila) => {
  //   handleOpenModalPuestoLaboral();

  //   setDpi(fila.DPI);
  //   setCodigoTipoMovimiento(2);
  // };

  const handleOnChangeTipoTurno = async (event, newValue) => {
    handleAbrirBackDrop();
    if (newValue !== null) {
      setCodigoTipoTurno(newValue.CodigoTipoTurno);
      setValueAutoCompleteTipoTurno(newValue);
    }
    handleCerrarBackDrop();
  };

  const handleOnChangeJefeInmediato = async (event, newValue) => {
    handleAbrirBackDrop();
    if (newValue !== null) {
      setDpiJefeInmediato(newValue.DPI);
      setValueAutoCompleteJefeInmediato(newValue);
    }
    handleCerrarBackDrop();
  };

  const handleOnChangePlazaLaboral = async (event, newValue) => {
    handleAbrirBackDrop();
    if (newValue !== null) {
      setCodigoPlazaLaboral(newValue.id);
      setValueAutoCompletePlazaLaboral(newValue);
    }
    handleCerrarBackDrop();
  };

  const handleOnChangeTipoObjetoSAP = async (event, newValue) => {
    handleAbrirBackDrop();
    if (newValue !== null) {
      setCodigoTipoObjetoSAP(newValue.CodigoTipoObjetoSAP);
      setValueAutoCompleteTipoObjetoSAP(newValue);
    }
    handleCerrarBackDrop();
  };

  const handleOnChangeObjetoSAP = async (event, newValue) => {
    handleAbrirBackDrop();
    if (newValue !== null) {
      setObjetoSAP(
        codigoTipoObjetoSAP === 3 ? newValue.Grafo : newValue.CodigoCentroCosto
      );
      setTextoObjetoSAP(
        codigoTipoObjetoSAP === 3
          ? newValue.TextoExplicativo
          : newValue.Descripcion
      );

      setValueAutoCompleteObjetoSAP(newValue);
    }
    handleCerrarBackDrop();
  };

  const handleDateChangeFechaCreacion = (date) => {
    setFechaEjecucion(date);
  };

  //selección multiple

  const onInitialized = (e) => {
    datagridEmpleadosSCILU = e.component;
  };

  const deseleccionarItemsEnGridEmpleados = () => {
    datagridEmpleadosSCILU.clearSelection();
  };

  const getEmployeeName = (row) => {
    return `[${row.CodigoAnterior} - ${row.NombreCompleto}]`;
  };

  const getEmployeeNames = (selectedRowsData) => {
    return selectedRowsData.length
      ? selectedRowsData.map(getEmployeeName).join("; ")
      : "No hay empleados seleccionados";
  };

  const onSelectionChanged = ({ selectedRowKeys, selectedRowsData }) => {
    setSelectedEmployeeNames(getEmployeeNames(selectedRowsData));
  };

  const filtrarPorGerencia = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/Empleado/Gerencia/" + codigoGerencia;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setListadoEmpleadoNoAsignado(request.data.data);
      }
    } catch (e) {
      console.log(e);
      toast.error("Ha ocurrido un error: " + e);
    } finally {
      deseleccionarItemsEnGridEmpleados();
      handleCerrarBackDrop();
    }
  };
  return (
    <React.Fragment>
      <Backdrop className={classes.backdrop} open={buscando}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {/* Modal Traslado*/}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openPL}
        onClose={handleCloseModalPuestoLaboral}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openPL}>
          <div className={classes.paperModal}>
            <h2 id="simple-modal-title">
              Seleccione los parametros para el movimiento
            </h2>
            <Grid container spacing={3}>
              <form
                autoComplete="off"
                onSubmit={handleSubmit(guardarDato, cuandoHayError)}
              >
                <Grid item xs={6} sm={6} md={12} lg={12}>
                  <FormControl className={classes.formControlSelects}>
                    <Controller
                      name="puestoLaboral"
                      required
                      render={({ PuestoLaboral }) => (
                        <Autocomplete
                          id="puestoLaboral"
                          options={maestroTipoTurno}
                          getOptionLabel={(option) => option.TipoTurno}
                          value={valueAutoCompleteTipoTurno}
                          onChange={(event, newValue) =>
                            handleOnChangeTipoTurno(event, newValue)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Tipo Turno"
                              variant="outlined"
                            />
                          )}
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} md={12} lg={12}>
                  <FormControl className={classes.formControlSelects}>
                    <Controller
                      name="plazasLaborales"
                      required
                      render={({ PlazasLaborales }) => (
                        <Autocomplete
                          id="jefe"
                          options={maestroPlazas}
                          getOptionLabel={(option) => option.name}
                          value={valueAutoCompletePlazaLaboral}
                          onChange={(event, newValue) =>
                            handleOnChangePlazaLaboral(event, newValue)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Plaza a Trasladar"
                              variant="outlined"
                            />
                          )}
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
                  <FormControl className={classes.formControlSelects}>
                    <Controller
                      name="jefe"
                      required
                      render={({ Jefe }) => (
                        <Autocomplete
                          id="jefe"
                          options={maestroJefeInmediato}
                          getOptionLabel={(option) => option.NombreCompleto}
                          value={valueAutoCompleteJefeInmediato}
                          onChange={(event, newValue) =>
                            handleOnChangeJefeInmediato(event, newValue)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Jefe Inmediato"
                              variant="outlined"
                            />
                          )}
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
                  <FormControl className={classes.formControlSelects}>
                    <Controller
                      name="tipoObjetoSAP"
                      required
                      render={({ TipoObjetoSAP }) => (
                        <Autocomplete
                          id="tipoObjetoSAP"
                          options={maestroTipoObjetoSAP}
                          getOptionLabel={(option) => option.TipoObjetoSAP}
                          value={valueAutoCompleteTipoObjetoSAP}
                          onChange={(event, newValue) =>
                            handleOnChangeTipoObjetoSAP(event, newValue)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Tipo Objeto SAP"
                              variant="outlined"
                            />
                          )}
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
                  <FormControl className={classes.formControlSelects}>
                    <Controller
                      name="objetoSAP"
                      required
                      render={({ ObjetoSAP }) => (
                        <Autocomplete
                          id="objetoSAP"
                          options={maestroObjetoSAP}
                          getOptionLabel={(option) =>
                            codigoTipoObjetoSAP === 3
                              ? option.Grafo +
                                "-" +
                                option.TextoExplicativo +
                                "-" +
                                option.GrupoPlanificador
                              : option.CodigoCentroCosto +
                                "-" +
                                option.Descripcion
                          }
                          value={valueAutoCompleteObjetoSAP}
                          onChange={(event, newValue) =>
                            handleOnChangeObjetoSAP(event, newValue)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Objeto SAP"
                              variant="outlined"
                            />
                          )}
                        />
                      )}
                      control={control}
                    />
                  </FormControl>

                  <FormControl className={classes.formControlSelects}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid container justify="flex-start">
                        <Typography>
                          Para personal de planilla, los cambios deben
                          realizarse únicamente día lunes
                        </Typography>
                        <KeyboardDatePicker
                          disableToolbar
                          variant="inline"
                          format="dd/MM/yyyy"
                          margin="normal"
                          id="date-picker-inline"
                          label="Fecha para Ejecución"
                          value={fechaEjecucion}
                          onChange={handleDateChangeFechaCreacion}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          required
                          autoOk={true}
                          shouldDisableDate={validarRangoDeFechas}
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </FormControl>
                  <FormControl className={classes.formControlSelects}>
                    <Controller
                      name="comentario"
                      render={({ Comentario }) => (
                        <TextField
                          id="comentario"
                          label="Comentario"
                          name="comentario"
                          value={comentario}
                          onChange={(e) => setComentario(e.target.value)}
                          variant="outlined"
                          helperText={
                            fieldsErrors.comentario
                              ? fieldsErrors.comentario.message
                              : ""
                          }
                          multiline
                          maxRows={4}
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} md={12} lg={12}>
                  <Button
                    width="100%"
                    variant="contained"
                    style={{
                      backgroundColor: "#69bd4b",
                      marginBottom: 10,
                      marginTop: 10,
                      color: "white",
                      width: "93%",
                      marginLeft: 30,
                    }}
                    type="submit"
                  >
                    &nbsp;Guardar Cambios
                  </Button>
                </Grid>
              </form>
            </Grid>
          </div>
        </Fade>
      </Modal>
      {/* Grid Empleados Asignados Actualmente */}
      <>
        <Typography variant="h5">Buscar Empleados</Typography>
        <br />

        <Grid container spacing={3}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit(filtrarPorGerencia, cuandoHayError)}
          >
            <Grid item xs={6} sm={6} md={12} lg={12}>
              <FormControl className={classes.formControlSelects2}>
                <Controller
                  name="gerencia"
                  required
                  render={({ Gerencia }) => (
                    <Autocomplete
                      id="gerencia"
                      options={maestroGerencia}
                      getOptionSelected={(option, value) =>
                        option.CodigoGerencia === value.CodigoGerencia
                      }
                      getOptionLabel={(option) => option.Gerencia || ""}
                      value={valueAutoCompleteGerencia}
                      onChange={(event, newValue) =>
                        handleOnChangeGerencia(event, newValue)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Gerencia"
                          variant="outlined"
                        />
                      )}
                    />
                  )}
                  control={control}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={6} md={12} lg={12}>
              <Button
                width="100%"
                variant="contained"
                style={{
                  backgroundColor: "#69bd4b",
                  marginBottom: 10,
                  marginTop: 10,
                  color: "white",
                  width: "93%",
                  marginLeft: 30,
                }}
                type="submit"
              >
                &nbsp;Filtrar
              </Button>
            </Grid>
          </form>
        </Grid>
        <Paper className={classes.rootTabla}>
          <DataGrid
            dataSource={listadoEmpleadoNoAsignado}
            //   defaultColumns={this.props.columns}
            showBorders={true}
            rowAlternationEnabled={true}
            //ref={(ref) => (dataGrid = ref)}
            allowColumnReordering={true}
            allowColumnResizing={true}
            columnAutoWidth={true}
            onExporting={onExporting}
            className={classes.table}
            onInitialized={onInitialized}
            onSelectionChanged={onSelectionChanged}
          >
            <FilterRow visible={showFilterRow} applyFilter={currentFilter} />
            <HeaderFilter visible={showHeaderFilter} />
            <GroupPanel visible={false} />
            <Grouping autoExpandAll={autoExpandAll} />
            <SearchPanel visible={false} width={240} placeholder="Search..." />
            {/* <Selection mode="multiple" /> */}
            <LoadPanel
              enabled={true}
              shadingColor="rgba(0,0,0,0.4)"
              showIndicator={true}
              shading={true}
              showPane={true}
              closeOnOutsideClick={false}
            />
            <Editing
              mode="row"
              useIcons={true}
              allowUpdating={false}
              allowDeleting={false}
            />

            {/* <RowDragging
            data={1}
            group="tasksGroup"
            onAdd={agregarEmpleadoAPlaza}
          /> */}
            <Selection mode="multiple" />
            <Column dataField="DPI" caption="DPI" />
            <Column dataField="CodigoAnterior" caption="Codigo" />
            <Column dataField="NombreCompleto" caption="Nombre Completo" />
            <Column dataField="PuestoLaboral" caption="Puesto Laboral" />
            <Column dataField="Expertis" caption="Expertis" />
            <Column
              dataField="FechaAltaReal"
              caption="Fecha de Alta"
              dataType="date"
              format="dd/MM/yyyy"
            />
            <Column dataField="EstadoEmpleado" caption="Estado Actual" />

            <ColumnFixing enabled={true} />
            <Sorting mode="multiple" />
            <Paging defaultPageSize={20} />
            <Pager
              showPageSizeSelector={true}
              allowedPageSizes={[10, 20, 50]}
              showInfo={true}
            />
            <Export enabled={true} allowExportSelectedData={true} />
            <Scrolling columnRenderingMode="virtual" />
          </DataGrid>
          <div className={classes.selectedData}>
            <span className={classes.selectedDataCaption}>
              Empleados seleccionados:
            </span>{" "}
            <span>{selectedEmployeeNames}</span>
          </div>
        </Paper>
        <br />
      </>
      {props.editarCompleto && (
        <React.Fragment>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {/* <input type="submit" /> */}
            <Tooltip title="Trasladar al departamento">
              {/* <IconButton aria-label="aplicar" className={classes.margin}>
                      <SearchIcon fontSize="large" />
                    </IconButton> */}
              <Button
                // fullWidth
                variant="contained"
                style={{
                  backgroundColor: "#69BD4B",
                  color: "white",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                // type="submit"
                size="small"
                onClick={handleAbrirDialogoConfirmacion}
                startIcon={<TransferWithinAStation />}
              >
                Trasladar
              </Button>
            </Tooltip>

            <Tooltip title="Alta de Empleado">
              <Button
                // fullWidth
                variant="contained"
                style={{
                  marginLeft: "5px",
                  backgroundColor: "#69BD4B",
                  color: "white",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={handleAbrirDialogoAlta}
                size="small"
                startIcon={<Check />}
              >
                Alta
              </Button>
            </Tooltip>
          </Grid>
        </React.Fragment>
      )}
      <Typography variant="h6">Acciones</Typography>
    </React.Fragment>
  );
};

export default MovimientoPersonal;
