import React, { useState, useEffect } from 'react'
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  FormControl,
  Select,
  InputLabel,
  TextField,
  MenuItem
} from '@material-ui/core'
import LoadingButton from '../utils/LoadingButton'
import { ToastContainer, toast } from 'react-toastify'
import api from '../../../utils/api'
import { getUsuarioInserta } from '../../utils/utilidades'

const CentroDocumentalEditar = ({ data, onClose, onRefresh, update=false }) => {
  const [saving, setSaving] = useState(false)
  const [macroproceso, setMacroproceso] = useState('')
  const [division, setDivision] = useState('')
  const [correlativo, setCorrelativo] = useState('')
  const [ubicacion, setUbicacion] = useState('')
  const [macroprocesos, setMacroprocesos] = useState([])
  const [masters, setMasters] = useState({
    divisiones: [],
    macroprocesos: []
  })
  const insertCentroDocumental = async () => {
    if (!validate()) {
      return
    }
    try {
      setSaving(true)
      const datos = {
        IdDivision: division,
        IdMacroproceso: macroproceso,
        Correlativo: correlativo,
        Ubicacion: ubicacion,
        UsuarioInserto: getUsuarioInserta()
      }
      await api.realizarPeticionPostPut('api/documentos/centro-documental/crear', datos, 'POST')
      onRefresh()
    } catch (error) {
      toast.error('No se pudo guardar el registro')
    } finally {
      setSaving(false)
    }
  }
  const updateCentroDocumental = async () => {
    if (!validate()) {
      return
    }
    try {
      setSaving(true)
      const datos = {
        IdDivision: division,
        IdMacroproceso: macroproceso,
        Correlativo: correlativo,
        Ubicacion: ubicacion,
        UsuarioModifico: getUsuarioInserta()
      }
      await api.realizarPeticionPostPut(`api/documentos/centro-documental/editar/${data.IdCentroDocumental}`, datos, 'POST')
      onRefresh()
    } catch (error) {
      toast.error('No se pudo guardar los cambios')
    } finally {
      setSaving(false)
    }
  }
  const validate = () => {
    let valido = true
    if (!division) {
      toast.error('Selecciona una división')
      valido = false
    }
    if (!macroproceso) {
      toast.error('Selecciona un macroproceso')
      valido = false
    }
    if (!correlativo) {
      toast.error('Ingresa un correlativo')
      valido = false
    }
    if (!ubicacion) {
      toast.error('Ingresa la ubicación')
      valido = false
    }
    return valido
  }
  const handleSubmit = () => {
    if (update) {
      updateCentroDocumental()
    } else {
      insertCentroDocumental()
    }
  }
  const getMasters = async () => {
    try {
      const reqDivisiones = api.fetchGetRequest('api/documentos/division')
      const reqMacroprocesos = api.fetchGetRequest('api/documentos/macroproceso')
      const requests = [
        reqDivisiones,
        reqMacroprocesos
      ]
      const response = await Promise.all(requests)
      setMasters({
        divisiones: response[0].data,
        macroprocesos: []
      })
      setMacroprocesos(response[1].data)
    } catch (error) {
      toast.error('Ha ocurrido un error al obtener los datos')
    }
  }
  useEffect(() => {
    if (division) {
      const tmp = { ...masters }
      setMasters({
        ...tmp,
        macroprocesos: macroprocesos.filter(i => i.IdDivision === division)
      })
    }
  }, [division])
  useEffect(() => {
    (async () => {
      await getMasters()
      if (update) {
        setDivision(data.IdDivision)
        setCorrelativo(data.Correlativo)
        setUbicacion(data.Ubicacion)
        setMacroproceso(data.IdMacroproceso)
      }
    })()
  }, [])

  return <>
    <DialogTitle>Crear centro documental</DialogTitle>
    <DialogContent>
      <Grid container>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>División</InputLabel>
            <Select
              fullWidth
              value={division}
              onChange={ev => setDivision(ev.target.value)}
            >
              {masters.divisiones.map((i, idx) => <MenuItem key={idx} value={i.Id}>
                {i.NombreDivision}
              </MenuItem>)}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>Macroproceso</InputLabel>
            <Select
              fullWidth
              value={macroproceso}
              onChange={ev => setMacroproceso(ev.target.value)}
            >
              {masters.macroprocesos.map((i, idx) => <MenuItem key={idx} value={i.IdMacroproceso}>
                {i.NombreMacroproceso}
              </MenuItem>)}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Correlativo"
            value={correlativo}
            type="number"
            onChange={(ev) => setCorrelativo(ev.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Ubicación"
            value={ubicacion}
            onChange={(ev) => setUbicacion(ev.target.value)}
          />
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button variant="contained" onClick={onClose} disabled={saving}>Cancelar</Button>
      <LoadingButton
        loading={saving}
        color="primary"
        variant="contained"
        onClick={handleSubmit}
      >Guardar</LoadingButton>
    </DialogActions>
  </>
}

export default CentroDocumentalEditar
