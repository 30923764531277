import React from "react";
import ReactDOM from "react-dom";
import SuperTreeview from "react-super-treeview";

import "../components/treeview-style.css";
import transitions from "@material-ui/core/styles/transitions";
import API from "../../utils/api";

class TaskTree extends React.Component {
  constructor() {
    super();
    // SET YOUR DATA
    this.state = {
      codigoPlantillaTareas: "",
      codigoProblema: "",
      data: [
        // {
        //   id: 1,
        //   name: "Parent A",
        // },
        // {
        //   id: 2,
        //   name: "Parent B",
        //   isExpanded: true,
        //   isChecked: true,
        //   activo: true,
        //   orden: 2,
        //   children: [
        //     {
        //       id: 1,
        //       name: "Child 1",
        //     },
        //     {
        //       id: 2,
        //       name: "Child 2",
        //       children: [
        //         {
        //           id: 3,
        //           name: "Child 3",
        //         },
        //         {
        //           id: 4,
        //           name: "Child 4",
        //           children: [
        //             {
        //               id: 5,
        //               name: "Child 5",
        //             },
        //             {
        //               id: 6,
        //               name: "Child 6",
        //             },
        //           ],
        //         },
        //       ],
        //     },
        //   ],
        // },
      ],
    };
  }

  componentWillMount() {
    this.setState(
      {
        // data: this.props.data,
        codigoPlantillaTareas: this.props.codigoPlantillaTareas,
        codigoProblema: this.props.codigoProblema,
      },
      () => {
        this.cargarListaArbolTareas();
      }
    );
  }

  componentDidUpdate() {
    if (this.state.codigoPlantillaTareas !== this.props.codigoPlantillaTareas) {
      this.setState(
        {
          codigoPlantillaTareas: this.props.codigoPlantillaTareas,
          codigoProblema: this.props.codigoProblema,
        },
        () => {
          this.cargarListaArbolTareas();
        }
      );
    }
  }

  cargarListaArbolTareas = async () => {
    let parent = this.state.codigoPlantillaTareas;
    console.log("TASKTREE");
    console.log("plantillaTareas:", this.state.codigoPlantillaTareas);
    console.log("CodigoProblema:", this.state.codigoProblema);

    const request = await API.fetchGetRequest(
      `api/flujo/listaArbolTareas/${parent}/${this.state.codigoProblema}`
    );
    try {
      if (Array.isArray(request.data.data)) {
        this.setState({
          // arbolTareas: JSON.parse(request.data.data),
          data: request.data.data,
        });
      } else {
        this.setState({
          data: [],
        });
      }
    } catch (error) {
      console.log("cargarListaArbolTareas error:" + error);
    }
  };

  render() {
    return (
      // RENDER THE COMPONENT
      <SuperTreeview
        data={this.state.data}
        onUpdateCb={(updatedData) => {
          this.setState({ data: updatedData });
        }}
        isCheckable={(node, depth) => {
          return false;
        }}
        isDeletable={(node, depth) => {
          return false;
        }}
        onExpandToggleCb={(node, depth) => {
          const status = node.isExpanded ? "open" : "close";
          //Si el nodo está cerrado, al dar clic carga lista de elementos hijos

          this.props.cargarListaTareas(node.id, node.name,node.llave);
          // if(node.isExpanded){
          // this.props.cargarListaTareas(node.id, node.name,node.llave);
          // }
        }}

        
      />
    );
  }
}

export default TaskTree;
