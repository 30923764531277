import React, { useState, useEffect } from 'react'
import {
  Grid,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  IconButton,
} from '@material-ui/core'
import { GetApp, Edit } from '@material-ui/icons'
import { toast } from 'react-toastify'
import Api from '../../../utils/api'
import { getPlantillaBase64 } from '../../utils/media'

const PlantillasList = () => {
  const [items, setItems] = useState([])
  const getPlantillas = async () => {
    const { data } = await Api.fetchGetRequest('api/documentos/plantilla')
    setItems(data.map(i => ({...i, Nombre: i.Nombre.trim()})))
  }
  const download = async (id) => {
    try {
      const item = items.find(i=> i.Id ===  id)
      let base64str = await getPlantillaBase64(item.Id)
      base64str = base64str.replace('data:application/octet-stream;base64,', '')
      const linkSource = `data:application/docx;base64,${base64str}`;
      const downloadLink = document.createElement("a");
      const fileName = `${item.Nombre}.docx`;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    } catch (error) {
      toast.error('Ha ocurrido un error')
    }
  }
  useEffect(() => {
    getPlantillas()
  }, [])
  return <>
    <Grid container justify="center">
      <Grid item xs={12} md={6}>
        {/* <Typography variant="h5" component="h1">Entornos</Typography> */}
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>No.</TableCell>
                <TableCell>Sistema</TableCell>
                <TableCell>Opciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                items.map((i, idx) =>
                <TableRow key={idx}>
                  <TableCell>{idx + 1}</TableCell>
                  <TableCell>{i.Nombre}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => download(i.Id)}>
                      <GetApp />
                    </IconButton>
                  </TableCell>
                </TableRow>)
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  </>
}

export default PlantillasList