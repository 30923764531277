import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Modal,
  Backdrop,
  CircularProgress,
  TextField,
  makeStyles,
  Fade,
  Grid,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Card,
  CardContent,
  Typography,
  CardActions,
  Tooltip,
} from "@material-ui/core";
import DataGrid, {
  Column,
  ColumnFixing,
  Editing,
} from "devextreme-react/data-grid";
import { green } from "material-ui/colors";
import { Check, FaceRounded } from "@material-ui/icons";
import { useForm, Controller } from "react-hook-form";
import { Autocomplete } from "@material-ui/lab";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

//componentes propios
import { signOut } from "../../utils/auth";
import { isSignedIn } from "../../utils/auth";
import API from "../../utils/api";

const width_proportion = "100%";

const useStyles = makeStyles((theme) => ({
  table: {
    width: "100%",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 210,
  },
  textFieldDireccion: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 230,
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  greenAvatar: {
    fontSize: "2.5em",
    color: "#fff",
    // borderStyle:'solid',
    // borderWidth:'1 !important',
    // borderColor:'#3f51b5 !important',
    backgroundColor: green[500],
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  paperCard: {
    padding: 20,
    width: "100%",
  },
  formControlSelects: {
    // margin: theme.spacing(1),
    // minWidth: 480,

    marginBottom: 10,
    marginTop: 10,
    width: "90%",
    marginLeft: 20,
  },
  grid: {
    marginTop: 10,
    marginBottom: 10,
  },
  formControlSelects2: {
    // margin: theme.spacing(1),
    minWidth: 480,

    marginBottom: 10,
    marginTop: 10,
    // width: "90%",
    marginLeft: 20,
  },

  formControlSelect: {
    // margin: theme.spacing(1),
    width: width_proportion,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  rootTabla: {
    width: "100%",
    marginTop: theme.spacing.unit * 1,
    overflowX: "auto",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paperModal: {
    position: "absolute",
    width: "70%",
    maxHeight: "95vh", // Altura máxima del modal
    overflowY: "auto", // Hacer que el contenido sea desplazable
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  textoEncabezado: {
    fontSize: 14,
    fontWeight: "bold",
    color: "black",
  },
}));

const AltasReclutamientoComponente = (props) => {
  const classes = useStyles();

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors: fieldsErrors },
    register,
  } = useForm();

  const monedaFormat = {
    style: "currency",
    currency: "GTQ",
    // useGrouping: true,
    minimumSignificantDigits: 3,
  };

  const porcentajeFormat = {
    type: "fixedPoint",
    format: "#0.##'%'",
    // useGrouping: true,
    // minimumSignificantDigits: 3,
  };

  function validarRangoDeFechas(date) {
    var hoy = new Date();
    var fechaAnterior = new Date(new Date().setDate(hoy.getDate() - 30));
    var fechaPosterior = new Date(new Date().setDate(hoy.getDate() + 45));
    return !(date > fechaAnterior && date < fechaPosterior);
  }

  const [empleadosSeleccion, setEmpleadoSeleccion] = useState([]);
  const [listadoEmpleadoNoAsignado, setListadoEmpleadoNoAsignado] = useState(
    []
  );
  const [listadoPlazaLaboral, setListadoPlazaLaboral] = useState([]);
  const [userName, setUserName] = useState("");
  const [openDialogoAlta, setOpenDialogoAlta] = useState(false);
  const [buscando, setBackDrop] = useState(false);

  const [openPL, setOpenPL] = useState(false);

  const [maestroExpertis, setMestroExpertis] = useState([]);
  const [codigoExpertis, setCodigoExpertis] = useState(0);
  const [valueAutoCompleteExpertis, setValueAutoCompleteExpertis] =
    useState(null);

  const [maestroTipoTurno, setMaestroTipoTurno] = useState([]);

  const [codigoTipoTurno, setCodigoTipoTurno] = useState(0);
  const [valueAutoCompleteTipoTurno, setValueAutoCompleteTipoTurno] =
    useState(null);

  const [codigoTipoMovimiento, setCodigoTipoMovimiento] = useState(0);

  const [maestroJefeInmediato, setMaestroJefeInmediato] = useState([]);
  const [dpiJefeInmediato, setDpiJefeInmediato] = useState("");
  const [valueAutoCompleteJefeInmediato, setValueAutoCompleteJefeInmediato] =
    useState(null);

  const [maestroPlazas, setMaestroPlazas] = useState([]);
  const [codigoPlazaLaboral, setCodigoPlazaLaboral] = useState(0);
  const [valueAutoCompletePlazaLaboral, setValueAutoCompletePlazaLaboral] =
    useState(null);
  const [plazaLaboral, setPlazaLaboral] = useState("");
  const [itemPlazaLaboral, setItemPlazaLaboral] = useState(null);

  const [maestroTipoObjetoSAP, setMaestroTipoObjetoSAP] = useState([]);
  const [codigoTipoObjetoSAP, setCodigoTipoObjetoSAP] = useState(0);
  const [valueAutoCompleteTipoObjetoSAP, setValueAutoCompleteTipoObjetoSAP] =
    useState(null);

  const [maestroObjetoSAP, setMaestroObjetoSAP] = useState([]);
  const [objetoSAP, setObjetoSAP] = useState("");
  const [textoObjetoSAP, setTextoObjetoSAP] = useState("");
  const [valueAutoCompleteObjetoSAP, setValueAutoCompleteObjetoSAP] =
    useState(null);

  const [maestroMotivoMovimientoPersonal, setMaestroMotivoMovimientoPersonal] =
    useState([]);
  const [codigoMotivoMovimientoPersonal, setCodigoMotivoMovimientoPersonal] =
    useState(null);
  const [
    valueAutoCompleteMotivoMovimientoPersonal,
    setValueAutoCompleteMotivoMovimientoPersonal,
  ] = useState(null);

  const [maestroRazonMovimientoPersonal, setMaestroRazonMovimientoPersonal] =
    useState([]);
  const [codigoRazonMovimientoPersonal, setCodigoRazonMovimientoPersonal] =
    useState(null);
  const [
    valueAutoCompleteRazonMovimientoPersonal,
    setValueAutoCompleteRazonMovimientoPersonal,
  ] = useState(null);

  const [fechaEjecucion, setFechaEjecucion] = useState(new Date());
  const [comentario, setComentario] = useState("");

  const [
    maestroAgrupacionDePagoTipoNomina,
    setMaestroAgrupacionDePagoTipoNomina,
  ] = useState([]);
  const [
    valueAutoCompleteAgrupacionDePagoTipoNomina,
    setValueAutoCompleteAgrupacionDePagoTipoNomina,
  ] = useState(null);
  const [
    codigoAgrupacionDePagoTipoNomina,
    setCodigoAgrupacionDePagoTipoNomina,
  ] = useState(0);
  const [esRequeridoObjetoSAP, setEsRequeridoObjetoSAP] = useState(false);

  const [maestroCentroDePago, setMaestroCentroDePago] = useState([]);
  const [valueAutoCompleteCentroDePago, setValueAutoCompleteCentroDePago] =
    useState(null);
  const [codigoCentroDePago, setCodigoCentroDePago] = useState(0);
  const [selectedEmployeeNames, setSelectedEmployeeNames] = useState(
    "No hay empleados seleccionados"
  );

  const [tipoNomina, setTipoNomina] = useState("planilla");

  const [detallePromocion, setDetallePromocion] = useState([]);
  const [salarioAnterior, setSalarioAnterior] = useState(0);
  const [salarioSolicitado, setSalarioSolicitado] = useState(0);

  const handleChangeRadio = (event) => {
    setTipoNomina(event.target.value);
  };

  // Función para manejar la selección de un empleado
  const handleEmployeeSelection = (employeeId) => {
    if (empleadosSeleccion.includes(employeeId)) {
      setEmpleadoSeleccion(
        empleadosSeleccion.filter((id) => id !== employeeId)
      );
    } else {
      setEmpleadoSeleccion([...empleadosSeleccion, employeeId]);
    }
  };

  const handleCerrarBackDrop = () => {
    setBackDrop(false);
  };
  const handleAbrirBackDrop = () => {
    setBackDrop(true);
  };

  const handleOpenModalPuestoLaboral = () => {
    setOpenPL(true);
  };

  const handleCloseModalPuestoLaboral = () => {
    setOpenPL(false);
  };

  const cargarMaestroAgrupacionDePagoTipoNomina = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/Empleado/MAestroAgrupacionDePagoTipoNomina";

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setMaestroAgrupacionDePagoTipoNomina(request.data.data);
      }
    } catch (e) {
      console.log(e);
      toast.error("Ha ocurrido un error: " + e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cargarMaestroCentroDePago = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/Empleado/MAestroCentroDePago";

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setMaestroCentroDePago(request.data.data);
      }
    } catch (e) {
      console.log(e);
      toast.error("Ha ocurrido un error: " + e);
    } finally {
      handleCerrarBackDrop();
    }
  };
  const cargarEmpleadoPorUnidad = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri =
        "api/Reclutamiento/Contratados/" + props.codigoUnidadOrganizacion;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setListadoEmpleadoNoAsignado(request.data.ficha);
        setListadoPlazaLaboral(request.data.ficha2);
      }
    } catch (e) {
      console.log(e);
      toast.error("Ha ocurrido un error: " + e);
    } finally {
      //   deseleccionarItemsEnGridEmpleados();
      handleCerrarBackDrop();
    }
  };

  const cargarMaestroMotivoMovimientoPersonal = async () => {
    try {
      handleAbrirBackDrop();

      let uri = "api/MotivoMovimientoPersonal/" + codigoTipoMovimiento;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setMaestroMotivoMovimientoPersonal(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
      toast.error("Ha ocurrido un error: " + e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const handleAbrirDialogoAlta = async () => {
    console.log(empleadosSeleccion);
    if (empleadosSeleccion.length === 0) {
      toast.info("Por favor seleccione al menos un empleado", {
        position: "top-right",
        autoClose: 4000, // Esta en milisegundos 3 segundos
      });
      return;
    }
    let idPlazaLaboral = 0;
    let textoPlazaLaboral = "";
    let itemPlazaLaboral = null;

    if (empleadosSeleccion.length >= 1) {
      // verificar que todos los empleados seleccionados sean a la misma plaza, plazas diferentes no se pueden mover juntas
      let hayPlazasDiferentes = false;
      itemPlazaLaboral = listadoEmpleadoNoAsignado.find(
        (w) => w.postulante.dpi === empleadosSeleccion[0]
      ).pl;

      idPlazaLaboral = listadoEmpleadoNoAsignado.find(
        (w) => w.postulante.dpi === empleadosSeleccion[0]
      ).pl.codigoPlazaLaboral;
      // console.log("idPlazaLaboral", idPlazaLaboral);
      // console.log(listadoPlazaLaboral);

      textoPlazaLaboral = listadoPlazaLaboral.find(
        (w) => w.CodigoPlazaLaboral === idPlazaLaboral
      )
        ? listadoPlazaLaboral.find(
            (w) => w.CodigoPlazaLaboral === idPlazaLaboral
          ).MATPuestoLaboral.PuestoLaboral
        : "";

      empleadosSeleccion.forEach((empleado) => {
        const e = listadoEmpleadoNoAsignado.find(
          (w) => w.postulante.dpi === empleado
        );

        if (e.pl.codigoPlazaLaboral !== idPlazaLaboral) {
          hayPlazasDiferentes = true;
        }
      });

      if (hayPlazasDiferentes) {
        toast.info("Por favor seleccione empleados de la misma plaza", {
          position: "top-right",
          autoClose: 4000, // Esta en milisegundos 3 segundos
        });
        return;
      }
    }

    const valido = await verificarEmpleado(empleadosSeleccion);
    if (valido.response) {
      handleOpenModalPuestoLaboral();
      setCodigoExpertis(0);
      setValueAutoCompleteExpertis(null);
      setCodigoTipoMovimiento(4);
      setValueAutoCompleteTipoTurno(null);
      setCodigoTipoTurno(null);
      // setValueAutoCompletePlazaLaboral(null);
      setCodigoPlazaLaboral(idPlazaLaboral);
      setPlazaLaboral(textoPlazaLaboral);
      setItemPlazaLaboral(itemPlazaLaboral);
      console.log(itemPlazaLaboral);
      setValueAutoCompleteJefeInmediato(null);
      setDpiJefeInmediato("");
      setComentario("");
    } else {
      toast.info(
        "Hay empleados que actualmente estan en una solicitud de movimiento: " +
          valido.ficha,
        {
          position: "top-right",
          autoClose: 4000, // Esta en milisegundos 3 segundos
        }
      );
    }
  };

  const cargarMaestroTipoTurno = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/TipoTurno/";

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setMaestroTipoTurno(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
      toast.error("Ha ocurrido un error: " + e);
      toast.error("Ha ocurrido un error: " + e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cargarMaestroTipoObjetoSAP = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/TipoObjetoSAP/";

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setMaestroTipoObjetoSAP(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
      toast.error("Ha ocurrido un error: " + e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cargarMaestroObjetoSAP = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/TipoObjetoSAP/" + codigoTipoObjetoSAP;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        if (request.data.response) {
          setMaestroObjetoSAP(request.data.ficha);
        } else {
          setMaestroObjetoSAP([]);
          toast.error(
            "Hubo un error al cargar la información de los objetos SAP." +
              request.data.mensaje
          );
        }
      }
    } catch (e) {
      console.log(e);

      toast.error("Ha ocurrido un error: " + e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cargarMaestroExpertis = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/Expertis/";

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setMestroExpertis(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const verificarEmpleado = async (pDPI) => {
    try {
      handleAbrirBackDrop();

      let uri = "api/EmpleadoSolicitudMovimiento/ComprobarEmpleado/PorDPI";

      const request = await API.post(uri, pDPI);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        return request.data;
      }
    } catch (e) {
      console.log(e);
      toast.error("Ha ocurrido un error: " + e);
      return false;
    } finally {
      handleCerrarBackDrop();
    }
  };

  const guardarDato = async () => {
    const data = {
      ListadoEmpleado: empleadosSeleccion.map((empleado) => ({
        DPI: empleado,
      })),
      DPIJefeInmediato: dpiJefeInmediato,
      CodigoTurno: codigoTipoTurno,
      CodigoTipoMovimientoPersonal: codigoTipoMovimiento,
      CodigoEstadoMovimientoPersonal: 1,
      CodigoPlazaLaboral: codigoPlazaLaboral,
      FechaEjecución: fechaEjecucion,
      Referencia: 0,
      Activo: true,
      Eliminado: false,
      UsuarioInserto: userName,
      FechaInserto: "",
      UsuarioModifico: "",
      FechaModifico: "",
      CodigoTipoObjetoSAP: codigoTipoObjetoSAP,
      TipoObjetoSAP: objetoSAP,
      TextoObjetoSAP: textoObjetoSAP,
      Comentario: comentario,
      CodigoAgrupacionDePagoTipoNomina: codigoAgrupacionDePagoTipoNomina,
      AgrupacionDePagoTipoNomina:
        valueAutoCompleteAgrupacionDePagoTipoNomina.AgrupacionDePagoTipoNomina,
      CodigoCentroDePago: codigoCentroDePago,
      CentroDePago: valueAutoCompleteCentroDePago.CentroDePago,
      CodigoMotivoMovimientoPersonal: codigoMotivoMovimientoPersonal,
      CodigoExpertis: codigoExpertis,
      SalarioSolicitado: tipoNomina === "planilla" ? salarioSolicitado : 0,
    };

    console.log(data);
    // dataFicha.usuarioInserto = "prueba";
    try {
      handleAbrirBackDrop();
      let vIsSignedIn = await isSignedIn();
      if (vIsSignedIn.response) {
        let request;

        request = await API.post("api/EmpleadoSolicitudMovimiento/", data);
        if (request.status !== 200) {
          toast.error(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        } else {
          toast.success("Se guardó exitosamente");

          await props.actualizarListaSolicitudes();

          //   setCodigoGerencia(0);
          //   setCodigoUnidadOrganizacion(0);

          //   deseleccionarItemsEnGridEmpleados();

          setComentario("");
        }
      }
    } catch (e) {
      console.log("EXCEPTION: " + e);
      toast.error(
        "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
      );
    } finally {
      handleCerrarBackDrop();
      handleCloseModalPuestoLaboral();
    }
  };

  const MaestroJefeInmediato = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri =
        "api/EmpleadoJefeInmediato/PorPlazaLaboral/" + codigoPlazaLaboral;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setMaestroJefeInmediato(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
      toast.error("Ha ocurrido un error: " + e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const obtenerDatosPromocion = async () => {
    const data = {
      ListadoEmpleado: empleadosSeleccion.map((empleado) => ({
        DPI: empleado,
      })),
      CodigoPlazaLaboral: codigoPlazaLaboral,
      CodigoExpertis: codigoExpertis,
    };

    console.log(data);
    // dataFicha.usuarioInserto = "prueba";
    try {
      handleAbrirBackDrop();
      let vIsSignedIn = await isSignedIn();
      if (vIsSignedIn.response) {
        let request;

        request = await API.post(
          "api/EmpleadoSolicitudMovimiento/ProyeccionSalarioAlta",
          data
        );
        if (request.status !== 200) {
          toast.error(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        } else {
          if (request.data.response) {
            setDetallePromocion(request.data.ficha);
            setSalarioAnterior(request.data.ficha[0].SalarioAnterior);
            setSalarioSolicitado(request.data.ficha[0].SalarioSolicitado);
          } else {
            toast.info(request.data.mensaje);
            setDetallePromocion([]);
          }
        }
      }
    } catch (e) {
      console.log("EXCEPTION: " + e);
      toast.error(
        "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
      );
    } finally {
      handleCerrarBackDrop();
      //   props.handleCloseModalPuestoLaboral();
    }
  };

  const cuandoHayError = async (data) => {
    toast.info(
      "Existen algunos campos con error, por favor valida el formulario",
      {
        position: "top-right",
        autoClose: 3000, // Esta en milisegundos 3 segundos
      }
    );
  };
  // Datos de ejemplo de los empleados
  useEffect(() => {
    const cargar = async () => {
      // CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
      // FIN DE CONSULTA DE PERMISOS

      handleAbrirBackDrop();

      //   await cargarMaestroGerencia();
      await cargarEmpleadoPorUnidad();
      await cargarMaestroTipoObjetoSAP();
      await cargarMaestroTipoTurno();
      await MaestroJefeInmediato();
      await cargarMaestroAgrupacionDePagoTipoNomina();
      await cargarMaestroCentroDePago();
      await cargarMaestroExpertis();
      //   await cargarEmpleadoAsignadoPorUnidadOrganizacion();
      handleCerrarBackDrop();
    };
    // console.log("codigoUnidadOrganizacion", props.codigoUnidadOrganizacion);
    if (props.codigoUnidadOrganizacion > 0) {
      cargar();
    }
  }, [props.codigoUnidadOrganizacion]);

  useEffect(() => {
    const cargar = async () => {
      handleAbrirBackDrop();

      if (codigoTipoMovimiento > 0) {
        await cargarMaestroMotivoMovimientoPersonal();
        setValueAutoCompleteMotivoMovimientoPersonal(null);
        setCodigoMotivoMovimientoPersonal("");
      }

      handleCerrarBackDrop();
    };

    cargar();
  }, [codigoTipoMovimiento]);

  useEffect(() => {
    const cargar = async () => {
      handleAbrirBackDrop();

      if (codigoPlazaLaboral > 0) {
        await MaestroJefeInmediato();
        setValueAutoCompleteJefeInmediato(null);
        setDpiJefeInmediato("");
      }

      handleCerrarBackDrop();
    };

    cargar();
  }, [codigoPlazaLaboral]);

  useEffect(() => {
    const cargar = async () => {
      handleAbrirBackDrop();

      if (codigoTipoObjetoSAP > 0) {
        await cargarMaestroObjetoSAP();
        setValueAutoCompleteObjetoSAP(null);
        setObjetoSAP("");
        setTextoObjetoSAP("");
      }

      handleCerrarBackDrop();
    };

    cargar();
  }, [codigoTipoObjetoSAP]);

  useEffect(() => {
    const cargar = async () => {
      handleAbrirBackDrop();

      if (
        codigoPlazaLaboral > 0 &&
        tipoNomina === "planilla" &&
        codigoExpertis > 0
      ) {
        await obtenerDatosPromocion();
      }

      handleCerrarBackDrop();
    };

    cargar();
  }, [codigoPlazaLaboral, tipoNomina, codigoExpertis]);

  const handleOnChangeExpertis = async (event, newValue) => {
    handleAbrirBackDrop();
    if (newValue !== null) {
      setCodigoExpertis(newValue.CodigoExpertis);
      setValueAutoCompleteExpertis(newValue);
    }
    handleCerrarBackDrop();
  };

  const handleOnChangeTipoTurno = async (event, newValue) => {
    handleAbrirBackDrop();
    if (newValue !== null) {
      setCodigoTipoTurno(newValue.CodigoTipoTurno);
      setValueAutoCompleteTipoTurno(newValue);
    }
    handleCerrarBackDrop();
  };

  const handleOnChangeJefeInmediato = async (event, newValue) => {
    handleAbrirBackDrop();
    if (newValue !== null) {
      setDpiJefeInmediato(newValue.DPI);
      setValueAutoCompleteJefeInmediato(newValue);
    }
    handleCerrarBackDrop();
  };

  const handleOnChangePlazaLaboral = async (event, newValue) => {
    handleAbrirBackDrop();
    if (newValue !== null) {
      setCodigoPlazaLaboral(newValue.id);
      setValueAutoCompletePlazaLaboral(newValue);
    }
    handleCerrarBackDrop();
  };

  const handleOnChangeTipoObjetoSAP = async (event, newValue) => {
    handleAbrirBackDrop();
    if (newValue !== null) {
      setCodigoTipoObjetoSAP(newValue.CodigoTipoObjetoSAP);
      setValueAutoCompleteTipoObjetoSAP(newValue);
    }
    handleCerrarBackDrop();
  };

  const handleOnChangeObjetoSAP = async (event, newValue) => {
    handleAbrirBackDrop();
    if (newValue !== null) {
      setObjetoSAP(
        codigoTipoObjetoSAP === 3 ? newValue.Grafo : newValue.CodigoCentroCosto
      );
      setTextoObjetoSAP(
        codigoTipoObjetoSAP === 3
          ? newValue.TextoExplicativo
          : newValue.Descripcion
      );

      setValueAutoCompleteObjetoSAP(newValue);
    }
    handleCerrarBackDrop();
  };

  const handleDateChangeFechaCreacion = (date) => {
    setFechaEjecucion(date);
  };

  const handleOnChangeAgrupacionDePagoTipoNomina = async (event, newValue) => {
    handleAbrirBackDrop();
    if (newValue !== null) {
      setCodigoAgrupacionDePagoTipoNomina(
        newValue.CodigoAgrupacionDePagoTipoNomina
      );
      setValueAutoCompleteAgrupacionDePagoTipoNomina(newValue);
      setEsRequeridoObjetoSAP(newValue.EsRequeridoObjetoSAP);
      console.log(newValue.EsRequeridoObjetoSAP);
    }
    handleCerrarBackDrop();
  };

  const handleOnChangeCentroDePago = async (event, newValue) => {
    handleAbrirBackDrop();
    if (newValue !== null) {
      setCodigoCentroDePago(newValue.CodigoCentroDePago);
      setValueAutoCompleteCentroDePago(newValue);
    }
    handleCerrarBackDrop();
  };

  const handleOnChangeMotivoMovimientoPersonal = async (event, newValue) => {
    handleAbrirBackDrop();
    if (newValue !== null) {
      setCodigoMotivoMovimientoPersonal(
        newValue.CodigoMotivoMovimientoPersonal
      );
      setValueAutoCompleteMotivoMovimientoPersonal(newValue);
    }
    handleCerrarBackDrop();
  };

  const handleOnSavedGrid = async (e) => {
    try {
      handleAbrirBackDrop();

      console.log(e.changes[0].data.SalarioSolicitado);

      setSalarioSolicitado(e.changes[0].data.SalarioSolicitado);
    } catch (ex) {
      toast.error("Hubo un error al actualizar el nuevo salario");
    } finally {
      handleCerrarBackDrop();
    }
  };

  return (
    <React.Fragment>
      <Backdrop className={classes.backdrop} open={buscando}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {listadoEmpleadoNoAsignado && listadoEmpleadoNoAsignado.length > 0 ? (
        listadoEmpleadoNoAsignado.map((empleado, idx) => (
          <Card
            key={idx}
            onClick={() => handleEmployeeSelection(empleado.postulante.dpi)}
            style={{
              border: empleadosSeleccion.includes(empleado.postulante.dpi)
                ? "2px solid blue"
                : "1px solid gray",
              padding: "10px",
              margin: "10px",
              cursor: "pointer",
            }}
          >
            <CardContent>
              <Typography variant="subtitle1">
                <b>Plaza Laboral: </b>
                {listadoPlazaLaboral.find(
                  (w) => w.CodigoPlazaLaboral === empleado.pl.codigoPlazaLaboral
                )
                  ? listadoPlazaLaboral.find(
                      (w) =>
                        w.CodigoPlazaLaboral === empleado.pl.codigoPlazaLaboral
                    ).MATPuestoLaboral.PuestoLaboral
                  : ""}
              </Typography>
              <Typography>
                <b>DPI: </b>
                {empleado.postulante.dpi}
              </Typography>
              <Typography>
                <b>Primer Nombre: </b>
                {empleado.postulante.primerNombre}
              </Typography>
              <Typography>
                <b>Segundo Nombre: </b>
                {empleado.postulante.segundoNombre}
              </Typography>
              <Typography>
                <b>Primer Apellido: </b>
                {empleado.postulante.primerApellido}
              </Typography>
              <Typography>
                <b>Segundo Apellido: </b>
                {empleado.postulante.segundoApellido}
              </Typography>
            </CardContent>
            <CardActions></CardActions>
          </Card>
        ))
      ) : (
        <Typography>
          No hay empleados disponibles para alta desde Reclutamiento
        </Typography>
      )}
      {/* Agrega el botón o la lógica para iniciar el proceso de alta */}
      {props.editarCompleto && (
        <Tooltip title="Alta de Empleado">
          <Button
            // fullWidth
            // variant="contained"
            style={{
              marginLeft: "5px",
              backgroundColor: "#69BD4B",
              color: "white",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={handleAbrirDialogoAlta}
            size="small"
            startIcon={<Check />}
          >
            Alta
          </Button>
        </Tooltip>
      )}

      {/* Modal Traslado*/}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openPL}
        onClose={handleCloseModalPuestoLaboral}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openPL}>
          <div className={classes.paperModal}>
            <h2 id="simple-modal-title">
              Seleccione los parametros para el movimiento
            </h2>
            <Grid container spacing={3} className={classes.grid}>
              <form
                autoComplete="off"
                onSubmit={handleSubmit(guardarDato, cuandoHayError)}
              >
                <Grid item xs={6} sm={6} md={12} lg={12}>
                  <Typography variant="subheading">{plazaLaboral}</Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={12} lg={12}>
                  <FormControl className={classes.formControlSelects}>
                    <Controller
                      name="expertis"
                      required
                      render={({ expertis }) => (
                        <Autocomplete
                          id="expertis"
                          options={maestroExpertis}
                          getOptionLabel={(option) => option.Expertis}
                          value={valueAutoCompleteExpertis}
                          onChange={(event, newValue) =>
                            handleOnChangeExpertis(event, newValue)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Expertis"
                              variant="outlined"
                            />
                          )}
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
                  <FormControl
                    component="fieldset"
                    className={classes.formControlSelects}
                  >
                    <FormLabel component="legend">Tipo de Nómina</FormLabel>
                    <RadioGroup
                      aria-label="gender"
                      name="gender1"
                      value={tipoNomina}
                      onChange={handleChangeRadio}
                    >
                      <FormControlLabel
                        value="planilla"
                        control={<Radio />}
                        label="Planilla"
                      />
                      <FormControlLabel
                        value="nomina"
                        control={<Radio />}
                        label="Nómina"
                      />
                    </RadioGroup>
                  </FormControl>
                  {tipoNomina === "nomina" ? (
                    <Typography sx={{ fontWeight: "bold" }}>
                      La propuesta salarial se trabaja por carta salarial
                    </Typography>
                  ) : (
                    detallePromocion.length > 0 && (
                      <DataGrid
                        dataSource={detallePromocion}
                        //   defaultColumns={this.props.columns}
                        showBorders={true}
                        rowAlternationEnabled={true}
                        //ref={(ref) => (dataGrid = ref)}
                        allowColumnReordering={true}
                        allowColumnResizing={true}
                        columnAutoWidth={true}
                        onSaved={handleOnSavedGrid}
                      >
                        <Editing
                          mode="cell"
                          allowUpdating={true}
                          // allowAdding={true}
                          // allowDeleting={true}
                        />
                        {/* <Selection mode="multiple" /> */}
                        {/* <LoadPanel
                  enabled={true}
                  shadingColor="rgba(0,0,0,0.4)"
                  showIndicator={true}
                  shading={true}
                  showPane={true}
                  closeOnOutsideClick={false}
                /> */}
                        <Column
                          dataField="SalarioSolicitado"
                          caption="Salario Nuevo"
                          format={monedaFormat}
                          // allowEditing={true}
                        />
                        <Column
                          dataField="Comentario"
                          caption="Comentario"
                          // allowEditing={false}
                        />
                        <ColumnFixing enabled={true} />
                      </DataGrid>
                    )
                  )}
                </Grid>

                <Grid item xs={6} sm={6} md={12} lg={12}>
                  <FormControl className={classes.formControlSelects}>
                    <Controller
                      name="puestoLaboral"
                      required
                      render={({ PuestoLaboral }) => (
                        <Autocomplete
                          id="puestoLaboral"
                          options={maestroTipoTurno}
                          getOptionLabel={(option) => option.TipoTurno}
                          value={valueAutoCompleteTipoTurno}
                          onChange={(event, newValue) =>
                            handleOnChangeTipoTurno(event, newValue)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Tipo Turno"
                              variant="outlined"
                            />
                          )}
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={6} sm={6} md={12} lg={12}>
                  <FormControl className={classes.formControlSelects}>
                    <Controller
                      name="jefe"
                      required
                      render={({ Jefe }) => (
                        <Autocomplete
                          id="jefe"
                          options={maestroJefeInmediato}
                          getOptionLabel={(option) => option.NombreCompleto}
                          value={valueAutoCompleteJefeInmediato}
                          onChange={(event, newValue) =>
                            handleOnChangeJefeInmediato(event, newValue)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Jefe Inmediato"
                              variant="outlined"
                            />
                          )}
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
                  <FormControl className={classes.formControlSelects}>
                    <Controller
                      name="agrupacionDePagoTipoNomina"
                      required
                      render={({ AgrupacionDePagoTipoNomina }) => (
                        <Autocomplete
                          id="agrupacionDePagoTipoNomina"
                          options={maestroAgrupacionDePagoTipoNomina}
                          getOptionLabel={(option) =>
                            option.AgrupacionDePagoTipoNomina
                          }
                          value={valueAutoCompleteAgrupacionDePagoTipoNomina}
                          onChange={(event, newValue) =>
                            handleOnChangeAgrupacionDePagoTipoNomina(
                              event,
                              newValue
                            )
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Agrupación De Pago"
                              variant="outlined"
                            />
                          )}
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
                  <FormControl className={classes.formControlSelects}>
                    <Controller
                      name="centroDePago"
                      required
                      render={({ CentroDePago }) => (
                        <Autocomplete
                          id="centroDePago"
                          options={maestroCentroDePago}
                          getOptionLabel={(option) => option.CentroDePago}
                          value={valueAutoCompleteCentroDePago}
                          onChange={(event, newValue) =>
                            handleOnChangeCentroDePago(event, newValue)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Centro De Pago"
                              variant="outlined"
                            />
                          )}
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
                  {esRequeridoObjetoSAP && (
                    <React.Fragment>
                      <FormControl className={classes.formControlSelects}>
                        <Controller
                          name="tipoObjetoSAP"
                          required
                          render={({ TipoObjetoSAP }) => (
                            <Autocomplete
                              id="tipoObjetoSAP"
                              options={maestroTipoObjetoSAP}
                              getOptionLabel={(option) => option.TipoObjetoSAP}
                              value={valueAutoCompleteTipoObjetoSAP}
                              onChange={(event, newValue) =>
                                handleOnChangeTipoObjetoSAP(event, newValue)
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Tipo Objeto SAP"
                                  variant="outlined"
                                />
                              )}
                            />
                          )}
                          control={control}
                        />
                      </FormControl>
                      <FormControl className={classes.formControlSelects}>
                        <Controller
                          name="objetoSAP"
                          required
                          render={({ ObjetoSAP }) => (
                            <Autocomplete
                              id="objetoSAP"
                              options={maestroObjetoSAP}
                              getOptionLabel={(option) =>
                                codigoTipoObjetoSAP === 3
                                  ? option.Grafo +
                                    "-" +
                                    option.TextoExplicativo +
                                    "-" +
                                    option.GrupoPlanificador
                                  : option.CodigoCentroCosto +
                                    "-" +
                                    option.Descripcion
                              }
                              value={valueAutoCompleteObjetoSAP}
                              onChange={(event, newValue) =>
                                handleOnChangeObjetoSAP(event, newValue)
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Objeto SAP"
                                  variant="outlined"
                                />
                              )}
                            />
                          )}
                          control={control}
                        />
                      </FormControl>
                    </React.Fragment>
                  )}
                  <FormControl className={classes.formControlSelects}>
                    <Controller
                      name="motivoMovimientoPersonalBaja"
                      required
                      render={({ MotivoMovimientoPersonalBaja }) => (
                        <Autocomplete
                          id="motivoMovimientoPersonalBaja"
                          options={maestroMotivoMovimientoPersonal}
                          getOptionLabel={(option) =>
                            option.MotivoMovimientoPersonal
                          }
                          value={valueAutoCompleteMotivoMovimientoPersonal}
                          onChange={(event, newValue) =>
                            handleOnChangeMotivoMovimientoPersonal(
                              event,
                              newValue
                            )
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Motivo de Movimiento"
                              variant="outlined"
                            />
                          )}
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
                  <FormControl className={classes.formControlSelects}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid container justify="flex-start">
                        <Typography>
                          Para personal de planilla, los cambios deben
                          realizarse únicamente día lunes
                        </Typography>
                        <KeyboardDatePicker
                          disableToolbar
                          variant="inline"
                          format="dd/MM/yyyy"
                          margin="normal"
                          id="date-picker-inline"
                          label="Fecha para Ejecución"
                          value={fechaEjecucion}
                          onChange={handleDateChangeFechaCreacion}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          required
                          autoOk={true}
                          shouldDisableDate={validarRangoDeFechas}
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </FormControl>
                  <FormControl className={classes.formControlSelects}>
                    <Controller
                      name="comentario"
                      render={({ Comentario }) => (
                        <TextField
                          id="comentario"
                          label="Comentario"
                          name="comentario"
                          value={comentario}
                          onChange={(e) => setComentario(e.target.value)}
                          variant="outlined"
                          helperText={
                            fieldsErrors.comentario
                              ? fieldsErrors.comentario.message
                              : ""
                          }
                          multiline
                          maxRows={4}
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} md={12} lg={12}>
                  <Button
                    width="100%"
                    variant="contained"
                    style={{
                      backgroundColor: "#69bd4b",
                      marginBottom: 10,
                      marginTop: 10,
                      color: "white",
                      width: "93%",
                      marginLeft: 30,
                    }}
                    type="submit"
                  >
                    &nbsp;Guardar Cambios
                  </Button>
                </Grid>
              </form>
            </Grid>
          </div>
        </Fade>
      </Modal>
    </React.Fragment>
  );
};

export default AltasReclutamientoComponente;
