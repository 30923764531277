
export default function plantillaCorreo(tema, headerImg=null, footerImg=null) {

  let headerUrl = tema.HeaderUrl;
  let footerUrl = tema.FooterUrl;
  
  if (headerImg) {
    headerUrl = headerImg;
  }
  if (footerImg) {
    footerUrl = footerImg;
  }

  return `
    <!DOCTYPE html
    PUBLIC '-//W3C//DTD XHTML 1.0 Transitional//EN' 'http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd'>
  <html xmlns='http://www.w3.org/1999/xhtml' xmlns:o='urn:schemas-microsoft-com:office:office'
    style='font-family:arial, helvetica neue, helvetica, sans-serif'>
  
  <head>
    <meta charset='UTF-8'>
    <meta content='width=device-width, initial-scale=1' name='viewport'>
    <meta name='x-apple-disable-message-reformatting'>
    <meta http-equiv='X-UA-Compatible' content='IE=edge'>
    <meta content='telephone=no' name='format-detection'>
    <title>Nuevo mensaje</title>
    <!--[if (mso 16)]>
    <style type='text/css'>
    a {text-decoration: none;}
    </style>
    <![endif]-->
      <!--[if gte mso 9]><style>sup { font-size: 100% !important; }</style><![endif]-->
      <!--[if gte mso 9]>
    <xml>
    <o:OfficeDocumentSettings>
    <o:AllowPNG></o:AllowPNG>
    <o:PixelsPerInch>96</o:PixelsPerInch>
    </o:OfficeDocumentSettings>
    </xml>
    <![endif]-->
    <style type='text/css'>
      #outlook a {
        padding: 0;
      }
  
      .es-button {
        mso-style-priority: 100 !important;
        text-decoration: none !important;
      }
  
      a[x-apple-data-detectors] {
        color: inherit !important;
        text-decoration: none !important;
        font-size: inherit !important;
        font-family: inherit !important;
        font-weight: inherit !important;
        line-height: inherit !important;
      }
  
      .es-desk-hidden {
        display: none;
        float: left;
        overflow: hidden;
        width: 0;
        max-height: 0;
        line-height: 0;
        mso-hide: all;
      }
  
      [data-ogsb] .es-button {
        border-width: 0 !important;
        padding: 10px 30px 10px 30px !important;
      }
  
      @media only screen and (max-device-width:600px) {
  
        .es-content table,
        .es-header table,
        .es-footer table {
          width: 100% !important;
          max-width: 600px !important;
        }
      }
  
      @media only screen and (max-width:600px) {
  
        p,
        ul li,
        ol li,
        a {
          line-height: 150% !important
        }
  
        h1,
        h2,
        h3,
        h1 a,
        h2 a,
        h3 a {
          line-height: 120%
        }
  
        h1 {
          font-size: 36px !important;
          text-align: center
        }
  
        h2 {
          font-size: 26px !important;
          text-align: center
        }
  
        h3 {
          font-size: 20px !important;
          text-align: center
        }
  
        .es-header-body h1 a,
        .es-content-body h1 a,
        .es-footer-body h1 a {
          font-size: 36px !important
        }
  
        .es-header-body h2 a,
        .es-content-body h2 a,
        .es-footer-body h2 a {
          font-size: 26px !important
        }
  
        .es-header-body h3 a,
        .es-content-body h3 a,
        .es-footer-body h3 a {
          font-size: 20px !important
        }
  
        .es-menu td a {
          font-size: 14px !important
        }
  
        .es-header-body p,
        .es-header-body ul li,
        .es-header-body ol li,
        .es-header-body a {
          font-size: 14px !important
        }
  
        .es-content-body p,
        .es-content-body ul li,
        .es-content-body ol li,
        .es-content-body a {
          font-size: 16px !important
        }
  
        .es-footer-body p,
        .es-footer-body ul li,
        .es-footer-body ol li,
        .es-footer-body a {
          font-size: 14px !important
        }
  
        .es-infoblock p,
        .es-infoblock ul li,
        .es-infoblock ol li,
        .es-infoblock a {
          font-size: 12px !important
        }
  
        *[class='gmail-fix'] {
          display: none !important
        }
  
        .es-m-txt-c,
        .es-m-txt-c h1,
        .es-m-txt-c h2,
        .es-m-txt-c h3 {
          text-align: center !important
        }
  
        .es-m-txt-r,
        .es-m-txt-r h1,
        .es-m-txt-r h2,
        .es-m-txt-r h3 {
          text-align: right !important
        }
  
        .es-m-txt-l,
        .es-m-txt-l h1,
        .es-m-txt-l h2,
        .es-m-txt-l h3 {
          text-align: left !important
        }
  
        .es-m-txt-r img,
        .es-m-txt-c img,
        .es-m-txt-l img {
          display: inline !important
        }
  
        .es-button-border {
          display: block !important
        }
  
        a.es-button,
        button.es-button {
          font-size: 20px !important;
          display: block !important;
          border-left-width: 0px !important;
          border-right-width: 0px !important
        }
  
        .es-adaptive table,
        .es-left,
        .es-right {
          width: 100% !important
        }
  
        .es-content table,
        .es-header table,
        .es-footer table,
        .es-content,
        .es-footer,
        .es-header {
          width: 100% !important;
          max-width: 600px !important
        }
  
        .es-adapt-td {
          display: block !important;
          width: 100% !important
        }
  
        .adapt-img {
          width: 100% !important;
          height: auto !important
        }
  
        .es-m-p0 {
          padding: 0 !important
        }
  
        .es-m-p0r {
          padding-right: 0 !important
        }
  
        .es-m-p0l {
          padding-left: 0 !important
        }
  
        .es-m-p0t {
          padding-top: 0 !important
        }
  
        .es-m-p0b {
          padding-bottom: 0 !important
        }
  
        .es-m-p20b {
          padding-bottom: 20px !important
        }
  
        .es-mobile-hidden,
        .es-hidden {
          display: none !important
        }
  
        tr.es-desk-hidden,
        td.es-desk-hidden,
        table.es-desk-hidden {
          width: auto !important;
          overflow: visible !important;
          float: none !important;
          max-height: inherit !important;
          line-height: inherit !important
        }
  
        tr.es-desk-hidden {
          display: table-row !important
        }
  
        table.es-desk-hidden {
          display: table !important
        }
  
        td.es-desk-menu-hidden {
          display: table-cell !important
        }
  
        .es-menu td {
          width: 1% !important
        }
  
        table.es-table-not-adapt,
        .esd-block-html table {
          width: auto !important
        }
  
        table.es-social {
          display: inline-block !important
        }
  
        table.es-social td {
          display: inline-block !important
        }
  
        .es-m-p5 {
          padding: 5px !important
        }
  
        .es-m-p5t {
          padding-top: 5px !important
        }
  
        .es-m-p5b {
          padding-bottom: 5px !important
        }
  
        .es-m-p5r {
          padding-right: 5px !important
        }
  
        .es-m-p5l {
          padding-left: 5px !important
        }
  
        .es-m-p10 {
          padding: 10px !important
        }
  
        .es-m-p10t {
          padding-top: 10px !important
        }
  
        .es-m-p10b {
          padding-bottom: 10px !important
        }
  
        .es-m-p10r {
          padding-right: 10px !important
        }
  
        .es-m-p10l {
          padding-left: 10px !important
        }
  
        .es-m-p15 {
          padding: 15px !important
        }
  
        .es-m-p15t {
          padding-top: 15px !important
        }
  
        .es-m-p15b {
          padding-bottom: 15px !important
        }
  
        .es-m-p15r {
          padding-right: 15px !important
        }
  
        .es-m-p15l {
          padding-left: 15px !important
        }
  
        .es-m-p20 {
          padding: 20px !important
        }
  
        .es-m-p20t {
          padding-top: 20px !important
        }
  
        .es-m-p20r {
          padding-right: 20px !important
        }
  
        .es-m-p20l {
          padding-left: 20px !important
        }
  
        .es-m-p25 {
          padding: 25px !important
        }
  
        .es-m-p25t {
          padding-top: 25px !important
        }
  
        .es-m-p25b {
          padding-bottom: 25px !important
        }
  
        .es-m-p25r {
          padding-right: 25px !important
        }
  
        .es-m-p25l {
          padding-left: 25px !important
        }
  
        .es-m-p30 {
          padding: 30px !important
        }
  
        .es-m-p30t {
          padding-top: 30px !important
        }
  
        .es-m-p30b {
          padding-bottom: 30px !important
        }
  
        .es-m-p30r {
          padding-right: 30px !important
        }
  
        .es-m-p30l {
          padding-left: 30px !important
        }
  
        .es-m-p35 {
          padding: 35px !important
        }
  
        .es-m-p35t {
          padding-top: 35px !important
        }
  
        .es-m-p35b {
          padding-bottom: 35px !important
        }
  
        .es-m-p35r {
          padding-right: 35px !important
        }
  
        .es-m-p35l {
          padding-left: 35px !important
        }
  
        .es-m-p40 {
          padding: 40px !important
        }
  
        .es-m-p40t {
          padding-top: 40px !important
        }
  
        .es-m-p40b {
          padding-bottom: 40px !important
        }
  
        .es-m-p40r {
          padding-right: 40px !important
        }
  
        .es-m-p40l {
          padding-left: 40px !important
        }
      }
    </style>
  </head>
  
  <body
    style='width:100%;font-family:arial, helvetica neue, helvetica, sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;padding:0;Margin:0'>
    <div class='es-wrapper-color' style='background-color:#FAFAFA'>
      <!--[if gte mso 9]>
  <v:background xmlns:v='urn:schemas-microsoft-com:vml' fill='t'>
  <v:fill type='tile' color='#fafafa'></v:fill>
  </v:background>
  <![endif]-->
      <table class='es-wrapper' width='100%' cellspacing='0' cellpadding='0'
        style='mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:100%;background-repeat:repeat;background-position:center top;background-color:#FAFAFA'>
        <tr>
          <td valign='top' style='padding:0;Margin:0'>
            <table cellpadding='0' cellspacing='0' class='es-header' align='center'
              style='mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top'>
              <tr>
                <td align='center' style='padding:0;Margin:0'>
                  <table bgcolor='#ffffff' class='es-header-body' align='center' cellpadding='0' cellspacing='0'
                    style='mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;background-color:transparent;width:600px'>
                    <tr>
                      <td align='left' style='padding:0;Margin:0'>
                        <table cellpadding='0' cellspacing='0' width='100%'
                          style='mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px'>
                          <tr>
                            <td class='es-m-p0r' valign='top' align='center' style='padding:0;Margin:0;width:600px'>
                              <table cellpadding='0' cellspacing='0' width='100%' role='presentation'
                                style='mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px'>
                                <tr>
                                  <td align='center' style='padding:0;Margin:0;font-size:0px;background:#FFFFFF'><img
                                      src='${headerUrl}' alt='Logo'
                                      style='display:block;border:0;outline:none;text-decoration:none;-ms-interpolation-mode:bicubic;font-size:12px'
                                      title='Logo' width='100%'></td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <table cellpadding='0' cellspacing='0' class='es-content' align='center'
              style='mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%'>
              <tr>
                <td align='center' style='padding:0;Margin:0'>
                  <table bgcolor='#ffffff' class='es-content-body' align='center' cellpadding='0' cellspacing='0'
                    style='mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;background-color:#FFFFFF;width:600px'>
                    <tr>
                      <td class='es-m-p20t' align='left'
                        style='padding:0;Margin:0;padding-left:20px;padding-right:20px;padding-top:30px'>
                        <table cellpadding='0' cellspacing='0' width='100%'
                          style='mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px'>
                          <tr>
                            <td align='center' valign='top' style='padding:0;Margin:0;width:560px'>
                              <table cellpadding='0' cellspacing='0' width='100%' role='presentation'
                                style='mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px'>
                                <tr>
                                  <td align='center' style='padding:10px;Margin:0;font-size:0px'><img class='adapt-img'
                                      src='/avatar.png'
                                      alt
                                      style='display:block;border:0;outline:none;text-decoration:none;-ms-interpolation-mode:bicubic;border-radius:15px'
                                      width='250'></td>
                                </tr>
                                <tr>
                                  <td align='center' class='es-m-p0r es-m-p0l'
                                    style='Margin:0;padding-top:5px;padding-bottom:5px;padding-left:40px;padding-right:40px'>
                                    <p
                                      style='Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, helvetica neue, helvetica, sans-serif;line-height:27px;color:${tema.colorMensaje};font-size:18px'>
                                      Texto para el mensaje de felicitación por cumpleañero.</p>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr class='es-mobile-hidden'>
                      <td align='left' style='padding:0;Margin:0;padding-top:20px;padding-bottom:20px'>
                        <!--[if mso]><table style='width:600px' cellpadding='0' cellspacing='0'><tr><td style='width:302px' valign='top'><![endif]-->
                        <table cellpadding='0' cellspacing='0' class='es-left' align='left'
                          style='mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;float:left'>
                          <tr>
                            <td class='es-m-p20b' align='left' style='padding:0;Margin:0;width:302px'>
                              <table cellpadding='0' cellspacing='0' width='100%' role='presentation'
                                style='mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px'>
                                <tr>
                                  <td align='center' style='padding:10px;Margin:0;border-right:2px solid #5c5b60'>
                                    <p
                                      style='Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, helvetica neue, helvetica, sans-serif;line-height:56px;color:${tema.colorNombre};font-size:28px'>
                                      <strong>Colaborador</strong></p>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                        <!--[if mso]></td><td style='width:0px'></td><td style='width:298px' valign='top'><![endif]-->
                        <table cellpadding='0' cellspacing='0' class='es-right' align='right'
                          style='mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;float:right'>
                          <tr>
                            <td align='left' style='padding:0;Margin:0;width:298px'>
                              <table cellpadding='0' cellspacing='0' width='100%' role='presentation'
                                style='mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px'>
                                <tr>
                                  <td align='center' style='padding:10px;Margin:0;border-left:2px solid #5c5b60'>
                                    <p
                                      style='Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, helvetica neue, helvetica, sans-serif;line-height:56px;color:${tema.colorGerencia};font-size:28px'>
                                      <strong>Gerencia</strong></p>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                        <!--[if mso]></td></tr></table><![endif]-->
                      </td>
                    </tr>
                    <!--[if !mso]><!-- -->
                    <tr class='es-desk-hidden'
                      style='display:none;float:left;overflow:hidden;width:0;max-height:0;line-height:0;mso-hide:all'>
                      <td align='left' style='padding:0;Margin:0'>
                        <table cellpadding='0' cellspacing='0' width='100%'
                          style='mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px'>
                          <tr>
                            <td align='center' valign='top' style='padding:0;Margin:0;width:600px'>
                              <table cellpadding='0' cellspacing='0' width='100%' role='presentation'
                                style='mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px'>
                                <tr>
                                  <td align='center' style='padding:10px;Margin:0'>
                                    <p
                                      style='Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, helvetica neue, helvetica, sans-serif;line-height:56px;color:${tema.colorNombre};font-size:28px'>
                                      <strong>Colaborador</strong></p>
                                  </td>
                                </tr>
                                <tr>
                                  <td align='center' style='padding:0;Margin:0;font-size:0'>
                                    <table border='0' width='70%' height='100%' cellpadding='0' cellspacing='0'
                                      role='presentation'
                                      style='mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px'>
                                      <tr>
                                        <td
                                          style='padding:0;Margin:0;border-bottom:4px solid #5c5b60;background:none;height:1px;width:100%;margin:0px'>
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                                <tr>
                                  <td align='center' style='padding:10px;Margin:0'>
                                    <p
                                      style='Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, helvetica neue, helvetica, sans-serif;line-height:56px;color:${tema.colorGerencia};font-size:28px'>
                                      <strong>Gerencia</strong></p>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <!--<![endif]-->
                    <tr>
                      <td align='left'
                        style='Margin:0;padding-top:5px;padding-bottom:5px;padding-left:20px;padding-right:20px'>
                        <!--[if mso]><table style='width:560px' cellpadding='0' cellspacing='0'><tr><td style='width:194px' valign='top'><![endif]-->
                        <table cellpadding='0' cellspacing='0' class='es-left' align='left'
                          style='mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;float:left'>
                          <tr>
                            <td class='es-m-p0r es-m-p20b' align='center' style='padding:0;Margin:0;width:174px'>
                              <table cellpadding='0' cellspacing='0' width='100%' role='presentation'
                                style='mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px'>
                                <tr>
                                  <td align='left' style='padding:0;Margin:0'>
                                    <p
                                      style='Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, helvetica neue, helvetica, sans-serif;line-height:21px;color:#333333;font-size:14px'>
                                      <br></p>
                                  </td>
                                </tr>
                              </table>
                            </td>
                            <td class='es-hidden' style='padding:0;Margin:0;width:20px'></td>
                          </tr>
                        </table>
                        <!--[if mso]></td><td style='width:173px' valign='top'><![endif]-->
                        <table cellpadding='0' cellspacing='0' class='es-left' align='left'
                          style='mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;float:left'>
                          <tr>
                            <td class='es-m-p20b' align='center' style='padding:0;Margin:0;width:173px'>
                              <table cellpadding='0' cellspacing='0' width='100%' bgcolor='#4da92c'
                                style='mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:separate;border-spacing:0px;background-color:${tema.colorNombre};border-radius:15px'
                                role='presentation'>
                                <tr>
                                  <td align='center'
                                    style='Margin:0;padding-top:5px;padding-bottom:5px;padding-left:20px;padding-right:20px'>
                                    <h2
                                      style='Margin:0;line-height:60px;mso-line-height-rule:exactly;font-family:arial, helvetica neue, helvetica, sans-serif;font-size:50px;font-style:normal;font-weight:bold;color:#ffffff'>
                                      <strong>Día</strong></h2>
                                  </td>
                                </tr>
                                <tr>
                                  <td align='center' style='padding:0;Margin:0'>
                                    <p
                                      style='Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, helvetica neue, helvetica, sans-serif;line-height:39px;color:#ffffff;font-size:26px'>
                                      <strong>Mes</strong></p>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                        <!--[if mso]></td><td style='width:20px'></td><td style='width:173px' valign='top'><![endif]-->
                        <table cellpadding='0' cellspacing='0' class='es-right' align='right'
                          style='mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;float:right'>
                          <tr>
                            <td align='center' style='padding:0;Margin:0;width:173px'>
                              <table cellpadding='0' cellspacing='0' width='100%' role='presentation'
                                style='mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px'>
                                <tr>
                                  <td align='left' style='padding:0;Margin:0'>
                                    <p
                                      style='Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, helvetica neue, helvetica, sans-serif;line-height:21px;color:#333333;font-size:14px'>
                                      <br></p>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                        <!--[if mso]></td></tr></table><![endif]-->
                      </td>
                    </tr>
                    <tr>
                      <td align='left' style='padding:0;Margin:0;padding-top:10px;padding-left:20px;padding-right:20px'>
                        <table cellpadding='0' cellspacing='0' width='100%'
                          style='mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px'>
                          <tr>
                            <td align='center' valign='top' style='padding:0;Margin:0;width:560px'>
                              <table cellpadding='0' cellspacing='0' width='100%'
                                style='mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:separate;border-spacing:0px;border-radius:5px'
                                role='presentation'>
                                <tr>
                                  <td align='center'
                                    style='padding:0;Margin:0;padding-bottom:20px;padding-left:20px;padding-right:20px'>
                                    <h2
                                      style='Margin:0;line-height:39px;mso-line-height-rule:exactly;font-family:arial, helvetica neue, helvetica, sans-serif;font-size:26px;font-style:normal;font-weight:bold;color:#5c5b60'>
                                      ¡Gracias por ser parte de esta gran familia!</h2>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr style='display: none'>
                      <td align='left'
                        style='padding:0;Margin:0;padding-left:20px;padding-right:20px;padding-bottom:30px'>
                        <table cellpadding='0' cellspacing='0' width='100%'
                          style='mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px'>
                          <tr>
                            <td align='center' valign='top' style='padding:0;Margin:0;width:560px'>
                              <table cellpadding='0' cellspacing='0' width='100%'
                                style='mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:separate;border-spacing:0px;border-radius:5px'
                                role='presentation'>
                                <tr>
                                  <td align='center' style='padding:0;Margin:0;padding-top:10px;padding-bottom:10px'><span
                                      class='es-button-border'
                                      style='border-style:solid;border-color:#2CB543;background:${tema.colorNombre};border-width:0px;display:inline-block;border-radius:25px;width:auto'><a
                                        href='https://apilu.com' class='es-button' target='_blank'
                                        style='mso-style-priority:100 !important;text-decoration:none;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;color:#FFFFFF;font-size:20px;border-style:solid;border-color:${tema.colorNombre};border-width:10px 30px 10px 30px;display:inline-block;background:${tema.colorNombre};border-radius:25px;font-family:arial, helvetica neue, helvetica, sans-serif;font-weight:normal;font-style:normal;line-height:24px;width:auto;text-align:center;border-left-width:30px;border-right-width:30px'>Click
                                        para ver tus felicitaciones</a></span></td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <table cellpadding='0' cellspacing='0' class='es-footer' align='center'
              style='mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%;background-color:transparent;background-repeat:repeat;background-position:center top'>
              <tr>
                <td align='center' style='padding:0;Margin:0'>
                  <table class='es-footer-body' align='center' cellpadding='0' cellspacing='0'
                    style='mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;background-color:transparent;width:640px'>
                    <tr>
                      <td align='left'
                        style='Margin:0;padding-top:20px;padding-bottom:20px;padding-left:20px;padding-right:20px'>
                        <table cellpadding='0' cellspacing='0' width='100%'
                          style='mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px'>
                          <tr>
                            <td align='left' style='padding:0;Margin:0;width:600px'>
                              <table cellpadding='0' cellspacing='0' width='100%' bgcolor='#4d9b2e'
                                style='mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;background-color:#4d9b2e'
                                role='presentation'>
                                <tr>
                                  <td align='center' style='padding:0;Margin:0;font-size:0px'><img
                                      src='${footerUrl}' alt='Logo'
                                      style='display:block;border:0;outline:none;text-decoration:none;-ms-interpolation-mode:bicubic;font-size:12px'
                                      title='Logo' width='100%'></td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <table cellpadding='0' cellspacing='0' class='es-content' align='center'
              style='mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;table-layout:fixed !important;width:100%'>
              <tr>
                <td class='es-info-area' align='center' style='padding:0;Margin:0'>
                  <table class='es-content-body' align='center' cellpadding='0' cellspacing='0'
                    style='mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px;background-color:transparent;width:600px'
                    bgcolor='#FFFFFF'>
                    <tr>
                      <td align='left' style='padding:20px;Margin:0'>
                        <table cellpadding='0' cellspacing='0' width='100%'
                          style='mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px'>
                          <tr>
                            <td align='center' valign='top' style='padding:0;Margin:0;width:560px'>
                              <table cellpadding='0' cellspacing='0' width='100%'
                                style='mso-table-lspace:0pt;mso-table-rspace:0pt;border-collapse:collapse;border-spacing:0px'>
                                <tr>
                                  <td align='center' style='padding:0;Margin:0;display:none'></td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </div>
  </body>
  
  </html>
    `
}