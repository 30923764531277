import React, { useState, useEffect } from 'react'
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { useStoreState, useStoreActions } from 'easy-peasy'
import dayjs from 'dayjs'

const Filtros = ({ onFilter, onClose, start=2010 }) => {
  const now = dayjs()
  const { tipos, sistemas, filtros } = useStoreState(state => ({
    tipos: state.tipos,
    sistemas: state.sistemas,
    filtros: state.filtros
  }))
  const { setFiltros } = useStoreActions(actions => ({
    setFiltros: actions.setFiltros
  }))
  const anhos = [...Array(now.year() - start + 1).keys()]
    .map(i => i + start)
    .map(i => i + '')
  const vigencias = ['VIGENTE', 'POR VENCER', 'VENCIDO']
  const handleChange = (prop, value) => {
    const tmp = { ...filtros }
    tmp[prop] = value
    setFiltros(tmp)
  }
  return <>
    <DialogTitle>Filtrar</DialogTitle>
    <DialogContent>
      <Grid container spacing={3}>
        {/* <Grid item xs={12} md={6}>
          <Autocomplete
            options={tipos}
            getOptionLabel={option => option.NombreTipoDocumento}
            renderInput={(params) => <TextField {...params} label="Tipo de documento" margin="normal" />}
            value={filtros.tipo}
            onChange={(e, value) => handleChange('tipo', value)}
          />
        </Grid> */}
        <Grid item xs={12} md={6}>
          <Autocomplete
            options={sistemas}
            getOptionLabel={option => option.NombreSistemaGestion}
            renderInput={(params) => <TextField {...params} label="Sistema de gestión" margin="normal" />}
            value={filtros.sistema}
            onChange={(e, value) => handleChange('sistema', value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Autocomplete
            options={anhos}
            getOptionLabel={option => option}
            renderInput={(params) => <TextField {...params} label="Año" margin="normal" />}
            value={filtros.anho}
            onChange={(e, value) => handleChange('anho', value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Autocomplete
            options={vigencias}
            getOptionLabel={option => option}
            renderInput={(params) => <TextField {...params} label="Vigencia" margin="normal" />}
            value={filtros.vigencia}
            onChange={(e, value) => handleChange('vigencia', value)}
          />
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button variant="contained" onClick={onClose}>Cancelar</Button>
      <Button color="primary" variant="contained" onClick={onFilter}>Filtrar</Button>
    </DialogActions>
  </>
}

export default Filtros
