import React from 'react';
import { Grid, makeStyles, Card, Typography, IconButton } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import { Notifications } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        margin: 0,
        borderRadius: 10,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.grey[100]
        }
    },
    iconoContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'flex-start'
    },
    datosContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start'
    },
    titulo: {
        fontSize: 18,
        color: 'rgba(0, 0, 0, 0.87)',
    },
    cantidad: {
        fontSize: 15,
        marginBottom: 0
    },
    iconButton: {
        '&:hover': {
            backgroundColor: theme.palette.text.secondary
        },
    }
}));

const CardGestion = ({ titulo, cantidad, color, iconComponent, action }) => {

    const classes = useStyles();

    return (
        <Card className={classes.root} elevation={2} onClick={action} >
            <CardContent >
                <Grid container>
                    <Grid item lg={9} md={9} xl={10} className={classes.datosContainer}>
                        {(cantidad != null || cantidad != undefined) && (
                            <Typography className={classes.cantidad} color="textSecondary">
                                {cantidad} Pendientes
                            </Typography>
                        )}
                        <Typography className={classes.titulo} color="textPrimary">
                            {titulo}
                        </Typography>
                    </Grid>
                    {/* <Grid item md={1}></Grid> */}
                    <Grid item lg={3} md={3} xl={2} className={classes.iconoContainer}>
                        <IconButton size="medium" style={{backgroundColor: color}} className={classes.iconButton}>
                            {iconComponent}
                        </IconButton>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default CardGestion;
