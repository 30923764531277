import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";


import Avatar from "@material-ui/core/Avatar";
import CardHeader from "@material-ui/core/CardHeader";
import Chip from "@material-ui/core/Chip";
import AlarmIcon from "@material-ui/icons/Alarm";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import EventIcon from "@material-ui/icons/Event";
import SubdirectoryArrowRightIcon from "@material-ui/icons/SubdirectoryArrowRight";
import TimerIcon from "@material-ui/icons/Timer";

import Grid from "@material-ui/core/Grid";
import ProgressComponent from "../../helpers/components/progress-component";
import API from "../../utils/api";
import { isSignedIn } from "../../utils/auth";

import DetalleGestionGeneral from "../components/gestion-tarea-detalle";

import { Visibility } from "@material-ui/icons";
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    width: "100%",
    marginTop: 5,
  },
  rootGestion: {
    flexGrow: 1,
    marginTop: 20,
  },
  cardHeader: {
    backgroundColor: "#324659",
    color: "#fff",
  },
  buttonOk: {
    margin: theme.spacing(1),
    backgroundColor: "#69BD4B",
    width: "95%",
    "&:hover": {
      backgroundColor: "green",
    },
  },
  buttonCancel: {
    width: "95%",
    margin: theme.spacing(1),
    backgroundColor: "#E74C3C",
  },
  buttonForm: {
    margin: theme.spacing(1),
    backgroundColor: "#42A5F5",
    width: "95%",
    "&:hover": {
      backgroundColor: "blue",
    },
  },
}));

export default function CardTarea(props) {
  const [ruta, setRuta] = React.useState(null);

  const [permisoEstadoTarea, setEstadoTarea] = useState(false);
  const [permisoEditar, setPermisoEditar] = useState(false);

  const [verForm, setVerForm] = React.useState(false);

  useEffect(() => {
    const cargar = async () => {
      await makeRequest();
    };

    cargar();
  }, [props.CodigoTarea]);

  const makeRequest = async () => {
    const request = await API.fetchGetRequest(
      `api/aviso/tarea/${props.CodigoTarea}`
    );

    const session = await isSignedIn();

    if (request.data.response) {
      const tareaBuff = request.data.data;
      console.log("serreciete");
      console.log(tareaBuff);

      if (tareaBuff.AvisoTarea.CodigoEstadoTarea > 1) {
        setEstadoTarea(true);
      } else {
        setEstadoTarea(false);
      }
      console.log("tarea buff", tareaBuff.AvisoTarea);

      //Permisos para editar tarea..
      if (
        tareaBuff.UsuariosAsignados.filter(
          (f) => f.CodigoUsuario == session.codigoUsuario
        ).length > 0
      ) {
        //Si el usuario logeado esta en los usuarios asignados, le damos permiso para editar.. de lo contrario nanain....
        setPermisoEditar(true);
      } else {
        setPermisoEditar(false);
      }
    }
  };

  const onClickRuta = async () => {
    let codigoAvisoTarea = props.CodigoTarea;
    let uri = `api/aviso/tarea/obtenerRutaTarea/${codigoAvisoTarea}`;

    const request = await API.fetchGetRequest(uri);
    setRuta(
      request.data.data == ""
        ? null
        : (request.data.data + ">" + props.Nombre).replaceAll(">", " > ")
    );
  };

  const onClickFormularioGestion = async () => {
    const res = !verForm;
    setVerForm(res);
    //setPermisos(permisos)
  };

  const classes = useStyles();
  const colorFranja = props.Activo
    ? props.Atrasado
      ? "#e74c3c"
      : "#2ecc71"
    : "#979797";

  const history = useHistory();

  const backgroundColor = props.Activo ? "white" : "#EEEEEE";
  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div
        style={{
          backgroundColor: colorFranja,
          width: 7,
          marginTop: 5,
        }}
      />
      <Card className={classes.root}>
        <CardHeader
          className={classes.cardHeader}
          avatar={
            // <Avatar aria-label="recipe" className={classes.avatar}>
            //   R
            // </Avatar>
            <ProgressComponent
              percent={props.porcentajeAvance}
              textColor="#fff"
            />
          }
          title={"#[" + props.CodigoAviso + "] " + props.GestionTitulo}
        //subheader={<span style={{color:"#bbb"}}>{props.Descripcion}</span>}
        />
        {props.Padre != null && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => onClickRuta()}
          >
            Ver ruta
          </Button>
        )}
        {ruta != null && "   " + ruta}
        <CardActionArea>
          <CardContent>
            <div
              style={{ width: "85%" }}
              // onClick={() => {
              //   window.location.href = "/infotarea/" + props.CodigoTarea;
              // }}
              onClick={() => {
                history.push({
                  pathname: '/gestion/' + props.CodigoAviso,
                  state: { codigoTarea: props.CodigoTarea },
                });
              }
              }
            >
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography gutterBottom variant="h5" component="h4">
                    Código tarea: {props.CodigoTarea}
                    <br />
                    Tarea: {props.Nombre}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Descripción: {props.Descripcion} <br />
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <div className={classes.classChips}>
                    Solicitante:{" "}
                    <Chip
                      size="medium"
                      avatar={
                        <Avatar>
                          {props.Solicitante.substring(0, 1).toUpperCase()}
                        </Avatar>
                      }
                      label={
                        props.Solicitante.length > 19
                          ? props.Solicitante.substring(0, 19) + ".."
                          : props.Solicitante
                      }
                    />
                  </div>
                  <br />
                  {props.Padre != null && (
                    <div className={classes.classChips}>
                      Tarea Padre:{" "}
                      <Chip
                        size="medium"
                        icon={<SubdirectoryArrowRightIcon />}
                        label={props.Padre == null ? "N/A" : props.Padre}
                      />
                    </div>
                  )}
                  {props.Padre != null && <br />}
                  {props.FechaVencimiento != "N/A" && (
                    <div className={classes.classChips}>
                      Vence:{" "}
                      <Chip
                        size="medium"
                        icon={<EventIcon />}
                        style={{ backgroundColor: props.colorSemaforo }}
                        label={
                          props.FechaVencimiento == "N/A"
                            ? "N/A Fecha Vencimiento"
                            : props.FechaVencimiento
                        }
                      />
                    </div>
                  )}
                  {props.FechaVencimiento != "N/A" && <br />}
                  {props.SLA != null && (
                    <div className={classes.classChips}>
                      SLA:{" "}
                      <Chip
                        size="medium"
                        icon={<TimerIcon />}
                        label={props.SLA == null ? "N/A" : props.SLA}
                      />
                      Quedan:{" "}
                      <Chip
                        size="medium"
                        icon={<AlarmIcon />}
                        label={props.Quedan == null ? "N/A" : props.Quedan}
                      />
                    </div>
                  )}
                </Grid>
              </Grid>
            </div>
          </CardContent>
        </CardActionArea>
        {/* permisoEditar && */}
        {permisoEstadoTarea === true && permisoEditar === true && (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="secondary"
                className={classes.buttonCancel}
                startIcon={<CloseIcon />}
                onClick={async () => {
                  await props.onClickCancel(props.CodigoTarea);
                  setVerForm(false);
                }}
              >
                CANCELAR / ANULAR
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                className={classes.buttonOk}
                endIcon={<CheckIcon>send</CheckIcon>}
                onClick={async () => {
                  await props.onClickAcept(props.CodigoTarea);
                  setVerForm(false);
                }}
              >
                ACEPTAR
              </Button>
            </Grid>
          </Grid>
        )}
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonForm}
              startIcon={<Visibility />}
              onClick={onClickFormularioGestion}
            >
              Ver Encabezado Gestión
            </Button>
          </Grid>
        </Grid>

        {verForm && <DetalleGestionGeneral codigoTarea={props.CodigoTarea} />}
      </Card>
    </div>
  );
}
