import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Box } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";

import { green, pink } from "@material-ui/core/colors";

import { makeStyles } from "@material-ui/core/styles";

//input date

//Own

import Layout from "../../layout/containers/Layout";
import Confirmacion from "../components/confirmar-movimiento-persona-componente";
// import { isSignedIn, profileImageChange, signOut } from "../../utils/auth";

const styles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
  },
  paperPerfil: {
    position: "relative",
    width: "100%", //theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 1,
    outline: "none",
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 100,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 1,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  greenAvatar: {
    fontSize: "2.5em",
    color: "#fff",
    // borderStyle:'solid',
    // borderWidth:'1 !important',
    // borderColor:'#3f51b5 !important',
    backgroundColor: green[500],
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  paperCard: {
    padding: 20,
    width: "100%",
  },
  box: {
    position: "relative",
    width: "100%", //theme.spacing.unit * 50,
    padding: theme.spacing.unit * 1,
    outline: "none",
  },
}));

const ReporteMovimientosLayout = (props) => {
  const classes = styles();
  

  return (
    // <Layout titulo={`Detalle de Movimiento de Personal`}  maxWidth={"100%"}>
      <React.Fragment>
        <ToastContainer />
        <Box className={classes.paperPerfil} pl={0} pt={0}>
          <Box className={classes.box} pl={0} pt={0}>
            <Confirmacion />
          </Box>
        </Box>
      </React.Fragment>
    // </Layout>
  );
};

export default withRouter(ReporteMovimientosLayout);
