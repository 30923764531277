import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Rating from "@material-ui/lab/Rating";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import Box from "@material-ui/core/Box";
import ChatButton from "../../helpers/components/chat-button-float";
import ClearIcon from "@material-ui/icons/Clear";
import IconButton from "@material-ui/core/IconButton";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

//Scroll bar
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";

//Own Components
import API from "../../utils/api";
import { isSignedIn } from "../../utils/auth";
import InputChat from "../components/input-chat";
import Mensaje from "../components/mensaje";
import { AddComment, Comment } from "@material-ui/icons";

function getChatModalStyle() {
  const bottom = 1;
  const right = 1;

  return {
    bottom: `${bottom}%`,
    right: `${right}%`,
    transform: `translate(-${bottom}%, -${right}%)`,
  };
}

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  paperChat: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    height: 550,
    borderRadius: 15,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: 0, //theme.spacing.unit * 4,
    paddingTop: 0,
    outline: "none",
  },
  textBar: {
    position: "fixed",
    bottom: 0,
  },
  tituloPaper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
});

class Chat extends React.Component {
  // constructor(props) {
  //   super(props);
  //   // Crea una referencia para guardar el scrollbar
  //   this.scrollbarRef = React.createRef();
  //   this.scrollbarRef.scrollTop = 0;
  // }

  state = {
    open: false,
    firstTime:false,
    codigoPadre: null,
    nombrePadre: "xxxxxx",
    llavePadre: "",
    codigoUsuarioActual: null,
    mensajes: [],
    scrollbarRef: null,
  };

  async componentDidMount() {
    if (
      this.props.codigoPadre !== null &&
      this.props.nombrePadre !== null &&
      this.props.llavePadre !== null
    ) {
      const session = await isSignedIn();
      this.setState(
        {
          codigoPadre: this.props.codigoPadre,
          nombrePadre: this.props.nombrePadre,
          llavePadre: this.props.llavePadre,
          codigoUsuarioActual: session.codigoUsuario,
          firstTime:true,
        },
        async () => {
          //console.log("Cagadales, si está entrando", this.props.codigoPadre)
        }
      );
    }
  }

  componentWillUnmount(){
    //clearInterval(this.state.interval);
  }
  // async componentDidUpdate() {
  //   if (
  //     (this.props.codigoPadre !== this.state.codigoPadre && this.props.codigoPadre !== null) ||
  //     (this.props.nombrePadre !== this.state.nombrePadre || this.props.nombrePadre !== null) ||
  //     (this.props.llavePadre !== this.state.llavePadre && this.props.llavePadre !== null)
  //   ) {
  //     console.log("diferente",this.props.codigoPadre);
  //     if (
  //       this.props.codigoPadre !== null &&
  //       this.props.nombrePadre !== null &&
  //       this.props.llavePadre !== null
  //     ) {
  //       const session = await isSignedIn();
  //       this.setState(
  //         {
  //           codigoPadre: this.props.codigoTPadre,
  //           nombrePadre: this.props.nombrePadre,
  //           llavePadre: this.props.llavePadre,
  //           codigoUsuarioActual: session.codigoUsuario,
  //         },
  //         async () => {
  //           //await this.obtenerMensajes();
  //         }
  //       );
  //     }
  //   }
  // }

  obtenerMensajes = async () => {
    // console.log("Open",this.state.open);
    // console.log("firstTime",this.state.firstTime);
    try{

    // if(this.state.open || this.state.firstTime){
    //   //Si la ventana de chat  está abierta o es la primera vez que carga
    //   console.log("Carga de mensajes");

      const request = await API.fetchGetRequest(
        `api/chat/obtenerMensajes/${this.state.codigoPadre}/${this.state.llavePadre}`
      );
      if (!Array.isArray(request.data.data)) {
        return;
      }
      this.setState(
        {
          mensajes: request.data.data,
          firstTime: false,
        },
        () => {
          
        }
      );
    //}
}catch(er){
  console.log(er);
}finally{
  //setTimeout(this.obtenerMensajes, 10000);
}
  };

  irFinal = () => {
    console.log("scrollRef", this.state.scrollbarRef);
    if (this.state.scrollbarRef !== null) {
      this.state.scrollbarRef.scrollTop = Number.MAX_SAFE_INTEGER;
    }
  };

  handleClose = () => {
    this.setState({
      open: false,
      actionType: "",
    });
  };

  handleOpenAddModal = () => {
    this.setState({
      actionType: "Agregar",
      open: true,
    });
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  enviarMensaje = async (mensaje) => {
    if (mensaje === null || mensaje.trim() === "") {
      return;
    }

    try {
      let vIsSignedIn = await isSignedIn();
      if (vIsSignedIn.response) {
        let request;
        const data = {
          CodigoMensaje: null,
          CodigoPadre: this.state.codigoPadre,
          LlavePadre: this.state.llavePadre,
          Mensaje: mensaje,
          CodigoUsuarioOpera: vIsSignedIn.codigoUsuario,
          UsuarioOpera: vIsSignedIn.userName,
        };
        request = await API.post(`api/chat/grabarEditar`, data);
        if (request.status != 200) {
          alert(
            "Hubo un error al guardar el registro, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
          return;
        }
        if (request.status === 401) {
          //alert("cerrar sesion");
        } else {
          this.obtenerMensajes();
        }
      }
    } catch (e) {
      alert("hubo un error");
    }
  };

  render() {
    const { classes } = this.props;
    let { data } = this.state;

    return (
      <div>
          
       
        {/* <ChatButton
          cantidadMensajes ={this.state.mensajes.length}
          color="#3498db"
          onClick={() => {
            this.setState(this.handleOpenAddModal());
          }}
        /> */}
        {this.state.codigoPadre !== null &&(
        
        <IconButton  
          onClick={async () => {
            this.setState(this.handleOpenAddModal());
            await this.obtenerMensajes();
          setTimeout(this.irFinal, 3000);
          }}>
            <Comment style={{color:"#3498db"}}/>
        </IconButton>
          
        )}
         
      
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.open}
          onClose={() => {}}
        >
          <div style={getChatModalStyle()} className={classes.paperChat}>
            <form className={classes.container} noValidate autoComplete="off">
              <AppBar position="static" style={{ backgroundColor: "#27ae60" }}>
                <Toolbar>
                  {/* <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton> */}
                  <Typography variant="h6" className={classes.title}>
                    {this.state.nombrePadre}
                  </Typography>
                  <IconButton
                    style={{ color: "#FFF" }}
                    onClick={this.handleClose}
                  >
                    <ClearIcon />
                  </IconButton>
                </Toolbar>
              </AppBar>

              <div style={{ width: "100%" }}>
                <PerfectScrollbar
                  containerRef={(ref) => {
                    this.setState({ scrollbarRef: ref });
                  }}
                  style={{
                    width: "100%",
                    maxWidth: "100%",
                    height: 400,
                    maxHeight: 400,
                  }}
                >
                  <div style={{ width: "100%" }}>
                    {this.state.mensajes.map((item, index) => (
                      <Mensaje
                        key={"message" + item.CodigoMensaje}
                        mensaje={item.Mensaje}
                        propio={
                          this.state.codigoUsuarioActual ===
                          item.CodigoUsuario.toString()
                        }
                        fecha={item.Fecha}
                        hora={item.Hora}
                        autor={item.NombreCompletoUsuario}
                        iniciales={item.Iniciales}
                      />
                    ))}
                    <div id="ultimoMensaje"></div>
                  </div>
                </PerfectScrollbar>
              </div>

              <div className={classes.textBar}>
                <InputChat send={this.enviarMensaje} />
              </div>
            </form>
          </div>
        </Modal>
      </div>
    );
  }
}

// We need an intermediary variable for handling the recursive nesting.
const ChatContainer = withStyles(styles)(Chat);
export default withRouter(ChatContainer);
