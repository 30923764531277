import React, { useState, useEffect } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import Avatar from "@material-ui/core/Avatar";
import API from "../../utils/api";
import { isSignedIn } from "../../utils/auth";

const useStyles = makeStyles((theme) =>
  createStyles({
    avatarLarge: {
      width: theme.spacing(6),
      height: theme.spacing(6),
    },
    avatar: {
      color: "white",
      backgroundColor: "#69BD4B",
    },
  })
);

export default function UserAvatar({
  iniciales,
  userName,
  nombre,
  isAsignado,
  imagenPerfil,
}) {
  const classes = useStyles();
  const [foto, setFoto] = useState(null);

  useEffect(() => {
    async function fetchData() {
      await cargarFoto(userName);
    }

    if (isAsignado) fetchData();
  }, []);

  const cargarFoto = async (dpiEmpleado) => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    }

    try {
      let uri = "api/Empleado/Foto/" + dpiEmpleado;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        setFoto(null);
        return false;
      } else {
        if (request.data.data !== null) {
          setFoto(request.data.data);
        }
      }
    } catch (e) {
      setFoto(null);
      console.log(e);
    } finally {
    }
  };

  return isAsignado ? (
    <Avatar
      alt={nombre}
      src={foto || imagenPerfil}
      className={[classes.avatarLarge, classes.greenAvatarFoto]}
      style={{
        border: "5px solid lightgray",
      }}
    >
      {iniciales}
    </Avatar>
  ) : (
    <Avatar alt={nombre} className={classes.avatarLarge}>
      {iniciales}
    </Avatar>
  );
}
