import { Chip, Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
// import SettingsIcon from "@material-ui/icons/Settings";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import MaskedInput from "react-text-mask";
import { ToastContainer, toast } from "react-toastify";
//Select
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

//Checkbox
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { arrayMoveControls } from "../../utils/dndUtils";
import "../components/styles.css";

//Drawer
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import clsx from "clsx";
import ConfirmDialog from "../../ConfirmDialog/ConfirmDialog";

//Tabs
import Box from "@material-ui/core/Box";
import FormLabel from "@material-ui/core/FormLabel";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import PropTypes from "prop-types";

//Badge
import Badge from "@material-ui/core/Badge";

//Scroll bar
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
//Radio
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";

//Autocomplete
import Autocomplete from "@material-ui/lab/Autocomplete";

//Lista Etiquetas
import TagList from "../../avisos-objects/components/tag-list";

//Own components
import plantillaIcon from "../../image-repository/plantillaIcon.svg";
import Layout from "../../layout/containers/Layout";
import API from "../../utils/api";
import { isSignedIn } from "../../utils/auth";
import {
  consultarPermiso,
  convertYMD,
  convertirASegundos,
  convertirHHMM,
  validaPermisoPaginaActual,
} from "../../utils/helpers";
import FormList from "../components/form-list";
import TareaProblemaCard from "../components/tarea-problema-card";
import UsuariosList from "../components/usuarios-list";

import FormDesigner from "../../form-designer/container/form-designer";
import ActionMenuFormTab from "../components/action-menu-form-tab";

//Inputs Formulario
import ProblemaApiConfig from "../components/ProblemaApiConfig";
import TaskTree from "../components/tasktree";
import ActionMenuFlujo from "../components/action-menu-flujo";
import TaskDesignerIndependiente from "../../PlantillaFlujo/task-designer-independiente";

import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import TitleCustomInput from "../../helpers/components/titleCustomInput"

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const drawerWidth = 260;
const rightDrawerWidth = 200;
const titleColor = "#2B3C4D";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
  },
  paper: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
  paperModal: {
    position: "absolute",
    width: "90%",
    height: "600px",
    maxHeight: "600px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },

  paperUserModal: {
    position: "absolute",
    width: "75%",
    height: "600px",
    maxHeight: "600px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
  paperNuevoForm: {
    position: "absolute",
    width: "50%",
    height: "300px",
    maxHeight: "300px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  rootTab: {
    backgroundColor: theme.palette.background.paper,
    width: "100% !important",
  },

  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  rightDrawer: {
    width: rightDrawerWidth,
    maxWidth: rightDrawerWidth,
    flexShrink: 0,
  },
  RightDrawerPaper: {
    width: rightDrawerWidth,
    maxWidth: rightDrawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
    backgroundColor: "#3f51b5",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0-9]/, /[0-9]/, ":", /[0-5]/, /[0-9]/]}
      placeholderChar={"\u2000"}
      showMask
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

// const theme = useTheme();

class AdministracionAvisosDetalle extends Component {
  state = {
    valorTab: 0,
    problema: {
      codigoProblema: "",
      nombre: "",
      descripcion: "",
      popular: "",
      tiempoEsperaEstimado: "",
      activo: "",
      tiempoEsperaSegundos: 0,
      fechaInicio: "",
      fechaFin: "",
      esEditable: "",
      codigoPlantilla: "",
      unidadSemaforoAmarillo: 0,
      unidadSemaforoRojo: 0,
      tiempoSemaforoAmarillo: "",
      tiempoSemaforoRojo: "",
      encuestaSatisfaccion: false,
      aplicaFechaVencimiento: false,
      muestraFechaVencimientoCreador: false,
      permiteAgregarTareas: false,
      codigoPlantillaNotificaciones: 0,
      ocultarDescripcion: false,
      ocultarPlantillaAlerta: false,
      ocultarEtiquetas: false,
      TomaAsistenciaReconocimientoFacial: false,
      TomaAsistenciaQR: false,
      TomaAsistenciaDPI: false,
      CodigoFormularioAsistencia: null,
      TomaAsistenciaFotografia: false,
      TomaAsistenciaGenerica: false,
      TituloAsistencia: "",
      TituloParticipantes: "",
      codigoTipoLandmark: 0,
    },
    open: false,
    openToolbar: false,
    actionType: "", //Crear, Actualizar
    codigoProblema: "",
    codigoPlantilla: "",
    tiempoEsperaEstimadoSegundos: "",
    items: [],
    deletedItems: [],
    codigoCampo: "",
    nombreCampo: "",
    valorMinimoCampo: "",
    valorMaximoCampo: "",
    requeridoCampo: false,
    llaveCampo: "",
    origenDatosCampo: "",
    codigoCampoPadreCampo: "",
    listaItemsPickList: [],
    codigoTipoCampo: "",
    displayTipoNumero: "none",
    displayTipoLista: "none",
    displayTipoSap: "none",

    confirmTexto: "",
    confirmOpen: false,
    codigoCampoEliminar: "",
    rightDrawer: false,

    // Tareas
    listaEntidades: [],
    listaFlujos: [],
    listaTareas: [],
    listaUsuariosAsignados: [],
    textoBusquedaFlujo: "",
    codigoPlantillaTareas: "",
    codigoPlantillaTareasPendienteconfirmar: "",
    nombrePlantillaTarea: "",
    codigoTareaPlantilla: "",
    codigoEntidad: 0,

    openChooseTemplate: false,
    confirmOpenCambioPlantilla: false,
    confirmTextoCambioPlantilla: "",

    //Asignacion de usuario a tareas
    openAsignarUsuarios: false,
    txtTextoBusquedaUsuario: "",
    listaUsuarios: [],
    tipoBusquedaAsignado: "Usuario",
    tipoBusquedaEjecutada: "Usuario",

    //Navegacion Tareas
    codigoTareaPadre: 0,
    nombreTareaPadre: 0,
    llaveTareaPadre: "",

    //Etiquetas
    listaFamiliasEtiquetas: [],
    listaEtiquetas: [],
    listaEtiquetasCopia: [],
    listaEtiquetasAsignadas: [],
    objFamilia: {
      CodigoFamiliaEtiqueta: 0,
      Nombre: "Todas",
      Descripcion: "",
      Activo: true,
    },
    codigoEtiqueta: null,
    openModalEtiquetas: false,
    textoBusquedaEtiqueta: "",
    codigoFamiliaEtiquetaSeleccionada: 0,

    listaUnidadMedida: [],
    listaPlantillasAlerta: [],

    textoBusquedaForm: "",
    listaForms: [],
    openChooseForm: false,
    confirmTextoCambioForm: "",
    confirmOpenCambioForm: false,
    codigoFormPendienteconfirmar: "",
    txtNombreForm: "",
    openNewForm: false,
    TextoBusquedaFormAsistencia: "",
    openChooseFormAsistencia: false,
    openNewFormAsistencia: false,
    txtNombreFormAsistencia: "",
    showProblemaApiConfig: false,
    objetoApiConfig: null,
    listaTipoLandmark: [],
    updateFnCallback: () => {},
    expandedGeneral: false,
    expandedAcuerdosNivelServicio: false,
    expandedAlertasVencimiento: false,
    expandedIntegraciones: false,
    expandedFlags: false,
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(
      ({ items }) => ({
        items: arrayMoveControls(items, oldIndex, newIndex),
      }),
      () => {
        this.cambiarOrden();
      }
    );
  };

  toggleConfigurationDrawer = (side, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    this.setState({ [side]: open });
  };

  getLandmarkTypes = async (token) => {
    const landmarkTypes = await API.getLandmarkTypes(token);
    console.log("LandmarkTypes", landmarkTypes);
    if (Array.isArray(landmarkTypes)) {
      landmarkTypes.push({ IdTipoPunto: 0, Nombre: "Ninguno" });
      this.setState({ listaTipoLandmark: landmarkTypes });
    }
  };

  handleAddChip = async (nombreItem) => {
    let vIsSignedIn = await isSignedIn();
    const request = await API.post(
      `api/campo/crearItemList/${this.state.codigoCampo}/${nombreItem}/${vIsSignedIn.userName}`
    );
    this.cargarPickListItems();
  };

  handleDeleteChip = async (nombreItem) => {
    let vIsSignedIn = await isSignedIn();
    const request = await API.post(
      `api/campo/eliminarItemList/${this.state.codigoCampo}/${nombreItem}/${vIsSignedIn.userName}`
    );
    this.cargarPickListItems();
  };

  cargarPickListItems = async () => {
    const request = await API.fetchGetRequest(
      `api/campo/listaItemsPickList/${this.state.codigoCampo}`
    );

    this.setState({
      listaItemsPickList: request.data.data,
    });
  };

  async componentDidMount() {
    //CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
    const llaveAgrupacion = "Menu.Principal.Web";
    const llavePagina = "Menu.Principal.Web.Administrar.Aviso";
    await validaPermisoPaginaActual(llaveAgrupacion, llavePagina);
    //FIN DE CONSULTA DE PERMISOS

    if (
      this.props.match.params.codigoProblema === null ||
      this.props.match.params.codigoProblema === undefined
    ) {
      window.location = "/home";
    } else if (!isNaN(this.props.match.params.codigoProblema)) {
      //Cargar lista subentidades
      this.setState(
        { CodigoProblema: parseInt(this.props.match.params.codigoProblema) },
        async () => {
          //Valida si el rol del usuario tiene acceso a este recurso

          let permisos = await consultarPermiso(
            this.state.CodigoProblema,
            "AITProblema"
          );
          console.log("permisos", permisos);
          if (!permisos.U) {
            toast.warn("No tienes permiso para ejecutar este elemento");
            setTimeout(() => {
              window.location.href = "/admongestiones";
            }, 3000);
          }

          await this.cargarDatosEdicionProblema();
          //Familia de etiquetas
          this.cargarListaFamiliaEtiquetas();
          //Etiquetas
          this.cargarListaEtiquetas();

          //Etiquetas asignadas
          this.cargarListaEtiquetasAsignadas();

          //Lista de unidades de medida de tiempo
          this.cargarListaUnidadesTiempo();

          //Lista Plantilla Notificaciones
          this.cargarListaPlantillaNotificaciones();

          this.getLandmarkTypes();
        }
      );
    }
  }

  async cargarListaFamiliaEtiquetas() {
    const request = await API.fetchGetRequest(`api/familiaetiqueta/listar`);
    if (Array.isArray(request.data.data)) {
      let listaFamilias = request.data.data;
      listaFamilias.push({
        CodigoFamiliaEtiqueta: 0,
        Nombre: "Todas",
        Descripcion: "",
        Activo: true,
      });

      this.setState({
        listaFamiliasEtiquetas: listaFamilias,
      });
    }
  }

  async cargarListaEtiquetas() {
    const request = await API.fetchGetRequest(`api/causa/listarTodas`);
    if (Array.isArray(request.data.data)) {
      this.setState({
        listaEtiquetas: request.data.data,
      });
    }
  }

  async cargarListaPlantillaNotificaciones() {
    const request = await API.fetchGetRequest(`api/plantillaalerta/listar`);
    if (Array.isArray(request.data.data)) {
      let lista = request.data.data;
      lista.push({ CodigoPlantillaAlerta: 0, Nombre: "Ninguna" });
      console.log("LIsta plantillas notify", lista);
      this.setState({
        listaPlantillasAlerta: lista,
      });
    }
  }

  async cargarListaUnidadesTiempo() {
    const request = await API.fetchGetRequest(
      `api/problema/listarUnidadMedidaTiempo`
    );
    if (Array.isArray(request.data.data)) {
      let lista = request.data.data;
      lista.push({ CodigoUnidadMedida: 0, Nombre: "Seleccione" });
      this.setState({
        listaUnidadMedida: lista,
      });
    }
  }

  async cargarListaEtiquetasAsignadas() {
    const request = await API.fetchGetRequest(
      `api/problema/listarEtiquetas/${this.state.codigoProblema}`
    );
    if (Array.isArray(request.data.data)) {
      this.setState({
        listaEtiquetasAsignadas: request.data.data,
      });
    }
  }

  async cargarDatosEdicionProblema() {
    const request = await API.fetchGetRequest(
      "api/problema/cargarDatosEdicion/" + this.state.CodigoProblema
    );

    let fechaInicioymd =
      request.data.data.FechaInicio == null
        ? null
        : convertYMD(request.data.data.FechaInicio, "-");
    let fechaFinymd =
      request.data.data.FechaFin == null
        ? null
        : convertYMD(request.data.data.FechaFin, "-");
    let tiempoEsperaEstimadoSegundos = request.data.data.TiempoEsperaEstimado;
    let tiempoEsperaEstimadoHHmm = convertirHHMM(tiempoEsperaEstimadoSegundos);

    let problema = {
      codigoProblema: request.data.data.CodigoProblema,
      codigoSubEntidad: request.data.data.CodigoSubEntidad,
      codigoPlantilla: request.data.data.CodigoPlantilla,
      nombre: request.data.data.Nombre,
      descripcion: request.data.data.Descripcion,
      popular: request.data.data.Popular,
      tiempoEsperaEstimado: tiempoEsperaEstimadoHHmm,
      fechaInicio: fechaInicioymd,
      fechaFin: fechaFinymd,
      esEditable: request.data.data.EsEditable,
      activo: request.data.data.Activo,
      tiempoSemaforoAmarillo:
        request.data.data.TiempoSemaforoAmarillo === null
          ? ""
          : request.data.data.TiempoSemaforoAmarillo,
      unidadSemaforoAmarillo:
        request.data.data.UnidadSemaforoAmarillo === null
          ? 0
          : request.data.data.UnidadSemaforoAmarillo,
      tiempoSemaforoRojo:
        request.data.data.TiempoSemaforoRojo === null
          ? ""
          : request.data.data.TiempoSemaforoRojo,
      unidadSemaforoRojo:
        request.data.data.UnidadSemaforoRojo === null
          ? 0
          : request.data.data.UnidadSemaforoRojo,
      encuestaSatisfaccion:
        request.data.data.EncuestaSatisfaccion === null
          ? false
          : request.data.data.EncuestaSatisfaccion,
      aplicaFechaVencimiento:
        request.data.data.AplicaFechaVencimiento === null
          ? false
          : request.data.data.AplicaFechaVencimiento,
      muestraFechaVencimientoCreador:
        request.data.data.MuestraFechaVencimientoCreador === null
          ? false
          : request.data.data.MuestraFechaVencimientoCreador,
      permiteAgregarTareas:
        request.data.data.PermiteAgregarTareas === null
          ? false
          : request.data.data.PermiteAgregarTareas,
      codigoPlantillaNotificaciones:
        request.data.data.CodigoPlantillaNotificaciones === null
          ? ""
          : request.data.data.CodigoPlantillaNotificaciones,
      ocultarDescripcion: request.data.data.OcultarDescripcion,
      ocultarPlantillaAlerta: request.data.data.OcultarPlantillaAlerta,
      ocultarEtiquetas: request.data.data.OcultarEtiquetas,
      TomaAsistenciaReconocimientoFacial:
        request.data.data.TomaAsistenciaReconocimientoFacial,
      TomaAsistenciaQR: request.data.data.TomaAsistenciaQR,
      TomaAsistenciaDPI: request.data.data.TomaAsistenciaDPI,
      CodigoFormularioAsistencia: request.data.data.CodigoFormularioAsistencia,
      TomaAsistenciaFotografia: request.data.data.TomaAsistenciaFotografia,
      TomaAsistenciaGenerica: request.data.data.TomaAsistenciaGenerica,
      TituloAsistencia: request.data.data.TituloAsistencia,
      TituloParticipantes: request.data.data.TituloParticipantes,
      ProblemaApi: request.data.data.ProblemaApi,
      codigoTipoLandmark: request.data.data.CodigoTipoLandmark,
    };

    console.log("log: " + request.data.data.CodigoFormularioAsistencia);
    console.log('PLANTILLA_TAREAS:', request.data.data.CodigoPlantillaTareas);
    this.setState(
      {
        codigoProblema: request.data.data.CodigoProblema,
        codigoPlantilla: request.data.data.CodigoPlantilla,
        tiempoEsperaEstimadoSegundos: tiempoEsperaEstimadoSegundos,
        problema: problema,
        codigoPlantillaTareas: request.data.data.CodigoPlantillaTareas,
        objetoApiConfig: request.data.data.ProblemaApi,
        showProblemaApiConfig: true,
      },

      () => {
        console.log("state:", this.state);
        //Datos de plantilla y tareas
        this.cargarDatosPlantillaTareas();
      }
    );
  }

  handleChange = (name) => (event) => {
    if (name === "activo" || name === "requeridoCampo") {
      this.setState({ [name]: event.target.checked });
    } else {
      this.setState({
        [name]: event.target.value,
      });
    }
  };

  handleChangeProblema = (name) => (event) => {
    console.log("name:", name);
    if (
      name === "activo" ||
      name === "popular" ||
      name === "esEditable" ||
      name === "encuestaSatisfaccion" ||
      name === "permiteAgregarTareas" ||
      name === "ocultarDescripcion" ||
      name === "ocultarPlantillaAlerta" ||
      name === "ocultarEtiquetas" ||
      name === "TomaAsistenciaFotografia" ||
      name === "TomaAsistenciaReconocimientoFacial" ||
      name === "TomaAsistenciaQR" ||
      name === "TomaAsistenciaDPI" ||
      name === "TomaAsistenciaGenerica"
    ) {
      // this.setState({ [name]: event.target.checked });

      const { problema } = { ...this.state };
      const currentState = problema;
      const { checked } = event.target;
      currentState[name] = checked;
      console.log("name:", name);
      console.log("value", checked);

      this.setState({ problema: currentState });
    } else if (name === "aplicaFechaVencimiento") {
      const { problema } = { ...this.state };
      const currentState = problema;
      const { checked } = event.target;
      currentState[name] = checked;
      if (checked == false) {
        currentState["muestraFechaVencimientoCreador"] = checked;
      }
      this.setState({ problema: currentState });
    } else if (name === "muestraFechaVencimientoCreador") {
      const { problema } = { ...this.state };
      const currentState = problema;
      const { checked } = event.target;

      const aplicaCheck = currentState["aplicaFechaVencimiento"];

      if (aplicaCheck == false) {
        currentState[name] = false;
        toast.warn(
          "Para validar este campo debe validarse la aplicación de Fecha"
        );
      } else {
        currentState[name] = checked;
      }

      this.setState({ problema: currentState });
    } else {
      // this.setState({
      //   [name]: event.target.value
      // });

      const { problema } = { ...this.state };
      const currentState = problema;
      const { value } = event.target;
      currentState[name] = value;

      //Si el campo que se actualiza es el tiempo de espera debe calcular su valor en segundos
      if (name === "tiempoEsperaEstimado") {
        let segundos = convertirASegundos(value);
        this.setState({ tiempoEsperaEstimadoSegundos: segundos });
        currentState.tiempoEsperaEstimado = value;
      }

      this.setState({ problema: currentState });
    }
  };

  actualizarProblema = async (item) => {
    const {
      nombre,
      fechaInicio,
      fechaFin,
      tiempoSemaforoAmarillo,
      tiempoSemaforoRojo,
      unidadSemaforoAmarillo,
      unidadSemaforoRojo,
      encuestaSatisfaccion,
      aplicaFechaVencimiento,
      muestraFechaVencimientoCreador,
      permiteAgregarTareas,
      codigoPlantillaNotificaciones,
      ocultarDescripcion,
      ocultarPlantillaAlerta,
      ocultarEtiquetas,
      TomaAsistenciaReconocimientoFacial,
      TomaAsistenciaQR,
      TomaAsistenciaDPI,
      CodigoFormularioAsistencia,
      TomaAsistenciaFotografia,
      TomaAsistenciaGenerica,
      TituloAsistencia,
      TituloParticipantes,
      codigoTipoLandmark,
    } = this.state.problema;
    const request = await API.fetchGetRequest(
      "api/problema/cargarDatosEdicion/" + this.state.CodigoProblema
    );
    if (request.data.data.CodigoPlantillaTareas === null) {
      toast.error("A esta gestión le falta flujo de tareas");
      return;
    }
    if (nombre.trim() == "") {
      toast.warn("Ingrese un nombre válido");
      return;
    }

    //Valida fechas
    if (!isNaN(Date.parse(fechaInicio)) || !isNaN(Date.parse(fechaFin))) {
      //Si una de las dos no es fecha
      if (
        (!isNaN(Date.parse(fechaInicio)) &&
          !isNaN(Date.parse(fechaFin)) === false) ||
        (!isNaN(Date.parse(fechaFin)) &&
          !isNaN(Date.parse(fechaInicio)) === false)
      ) {
        toast.warn("Olvidó ingresar una de las fechas");
        return;
      }

      //Si la fecha inicial es mayor que la final
      if (new Date(fechaInicio) > new Date(fechaFin)) {
        toast.warn("La fecha de inicio no debe ser mayor que la final");
        return;
      }
    }

    //Valida semáforo amarillo
    if (tiempoSemaforoAmarillo.length > 0 || unidadSemaforoAmarillo > 0) {
      if (tiempoSemaforoAmarillo === "" || unidadSemaforoAmarillo === 0) {
        toast.warn("Por favor verifica los datos del semáforo amarillo");
        return;
      }
    }

    //Valida semáforo rojo
    if (tiempoSemaforoRojo.length > 0 || unidadSemaforoRojo > 0) {
      if (tiempoSemaforoRojo === "" || unidadSemaforoRojo === 0) {
        toast.warn("Por favor verifica los datos del semáforo rojo");
        return;
      }
    }

    try {
      let vIsSignedIn = await isSignedIn();
      let palabra = "";
      if (vIsSignedIn.response) {
        let request;
        let data;

        data = {
          CodigoProblema: this.state.problema.codigoProblema,
          CodigoSubEntidad: this.state.problema.codigoSubEntidad,
          Nombre: this.state.problema.nombre,
          Descripcion: this.state.problema.descripcion,
          Popular: this.state.problema.popular,
          TiempoEsperaEstimado: this.state.tiempoEsperaEstimadoSegundos,
          Activo: this.state.problema.activo,
          Eliminado: false,
          UsuarioOpera: vIsSignedIn.userName,
          CodigoPlantilla: this.state.problema.codigoPlantilla,
          CodigoPlantillaTareas: null,
          FechaInicio: this.state.problema.fechaInicio,
          FechaFin: this.state.problema.fechaFin,
          EsEditable: this.state.problema.esEditable,
          TiempoSemaforoRojo: !isNaN(tiempoSemaforoRojo)
            ? parseInt(tiempoSemaforoRojo)
            : null,
          TiempoSemaforoAmarillo: !isNaN(tiempoSemaforoAmarillo)
            ? parseInt(tiempoSemaforoAmarillo)
            : null,
          UnidadSemaforoRojo:
            unidadSemaforoRojo === 0 ? null : unidadSemaforoRojo,
          UnidadSemaforoAmarillo:
            unidadSemaforoAmarillo === 0 ? null : unidadSemaforoAmarillo,
          EncuestaSatisfaccion:
            encuestaSatisfaccion === null ? false : encuestaSatisfaccion,
          AplicaFechaVencimiento:
            aplicaFechaVencimiento === null ? false : aplicaFechaVencimiento,
          MuestraFechaVencimientoCreador:
            muestraFechaVencimientoCreador === null
              ? false
              : muestraFechaVencimientoCreador,
          PermiteAgregarTareas:
            permiteAgregarTareas === null ? false : permiteAgregarTareas,
          CodigoPlantillaNotificaciones:
            codigoPlantillaNotificaciones === 0
              ? null
              : codigoPlantillaNotificaciones,
          OcultarDescripcion: ocultarDescripcion,
          OcultarPlantillaAlerta: ocultarPlantillaAlerta,
          OcultarEtiquetas: ocultarEtiquetas,
          TomaAsistenciaReconocimientoFacial,
          TomaAsistenciaQR,
          TomaAsistenciaDPI,
          CodigoFormularioAsistencia,
          TomaAsistenciaFotografia,
          TomaAsistenciaGenerica,
          TituloAsistencia,
          TituloParticipantes,
          ProblemaApi: this.state.objetoApiConfig,
          ParametrosApi: this.state.objetoApiConfig.ParametrosApi,
          CodigoTipoLandmark:
            codigoTipoLandmark === 0 ? null : codigoTipoLandmark,
        };
        request = await API.post(`api/problema/grabarEditarTipoGestion`, data);

        if (request.status != 200) {
          alert(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        }

        if (request.statusCode === 401) {
          //alert("cerrar sesion");
        } else {
          toast.success("El registro se actualizó exitosamente.");
          this.cargarDatosEdicionProblema();
        }
      }
    } catch (e) {
      alert(e);
    }
  };

  handleChangeTabs = (event, newValue) => {
    console.log("valorTab:", newValue);
    this.setState({ valorTab: newValue });
  };

  handleChangeIndexTabs = (index) => {
    console.log("valorTab:", index);
    this.setState({ valorTab: index });
  };

  handleOpenChooseTemplate = () => {
    this.cargarListaEntidades();

    this.setState({
      openChooseTemplate: true,
    });
  };

  handleCloseChooseTemplate = () => {
    this.setState({
      openChooseTemplate: false,
      codigoEntidad: "",
      textoBusquedaFlujo: "",
    });
  };

  handleOpenChooseForm = () => {
    this.setState({
      openChooseForm: true,
    });
  };

  handleOpenChooseFormAsistencia = () => {
    this.setState({
      openChooseFormAsistencia: true,
    });
  };

  handleCloseChooseForm = () => {
    this.setState({
      openChooseForm: false,
      textoBusquedaForm: "",
    });
  };
  handleCloseChooseFormAsistencia = () => {
    this.setState({
      openChooseFormAsistencia: false,
      TextoBusquedaFormAsistencia: "",
    });
  };

  handleOpenNewForm = () => {
    this.setState({
      openNewForm: true,
    });
  };

  nuevoFlujo = async () => {
    const { problema } = this.state;

    if (problema.nombre.trim() == "") {
      toast.warn("el nombre del tipo de gestión es invalido");
      return;
    }

    try {
      let vIsSignedIn = await isSignedIn();
      if (vIsSignedIn.response) {
        let request;
        let data;
        data = {
          CodigoPlantillaTareas: null,
          Nombre: problema.nombre,
          Descripcion: `Flujo_problema_${problema.codigoProblema}`,
          Activo: true,
          UsuarioOpera: vIsSignedIn.userName,
          CodigoEntidad:
            this.state.codigoEntidad === "" || this.state.codigoEntidad === 0
              ? null
              : this.state.codigoEntidad,
        };

        request = await API.post(`api/flujo/grabarEditar`, data);

        if (request.status != 200) {
          alert(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        }

        if (request.statusCode === 401) {
          //alert("cerrar sesion");
        } else {
          toast.success("El flujo se creó exitosamente.");
          //Setear el código de flujo al problema local
          console.log("grabar plantilla", request);
          this.setState({
            codigoPlantillaTareas: request.data.data.CodigoPlantillaTareas,
          });
          let datos = {
            codigoProblema: this.state.codigoProblema,
            codigoPlantillaTareas: request.data.data.CodigoPlantillaTareas,
            usuarioOpera: vIsSignedIn.userName,
          };
          const reqUPD = await API.post(
            `api/problema/asignarPlantillaTareas`,
            datos
          );

          //Cargar Tareas

          if (request.status != 200) {
            alert(
              "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
            );
          }
        }
      }
    } catch (e) {
      alert("hubo un error");
    }
  };

  handleOpenNewFormAsistencia = () => {
    this.setState({
      openNewFormAsistencia: true,
    });
  };

  handleCloseNewForm = () => {
    this.setState({
      openNewForm: false,
      txtNombreForm: "",
    });
  };

  handleCloseNewFormAsistencia = () => {
    this.setState({
      openNewFormAsistencia: false,
      txtNombreFormAsistencia: "",
    });
  };

  cargarListaEntidades = async () => {
    const request = await API.fetchGetRequest(`api/entidad/listarTodas`);
    try {
      if (Array.isArray(request.data.data)) {
        this.setState({
          listaEntidades: request.data.data,
        });
      }
    } catch (error) {
      console.log("cargarListaEntidades error:" + error);
    }
  };

  buscarForm = async (tipoAsignacion) => {
    let criterio;
    if (tipoAsignacion === "tipoGestion") {
      criterio =
        this.state.textoBusquedaForm === null ||
        this.state.textoBusquedaForm === undefined
          ? ""
          : this.state.textoBusquedaForm;
    } else if (tipoAsignacion === "asistencia") {
      criterio =
        this.state.TextoBusquedaFormAsistencia === null ||
        this.state.TextoBusquedaFormAsistencia === undefined
          ? ""
          : this.state.TextoBusquedaFormAsistencia;
    }

    let data = {
      codigoEntidad: null,
      criterio: criterio,
    };
    const request = await API.post(`api/form/buscar`, data);
    try {
      if (Array.isArray(request.data.data)) {
        this.setState({
          listaForms: request.data.data,
        });
      }
    } catch (error) {
      console.log("cargarListaForms error:" + error);
    }
  };

  confirmarCambioForm = (codigoForm) => {
    this.setState({
      confirmTextoCambioForm: `¿Confirma que desea reemplazar el formulario actual por este nuevo?`,
      confirmOpenCambioForm: true,
      codigoFormPendienteconfirmar: codigoForm,
    });
  };

  funcionOkCamioForm = () => {
    this.cambiarForm();
  };

  cerrarConfirmCambioForm = () => {
    this.setState({
      confirmTextoCambioForm: "",
      confirmOpenCambioForm: false,
    });
  };
  asignarForm = async (codigoForm) => {
    if (
      this.state.codigoPlantilla === "" ||
      this.state.codigoPlantilla === null ||
      this.state.codigoPlantilla === undefined
    ) {
      this.setState({ codigoFormPendienteconfirmar: codigoForm }, () => {
        this.cambiarForm();
      });
    } else {
      this.confirmarCambioForm(codigoForm);
    }
  };

  asignarFormAsistencia = async (codigoForm) => {
    this.cambiarFormAsistencia(codigoForm);
  };

  cambiarForm = async () => {
    try {
      let vIsSignedIn = await isSignedIn();

      if (vIsSignedIn.response) {
        let data = {
          codigoProblema: this.state.codigoProblema,
          codigoPlantillaForm: this.state.codigoFormPendienteconfirmar,
          usuarioOpera: vIsSignedIn.userName,
        };
        const request = await API.post(`api/form/asignarFormulario`, data);
        if (request.status != 200) {
          alert(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        }

        if (request.statusCode === 401) {
          //alert("cerrar sesion");
        } else {
          toast.success("Formulario asignado exitosamente.");
          this.handleCloseChooseForm();
          await this.cargarDatosEdicionProblema();
        }
      } else {
        toast.error("La sessión no se encuentra activa");
      }
    } catch (e) {
      alert(e);
    } finally {
      this.setState({ codigoPlantillaTareasPendienteconfirmar: "" });
      this.handleCloseChooseForm();
    }
  };

  cambiarFormAsistencia = async (codigoForm) => {
    try {
      let vIsSignedIn = await isSignedIn();

      if (vIsSignedIn.response) {
        let data = {
          codigoProblema: this.state.codigoProblema,
          codigoFormularioAsistencia: codigoForm,
          usuarioOpera: vIsSignedIn.userName,
        };
        const request = await API.post(
          `api/form/asignarFormularioAsistencia`,
          data
        );
        if (request.status != 200) {
          alert(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        }

        if (request.statusCode === 401) {
          //alert("cerrar sesion");
        } else {
          let problema = this.state.problema;
          problema = { ...problema, CodigoFormularioAsistencia: codigoForm };
          this.setState({ problema });
          toast.success("Formulario asignado exitosamente.");
          this.handleCloseChooseFormAsistencia();
          await this.cargarDatosEdicionProblema();
        }
      } else {
        toast.error("La sessión no se encuentra activa");
      }
    } catch (e) {
      alert(e);
    } finally {
      this.setState({ codigoPlantillaTareasPendienteconfirmar: "" });
      this.handleCloseChooseForm();
    }
  };

  buscarPlantillas = async () => {
    let codigo =
      this.state.codigoEntidad == 0 || this.state.codigoentidad === ""
        ? null
        : this.state.codigoEntidad;
    let criterio =
      this.state.textoBusquedaFlujo === null ||
      this.state.textoBusquedaFlujo === undefined
        ? ""
        : this.state.textoBusquedaFlujo;
    let data = {
      codigoEntidad: codigo,
      criterio: criterio,
    };
    const request = await API.post(`api/flujo/buscar`, data);
    try {
      if (Array.isArray(request.data.data)) {
        this.setState({
          listaFlujos: request.data.data,
        });
      }
    } catch (error) {
      console.log("cargarListaEntidades error:" + error);
    }
  };

  asignarPlantilla = async (codigoPlantillaTarea) => {
    if (
      this.state.codigoPlantillaTareas === "" ||
      this.state.codigoPlantillaTareas === null ||
      this.state.codigoPlantillaTareas === undefined
    ) {
      this.setState(
        { codigoPlantillaTareasPendienteconfirmar: codigoPlantillaTarea },
        () => {
          this.cambiarPlantilla();
        }
      );
    } else {
      this.confirmarAccionCambioPlantilla(codigoPlantillaTarea);
    }
  };

  confirmarAccionCambioPlantilla = (codigoPlantillaTareas) => {
    this.setState({
      confirmTextoCambioPlantilla: `¿Confirma que desea reemplazar la plantilla actual por esta nueva?`,
      confirmOpenCambioPlantilla: true,
      codigoPlantillaTareasPendienteconfirmar: codigoPlantillaTareas,
    });
  };

  funcionOkCamioPlantilla = () => {
    this.cambiarPlantilla();
  };

  cerrarConfirmCambioPlantilla = () => {
    this.setState({
      confirmTextoCambioPlantilla: "",
      confirmOpenCambioPlantilla: false,
    });
  };

  cambiarPlantilla = async () => {
    try {
      let vIsSignedIn = await isSignedIn();

      if (vIsSignedIn.response) {
        let data = {
          codigoProblema: this.state.codigoProblema,
          codigoPlantillaTareas:
            this.state.codigoPlantillaTareasPendienteconfirmar,
          usuarioOpera: vIsSignedIn.userName,
        };
        const request = await API.post(
          `api/problema/asignarPlantillaTareas`,
          data
        );
        if (request.status != 200) {
          alert(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        }

        if (request.statusCode === 401) {
          //alert("cerrar sesion");
        } else {
          toast.success("Plantilla asignada exitosamente.");
          await this.cargarDatosEdicionProblema();
          this.cargarListaTareas();
        }
      } else {
        toast.error("La sessión no se encuentra activa");
      }
    } catch (e) {
      alert(e);
    } finally {
      this.setState({ codigoPlantillaTareasPendienteconfirmar: "" });
      this.handleCloseChooseTemplate();
    }
  };

  async cargarDatosPlantillaTareas() {
    if (
      this.state.codigoPlantillaTareas === null ||
      this.state.codigoPlantillaTareas === "" ||
      this.state.codigoPlantillaTareas === undefined
    ) {
      return;
    }

    const request = await API.fetchGetRequest(
      `api/flujo/cargarDatosEdicion/${this.state.codigoPlantillaTareas}`
    );
    this.setState(
      {
        codigoPlantillaTareas: request.data.data.CodigoPlantillaTareas,
        nombrePlantillaTarea: request.data.data.Nombre,

        codigoTareaPadre: this.state.codigoPlantillaTareas,
        nombreTareaPadre: request.data.data.Nombre,
        llaveTareaPadre: "AITPlantillaTareas",
      },
      //Carga tareas para la plantilla
      () => {
        this.cargarListaTareas();
      }
    );
  }

  cargarListaTareas = async () => {
    let codigoTareaPadre = this.state.codigoTareaPadre;
    let nombreTareaPadre = this.state.nombreTareaPadre;
    let llaveTareaPadre = this.state.llaveTareaPadre;

    // const request = await API.fetchGetRequest(
    //   `api/flujo/listaTareasProblema/${this.state.codigoPlantillaTareas}/${this.state.codigoProblema}`
    // );

    let params = {
      codigoPadre: codigoTareaPadre,
      codigoProblema: this.state.codigoProblema,
      llave: llaveTareaPadre,
    };
    const request = await API.post(`api/flujo/listaTareasProblemaPost`, params);
    try {
      if (Array.isArray(request.data.data)) {
        this.setState({
          listaTareas: request.data.data,
        });
      } else {
        this.setState({
          listaTareas: [],
        });
      }
    } catch (error) {
      console.log("cargarListaTareas error:" + error);
    }
  };

  ListaTareasCall = async (codigo, nombre, llave) => {
    this.setState(
      {
        codigoTareaPadre: codigo,
        nombreTareaPadre: nombre,
        llaveTareaPadre: llave,
      },
      () => {
        this.cargarListaTareas();
      }
    );
  };

  flujoElement = (
    nombre,
    numeroTareas,
    descripcion,
    nombreEntidad,
    codigoPlantillaTareas
  ) => {
    return (
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <Paper
          className="flujoCard"
          style={{ padding: 10 }}
          onClick={() => {
            this.asignarPlantilla(codigoPlantillaTareas);
          }}
        >
          <div className="iconoFlujoContainer">
            <img src={plantillaIcon} class="plantillaIcono" alt="icono" />
          </div>
          <div className="nombreFlujo">{nombre}</div>
          {/* <div className="descripcionFlujo">
          {descripcion}
        </div> */}
          <div className="numeroTareasFlujo">
            <Badge badgeContent={numeroTareas} color="primary">
              Tareas: &nbsp;&nbsp;
            </Badge>
          </div>

          {/* <div className="nombreEntidadFlujo">
            {nombreEntidad !== null &&
            nombreEntidad !== undefined &&
            nombreEntidad !== "" ? (
              nombreEntidad
            ) : (
              <span>&nbsp;&nbsp;</span>
            )}
          </div> */}
        </Paper>
      </Grid>
    );
  };

  handleOpenAsignarUsuarios = (codigoTarea, updateFnCallback) => {
    console.log("codigoTarea: " + codigoTarea);
    this.setState({
      openAsignarUsuarios: true,
      codigoTareaPlantilla: codigoTarea,
      updateFnCallback: updateFnCallback,
    });
  };

  handleCloseAsignarUsuarios = () => {
    this.setState({
      openAsignarUsuarios: false,
      txtTextoBusquedaUsuario: "",
      codigoTareaPlantilla: "",
      listaUsuarios: [],
    });
  };

  cargarListaUsuarios = async () => {
    let criterio =
      this.state.txtTextoBusquedaUsuario === "" ||
      this.state.txtTextoBusquedaUsuario === null ||
      this.state.txtTextoBusquedaUsuario === undefined
        ? "x-x"
        : this.state.txtTextoBusquedaUsuario;

    let tipoBusqueda = this.state.tipoBusquedaAsignado;
    let request = null;
    if (tipoBusqueda === "Usuario") {
      request = await API.fetchGetRequest(`api/Usuario/buscar/${criterio}`);
    } else if (tipoBusqueda === "Rol") {
      request = await API.fetchGetRequest(`api/Usuario/buscarRol/${criterio}`);
    } else if (tipoBusqueda === "RolDinamico") {
      request = await API.fetchGetRequest(
        `api/Usuario/buscarRolDinamico/${criterio}`
      );
    }

    try {
      if (Array.isArray(request.data.usuarios)) {
        this.setState({
          listaUsuarios: request.data.usuarios,
          tipoBusquedaEjecutada: tipoBusqueda,
        });
      } else {
        this.setState({
          listaUsuarios: [],
          tipoBusquedaEjecutada: tipoBusqueda,
        });
      }
    } catch (error) {
      console.log("cargarListaTareas error:" + error);
    }
  };

  seleccionarUsuario = async (codigoUsuario) => {
    try {
      let vIsSignedIn = await isSignedIn();

      if (vIsSignedIn.response) {
        let data = {
          codigoProblema: this.state.codigoProblema,
          codigoTarea: this.state.codigoTareaPlantilla,
          codigoUsuario: codigoUsuario,
          usuarioOpera: vIsSignedIn.userName,
          tipoUsuarioRol: this.state.tipoBusquedaEjecutada,
        };
        const request = await API.post(
          `api/flujo/grabarEditarUsuarioTareaProblema`,
          data
        );
        if (request.status != 200) {
          alert(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        }

        console.log("data", data);

        if (request.statusCode === 401) {
          //alert("cerrar sesion");
        } else {
          toast.success("Usuario asignado exitosamente.");
          this.cargarListaTareas();
          try {
            this.state.updateFnCallback();
          } catch (error) {
            console.error(
              "Error al actualizar lista de tareas del componente hijo",
              error
            );
          }
        }
      } else {
        toast.error("La sessión no se encuentra activa");
      }
    } catch (e) {
      alert(e);
    } finally {
      this.setState({ codigoPlantillaTareasPendienteconfirmar: "" });
      this.handleCloseAsignarUsuarios();
    }
  };

  eliminarUsuarioTareaProblema = async (codigoGrupoUsuario) => {
    try {
      let vIsSignedIn = await isSignedIn();

      if (vIsSignedIn.response) {
        const request = await API.post(
          `api/flujo/eliminarUsuarioTareaProblema/${codigoGrupoUsuario}/${vIsSignedIn.userName}`
        );
        if (request.status != 200) {
          alert(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        }

        if (request.statusCode === 401) {
          //alert("cerrar sesion");
        } else {
          toast.success("Usuario removido exitosamente.");
          this.cargarListaTareas();
        }
      } else {
        toast.error("La sessión no se encuentra activa");
      }
    } catch (e) {
      alert(e);
    } finally {
      this.setState({ codigoPlantillaTareasPendienteconfirmar: "" });
      this.handleCloseAsignarUsuarios();
    }
  };

  busquedaEtiqueta = async () => {
    console.log("Búsqueda etiqueta");
    let textoBusquedaEtiqueta = this.state.textoBusquedaEtiqueta;

    // if (textoBusquedaEtiqueta.length > 0) {
    let filtro = this.state.listaEtiquetas.filter((fila) => {
      return (
        fila.name.toLowerCase().includes(textoBusquedaEtiqueta.toLowerCase()) &&
        (this.state.codigoFamiliaEtiquetaSeleccionada === 0
          ? true
          : fila.codigoFamiliaEtiqueta ===
            this.state.codigoFamiliaEtiquetaSeleccionada)
      );
    });
    this.setState({ listaEtiquetasCopia: filtro });
    // } else {
    //   this.setState({listaEtiquetasCopia:this.state.listaEtiquetas});
    // }
  };

  setSearchEtiqueta = (name) => (event) => {
    this.setState({ textoBusquedaEtiqueta: event.target.value }, () => {
      this.busquedaEtiqueta();
    });
  };

  asignarEtiqueta = async (codigoEtiqueta) => {
    try {
      let vIsSignedIn = await isSignedIn();
      if (vIsSignedIn.response) {
        let request;
        let data;
        data = {
          CodigoProblema: this.state.codigoProblema,
          CodigoEtiqueta: codigoEtiqueta,
          UsuarioOpera: vIsSignedIn.userName,
        };
        request = await API.post(
          `api/problema/grabarEditarEtiquetaProblema`,
          data
        );
        if (request.status != 200) {
          alert(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        }

        if (request.statusCode === 401) {
          //alert("cerrar sesion");
        } else {
          toast.success("La etiqueta se asignó exitosamente.");
          this.cargarListaEtiquetasAsignadas();
        }
      }
    } catch (e) {
      alert("hubo un error");
    }
  };

  async eliminarEtiquetaAsignada(codigo) {
    let vIsSignedIn = await isSignedIn();

    if (vIsSignedIn.response) {
      const request = await API.post(
        `api/problema/EliminarProblemaEtiqueta/${codigo}/${vIsSignedIn.userName}`
      );
      if (request.status != 200) {
        alert(
          "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
        );
      }

      if (request.statusCode === 401) {
        //alert("cerrar sesion");
      } else {
        toast.success("La etiqueta se removió exitosamente.");
        this.cargarListaEtiquetasAsignadas();
      }
    } else {
      toast.error("La sessión no se encuentra activa");
    }
  }

  grabarNuevoForm = async () => {
    const { txtNombreForm, codigoProblema } = this.state;

    if (txtNombreForm.trim() == "") {
      toast.warn("Ingrese un nombre válido");
      return;
    }

    try {
      let vIsSignedIn = await isSignedIn();
      let palabra = "";
      if (vIsSignedIn.response) {
        let request;
        let data;

        data = {
          CodigoPlantilla: this.state.codigo,
          Nombre: txtNombreForm,
          Activo: true,
          UsuarioOpera: vIsSignedIn.userName,
          codigoProblema: codigoProblema,
        };

        request = await API.post(`api/form/grabarAsignar`, data);
        if (request.status != 200) {
          alert(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        }

        if (request.statusCode === 401) {
          //alert("cerrar sesion");
        } else {
          toast.success("El registro se creó exitosamente.");

          //Redirecciona cuando la acción es grabar
          this.handleCloseNewForm();
          this.cargarDatosEdicionProblema();
        }
      }
    } catch (e) {
      alert("hubo un error");
    }
  };

  grabarNuevoFormAsistencia = async () => {
    const { txtNombreFormAsistencia, codigoProblema } = this.state;

    if (txtNombreFormAsistencia.trim() == "") {
      toast.warn("Ingrese un nombre válido");
      return;
    }

    try {
      let vIsSignedIn = await isSignedIn();
      let palabra = "";
      if (vIsSignedIn.response) {
        let request;
        let data;

        data = {
          //CodigoFormularioAsistencia: this.state.codigo,
          Nombre: txtNombreFormAsistencia,
          Activo: true,
          UsuarioOpera: vIsSignedIn.userName,
          codigoProblema: codigoProblema,
        };

        request = await API.post(`api/form/grabarAsignarAsistencia`, data);
        if (request.status != 200) {
          alert(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        }

        if (request.statusCode === 401) {
          //alert("cerrar sesion");
        } else {
          toast.success("El registro se creó exitosamente.");

          //Redirecciona cuando la acción es grabar
          this.handleCloseNewFormAsistencia();
          this.cargarDatosEdicionProblema();
        }
      }
    } catch (e) {
      alert("hubo un error");
    }
  };

  updateApiConfig = async (objeto) => {
    this.setState({ objetoApiConfig: objeto });
  };

  cambiarTextoNombreFn = async (texto)=>{
    this.updateNombreDescripcion('nombre', texto);
  }

  cambiarTextoDescripcionFn = async (texto)=>{
    this.updateNombreDescripcion('descripcion', texto);
  }

  updateNombreDescripcion =  async(campo, texto)=>{
    const data = {
      id: this.state.problema.codigoProblema,
      value: texto,
      aditional: campo,
    }
    const request = await API.post(`api/problema/updateTitleDescription`, data);

    if (request.status != 200) {
      alert(
        "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
      );
    }

    if (request.statusCode === 401) {
      //alert("cerrar sesion");
    } else {
      toast.success("Actualizado exitosamente.");
      let prob = this.state.problema
      let temp =  prob;
      temp[campo] = texto;
      this.setState({problema:temp})
    }
  }

  render() {
    const { classes } = this.props;
    const { items } = this.state;
    return (
      <Layout titulo="Configuración del tipo de gestión">
        <Paper className={classes.rootTab} style={{ width: "10000px" }}>
          <AppBar
            position="static"
            color="default"
            style={{ width: "100% !important" }}
          >
            <Tabs
              style={{ width: "100% !important" }}
              value={this.state.valorTab}
              onChange={this.handleChangeTabs}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab label="ENCABEZADO" {...a11yProps(0)} />
              <Tab label="TAREAS" {...a11yProps(1)} />
              <Tab label="CONFIGURACIÓN" {...a11yProps(2)} />
            </Tabs>
          </AppBar>
          <div
            style={{ width: "100% !important" }}
            // axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            // index={this.state.valorTab}
            // onChangeIndex={this.handleChangeIndexTabs}
          >
            <TabPanel value={this.state.valorTab} index={2}>
              <Grid container spacing={10}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <form
                    className={classes.container}
                    noValidate
                    autoComplete="off"
                  >
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        {/* Datos generales */}
                       {/* <Accordion
                          expanded={this.state.expandedGeneral}
                          onChange={() => {
                            this.setState({
                              expandedGeneral: !this.state.expandedGeneral,
                            });
                          }}
                        >
                           <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  width: "100%",
                                }}
                              >
                                <div>
                                  <Typography className={classes.heading}>
                                    {"Datos generales"}
                                    <span style={{ color: "gray" }}>
                                     
                                    </span>
                                  </Typography>
                                </div>
                              </div>
                            </div>
                          </AccordionSummary>
                          <AccordionDetails
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextField
                          required
                          id="txtNombre"
                          label="Nombre"
                          className={classes.textField}
                          value={this.state.problema.nombre}
                          onChange={this.handleChangeProblema("nombre")}
                          margin="normal"
                          inputProps={{ maxLength: 512 }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextField
                          id="txtDescripcion"
                          label="Descripción de uso"
                          className={classes.textField}
                          value={this.state.problema.descripcion}
                          onChange={this.handleChangeProblema("descripcion")}
                          margin="normal"
                          multiline
                          inputProps={{ maxLength: 512 }}
                        />
                      </Grid>
                
                          </AccordionDetails>
                        </Accordion> */}
                        {/* Fin de datos generales */}

                         {/* Acuerdos de nivel de servicio */}
                         <Accordion
                          expanded={this.state.expandedAcuerdosNivelServicio}
                          onChange={() => {
                            this.setState({
                              expandedAcuerdosNivelServicio: !this.state.expandedAcuerdosNivelServicio,
                            });
                          }}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  width: "100%",
                                }}
                              >
                                <div>
                                  <Typography className={classes.heading}>
                                    {"Acuerdos de nivel de servicio"}
                                    <span style={{ color: "gray" }}>
                                      {/* ({grupo.Gestiones.length}) */}
                                    </span>
                                  </Typography>
                                </div>
                              </div>
                            </div>
                          </AccordionSummary>
                          <AccordionDetails
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <FormControl style={{ width: "100%" }}>
                          <InputLabel htmlFor="formatted-text-mask-input">
                            Tiempo de espera (HH:mm)
                          </InputLabel>
                          <Input
                            value={this.state.problema.tiempoEsperaEstimado}
                            onChange={this.handleChangeProblema(
                              "tiempoEsperaEstimado"
                            )}
                            id="formatted-text-mask-input"
                            inputComponent={TextMaskCustom}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextField
                          id="FechaInicio"
                          label="Fecha inicio"
                          type="date"
                          defaultValue=""
                          value={this.state.problema.fechaInicio}
                          className={classes.textField}
                          onChange={this.handleChangeProblema("fechaInicio")}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextField
                          id="FechaFin"
                          label="Fecha fin"
                          type="date"
                          defaultValue=""
                          value={this.state.problema.fechaFin}
                          className={classes.textField}
                          onChange={this.handleChangeProblema("fechaFin")}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>

                          </AccordionDetails>
                        </Accordion>
                        {/* Fin de acuerdo de nivel de servicio */}

                         {/* Alertas de vencimiento */}
                         <Accordion
                          expanded={this.state.expandedAlertasVencimiento}
                          onChange={() => {
                            this.setState({
                              expandedAlertasVencimiento: !this.state.expandedAlertasVencimiento,
                            });
                          }}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  width: "100%",
                                }}
                              >
                                <div>
                                  <Typography className={classes.heading}>
                                    {"Alertas de vencimiento"}
                                    <span style={{ color: "gray" }}>
                                     
                                    </span>
                                  </Typography>
                                </div>
                              </div>
                            </div>
                          </AccordionSummary>
                          <AccordionDetails
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                                           <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography variant="h6">
                          Plantilla Frecuencia de alertas
                        </Typography>
                        <Grid container spacing={3}>
                          <Grid item xs={7} sm={7} md={7} lg={7}>
                            <FormControl
                              className={classes.formControl}
                              style={{ width: "100%", marginTop: "17px" }}
                            >
                              <InputLabel htmlFor="PlantillaAlerta">
                                Plantilla
                              </InputLabel>
                              <Select
                                name="plantillaAlerta"
                                inputRef={this.codigoPlantillaNotificacionesRef}
                                value={
                                  this.state.problema
                                    .codigoPlantillaNotificaciones
                                }
                                onChange={this.handleChangeProblema(
                                  "codigoPlantillaNotificaciones"
                                )}
                                input={
                                  <Input
                                    name="unidadPlantillaNotificaciones"
                                    id="unidadPlantillaNotificaciones"
                                  />
                                }
                              >
                                {this.state.listaPlantillasAlerta.map(
                                  (item) => (
                                    <MenuItem
                                      key={
                                        "listaPlantillaAlerta" +
                                        item.CodigoPlantillaAlerta
                                      }
                                      value={item.CodigoPlantillaAlerta}
                                    >
                                      {item.Nombre}
                                    </MenuItem>
                                  )
                                )}
                              </Select>
                              <FormHelperText />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <div>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  this.state.problema.ocultarPlantillaAlerta
                                }
                                onChange={this.handleChangeProblema(
                                  "ocultarPlantillaAlerta"
                                )}
                                value="Ocultar plantilla de alerta"
                                color="primary"
                              />
                            }
                            label="Ocultar plantilla de alerta"
                          />
                          <br />
                        </div>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography variant="h6">Semáforo amarillo</Typography>
                        <Grid container spacing={3}>
                          <Grid item xs={5} sm={5} md={5} lg={5}>
                            <TextField
                              type="number"
                              id="txtTiempoSemaforoAmarillo"
                              label="Tiempo"
                              className={classes.textField}
                              value={this.state.problema.tiempoSemaforoAmarillo}
                              onChange={this.handleChangeProblema(
                                "tiempoSemaforoAmarillo"
                              )}
                              margin="normal"
                            />
                          </Grid>
                          <Grid item xs={7} sm={7} md={7} lg={7}>
                            <FormControl
                              className={classes.formControl}
                              style={{ width: "100%", marginTop: "17px" }}
                            >
                              <InputLabel htmlFor="Unidad">Unidad</InputLabel>
                              <Select
                                name="unidadMedidaSemaforoAmarillo"
                                inputRef={this.unidadMedidaSemaforoAmarilloRef}
                                value={
                                  this.state.problema.unidadSemaforoAmarillo
                                }
                                onChange={this.handleChangeProblema(
                                  "unidadSemaforoAmarillo"
                                )}
                                input={
                                  <Input
                                    name="unidadMedidaSemaforoAmarillo"
                                    id="unidadMedidaSemaforoAmarillo"
                                  />
                                }
                              >
                                {this.state.listaUnidadMedida.map((item) => (
                                  <MenuItem
                                    key={"unidad" + item.CodigoUnidadMedida}
                                    value={item.CodigoUnidadMedida}
                                  >
                                    {item.Nombre}
                                  </MenuItem>
                                ))}
                              </Select>
                              <FormHelperText />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography variant="h6">Semáforo rojo</Typography>
                        <Grid container spacing={3}>
                          <Grid item xs={5} sm={5} md={5} lg={5}>
                            <TextField
                              type="number"
                              id="tiempoSemaforoRojo"
                              label="Tiempo"
                              className={classes.textField}
                              value={this.state.problema.tiempoSemaforoRojo}
                              onChange={this.handleChangeProblema(
                                "tiempoSemaforoRojo"
                              )}
                              margin="normal"
                            />
                          </Grid>
                          <Grid item xs={7} sm={7} md={7} lg={7}>
                            <FormControl
                              className={classes.formControl}
                              style={{ width: "100%", marginTop: "17px" }}
                            >
                              <InputLabel htmlFor="Unidad">Unidad</InputLabel>
                              <Select
                                name="unidadSemaforoRojo"
                                inputRef={this.unidadMedidaSemaforoRojoRef}
                                value={this.state.problema.unidadSemaforoRojo}
                                onChange={this.handleChangeProblema(
                                  "unidadSemaforoRojo"
                                )}
                                input={
                                  <Input
                                    name="unidadMedidaSemaforoRojo"
                                    id="unidadMedidaSemaforoRojo"
                                  />
                                }
                              >
                                {this.state.listaUnidadMedida.map((item) => (
                                  <MenuItem
                                    key={"listaRoja" + item.CodigoUnidadMedida}
                                    value={item.CodigoUnidadMedida}
                                  >
                                    {item.Nombre}
                                  </MenuItem>
                                ))}
                              </Select>
                              <FormHelperText />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <div>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  this.state.problema.aplicaFechaVencimiento
                                }
                                onChange={this.handleChangeProblema(
                                  "aplicaFechaVencimiento"
                                )}
                                value="¿Aplica fecha de vencimiento?"
                                color="primary"
                              />
                            }
                            label="¿Aplica fecha de vencimiento?"
                          />
                          <br />
                        </div>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <div>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  this.state.problema
                                    .muestraFechaVencimientoCreador
                                }
                                onChange={this.handleChangeProblema(
                                  "muestraFechaVencimientoCreador"
                                )}
                                value="¿Creador ingresa fecha de vencimiento?"
                                color="primary"
                              />
                            }
                            label="¿Creador ingresa fecha de vencimiento?"
                          />
                          <br />
                        </div>
                      </Grid>
                          </AccordionDetails>
                        </Accordion>
                        {/* Fin alertas de vencimiento */}

                         {/* integraciones */}
                         <Accordion
                          expanded={this.state.expandedIntegraciones}
                          onChange={() => {
                            this.setState({
                              expandedIntegraciones: !this.state.expandedIntegraciones,
                            });
                          }}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  width: "100%",
                                }}
                              >
                                <div>
                                  <Typography className={classes.heading}>
                                    {"Integraciones"}
                                    <span style={{ color: "gray" }}>
                                      
                                    </span>
                                  </Typography>
                                </div>
                              </div>
                            </div>
                          </AccordionSummary>
                          <AccordionDetails
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                             <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Paper elevation={3} style={{ flex: 1, padding: 10 }}>
                          <Typography variant="h5">
                            Toma de asistencia
                          </Typography>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <div>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      this.state.problema
                                        .TomaAsistenciaFotografia
                                    }
                                    onChange={this.handleChangeProblema(
                                      "TomaAsistenciaFotografia"
                                    )}
                                    //value="TomaAsistenciaReconocimientoFacial"
                                    color="primary"
                                  />
                                }
                                label="Toma de fotografía (sin reconocimiento facial)"
                              />
                              <br />
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <div>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      this.state.problema
                                        .TomaAsistenciaReconocimientoFacial
                                    }
                                    onChange={this.handleChangeProblema(
                                      "TomaAsistenciaReconocimientoFacial"
                                    )}
                                    //value="TomaAsistenciaReconocimientoFacial"
                                    color="primary"
                                  />
                                }
                                label="Toma asistencia con reconocimiento facial"
                              />
                              <br />
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <div>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      this.state.problema.TomaAsistenciaQR
                                    }
                                    onChange={this.handleChangeProblema(
                                      "TomaAsistenciaQR"
                                    )}
                                    value="Toma asistencia con QR"
                                    color="primary"
                                  />
                                }
                                label="Toma asistencia con QR"
                              />
                              <br />
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <div>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      this.state.problema.TomaAsistenciaDPI
                                    }
                                    onChange={this.handleChangeProblema(
                                      "TomaAsistenciaDPI"
                                    )}
                                    value="Toma asistencia con DPI"
                                    color="primary"
                                  />
                                }
                                label="Toma asistencia con DPI"
                              />
                              <br />
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <div>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      this.state.problema.TomaAsistenciaGenerica
                                    }
                                    onChange={this.handleChangeProblema(
                                      "TomaAsistenciaGenerica"
                                    )}
                                    //value="TomaAsistenciaReconocimientoFacial"
                                    color="primary"
                                  />
                                }
                                label="Registros genéricos"
                              />
                              <br />
                            </div>
                          </Grid>

                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                              required
                              id="txtTituloAsistencia"
                              label="Título para registro de asistencia"
                              className={classes.textField}
                              value={this.state.problema.TituloAsistencia}
                              onChange={this.handleChangeProblema(
                                "TituloAsistencia"
                              )}
                              margin="normal"
                              inputProps={{ maxLength: 512 }}
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                              required
                              id="txtTituloParticipantes"
                              label="Título para sección de lista"
                              className={classes.textField}
                              value={this.state.problema.TituloParticipantes}
                              onChange={this.handleChangeProblema(
                                "TituloParticipantes"
                              )}
                              margin="normal"
                              inputProps={{ maxLength: 512 }}
                            />
                          </Grid>

                          <Grid
                            container
                            spacing={3}
                            style={{ width: "100% !important" }}
                          >
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                color: "#3f51b5",
                                // fontWeight:"bold",
                              }}
                            >
                              Opciones de formulario para toma de asistencia
                              <ActionMenuFormTab
                                asignar={this.handleOpenChooseFormAsistencia}
                                nuevoForm={this.handleOpenNewFormAsistencia}
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={1}>
                            <Grid item xs={12} lg={12} sm={12}>
                              {this.state.problema
                                .CodigoFormularioAsistencia === "" ||
                              this.state.problema.CodigoFormularioAsistencia ===
                                null ||
                              this.state.problema.CodigoFormularioAsistencia ===
                                undefined ? (
                                <div>No hay datos para mostrar</div>
                              ) : (
                                <FormDesigner
                                  codigoPlantilla={
                                    this.state.problema
                                      .CodigoFormularioAsistencia
                                  }
                                />
                              )}
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>

                     
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Paper elevation={3} style={{ flex: 1, padding: 10, marginTop:10 }}>
                          <Typography variant="h5">
                            Landmarks
                          </Typography>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography variant="h6">
                          Visualiza un punto en el mapa al crear una nueva
                          gestión de este tipo
                        </Typography>
                        <Grid container spacing={3}>
                          <Grid item xs={7} sm={7} md={7} lg={7}>
                            <FormControl
                              className={classes.formControl}
                              style={{ width: "100%", marginTop: "17px" }}
                            >
                              <InputLabel htmlFor="tipoLandmark">
                                Tipo de punto
                              </InputLabel>
                              <Select
                                name="tipoLandmark"
                                inputRef={this.codigoTipoLandmarkRef}
                                value={this.state.problema.codigoTipoLandmark}
                                onChange={this.handleChangeProblema(
                                  "codigoTipoLandmark"
                                )}
                                input={
                                  <Input
                                    name="codigoTipoLandmark"
                                    id="codigoTipoLandmark"
                                  />
                                }
                              >
                                {this.state.listaTipoLandmark.map((item) => (
                                  <MenuItem
                                    key={"llandmark" + item.IdTipoPunto}
                                    value={item.IdTipoPunto}
                                  >
                                    {item.Nombre}
                                  </MenuItem>
                                ))}
                              </Select>
                              <FormHelperText />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                      </Paper>
                    </Grid>

                            {this.state.showProblemaApiConfig && (
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Paper elevation={3} style={{ flex: 1, padding: 10, marginTop:10  }}>
                            <Typography variant="h5">
                              Configuración de llamadas API
                            </Typography>
                            <ProblemaApiConfig
                              CodigoProblema={this.state.codigoProblema}
                              Tareas={this.state.listaTareas}
                              CodigoPlantilla={this.state.codigoPlantilla}
                              UpdateApi={this.updateApiConfig}
                              ProblemaApi={this.state.objetoApiConfig}
                            />
                          </Paper>
                        </Grid>
                      )}
                          </AccordionDetails>
                        </Accordion>
                        {/* Fin de dintegraciones */}

                         {/* Flags */}
                         <Accordion
                          expanded={this.state.expandedFlags}
                          onChange={() => {
                            this.setState({
                              expandedFlags: !this.state.expandedFlags,
                            });
                          }}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  width: "100%",
                                }}
                              >
                                <div>
                                  <Typography className={classes.heading}>
                                    {"Activar / desactivar características"}
                                    <span style={{ color: "gray" }}>
                                      {/* ({grupo.Gestiones.length}) */}
                                    </span>
                                  </Typography>
                                </div>
                              </div>
                            </div>
                          </AccordionSummary>
                          <AccordionDetails
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                                  <Grid item xs={12} sm={12} md={12} lg={12}>
                        <div>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={this.state.problema.activo}
                                onChange={this.handleChangeProblema("activo")}
                                value="Activo"
                                color="primary"
                              />
                            }
                            label="Activo"
                          />
                          <br />
                        </div>
                      </Grid>
                      
                             <Grid item xs={12} sm={12} md={12} lg={12}>
                        <div>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  this.state.problema.encuestaSatisfaccion
                                }
                                onChange={this.handleChangeProblema(
                                  "encuestaSatisfaccion"
                                )}
                                value="¿Aplica encuesta de satisfacción?"
                                color="primary"
                              />
                            }
                            label="¿Aplica encuesta de satisfacción?"
                          />
                          <br />
                        </div>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <div>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  this.state.problema.permiteAgregarTareas
                                }
                                onChange={this.handleChangeProblema(
                                  "permiteAgregarTareas"
                                )}
                                value="¿Permitirá que se añadan nuevas tareas en la gestión?"
                                color="primary"
                              />
                            }
                            label="¿Permitirá que se añadan nuevas tareas en la gestión?"
                          />
                          <br />
                        </div>
                      </Grid>

                     

                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <div>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={this.state.problema.popular}
                                onChange={this.handleChangeProblema("popular")}
                                value="Popular"
                                color="primary"
                              />
                            }
                            label="Popular"
                          />
                          <br />
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        style={{ display: "none" }}
                      >
                        <div>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={this.state.problema.esEditable}
                                onChange={this.handleChangeProblema(
                                  "esEditable"
                                )}
                                value="esEditable"
                                color="primary"
                              />
                            }
                            label="¿Es editable?"
                          />
                          <br />
                        </div>
                      </Grid>
                  
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <div>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={this.state.problema.ocultarDescripcion}
                                onChange={this.handleChangeProblema(
                                  "ocultarDescripcion"
                                )}
                                value="Ocultar descripción"
                                color="primary"
                              />
                            }
                            label="Ocultar descripción"
                          />
                          <br />
                        </div>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <div>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={this.state.problema.ocultarEtiquetas}
                                onChange={this.handleChangeProblema(
                                  "ocultarEtiquetas"
                                )}
                                value="Ocultar etiquetas"
                                color="primary"
                              />
                            }
                            label="Ocultar etiqueta"
                          />
                          <br />
                        </div>
                      </Grid>
                          </AccordionDetails>
                        </Accordion>
                        {/* Fin de flags */}
                        </Grid>
                        

                      <Grid container spacing={3}>
                        <Grid item xs={12} lg={12} sm={12}>
                          <Button
                            variant="contained"
                            style={{
                              backgroundColor: "#69bd4b",
                              color: "white",
                            }}
                            className={classes.buttonAdd}
                            onClick={() => this.actualizarProblema()}
                          >
                            Guardar
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
              </Grid>
            </TabPanel>

            <TabPanel
              value={this.state.valorTab}
              index={0}
              style={{ width: "100% !important" }}
            >
              <Grid container spacing={3} style={{ width: "100% !important" }}>
                {/* <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    color: "#3f51b5",
                    // fontWeight:"bold",
                  }}
                >
                  Opciones de formulario
                  <ActionMenuFormTab
                    asignar={this.handleOpenChooseForm}
                    nuevoForm={this.handleOpenNewForm}
                    mostrarAsignar={false}
                  />
                </Grid> */}

                 <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    color: "#3f51b5",
                    // fontWeight:"bold",
                    width: "100% !important",
                  }}
                >
                  <TitleCustomInput 
                  textoActual = {this.state.problema.nombre}
                  titulo="Nombre"
                  cambiarTextoFn = {this.cambiarTextoNombreFn}
                  />

                  </Grid>

                  <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    color: "#3f51b5",
                    // fontWeight:"bold",
                    width: "100% !important",
                  }}
                >
                  <TitleCustomInput 
                  textoActual = {this.state.problema.descripcion}
                  titulo="Descripción"
                  cambiarTextoFn = {this.cambiarTextoDescripcionFn}
                  />
                  </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <div
                    className={classes.root}
                    style={{ width: "100% !important" }}
                  >
                    <CssBaseline />

                    <main
                      className={clsx(classes.content, {
                        [classes.contentShift]: this.state.openToolbar,
                      })}
                    >
                      <div className={classes.drawerHeader} />
                      <Grid item xs={12} lg={12} sm={12}>
                        {this.state.codigoPlantilla === "" ||
                        this.state.codigoPlantilla === null ||
                        this.state.codigoPlantilla === undefined ? (
                          <div>No hay datos para mostrar</div>
                        ) : (
                          <FormDesigner
                            codigoPlantilla={this.state.codigoPlantilla}
                            showAddButton={
                              this.state.valorTab != 0 ? false : true
                            } // si es 0 es porque la pestaña activa es la de forms
                          />
                        )}
                      </Grid>
                    </main>
                  </div>
                </Grid>
                {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Paper style={{ padding: 15 }}>
                    <div
                      style={{
                        display: "flex",
                        //justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="h6">Etiquetas</Typography>
                      <IconButton
                        onClick={() => {
                          this.setState({ openModalEtiquetas: true });
                        }}
                      >
                        <AddIcon color="primary" />
                      </IconButton>
                    </div>
                    <div>
                      {this.state.listaEtiquetasAsignadas.map((element, i) => (
                        <Chip
                          key={"tag" + element.CodigoProblemaCausa}
                          style={{ margin: 5 }}
                          icon={<LocalOfferIcon />}
                          size="small"
                          label={element.Nombre}
                          color="Primary"
                          // variant="outlined"
                          onDelete={() => {
                            this.eliminarEtiquetaAsignada(
                              element.CodigoProblemaCausa
                            );
                          }}
                        />
                      ))}
                    </div>
                  </Paper>
                </Grid> */}
              </Grid>
            </TabPanel>
            <TabPanel
              value={this.state.valorTab}
              index={1}
              style={{ width: "100% !important" }}
            >
              <Grid container spacing={3} style={{ width: "100% !important" }}>
                {/* <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    color: "#3f51b5",
                    // fontWeight:"bold",
                  }}
                >
                  Opciones de flujo
                  <ActionMenuFlujo nuevoFlujo={this.nuevoFlujo} />
                </Grid> */}
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <div
                    className={classes.root}
                    style={{ width: "100% !important" }}
                  >
                    <CssBaseline />

                    <main
                      className={clsx(classes.content, {
                        [classes.contentShift]: this.state.openToolbar,
                      })}
                    >
                      <div className={classes.drawerHeader} />
                      <Grid item xs={12} lg={12} sm={12}>
                        {this.state.codigoPlantillaTareas === "" ||
                        this.state.codigoPcodigoPlantillaTareaslantilla === null ||
                        this.state.codigoPlantillaTareas === undefined ? (
                          <div>No hay datos para mostrar</div>
                        ) : (
                          <TaskDesignerIndependiente
                            codigoPlantillaTareas={
                              this.state.codigoPlantillaTareas
                            }
                            codigoProblema={this.state.codigoProblema}
                            showAddButton={
                              this.state.valorTab != 1 ? false : true
                            } // si es 1 es porque la pestaña activa es la de flujos
                            openModalUsuarios={this.handleOpenAsignarUsuarios}
                            eliminarUsuario={this.eliminarUsuarioTareaProblema}
                          />
                        )}
                      </Grid>
                    </main>
                  </div>
                </Grid>
              </Grid>
            </TabPanel>
          </div>
        </Paper>
        <ConfirmDialog
          id="confirmar"
          keepMounted
          open={this.state.confirmOpen}
          onClose={this.cerrarConfirm}
          value=""
          texto={this.state.confirmTexto}
          okfunction={this.funcionOk}
        />

        <ConfirmDialog
          id="confirmarCambioPlantilla"
          keepMounted
          open={this.state.confirmOpenCambioPlantilla}
          onClose={this.cerrarConfirmCambioPlantilla}
          value=""
          texto={this.state.confirmTextoCambioPlantilla}
          okfunction={this.funcionOkCamioPlantilla}
          style={{ zIndex: 3000 }}
        />

        <ConfirmDialog
          id="confirmarCambioForm"
          keepMounted
          open={this.state.confirmOpenCambioForm}
          onClose={this.cerrarConfirmCambioForm}
          value=""
          texto={this.state.confirmTextoCambioForm}
          okfunction={this.funcionOkCamioForm}
          style={{ zIndex: 3000 }}
        />

        <ToastContainer />

        <Modal
          // style={{ zIndex: 2990 }}
          aria-labelledby="Seleccionar una plantilla"
          aria-describedby="Seleccionar una plantilla"
          open={this.state.openChooseTemplate}
          onClose={this.handleCloseChooseTemplate}
        >
          <div style={getModalStyle()} className={classes.paperModal}>
            <PerfectScrollbar>
              <form className={classes.container} noValidate autoComplete="off">
                <Typography variant="h6" gutterBottom>
                  Elegir plantilla
                </Typography>

                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextField
                      id="txtTextoBusquedaFlujo"
                      label="Nombre"
                      className={classes.textField}
                      value={this.state.textoBusquedaFlujo}
                      onChange={this.handleChange("textoBusquedaFlujo")}
                      onKeyPress={(ev) => {
                        console.log("keyP: " + ev.key);
                        if (ev.key === "Enter") {
                          this.buscarPlantillas();
                          ev.preventDefault();
                        }
                      }}
                      margin="normal"
                      inputProps={{ maxLength: 512 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#217ecf",
                        color: "white",
                        width: "100%",
                      }}
                      className={classes.buttonAdd}
                      onClick={() => this.buscarPlantillas()}
                    >
                      Buscar
                    </Button>
                  </Grid>
                </Grid>

                <Grid item xs={12} lg={12} sm={12}>
                  <Grid container spacing={1}>
                    {this.state.listaFlujos.map((item) =>
                      this.flujoElement(
                        item.Nombre,
                        item.numeroTareas,
                        item.Descripcion,
                        item.nombreEntidad,
                        item.CodigoPlantillaTareas
                      )
                    )}
                  </Grid>
                </Grid>
              </form>
            </PerfectScrollbar>
          </div>
        </Modal>

        <Modal
          // style={{ zIndex: 2990 }}
          aria-labelledby="Seleccionar un formulario"
          aria-describedby="Seleccionar un formulario"
          open={this.state.openChooseForm}
          onClose={this.handleCloseChooseForm}
        >
          <div style={getModalStyle()} className={classes.paperModal}>
            <PerfectScrollbar>
              <form className={classes.container} noValidate autoComplete="off">
                <Typography variant="h6" gutterBottom>
                  Elegir formulario
                </Typography>

                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextField
                      id="txtTextoBusquedaForm"
                      label="Nombre"
                      className={classes.textField}
                      value={this.state.textoBusquedaForm}
                      onChange={this.handleChange("textoBusquedaForm")}
                      onKeyPress={(ev) => {
                        console.log("keyP: " + ev.key);
                        if (ev.key === "Enter") {
                          this.buscarForm("tipoGestion");
                          ev.preventDefault();
                        }
                      }}
                      margin="normal"
                      inputProps={{ maxLength: 512 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#217ecf",
                        color: "white",
                        width: "100%",
                      }}
                      className={classes.buttonAdd}
                      onClick={() => this.buscarForm("tipoGestion")}
                    >
                      Buscar
                    </Button>
                  </Grid>
                </Grid>

                <Grid item xs={12} lg={12} sm={12}>
                  <Grid container spacing={1}>
                    <FormList
                      registros={this.state.listaForms}
                      seleccionarForm={this.asignarForm}
                    />
                  </Grid>
                </Grid>
              </form>
            </PerfectScrollbar>
          </div>
        </Modal>

        <Modal
          // style={{ zIndex: 2990 }}
          aria-labelledby="Asignar usuarios"
          aria-describedby="Asignar usuarios"
          open={this.state.openAsignarUsuarios}
          onClose={this.handleCloseAsignarUsuarios}
        >
          <div style={getModalStyle()} className={classes.paperUserModal}>
            <PerfectScrollbar>
              <form className={classes.container} noValidate autoComplete="off">
                <Typography variant="h6" gutterBottom>
                  Asignar usuario(s)
                </Typography>

                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <FormControl component="fieldset">
                        <FormLabel component="legend">Buscar por</FormLabel>
                        <RadioGroup
                          aria-label="Tipo Busqueda"
                          name="tipoBusqueda"
                          value={this.state.tipoBusquedaAsignado}
                          onChange={this.handleChange("tipoBusquedaAsignado")}
                        >
                          <FormControlLabel
                            value="Usuario"
                            control={<Radio />}
                            label="Usuario"
                          />
                          <FormControlLabel
                            value="Rol"
                            control={<Radio />}
                            label="Rol"
                          />
                          <FormControlLabel
                            value="RolDinamico"
                            control={<Radio />}
                            label="Jerarquía"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <TextField
                      id="txtTextoBusquedaUsuario"
                      label="Criterio de búsqueda"
                      className={classes.textField}
                      value={this.state.txtTextoBusquedaUsuario}
                      onChange={this.handleChange("txtTextoBusquedaUsuario")}
                      onKeyPress={(ev) => {
                        console.log("keyP: " + ev.key);
                        if (ev.key === "Enter") {
                          this.cargarListaUsuarios();
                          ev.preventDefault();
                        }
                      }}
                      margin="normal"
                      inputProps={{ maxLength: 512 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#217ecf",
                        color: "white",
                        width: "100%",
                      }}
                      className={classes.buttonAdd}
                      onClick={() => this.cargarListaUsuarios()}
                    >
                      Buscar
                    </Button>
                  </Grid>
                </Grid>

                <Grid item xs={12} lg={12} sm={12}>
                  <Grid container spacing={1}>
                    <UsuariosList
                      registros={this.state.listaUsuarios}
                      seleccionarUsuario={this.seleccionarUsuario}
                    />
                  </Grid>
                </Grid>
              </form>
            </PerfectScrollbar>
          </div>
        </Modal>

        <Modal
          // style={{ zIndex: 2990 }}
          aria-labelledby="Asignar etiquetas"
          aria-describedby="Asignar etiquetas"
          open={this.state.openModalEtiquetas}
          onClose={() => {
            this.setState({ openModalEtiquetas: false });
          }}
        >
          <div style={getModalStyle()} className={classes.paperUserModal}>
            <PerfectScrollbar style={{ paddingRight: 30 }}>
              <form className={classes.container} noValidate autoComplete="off">
                <Typography variant="h6" gutterBottom>
                  Agregar Etiquetas
                </Typography>

                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Autocomplete
                      className={classes.textField}
                      style={{
                        width: "100%",
                        marginLeft: 10,
                        marginTop: 25,
                      }}
                      options={this.state.listaFamiliasEtiquetas}
                      autoHighlight
                      getOptionLabel={(option) => option.Nombre}
                      disabled={false}
                      renderOption={(option) => (
                        <React.Fragment>
                          <span></span>
                          {option.Nombre}
                        </React.Fragment>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Familia de etiquetas"
                          variant="outlined"
                        />
                      )}
                      value={this.state.objFamilia}
                      onChange={(event, newInputValue) => {
                        this.setState(
                          {
                            objFamilia: newInputValue ? newInputValue : -1,
                            codigoFamiliaEtiquetaSeleccionada: parseInt(
                              newInputValue
                                ? newInputValue.CodigoFamiliaEtiqueta
                                : 0
                            ),
                          },
                          () => {
                            this.busquedaEtiqueta();
                          }
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextField
                      id="txtTextoBusquedaTag"
                      label="Tag"
                      className={classes.textField}
                      value={this.state.textoBusquedaEtiqueta}
                      onChange={this.setSearchEtiqueta()}
                      margin="normal"
                      inputProps={{ maxLength: 512 }}
                      style={{ width: "100%" }}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} lg={12} sm={12}>
                  <Grid container spacing={1}>
                    <TagList
                      registros={this.state.listaEtiquetasCopia}
                      agregarCausas={this.asignarEtiqueta}
                      closeModal={() => {
                        this.setState({ openModalEtiquetas: false });
                      }}
                    />
                  </Grid>
                </Grid>
              </form>
            </PerfectScrollbar>
          </div>
        </Modal>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.openNewForm}
          onClose={this.handleCloseNewForm}
        >
          <div style={getModalStyle()} className={classes.paperNuevoForm}>
            <form className={classes.container} noValidate autoComplete="off">
              <Typography variant="title" gutterBottom>
                Crear nuevo formulario
              </Typography>
              <TextField
                required
                id="txtNombreForm"
                label="Nombre"
                className={classes.textField}
                value={this.state.txtNombreForm}
                onChange={this.handleChange("txtNombreForm")}
                margin="normal"
                variant="outlined"
                inputProps={{ maxLength: 512 }}
              />

              <Grid container spacing={3}>
                <Grid item xs={12} lg={12} sm={12}>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: "#69bd4b", color: "white" }}
                    className={classes.buttonAdd}
                    onClick={() => this.grabarNuevoForm()}
                  >
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </Modal>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.openNewFormAsistencia}
          onClose={this.handleCloseNewFormAsistencia}
        >
          <div style={getModalStyle()} className={classes.paperNuevoForm}>
            <form className={classes.container} noValidate autoComplete="off">
              <Typography variant="title" gutterBottom>
                Crear nuevo formulario para asistencia
              </Typography>
              <TextField
                required
                id="txtNombreFormAsistencia"
                label="Nombre"
                className={classes.textField}
                value={this.state.txtNombreFormAsistencia}
                onChange={this.handleChange("txtNombreFormAsistencia")}
                margin="normal"
                variant="outlined"
                inputProps={{ maxLength: 512 }}
              />

              <Grid container spacing={3}>
                <Grid item xs={12} lg={12} sm={12}>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: "#69bd4b", color: "white" }}
                    className={classes.buttonAdd}
                    onClick={() => this.grabarNuevoFormAsistencia()}
                  >
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </Modal>

        <Modal
          // style={{ zIndex: 2990 }}
          aria-labelledby="Seleccionar un formulario"
          aria-describedby="Seleccionar un formulario"
          open={this.state.openChooseFormAsistencia}
          onClose={this.handleCloseChooseFormAsistencia}
        >
          <div style={getModalStyle()} className={classes.paperModal}>
            <PerfectScrollbar>
              <form className={classes.container} noValidate autoComplete="off">
                <Typography variant="h6" gutterBottom>
                  Elegir formulario para asistencia
                </Typography>

                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextField
                      id="txtTextoBusquedaFormAsistencia"
                      label="Nombre"
                      className={classes.textField}
                      value={this.state.TextoBusquedaFormAsistencia}
                      onChange={this.handleChange(
                        "TextoBusquedaFormAsistencia"
                      )}
                      onKeyPress={(ev) => {
                        console.log("keyP: " + ev.key);
                        if (ev.key === "Enter") {
                          this.buscarForm("asistencia");
                          ev.preventDefault();
                        }
                      }}
                      margin="normal"
                      inputProps={{ maxLength: 512 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#217ecf",
                        color: "white",
                        width: "100%",
                      }}
                      className={classes.buttonAdd}
                      onClick={() => this.buscarForm("asistencia")}
                    >
                      Buscar
                    </Button>
                  </Grid>
                </Grid>

                <Grid item xs={12} lg={12} sm={12}>
                  <Grid container spacing={1}>
                    <FormList
                      registros={this.state.listaForms}
                      seleccionarForm={this.asignarFormAsistencia}
                    />
                  </Grid>
                </Grid>
              </form>
            </PerfectScrollbar>
          </div>
        </Modal>
      </Layout>
    );
  }
}
const AdministracionAvisosDetalleContainer = withStyles(styles,{index:1})(
  AdministracionAvisosDetalle
);
export default withRouter(AdministracionAvisosDetalleContainer);
