import * as yup from 'yup'

export const tipoDocumentoModel = yup.object().shape({
  codigo: yup.string()
    .required('El código es requerido')
    .length(1, 'Código no valido')
    .uppercase('Código no valido'),
  nombre: yup.string()
    .required('El tipo de documento es requerido')
})

export const solicitudModel = yup.object().shape({
  CodigoUsuarioSolicitante: yup.number().typeError('El solicitante es requerido'),
  IdTipoDocumento: yup.number().typeError('El tipo de documento es requerido'),
  NombreDocumento: yup.string()
    .required('El nombre de documento es requerido'),
  DescripcionDocumento: yup.string()
    .required('La descripción del documento es requerida'),
  CodigoUsuarioAutoriza: yup.number().typeError('El autorizador es requerido'),
  IdMacroprocesoDocumento: yup.number().typeError('El macroproceso es requerido'),
  IdDivisionDocumento: yup.number().typeError('La división es requerida'),
  IdProcesoDocumento: yup.number().typeError('El proceso es requerido'),
  SistemasDeGestion: yup.array().min(1, 'El sistema de gestión es requerido'),
  CentroDocumental: yup.array(),
  UsuariosInformados: yup.array()
})

export const aprobarModel = yup.object().shape({
  NombreDocumento: yup.string()
    .required('El nombre de documento es requerido'),
  DescripcionDocumento: yup.string()
    .required('La descripción del documento es requerida'),
  CorrelativoDocumento: yup.number().typeError('El correlativo es requerido'),
  Observaciones: yup.string().required('Las observaciones son requeridas'),
  // FechaSolicitud: yup.string().required('La fecha es requerida'),
  IdSistemaGestion: yup.number().typeError('El sistema de gestion es requerido'),
  IdTipoDocumento: yup.number().typeError('El tipo de documento es requerido')
})

export const correlativoModel = yup.object().shape({
  CorrelativoInicio: yup.number().typeError('El correlativo inicial es requerido'),
  CorrelativoFin: yup.number().typeError('El correlativo final es requerido'),
  IdProceso: yup.string().required('El proceso es requerido'),
  IdMacroproceso: yup.string().required('El macroproceso es requerido')
})

export const externoModel = yup.object().shape({
  Edicion: yup.number().typeError('La edición del documento es requerida'),
  NumeroUnidades: yup.number().typeError('El numero de unidades es requerido'),
  AnioLanzamiento: yup.string().required('El año de lanzamiento es requerido'),
  AutorOriginal: yup.string().required('El autor original es requerido'),
  DuenioOriginal: yup.string().required('El dueño original es requerido'),
  FechaIngreso: yup.string().required('La fecha de ingreso es requerida'),
  // IdCentroDocumental: yup.number().typeError('El centro documental es requerido'),
  CodigoResponsable: yup.number().typeError('Selecciona un responsable'),
  IdTipoDocumentoExterno: yup.number().typeError('El tipo de documento es requerido'),
})

export const registroModel = yup.object().shape({
  IdTiempoRetencion: yup.number().typeError('Selecciona el tiempo de retención'),
  CodigoResponsable: yup.number().typeError('Selecciona un responsable'),
  MedioAlmacenamiento: yup.string().required('El año de lanzamiento es requerido'),
  Proteccion: yup.string().required('Ingresa el medio de protección'),
  DisposicionRegistrosViejos: yup.string().required('Ingresa la disposición de registros viejos')
})

export const actualizacionModel = yup.object().shape({
  Titulo: yup.string().required('El título es requerido'),
  Descripcion: yup.string().required('La descripción es requerida'),
  CodigoRevisor: yup.number().typeError('El revisor es requerido')
})
