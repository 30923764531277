import React from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";

export default function SimpleBreadcrumbs(props) {
  const handleClick = (event, tarea) => {
    event.preventDefault();
    props.onClickBreadcrumb(tarea);
  };

  return (
    <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: 10 }}>
      {props.niveles.map((nivel, i) => (
        <Link
          style={{ color: "#2196F3" }}
          href="/"
          onClick={(event) => handleClick(event, nivel)}
          key={"bc-" + i}
        >
          {nivel.nombre}
        </Link>
      ))}
    </Breadcrumbs>
  );
}
