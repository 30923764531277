import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import { validaPermisoPaginaActual } from "../../utils/helpers";
import { isSignedIn, signOut } from "../../utils/auth";
import API from "../../utils/api";
import { toast } from "react-toastify";

const AnalisisSalarialConfiguracion = (props) => {
  const [selectedConfig, setSelectedConfig] = useState(null);
  const [buscando, setBackDrop] = useState(false);
  const [maestroEncabezadoAS, setMaestroEncabezadoAS] = useState([]);
  const [userName, setUserName] = useState("");

  const handleCerrarBackDrop = () => {
    setBackDrop(false);
  };
  const handleAbrirBackDrop = () => {
    setBackDrop(true);
  };

  useEffect(() => {
    const cargar = async () => {
      // CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
      const llaveAgrupacion = "Menu.Principal.Web";
      const llavePagina = "Menu.Principal.Web.PuestoLaboral";
      await validaPermisoPaginaActual(llaveAgrupacion, llavePagina);
      // FIN DE CONSULTA DE PERMISOS

      handleAbrirBackDrop();

      await cargarMaestroEncabezadoAnalisisSalarial();
      await cargarItemEncabezadoAnalisisSalarial();

      handleCerrarBackDrop();
    };

    cargar();
  }, [props.codigoUnidadOrganizacion]);

  const cargarMaestroEncabezadoAnalisisSalarial = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/EncabezadoEscalaSalarial";

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setMaestroEncabezadoAS(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cargarItemEncabezadoAnalisisSalarial = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      //   setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri =
        "api/EscalaSalarialUnidadOrganizacion/" +
        props.codigoUnidadOrganizacion;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        if (request.data.response) {
          const valor = request.data.ficha[0];
          setSelectedConfig(valor);
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const guardar = async () => {
    if (selectedConfig === null) {
      toast.error(
        "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
      );
      return;
    }

    const data = selectedConfig;
    data.UsuarioInserto = userName;
    console.log(data);
    // dataFicha.usuarioInserto = "prueba";
    try {
      handleAbrirBackDrop();
      let vIsSignedIn = await isSignedIn();
      if (vIsSignedIn.response) {
        let request;

        request = await API.post(
          "api/EscalaSalarialUnidadOrganizacion/" +
            props.codigoUnidadOrganizacion,
          data
        );
        if (request.status !== 200) {
          toast.error(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        } else {
          toast.success("Se guardó exitosamente");

          await cargarItemEncabezadoAnalisisSalarial();
        }
      }
    } catch (e) {
      console.log("EXCEPTION: " + e);
      toast.error(
        "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
      );
    } finally {
      handleCerrarBackDrop();
    }
  };

  const handleSaveConfig = () => {
    console.log("Configuración seleccionada: ", selectedConfig);
  };

  return (
    <div>
      <Autocomplete
        options={maestroEncabezadoAS}
        getOptionLabel={(option) => option.Nombre}
        onChange={(event, newValue) => {
          setSelectedConfig(newValue);
        }}
        renderInput={(params) => (
          <TextField {...params} label="Nombre" variant="outlined" />
        )}
        value={selectedConfig}
      />
      <Button onClick={guardar} variant="contained" color="primary">
        Guardar Configuración
      </Button>
    </div>
  );
};

export default AnalisisSalarialConfiguracion;
