import React, { useState, useEffect } from "react"
import {
  AppBar,
  Tabs,
  Tab,
  Typography,
  makeStyles,
  Box,
  Fab,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  IconButton,
  Menu,
  MenuItem
} from "@material-ui/core"
import { useHistory } from "react-router-dom"
import PropTypes from "prop-types"
import { ToastContainer, toast } from "react-toastify"
import { Add, MoreVert } from "@material-ui/icons"
import { useStoreState, useStoreActions } from "easy-peasy"
import Layout from "../layout/containers/Layout"
import SolicitudesUsuario from "./componentes/solicitudes/SolicitudesUsuario"
import DocumentosUsuario from "./componentes/solicitudes/DocumentosUsuario"
import { getReporteXLSX } from "./utils/media"
import ListaRegistros from "./componentes/solicitudes/ListaRegistros"
import ListaDocsExternos from "./componentes/solicitudes/ListaDocsExternos"

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
const a11yProps = (index) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  }
}
const useStyles = makeStyles((theme) => ({
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const ControlDoc = () => {
  const { documentos, todas, registros, externos } = useStoreState(
    (state) => ({
      documentos: state.documentos,
      todas: state.todas,
      registros: state.registros,
      externos: state.externos
    })
  )
  const {
    getSolicitudes,
    getDocsOficiales,
    getRegistrosOficiales,
    getExternosOficiales
  } = useStoreActions((actions) => ({
    getSolicitudes: actions.getSolicitudes,
    getDocsOficiales: actions.getDocsOficiales,
    getRegistrosOficiales: actions.getRegistrosOficiales,
    getExternosOficiales: actions.getExternosOficiales
  }))
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [tabValue, setTabValue] = useState(0)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [tabs, setTabs] = useState([
    {
      id: 1,
      titulo: "Documentos oficiales",
      color: "#7209b7",
      num: "-",
      hasNum: true,
    },
    {
      id: 2,
      titulo: "Registros",
      color: "#2b9348",
      num: "-",
      hasNum: true,
    },
    {
      id: 3,
      titulo: "Docs. externos",
      color: "#2ec4b6",
      num: "-",
      hasNum: true,
    },
    {
      id: 4,
      titulo: "Borradores por publicar",
      color: "#118ab2",
      num: "-",
      hasNum: true,
    }
  ])
  const renderTab = (tab) => {
    switch (tab) {
      case 0:
        return <DocumentosUsuario loading={loading} />
      case 1:
        return <ListaRegistros loading={loading} />
      case 2:
        return <ListaDocsExternos loading={loading} />
      case 3:
        return <SolicitudesUsuario loading={loading} tipo="todas" />
    }
  }
  const getReporte = async (tipo='comentarios') => {
    setAnchorEl(null)
    let base64str = await getReporteXLSX(tipo)
    if (base64str) {
      base64str = base64str.replace(
        "data:application/octet-stream;base64,",
        "data:application/xlsx;base64,"
      )
      const a = document.createElement("a")
      a.href = base64str
      a.download = `Reporte_${tipo}.xlsx`
      a.click()
    }
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  useEffect(() => {
    const tmp = [...tabs];
    tmp[0].num = documentos.length || "-"
    tmp[1].num = registros.length || "-"
    tmp[2].num = externos.length || "-"
    tmp[3].num = todas.length || "-"
    setTabs(tmp)
  }, [documentos, registros, externos, todas])
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const gets = [
          getDocsOficiales(),
          getSolicitudes('todas'),
          getRegistrosOficiales(),
          getExternosOficiales()
        ]
        await Promise.all(gets)
      } catch (error) {
        console.log(error);
        toast.error("😅 Ha ocurrido un error al obtener los datos");
      } finally {
        setLoading(false);
      }
    })()
  }, [])
  return (
    <Layout
      titulo="Administración de documentos"
      maxWidth="xl"
      bodyContainerStyle={{paddingTop: 15, paddingBottom: 10}}
    >
      <div className={classes.root}>
        <AppBar position="static" color="default">
          <Tabs
            value={tabValue}
            onChange={(_, val) => setTabValue(val)}
            indicatorColor="secondary"
            textColor="inherit"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            {tabs.map((i, idx) => (
              <Tab
                key={idx}
                label={`${i.titulo}${i.hasNum ? " - (" + i.num + ")" : ""}`}
                {...a11yProps(idx)}
                style={{ background: i.color, color: "#FFF" }}
              />
            ))}
            <div style={{flex: '1 0 0'}} />
            <IconButton onClick={handleClick}>
              <MoreVert />
            </IconButton>
          </Tabs>
        </AppBar>
        {tabs.map((i, idx) => (
          <TabPanel key={idx} value={tabValue} index={idx}>
            {renderTab(tabValue)}
          </TabPanel>
        ))}
      </div>
      <Menu
        id="opciones"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => getReporte('comentarios')}>
          Comentarios por documento
        </MenuItem>
        <MenuItem onClick={() => getReporte('datos')}>
          Datos de documentos
        </MenuItem>
      </Menu>
      <ToastContainer />
    </Layout>
  );
};

export default ControlDoc
