import React, { useState, useEffect } from 'react'
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemText,
  LinearProgress,
  ListItemAvatar,
  Typography,
  Avatar,
  makeStyles,
  Box,
  Fab
} from '@material-ui/core'
import { green } from 'material-ui/colors'
import { GetApp } from '@material-ui/icons'
import { downloadCSV } from '../../utils/utilidades'

const useStyles = makeStyles(theme => ({
  listDocs: {
    maxHeight: 600,
    overflowX: 'auto'
  },
  btnDownload: {
    background: green[500],
    color: '#FFF'
  }
}))

const ListaDocumentos = ({ centro, items=[], onClose }) => {
  const exportarCSV = () => {
    const nombres = {
      'Codigo': 'Código',
      'Version': 'Versión',
      'NombreDocumento': 'Documento'
    }
    downloadCSV(items, 'documentos', nombres)
  }
  const classes = useStyles()
  return <>
    <Box display="flex" justifyContent="space-between" style={{padding: 10}}>
      <Typography variant="h6">Documentos en centro documental</Typography>
      <Fab
        className={classes.btnDownload}
        size="small"
        onClick={exportarCSV}
      >
        <GetApp />
      </Fab>
    </Box>
    <DialogContent>
      <Typography variant="h6">{centro ? centro.Ubicacion : '--'}</Typography>
      <List className={classes.listDocs}>
        {
          items.map((i, idx) => <ListItem key={idx}>
            <ListItemAvatar>
              <Avatar>{idx+1}</Avatar>
            </ListItemAvatar>
            <ListItemText primary={i.NombreDocumento} secondary={`${i.Codigo} ${i.Version}`} />
          </ListItem>)
        }
        {
          !items.length && <LinearProgress />
        }
      </List>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cerrar</Button>
    </DialogActions>
  </>
}

export default ListaDocumentos
