import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Skeleton from "@material-ui/lab/Skeleton";

import { AppBar, Button, Chip, Grid, Toolbar } from "@material-ui/core";
import DataGrid, {
  Column,
  Pager,
  Paging,
  Sorting,
  ColumnFixing,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Grouping,
  GroupPanel,
  Tooltip,
  Export,
  Selection,
  Editing,
  Scrolling,
  Lookup,
  Summary,
  TotalItem,
  GroupItem,
  LoadPanel,
} from "devextreme-react/data-grid";

import {
  Typography,
  Backdrop,
  CircularProgress,
  Tab,
  Tabs,
  Box,
  Paper,
  Modal,
  Fade,
} from "@material-ui/core";
import {
  Edit,
  Delete,
  Visibility,
  FiberManualRecord,
  ViewList,
} from "@material-ui/icons";

import CheckBox from "devextreme-react/check-box";
import SelectBox from "devextreme-react/select-box";
// import Button from "devextreme-react/button";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
//import DevexpressPercentColumn from "./devexpress-percent-column";
import "devextreme/dist/css/dx.material.teal.light.compact.css";

import BackdropModal from "@material-ui/core/Backdrop";
import Rating from "@material-ui/lab/Rating";

import AttachFileIcon from "@material-ui/icons/AttachFile";
//Own components

import API from "../../utils/api";
import { isSignedIn } from "../../utils/auth";
import { ConstantLine } from "devextreme-react/chart";

const titleColor = "#2B3C4D";

const styles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 1,
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  paperModal: {
    position: "absolute",
    width: "70%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  itemContent: {
    padding: "6px 2px",
    minWidth: "10rem",
    textAlign: "center",
    fontWeight: "bold",
  },
  box: {
    padding: "1rem",
  },
}));

const renderTitleHeader = (data) => {
  return <b>{data.column.caption}</b>;
};

const HistoricoCompetencias = (props) => {
  const classes = styles();
  const { dataEmpleado } = props;

  const [cargando, setCargando] = useState(true);
  let applyFilterTypes = [
    {
      key: "auto",
      name: "Immediately",
    },
    {
      key: "onClick",
      name: "On Button Click",
    },
  ];

  const [positionDisableSorting, setPositionDisableSorting] = useState(false);
  const [showFilterRow, setShowFilterRow] = useState(false);
  const [showHeaderFilter, setShowHeaderFilter] = useState(false);
  const [currentFilter, setCurrentFilter] = useState(applyFilterTypes[0].key);
  const [autoExpandAll, setAutoExpandAll] = useState(true);

  const [competenciasPuesto, setCompetenciasPuesto] = useState([]);
  const [listadoEvaluaciones, setListadoEvaluaciones] = useState([]);
  const [detalleEvaluacion, setDetalleEvaluacion] = useState([]);
  const [maestroNivelDominioCompetencia, setMaestroNivelDominioCompetencia] =
    useState([]);
  const [userName, setUserName] = useState("");

  const [open, setOpen] = useState(false);

  useEffect(() => {
    async function fetchData() {
      //await cargarHistoricoSalario();
      await cargarCompetencias();
      await cargarNivelDominioCompetencia();
      await cargarEvaluaciones();
    }
    fetchData();
  }, [dataEmpleado]);

  const cargarCompetencias = async () => {
    try {
      const session = await isSignedIn();
      if (!session.response) {
        window.location.href = "/login";
        return;
      } else {
        console.log(session);
        setUserName(session.userName);
      }
      setCargando(true);
      let usuarioDPI = dataEmpleado.DPI;
      const request = await API.fetchGetRequest(
        `api/EmpleadoConductaObservable/proyeccion/${usuarioDPI}`
      );
      console.log("Estas son las competencias del puesto", request.data);
      if (!request.data.response) {
        toast.error(
          "El usuario no inició sesión con un DPI, no puede desplegarse información"
        );
      } else {
        if (request.data.ficha.count === 0) {
          toast.warn(
            "El puesto del empleado no cuenta con información sobre competencias"
          );
        } else {
          setCompetenciasPuesto(request.data.ficha);
        }
      }
      setCargando(false);
    } catch (e) {
      console.log("Error en los datos de competencias", e);
      setCargando(false);
    } finally {
      setCargando(false);
    }
  };

  const cargarEvaluaciones = async () => {
    try {
      setCargando(true);
      let usuarioDPI = dataEmpleado.DPI;
      const request = await API.fetchGetRequest(
        `api/EncabezadoEvaluacionCompetencia/${usuarioDPI}`
      );
      if (!request.data.response) {
        toast.error(
          "El usuario no inició sesión con un DPI, no puede desplegarse información"
        );
      } else {
        if (request.data.ficha.count === 0) {
          setListadoEvaluaciones([]);
        } else {
          setListadoEvaluaciones(request.data.ficha);
        }
      }
      setCargando(false);
    } catch (e) {
      console.log("Error en los datos de evaluaciones", e);
      setCargando(false);
    } finally {
      setCargando(false);
    }
  };

  const cargarDetalle = async (id) => {
    try {
      const session = await isSignedIn();
      if (!session.response) {
        window.location.href = "/login";
        return;
      } else {
        console.log(session);
        setUserName(session.userName);
      }
      setCargando(true);

      const request = await API.fetchGetRequest(
        `api/DetalleEvaluacionCompetencia/${id}`
      );
      if (!request.data.response) {
        toast.error(
          "El usuario no inició sesión con un DPI, no puede desplegarse información"
        );
      } else {
        if (request.data.ficha.count === 0) {
          setDetalleEvaluacion([]);
        } else {
          setDetalleEvaluacion(request.data.ficha);
        }
      }
      setCargando(false);
    } catch (e) {
      console.log("Error en los datos de competencias", e);
      setCargando(false);
    } finally {
      setCargando(false);
    }
  };

  const cargarNivelDominioCompetencia = async () => {
    try {
      setCargando(true);

      const request = await API.fetchGetRequest(`api/NivelDominioCompetencia`);
      console.log("Estas son las competencias del puesto", request.data);
      if (!request.data.response) {
        toast.error(
          "El usuario no inició sesión con un DPI, no puede desplegarse información"
        );
      } else {
        if (request.data.ficha.count === 0) {
          toast.warn(
            "Ocurrio un error en la carga del maestro de Nivel de Dominio"
          );
        } else {
          setMaestroNivelDominioCompetencia(request.data.ficha);
        }
      }
      setCargando(false);
    } catch (e) {
      console.log("Error en los datos de competencias", e);
      setCargando(false);
    } finally {
      setCargando(false);
    }
  };
  const ratingStars = (data) => {
    return <Rating name="read-only" value={data.value} readOnly />;
  };
  const ratingStarsDominio = (data) => {
    if (data.value > 0)
      return <Rating name="read-only" max={4} value={data.value} readOnly />;
    else return <Typography variant="body1"> No aplica</Typography>;
  };

  const newTab = (url) => {
    if (url != null) {
      var form = document.createElement("form");
      form.method = "GET";
      form.action = url;
      form.target = "_blank";
      document.body.appendChild(form);
      form.submit();
    }
  };
  const chipAdjunto = (data) => {
    let path = data.value;
    if (path != null)
      return (
        <Chip
          icon={<AttachFileIcon />}
          label={"Documento Evidencia"}
          color="primary"
          size="small"
          onClick={() => {
            newTab(path);
          }}
        />
      );
    else return <Typography variant="body1"> - </Typography>;
  };

  const cellOpciones = (rowInfo) => {
    console.log("RowInfo", rowInfo.data);
    // let info = rowInfo.data;
    // let usuarioDPI = dataEmpleado.DPI;
    return (
      <>
        <ViewList
          style={{ color: "#337ab7", cursor: "pointer" }}
          onClick={() =>
            cargarDetalle(rowInfo.data.CodigoEncabezadoEvaluacionCompetencia)
          }
        />
      </>
    );
  };
  const handleOpenModal = (info) => {
    console.log("info ver", info);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleOnSaved = async (e) => {
    console.log("Cambio en el GRID");
    console.log(e);
  };

  const onEditorPreparing = async (e) => {
    if (
      e.parentType === "dataRow" &&
      e.dataField === "CodigoNivelDominioCompetenciaEvaluacion"
    ) {
      e.editorOptions.onValueChanged = function (ev) {
        let selectedItem = ev.component.option("selectedItem");
        e.setValue(selectedItem);
      };
    }
  };

  const setCellValue = async (row, value, e) => {
    console.log("Cambio en el GRID");

    console.log(value);

    e.CodigoNivelDominioCompetenciaEvaluacion =
      value.CodigoNivelDominioCompetencia;
    e.Resultado = (value.RangoSuperior / 100) * e.Indice;

    var temp = competenciasPuesto;

    temp.map((fila) => {
      if (fila.CodigoConductaObservable === e.CodigoConductaObservable) {
        fila.CodigoNivelDominioCompetenciaEvaluacion =
          value.CodigoNivelDominioCompetencia;
        fila.Resultado = (value.RangoSuperior / 100) * fila.Indice;
        fila.ValorEsperado = value.Orden - fila.OrdenNivelDominioCompetencia;
      }
    });

    console.log(e);
    // e.Phone = value.Phone;
  };

  const cellSemaforo = (rowInfo) => {
    let color = "#8B0000";

    if (rowInfo.data.ValorEsperado > 0) {
      color = "#2032f5";
    } else if (rowInfo.data.ValorEsperado === 0) {
      color = "#49a135";
    } else {
      color = "#a80823";
    }
    return (
      <>
        <FiberManualRecord style={{ color: color, cursor: "pointer" }} />
      </>
    );
  };

  const generarSolicitud = async () => {
    const data = {
      DPI: dataEmpleado.DPI,
      UsuarioInserto: userName,
      Detalle: competenciasPuesto,
    };

    console.log(data);
    // dataFicha.usuarioInserto = "prueba";
    try {
      setCargando(true);
      let vIsSignedIn = await isSignedIn();
      if (vIsSignedIn.response) {
        let request;

        request = await API.post("api/EncabezadoEvaluacionCompetencia", data);
        if (request.status !== 200) {
          toast.error(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        } else {
          if (request.data.response) {
            toast.success("Se guardó exitosamente");
            await cargarEvaluaciones();
          } else {
            console.log("EXCEPTION: " + request.data.mensaje);
            toast.error("Hubo un error: " + request.data.mensaje);
          }
          // await NuevaLista();
        }
      }
    } catch (e) {
      console.log("EXCEPTION: " + e);
      toast.error(
        "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
      );
    } finally {
      setCargando(false);
    }
  };
  return (
    <React.Fragment>
      <Backdrop className={classes.backdrop} open={cargando}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Paper className={classes.root}>
        <DataGrid
          dataSource={competenciasPuesto}
          //   defaultColumns={this.props.columns}
          showBorders={true}
          rowAlternationEnabled={true}
          //ref={(ref) => (dataGrid = ref)}
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
          className={classes.table}
          backgroundColor={"red"}
          // onSaved={handleOnSaved}
          onEditorPreparing={onEditorPreparing}
        >
          <FilterRow visible={showFilterRow} applyFilter={currentFilter} />
          <HeaderFilter visible={showHeaderFilter} />
          <GroupPanel visible={false} />
          <Grouping autoExpandAll={autoExpandAll} />
          <SearchPanel visible={false} width={240} placeholder="Search..." />
          {/* <Selection mode="multiple" /> */}
          {/* <Editing
            mode="row"
            useIcons={true}
            allowUpdating={false}
            allowDeleting={false}
          /> */}

          <Editing mode="cell" allowUpdating={true} />

          <Column
            dataField="TipoSkill"
            caption="Tipo"
            groupIndex={0}
            allowGrouping={true}
            visible={false}
            allowEditing={false}
          />
          <Column
            dataField="Competencia"
            caption="Competencia"
            groupIndex={1}
            allowEditing={false}
          />
          <Column
            dataField="ConductaObservable"
            caption="Conducta"
            allowEditing={false}
          />
          <Column
            dataField="NivelDominioCompetencia"
            caption="Nivel de Dominio Esperado"
            allowEditing={false}
          />
          <Column
            dataField="CodigoNivelDominioCompetenciaEvaluacion"
            caption="Nivel Dominio del Empleado"
            setCellValue={setCellValue}
          >
            <Lookup
              dataSource={maestroNivelDominioCompetencia}
              valueExpr="CodigoNivelDominioCompetencia"
              displayExpr="Descripcion"
            />
          </Column>
          {/* <Column
            dataField="NivelDominioCompetenciaEvaluacion"
            caption="Nivel Dominio del Empleado"
          /> */}
          {/* <Column
            dataField="ValorEsperadoTexto"
            caption="Resultado"
            allowEditing={false}
          /> */}
          <Column
            dataField="Resultado"
            caption="Resultado"
            allowEditing={false}
          />
          <Column cellRender={cellSemaforo} caption="Indicador" />
          {/* <Column
            dataField="ValorEsperado"
            caption="Indicador"
            allowEditing={false}
          /> */}
          {/* <Column
            dataField="NivelDominio"
            caption="Nivel de Dominio Requerido"
            cellRender={ratingStarsDominio}
          />

          <Column
            dataField="DominioEmpleado"
            caption="Nivel de Dominio de empleado"
            cellRender={ratingStarsDominio}
          />

          <Column
            dataField="RelevanciaPuesto"
            caption="Relevancia Puesto"
            cellRender={ratingStars}
          /> */}
          {/* <Column
            dataField="Path"
            caption="Evidencia"
            cellRender={chipAdjunto}
          /> */}

          <Summary>
            <GroupItem
              column="ConductaObservable"
              summaryType="count"
              displayFormat="{0} conductas"
            />
            <GroupItem
              column="Resultado"
              summaryType="sum"
              displayFormat="{0} puntos"
            />
            <TotalItem
              column="ConductaObservable"
              summaryType="count"
              displayFormat="{0} conductas"
            />
            <TotalItem
              column="Resultado"
              summaryType="sum"
              displayFormat="{0} puntos"
            />
          </Summary>

          <ColumnFixing enabled={true} />
          <Sorting mode="multiple" />
          <Paging defaultPageSize={10} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[5, 10, 20]}
            showInfo={true}
          />
          <Export enabled={false} allowExportSelectedData={false} />
          <Scrolling columnRenderingMode="virtual" />
        </DataGrid>

        <Typography variant="h6">Acciones</Typography>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#69BD4B",
              color: "white",
              alignItems: "center",
              justifyContent: "center",
            }}
            // size="small"
            onClick={generarSolicitud}
          >
            Guardar
          </Button>
        </Grid>

        <AppBar
          position="sticky"
          className={classes.appBar}
          style={{ backgroundColor: titleColor }}
        >
          <Toolbar>
            <Typography
              variant="h6"
              noWrap
              style={{ display: "flex", flexGrow: 1 }}
            >
              {`Listado de Evaluaciones Anteriores`}
            </Typography>
          </Toolbar>
        </AppBar>
        <DataGrid
          dataSource={listadoEvaluaciones}
          //   defaultColumns={this.props.columns}
          showBorders={true}
          rowAlternationEnabled={true}
          //ref={(ref) => (dataGrid = ref)}
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
          className={classes.table}
        >
          <FilterRow visible={showFilterRow} applyFilter={currentFilter} />
          <HeaderFilter visible={showHeaderFilter} />
          <GroupPanel visible={false} />
          <Grouping autoExpandAll={autoExpandAll} />
          <SearchPanel visible={false} width={240} placeholder="Search..." />
          {/* <Selection mode="multiple" /> */}
          <LoadPanel
            enabled={true}
            shadingColor="rgba(0,0,0,0.4)"
            showIndicator={true}
            shading={true}
            showPane={true}
            closeOnOutsideClick={false}
          />
          <Column
            caption="Opciones"
            allowFiltering={false}
            allowSorting={false}
            cellRender={cellOpciones}
          />
          <Column
            dataField="CodigoEncabezadoEvaluacionCompetencia"
            caption="Código Evaluación"
            // dataType="number"
            // visible={false}
          />
          <Column
            dataField="Fecha"
            caption="Fecha"
            dataType="date"
            format="dd/MM/yyyy"
          />
          <Column
            dataField="PuntosTotales"
            caption="Puntos Totales"
            dataType="number"
            // visible={false}
          />

          <ColumnFixing enabled={true} />
          <Sorting mode="multiple" />
          <Paging defaultPageSize={50} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[50, 100, 250]}
            showInfo={true}
          />
          <Export enabled={true} allowExportSelectedData={true} />
          <Scrolling columnRenderingMode="virtual" />
        </DataGrid>

        {detalleEvaluacion.length >= 0 && (
          <React.Fragment>
            <AppBar
              position="sticky"
              className={classes.appBar}
              // style={{ backgroundColor: titleColor }}
            >
              <Toolbar>
                <Typography
                  variant="h6"
                  noWrap
                  style={{ display: "flex", flexGrow: 1 }}
                >
                  {`Detalle de Evaluación`}
                </Typography>
              </Toolbar>
            </AppBar>
            <DataGrid
              dataSource={detalleEvaluacion}
              //   defaultColumns={this.props.columns}
              showBorders={true}
              rowAlternationEnabled={true}
              //ref={(ref) => (dataGrid = ref)}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              className={classes.table}
              backgroundColor={"red"}
              // onSaved={handleOnSaved}
              onEditorPreparing={onEditorPreparing}
            >
              <FilterRow visible={showFilterRow} applyFilter={currentFilter} />
              <HeaderFilter visible={showHeaderFilter} />
              <GroupPanel visible={false} />
              <Grouping autoExpandAll={autoExpandAll} />
              <SearchPanel
                visible={false}
                width={240}
                placeholder="Search..."
              />
              {/* <Selection mode="multiple" /> */}
              {/* <Editing
            mode="row"
            useIcons={true}
            allowUpdating={false}
            allowDeleting={false}
          /> */}

              <Editing mode="cell" allowUpdating={true} />

              <Column
                dataField="TipoSkill"
                caption="Tipo"
                groupIndex={0}
                allowGrouping={true}
                visible={false}
                allowEditing={false}
              />
              <Column
                dataField="Competencia"
                caption="Competencia"
                groupIndex={1}
                allowEditing={false}
              />
              <Column
                dataField="ConductaObservable"
                caption="Conducta"
                allowEditing={false}
              />
              <Column
                dataField="NivelDominioCompetencia"
                caption="Nivel de Dominio Esperado"
                allowEditing={false}
              />
              <Column
                dataField="CodigoNivelDominioCompetenciaEvaluacion"
                caption="Nivel Dominio del Empleado"
                setCellValue={setCellValue}
                allowEditing={false}
              >
                <Lookup
                  dataSource={maestroNivelDominioCompetencia}
                  valueExpr="CodigoNivelDominioCompetencia"
                  displayExpr="Descripcion"
                />
              </Column>
              {/* <Column
            dataField="NivelDominioCompetenciaEvaluacion"
            caption="Nivel Dominio del Empleado"
          /> */}
              {/* <Column
            dataField="ValorEsperadoTexto"
            caption="Resultado"
            allowEditing={false}
          /> */}
              <Column
                dataField="Resultado"
                caption="Resultado"
                allowEditing={false}
              />
              <Column cellRender={cellSemaforo} caption="Indicador" />
              {/* <Column
            dataField="ValorEsperado"
            caption="Indicador"
            allowEditing={false}
          /> */}
              {/* <Column
            dataField="NivelDominio"
            caption="Nivel de Dominio Requerido"
            cellRender={ratingStarsDominio}
          />

          <Column
            dataField="DominioEmpleado"
            caption="Nivel de Dominio de empleado"
            cellRender={ratingStarsDominio}
          />

          <Column
            dataField="RelevanciaPuesto"
            caption="Relevancia Puesto"
            cellRender={ratingStars}
          /> */}
              {/* <Column
            dataField="Path"
            caption="Evidencia"
            cellRender={chipAdjunto}
          /> */}

              <Summary>
                <GroupItem
                  column="ConductaObservable"
                  summaryType="count"
                  displayFormat="{0} conductas"
                />
                <GroupItem
                  column="Resultado"
                  summaryType="sum"
                  displayFormat="{0} puntos"
                />
                <TotalItem
                  column="ConductaObservable"
                  summaryType="count"
                  displayFormat="{0} conductas"
                />
                <TotalItem
                  column="Resultado"
                  summaryType="sum"
                  displayFormat="{0} puntos"
                />
              </Summary>

              <ColumnFixing enabled={true} />
              <Sorting mode="multiple" />
              <Paging defaultPageSize={10} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[5, 10, 20]}
                showInfo={true}
              />
              <Export enabled={false} allowExportSelectedData={false} />
              <Scrolling columnRenderingMode="virtual" />
            </DataGrid>
          </React.Fragment>
        )}
      </Paper>

      <ToastContainer />
    </React.Fragment>
  );
};

export default HistoricoCompetencias;
