import React, { useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import MaterialTable from "material-table";
import SettingsIcon from '@material-ui/icons/Settings';

import ConfirmDialog from "../../ConfirmDialog/ConfirmDialog";

export default function PlantillaTablePagination(props) {
  const [filas, setFilas] = React.useState(props.rows);
  const [confirmTexto, setConfirmTexto] = useState("");
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [codigo,setCodigo] = useState("");
  const [] = useState();
  const [] = useState();

  //Equivalente a didmount
  useEffect(() => {
    setFilas(props.rows);
  }, []);
  useEffect(() => {
    setFilas(props.rows);
  }, [props.rows]);

  const remover = async () => {
   await props.remover(codigo);
   setCodigo("");

  };

  const editar = (codigo) => {
    props.editar(codigo);
  };

  const redireccionar = (codigo) => {
    props.redireccionar(codigo);
  };


  const confirmarAccion = (id) => {
    setConfirmTexto(`¿Confirma que desea eliminar este registro? esta acción no podrá revertirse.`);
    setConfirmOpen(true);
    setCodigo(id);
  };

 const funcionOk = () => {
    remover();
  };

  const cerrarConfirm = () => {
    setConfirmTexto("");
    setConfirmOpen(false);
     
  };

  
  const [state, setState] = React.useState({
    columns: [
      {
        title: "Nombre",
        field: "Nombre",
      },
      {
        title: "Activo",
        field: "Activo",
         
      },
      {
        title: "CodigoPlantilla",
        field: "CodigoPlantilla",
        hidden: true,
      },
      
    ],
    data: filas,
  });

  return (
    <Container maxWidth="md">
      <MaterialTable
        title=""
        columns={state.columns}
        data={props.rows}
        editable={{
          // onRowAdd: (newData) =>
          //   new Promise((resolve) => {
          //     setTimeout(() => {
          //       resolve();
          //       setState((prevState) => {
          //         const data = [...prevState.data];
          //         data.push(newData);
          //         return {
          //           ...prevState,
          //           data,
          //         };
          //       });
          //     }, 600);
          //   }),
          // onRowUpdate: (newData, oldData) =>
          //   new Promise((resolve) => {
          //     setTimeout(() => {
          //       resolve();
          //       if (oldData) {
          //         setState((prevState) => {
          //           const data = [...prevState.data];
          //           data[data.indexOf(oldData)] = newData;
          //           return {
          //             ...prevState,
          //             data,
          //           };
          //         });
          //       }
          //     }, 600);
          //   }),
          // onRowDelete: (oldData) =>
          //   new Promise((resolve, reject) => {
          //     setTimeout(() => {
          //       // const dataDelete = [...data];
          //       // const index = oldData.tableData.id;
          //       // dataDelete.splice(index, 1);
          //       // setData([...dataDelete]);
          //       console.log(oldData.CodigoPlantilla);
          //       remover(oldData.CodigoPlantilla);
          //       resolve();
          //     }, 1000);
          //   }),
        }}
        actions={[
          {
            icon: 'edit',
            tooltip: 'Editar',
            onClick: (event, rowData) => {
              editar(rowData.CodigoPlantilla);
                          }
          },
          {
            icon: 'delete',
            tooltip: 'Eliminar',
            onClick: (event, rowData) => {
              confirmarAccion(rowData.CodigoPlantilla);
            }
          },
          {
            icon:'settings',
            tooltip: 'Configurar campos',
            onClick: (event, rowData) => {
              redireccionar(rowData.CodigoPlantilla);
            }
          }
        ]}
      />
       <ConfirmDialog
            id="confirmar"
            keepMounted
            open={confirmOpen}
            onClose={cerrarConfirm}
            value=""
            texto={confirmTexto}
            okfunction={funcionOk}
          />
         
        
    </Container>
  );
}
