import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import FormularioTema from "../components/formulario-tema";
import Layout from "../../layout/containers/Layout";
import API from "../../utils/api";
import { isSignedIn } from "../../utils/auth";
import { TIPOS_TEMAS, validaPermisoPaginaActual } from '../../utils/helpers';

const styles = (theme) => ({
    uploadContainer: {
        display: 'flex',
        alignItems: 'center',
        flex: 1
    },
    input: {
        display: 'none',
    },
});

class EditarTema extends Component {

    state = {
        loading: false,
        Id: null,
        HeaderUrl: null,
        FooterUrl: null,
        nombre: null,
        tipo: null,
        temaActivo: true,
        colorNombre: "#911F27",
        colorGerencia: "#606060",
        colorMensaje: "#000000",
        HeaderImg: null,
        FooterImg: null
    }

    async componentDidMount() {
        //CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
        const llaveAgrupacion = "Menu.Principal.Web";
        const llavePagina = "Menu.Principal.Web.Notificaciones";
        await validaPermisoPaginaActual(llaveAgrupacion, llavePagina);
        //FIN DE CONSULTA DE PERMISOS

        const { match: { params } } = this.props;
        console.log('props ', this.props);
        API.fetchGetRequest(`/api/temas-notificaciones/${params.id}`).then(res => {
            const { data } = res;
            data.nombre = data.Nombre;
            data.tipo = TIPOS_TEMAS.find(item => item.value === data.Tipo) || null;
            data.colorNombre = `#${data.ColorNombre}`;
            data.colorGerencia = `#${data.ColorGerencia}`;
            data.colorMensaje = `#${data.ColorMensaje}`;
            data.temaActivo = data.TemaActivo;
            this.setState({ ...data });
        })
    }

    onChangeValue = (name) => (event, value) => {
        if (name !== 'tipo')
            value = event;
        if (name === 'nombre')
            value = event.target.value
        if (name === 'temaActivo')
            value = event.target.checked
        this.setState({ [name]: value });
    }

    guardarEvento = async () => {
        const body = {...this.state}
        console.log("values ", this.state)
        if (!body.nombre || !body.tipo || !body.colorNombre || !body.colorGerencia || !body.colorMensaje) {
            toast.error("Faltan campos")
        } else if (!body.HeaderUrl && !body.HeaderImg) {
            toast.error("Seleccione una imagen de header")
        } else if (!body.FooterUrl && !body.FooterImg) {
            toast.error("Seleccione una imagen de footer")
        } else {
            const session = await isSignedIn();
            const formData = new FormData();
            formData.append('nombre', body.nombre);
            formData.append('tipo', body.tipo.value);
            formData.append('temaActivo', body.temaActivo);
            formData.append('colorNombre', body.colorNombre.replace('#', ''));
            formData.append('colorGerencia', body.colorGerencia.replace('#', ''));
            formData.append('colorMensaje', body.colorMensaje.replace('#', ''));
            formData.append('existeHeader', body.HeaderImg !== null);
            formData.append('existeFooter', body.FooterImg !== null);
            formData.append('0', body.HeaderImg);
            formData.append('1', body.FooterImg);
            formData.append('usuario', session["userName"]);

            this.setState({ loading: true });
            API.post(`/api/temas-notificaciones/${body.Id}`, formData).then(res => {
                toast.success("Tema editado correctamente");
                setTimeout(() => {
                    this.props.history.push("/centro-temas-notificaciones");
                }, 3000);
            }).catch(err => {
                const error = err.response;
                let mensaje = "Error al crear el tema, intentelo de nuevo."
                if (error && error.data && error.data.Message)
                    mensaje = error.data.Message
                toast.error(mensaje)
            }).finally(() => this.setState({ loading: false }))
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <Layout titulo="Editar tema">
                <Typography variant="h5" gutterBottom>Personalizar el tema</Typography>
                {/* {(this.state.Id) && ( */}
                    <FormularioTema
                        values={this.state}
                        onChangeValue={this.onChangeValue}
                        guardar={this.guardarEvento}
                        loading={this.state.loading}
                        classes={classes}
                    />
                {/* )} */}
                <ToastContainer />
            </Layout>
        );
    }
}

const EditarTemaContainer = withStyles(styles)(EditarTema);
export default withRouter(EditarTemaContainer);
