// import React, { useState, useEffect } from "react";
// import PropTypes from "prop-types";
// import { makeStyles, useTheme } from "@material-ui/core/styles";
// import Table from "@material-ui/core/Table";
// import TableBody from "@material-ui/core/TableBody";
// import TableCell from "@material-ui/core/TableCell";
// import TableContainer from "@material-ui/core/TableContainer";
// import TableFooter from "@material-ui/core/TableFooter";
// import TablePagination from "@material-ui/core/TablePagination";
// import TableRow from "@material-ui/core/TableRow";
// import Paper from "@material-ui/core/Paper";
// import IconButton from "@material-ui/core/IconButton";
// import FirstPageIcon from "@material-ui/icons/FirstPage";
// import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
// import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
// import LastPageIcon from "@material-ui/icons/LastPage";

// const useStyles1 = makeStyles((theme) => ({
//   root: {
//     flexShrink: 0,
//     marginLeft: theme.spacing(2.5),
//   },
// }));

// function TablePaginationActions(props) {
//   const classes = useStyles1();
//   const theme = useTheme();
//   const { count, page, rowsPerPage, onChangePage } = props;

//   const handleFirstPageButtonClick = (event) => {
//     onChangePage(event, 0);
//   };

//   const handleBackButtonClick = (event) => {
//     onChangePage(event, page - 1);
//   };

//   const handleNextButtonClick = (event) => {
//     onChangePage(event, page + 1);
//   };

//   const handleLastPageButtonClick = (event) => {
//     onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
//   };

//   return (
//     <div className={classes.root}>
//       <IconButton
//         onClick={handleFirstPageButtonClick}
//         disabled={page === 0}
//         aria-label="first page"
//       >
//         {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
//       </IconButton>
//       <IconButton
//         onClick={handleBackButtonClick}
//         disabled={page === 0}
//         aria-label="previous page"
//       >
//         {theme.direction === "rtl" ? (
//           <KeyboardArrowRight />
//         ) : (
//           <KeyboardArrowLeft />
//         )}
//       </IconButton>
//       <IconButton
//         onClick={handleNextButtonClick}
//         disabled={page >= Math.ceil(count / rowsPerPage) - 1}
//         aria-label="next page"
//       >
//         {theme.direction === "rtl" ? (
//           <KeyboardArrowLeft />
//         ) : (
//           <KeyboardArrowRight />
//         )}
//       </IconButton>
//       <IconButton
//         onClick={handleLastPageButtonClick}
//         disabled={page >= Math.ceil(count / rowsPerPage) - 1}
//         aria-label="last page"
//       >
//         {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
//       </IconButton>
//     </div>
//   );
// }

// TablePaginationActions.propTypes = {
//   count: PropTypes.number.isRequired,
//   onChangePage: PropTypes.func.isRequired,
//   page: PropTypes.number.isRequired,
//   rowsPerPage: PropTypes.number.isRequired,
// };

// function createData(name, calories, fat) {
//   return { name, calories, fat };
// }

// // const rows = [
// //   createData("Cupcake", 305, 3.7),
// //   createData("Donut", 452, 25.0),
// //   createData("Eclair", 262, 16.0),
// //   createData("Frozen yoghurt", 159, 6.0),
// //   createData("Gingerbread", 356, 16.0),
// //   createData("Honeycomb", 408, 3.2),
// //   createData("Ice cream sandwich", 237, 9.0),
// //   createData("Jelly Bean", 375, 0.0),
// //   createData("KitKat", 518, 26.0),
// //   createData("Lollipop", 392, 0.2),
// //   createData("Marshmallow", 318, 0),
// //   createData("Nougat", 360, 19.0),
// //   createData("Oreo", 437, 18.0),
// // ].sort((a, b) => (a.calories < b.calories ? -1 : 1));

// const useStyles2 = makeStyles({
//   table: {
//     minWidth: 500,
//   },
// });

// export default function UsuariosTable(props) {
//   const classes = useStyles2();
//   const [page, setPage] = React.useState(0);
//   const [rowsPerPage, setRowsPerPage] = React.useState(5);
//   const [usuariosCopia, setUsuariosCopia] = useState([]);

//   const filas = props.rows;
//   // //Equivalente a didmount
//   // useEffect(() => {
//   //   setUsuariosCopia(props.rows);
//   // }, []);

//   const emptyRows =
//     rowsPerPage - Math.min(rowsPerPage, filas.length - page * rowsPerPage);

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);
//   };

//   return (
//     <TableContainer component={Paper}>
//       <Table className={classes.table} aria-label="Lista de miembros">
//         <TableBody>
//           {(rowsPerPage > 0
//             ? filas.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
//             : filas
//           ).map((row) => (
//             <TableRow key={row.CodigoGrupoUsuario}>
//               <TableCell component="th" scope="row">
//                 {row.NombreCompletoUsuario}
//               </TableCell>
//             </TableRow>
//           ))}

//           {emptyRows > 0 && (
//             <TableRow
//               style={{
//                 height: 53 * emptyRows,
//               }}
//             >
//               <TableCell colSpan={6} />
//             </TableRow>
//           )}
//         </TableBody>
//         <TableFooter>
//           <TableRow>
//             <TablePagination
//               rowsPerPageOptions={[
//                 5,
//                 10,
//                 25,
//                 {
//                   label: "All",
//                   value: -1,
//                 },
//               ]}
//               colSpan={3}
//               count={filas.length}
//               rowsPerPage={rowsPerPage}
//               page={page}
//               SelectProps={{
//                 inputProps: {
//                   "aria-label": "Filas por página",
//                 },
//                 native: true,
//               }}
//               onChangePage={handleChangePage}
//               onChangeRowsPerPage={handleChangeRowsPerPage}
//               ActionsComponent={TablePaginationActions}
//             />
//           </TableRow>
//         </TableFooter>
//       </Table>
//     </TableContainer>
//   );
// }

import React, { useEffect } from "react";
import Container from "@material-ui/core/Container";
import MaterialTable from "material-table";

export default function UsuariosTable(props) {
  const [filas, setFilas] = React.useState(props.rows);

  //Equivalente a didmount
  useEffect(() => {
    setFilas(props.rows);
  }, []);
  useEffect(() => {
    setFilas(props.rows);
  }, [props.rows]);

  const removerUsuarios = (codigoGrupoUsuario) => {
    props.removerUsuario(codigoGrupoUsuario);
  };

  const [state, setState] = React.useState({
    columns: [
      {
        title: "Nombre",
        field: "NombreCompletoUsuario",
      },
      {
        title: "CodigoGrupoUsuario",
        field: "CodigoGrupoUsuario",
        hidden: true,
      },
      {
        title: "CodigoUsuario",
        field: "CodigoUsuario",
        hidden: true,
      },
    ],
    data: filas,
  });

  return (
    <Container maxWidth="md">
      <MaterialTable
        title=""
        columns={state.columns}
        data={props.rows}
        editable={{
          // onRowAdd: (newData) =>
          //   new Promise((resolve) => {
          //     setTimeout(() => {
          //       resolve();
          //       setState((prevState) => {
          //         const data = [...prevState.data];
          //         data.push(newData);
          //         return {
          //           ...prevState,
          //           data,
          //         };
          //       });
          //     }, 600);
          //   }),
          // onRowUpdate: (newData, oldData) =>
          //   new Promise((resolve) => {
          //     setTimeout(() => {
          //       resolve();
          //       if (oldData) {
          //         setState((prevState) => {
          //           const data = [...prevState.data];
          //           data[data.indexOf(oldData)] = newData;
          //           return {
          //             ...prevState,
          //             data,
          //           };
          //         });
          //       }
          //     }, 600);
          //   }),
          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                // const dataDelete = [...data];
                // const index = oldData.tableData.id;
                // dataDelete.splice(index, 1);
                // setData([...dataDelete]);
                console.log(oldData.CodigoGrupoUsuario);
                removerUsuarios(oldData.CodigoGrupoUsuario);
                resolve();
              }, 1000);
            }),
        }}
      />
    </Container>
  );
}
