import React, { useState, useEffect } from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Button
} from "@material-ui/core";
import api from "../../../utils/api";
import { toast } from "react-toastify";
import Loading from '../utils/Loading'

const Correlativos = ({ onClose, onSelect, solicitud }) => {
  const [comienza, setComienza] = useState(0);
  const [termina, setTermina] = useState(0);
  const [items, setItems] = useState([]);
  const [ocupados, setOcupados] = useState([]);
  const [loading, setLoading] = useState(false)
  const getOcupados = async () => {
    const body = {
      IdTipoDocumento: solicitud.IdTipoDocumento,
      IdMacroproceso: solicitud.IdMacroproceso,
      IdProceso: solicitud.IdProceso,
      IdDepartamento: solicitud.IdDepartamento
    };
    const { data } = await api.realizarPeticionPostPut(
      "api/documentos/correlativo/ocupados-por-proceso-tipodocumento",
      body,
      "POST"
    );
    setOcupados(data.map((i) => i.CorrelativoDocumento));
  };
  const getRango = async () => {
    const body = {
      IdTipoDocumento: solicitud.IdTipoDocumento,
      IdMacroproceso: solicitud.IdMacroproceso,
      IdProceso: solicitud.IdProceso,
      IdDepartamento: solicitud.IdDepartamento
    };
    const { data } = await api.realizarPeticionPostPut(
      "api/documentos/correlativo/rango",
      body,
      "POST"
    );
    setComienza(data.CorrelativoInicio);
    setTermina(data.CorrelativoFin);
  };
  useEffect(() => {
    const _items = [];
    for (let i = comienza; i <= termina; i++) {
      _items.push(i);
    }
    setItems([..._items]);
  }, [comienza, termina]);
  useEffect(() => {
    (async () => {
      try {
        setLoading(true)
        await getRango();
        await getOcupados();
      } catch (error) {
        toast.error("Correlativos incorrectos");
        onClose();
      } finally {
        setLoading(false)
      }
    })();
  }, []);
  return (
    <>
      <DialogTitle>Correlativos</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          {items.map((i) => (
            <Grid item xs={2}>
              <Button
                size="small"
                variant="contained"
                disabled={ocupados.includes(i)}
                onClick={() => onSelect(i)}
              >
                {i}
              </Button>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
      </DialogActions>
      <Loading loading={loading} />
    </>
  );
};

export default Correlativos;
