import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { IOSSwitch } from "../../helpers/components/ios-switch";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//Own Components
import Layout from "../../layout/containers/Layout";
import API from "../../utils/api";
import SaveButton from "../../helpers/components/save-button-float";
import { isSignedIn } from "../../utils/auth";
import {validaPermisoPaginaActual} from "../../utils/helpers";

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%"
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  buttonAdd: {
    margin: theme.spacing.unit
  },
  iOSSwitchBase: {
    "&$iOSChecked": {
      color: theme.palette.common.white,
      "& + $iOSBar": {
        backgroundColor: "#52d869"
      }
    },
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.sharp
    })
  },
  iOSChecked: {
    transform: "translateX(15px)",
    "& + $iOSBar": {
      opacity: 1,
      border: "none"
    }
  },
  iOSBar: {
    borderRadius: 13,
    width: 42,
    height: 26,
    marginTop: -13,
    marginLeft: -21,
    border: "solid 1px",
    borderColor: theme.palette.grey[400],
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"])
  },
  iOSIcon: {
    width: 24,
    height: 24
  },
  iOSIconChecked: {
    boxShadow: theme.shadows[1]
  }
});

class Detail extends React.Component {
  state = {
    open: false,
    permiso: {
      CodigoPermiso: "",
      Permiso: "",
      Llave: "",
      Descripcion: "",
      Activo: true,
      Eliminado: false,
      FechaInserto: "",
      CodigoPermisoAgrupacion: 0,
      Icono: ""
    }
  };

  async componentWillMount() {

//CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
const llaveAgrupacion = "Menu.Principal.Web";
const llavePagina = "Menu.Principal.Web.Permisos";
await validaPermisoPaginaActual(llaveAgrupacion, llavePagina);
//FIN DE CONSULTA DE PERMISOS

    await this.setState({ CodigoPermiso: this.props.match.params.id });
    await this.makeRequest();
  }

  makeRequest = async () => {
    const request = await API.fetchGetRequest(
      `api/desktop/permiso/${this.state.CodigoPermiso}`
    );
    const permiso = request.data.permiso;

    this.setState({
      permiso: permiso
    });
  };

  guardar = async () => {
    try {
      let vIsSignedIn = await isSignedIn();

      if (vIsSignedIn.response) {
        let request;

        const data = {
          ...this.state.permiso,
          UsuarioModifico: vIsSignedIn["userName"]
        };

        request = await API.post(
          `api/desktop/permiso/${this.state.permiso.CodigoPermiso}`,
          data
        );
        if (request.status != 200) {
          alert(
            "Hubo un error al guardar el registro, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        }

        if (request.status === 401) {
          //alert("cerrar sesion");
        } else {
          if (request.data.response) {
            toast.info(request.data.mensaje);
          } else {
            toast.error(request.data.mensaje);
          }
        }
      }
    } catch (e) {
      alert("hubo un error");
    }
  };

  handleChange = name => event => {
    let permiso = this.state.permiso;

    permiso[name] = event.target.value;
    this.setState({
      permiso
    });
  };

  handleChangeSwitch = name => event => {
    let permiso = this.state.permiso;

    permiso[name] = event.target.checked;
    this.setState({ permiso });
  };

  handleChangeRol = name => event => {
    const rolesUsuario = this.state.rolesUsuario;
    let rolesUsuarioCopia = rolesUsuario.filter(item => {
      if (item.CodigoRol === name) {
        item.Activo = event.target.checked;
      }
      return item;
    });

    this.setState({ rolesUsuario: rolesUsuarioCopia });
  };

  render() {
    const { classes } = this.props;
    let { data } = this.state;

    return (
      <div>
        <Layout titulo={`Permiso`}>
          <Grid container spacing={24}>
            <Grid item xs />
            <Grid item xs={7}>
              <Card className={classes.card}>
                <CardContent>
                  <Typography color="textSecondary" gutterBottom>
                    Datos generales
                  </Typography>
                  <TextField
                    disabled
                    id="standard-disabled"
                    label="Codigo"
                    value={this.state.permiso.CodigoPermiso}
                    className={classes.textField}
                    margin="normal"
                  />
                  <TextField
                    id="standard-name"
                    label="Llave"
                    className={classes.textField}
                    value={this.state.permiso.Llave}
                    onChange={this.handleChange("Llave")}
                    margin="normal"
                  />
                  <TextField
                    id="standard-name"
                    label="Permiso"
                    className={classes.textField}
                    value={this.state.permiso.Permiso}
                    onChange={this.handleChange("Permiso")}
                    margin="normal"
                  />
                  <TextField
                    id="standard-name"
                    label="Icono"
                    className={classes.textField}
                    value={this.state.permiso.Icono}
                    onChange={this.handleChange("Icono")}
                    margin="normal"
                  />
                  <TextField
                    id="standard-name"
                    label="Descripcion"
                    className={classes.textField}
                    value={this.state.permiso.Descripcion}
                    onChange={this.handleChange("Descripcion")}
                    margin="normal"
                  />

                  <br />
                  <br />
                  <Grid container>
                    <Grid item xs={10}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        className={classes.textField}
                      >
                        Activo
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      {/* <FormControlLabel
                        className={classes.textField}
                        control={
                          <Switch
                            classes={{
                              switchBase: classes.iOSSwitchBase,
                              bar: classes.iOSBar,
                              icon: classes.iOSIcon,
                              iconChecked: classes.iOSIconChecked,
                              checked: classes.iOSChecked
                            }}
                            disableRipple
                            checked={this.state.permiso.Activo}
                            onChange={this.handleChangeSwitch("Activo")}
                            value="Activo"
                          />
                        }
                      /> */}
                      <FormControlLabel
                        className={classes.textField}
                        control={
                          <IOSSwitch
                            checked={this.state.permiso.Activo}
                            onChange={this.handleChangeSwitch("Activo")}
                            value="Activo"
                          />
                        }
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <br />
              <br />
              <Card className={classes.card}>
                <CardContent>
                  <Typography color="textSecondary" gutterBottom>
                    Roles
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs />
          </Grid>
          <SaveButton onClick={() => this.guardar()} />
          <ToastContainer />
        </Layout>
      </div>
    );
  }
}

// We need an intermediary variable for handling the recursive nesting.
const DetailContainer = withStyles(styles)(Detail);

export default withRouter(DetailContainer);
