import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import AssignmentIcon from "@material-ui/icons/Assignment";
import CardHeader from '@material-ui/core/CardHeader';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import IconButton from '@material-ui/core/IconButton';

import ActionMenuObject from "./action-menu-objects";
import {isSignedIn} from "../../utils/auth";
import API from "../../utils/api";
import { ToastContainer, toast } from "react-toastify";
import { convertirHHMM } from "../../utils/helpers";
import ProgressComponent from "../../helpers/components/progress-component";

const useStyles = makeStyles({
  root: {
    maxWidth: "100%",
    width: "100%",
    marginTop: 5,
  },
  cardHeader:{
    backgroundColor:"#324659",
    color:"#fff",
  },
});

function calcularTiempoRestante(fechaCreacion, tiempoResolucion) {
  if (tiempoResolucion == null || tiempoResolucion == 0) {
    return null;
  }

  let dt = new Date(fechaCreacion); //from 1970/1/1 00:00:00 to now

  let dtNow = new Date();

  // let result = dtNow.getMilliseconds();

  // let seconds = Convert.ToInt32(result.TotalSeconds);

  let seconds = dtNow.getMilliseconds() * 1000 - dt.getMilliseconds() * 1000;

  let secondsAbs = Math.abs(tiempoResolucion - seconds);

  let tiempoAmigable = "";
  if (secondsAbs / 60 <= 1) {
    tiempoAmigable = secondsAbs + " seg";
  } else if (secondsAbs / 60 <= 59) {
    tiempoAmigable = Math.round(secondsAbs / 60, 1) + " min";
  } else {
    //tiempoAmigable = Math.Round((secondsAbs / 60) / 60, 1) + " hrs";

    //let t = TimeSpan.FromSeconds(secondsAbs);

    // tiempoAmigable = string.Format("{0:D2}h:{1:D2}m", t.Hours, t.Minutes);
    tiempoAmigable = convertirHHMM(seconds);
  }

  if (tiempoResolucion - seconds <= 0) {
    return "-" + tiempoAmigable;
  } else {
    return tiempoAmigable;
  }
}

export default function CardAviso(props) {

  const clonar=async()=>{
    let vIsSignedIn = await isSignedIn();
    if (!vIsSignedIn.response) {
      this.window.location.href = "/login";
    }
    //Valida permisos para editar
    // let permisos = await consultarPermiso(idObjeto, llave);
    // if (!permisos.U) {
    //   toast.warn("No tienes permiso para editar este elemento");
    //   return;
    // }

    let uri = `api/aviso/clonarGestion/${props.CodigoAviso}/${vIsSignedIn.codigoUsuario}`;
    let request = await API.post(uri);
    if (request.status != 200) {
      alert(
        "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
      );
    }

    if (request.statusCode === 401) {
      //alert("cerrar sesion");
    } else {
      toast.success("La gestión se ha clonado exitosamente.");
      props.recargar();
    }
  }
  const classes = useStyles();
  const colorFranja = props.Activo
    ? props.Atrasado
      ? "#e74c3c"
      : "#2ecc71"
    : "#979797";

  const backgroundColor = props.Activo ? "white" : "#EEEEEE";
  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div
        style={{
          backgroundColor: colorFranja,
          width: 7,
          marginTop: 5,
        }}
      />
      <Card className={classes.root}>
        <CardHeader
          className={classes.cardHeader}
          avatar={
            // <Avatar aria-label="recipe" className={classes.avatar}>
            //   R
            // </Avatar>
            <ProgressComponent
            percent = {props.porcentajeAvance}
            textColor = "#fff"
            />

          }
          action={
            <div>
              <ActionMenuObject
                style ={{color:"#fff"}}
                idObjeto={props.CodigoAviso}
                llave={"AITAviso"}
                clonar={clonar}
              />
            </div>
          }
          title={"#[" + props.CodigoAviso + "] " + props.Nombre}
          subheader={<span style={{color:"#bbb"}}>{props.NombreCarpeta}</span>}
        />
        <CardActionArea>
          <CardContent>
            {/* <Typography gutterBottom variant="h5" component="h2">
              {"#[" + props.CodigoAviso + "] " + props.Nombre}
            </Typography> */}
            {/* <span
              style={{
                border: "Solid",
                borderWidth: "0.5px",
                borderColor: "#7f8c8d",
                backgroundColor: "#ecf0f1",
                padding: 5,
                margin: 5,
              }}
            >
              {props.NombreCarpeta}
            </span> */}
          
            <div style={{ display: "flex", flexDirection:"row",  }}>
              <div style={{width:"85%"}} 
              onClick={() => {
                window.location.href = "/gestion/" + props.CodigoAviso;
                //this.props.history.push("/avisodetalle/" + this.props.CodigoAviso);
              }}
              >
                <div style={{ marginTop: 5, color: "#888" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {props.Fecha + "      "}
                    {props.NombreEstado === undefined ||
                    props.NombreEstado === null ? (
                      <></>
                    ) : (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <AssignmentIcon style={{ marginLeft: "20" }} />
                        <span>{"   " + props.NombreEstado}</span>
                      </div>
                    )}
                  </div>
                </div>

                <div>
                  {props.NombreEstado === undefined ||
                  props.NombreEstado === null ? (
                    <></>
                  ) : (
                    <div>
                      {"Paciente: " + props.Paciente}
                    </div>
                  )}
                </div>
              </div>
              <div style={{display:"flex", width:"15%", alignItems:"center", justifyContent:"flex-end"}}>
               
                <IconButton
                
                  aria-label="Ver detalle"
                  onClick={() => {
                    window.location.href = "/gestion/" + props.CodigoAviso;
                    //this.props.history.push("/avisodetalle/" + this.props.CodigoAviso);
                  }}
                >
                  <ArrowForwardIosIcon />
                </IconButton>
              </div>
            </div>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
            ></Typography>
          </CardContent>
        </CardActionArea>
        {/* <CardActions>
        <Button size="small" color="primary">
          Share
        </Button>
        <Button size="small" color="primary">
          Learn More
        </Button>
      </CardActions> */}
      </Card>
    </div>
  );
}
