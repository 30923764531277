import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import EncabezadoAvance from "./encabezado-avance";
import Paper from "@material-ui/core/Paper";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    marginBottom: 15,
    display: "flex",
    width: "100%",
    flexDirection: "row",
    backgroundColor: "#324659",
    borderRadius: 15,
    padding: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5,
    marginBottom: 20,
  },
  container: {
    padding: 20,
    backgroundColor: "#2B3C4D",
  },
  cardPercent: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    backgroundColor: "#324659",
    borderRadius: 15,
    padding: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5,
    marginBottom: 20,
  },
  leftSide: {
    display: "flex",
    margin: 5,
    marginLeft: 15,
    marginRight: 10,
    alignItems: "center",
    justifyContent: "center",
  },
  centerSide: { flex: 1, margin: 5, textAlign: "left" },
  rightSide: {
    display: "flex",
    margin: 5,
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    color: "white",
    fontSize: 16,
    fontWeight: "500",
  },
  detailView: {
    flexDirection: "row",
  },
  text: {
    color: "white",
    marginLeft: 3,
    fontSize: 12,
  },
  addButton: {
    backgroundColor: "#4DAD4A",
  },
}));

function CardPercentage(props) {
  const styles = useStyles();

  const {
    percent,
    percentColor,
    porcentajeAvance,
    tareasTerminadas,
    totalTareas,
    permisoCrearTarea,
  } = props;
  return (
    <Paper className={styles.paper}>
      {/* <div className={styles.cardPercent}> */}
      <div className={styles.leftSide}>
        <EncabezadoAvance percent={porcentajeAvance} textColor={"white"} />
      </div>
      <div style={{ height: "100%", width: 1, backgroundColor: "#2B3C4D" }} />
      <div className={styles.centerSide}>
        <span className={styles.title}>Tareas</span>
        <div className={styles.detailView}>
          <span className={styles.text}>
            ({tareasTerminadas}/{totalTareas}) Completadas
          </span>
        </div>
      </div>
      {permisoCrearTarea && (
        <div className={styles.rightSide}>
          <Fab
            color="primary"
            aria-label="add"
            size="small"
            style={{ backgroundColor: "#69bd4b" }}
            onClick={props.onClickCrearTarea}
          >
            <AddIcon />
          </Fab>
        </div>
      )}
      {/*  </div> */}
    </Paper>
  );
}

export default CardPercentage;
