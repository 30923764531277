import React, { useEffect, useState } from "react";
import Color from "color";
import GoogleFont from "react-google-font-loader";
import { makeStyles } from "@material-ui/core/styles";
import NoSsr from "@material-ui/core/NoSsr";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CardActionArea from "@material-ui/core/CardActionArea";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { useFourThreeCardMediaStyles } from "@mui-treasury/styles/cardMedia/fourThree";

import { isSignedIn, signOut } from "../../utils/auth";
import API from "../../utils/api";

const useGridStyles = makeStyles(({ breakpoints }) => ({
  root: {
    [breakpoints.up("md")]: {
      justifyContent: "center",
    },
  },
}));

const useStyles = makeStyles(() => ({
  actionArea: {
    borderRadius: 16,
    transition: "0.2s",
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  card: ({ color }) => ({
    minWidth: 128,
    maxWidth: 128,
    borderRadius: 16,
    boxShadow: "none",
    "&:hover": {
      boxShadow: `0 6px 12px 0 ${Color(color)
        .rotate(-12)
        .darken(0.2)
        .fade(0.5)}`,
    },
  }),
  content: ({ color }) => {
    return {
      backgroundColor: color,
      padding: "1rem 1.5rem 1.5rem",
    };
  },
  title: {
    // fontFamily: 'Keania One',
    fontSize: "1rem",
    color: "#fff",
    textTransform: "uppercase",
  },
  subtitle: {
    // fontFamily: 'Montserrat',
    color: "#fff",
    opacity: 0.87,
    marginTop: "2rem",
    fontWeight: 500,
    fontSize: 14,
  },
}));

const CustomCard = ({ classes, title, dpi }) => {
  const mediaStyles = useFourThreeCardMediaStyles();
  const [foto, setFoto] = useState(null);

  useEffect(() => {
    const cargar = async () => {
      if (dpi !== null) {
        await cargarFoto();
      }
    };

    cargar();
  }, [dpi]);

  const cargarFoto = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    }

    try {
      let uri = "api/Empleado/Foto/" + dpi;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        setFoto(null);
        return false;
      } else {
        if (request.data.data !== null) {
          setFoto(request.data.data);
        }
      }
    } catch (e) {
      setFoto(null);
      console.log(e);
    } finally {
    }
  };

  return (
    <CardActionArea className={classes.actionArea}>
      <Card className={classes.card}>
        <CardMedia classes={mediaStyles} image={foto} />
        <CardContent className={classes.content}>
          <Typography className={classes.title} variant={"h3"}>
            {title}
          </Typography>
        </CardContent>
      </Card>
    </CardActionArea>
  );
};

const SolidGameCardDemo = (props) => {
  const gridStyles = useGridStyles();
  const styles = useStyles({ color: "#203f52" });

  const [empleadosPlazaLaboral, setEmpleadosPlazaLaboral] = useState([]);

  useEffect(() => {
    const cargar = async () => {
      if (props.codigoPlazaLaboral > 0) {
        props.handleAbrirBackDrop();

        await cargarDatosEmpleadoPlazaLaboral();

        props.handleCerrarBackDrop();
      }
    };

    cargar();
  }, [props.codigoPlazaLaboral]);

  const cargarDatosEmpleadoPlazaLaboral = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      //   setUserName(session["userName"]);
    }

    try {
      props.handleAbrirBackDrop();

      let uri = "api/PlazaLaboral/Asignados/" + props.codigoPlazaLaboral;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setEmpleadosPlazaLaboral(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
    } finally {
      props.handleCerrarBackDrop();
    }
  };

  return (
    <>
      <Grid classes={gridStyles} container spacing={4} wrap={"wrap"}>
        {empleadosPlazaLaboral.length > 0 ? (
          empleadosPlazaLaboral.map((empleado) => (
            <Grid item>
              <CustomCard
                classes={styles}
                title={empleado.NombreCompleto}
                dpi={empleado.DPI}
              />
            </Grid>
          ))
        ) : (
          <Grid item>
            <Typography>No existen empleados asignados a esta plaza</Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default SolidGameCardDemo;
