import React from 'react'
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";
import {MuiPickersUtilsProvider, KeyboardDatePicker,} from '@material-ui/pickers';

const DatePicker = ({value, onChange, className, label, format, onlyYear}) => {
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>

            <KeyboardDatePicker
                disableToolbar
                variant = "inline"
                label={label}
                id="date-picker-inline"
                className = {className}
                value = {value!==null?value:Date.now()}
                onChange = {(value) => onChange(value) }
                format={format || "dd/MM/yyyy"}
                margin="normal"
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
                views={onlyYear ? ["year"] : ["year", "month", "date"]}
            >
            </KeyboardDatePicker>
        </MuiPickersUtilsProvider>
    )
}

export default DatePicker
