import { DataGrid, Toast } from "devextreme-react";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from "../../layout/containers/Layout";

import { Box, makeStyles } from "@material-ui/core";
import { Column, ColumnChooser, FilterRow, GroupPanel, Grouping, HeaderFilter, OperationDescriptions, Scrolling, SearchPanel, StateStoring } from "devextreme-react/data-grid";
import API from "../../utils/api";

const ReporteLlamadasApi = () => {
    const classes = styles();
    const [loading, setLoading] = useState(false);
    const [datosGrid, setDatosGrid] = useState([]);

    useEffect(() => {
        setLoading(true);
        toast.info('Cargando...', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        API.fetchGetRequest("api/problema/cargarBitacoraLlamadas").then(request => {
            if (request.data.response) {
                console.log("request", request);
                setDatosGrid(request.data.data);
            }
            else {
                toast.error('Error al cargar los datos', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                console.log("Error al cargar los datos", request);
            }
        }).catch(e => {
            toast.error('Error al cargar los datos', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            console.log("Error al cargar los datos", e);
        });
        setLoading(false);
    }, []);


    return (
        <Layout titulo={'Reporte de llamadas API'} maxWidth={"100%"}>
            <Box className={classes.paperPerfil} pl={0} pt={0}>
                <Box className={classes.box} pl={0} pt={0}>
                    <React.Fragment>
                        <ToastContainer />
                        {loading ? (
                            <Toast
                                type="info"
                                text="Cargando..."
                                showCloseButton={false}
                                displayTime={10000}
                                width={300}
                                height={100}
                                closeOnClick={false}
                                closeOnOutsideClick={false}
                            />
                        ) : (
                            <Box style={{ position: 'relative', width: '100%', outline: 'none' }}>
                                <DataGrid
                                    dataSource={datosGrid}
                                    showBorders={true}
                                    rowAlternationEnabled={true}
                                    columnAutoWidth={true}
                                    allowColumnReordering={true}
                                    columnResizingMode="widget"
                                    allowColumnResizing={true}
                                    wordWrapEnabled
                                    groupPanel={
                                        {
                                            visible: true,
                                            emptyPanelText: "Arrastre un encabezado aquí para agrupar por esa columna"
                                        }
                                    }
                                >
                                    <ColumnChooser enabled={true} />
                                    <Grouping autoExpandAll={false} contextMenuEnabled={true} />
                                    <GroupPanel visible={true} />
                                    <FilterRow visible={true}>
                                        <OperationDescriptions
                                            between="Entre"
                                            contains="Contiene"
                                            endsWith="Termina con"
                                            equal="Igual"
                                            greaterThan="Mayor que"
                                            greaterThanOrEqual="Mayor o igual que"
                                            lessThan="Menor que"
                                            lessThanOrEqual="Menor o igual que"
                                            notContains="No contiene"
                                            notEqual="No igual"
                                            startsWith="Empieza con" />
                                    </FilterRow>
                                    <HeaderFilter visible={true} />
                                    <Scrolling mode="virtual" rowRenderingMode="virtual" scrollByThumb={true} />
                                    <StateStoring enabled={true} type="localStorage" storageKey={'LlamadasApiDataGrid'} />
                                    <SearchPanel visible={true}
                                        width={240}
                                        placeholder="Buscar..." />

                                    <Column dataField="CodigoProblemaApiBitacora" caption="ID" width={'auto'} />
                                    <Column dataField="CodigoAviso" caption="Aviso" width={'auto'} />
                                    <Column dataField="CodigoProblema" caption="Tipo Problema" width={'auto'} />
                                    <Column dataField="CodigoProblemaApi" caption="Id pro. API" width={'auto'} />
                                    <Column dataField="Parametros" caption="Parámetros" width={'auto'} />
                                    <Column dataField="Fecha" caption="Fecha" width={'auto'} dataType={'datetime'} />
                                    <Column dataField="Uri" caption="Uri" width={'auto'} />
                                    <Column dataField="NombreHeaderAuth" caption="Header Auth" width={'auto'} />
                                    <Column dataField="Metodo" caption="Método" width={'auto'} />
                                    <Column dataField="Respuesta" caption="Respuesta" width={'auto'} />
                                    <Column dataField="TareaTrigger" caption="Tarea Trigger" width={'auto'} />
                                    <Column dataField="Estado" caption="Estado" width={'auto'} />
                                    <Column dataField="UsuarioCreacion" caption="Usuario Creación" width={'auto'} />

                                </DataGrid>
                            </Box>

                        )}

                    </React.Fragment>
                </Box>
            </Box>
        </Layout>
    )
};

const styles = makeStyles((theme) => ({
    paperPerfil: {
        position: "relative",
        width: "100%", //theme.spacing.unit * 50,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 1,
        outline: "none",
    },
    box: {
        position: "relative",
        width: "100%", //theme.spacing.unit * 50,
        padding: theme.spacing.unit * 1,
        outline: "none",
    },
}));

export default ReporteLlamadasApi;