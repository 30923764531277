import React,{useState,useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import DirectionsIcon from '@material-ui/icons/Directions';
import SendIcon from '@material-ui/icons/Send';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function InputChat(props) {
  const classes = useStyles();
  const[value,setValue] = useState('');

    const handleChange=(event)=>{
        setValue(event.target.value);
           }
    const limpiarTexto =()=>{
        setValue("");
    }

  return (
    <Paper component="form" className={classes.root}>
      {/* <IconButton className={classes.iconButton} aria-label="menu">
        <MenuIcon />
      </IconButton> */}
      <InputBase
        className={classes.input}
        placeholder="Escribe un mensaje"
        inputProps={{ 'aria-label': 'Mensaje' }}
        multiline
        value = {value}
        onChange={handleChange}
        onKeyPress={(ev) => {
            console.log("keyP: " + ev.key);
            if (ev.key === "Enter") {
              props.send(value);
              limpiarTexto();
              ev.preventDefault();
            }
          }}
      />
      <IconButton  className={classes.iconButton} aria-label="send"
      onClick={()=>{props.send(value);
        limpiarTexto();
    }}
      >
        <SendIcon />
      </IconButton>
      {/* <Divider className={classes.divider} orientation="vertical" />
      <IconButton color="primary" className={classes.iconButton} aria-label="directions">
        <DirectionsIcon />
      </IconButton> */}
    </Paper>
  );
}
