import { action, thunk } from 'easy-peasy'
import api from '../utils/api'
import dayjs from 'dayjs'

const semaforo = (fecha) => {
  const fechaRef = dayjs()
  const meses = fechaRef.diff(fecha, 'month')
  if (meses <= 18) {
    if (meses < 1) {
      const dias = fechaRef.diff(fecha, 'day')
      return {
        color: '#2d6a4f',
        tiempo: `Última actualización: ${dias} días`,
        vigencia: 'VIGENTE'
      }
    }
    return {
      color: '#2d6a4f',
      tiempo: `Última actualización: ${meses} meses`,
      vigencia: 'VIGENTE'
    }
  }
  if (meses > 18 && meses <= 23) {
    const resta = 24 - meses
    return {
      color: '#FFD151',
      tiempo: `El documento expirará en ${resta} ${resta > 1 ? 'meses' : 'mes'}`,
      vigencia: 'POR VENCER'
    }
  }
  const dias = fecha.diff(fechaRef, 'day')
  return {
    color: '#d62828',
    tiempo: dias > 0 ? `El documento expirará en ${dias} dias` : 'Documento expirado',
    vigencia: 'VENCIDO'
  }
}

export default {
  tipos: [],
  sistemas: [],
  divisiones: [],
  docs: [],
  gerencia: null,
  filtros: {
    tipo: null,
    sistema: null,
    anho: null,
    vigencia: null
  },
  favoritos: [],
  docsGerencia: [],
  docsBusqueda: [],
  docsInformado: [],
  macroprocesos: [],
  setTipos: action((state, payload) => {
    state.tipos = payload
  }),
  setSistemas: action((state, payload) => {
    state.sistemas = payload
  }),
  setDivisiones: action((state, payload) => {
    state.divisiones = payload
  }),
  setDocs: action((state, payload) => {
    state.docs = payload
  }),
  setGerencia: action((state, payload) => {
    state.gerencia = payload
  }),
  setFiltros: action((state, payload) => {
    state.filtros = payload
  }),
  setFavoritos: action((state, payload) => {
    state.favoritos = payload
  }),
  setDocsGerencia: action((state, payload) => {
    state.docsGerencia = payload
  }),
  setDocsBusqueda: action((state, payload) => {
    state.docsBusqueda = payload
  }),
  setDocsInformado: action((state, payload) => {
    state.docsInformado = payload
  }),
  setMacroprocesos: action((state, payload) => {
    state.macroprocesos = payload
  }),
  getMasters: thunk(async (actions, _, { getStoreState }) => {
    const { tipos, sistemas } = getStoreState()
    if (!tipos.length || !sistemas.length) {
      const requests = [
        api.fetchGetRequest('api/documentos/tipodocumento'),
        api.fetchGetRequest('api/documentos/sistemagestion'),
      ]
      const res = await Promise.all(requests)
      actions.setTipos(res[0].data)
      actions.setSistemas(res[1].data)
    }
  }),
  getDocs: thunk(async (actions, query) => {
    let url = 'api/documentos/obtener-documentos-directorio?'
    let tipo = ''
    if (query.gerencia) {
      url += `gerenciaId=${query.gerencia}`
      tipo = 'gerencia'
    }
    if (query.nombre && !query.gerencia) {
      url += `nombre=${query.nombre}`
      tipo = 'busqueda'
    }
    const { data } = await api.fetchGetRequest(url)
    actions.setFiltros({
      tipo: null,
      sistema: null,
      anho: null
    })
    const items = data.map(i => {
      const fecha = dayjs(i.actualizacion)
      return {
        ...i,
        tipo: `${i.codigoTipo} - ${i.tipo}`,
        fecha: fecha.format('DD/MM/YYYY'),
        semaforo: semaforo(fecha),
        anho: fecha.year() + ''
      }
    })
    if (tipo === 'gerencia') {
      actions.setDocsGerencia(items)
    } else {
      actions.setDocsBusqueda(items)
    }
  }),
  getFavoritos: thunk(async (actions, usuario) => {
    const { data } = await api.fetchGetRequest(`api/documentos/documento-favorito/por-usuario/${usuario}`)
    actions.setFavoritos(data.map(i => {
      const fecha = dayjs(i.actualizacion)
      return {
        ...i,
        tipo: `${i.codigoTipo} - ${i.tipo}`,
        fecha: fecha.format('DD/MM/YYYY'),
        semaforo: semaforo(fecha),
        anho: fecha.year() + ''
      }
    }))
  }),
  getDocsInformado: thunk(async (actions, usuario) => {
    const { data } = await api.fetchGetRequest(`api/documentos/obtener-documentos-informado/${usuario}`)
    actions.setDocsInformado(data.map(i => {
      const fecha = dayjs(i.actualizacion)
      return {
        ...i,
        tipo: `${i.codigoTipo} - ${i.tipo}`,
        fecha: fecha.format('DD/MM/YYYY'),
        semaforo: semaforo(fecha),
        anho: fecha.year() + ''
      }
    }))
  }),
}
