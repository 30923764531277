import React, { Component, useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import PersonIcon from "@material-ui/icons/Person";
import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PhonelinkSetupIcon from "@material-ui/icons/PhonelinkSetup";
import DeviceHubIcon from "@material-ui/icons/DeviceHub";
import TuneIcon from "@material-ui/icons/Tune";
import ImportContactsIcon from "@material-ui/icons/ImportContacts";
import AssessmentIcon from "@material-ui/icons/Assessment";
import RoomIcon from "@material-ui/icons/Room";
import NotificationsIcon from "@material-ui/icons/Notifications";
import AddAlertIcon from "@material-ui/icons/AddAlert";
import GroupIcon from "@material-ui/icons/Group";
import AppsIcon from "@material-ui/icons/Apps";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import ListAltIcon from "@material-ui/icons/ListAlt";
import Avatar from "@material-ui/core/Avatar";
import { green, pink } from "@material-ui/core/colors";

import logo from "../../image-repository/logo.png";
import "../components/Layout.css";
import OptionComponent from "../components/option-component";
import { isSignedIn, signOut } from "../../utils/auth";
import API from "../../utils/api";
import { permisoAgrupacion } from "../../utils/helpers";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    backgroundColor: "#202e3c",
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    background: "#2b3c4d",
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  containerTitle: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: 0,
    paddingright: 0,
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  greenAvatar: {
    color: "#fff",
    backgroundColor: green[500],
    borderStyle: "solid",
    borderWidth: "1 !important",
    borderColor: "white !important",
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
}));

export default function LayoutPublic(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [permisos, setPermisos] = useState([]);
  const [nombreCompletoUsuario, setNombreCompletoUsuario] = useState("");
  const [inicialesUsuario, setInicialesUsuario] = useState("");
  const [codigoUsuario, setCodigoUsuario] = useState("");
  const [imagenPerfil, setImagenPerfil] = useState("");
  const llaveAgrupacion = "Menu.Principal.Web";

  // useEffect(() => {
  //   async function obtenerListaAcceso() {
  //     let listaAcceso = await permisoAgrupacion(llaveAgrupacion);

  //     if (Array.isArray(listaAcceso)) {
  //       setPermisos(listaAcceso);
  //     }
  //   }

  //   async function obtenerDatosSesion() {
  //     let session = await isSignedIn();
  //     if (session.response) {
  //       setCodigoUsuario(session.codigoUsuario);
  //       setImagenPerfil(session.imagenPerfil);
  //       console.log("imagenPerfil",session.imagenPerfil);
  //       setNombreCompletoUsuario(session.nombreCompletoUsuario);

  //       if(session.nombreCompletoUsuario!==null){
  //         if (session.nombreCompletoUsuario.length > 0) {
  //           setInicialesUsuario(session.nombreCompletoUsuario.substring(0, 2));
  //         }
  //       }

  //     }
  //   }
  //   obtenerDatosSesion();
  //   obtenerListaAcceso();
  // }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const DibujarMenuItem = (item) => {
    let respuesta = <></>;

    switch (item.item.Llave) {
      case "Menu.Principal.Web.Usuarios":
        respuesta = (
          <OptionComponent
            name="Usuarios"
            icon={<PersonIcon style={{ color: "white" }} />}
            url="/usuarios"
            estadoBarra={open}
          />
        );
        break;

      case "Menu.Principal.Web.Notificaciones":
        respuesta = (
          <OptionComponent
            name="Notificaciones"
            icon={<AddAlertIcon style={{ color: "white" }} />}
            url="/notificaciones"
            estadoBarra={open}
          />
        );
        break;

      case "Menu.Principal.Web.Roles":
        respuesta = (
          <OptionComponent
            name="Roles"
            icon={<PersonAddIcon style={{ color: "white" }} />}
            url="/roles"
            estadoBarra={open}
          />
        );
        break;

      case "Menu.Principal.Web.Permisos":
        respuesta = (
          <OptionComponent
            name="Permisos"
            icon={<VpnKeyIcon style={{ color: "white" }} />}
            url="/permisos"
            estadoBarra={open}
          />
        );
        break;

      case "Menu.Principal.Web.Grupos":
        respuesta = (
          <OptionComponent
            name="Grupos"
            icon={<GroupIcon style={{ color: "white" }} />}
            url="/grupo"
            estadoBarra={open}
          />
        );
        break;

      case "Menu.Principal.Web.Agrupaciones":
        respuesta = (
          <OptionComponent
            name="Agrupaciones"
            icon={<GroupWorkIcon style={{ color: "white" }} />}
            url="/agrupaciones"
            estadoBarra={open}
          />
        );
        break;

      // case "Menu.Principal.Web.Administrar.Aviso":
      //   respuesta = (
      //     <OptionComponent
      //     name="Administrar avisos"
      //     icon={<PhonelinkSetupIcon style={{ color: "white" }} />}
      //     url="/entidad"
      //     estadoBarra={open}
      //     />
      //   );
      //   break;

      case "Menu.Principal.Web.Flujos":
        respuesta = (
          <OptionComponent
            name="Flujos"
            icon={<DeviceHubIcon style={{ color: "white" }} />}
            url="/flujo"
            estadoBarra={open}
          />
        );
        break;

      case "Menu.Principal.Web.formDesigner":
        respuesta = (
          <OptionComponent
            name="Formularios"
            icon={<ListAltIcon style={{ color: "white" }} />}
            url="/formdesigner"
            estadoBarra={open}
          />
        );
        break;

      case "Menu.Principal.Web.Etiquetas":
        respuesta = (
          <OptionComponent
            name="Etiquetas"
            icon={<LocalOfferIcon style={{ color: "white" }} />}
            url="/familiaetiqueta"
            estadoBarra={open}
          />
        );
        break;

      // case "Menu.Principal.Web.Procesos.Gestion":
      //   respuesta = (
      //     <OptionComponent
      //     name="Gestión de procesos"
      //     icon={<ImportContactsIcon style={{ color: "white" }} />}
      //     url="/gestionprocesos"
      //     estadoBarra={open}
      //     />
      //   );
      //   break;

      case "Menu.Principal.Web.Gestiones":
        respuesta = (
          <OptionComponent
            name="Gestiones"
            icon={<AppsIcon style={{ color: "white" }} />}
            url="/bandeja"
            estadoBarra={open}
          />
        );
        break;

      case "Menu.Principal.Web.Reportes":
        respuesta = (
          <OptionComponent
            name="Reportes"
            icon={<AssessmentIcon style={{ color: "white" }} />}
            url="/reportes"
            estadoBarra={open}
          />
        );
        break;

      case "Menu.Principal.Web.ContactTracing":
        respuesta = (
          <OptionComponent
            name="Contact tracing"
            icon={<RoomIcon style={{ color: "white" }} />}
            url="/contacttracing"
            estadoBarra={open}
          />
        );
        break;
    }
    return respuesta;
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          {/* <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton> */}
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            <a href="/">
              <img src={logo} className="ImgLogo" alt="logo" />
            </a>
          </Typography>

          {/* {imagenPerfil === null || imagenPerfil===undefined || imagenPerfil.endsWith('perfil/') ? (
            <Avatar alt="" className={classes.greenAvatar} style={{cursor:'pointer'}}
            onClick={
              ()=>{
                window.location.href='/perfil';
              }
            }
            >
              {inicialesUsuario}
            </Avatar>
                ) : (
                  <Avatar
                    alt={nombreCompletoUsuario}
                    src={imagenPerfil}
                    className={[classes.greenAvatar]}
                    style={{cursor:'pointer'}}
                    onClick={
                      ()=>{
                        window.location.href='/perfil';
                      }}
                  />
                )} */}
        </Toolbar>
      </AppBar>
      {/* <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon style={{ color: "#fff" }} />
          </IconButton>
        </div>
        <Divider />
        <List>
          <div>
            {permisos.map((permiso, i) => (
              <DibujarMenuItem item={permiso} />
            ))}

            <Divider />
            <OptionComponent
              name="Cerrar sesión"
              icon={<ExitToAppIcon style={{ color: "white" }} />}
              url="/logout"
              estadoBarra={open}
            />
          </div>
        </List>
      </Drawer> */}
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        {typeof props.bodyHeader !== "undefined" && !props.bodyHeader ? (
          <></>
        ) : (
          <div className="LayoutHeader">
            <h2 className="Titulo">
              <span>{typeof !props.titulo ? props.titulo : ""}</span>
            </h2>
            {props.header}
          </div>
        )}

        <Container
          maxWidth="lg"
          className={classes.container}
          style={{ ...props.bodyContainerStyle }}
        >
          <Grid container spacing={3}>
            {props.children}
          </Grid>
        </Container>
      </main>
    </div>
  );
}
