import React, { useState, useEffect } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  LinearProgress,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  makeStyles,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import {
  Delete,
  Description,
  Message,
  GetApp,
  Print,
  Visibility,
} from "@material-ui/icons";
import { toast } from "react-toastify";
import { useStoreState } from "easy-peasy";

import SolicitudComentarios from "./SolicitudComentarios";
import { getPDFSolicitudBase64 } from "../../utils/media";
import SolicitudVer from "./SolicitudVer";
import api from "../../../utils/api";
import { getUsuarioInserta } from "../../utils/utilidades";
import EliminarObsoleto from "./EliminarObsoleto";
import PDFView from "../utils/PDFView";

const heads = [
  "No.",
  "Código de documento",
  "Edición del documento",
  "Titúlo",
  "Fecha de creación",
  "Fecha Obsoleto",
  "Autor",
  "Opciones",
];

const useStyles = makeStyles((theme) => ({
  vigente: {
    color: "#90be6d",
  },
  porVencer: {
    color: "#f3722c",
  },
  vencido: {
    color: "#f94144",
  },
}));

const DocumentosObsoletosUsuario = ({ loading, refresh }) => {
  const { docsObsoletos } = useStoreState((state) => ({
    docsObsoletos: state.docsObsoletos,
  }));

  const [docs, setDocs] = useState([]);
  const [selected, setSelected] = useState(null);
  const [dialogComentarios, setDialogComentarios] = useState(false);
  const [dialogDoc, setDialogDoc] = useState(false);
  const [dialogPrint, setDialogPrint] = useState(false);
  const [dialogDetalles, setDialogDetalles] = useState(false);
  const [dialogDelete, setDialogDelete] = useState(false)
  const [PDF, setPDF] = useState("");
  const [print, setPrint] = useState("");

  const eliminarDocumento = async (idSolicitd) => {
    if (window.confirm("¿Estas seguro de eliminar el documento obsoleto?")) {
      try {
        await api.post(`api/documentos/solicitud/eliminar/${idSolicitd}`, {
          UsuarioModifico: getUsuarioInserta(),
        });

        refresh();
      } catch (error) {
        toast.error("Ha ocurrido un error");
      }
    }
  };

  const verDoc = async (id) => {
    try {
      setDialogDoc(true);
      const item = docs.find((i) => i.IdSolicitud === id);
      await getPDF(item.IdSolicitud);
    } catch (error) {
      toast.error("No se pudo cargar el documento");
    }
  };

  const showComentarios = (id) => {
    const item = docs.find((i) => i.IdSolicitud === id);
    setSelected({ ...item });
    setDialogComentarios(true);
  };
  const showEliminar = (id) => {
    const item = docs.find((i) => i.IdSolicitud === id)
    setSelected({ ...item })
    setDialogDelete(true)
  };

  const getPDF = async (idSolicitud) => {
    try {
      setPDF(null)
      let base64str = await getPDFSolicitudBase64(idSolicitud)
      if (base64str) {
        base64str = base64str.replace(
          "data:application/octet-stream;base64,",
          "data:application/pdf;base64,"
        )
        setPDF(base64str)
      }
    } catch (error) {
      toast.error('Ocurrio un error al obtener el documento 😅')
    }
  };

  const downloadDoc = async (id) => {
    const item = docs.find((i) => i.IdSolicitud === id)
    let base64str = await getPDFSolicitudBase64(item.IdSolicitud)
    base64str = base64str.replace("data:application/octet-stream;base64,", "")
    const linkSource = `data:application/pdf;base64,${base64str}`
    const downloadLink = document.createElement("a")
    const fileName = `${item.titulo}.pdf`
    downloadLink.href = linkSource
    downloadLink.download = fileName
    downloadLink.click()
  };

  const printDoc = async (id) => {
    const item = docs.find((i) => i.IdSolicitud === id)
    let base64str = await getPDFSolicitudBase64(item.IdSolicitud)
    base64str = base64str.replace(
      "data:application/octet-stream;base64,",
      "data:application/pdf;base64,"
    )
    setPrint(base64str)
    setDialogPrint(true)
  };

  const showDetalles = (idx) => {
    const doc = { ...docs[idx] };
    doc.DescripcionDocumento = doc.descripcion;
    doc.NombreDocumento = doc.titulo;
    doc.Estado = doc.EstadoVigencia;
    doc.fecha = doc.actualizacion;
    doc.Solicitante = doc.Autor;
    setSelected(doc);
    setDialogDetalles(true);
  };

  const hidePDF = () => {
    setPDF(null);
    setDialogDoc(false);
  };

  useEffect(() => {
    setDocs(docsObsoletos);
  }, [docsObsoletos]);

  return (
    <div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {heads.map((i) => (
                <TableCell key={i}>{i}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {docs.map((i, idx) => (
              <TableRow key={idx}>
                <TableCell>{idx + 1}</TableCell>
                <TableCell>{i.Correlativo}</TableCell>
                <TableCell>{i.version}</TableCell>
                <TableCell>
                  <strong>{i.titulo}</strong>
                </TableCell>
                <TableCell>{i.actualizacion}</TableCell>
                <TableCell>{i.FechaObsoleto}</TableCell>
                <TableCell>{i.Autor}</TableCell>
                <TableCell>
                  <Tooltip title="Ver documento">
                    <IconButton onClick={() => verDoc(i.IdSolicitud)}>
                      <Description />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Ver observaciones de documento">
                    <IconButton onClick={() => showComentarios(i.IdSolicitud)}>
                      <Message />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Descargar documento">
                    <IconButton onClick={() => downloadDoc(i.IdSolicitud)}>
                      <GetApp />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Imprimir documento">
                    <IconButton onClick={() => printDoc(i.IdSolicitud)}>
                      <Print />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Ver detalles">
                    <IconButton onClick={() => showDetalles(idx)}>
                      <Visibility />
                    </IconButton>
                  </Tooltip>
                  {i.Eliminable && (
                    <Tooltip title="Eliminar documento obsoleto">
                      <IconButton
                        onClick={() => showEliminar(i.IdSolicitud)}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  )}
                  <Tooltip title="Eliminar documento obsoleto">
                    <IconButton
                      onClick={() => showEliminar(i.IdSolicitud)}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {loading && <LinearProgress />}
        {!loading && !docs.length && (
          <Alert severity="info" variant="filled">
            No tienes documentos registrados
          </Alert>
        )}
      </TableContainer>

      <Dialog open={dialogDoc} onClose={hidePDF} fullScreen>
        <PDFView PDFbase64={PDF} onClose={hidePDF} />
      </Dialog>
      <Dialog
        open={dialogPrint}
        onClose={() => setDialogPrint(false)}
        maxWidth="xl"
        fullWidth
      >
        <DialogContent style={{ height: "80vh", background: "#FAFAFA" }}>
          <iframe src={print} width="100%" height="100%" />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogPrint(false)}>Cerrar</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={dialogDetalles}
        onClose={() => setDialogDetalles(false)}
        maxWidth="sm"
        fullWidth
      >
        <SolicitudVer
          datos={selected}
          onClose={() => setDialogDetalles(false)}
        />
      </Dialog>
      <Dialog
        fullWidth
        open={dialogComentarios}
        onClose={() => setDialogComentarios(false)}
        maxWidth="md"
      >
        <SolicitudComentarios
          datos={selected}
          onClose={() => setDialogComentarios(false)}
        />
      </Dialog>
      <Dialog open={dialogDelete} onClose={() => setDialogDelete(false)}>
        <EliminarObsoleto
          doc={selected}
          onClose={() => setDialogDelete(false)}
          onPrint={() => printDoc(selected.IdSolicitud)}
          onDownload={() => downloadDoc(selected.IdSolicitud)}
          onDelete={() => eliminarDocumento(selected.IdSolicitud)}
        />
      </Dialog>
    </div>
  );
};

export default DocumentosObsoletosUsuario;
