import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Checkbox,
  FormControlLabel,
  Divider,
  Paper,
  Tooltip,
  Modal,
  Fade,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
} from "@material-ui/core";

import BackdropModal from "@material-ui/core/Backdrop";

import clsx from "clsx";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import {
  ChromeReaderMode,
  Edit,
  People,
  AttachFile,
  Cached,
  Delete,
  Label,
  ViewList,
  Add,
} from "@material-ui/icons";

import { green } from "@material-ui/core/colors";
import { NavLink } from "react-router-dom";

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import DataGrid, {
  Column,
  Pager,
  Paging,
  Sorting,
  ColumnFixing,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Grouping,
  GroupPanel,
  Export,
  Selection,
  Editing,
  Scrolling,
} from "devextreme-react/data-grid";

import API from "../../utils/api";
import { isSignedIn, signOut } from "../../utils/auth";
import { validaPermisoPaginaActual } from "../../utils/helpers";
import { ToastContainer, toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import "devextreme/dist/css/dx.material.teal.light.compact.css";

const width_proportion = "100%";
const titleColor = "#2B3C4D";

const useStyles = makeStyles((theme) => ({
  table: {
    width: "100%",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 210,
  },
  textFieldDireccion: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 230,
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  greenAvatar: {
    fontSize: "2.5em",
    color: "#fff",
    // borderStyle:'solid',
    // borderWidth:'1 !important',
    // borderColor:'#3f51b5 !important',
    backgroundColor: green[500],
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  paperCard: {
    padding: 20,
    width: "100%",
  },
  formControlSelects: {
    // margin: theme.spacing(1),
    // minWidth: 230,

    marginBottom: 10,
    marginTop: 10,
    width: "100%",
    marginLeft: 30,
  },
  formControlSelect: {
    // margin: theme.spacing(1),
    width: width_proportion,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  rootTabla: {
    width: "100%",
    marginTop: theme.spacing.unit * 1,
    overflowX: "auto",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paperModal: {
    position: "absolute",
    width: "70%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const ReporteVacunacion = () => {
  const classes = useStyles();
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors: fieldsErrors },
    register,
  } = useForm();

  let applyFilterTypes = [
    {
      key: "auto",
      name: "Immediately",
    },
    {
      key: "onClick",
      name: "On Button Click",
    },
  ];
  const [fechaFin, setFechaFin] = useState(new Date());
  const [fechaInicio, setFechaInicio] = useState(
    new Date(
      new Date().getFullYear(),
      new Date().getMonth() - 6,
      new Date().getDate()
    )
  );
  const [userName, setUserName] = useState("");
  const [open, setOpen] = useState(false);
  const [cargandoMaestros, setCargandoMaestros] = useState(false);
  const [puestoLaboral, setPuestoLaboral] = useState([]);
  const [maestroNivelPuestoLaboral, setMaestroNivelPuestoLaboral] = useState(
    []
  );
  const [codigoPuestoLaboral, setCodigoPuestoLaboral] = useState(0);
  const [codigoNivelPuestoLaboral, setCodigoNivelPuestoLaboral] = useState(0);
  const [puestoLaboralNombre, setPuestoLaboralNombre] = useState("");
  const [numeroVersion, setNumeroVersion] = useState("");
  const [fechaCreacion, setFechaCreacion] = useState(new Date());

  const [
    valueAutoCompleteNivelPuestoLaboral,
    setValueAutoCompleteNivelPuestoLaboral,
  ] = useState(null);

  const [positionDisableSorting, setPositionDisableSorting] = useState(false);
  const [showFilterRow, setShowFilterRow] = useState(true);
  const [showHeaderFilter, setShowHeaderFilter] = useState(true);
  const [currentFilter, setCurrentFilter] = useState(applyFilterTypes[0].key);
  const [autoExpandAll, setAutoExpandAll] = useState(true);

  const [buscando, setBackDrop] = useState(false);

  const handleCerrarBackDrop = () => {
    setBackDrop(false);
  };
  const handleAbrirBackDrop = () => {
    setBackDrop(true);
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  useEffect(() => {
    const cargar = async () => {
      // CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
      const llaveAgrupacion = "Menu.Principal.Web";
      const llavePagina = "Menu.Principal.Web.FichaClinica";
      await validaPermisoPaginaActual(llaveAgrupacion, llavePagina);
      // FIN DE CONSULTA DE PERMISOS

      handleAbrirBackDrop();

      await cargarReporte();

      handleCerrarBackDrop();
    };

    cargar();
  }, [cargandoMaestros]);

  const cargarReporte = async () => {
    const data = {
      FechaInicial: fechaInicio,
      FechaFinal: fechaFin,
    };

    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      // let uri = "api/Empleado/Vacuna/Reporte?fechaInicial="+fechaInicio.toJSON()+"&fechafinal"+fechaFin.toJSON();

      // const request = await API.fetchGetRequest(uri);
      let request;

      request = await API.post("api/Empleado/Vacuna/Reporte/", data);

      if (request.status !== 200) {
        toast.error(
          "Hubo un error al realizar la carga del reporte, por favor revisa los datos de tu formulario o consulta con un administrador"
        );
      } else {
        setPuestoLaboral(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const guardarDato = async () => {
    const data = {
      CodigoPuestoLaboral: codigoPuestoLaboral,
      CodigoNivelPuestoLaboral: codigoNivelPuestoLaboral,
      PuestoLaboral: puestoLaboralNombre,
      NumeroVersion: numeroVersion,
      FechaCreacion: fechaCreacion,
      Activo: true,
      Eliminado: false,
      UsuarioInserto: userName,
      FechaInserto: "",
      UsuarioModifico: "",
      FechaModifico: "",
    };

    console.log(data);
    // dataFicha.usuarioInserto = "prueba";
    try {
      handleAbrirBackDrop();
      let vIsSignedIn = await isSignedIn();
      if (vIsSignedIn.response) {
        let request;

        request = await API.post("api/PuestoLaboral/", data);
        if (request.status !== 200) {
          toast.error(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        } else {
          toast.success("Se guardó exitosamente");

          await cargarReporte();

          setPuestoLaboralNombre("");
          setNumeroVersion("");
          setFechaCreacion(new Date());
          setCodigoPuestoLaboral(0);
          setCodigoNivelPuestoLaboral(0);

          setValueAutoCompleteNivelPuestoLaboral(null);
        }
      }
    } catch (e) {
      console.log("EXCEPTION: " + e);
      toast.error(
        "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
      );
    } finally {
      handleCerrarBackDrop();
      handleCloseModal();
    }
  };

  const cuandoHayError = async (data) => {
    toast.info(
      "Existen algunos campos con error, por favor valida el formulario",
      {
        position: "top-right",
        autoClose: 3000, // Esta en milisegundos 3 segundos
      }
    );
  };

  const handleDateChangeFechaInicial = (date) => {
    setFechaInicio(date);
  };
  const handleDateChangeFechaFinal = (date) => {
    setFechaFin(date);
  };

  const onExporting = async (e) => {
    // const workbook = new Workbook();
    // const worksheet = workbook.addWorksheet("Main sheet");
    // exportDataGrid({
    //   component: e.component,
    //   worksheet: worksheet,
    // }).then(function () {
    //   workbook.xlsx.writeBuffer().then(function (buffer) {
    //     saveAs(
    //       new Blob([buffer], { type: "application/octet-stream" }),
    //       "DataGrid.xlsx"
    //     );
    //   });
    // });
    // e.cancel = true;
  };

  const [dialogoAbierto, abrirDialogo] = useState(false);
  const [dialogoAbiertoEditar, abrirDialogoEditar] = useState(false);
  const handleAbrirDialogoEliminar = (id) => {
    abrirDialogo(true);

    setCodigoPuestoLaboral(id);
  };

  const handleCerrarDialogo = () => {
    abrirDialogo(false);
  };

  const handleAbrirDialogoEditar = (id) => {
    abrirDialogoEditar(true);

    setCodigoPuestoLaboral(id);
  };

  const handleCerrarDialogoEditar = () => {
    abrirDialogoEditar(false);
  };

  const quitarProyecto = async (pId) => {
    handleCerrarDialogo();
    handleAbrirBackDrop();

    const proyectosExcluidos = puestoLaboral.filter(
      (elemento) => elemento.CodigoPuestoLaboral !== pId
    );

    console.log("Quitar" + proyectosExcluidos);

    try {
      let uri = "api/PuestoLaboral/Quitar/" + pId;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        toast.error(
          "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
        );
      } else {
        toast.success("Se quitó el registro con éxito", {
          position: "bottom-right",
          autoClose: 3000, // Esta en milisegundos 3 segundos
        });

        await cargarReporte();
        setPuestoLaboral("");
        setNumeroVersion("");
        setFechaCreacion(new Date());
        setCodigoPuestoLaboral(0);
        setCodigoNivelPuestoLaboral(0);

        setValueAutoCompleteNivelPuestoLaboral(null);
      }
    } catch (error) {
      console.log("Error en  eliminación de proyectos" + error);
    }

    handleCerrarBackDrop();

    //alert("Quitar recurso del proyecto" + codigoRecurso);
  };

  const editarProyecto = async (pId) => {
    handleCerrarDialogoEditar();
    handleAbrirBackDrop();

    const proyectosExcluidos = puestoLaboral.filter(
      (elemento) => elemento.CodigoPuestoLaboral === pId
    );

    console.log("Editar el proyecto" + proyectosExcluidos);

    try {
      handleOpenModal();
      setCodigoPuestoLaboral(pId);
      setPuestoLaboralNombre(proyectosExcluidos[0].PuestoLaboral);
      setNumeroVersion(proyectosExcluidos[0].NumeroVersion);
      setFechaCreacion(proyectosExcluidos[0].FechaCreacion);
      setCodigoNivelPuestoLaboral(
        proyectosExcluidos[0].CodigoNivelPuestoLaboral
      );

      const itemNivelPuestoLaboral = maestroNivelPuestoLaboral.filter(
        (elemento) =>
          elemento.CodigoNivelPuestoLaboral ===
          proyectosExcluidos[0].CodigoNivelPuestoLaboral
      );
      setValueAutoCompleteNivelPuestoLaboral(itemNivelPuestoLaboral[0]);
    } catch (error) {
      console.log("Error en  edición de proyectos" + error);
    }

    handleCerrarBackDrop();

    //alert("Quitar recurso del proyecto" + codigoRecurso);
  };

  const handleOnChangeTipoParentesco = async (event, newValue) => {
    handleAbrirBackDrop();
    if (newValue !== null) {
      setCodigoNivelPuestoLaboral(newValue.CodigoNivelPuestoLaboral);
      setValueAutoCompleteNivelPuestoLaboral(newValue);
    }
    handleCerrarBackDrop();
  };

  return (
    <React.Fragment>
      <Backdrop className={classes.backdrop} open={buscando}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer />

      <AppBar
        position="sticky"
        className={classes.appBar}
        style={{ backgroundColor: titleColor }}
      >
        <Toolbar>
          <Typography
            variant="h6"
            noWrap
            style={{ display: "flex", flexGrow: 1 }}
          >
            {`Vacunación`}
          </Typography>
        </Toolbar>
      </AppBar>

      <Paper className={classes.rootTabla}>
        <div>
          <Grid container spacing={3}>
            <form
              autoComplete="off"
              onSubmit={handleSubmit(cargarReporte, cuandoHayError)}
            >
              <Grid item xs={6} sm={6} md={12} lg={12}>
                <FormControl className={classes.formControlSelects}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify="flex-start">
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        label="Fecha Inicial"
                        value={fechaInicio}
                        onChange={handleDateChangeFechaInicial}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        required
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </FormControl>
                <FormControl className={classes.formControlSelects}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify="flex-start">
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        label="Fecha Final"
                        value={fechaFin}
                        onChange={handleDateChangeFechaFinal}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        required
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={6} md={12} lg={12}>
                <Button
                  width="100%"
                  variant="contained"
                  style={{
                    backgroundColor: "#69bd4b",
                    marginBottom: 10,
                    marginTop: 10,
                    color: "white",
                    width: "93%",
                    marginLeft: 30,
                  }}
                  type="submit"
                >
                  &nbsp;Generar Reporte
                </Button>
              </Grid>
            </form>
          </Grid>
        </div>
        <DataGrid
          dataSource={puestoLaboral}
          //   defaultColumns={this.props.columns}
          showBorders={true}
          rowAlternationEnabled={true}
          //ref={(ref) => (dataGrid = ref)}
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
          onExporting={onExporting}
          className={classes.table}
        >
          <FilterRow visible={showFilterRow} applyFilter={currentFilter} />
          <HeaderFilter visible={showHeaderFilter} />
          <GroupPanel visible={false} />
          <Grouping autoExpandAll={autoExpandAll} />
          <SearchPanel visible={false} width={240} placeholder="Search..." />
          {/* <Selection mode="multiple" /> */}
          <Editing
            mode="row"
            useIcons={true}
            allowUpdating={false}
            allowDeleting={false}
          />
          {/* <Column
            caption="Opciones"
            allowFiltering={false}
            allowSorting={false}
            cellRender={cellOpciones}
          /> */}
          <Column
            dataField="CodigoEmpleadoVacuna"
            caption="Código DB"
            dataType="number"
            visible={false}
          />
          <Column
            dataField="CodigoTipoVacuna"
            caption="Código Tipo Vacuna"
            dataType="number"
            visible={false}
          />
          <Column
            dataField="CodigoDosis"
            caption="Código Dosis"
            dataType="number"
            visible={false}
          />
          <Column dataField="CodigoAnterior" caption="Código Empleado" />
          <Column dataField="DPI" caption="DPI" />

          <Column dataField="NombreCompleto" caption="Nombre Completo" />
          <Column dataField="TipoVacuna" caption="Tipo Vacuna" />
          <Column dataField="Dosis" caption="Dosis" />
          <Column dataField="PuestoVacunacion" caption="Institución/Puesto" />
          <Column
            dataField="FechaVacunacion"
            caption="Fecha Vacunación"
            dataType="date"
            format="dd/MM/yyyy"
          />
          <Column dataField="NumeroLote" caption="Lote" />

          <ColumnFixing enabled={true} />
          <Sorting mode="multiple" />
          <Paging defaultPageSize={20} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[50, 100, 250]}
            showInfo={true}
          />
          <Export enabled={true} allowExportSelectedData={true} />
          <Scrolling columnRenderingMode="virtual" />
        </DataGrid>
      </Paper>
    </React.Fragment>
  );
};

export default ReporteVacunacion;
