import React, { useState, useEffect } from 'react'
import {
  Button,
  DialogContent,
  DialogTitle,
  DialogActions,
  TextField,
  Dialog
} from '@material-ui/core'
import { DropzoneArea } from 'material-ui-dropzone'
import { Autocomplete } from '@material-ui/lab'
import { toast } from 'react-toastify'
import Api from '../../../utils/api'
import LoadingButton from '../utils/LoadingButton'
import { getCodigoUsuario } from '../../utils/utilidades'
import { actualizacionModel } from '../../utils/modelos'
import api from '../../../utils/api'
import { convertBase64 } from '../../utils/media'
import PDFView from '../utils/PDFView'
import SelectUsuario from '../usuarios/SelectUsuario'
import SelectCentroDocumental from './SelectCentroDocumental'

const ActualizacionCambios = ({ data, onClose, onRefresh }) => {
  const [usuarios, setUsuarios] = useState([])
  const [titulo, setTitulo] = useState('')
  const [descripcion, setDescripcion] = useState('')
  const [encargado, setEncargado] = useState(null)
  const [autorizador, setAutorizador] = useState(null)
  const [archivo, setArchivo] = useState(null)
  const [loading, setLoading] = useState(false)
  const [preview, setPreview] = useState(false)
  const [informados, setInformados] = useState([])
  const [centros, setCentros] = useState([])
  const [centrosDoc, setCentrosDoc] = useState([])
  const [informadosDoc, setInformadosDoc] = useState([])
  const [PDF, setPDF] = useState(null)
  const showPreview = async () => {
    try {
      setPDF(null)
      let base64 = await convertBase64(archivo)
      setPDF(base64)
      setPreview(true)
    } catch (error) {
      toast.error('Archivo incorrecto')
    }
  }
  const getCentrosDocumentales = async (IdSolicitud) => {
    try {
      const { data } = await Api.fetchGetRequest(`api/documentos/centros-documentales-por-documento/${IdSolicitud}`)
      setCentrosDoc(data)
    } catch (error) {
      toast.error('Ha ocurrido un error')
    }
  }
  const getInformados = async (IdSolicitud) => {
    try {
      const { data } = await Api.fetchGetRequest(`api/documentos/informados-por-documento/${IdSolicitud}`)
      setInformadosDoc(data)
    } catch (error) {
      toast.error('Ha ocurrido un error')
    }
  }
  const getUsuarios = async () => {
    const { data } = await Api.fetchGetRequest('api/Usuario/buscar/x-x')
    setUsuarios(data.usuarios)
  }
  const saveChanges = async () => {
    if (!archivo) {
      toast.error('El archivo es requerido')
      return
    }
    if (!encargado) {
      toast.error('Selecciona el revisor')
      return
    }
    if (!autorizador) {
      toast.error('Selecciona el autorizador')
      return
    }
    if (!centros.length) {
      toast.error('Selecciona el centro documental')
      return
    }
    if (!informados.length) {
      toast.error('Selecciona a los informados')
      return
    }
    try {
      setLoading(true)
      const documento = {
        Titulo: titulo,
        Descripcion: descripcion,
        CodigoRevisor: encargado && encargado.CodigoUsuario || null,
        CodigoAutorizador: autorizador && autorizador.CodigoUsuario || null,
        ListaCentrosDocumentales: centros.map(i => i.IdCentroDocumental).join(','),
        ListaUsuariosAccesoADocumentos: informados.map(i => i.CodigoUsuario).join(',')
      }
      const datos = await actualizacionModel.validate(documento)
      datos.IdSolicitud = data.IdSolicitud
      datos.CodigoAutor = getCodigoUsuario()
      datos.Archivo = archivo
      const form = new FormData()
      for (const k in datos) {
        form.append(k, datos[k])
      }
      await api.postFile(
        "api/documentos/solicitud/crear-solicitud-y-subir-documento-actualizacion-con-cambios",
        form
      );
      toast.success("La solicitud ha sido enviada");
      onRefresh();
    } catch (error) {
      console.log(error)
      if (error.name && error.name === 'ValidationError') {
        toast.error(error.errors[0])
      } else {
        toast.error('Ha ocurrido un error')
      }
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getUsuarios()
    setTitulo(data.titulo)
    setDescripcion(data.descripcion)
    getCentrosDocumentales(data.IdSolicitud)
    getInformados(data.IdSolicitud)
  }, [])
  return <>
    <DialogTitle>Actualización con cambios</DialogTitle>
    <DialogContent>
      <TextField
        fullWidth
        value={titulo}
        onChange={ev => setTitulo(ev.target.value)}
        label="Título del documento"
      />
      <TextField
        fullWidth
        value={descripcion}
        onChange={ev => setDescripcion(ev.target.value)}
        multiline
        rows={4}
        label="Descripción"
      />
      <Autocomplete
        options={usuarios}
        getOptionLabel={(option) => option.NombreCompletoUsuario ? option.NombreCompletoUsuario : ''}
        defaultValue=""
        value={encargado}
        onChange={(ev, value) => {
          setEncargado(value)
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="Encargado de revisión"
          />
        )}
        style={{
          marginBottom: 10
        }}
      />
      <Autocomplete
        options={usuarios}
        getOptionLabel={(option) => option.NombreCompletoUsuario ? option.NombreCompletoUsuario : ''}
        defaultValue=""
        value={autorizador}
        onChange={(ev, value) => {
          setAutorizador(value)
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="Encargado de aprobación"
          />
        )}
      />
      <SelectUsuario
        selectMany={true}
        label="Acceso a los documentos"
        initValue={informadosDoc}
        onSelect={(value) => setInformados(value)}
      />
      <SelectCentroDocumental
        value={centros}
        onChange={(v) => setCentros(v)}
        initial={centrosDoc}
      />
      <br />
      <DropzoneArea
        filesLimit={1}
        maxFileSize={100000000}
        dropzoneText="Selecciona el archivo"
        acceptedFiles={["application/pdf"]}
        onChange={files => setArchivo(files[0])}
      />
    </DialogContent>
    <DialogActions>
      <Button disabled={loading} onClick={onClose}>Cancelar</Button>
      <Button
        disabled={!archivo}
        onClick={showPreview}
        color="secondary"
      >
        Previsualizar
      </Button>
      <LoadingButton
        loading={loading}
        color="primary"
        onClick={saveChanges}
      >Guardar</LoadingButton>
    </DialogActions>
    <Dialog fullScreen open={preview} onClose={() => setPreview(false)}>
      <PDFView
        PDFbase64={PDF}
        onClose={() => setPreview(false)}
      />
    </Dialog>
  </>
}

export default ActualizacionCambios
