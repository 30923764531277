import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import FormularioEvento from "../components/formulario-evento";
import Layout from "../../layout/containers/Layout";
import { validaPermisoPaginaActual } from "../../utils/helpers";
import { isSignedIn } from "../../utils/auth";
import { format, isValid, parse } from "date-fns";
import API from "../../utils/api";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
});

class EditarEvento extends Component {
  state = {
    Id: null,
    Titulo: null,
    Descripcion: null,
    Fecha: null,
    TodoElDia: false,
    HoraInicio: null,
    HoraFin: null,
    Color: "#202e3c",
    loading: false,
  };

  async componentDidMount() {
    //CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
    const llaveAgrupacion = "Menu.Principal.Web";
    const llavePagina = "Menu.Principal.Web.Notificaciones";
    await validaPermisoPaginaActual(llaveAgrupacion, llavePagina);
    //FIN DE CONSULTA DE PERMISOS

    const {
      match: { params },
    } = this.props;
    console.log("props ", this.props);
    API.fetchGetRequest(`/api/calendarioEvento/${params.id}`).then((res) => {
      const { data } = res;
      // data.Fecha = parse(data.Fecha, 'yyyy-MM-ddTHH:mm:ss', new Date());
      data.Fecha = new Date(data.Fecha);
      data.HoraInicio = parse(data.HoraInicio, "HH:mm:ss", new Date());
      data.HoraFin = parse(data.HoraFin, "HH:mm:ss", new Date());
      this.setState({ ...data });
    });
  }

  onChangeValue = (name, type) => (event) => {
    let value = event;
    if (type == 1) value = event.target.value; //Inputs de texto
    // if (type == 2) value = isValid(value) ? format(value, "yyyy-MM-dd") : value; // Datepicker
    // if (type == 3) value = isValid(value) ? format(event, "HH:MM:ss") : value; // Timepicker
    if (type == 4) value = event.target.checked; // Checkbox
    this.setState({ [name]: value });
  };

  guardarEvento = async () => {
    const data = { ...this.state };
    if (!data.Titulo) {
      toast.error("El campo titulo es requerido");
    } else if (!data.Descripcion) {
      toast.error("El campo descripción es requerido");
    } else if (!data.Fecha) {
      toast.error("El campo fecha es requerido");
    } else if (!isValid(data.Fecha)) {
      toast.error("Fecha invalida");
    } else if (!data.TodoElDia && !data.HoraInicio) {
      toast.error("El campo hora de inicio es requerido");
    } else if (!data.TodoElDia && !isValid(data.HoraInicio)) {
      toast.error("Hora de inicio invalida");
    } else if (!data.TodoElDia && !data.HoraFin) {
      toast.error("El campo hora de fin es requerido");
    } else if (!data.TodoElDia && !isValid(data.HoraFin)) {
      toast.error("Hora de fin invalida");
    } else if (!data.Color) {
      toast.error("El campo color es requerido");
    } else {
      data.Fecha = format(data.Fecha, "yyyy-MM-dd");
      if (!data.TodoElDia) {
        data.HoraInicio = format(data.HoraInicio, "HH:mm:ss");
        data.HoraFin = format(data.HoraFin, "HH:mm:ss");
      } else {
        data.HoraInicio = null;
        data.HoraFin = null;
      }

      const session = await isSignedIn();
      data.usuario = session["userName"];
      console.log("data ", data);

      this.setState({ loading: true });
      API.post(`/api/calendarioEvento/${data.Id}`, data)
        .then((res) => {
          toast.success("Se ha editado el evento");
          setTimeout(() => {
            this.props.history.push("/eventos-calendario");
          }, 3000);
        })
        .catch((error) => {
          toast.error("Error al editar el evento, intentelo de nuevo.");
        })
        .finally(() => this.setState({ loading: false }));
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <Layout titulo="Editar evento">
        <Typography variant="h5" gutterBottom>
          Ingrese los datos del evento
        </Typography>
        {this.state.Id && (
          <FormularioEvento
            values={this.state}
            onChangeValue={this.onChangeValue}
            guardar={this.guardarEvento}
            loading={this.state.loading}
          />
        )}
        <ToastContainer />
      </Layout>
    );
  }
}

const EditarEventoContainer = withStyles(styles)(EditarEvento);
export default withRouter(EditarEventoContainer);
