import React, { useState, useEffect } from 'react'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { useStoreActions, useStoreState } from 'easy-peasy'

const SelectUsuario = ({ selectMany = false, onSelect, label, initValue=null, disabled=false }) => {
  const { usuarios } = useStoreState(state => ({
    usuarios: state.usuarios
  }))
  const { getUsuarios } = useStoreActions(actions => ({
    getUsuarios: actions.getUsuarios
  }))
  const [items, setItems] = useState([])
  const [one, setOne] = useState('')
  const [many, setMany] = useState([])
  useEffect(() => {
    if (usuarios.length) {
      setItems(usuarios)
    } else {
      getUsuarios()
    }
  }, [usuarios])
  useEffect(() => {
    if (items.length && initValue) {
      setOne(initValue)
      onSelect(initValue)
    }
    if (items.length && Array.isArray(initValue) && selectMany) {
      const ids = initValue.map(i => i.CodigoUsuario)
      const selected = items.filter(i => ids.includes(i.CodigoUsuario))
      setMany(selected)
      onSelect(selected)
    }
  }, [items, initValue])
  return <>
    {
      selectMany ? <Autocomplete
        multiple
        options={items}
        disabled={disabled}
        getOptionLabel={(option) => option.NombreCompletoUsuario ? option.NombreCompletoUsuario : ''}
        value={many}
        onChange={(ev, selected) => {
          setMany([...selected])
          onSelect([...selected])
        }}
        defaultValue=""
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label={label}
          />
        )}
      /> :
      <Autocomplete
        options={items}
        getOptionLabel={(option) => option.NombreCompletoUsuario ? option.NombreCompletoUsuario : ''}
        value={one}
        disabled={disabled}
        onChange={(ev, value) => {
          setOne(value)
          onSelect(value)
        }}
        defaultValue=""
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label={label}
          />
        )}
      />
    }
  </>
}

export default SelectUsuario
