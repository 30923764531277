import React from 'react'
import Layout from "../../../layout/containers/Layout";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { withRouter } from "react-router-dom";
//import CATList from "../components/cat-list"
import {Box, Container, IconButton, Paper, TextField,  } from '@material-ui/core';
import Api from "../../../utils/api"
import {format, parseISO} from "date-fns"
import SearchIcon from '@material-ui/icons/Search'
//import { Pagination } from '@material-ui/lab';
import DatePicker from '../../components/inputs/datepicker';
import DevGrid from '../../components/containers/devgrid';
import ModalForm from '../../components/inputs/modal-form';
import { Autocomplete } from '@material-ui/lab';
import Modal from '../../components/containers/modal';
import GraphCard from './graph-card';
import loadImage from "../../../image-repository/loadImage.gif";



const Asistencia = (props) => {
    
    const BASE_API = Api.APIS("digitalizacion")
    const[fechaInicio,setFechaInicio] = React.useState(Date.now())
    const[fechaFin,setFechaFin] = React.useState(Date.now())
    const [areas,setAreas] = React.useState([]);
    const [area,setArea] = React.useState(null);
    const [asistencias,setAsistencias] = React.useState([]);
    const [total,setTotal] = React.useState(0)
    const [detailOpen,setDetailOpen] = React.useState(false)
    const [photos,setPhotos] = React.useState([])
    const [graphToken,setGraphToken] = React.useState("")
    const [registro,setRegistro] = React.useState()
    const openDetail=()=>{
        setDetailOpen(true)
        setPhotos([])
    }
    const closeDetail=()=>{
        setDetailOpen(false)
    }
    const simpleAction=async (info)=>{
        const response = await Api.fetchGetRequest(`Asistencia/CAT/${info.IdRegistro}`,BASE_API)
        setRegistro(response.data)
        var images = []
        try{
            if(response.data.Firma!==null)
            {
               /*  console.log(response.data.Firma)
                const firma = await Api.Post("Asistencia/CATBase64",
                    {File:response.data.Firma})
                images = [...images,{label:"Firma",input:"img", value:firma.data}] */
            }
            if(response.data.Foto!==null){
        /*         const foto = await Api.Post("Asistencia/CATBase64",
                    {File:response.data.Foto})
                images = [...images,{label:"Firma",input:"img", value:foto.data, type:"a"}] */
            }
        }
        catch(ex){
            console.log(ex)
        }
        setPhotos(images)
        setDetailOpen(true)
    }
    const showDetail = async (info)=>{
        const response = await Api.fetchGetRequest(`Asistencia/Agricola/${info.IdRegistro}`,BASE_API)
        setRegistro(response.data)
        setDetailOpen(true)
    }
    const loadFoto= async (foto)=>{
        const response =  await Api.post_custom_uri(`Asistencia/AgricolaBase64`,{
            Token : graphToken,
            File: foto
        },BASE_API)
        if(response.status===200)
        {
            return response.data
        }
        return null
    }
    const loadFotoCAT= async (foto)=>{
        const response =  await Api.post_custom_uri(`Asistencia/CATBase64`,{
            File: foto
        },BASE_API)
        if(response.status===200)
        {
            return response.data
        }
        return null
    }
    const loadAsistencia = async (page=1) =>{
        console.log("AREA",area)
        if(area===null){
            return
        }
        else if(area.CAT===true){
            const response = await Api.post_custom_uri("Asistencia/CAT/",{ 
                FechaInicio: format(fechaInicio,"yyyy-MM-dd"), 
                FechaFin: format(fechaFin,"yyyy-MM-dd"),
                Todos:false},BASE_API
                )
            setPhotos([])
            setTotal(response.data.Total)
            if(response.data.Results!==undefined)
                setAsistencias(response.data.Results)
        }
        else if(area.CAT===false){
            const response = await Api.fetchGetRequestWithParams("Asistencia/AgricolaList",{ 
                FechaInicio: format(fechaInicio,"yyyy-MM-dd"), 
                FechaFin: format(fechaFin,"yyyy-MM-dd"),
                Area: area.Area},BASE_API
                )
            const response_token = await Api.fetchGetRequest("Asistencia/GraphToken",BASE_API)
            if(response_token.data.token!==undefined)
                setGraphToken(response_token.data.token)
            setPhotos([])
            setTotal(response.data.Total)
            if(response.data.Results!==undefined)
                setAsistencias(response.data.Results)
        }
        
    }
    const searchAsistencia = async ()=>{
        setAsistencias([])
        await loadAsistencia()
    }
    React.useEffect(()=>{ 
        console.log("API",BASE_API)
        setAreas([
            {Codigo:1,Nombre:"CAT",Area:"CAT", CAT:true},
            {Codigo:2,Nombre:"MÁQUINARIA DE CULTIVO", Area:"AGRONOMIA", CAT:false},
            //{id:2,name:"AGRICOLA", active:false}
            ])
        
    },[]);
    React.useEffect(()=>{ 
        
    },[asistencias]);
    return (
    <div>
        <Layout title="Asistencia">
                
                {
                    area===null?null:
                    (
                        area.CAT===false?
                        (<Modal
                            open= {detailOpen}
                            onClose = {closeDetail}
                            title={`Registro - ${registro!==undefined?registro.IdRegistro:""}`}
                        >
                            {registro===undefined?null:
                            (
                            <div>
                            <TextField
                  
                                id="outlined-full-width"
                                label={"Colaborador"}
                                fullWidth
                                value = {`${registro.Codigo===null?"":registro.Codigo} - ${registro.Nombre===null?"":registro.Nombre}`}
                                type = "string"
                                margin="normal"
                                inputProps ={
                                    {readOnly:true}
                                }
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                                />
                            <TextField
                                id="outlined-full-width"
                                label={"Fecha"}
                                fullWidth
                                value = {format(parseISO(registro.Fecha),"dd/MM/yyyy")}
                                type = "string"
                                margin="normal"
                                inputProps ={
                                    {readOnly:true}
                                }
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                                />    
                            <TextField
                                id="outlined-full-width"
                                label={"Tipo"}
                                fullWidth
                                value = {registro.Tipo}
                                type = "string"
                                margin="normal"
                                inputProps ={
                                    {readOnly:true}
                                }
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                                />
                            
                            <TextField
                                id="outlined-full-width"
                                label={"Trabajo"}
                                fullWidth
                                value = {`(${registro.Turno}) ${registro.Entrada===null?"No registrado":registro.Entrada} - ${registro.Salida===null?"No registrado":registro.Salida}`}
                                type = "string"
                                margin="normal"
                                inputProps ={
                                    {readOnly:true}
                                }
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                                />
                                <TextField
                                id="outlined-full-width"
                                label={"Trabajo"}
                                fullWidth
                                value = {registro.Trabajo}
                                type = "string"
                                margin="normal"
                                inputProps ={
                                    {readOnly:true}
                                }
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                                />
                                <TextField
                                id="outlined-full-width"
                                label={"Finca"}
                                fullWidth
                                value = {registro.NombreFinca}
                                type = "string"
                                margin="normal"
                                inputProps ={
                                    {readOnly:true}
                                }
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                                />
                            {
                                registro.Evaluacion===undefined?null:
                                registro.Evaluacion.map(e=>
                                <GraphCard
                                    default = {loadImage}
                                    primary = {e.Evaluacion}
                                    secondary = {e.IdDetalleFoto}
                                    image={e.Base64}
                                    load = {()=>loadFoto(e.Foto)}
                                />)
                            }
                            </div>)
                            }
                        </Modal>):
                    (<ModalForm
                        open= {detailOpen}
                        onClose = {closeDetail}
                        readOnly = {true}
                        title={`Registro - ${registro!==undefined?registro.IdRegistro:""}`}
                        inputs={
                            registro===undefined?[]:
                            [
                            { label:"Tipo",value:registro.Tipo,type:"string",input:"input"},
                            { label:"Frente",value:registro.Frente,type:"string",input:"input"},
                            { label:"Id",value:registro.IdRegistro,type:"string",input:"input"},
                            { label:"Colaborador",value:`${registro.Codigo} - ${registro.Nombre}`,type:"string",input:"input"},
                            { label:"Fecha",value:format(parseISO(registro.Fecha),'dd/MM/yyyy'),type:"string",input:"input"},
                            { label:"Hora",value:registro.Hora,type:"string",input:"input"},
                            { label:"Grupo",value:registro.Grupo,type:"string",input:"input"},
                            { label:"Fecha de registro",value:format(parseISO(registro.FechaCreacionRegistro),"dd/MM/yyyy hh:mm:ss"),type:"string",input:"input"},
                            { label:"Motivo de inasistencia",value:registro.MotivoInasistencia,type:"string",input:"input"},
                            { label:"Observaciones",value:registro.Observaciones, multiline:true ,type:"string",input:"input"}
                            //,
                            //...photos
                        ]}
                    >
                        {
                            registro===undefined?null:(registro.Firma===null | registro.Firma===""?null:
                            <GraphCard
                                default = {loadImage}
                                primary=""
                                long
                                image={null}
                                secondary = {"Firma"}
                                load = {()=>loadFotoCAT(registro.Firma)}
                                />)
                        }
                        {
                            registro===undefined?null:(registro.Foto===null | registro.Foto===""?null:
                            <GraphCard
                                default = {loadImage}
                                primary=""
                                image={null}
                                secondary = {"Foto de asistencia"}
                                load = {()=>loadFotoCAT(registro.Foto)}
                                long
                            />)
                        }

                    </ModalForm>))
                }
                <Grid
                    container
                    fullWidth
                    spacing = {3}
                    justify="center"
                    >
                        <Grid item xs={12} sm={4} justifyContent="center"> 
                            <Box mx={2}>
                            <Autocomplete
                                fullWidth
                                options={areas}
                                value = {area}
                                onChange={(e,v)=>{setArea(v);setAsistencias([]);setRegistro()}}
                                getOptionLabel={(option)=>option===null?"":option.Nombre}
                                renderInput={ (params)=>
                            <TextField {...params} required
                                id="outlined-full-width"
                                label="Area"
                                fullWidth
                                type = "string"
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"/>}/>
                                </Box> 
                        </Grid>
                        <Grid
                         item xs={12} sm={8}  justifyContent="center">
                             <Grid container spacing={1}>
                                <Grid item xs={12} sm={5}>
                                <DatePicker 
                                    label="Fecha inicio"
                                    value = {fechaInicio}
                                    onChange = {setFechaInicio}  
                                ></DatePicker> 
                                </Grid>
                                <Grid item  xs={12} sm={5}>
                                <DatePicker 
                                    label="Fecha fin"
                                    value = {fechaFin}
                                    onChange = {setFechaFin}  
                                ></DatePicker>
                                </Grid>
                                <Grid item  xs={2}>
                                    <IconButton
                                        onClick={()=>searchAsistencia()}>
                                            <SearchIcon/>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                </Grid>  
                <Box mt={2}>
                    <Paper>
                    {
                        area===null? null:
                        (area.CAT?
                        (<DevGrid 
                            excelName = {`Asistencia ${format(fechaInicio,'dd-MM-yyyy')}`}
                            data = {asistencias}
                            info={[
                                
                                {dataField:"Codigo", summaryType:"count", displayFormat:"{1}"},
                                {dataField:"Nombre", dataType:"string", width:"auto"},
                                {dataField:"Tipo", dataType:"string"},
                                {dataField:"Fecha", dataType:"date"},
                                {dataField:"Frente", dataType:"string"},
                                {dataField:"Turno", dataType:"string"},
                                {dataField:"Grupo", dataType:"string"},
                                {dataField:"MotivoInasistencia", dataType:"string", visible:false},
                                {dataField:"Observaciones", dataType:"string",visible:false}]}
                            actions = {[{action:"simpleAction", function: simpleAction, text:"ver"}]}
                            />):
                            (<DevGrid 
                                defaultPageSize={50}
                                excelName = {`Asistencia ${format(fechaInicio,'dd-MM-yyyy')}`}
                                data = {asistencias}
                                info={[
                                    {dataField:"IdRegistro",caption:"Id", summaryType:"count", displayFormat:"{0}"},
                                    {dataField:"Codigo"},
                                    {dataField:"Nombre", dataType:"string", width:"auto"},
                                    {dataField:"Tipo", dataType:"string"},
                                    {dataField:"Trabajo", dataType:"string"},
                                    {dataField:"Fecha", dataType:"date"},
                                    {dataField:"Turno", dataType:"string"},
                                    {dataField:"Entrada", dataType:"string"},
                                    {dataField:"Salida", dataType:"string"},
                                    {dataField:"Horas",dataType:"number"},
                                    {dataField:"Finca", dataType:"string"}]}
                                actions = {[{action:"showDetail", function: showDetail, text:"ver"}]}
                                />)
                        )
                    }
                    </Paper>
                </Box>
        </Layout>
    </div>
      
    )
}

export default withRouter(Asistencia)
