import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import {
  Typography,
  Backdrop,
  CircularProgress,
  Tab,
  Tabs,
  Box,
  Divider,
  TabScrollButton,
} from "@material-ui/core";

import Container from "@material-ui/core/Container";
import { green } from "@material-ui/core/colors";

import { makeStyles, withStyles } from "@material-ui/core/styles";
//iconos

import { Face, Group, VerifiedUser, ContactMail } from "@material-ui/icons";

//input date

import PropTypes from "prop-types";

//Own components
// import Layout from "../../layout/containers/Layout";

import DatosEmpleadoPersonal from "../components/datos-empleado-personal";
import DatosEmpleadoEditar from "../components/dato-empleado-editar";
import DatosEmpleadoFamilia from "../components/empleado-familia";
import DatosEmpleadoVacuna from "../components/empleado-vacuna";
import DatosEmpleadoCorreo from "../components/empleado-correo";

const a11yProps = (index) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={"span"} variant={"body2"}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const MyTabScrollButton = withStyles((theme) => ({
  root: {
    width: 28,
    overflow: "hidden",
    transition: "width 0.5s",
    "&.Mui-disabled": {
      width: 0,
    },
  },
}))(TabScrollButton);

const styles = makeStyles((theme) => ({
  item: {
    //border: "1px solid blue",
    margin: "10",
  },
  itemFlexGrow: {
    flexGrow: 1,
    // border: "1px solid red"
  },
  content: {
    display: "flex",
    // justify-content: "space-between",
    maxWidth: "400px",
    margin: "0 auto",
    padding: "10px 0",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
  },
  paperPerfil: {
    position: "relative",
    width: "100%", //theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  greenAvatar: {
    fontSize: "2.5em",
    color: "#fff",
    // borderStyle:'solid',
    // borderWidth:'1 !important',
    // borderColor:'#3f51b5 !important',
    backgroundColor: green[500],
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  paperCard: {
    padding: 20,
    width: "100%",
  },
  greenAvatarFoto: {
    fontSize: "2.5em",
    color: "#fff",
    // borderStyle:'solid',
    // borderWidth:'1 !important',
    // borderColor:'#3f51b5 !important',
    backgroundColor: green[500],
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
  tabsVertical: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

const Perfil = (props) => {
  const { dataEmpleado } = props;
  const [tab, setTab] = useState(0);
  const classes = styles();

  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Tabs
              value={tab}
              indicatorColor="primary"
              textColor="primary"
              onChange={(_, val) => setTab(val)}
              variant="scrollable"
              scrollButtons="on"
              aria-label="scrollable force tabs example"
              ScrollButtonComponent={MyTabScrollButton}
            >
              <Tab
                label="Datos Personales"
                icon={<Face />}
                {...a11yProps(0)}
                style={{ textTransform: "none" }}
                disabled={dataEmpleado === undefined}
                value={0}
              />
              <Tab
                label="Datos Familiares"
                icon={<Group />}
                {...a11yProps(1)}
                style={{ textTransform: "none" }}
                disabled={dataEmpleado === undefined}
                value={1}
              />
              <Tab
                label="Vacunación"
                icon={<VerifiedUser />}
                {...a11yProps(2)}
                style={{ textTransform: "none" }}
                disabled={dataEmpleado === undefined}
                value={2}
              />
              <Tab
                label="Correo Electrónico"
                icon={<ContactMail />}
                {...a11yProps(3)}
                style={{ textTransform: "none" }}
                disabled={dataEmpleado === undefined}
                value={3}
              />
            </Tabs>
            <TabPanel id="datosPersonales" value={tab} index={0}>
              <Box className={classes.box} pl={0} pt={0}>
                {dataEmpleado !== undefined && (
                  <Grid container spacing={3}>
                    <DatosEmpleadoPersonal dataEmpleado={dataEmpleado} />
                    <DatosEmpleadoEditar dataEmpleado={dataEmpleado} />
                  </Grid>
                )}
              </Box>
            </TabPanel>
            <TabPanel id="datosFamiliares" value={tab} index={1}>
              <Box className={classes.box} pl={0} pt={0}>
                {dataEmpleado !== undefined && (
                  <Grid container spacing={3}>
                    <DatosEmpleadoFamilia dataEmpleado={dataEmpleado} />
                  </Grid>
                )}
              </Box>
            </TabPanel>
            <TabPanel id="vacunacion" value={tab} index={2}>
              <Box className={classes.box} pl={0} pt={0}>
                {dataEmpleado !== undefined && (
                  <Grid container spacing={3}>
                    <DatosEmpleadoVacuna dataEmpleado={dataEmpleado} />
                  </Grid>
                )}
              </Box>
            </TabPanel>
            <TabPanel id="correo" value={tab} index={3}>
              <Box className={classes.box} pl={0} pt={0}>
                {dataEmpleado !== undefined && (
                  <Grid container spacing={3}>
                    <DatosEmpleadoCorreo dataEmpleado={dataEmpleado} />
                  </Grid>
                )}
              </Box>
            </TabPanel>
          </Grid>
        </Grid>
      </Container>

      <ToastContainer />
    </React.Fragment>
  );
};

export default withRouter(Perfil);
