import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Grid, Icon } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import Drawer from "@material-ui/core/Drawer";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import {
  FloatingMenu,
  MainButton,
  ChildButton,
} from "react-floating-button-menu";
import AddIcon from "@material-ui/icons/Add";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import ClearIcon from "@material-ui/icons/Clear";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";
import AddAlertIcon from "@material-ui/icons/AddAlert";
import PersonIcon from "@material-ui/icons/Person";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Loader from "react-loader-spinner";
import Popover from "@material-ui/core/Popover";
import MaskedInput from "react-text-mask";
import PropTypes from "prop-types";
import esLocale from "date-fns/locale/es";
import { DatePicker, MuiPickersUtilsProvider, Day } from "material-ui-pickers";
import DateFnsUtils from "@date-io/date-fns";
import { DropzoneArea } from "material-ui-dropzone";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Alert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";
import Paper from "@material-ui/core/Paper";
import NotificationsIcon from "@material-ui/icons/Notifications";
import AssignmentIcon from "@material-ui/icons/Assignment";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Container from "@material-ui/core/Container";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SwipeableViews from "react-swipeable-views";
import Box from "@material-ui/core/Box";
import { useTheme } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";

//Inputs Formulario
import InputHora from "../../avisos-objects/components/input-hora";
import Separador from "../../avisos-objects/components/separador";

//Scroll bar
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import UsuariosList from "../../plantillaProblema/components/usuarios-list";

//Appbar
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import InputBase from "@material-ui/core/InputBase";
import { fade, makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";

//Own components
import Layout from "../../layout/containers/Layout";
import ObjetosTabla from "../components/objetos-tabla";
import AddButton from "../../helpers/components/add-button-float";
import API from "../../utils/api";
import { isSignedIn, signOut } from "../../utils/auth";
import { convertYYYYMMDDToDate, convertirHHMM } from "../../utils/helpers";
import { convertirASegundos, convertToYearMonthDay } from "../../utils/helpers";
import CardAvisos from "../components/card-aviso";
import { validaPermisoPaginaActual } from "../../utils/helpers";

//CSS
import "../components/gestion-style.css";
import { registrarAnalitica } from "../../utils/firebase-config";

import {
  Timeline,
  TimelineItem,
  TimelineContent,
  TimelineSeparator,
  TimelineConnector,
  TimelineDot,
} from "@material-ui/lab";
import {
  AddComment,
  Cancel,
  Check,
  HourglassFullTwoTone,
} from "@material-ui/icons";
import "./timeline.css";
import Chat from "../../chat/containers/chat";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

//const theme = useTheme();

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  paperUserModal: {
    position: "absolute",
    width: "75%",
    height: "600px",
    maxHeight: "600px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  popover: {
    pointerEvents: "none",
  },
  paperPopover: {
    padding: theme.spacing(1),
    background: "#000",
    color: "#fff",
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
});

class ListaAvisos extends Component {
  state = {
    tipo: 0,
    lista: [],
    pendientes: [],
    terminados: [],
    value: 0,
    openDrawer: false,
    openToolbar: false,
    codigoAvisoTracking: "",
    nombreTracking: "",
    trackingItems: [],
    cargandoTracking: false,
  };

  async componentDidMount() {
    //CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
    const llaveAgrupacion = "Menu.Principal.Web";
    const llavePagina = "Menu.Principal.Web.Gestiones";
    await validaPermisoPaginaActual(llaveAgrupacion, llavePagina);
    //FIN DE CONSULTA DE PERMISOS
    registrarAnalitica("Gestiones_Creadas_Por_Mi");
    const session = await isSignedIn();

    if (
      this.props.match.params.tipo === null ||
      this.props.match.params.tipo === undefined
    ) {
      window.location = "/home";
      return;
    }
    if (session.response) {
      this.setState(
        {
          tipo: this.props.match.params.tipo,
          codigoUsuarioActual: session["codigoUsuario"],
          usuarioAfectado: parseInt(session["codigoUsuario"]),
        },
        async () => {
          this.makeRequesPendientes();
          this.makeRequesTerminados();
        }
      );
    } else {
      window.location.href = "/login";
    }
  }

  recargar = async () => {
    this.makeRequesPendientes();
    this.makeRequesTerminados();
  };

  makeRequesTerminados = async () => {
    //Obtenemos los filtros del storage, de lo contrario dejamos el default.
    let direccion = "asc";
    let filtroPorReq = "fecha-creacion";
    const { filtrosExtras } = this.state;
    try {
      const session = await isSignedIn();

      let uri;
      if (this.state.tipo === "A") {
        uri = `api/aviso/asignadosTerminados/${session["codigoUsuario"]}`;
      } else if (this.state.tipo === "C") {
        uri = `api/aviso/propiosTerminados/${session["codigoUsuario"]}`;
      }

      const request = await API.fetchGetRequestWithParams(uri, filtrosExtras);
      console.log(request);
      if (request.statusCode === 401) {
        if (await signOut()) {
          window.location.href = "/login";
        }
        return;
      }

      if (this.state.tipo === "A") {
        if (request.data.response && Array.isArray(request.data.asignados)) {
          //OK
          this.setState({
            terminados: request.data.asignados,
            isLoading: false,
            error: false,
          });
        } else {
          //El API alerta error.
          this.setState({
            isLoading: false,
            error: false,
            terminados: [],
          });
        }
      } else if (this.state.tipo === "C") {
        if (request.data.response && Array.isArray(request.data.data)) {
          //OK
          this.setState({
            terminados: request.data.data,
            isLoading: false,
            error: false,
          });
        } else {
          //El API alerta error.
          this.setState({
            isLoading: false,
            error: false,
            terminados: [],
          });
        }
      }
    } catch (e) {
      //Hubo error en el request al server
      this.setState({
        isLoading: false,
        error: true,
      });
    }
  };

  makeRequesPendientes = async () => {
    //Obtenemos los filtros del storage, de lo contrario dejamos el default.
    let direccion = "asc";
    let filtroPorReq = "fecha-creacion";
    const { filtrosExtras } = this.state;

    let uri;
    try {
      const session = await isSignedIn();

      if (this.state.tipo === "A") {
        uri = `api/aviso/asignados/${direccion}/${filtroPorReq}/${session["codigoUsuario"]}`;
      } else if (this.state.tipo === "C") {
        uri = `api/aviso/propios/${session["codigoUsuario"]}`;
      }

      const request = await API.fetchGetRequestWithParams(uri, filtrosExtras);

      if (request.statusCode === 401) {
        if (await signOut()) {
          window.location.href = "/login";
        }
        return;
      }

      if (this.state.tipo === "A") {
        if (request.data.response && Array.isArray(request.data.asignados)) {
          //OK
          this.setState({
            pendientes: request.data.asignados,
            isLoading: false,
            error: false,
          });
        } else {
          //El API alerta error.
          this.setState({
            isLoading: false,
            error: false,
            pendientes: [],
          });
        }
      } else if (this.state.tipo === "C") {
        if (request.data.response && Array.isArray(request.data.data)) {
          this.setState({
            pendientes: request.data.data,
            isLoading: false,
            error: false,
          });
        } else {
          //El API alerta error.
          this.setState({
            isLoading: false,
            error: true,
            pendientes: [],
          });
        }
      }
    } catch (e) {
      //Hubo error en el request al server
      this.setState({
        isLoading: false,
        error: true,
      });
    }
  };

  handleChange = (name) => (event) => {
    if (name == "activo") {
      this.setState({ [name]: event.target.checked });
    } else {
      this.setState({
        [name]: event.target.value,
      });
    }
  };

  handleChangeCheck = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  handlePopoverOpen = (event, textoPopover) => {
    this.setState({
      anchorEl: event.currentTarget,
      popOverOpen: true,
      textoPopover: textoPopover,
    });
  };

  getTracking = async (codigoAviso, nombre) => {
    this.setState({
      openToolbar: true,
      codigoAvisoTracking: codigoAviso,
      nombreTracking: nombre,
      cargadoTracking: true,
      trackingItems:[],
    });
    const request = await API.fetchGetRequest(
      `api/aviso/tracking/${codigoAviso}`
    );

    if (request.statusCode === 401) {
      if (await signOut()) {
        window.location.href = "/login";
      }
      return;
    }

    if (Array.isArray(request.data.data)) {
      this.setState({
        cargandoTracking: false,
        trackingItems: request.data.data,
      });
    }
  };

  handlePopoverClose = () => {
    this.setState({ anchorEl: null, popOverOpen: false, textoPopover: "" });
  };

  handleChangeTabs = (event, newValue) => {
    this.setState({ value: newValue });
  };

  handleChangeIndex = (index) => {
    this.setState({ value: index });
  };

  openChat = () => {};

  render() {
    const { classes } = this.props;

    return (
      <Layout
        titulo={
          "Gestiones" +
          (this.state.tipo === "C" ? " creadas por mí" : " asignadas a mí")
        }
      >
        <div
          style={{
            color: "#2196F3",
            display: "flex",
            alignContent: "center",
            fontSize: "1m",
            marginTop: 10,
          }}
        ></div>

        {this.state.cargando ? (
          <div
            style={{
              width: "100%",
              marginTop: 10,
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loader
              type="Oval"
              color="#3498db"
              height="50"
              width="50"
              style={{ textAlign: "center" }}
            />
          </div>
        ) : (
          <></>
        )}

        <div className={classes.root}>
          <AppBar position="static" color="default">
            <Toolbar>
              <Typography variant="h6" className={classes.title}></Typography>
              {/* <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="menu"
                onClick={() => {
                  this.setState({ openDrawer: true });
                }}
              >
                <SearchIcon />
              </IconButton> */}
            </Toolbar>
            <Tabs
              value={this.state.value}
              onChange={this.handleChangeTabs}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab label="Pendientes" {...a11yProps(0)} />
              <Tab label="Terminados" {...a11yProps(1)} />
            </Tabs>
          </AppBar>
          <Drawer
            className={classes.drawer}
            style={{ width: 360 }}
            variant="temporary"
            anchor="right"
            open={this.state.openToolbar}
            onClose={() => {
              this.setState({ openToolbar: false });
            }}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div
              className={classes.drawerHeader}
              style={{ backgroundColor: "#2B3C4D", color: "#fff", width: 500 }}
            >
              <IconButton
                // onClick={this.handleDrawerClose}
                onClick={() => {
                  this.setState({ openToolbar: false });
                }}
              >
                {this.state.openToolbar === true ? (
                  <CloseIcon style={{ color: "#fff" }} />
                ) : (
                  <ChevronRightIcon />
                )}
              </IconButton>
              <Typography variant="h6" component="span">
                Gestión #{this.state.codigoAvisoTracking}
              </Typography>
              {/* <strong style ={{color:'#fff'}}>Gestión #{this.state.codigoAvisoTracking}</strong> */}
            </div>

            <div>
              <Timeline align="left" style={{ left: "-50% important" }}>
                {this.state.trackingItems.map((i, idx) => (
                  <TimelineItem key={`item${idx}`}>
                    <TimelineSeparator>
                      <TimelineDot
                        variant="outlined"
                        color="primary"
                        style={{
                          color:
                            i.tipo === "I" || i.tipo === "TC" || i.tipo === "F"
                              ? "#1abc9c"
                              : i.tipo === "TA"
                              ? "#c0392b"
                              : "#7f8c8d",
                          borderColor:
                            i.tipo === "I" || i.tipo === "TC" || i.tipo === "F"
                              ? "#1abc9c"
                              : i.tipo === "TA"
                              ? "#c0392b"
                              : "#7f8c8d",
                        }}
                      >
                        {(i.tipo === "I" ||
                          i.tipo === "TC" ||
                          i.tipo === "F") && <Check />}
                        {i.tipo === "TA" && <Cancel />}
                        {(i.tipo === "TP" || i.tipo === "FP") && (
                          <HourglassFullTwoTone />
                        )}
                      </TimelineDot>
                      {this.state.trackingItems.length !== idx + 1 && (
                        <TimelineConnector />
                      )}
                    </TimelineSeparator>
                    <TimelineContent>
                      <Paper elevation={0}>
                        <Typography
                          variant="subtitle1"
                          style={{ fontWeight: "bold" }}
                        >
                          {i.titulo}
                        </Typography>
                        <Typography variant="body1" component="p">
                          {i.persona}
                        </Typography>
                        <Typography
                          variant="body1"
                          component="p"
                          style={{ fontStyle: "italic" }}
                        >
                          {i.fecha}
                        </Typography>
                        {i.codigoTarea !== null && (
                          <Chat 
                          codigoPadre = {i.codigoTarea}
                          nombrePadre = {i.titulo}
                          llavePadre = {"AITAvisoTarea"}
                          />
                        )}
                      </Paper>
                    </TimelineContent>
                  </TimelineItem>
                ))}
              </Timeline>
            </div>
          </Drawer>
          <SwipeableViews
            //axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={this.state.value}
            onChangeIndex={this.handleChangeIndex}
          >
            <TabPanel value={this.state.value} index={0}>
              <Container maxWidth="md">
                {this.state.pendientes.map((item, index) => (
                  <CardAvisos
                    key={item.CodigoAviso}
                    CodigoAviso={item.CodigoAviso}
                    Nombre={item.Titulo}
                    NombreCarpeta={item.Problema.Nombre}
                    Fecha={item.FechaInsertoAmigable}
                    NombreEstado={((item || {}).Estado || {}).Nombre}
                    Activo={item.Activo}
                    Atrasado={item.Atrasado}
                    SLA={item.SLA}
                    TiempoTranscurridoHastaCompletada={
                      item.TiempoTranscurridoHastaCompletada
                    }
                    Quedan={
                      item.VencimientoTiempoEsperaEstimado != null
                        ? item.VencimientoTiempoEsperaEstimado
                        : item.CausasTiempoEsperaEstimado != null
                        ? item.CausasTiempoEsperaEstimado
                        : item.ProblemaTiempoEsperaEstimado
                    }
                    recargar={this.recargar}
                    porcentajeAvance={item.PorcentajeAvance}
                    getTrackingFn={this.getTracking}
                    showTrackingButton={true}
                  />
                ))}
              </Container>
            </TabPanel>
            <TabPanel value={this.state.value} index={1}>
              <Container maxWidth="md">
                {this.state.terminados.map((item, index) => (
                  <CardAvisos
                    key={item.CodigoAviso}
                    CodigoAviso={item.CodigoAviso}
                    Nombre={item.Titulo}
                    NombreCarpeta={item.Problema.Nombre}
                    Fecha={item.FechaInsertoAmigable}
                    NombreEstado={((item || {}).Estado || {}).Nombre}
                    Activo={item.Activo}
                    Atrasado={item.Atrasado}
                    SLA={item.SLA}
                    Quedan={
                      item.VencimientoTiempoEsperaEstimado != null
                        ? item.VencimientoTiempoEsperaEstimado
                        : item.CausasTiempoEsperaEstimado != null
                        ? item.CausasTiempoEsperaEstimado
                        : item.ProblemaTiempoEsperaEstimado
                    }
                    recargar={this.recargar}
                    porcentajeAvance={item.PorcentajeAvance}
                    getTrackingFn={this.getTracking}
                    showTrackingButton={true}
                  />
                ))}
              </Container>
            </TabPanel>
          </SwipeableViews>
        </div>

        <ToastContainer />
        <Popover
          id="mouse-over-popover"
          className={classes.popover}
          classes={{
            paper: classes.paperPopover,
          }}
          open={this.state.popOverOpen}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          onClose={this.handlePopoverClose}
          disableRestoreFocus
        >
          <Typography>{this.state.textoPopover}</Typography>
        </Popover>

              
       
      </Layout>
    );
  }
}
const ListaAvisosContainer = withStyles(styles)(ListaAvisos);
export default withRouter(ListaAvisosContainer);
