import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import PasoUnoFormulario from "./crear-notificacion-paso-uno-form";
import PasoDosFormulario from "./crear-notificacion-paso-dos-form";
import PasoTresFormulario from "./crear-notificacion-paso-tres-form";
import Mensaje from "../../helpers/components/message";

const styles = (theme) => ({
  root: {
    width: "90%",
  },
  button: {
    marginRight: theme.spacing.unit,
    color: "#fff",
    backgroundColor: "#69BD4B",
  },
  buttonAtras: {
    marginRight: theme.spacing.unit,
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  step: {
    "& $completed": {
      color: "#69BD4B",
    },
    "& $active": {
      color: "#69BD4B",
    },
    "& $disabled": {
      color: "red",
    },
  },
  alternativeLabel: {},
  active: {}, //needed so that the &$active tag works
  completed: {},
  disabled: {},
  labelContainer: {
    "& $alternativeLabel": {
      marginTop: 0,
    },
  },
  navigationButtons: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    marginTop: 25,
    marginBottom: 30,
  },
  navigationButtons2: {
    width: 500,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
});

function getSteps() {
  return ["Agrega una descripción", "Programa tu envío", "Confirma los datos"];
}

class HorizontalLinearStepper extends React.Component {
  state = {
    activeStep: 0,
    skipped: new Set(),
    openAlert: false,
    alertMensaje: "Revisa que todos los campos estén llenos",
    finalizado: false,
  };

  getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <PasoUnoFormulario
            titulo={this.props.titulo}
            descripcion={this.props.descripcion}
            cuerpo={this.props.cuerpo}
            guardarInputs={this.props.guardarInputs}
            imagen={this.props.imagen}
            editar={this.props.editar}
          />
        );
      case 1:
        return (
          <PasoDosFormulario
            fecha={this.props.fecha}
            seleccionarGrupo={this.props.seleccionarGrupo}
            fechaHoy={this.props.fechaHoy}
            seleccionarOtraFecha={this.props.seleccionarOtraFecha}
            seleccionoAhora={this.props.seleccionoAhora}
            gruposDisponibles={this.props.gruposDisponibles}
            gruposSeleccionados={this.props.gruposSeleccionados}
            guardarInputs={this.props.guardarInputs}
            editar={this.props.editar}
          />
        );
      case 2:
        return (
          <PasoTresFormulario
            titulo={this.props.titulo}
            descripcion={this.props.descripcion}
            cuerpo={this.props.cuerpo}
            fecha={this.props.fecha}
            seleccionoAhora={this.props.seleccionoAhora}
            gruposSeleccionados={this.props.gruposSeleccionados}
            gruposDisponibles={this.props.gruposDisponibles}
            editar={this.props.editar}
          />
        );
      default:
        return "Unknown step";
    }
  }

  isStepOptional = (step) => step === 1;

  handleNext = async () => {
    const { activeStep } = this.state;
    let { skipped } = this.state;

    // Validaciones
    if (activeStep === 0) {
      // Validaciones para el primer paso.
      if (
        this.props.titulo.trim() === "" ||
        this.props.descripcion.trim() === "" ||
        this.props.cuerpo.trim() === ""
      ) {
        this.setState({
          openAlert: true,
        });
        return;
      }
    } else if (activeStep === 1) {
      // Validaciones para el segundo paso.
      if (this.props.fecha === "") {
        this.setState({
          openAlert: true,
        });
        return;
      }
    } else if (activeStep === 2) {
      // Validaciones para el tercer paso, que incluye la validación del formulario de evento.
      if (this.props.crearEvento) {
        const {
          Titulo,
          Descripcion,
          Fecha,
          TodoElDia,
          HoraInicio,
          HoraFin,
          Color,
        } = this.props.eventoData;

        if (
          !Titulo ||
          !Descripcion ||
          !Fecha ||
          !Color ||
          (!TodoElDia && (!HoraInicio || !HoraFin))
        ) {
          this.setState({
            openAlert: true,
            alertMensaje: "Revisa que todos los campos del evento estén llenos",
          });
          return;
        }
      }
    }

    if (this.isStepSkipped(activeStep)) {
      skipped = new Set(skipped.values());
      skipped.delete(activeStep);
    }

    this.setState({
      activeStep: activeStep + 1,
      skipped,
    });

    const steps = getSteps();

    if (activeStep === steps.length - 1) {
      const result = await this.props.guardar();
      if (result) {
        this.setState({ finalizado: true });
      } else {
        this.setState({
          openAlert: true,
          alertMensaje: "Hubo un error al guardar la notificación",
          activeStep: activeStep, // Para mantener el paso actual si hay un error
        });
      }
    }
  };

  handleBack = () => {
    this.setState((state) => ({
      activeStep: state.activeStep - 1,
    }));
  };

  isStepSkipped(step) {
    return this.state.skipped.has(step);
  }

  handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ openAlert: false });
  };

  render() {
    const { classes } = this.props;
    const steps = getSteps();
    const { activeStep, finalizado } = this.state;

    return (
      <div className={classes.root}>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          classes={{
            root: classes.root,
          }}
        >
          {steps.map((label, index) => {
            const props = {};
            const labelProps = {};

            if (this.isStepSkipped(index)) {
              props.completed = false;
            }
            return (
              <Step
                key={label}
                {...props}
                classes={{
                  root: classes.step,
                  completed: classes.completed,
                  active: classes.active,
                }}
              >
                <StepLabel
                  {...labelProps}
                  classes={{
                    alternativeLabel: classes.alternativeLabel,
                    labelContainer: classes.labelContainer,
                  }}
                  StepIconProps={{
                    classes: {
                      root: classes.step,
                      completed: classes.completed,
                      active: classes.active,
                      disabled: classes.disabled,
                    },
                  }}
                >
                  {label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <div>
          {finalizado ? (
            <div>
              <Typography className={classes.instructions}>
                Listo, has finalizado de programar tu envío.
              </Typography>
            </div>
          ) : (
            <div>
              {this.getStepContent(activeStep)}
              <div className={classes.navigationButtons}>
                <div className={classes.navigationButtons2}>
                  <Button
                    disabled={activeStep === 0}
                    onClick={this.handleBack}
                    className={classes.buttonAtras}
                  >
                    Atrás
                  </Button>

                  <Button
                    variant="contained"
                    onClick={this.handleNext}
                    className={classes.button}
                  >
                    {activeStep === steps.length - 1 ? "Guardar" : "Siguiente"}
                  </Button>
                  {this.props.editar &&
                  this.props.codigoNotificacionEstado != 1 ? (
                    <>
                      <br />
                      <Mensaje>
                        No puede modificar esta notificación debido a que ya se
                        programó su envío.
                      </Mensaje>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          )}
        </div>

        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={this.state.openAlert}
          autoHideDuration={2000}
          onClose={this.handleCloseAlert}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">{this.state.alertMensaje}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleCloseAlert}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </div>
    );
  }
}

HorizontalLinearStepper.propTypes = {
  classes: PropTypes.object,
  titulo: PropTypes.string,
  descripcion: PropTypes.string,
  cuerpo: PropTypes.string,
  fecha: PropTypes.string,
  seleccionarGrupo: PropTypes.func,
  fechaHoy: PropTypes.func,
  seleccionarOtraFecha: PropTypes.func,
  seleccionoAhora: PropTypes.bool,
  gruposDisponibles: PropTypes.array,
  gruposSeleccionados: PropTypes.object,
  guardarInputs: PropTypes.func,
  editar: PropTypes.bool,
  codigoNotificacionEstado: PropTypes.number,
  guardar: PropTypes.func,
  crearEvento: PropTypes.bool,
  eventoData: PropTypes.object,
};

export default withStyles(styles)(HorizontalLinearStepper);
