import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import PerfectScrollbar from "react-perfect-scrollbar";
import Modal from "@material-ui/core/Modal";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import Mensaje from "./message";

import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  container: {
    margin: 10,
  },
  paperUserModal: {
    position: "absolute",
    width: "75%",
    height: "600px",
    maxHeight: "600px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
  inputs: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  button: {
    backgroundColor: "#2196F3",
    marginLeft: 10,
    height: 30,
  },
}));

function ModalAgregarItem(props) {
  const classes = useStyles();
  const [textoBusqueda, setTextoBusqueda] = useState("");

  return (
    <Modal
      aria-labelledby={props.title}
      aria-describedby={props.title}
      /* open={open} */
      open={props.open}
      /* onClose={() => {
        setOpen(false);
      }} */
      onClose={() => {
        props.setOpen(false);
      }}
    >
      <div style={getModalStyle()} className={classes.paperUserModal}>
        <PerfectScrollbar>
          <div className={classes.container}>
            <Typography variant="h6" gutterBottom>
              {props.title}
            </Typography>

            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <div className={classes.inputs}>
                  <TextField
                    id="txtTextoBusquedaTag"
                    label={props.inputPlaceholder}
                    className={classes.textField}
                    value={textoBusqueda}
                    onChange={(event) => setTextoBusqueda(event.target.value)}
                    margin="normal"
                    inputProps={{ maxLength: 512 }}
                    style={{ width: "100%" }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    endIcon={<SearchIcon>send</SearchIcon>}
                    onClick={() => props.onClickSearch(textoBusqueda)}
                  >
                    Buscar
                  </Button>
                </div>
              </Grid>
            </Grid>

            <Grid item xs={12} lg={12} sm={12}>
              <Grid container spacing={1}>
                <List component="nav" aria-label="main mailbox folders">
                  {props.items.length == 0 && (
                    <div>
                      <Mensaje>No hay registros.</Mensaje>
                    </div>
                  )}
                  {props.items.map((item) => (
                    <ListItem
                      button
                      onClick={() => {
                        props.onClickItem(item);
                      }}
                    >
                      <ListItemIcon>
                        <LocalOfferIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={item.name}
                        // secondary={
                        //   item.TiempoEsperaEstimado > 0
                        //     ? convertirHHMM(item.TiempoEsperaEstimado)
                        //     : ""
                        // }
                      />
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
          </div>
        </PerfectScrollbar>
      </div>
    </Modal>
  );
}

export default ModalAgregarItem;
