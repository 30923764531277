import React, { useState } from 'react'
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField
} from '@material-ui/core'
import { toast } from 'react-toastify'
import LoadingButton from '../utils/LoadingButton'
import api from '../../../utils/api'
import SelectUsuario from '../usuarios/SelectUsuario'
import { getCodigoUsuario } from '../../utils/utilidades'

const EnviarObsoletos = ({documento, onClose, onRefresh}) => {
  const [justificacion, setJustificacion] = useState('')
  const [usuario, setUsuario] = useState(null)
  const [sending, setSending] = useState(false)
  const enviar = async () => {
    if (!usuario) {
      toast.error('Selecciona a un encargado de aprobar obsolescencia')
      return
    }
    if (!justificacion.trim()) {
      toast.error('Selecciona a un encargado de aprobar obsolescencia')
      return
    }
    try {
      setSending(true)
      const body = {
        IdSolicitud: documento.IdSolicitud,
        CodigoUsuarioSolicita: getCodigoUsuario(),
        Justificacion: justificacion.trim(),
        CodigoUsuarioAutoriza: usuario.CodigoUsuario
      }
      await api.realizarPeticionPostPut('api/documentos/obsolescencia/enviar-solicitud-aprobacion-obsolescencia',
        body,
        'POST')
      onRefresh()
    } catch (error) {
      console.log(error)
      toast.error('Ha ocurrido un error')
    } finally {
      setSending(false)
    }
  }
  return <>
    <DialogTitle>
      Enviar a documentos obsoletos
    </DialogTitle>
    <DialogContent>
      <TextField
        fullWidth
        multiline
        rows={2}
        label="Justificación"
        value={justificacion}
        onChange={ev => setJustificacion(ev.target.value)}
      />
      <SelectUsuario
        label="Usuario encargado de aprobar obsolescencia"
        onSelect={val => setUsuario(val)}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancelar</Button>
      <LoadingButton loading={sending} color="primary" onClick={enviar}>Enviar</LoadingButton>
    </DialogActions>
  </>
}

export default EnviarObsoletos
