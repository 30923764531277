import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import EditIcon from '@material-ui/icons/Edit';
import ActionMenuObjects from "./action-menu-objects";


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

export default function ListaObjetos(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <List component="nav" aria-label="">
        {props.registros.map(item => (
          <React.Fragment>
          <ListItem button
           onClick={()=>{}}
          >
            <ListItemIcon>
              <FolderOpenIcon />
            </ListItemIcon>
           
            <ListItemText
              primary={item.nombre}
            //   secondary={item.NombreCompletoUsuario}
            />
            <ActionMenuObjects/>
          </ListItem>
          
          </React.Fragment>
        ))}
      </List>
    </div>
  );
}
