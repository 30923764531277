import React, { useState, useEffect } from 'react';
import { AppBar, Tabs, Tab, Box } from '@material-ui/core';
import { ToastContainer, toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import Layout from "../../layout/containers/Layout";
import ListadoTemasContainer from './listado-temas';
import ListadoMensajesContainer from './listado-mensajes';
import { validaPermisoPaginaActual } from '../../utils/helpers';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={{ width: '100%' }}
            {...other}
        >
            {value === index && (
                <Box p={0}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const CentroTemasNotificaciones = (props) => {

    const [tab, setTab] = useState(0);

    useEffect(() => {
        permisos()
    }, []);

    const permisos = async () => {
        //CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
        const llaveAgrupacion = "Menu.Principal.Web";
        const llavePagina = "Menu.Principal.Web.Notificaciones";
        await validaPermisoPaginaActual(llaveAgrupacion, llavePagina);
        //FIN DE CONSULTA DE PERMISOS
    }

    return (
        <Layout titulo="Centro de temas notificaciones">
            <AppBar position="static" color="default">
                <Tabs
                    value={tab}
                    onChange={(event, newValue) => setTab(newValue)}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                >
                    <Tab label="Listado de temas" {...a11yProps(0)} />
                    <Tab label="Listado de mensajes" {...a11yProps(1)} />
                </Tabs>
            </AppBar>
            <TabPanel value={tab} index={0}>
                <ListadoTemasContainer {...props} />
            </TabPanel>
            <TabPanel value={tab} index={1}>
                <ListadoMensajesContainer {...props} />
            </TabPanel>
            <ToastContainer />
        </Layout>
    )
}

export default withRouter(CentroTemasNotificaciones);
