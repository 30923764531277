import { Grid } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import FunctionsIcon from "@material-ui/icons/Functions";
import ImageIcon from "@material-ui/icons/Image";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "axios";
import { DropzoneArea } from "material-ui-dropzone";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import MaskedInput from "react-text-mask";

//Own
import { toast } from "react-toastify";
import DataGrid from "../../helpers/components/data-grid";
import API from "../../utils/api";
import { isSignedIn } from "../../utils/auth";
import { set } from "date-fns";

const useStyles = makeStyles((theme) => ({
  tituloPaper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: 20,
  },
  gestionGeneral: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  container: {
    margin: 10,
  },
  input: {
    marginTop: 15,
  },
  item: {
    paddingLeft: 3 * theme.spacing.unit,
  },
  group: {
    fontWeight: theme.typography.fontWeightMedium,
    opacity: 1,
  },
  inputField: {
    textAlign: "start",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
  },
}));

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0-9]/, /[0-9]/, ":", /[0-5]/, /[0-9]/]}
      placeholderChar={"\u2000"}
      showMask
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

function newTab(url) {
  var form = document.createElement("form");
  form.method = "GET";
  form.action = url;
  form.target = "_blank";
  document.body.appendChild(form);
  form.submit();
}

async function newTabAuth({ fileName, key }) {
  const session = await isSignedIn();
  const baseApi = await API.baseApi();

  axios({
    url: `${baseApi}api/media/descargar?key=${key}&fileName=${fileName}`, //your url
    method: "GET",
    headers: { Authorization: "bearer " + session.accessToken },
    responseType: "blob", // important
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName); //or any other extension
    document.body.appendChild(link);
    link.click();
  });
}

const uploadFiles = async (
  codigoAviso,
  campo,
  recargarDatos,
  iterarKey,
  files
) => {
  console.log("Uploading...");
  const session = await isSignedIn();

  if (campo.Valor != null && files.length > 0) {
    let data = new FormData();
    data.append("CodigoAviso", codigoAviso);
    data.append("UsuarioInserto", session["userName"]);
    data.append("CodigoCampo", campo.CodigoCampo);
    data.append("CodigoTipoCampo", campo.CodigoTipoCampo);

    await files.forEach(async (element2, j) => {
      await data.append("files[]", element2);
    });

    let request = await API.post("/api/aviso/agregarMultimedia", data, {
      "content-type": "multipart/form-data",
    });
    await console.log(request);
    await recargarDatos();
    iterarKey();
  }
};

function Formulario(props) {
  const refs = useRef([]);
  const [camposPersonalizados, setCamposPersonalizados] = useState(null);
  const [detallePermisoUsuarioActual, setDetallePermisoUsuarioActual] =
    useState(null);

  const handleKeyDown = useCallback((e, index) => {
    if (e.key === "Tab") {
      e.preventDefault();
      if (refs.current[index + 1]) {
        // console.log(refs.current[index + 1]);
        refs.current[index + 1].focus();
        // setIndex(index + 1);
      }
    }
  }, []);

  const grabarFila = async (
    codigoCampoBase,
    plantillaValores,
    fila,
    codigoGrid
  ) => {
    try {
      let vIsSignedIn = await isSignedIn();

      if (vIsSignedIn.response) {
        let data = {
          codigoGrid: codigoGrid, // código del avisoCampoValor / FormularioCampoValor del grid
          codigoCampoGrid: codigoCampoBase, //Generalmente se establece el mismo codigo de campo del grid al momento de crear una columna ya que no existe el tipo de campo columna
          origen: "AITAvisoCampoValor", //ej: AITAvisoCampoValor / AITFormularioCampoValor
          camposColumnas: plantillaValores,
          codigoUsuarioOpera: vIsSignedIn.codigoUsuario,
          usuarioOpera: vIsSignedIn.userName,
        };

        let request = await API.post("api/formulario/agregarFilaGrid", data);
        if (request.status != 200) {
          alert(
            "Hubo un error al realizar la operación, consulta con un administrador"
          );
          return;
        }

        //Carga adjuntos
        console.log("requestSaveRow", request.data.data);
        if (request.data.response == true) {
          await cargarAdjuntosGrid(request.data.data.filaId, plantillaValores);
        }
      }

      props.recargar();
    } catch (err) {
      console.error(err);
    }
  };

  const removerFila = async (
    objectToDelete,
    codigoFormDeCampo,
    codigoCampo
  ) => {
    try {
      let vIsSignedIn = await isSignedIn();

      if (vIsSignedIn.response) {
        let data = {
          Id: objectToDelete.Codigo,
          CodigoUsuarioOpera: vIsSignedIn.codigoUsuario,
          UsuarioOpera: vIsSignedIn.userName,
        };

        let request = await API.post("api/formulario/removerFilaGrid", data);
        if (request.status != 200) {
          alert(
            "Hubo un error al realizar la operación, consulta con un administrador"
          );
          return;
        }
        toast.info("El registro fue removido con éxito");
        props.recargar();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const cargarAdjuntosGrid = async (codigoFila, plantillaValores) => {
    const session = await isSignedIn();

    const camposConArchivos = plantillaValores.filter((obj) => {
      return obj.CodigoTipoCampo === 6 || obj.CodigoTipoCampo === 8;
    });

    console.log("Al menos entró a esta babosada", camposConArchivos);

    //Inicio sync
    await camposConArchivos.forEach(async (element, i) => {
      if (element.Valor != null && element.Valor.length > 0) {
        let data = new FormData();
        data.append("CodigoAviso", -1);
        data.append("CodigoFila", codigoFila);
        data.append("UsuarioInserto", session["userName"]);
        data.append("CodigoCampo", element.CodigoCampo);
        data.append("CodigoCampoAviso", -1);
        data.append("LocalIndex", -1);

        await element.Valor.forEach(async (element2, j) => {
          await data.append("files[]", element2);
        });

        console.log("data Multimedia " + i, data);

        let request = await API.post(
          "/api/formulario/agregarAdjuntosGridAviso",
          data,
          {
            "content-type": "multipart/form-data",
          }
        );
        console.log(i + request);
      }
    });
  };

  useEffect(() => {
    setCamposPersonalizados(props.camposPersonalizados);
  }, [props.camposPersonalizados]);

  useEffect(() => {
    if (props.detallePermisoUsuarioActual !== null) {
      setDetallePermisoUsuarioActual(props.detallePermisoUsuarioActual);
    }
  }, [props.detallePermisoUsuarioActual]);

  function DibujarCampo(props) {
    const [row, setRow] = useState(props.row);
    const index = props.index;
    useEffect(() => {
      setRow(props.row);
    }, [props.row]);

    const [iKey, setIkey] = useState(0);
    const iterarKey = () => {
      setIkey(iKey + 1);
    };

    let component;
    switch (row.CodigoTipoCampo) {
      case 1:
        component = (
          <TextField
            required={props.row.Requerido}
            id={`txt${props.row.CodigoCampo}`}
            label={props.row.Nombre}
            style={{ width: "100%" }}
            margin="normal"
            variant="outlined"
            placeholder={
              props.row.PlaceHolderIgualNombre == true
                ? props.row.Nombre
                : props.row.PlaceHolderText
            }
            inputProps={{ zIndex: 0 }}
            defaultValue={row.Valor.Valor}
            onBlur={props.onChangeCampoPersonalizado(row.CodigoCampo)}
            disabled={
              props.numeroEstadosPosteriores > 0 &&
              (props.permisoEditar || detallePermisoUsuarioActual.Update)
                ? false
                : true
            }
            inputRef={(el) => (refs.current[index] = el)}
            onKeyDown={(e) => handleKeyDown(e, index)}

            //    verificarDependencias(campo);
            //  }}
          />
        );

        break;

      case 2:
        component = (
          <TextField
            required={props.row.Requerido}
            id={`txt${props.row.CodigoCampo}`}
            type="number"
            label={props.row.Nombre}
            style={{ width: "100%" }}
            margin="normal"
            variant="outlined"
            placeholder={
              props.row.PlaceHolderIgualNombre == true
                ? props.row.Nombre
                : props.row.PlaceHolderText
            }
            inputProps={{ zIndex: 0 }}
            defaultValue={row.Valor.Valor}
            disabled={
              props.numeroEstadosPosteriores > 0 &&
              (props.permisoEditar || detallePermisoUsuarioActual.Update)
                ? false
                : true
            }
            onBlur={props.onChangeCampoPersonalizado(row.CodigoCampo)}
            inputRef={(el) => (refs.current[index] = el)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            //  onBlur={() => {
            //    //Esta función se manda a llamar cuando algún otro campo depende del cambio del campo actual.
            //    let { plantillaValores } = this.state;

            //    let campo = plantillaValores.find(
            //      (f) => f.CodigoCampo == row.CodigoCampo
            //    );

            //    this.verificarDependencias(campo);
            //  }}
          />
        );
        break;

      case 3:
        component = (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container spacing={0}>
              <TextField
                required={props.row.Requerido}
                id={props.row.Nombre}
                label={props.row.Nombre}
                type="date"
                style={{ width: "100%" }}
                disabled={
                  props.numeroEstadosPosteriores > 0 &&
                  (props.permisoEditar || detallePermisoUsuarioActual.Update)
                    ? false
                    : true
                }
                defaultValue={row.Valor.Valor}
                onBlur={props.onChangeCampoPersonalizado(row.CodigoCampo)}
                InputLabelProps={{
                  shrink: true,
                }}
                inputRef={(el) => (refs.current[index] = el)}
                onKeyDown={(e) => handleKeyDown(e, index)}
              />
            </Grid>
          </Grid>
        );
        break;
      case 4:
        component = (
          <TextField
            required={props.row.Requerido}
            id={`txt${props.row.CodigoCampo}`}
            label={props.row.Nombre}
            style={{ width: "100%" }}
            margin="normal"
            variant="outlined"
            placeholder={
              props.row.PlaceHolderIgualNombre == true
                ? props.row.Nombre
                : props.row.PlaceHolderText
            }
            inputProps={{ zIndex: 0 }}
            multiline
            rows="3"
            rowsMax={20}
            // margin="normal"
            // variant="outlined"
            // inputProps={{ zIndex: 0 }}
            disabled={
              props.numeroEstadosPosteriores > 0 &&
              (props.permisoEditar || detallePermisoUsuarioActual.Update)
                ? false
                : true
            }
            defaultValue={row.Valor.Valor}
            onBlur={props.onChangeCampoPersonalizado(row.CodigoCampo)}
            // onBlur={handleBlur(row.CodigoCampo, index)}
            inputRef={(el) => (refs.current[index] = el)}
            onKeyDown={(e) => handleKeyDown(e, index)}
          />
        );
        break;

      case 5:
        let find = camposPersonalizados.find(
          (f) => f.CodigoCampo == props.row.CodigoCampo
        );

        let selectedItems = [];
        if (find && find.Valor) {
          selectedItems = find.Valor;
        } else {
          selectedItems = [];
        }
        component = (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControl
                style={{ width: "100%" }}
                required={props.row.Requerido}
              >
                <InputLabel htmlFor="">{props.row.Nombre}</InputLabel>
                <Select
                  // multiple
                  name={"select" + props.row.Nombre}
                  placeholder={
                    props.row.PlaceHolderIgualNombre == true
                      ? props.row.Nombre
                      : props.row.PlaceHolderText
                  }
                  input={
                    <Input
                      name={"select" + props.row.Nombre}
                      id={"select" + props.row.Nombre}
                    />
                  }
                  disabled={
                    props.numeroEstadosPosteriores > 0 &&
                    (props.permisoEditar || detallePermisoUsuarioActual.Update)
                      ? false
                      : true
                  }
                  defaultValue={row.Valor.Valor}
                  onChange={props.onChangeCampoPersonalizado(row.CodigoCampo)}
                  inputRef={(el) => (refs.current[index] = el)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                >
                  {props.row.Items.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText />
              </FormControl>
            </Grid>
          </Grid>
        );
        break;

      case 6:
        component = (
          <React.Fragment>
            <FormControl
              style={{ width: "100%" }}
              required={props.row.Requerido}
            >
              <label>{props.row.Nombre}</label>
              {props.numeroEstadosPosteriores > 0 &&
              (props.permisoEditar || detallePermisoUsuarioActual.Create) ? (
                <DropzoneArea
                  dropzoneText={props.row.Nombre}
                  onChange={uploadFiles.bind(
                    this,
                    props.codigoAviso,
                    row,
                    props.recargarDatos,
                    iterarKey
                  )}
                  acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
                  maxFileSize={1073741824}
                  filesLimit={1}
                  key={iKey + row.CodigoCampo}
                  inputRef={(el) => (refs.current[index] = el)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                />
              ) : (
                <></>
              )}
            </FormControl>
            <div>
              {Array.isArray(props.row.Valor) ? (
                props.row.Valor.map((fila, i) => (
                  <Chip
                    icon={<ImageIcon />}
                    label={fila.FileName.substring(0, 20) + "..."}
                    color="primary"
                    onClick={() => {
                      // newTab(fila.Path);
                      if (!detallePermisoUsuarioActual.Read) {
                        return;
                      } else {
                        newTabAuth({
                          fileName: fila.FileName,
                          key: "IMAGE_REPOSITORY_AVISOS",
                        });
                      }
                    }}
                    onDelete={async () => {
                      if (
                        props.numeroEstadosPosteriores > 0 &&
                        (props.permisoEditar ||
                          detallePermisoUsuarioActual.Delete)
                      ) {
                        const response = await props.onPressInactivarCampoValor(
                          {
                            CodigoAviso: props.codigoAviso,
                            AvisoCampoValorMultimedia: {
                              CodigoAvisoCampoValorMultimedia:
                                fila.CodigoAvisoCampoValorMultimedia,
                              Activo: false,
                            },
                          }
                        );
                        props.recargarDatos();
                        //props.quitarDocAdjuntoDeEstado(props.row, fila);
                      } else {
                        toast.warn(
                          "No tienes permisos para eliminar esta imagen."
                        );
                        return;
                      }
                    }}
                    style={{ margin: 15 }}
                  />
                ))
              ) : (
                <></>
              )}
            </div>
          </React.Fragment>
        );
        break;
      case 7:
        component = (
          <FormControlLabel
            style={{ display: "flex", justifyContent: "flexStart" }}
            required={props.row.Requerido}
            control={
              <Checkbox
                required={props.row.Requerido}
                disabled={
                  props.numeroEstadosPosteriores > 0 &&
                  (props.permisoEditar || detallePermisoUsuarioActual.Update)
                    ? false
                    : true
                }
                checked={row.Valor.Valor}
                defaultValue={row.Valor.Valor}
                onChange={props.onChangeCampoPersonalizado(row.CodigoCampo)}
                value="activo"
                color="primary"
              />
            }
            label={props.row.Nombre}
            inputRef={(el) => (refs.current[index] = el)}
            onKeyDown={(e) => handleKeyDown(e, index)}
          />
        );
        break;

      case 8:
        component = (
          <React.Fragment>
            <FormControl style={{ width: "100%" }} required={row.Requerido}>
              <label>{props.row.Nombre}</label>
              {props.numeroEstadosPosteriores > 0 &&
              (props.permisoEditar || detallePermisoUsuarioActual.Create) ? (
                <DropzoneArea
                  dropzoneText={props.row.Nombre}
                  onChange={uploadFiles.bind(
                    this,
                    props.codigoAviso,
                    row,
                    props.recargarDatos,
                    iterarKey
                  )}
                  maxFileSize={1073741824}
                  acceptedFiles={[
                    "image/jpeg",
                    "image/png",
                    "image/bmp",
                    ".sql",
                    ".txt",
                    ".pdf",
                    ".doc",
                    ".docx",
                    ".xls",
                    ".xlsx",
                    ".csv",
                    ".ppt",
                    ".pptx",
                    ".pages",
                    ".odt",
                    ".rtf",
                    ".ai",
                    ".psd",
                    ".mp3",
                    ".mp4",
                    ".avi",
                    ".eps",
                    ".ait",
                    ".svg",
                    ".svgz",
                  ]}
                  filesLimit={1}
                  key={iKey + row.CodigoCampo}
                  inputRef={(el) => (refs.current[index] = el)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                />
              ) : (
                <></>
              )}
            </FormControl>
            <div>
              {Array.isArray(props.row.Valor) ? (
                props.row.Valor.map((fila, i) => (
                  <Chip
                    icon={<AttachFileIcon />}
                    label={fila.FileName.substring(0, 20) + "..."}
                    color="primary"
                    onClick={() => {
                      //newTab(fila.Path);
                      if (!detallePermisoUsuarioActual.Read) {
                        return;
                      } else {
                        newTabAuth({
                          fileName: fila.FileName,
                          key: "IMAGE_REPOSITORY_AVISOS",
                        });
                      }
                    }}
                    onDelete={async () => {
                      if (
                        props.numeroEstadosPosteriores > 0 &&
                        (props.permisoEditar ||
                          detallePermisoUsuarioActual.Delete)
                      ) {
                        const response = await props.onPressInactivarCampoValor(
                          {
                            CodigoAviso: props.codigoAviso,
                            AvisoCampoValorMultimedia: {
                              CodigoAvisoCampoValorMultimedia:
                                fila.CodigoAvisoCampoValorMultimedia,
                              Activo: false,
                            },
                          }
                        );
                        props.recargarDatos();
                        //props.quitarDocAdjuntoDeEstado(props.row, fila);
                      } else {
                        toast.warn(
                          "No tienes permisos para eliminar este archivo."
                        );
                        return;
                      }
                    }}
                    style={{ margin: 15 }}
                    inputRef={(el) => (refs.current[index] = el)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                  />
                ))
              ) : (
                <></>
              )}
            </div>
          </React.Fragment>
        );
        break;

      case 10: {
        let find = camposPersonalizados.find(
          (f) => f.CodigoCampo == props.row.CodigoCampo
        );

        let selectedItems = [];
        if (find && find.Valor) {
          selectedItems = find.Valor;
        } else {
          selectedItems = [];
        }

        let itemsCopy = [];
        props.row.Items.forEach((item, index) => {
          itemsCopy.push({ id: 0, name: item.name });
          item.children.forEach((hijo, i) => {
            itemsCopy.push({ id: hijo.id, name: hijo.name });
          });
        });
        console.log("itemscopy", itemsCopy);

        component = (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControl
                style={{ width: "100%" }}
                required={props.row.Requerido}
              >
                <InputLabel htmlFor="">{props.row.Nombre}</InputLabel>
                <Select
                  placeholder={
                    props.row.PlaceHolderIgualNombre == true
                      ? props.row.Nombre
                      : props.row.PlaceHolderText
                  }
                  // multiple
                  name={"select" + props.row.Nombre}
                  value={row.Valor.Valor}
                  //  onChange={this.onSelectedItemsCamposDinamicos(props.row)}
                  input={
                    <Input
                      name={"select" + props.row.Nombre}
                      id={"select" + props.row.Nombre}
                    />
                  }
                  disabled={
                    props.numeroEstadosPosteriores > 0 &&
                    (props.permisoEditar || detallePermisoUsuarioActual.Update)
                      ? false
                      : true
                  }
                  defaultValue={row.Valor.Valor}
                  onChange={props.onChangeCampoPersonalizado(row.CodigoCampo)}
                  inputRef={(el) => (refs.current[index] = el)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                >
                  {/* {props.row.Items.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))} */}
                  {itemsCopy.map((item) => (
                    <MenuItem
                      key={item.id}
                      value={item.id}
                      disabled={item.id === 0 ? true : false}
                      className={
                        item.id === 0 ? props.css.group : props.css.item
                      }
                      style={{ opacity: 1 }}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText />
              </FormControl>
            </Grid>
          </Grid>
        );
        break;
      }
      case 12: {
        let find = camposPersonalizados.find(
          (f) => f.CodigoCampo == props.row.CodigoCampo
        );

        let selectedItems = [];
        if (find && find.Valor) {
          selectedItems = find.Valor;
        } else {
          selectedItems = [];
        }
        let itemsCopy = [];
        props.row.Items.forEach((item, index) => {
          itemsCopy.push({ id: 0, name: item.name });
          item.children.forEach((hijo, i) => {
            itemsCopy.push({ id: hijo.id, name: hijo.name });
          });
        });
        console.log("itemscopy", itemsCopy);

        component = (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControl
                style={{ width: "100%" }}
                required={props.row.Requerido}
              >
                <InputLabel htmlFor="">{props.row.Nombre}</InputLabel>
                <Select
                  // multiple
                  placeholder={
                    props.row.PlaceHolderIgualNombre == true
                      ? props.row.Nombre
                      : props.row.PlaceHolderText
                  }
                  name={"select" + props.row.Nombre}
                  value={row.Valor.Valor}
                  input={
                    <Input
                      name={"select" + props.row.Nombre}
                      id={"select" + props.row.Nombre}
                    />
                  }
                  disabled={
                    props.numeroEstadosPosteriores > 0 &&
                    (props.permisoEditar || detallePermisoUsuarioActual.Update)
                      ? false
                      : true
                  }
                  defaultValue={row.Valor.Valor}
                  onChange={props.onChangeCampoPersonalizado(row.CodigoCampo)}
                  inputRef={(el) => (refs.current[index] = el)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                >
                  {/* {props.row.Items.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))} */}
                  {itemsCopy.map((item) => (
                    <MenuItem
                      key={item.id}
                      value={item.id}
                      disabled={item.id === 0 ? true : false}
                      className={
                        item.id === 0 ? props.css.group : props.css.item
                      }
                      style={{ opacity: 1 }}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText />
              </FormControl>
            </Grid>
          </Grid>
        );
        break;
      }
      case 13:
        component = (
          <TextField
            required={props.row.Requerido}
            id={`txt${props.row.CodigoCampo}`}
            label={props.row.Nombre}
            style={{ width: "100%" }}
            margin="normal"
            variant="outlined"
            placeholder={
              props.row.PlaceHolderIgualNombre == true
                ? props.row.Nombre
                : props.row.PlaceHolderText
            }
            inputProps={{ zIndex: 0 }}
            disabled={
              props.numeroEstadosPosteriores > 0 &&
              (props.permisoEditar || detallePermisoUsuarioActual.Update)
                ? false
                : true
            }
            defaultValue={row.Valor.Valor}
            onBlur={props.onChangeCampoPersonalizado(row.CodigoCampo)}
            inputRef={(el) => (refs.current[index] = el)}
            onKeyDown={(e) => handleKeyDown(e, index)}
          />
        );

        break;
      //Campo de tipo Hora
      case 14:
        component = (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel htmlFor="formatted-text-mask-input">
                {props.row.Nombre} (HH:mm)
              </InputLabel>
              <Input
                required={props.row.Required}
                id={props.row.CodigoCampo}
                inputComponent={TextMaskCustom}
                defaultValue={row.Valor.Valor}
                onBlur={props.onChangeCampoPersonalizado(row.CodigoCampo)}
                disabled={
                  props.numeroEstadosPosteriores > 0 &&
                  (props.permisoEditar || detallePermisoUsuarioActual.Update)
                    ? false
                    : true
                }
                inputRef={(el) => (refs.current[index] = el)}
                onKeyDown={(e) => handleKeyDown(e, index)}
              />
            </FormControl>
          </Grid>
        );
        break;
      //Campo de tipo Separador 01
      case 15:
        component = (
          // <Separador CodigoCampo={row.CodigoCampo} Nombre={row.Nombre} />
          <Divider style={{ marginTop: 10, marginBottom: 10 }} />
        );
        break;
      case 16: //Un titulo
        component = (
          <div className={props.css.inputField}>
            <Typography variant="h6" gutterBottom>
              {props.row.Nombre}
            </Typography>
          </div>
        );
        break;
      case 17: //Una descripción
        component = (
          <div className={props.css.inputField}>
            <Typography variant="body2" gutterBottom>
              {props.row.Nombre}
            </Typography>
          </div>
        );
        break;
      case 18: //Imagen fija
        component = (
          <div
            className={props.css.inputField}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <img
              alt="img"
              src={props.row.Valor.Valor}
              style={{ maxWidth: "100%" }}
            />
          </div>
        );
        break;
      case 19:
        {
          let fiind = camposPersonalizados.find(
            (f) => f.CodigoCampo == props.row.CodigoCampo
          );

          let selectedItems = [];
          if (fiind && fiind.Valor) {
            selectedItems = fiind.Valor;
          } else {
            selectedItems = [];
          }
          component = (
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl
                  style={{ width: "100%" }}
                  required={props.row.Requerido}
                >
                  <InputLabel htmlFor="">{props.row.Nombre}</InputLabel>
                  <Select
                    placeholder={
                      props.row.PlaceHolderIgualNombre == true
                        ? props.row.Nombre
                        : props.row.PlaceHolderText
                    }
                    multiple
                    name={"select" + props.row.Nombre}
                    input={
                      <Input
                        name={"select" + props.row.Nombre}
                        id={"select" + props.row.Nombre}
                      />
                    }
                    disabled={
                      props.numeroEstadosPosteriores > 0 &&
                      (props.permisoEditar ||
                        detallePermisoUsuarioActual.Update)
                        ? false
                        : true
                    }
                    defaultValue={row.Valor.Valor}
                    onChange={props.onChangeCampoPersonalizado(row.CodigoCampo)}
                    inputRef={(el) => (refs.current[index] = el)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                  >
                    {props.row.Items.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText />
                </FormControl>
              </Grid>
            </Grid>
          );
        }
        break;

      case 20:
        {
          let find = camposPersonalizados.find(
            (f) => f.CodigoCampo == props.row.CodigoCampo
          );

          let cols = [];
          if (find && find.Items) {
            cols = find.Items;
          } else {
            cols = [];
          }

          let filas = [];
          if (find && find.Filas) {
            filas = find.Filas;
          } else {
            filas = [];
          }
          console.log(filas);
          component = (
            <Grid container spacing={3}>
              <div>
                <strong>{row.Nombre}</strong>
              </div>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <DataGrid
                  columns={cols}
                  rows={filas}
                  codigoFormDeCampo={Number(props.row.ValorPorDefecto)}
                  codigoCampo={props.row.CodigoCampo}
                  codigoLocal={0} //no se va a necesitar porque se va a insertar directamente al server
                  grabarFila={grabarFila}
                  removerFila={removerFila}
                  incrementarCodigoLocal={() => {}} //no se va a necesitar porque se va a insertar directamente al server
                  codigoGrid={props.row.Valor} //codigoXCampovalor
                  editable={
                    props.numeroEstadosPosteriores > 0 &&
                    (props.permisoEditar || detallePermisoUsuarioActual.Update)
                      ? true
                      : false
                  }
                  inputRef={(el) => (refs.current[index] = el)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                />
              </Grid>
            </Grid>
          );
        }
        break;
      case 21:
        //Campo Calculado
        component = (
          <div className={props.css.flexRow}>
            <FunctionsIcon
              style={{ color: "rgb(33, 150, 243)", marginRight: 10 }}
            />
            <TextField
              required={props.row.Requerido}
              id={`txt${props.row.CodigoCampo}`}
              type="number"
              label={props.row.Nombre}
              style={{ width: "100%" }}
              margin="normal"
              variant="outlined"
              inputProps={{ zIndex: 0 }}
              defaultValue={row.Valor.Valor}
              disabled={true}
              inputRef={(el) => (refs.current[index] = el)}
              onKeyDown={(e) => handleKeyDown(e, index)}
            />
          </div>
        );
        break;
      case 22: {
        //Picklist API
        let find = camposPersonalizados.filter(
          (f) => f.CodigoCampo === props.row.CodigoCampo
        );

        let selectedItem = null;
        if (find && find.length && find[0].Valor) {
          selectedItem = { id: find[0].Valor.Valor, name: find[0].Valor.Valor };
        }

        component = (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControl
                style={{ width: "100%" }}
                required={props.row.Requerido}
              >
                <Autocomplete
                  name={"select" + props.row.Nombre}
                  id={"select" + props.row.Nombre}
                  options={[]}
                  getOptionLabel={(option) => option.name}
                  value={selectedItem}
                  onChange={(event, newValue) =>
                    props.onChangeCampoPersonalizadosAutocomplete(
                      event,
                      newValue,
                      props.row
                    )
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={props.row.Nombre}
                      variant="outlined"
                    />
                  )}
                  inputRef={(el) => (refs.current[index] = el)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                />
                <FormHelperText />
              </FormControl>
            </Grid>
          </Grid>
        );
        break;
      }
      case 23: {
        let find = camposPersonalizados.find(
          (f) => f.CodigoCampo == props.row.CodigoCampo
        );

        let selectedItems = [];
        if (find && find.Valor) {
          selectedItems = find.Valor;
        } else {
          selectedItems = [];
        }
        let Items = [];
        if (!(props.row.Items === undefined)) Items = props.row.Items;
        component = (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControl
                style={{ width: "100%" }}
                required={props.row.Requerido}
              >
                <InputLabel htmlFor="">{props.row.Nombre}</InputLabel>
                <Select
                  placeholder={
                    props.row.PlaceHolderIgualNombre == true
                      ? props.row.Nombre
                      : props.row.PlaceHolderText
                  }
                  // multiple
                  name={"select" + props.row.Nombre}
                  input={
                    <Input
                      name={"select" + props.row.Nombre}
                      id={"select" + props.row.Nombre}
                    />
                  }
                  disabled={
                    props.numeroEstadosPosteriores > 0 &&
                    (props.permisoEditar || detallePermisoUsuarioActual.Update)
                      ? false
                      : true
                  }
                  defaultValue={row.Valor.Valor}
                  onChange={props.onChangeCampoPersonalizado(row.CodigoCampo)}
                  inputRef={(el) => (refs.current[index] = el)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                >
                  {Items.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText />
              </FormControl>
            </Grid>
          </Grid>
        );
        break;
      }
      case 25: {
        component = (
          <div>
            <span>
              Campo de firma únicamente en móvil.
              {row.Nombre}
            </span>
          </div>
        );

        break;
      }
      default:
        component = (
          <div>
            <span>
              Este campo no esta disponible, por favor comuniquese con un
              administrador. Codigo de campo: {row.CodigoTipoCampo} Nombre:{" "}
              {row.Nombre}
            </span>
          </div>
        );
        break;
    }
    return component;
  }

  const classes = useStyles();

  return (
    <>
      {Array.isArray(camposPersonalizados) &&
        camposPersonalizados.length > 0 && (
          <div>
            <div class={classes.tituloPaper}>
              <div class={classes.gestionGeneral}>
                <Typography variant="h5" gutterBottom>
                  Formulario
                </Typography>
              </div>
            </div>

            <Paper className={classes.paper}>
              {camposPersonalizados !== null ? (
                <div className={classes.container}>
                  {camposPersonalizados.map((row, i) => (
                    <Grid
                      item
                      xs={12}
                      lg={12}
                      sm={12}
                      style={{ marginTop: 25 }}
                    >
                      <DibujarCampo
                        row={row}
                        index={i}
                        camposPersonalizados={props.camposPersonalizados}
                        codigoAviso={props.aviso.CodigoAviso}
                        recargarDatos={props.recargar}
                        onPressInactivarCampoValor={
                          props.onPressInactivarCampoValor
                        }
                        quitarDocAdjuntoDeEstado={
                          props.quitarDocAdjuntoDeEstado
                        }
                        permisoEditar={props.permisoEditar}
                        numeroEstadosPosteriores={
                          props.aviso.NumeroEstadosPosteriores
                        }
                        onChangeCampoPersonalizado={
                          props.onChangeCampoPersonalizado
                        }
                        onChangeCampoPersonalizadosAutocomplete={
                          props.onChangeCampoPersonalizadosAutocomplete
                        }
                        css={classes}
                      />
                    </Grid>
                  ))}
                </div>
              ) : (
                <div>
                  <CircularProgress />
                </div>
              )}
            </Paper>
          </div>
        )}
    </>
  );
}
// }
//const FormularioContainer = withStyles(useStyles)(Formulario);
export default withRouter(Formulario);
