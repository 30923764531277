import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, Typography } from "@material-ui/core";
import { isSignedIn } from "../../utils/auth";
import API from "../../utils/api";
import dayjs from "dayjs";

import EmpleadoDocumentosComponente from "../../perfil/components/empleado-documentos-componente";

const useStyles = makeStyles({
  card: {
    width: 400,
    margin: "20px",
  },
});

const ResumenEmpleadoMovimientoComponente = ({ movimiento }) => {
  const classes = useStyles();

  const [estadoEmpleado, setEstadoEmpleado] = useState(null);
  const [empleado, setEmpleado] = useState(null);
  const [documentos, setDocumentos] = useState([]);
  const [repositorio, setRepositorio] = useState(null);

  const obtenerEstadoEmpleado = async () => {
    try {
      const session = await isSignedIn();
      if (!session.response) {
        window.location.href = "/login";
        return;
      } else {
        // setUserName(session["userName"]);
      }

      let uri = "api/Empleado/Estado/" + movimiento.DPI;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        return false;
      } else {
        if (request.data.data) setEstadoEmpleado(request.data.data[0]);
        else setEstadoEmpleado(null);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const obtenerDatoEmpleado = async () => {
    try {
      const session = await isSignedIn();
      if (!session.response) {
        window.location.href = "/login";
        return;
      } else {
        // setUserName(session["userName"]);
      }

      let uri = "api/Empleado/Datos/" + movimiento.DPI;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        return false;
      } else {
        if (request.data.data) setEmpleado(request.data.data);
        else setEmpleado(null);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const obtenerDocumentoEmpleado = async () => {
    try {
      const session = await isSignedIn();
      if (!session.response) {
        window.location.href = "/login";
        return;
      } else {
        // setUserName(session["userName"]);
      }

      let uri = "api/Empleado/Documentos/" + movimiento.DPI;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        return false;
      } else {
        if (request.data.data) {
          setRepositorio(request.data.data2);
          setDocumentos(request.data.data);
        } else setRepositorio([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await obtenerEstadoEmpleado();
      await obtenerDatoEmpleado();
      await obtenerDocumentoEmpleado();
      console.log(movimiento);
    };
    fetchData();
  }, [movimiento]);

  const handleDownload = async (filename) => {
    // const baseAPi = API.baseApi();
    // const uri = "api/EmpleadoDocumento/File?filename=" + filename;
    // const url = baseAPi + uri;
    // const newWindow = window.open(url, "_blank");
    // if (newWindow) {
    //   newWindow.focus();
    // }
    try {
      const session = await isSignedIn();
      if (!session.response) {
        window.location.href = "/login";
        return;
      } else {
        // setUserName(session["userName"]);
      }

      const base_api = API.baseApi();
      const uri = "api/EmpleadoDocumento/File?filename=" + filename;
      const fetchEndpoint = `${base_api}${uri}`;

      const request = await fetch(fetchEndpoint, {
        headers: {
          Authorization: "bearer " + session.accessToken,
        },
      })
        .then((response) => {
          return response.blob();
        })
        .catch((error) => {
          console.log(error);
        });

      const fileExtension = filename.split(".").pop().toLowerCase();
      const viewableExtensions = ["pdf", "jpg", "jpeg", "png", "gif"];

      if (!viewableExtensions.includes(fileExtension)) {
        const url = window.URL.createObjectURL(new Blob([request]));
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        a.remove();
      } else {
        const mimeType =
          {
            pdf: "application/pdf",
            jpg: "image/jpeg",
            jpeg: "image/jpeg",
            png: "image/png",
            gif: "image/gif",
          }[fileExtension] || "application/octet-stream";

        const blob = new Blob([request], {
          type: mimeType,
        });
        const newUrl = window.URL.createObjectURL(blob);
        window.open(newUrl, "_blank", "fullscreen=yes");
      }
      // const request = await API.processResponse(query);

      // const request = await API.fetchGetRequest(uri, {responseType: 'blob'});

      // if (request.status !== 200) {
      //   return false;
      // } else {
      //   console.log(request);
      // const url = window.URL.createObjectURL(new Blob(request.blob()));
      // const fileExtension = filename.split(".").pop().toLowerCase();
      // console.log(fileExtension);
      // const viewableExtensions = ["pdf", "jpg", "jpeg", "png", "gif"];

      // if (viewableExtensions.includes(fileExtension)) {
      //   const mimeType =
      //     {
      //       pdf: "application/pdf",
      //       jpg: "image/jpeg",
      //       jpeg: "image/jpeg",
      //       png: "image/png",
      //       gif: "image/gif",
      //     }[fileExtension] || "application/octet-stream";

      //   const blob = new Blob([request.body], {
      //     type: mimeType,
      //   });
      //   const newUrl = window.URL.createObjectURL(blob);
      //   window.open(newUrl, "_blank", "fullscreen=yes");
      //   // window.open(url, '_blank');
      // } else {
      //   const a = document.createElement("a");
      //   a.href = url;
      //   a.download = filename;
      //   document.body.appendChild(a);
      //   a.click();
      //   a.remove();
      // }
      // }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div style={{ display: "flex" }}>
        <Card className={classes.card}>
          <CardContent>
            <Typography variant="h5" component="h2">
              <strong>Resumen del movimiento</strong>
            </Typography>
            <Typography color="textSecondary">
              <strong>Tipo Movimiento: </strong>{" "}
              {movimiento.TipoMovimientoPersonal}
            </Typography>
            <Typography color="textSecondary">
              <strong>Motivo Movimiento: </strong>{" "}
              {movimiento.MotivoMovimientoPersonal}
            </Typography>
            <Typography color="textSecondary">
              <strong>Estado Movimiento: </strong>{" "}
              {movimiento.EstadoMovimientoPersonal}
            </Typography>
            <Typography color="textSecondary">
              <strong>Fecha Ejecución: </strong>
              {dayjs(movimiento.FechaEjecucion).format("DD-MM-YYYY")}
            </Typography>
            <Typography color="textSecondary">
              <strong>Agrupación de Pago: </strong>{" "}
              {movimiento.AgrupacionDePago}
            </Typography>
            <Typography color="textSecondary">
              <strong>Centro de Pago: </strong> {movimiento.CentroDePago}
            </Typography>
            <Typography color="textSecondary">
              <strong>Gerencia: </strong> {movimiento.Gerencia}
            </Typography>
            <Typography color="textSecondary">
              <strong>Área: </strong> {movimiento.Area}
            </Typography>
            <Typography color="textSecondary">
              <strong>Departamento: </strong> {movimiento.Departamento}
            </Typography>
            <Typography color="textSecondary">
              <strong>Puesto Laboral: </strong> {movimiento.PuestoLaboral}
            </Typography>
          </CardContent>
        </Card>
        <Card className={classes.card}>
          <CardContent>
            <Typography variant="h5" component="h2">
              <strong>Estado del Empleado</strong>
            </Typography>
            <Typography color="textSecondary">
              <strong>Estado del Empleado:</strong>
              {estadoEmpleado
                ? estadoEmpleado.EstadoEmpleado
                : "No existe registro"}
            </Typography>
            <Typography color="textSecondary">
              <strong>Fecha Ultimo Estado:</strong>
              {estadoEmpleado
                ? dayjs(estadoEmpleado.FechaUltimoEstado).format("DD-MM-YYYY")
                : null}
            </Typography>
          </CardContent>
        </Card>
      </div>
      <div style={{ display: "flex" }}>
        <Card className={classes.card}>
          <CardContent>
            <Typography variant="h5" component="h2">
              <strong>Datos del Empleado</strong>
            </Typography>
            {empleado ? (
              <div>
                <Typography color="textSecondary">
                  <strong>DPI: </strong>
                  {empleado.DPI}
                </Typography>
                <Typography color="textSecondary">
                  <strong>NIT: </strong>
                  {empleado.NIT
                    ? empleado.NIT
                    : "Ingrese este dato en el sistema"}
                </Typography>
                <Typography color="textSecondary">
                  <strong>IGSS: </strong>
                  {empleado.IGSS
                    ? empleado.IGSS
                    : "Ingrese este dato en el sistema"}
                </Typography>
                <Typography color="textSecondary">
                  <strong>Primer Nombre: </strong>
                  {empleado.PrimerNombre}
                </Typography>
                <Typography color="textSecondary">
                  <strong>Segundo Nombre: </strong>
                  {empleado.SegundoNombre}
                </Typography>
                <Typography color="textSecondary">
                  <strong>Tercer Nombre: </strong>
                  {empleado.TercerNombre}
                </Typography>
                <Typography color="textSecondary">
                  <strong>Primer Apellido: </strong>
                  {empleado.PrimerApellido}
                </Typography>
                <Typography color="textSecondary">
                  <strong>Segundo Apellido: </strong>
                  {empleado.SegundoApellido}
                </Typography>
                <Typography color="textSecondary">
                  <strong>Apellido de Casada: </strong>
                  {empleado.ApellidoCasada}
                </Typography>
                <Typography color="textSecondary">
                  <strong>Fecha Nacimiento: </strong>
                  {dayjs(empleado.Nacimiento).format("DD-MM-YYYY")}
                </Typography>

                <Typography color="textSecondary">
                  <strong>Genero: </strong>
                  {empleado.Genero}
                </Typography>
                <Typography color="textSecondary">
                  <strong>Estado Civil: </strong>
                  {empleado.EstadoCivil}
                </Typography>
                <Typography color="textSecondary">
                  <strong>Etnia: </strong>
                  {empleado.Etnia}
                </Typography>
                <Typography color="textSecondary">
                  <strong>Cuenta Bancaria: </strong>
                  {empleado.NumeroCuenta}
                </Typography>
              </div>
            ) : null}
          </CardContent>
        </Card>
        <Card className={classes.card}>
          <CardContent>
            <Typography variant="h5" component="h2">
              <strong>Documentos del Empleado</strong>
            </Typography>
            {documentos.length > 0 ? (
              <div>
                <ul>
                  {documentos.map((documento) => (
                    <li
                      key={documento.recursoID}
                      style={{
                        border: "1px solid black",
                        padding: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <Typography color="textSecondary">
                        <strong>Nombre: </strong>
                        {documento.alias}
                      </Typography>
                      <Typography color="textSecondary">
                        <strong>Descripción: </strong>
                        {documento.nombre}
                      </Typography>

                      <Typography color="textSecondary">
                        <strong>Archivo: </strong>
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            handleDownload(documento.filename);
                          }}
                        >
                          Descargar
                        </a>
                      </Typography>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </CardContent>
        </Card>
        {/* <Card className={classes.card}>
          <EmpleadoDocumentosComponente DPI={movimiento.DPI} />
        </Card> */}
        {/* {<Card>
 <iframe src=”path_to_pdf_file.pdf” width=”100%” height=”500px” />
 </Card>} */}
      </div>
    </div>
  );
};

export default ResumenEmpleadoMovimientoComponente;
