import React, { useState } from 'react'
import {
  Button,
  TextField,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import { green, red } from '@material-ui/core/colors'
import LoadingButton from '../utils/LoadingButton'
import { getCodigoUsuario } from '../../utils/utilidades'
import api from '../../../utils/api'
import { toast } from 'react-toastify'

const ObsolescenciaAprobar = ({ solicitud, onRefresh, onClose }) => {
  const [justificacion, setJustificacion] = useState('')
  const [loading, setLoading] = useState(false)
  const submit = async (operacion) => {
    if (justificacion.trim() === '') {
      toast.error('La justificación es requerida')
      return
    }
    try {
      setLoading(true)
      const body = {
        IdSolicitud: solicitud.IdSolicitud,
        Justificacion: justificacion,
        CodigoUsuarioAutoriza: getCodigoUsuario(),
      }
      const url = operacion === 'aprobar'
        ? 'api/documentos/obsolescencia/aprobar-solicitud-obsolescencia'
        : 'api/documentos/obsolescencia/rechazar-solicitud-obsolescencia'
      await api.realizarPeticionPostPut(url, body, 'POST')
      onRefresh()
    } catch (error) {
      console.log(error)
      toast.error('Ha ocurrido un error')
    } finally {
      setLoading(false)
    }
  }
  return <>
    <DialogTitle>Aprobar obsolescencia de documento</DialogTitle>
    <DialogContent>
      <TextField
        fullWidth
        multiline
        label="Justificación"
        rows={3}
        value={justificacion}
        onChange={ev => setJustificacion(ev.target.value)}
      />
      <LoadingButton
        fullWidth
        variant="contained"
        loading={loading}
        style={{marginTop: 10, backgroundColor: green[500], color: 'white'}}
        onClick={() => submit("aprobar")}
      >Aprobar</LoadingButton>
      <LoadingButton
        fullWidth
        variant="contained"
        loading={loading}
        style={{marginTop: 10, backgroundColor: red[500], color: 'white'}}
        onClick={() => submit("rechazar")}
      >Rechazar</LoadingButton>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancelar</Button>
    </DialogActions>
  </>
}

export default ObsolescenciaAprobar
