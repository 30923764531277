import React, { useState } from "react";
import PropTypes from "prop-types";
import API from "../../utils/api";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import EditIcon from "@material-ui/icons/Edit";
import Chip from "@material-ui/core/Chip";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import NotificationsIcon from "@material-ui/icons/Notifications";
import NoteIcon from "@material-ui/icons/Note";
import AppsIcon from '@material-ui/icons/Apps';
import Checkbox from '@material-ui/core/Checkbox';
import SearchIcon from '@material-ui/icons/Search';

import IconButton from '@material-ui/core/IconButton';

//Own components
// import TableTitle from "../../helpers/components/table-title";
// import TableDescription from "../../helpers/components/table-description";
// import Link from "../../helpers/components/link";
// import ActionMenuObject from "./action-menu-objects";
import ConfirmDialog from "../../ConfirmDialog/ConfirmDialog";
import { Button } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
  table: {
    minWidth: "100%",
  },
});

function SimpleTable(props) {
  const [confirmTexto, setConfirmTexto] = useState("");
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [idObjetoEliminar, setIdObjetoEliminar] = useState("");
  const [llaveObjetoEliminar, setLlaveObjetoEliminar] = useState("");
  const [checkedB, setcheckedB ] = useState(false)
  const { classes, data } = props;

  const llaveTablaCarpeta = "AITCarpeta";
  const llaveTablaGestion = "AITProblema";

  const editar = (idObjeto, llave) => {
    props.editar(idObjeto, llave);
  };

  const irConfiguracion = (idObjeto, llave) => {
    props.irConfiguracion(idObjeto, llave);
  };

  const nuevaInstancia = (idObjeto, llave) => {
    props.nuevaInstancia(idObjeto, llave);
  };

  const verCausas = (idObjeto, llave) => {
    props.verCausas(idObjeto, llave);
  };

  const clonar = (idObjeto, llave) => {
    props.clonar(idObjeto, llave);
  };

  const permisos = (idObjeto, llave) => {
    props.permisos(idObjeto, llave);
  };

  const moverA =  (idObjeto, llave) => {
    props.moverA(idObjeto, llave);
  };

  const confirmarAccion = (id, llave) => {
    setConfirmTexto(
      `¿Confirma que desea eliminar este registro? esta acción no podrá revertirse.`
    );
    setConfirmOpen(true);
    setIdObjetoEliminar(id);
    setLlaveObjetoEliminar(llave);
  };

  const funcionOk = () => {
    eliminar();
  };

  const cerrarConfirm = () => {
    setConfirmTexto("");
    setConfirmOpen(false);
    setIdObjetoEliminar("");
  };

  const eliminar = async () => {
    await props.eliminar(idObjetoEliminar, llaveObjetoEliminar);
    setIdObjetoEliminar("");
    setLlaveObjetoEliminar("");
  };
  
  
  

  const iconType = (llave) => {
    let icono;
    switch (llave) {
      case llaveTablaCarpeta:
        icono = <FolderOpenIcon />;
        break;
      case llaveTablaGestion:
        icono = <AppsIcon />;
        break;
      
    }

    return icono;
  };

  return (
    <React.Fragment>
      <Paper className={classes.root}>
        <Table className={classes.table}>
          {/* <TableHead>
          <TableRow>
            <TableCell style={{ width: "80%" }}>Nombre</TableCell>
 
            <TableCell style={{ width: "20%" }}>
              
            </TableCell>
            
          </TableRow>
        </TableHead> */}
          <TableBody>
            {data.map((row, i) => (
              <TableRow>
                
                
                <TableCell
                  key={row.IdObjeto}
                  onClick={async () => {
                    if (row.Llave === llaveTablaCarpeta) {
                      props.navegar(row.Codigo, row.Nombre, row.Llave);
                    } else if (row.Llave === llaveTablaGestion) {
                      props.elegirTipoGestion(row.Codigo, row.Llave);
                      // window.location.href=`/superfiltro/${row.Codigo}`
                    }
                  }}
                  style={{ cursor: "pointer", width: "5%" }} 
                >
                  
                  {iconType(row.Llave)}
                </TableCell>

                <TableCell
                  style={{ width: "70%" }}
                  component="th"
                  scope="row"
                  key={i}
                  onClick={() => {
                    if (row.Llave === llaveTablaCarpeta) {
                      props.navegar(row.Codigo, row.Nombre, row.Llave);
                    } else if (row.Llave === llaveTablaGestion) {
                      props.elegirTipoGestion(row.Codigo, row.Llave);
                      // window.location.href=`/superfiltro/${row.Codigo}`
                    }
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {row.Nombre}
                </TableCell>
                {/* <TableCell
                  component="th"
                  scope="row"
                  style={{
                    textAlign: "right",
                    width: "25%",
                  }}
                >
                  <ActionMenuObject
                    idObjeto={row.Codigo}
                    idObjetoPadre={row.IdObjetoPadre}
                    llave={row.Llave}
                    editar={editar}
                    eliminar={confirmarAccion}
                    irConfiguracion={irConfiguracion}
                    nuevaInstancia={nuevaInstancia}
                    verCausas={verCausas}
                    clonar={clonar}
                    permisos={permisos}
                    moverA = {moverA}
                  />
                </TableCell> */}
                
                <TableCell
                  style={{ width: "70%" }}
                  component="th"
                  scope="row"
                  key={i}
                  style={{ cursor: "pointer" }}
                >
                  {(row.Llave=== llaveTablaCarpeta)&& <IconButton 
                    color="primary"
                    onClick={()=>props.listarProblemasCarpeta(row)}>
                    <SearchIcon />
                  </IconButton> }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <ConfirmDialog
        id="confirmar"
        keepMounted
        open={confirmOpen}
        onClose={cerrarConfirm}
        value=""
        texto={confirmTexto}
        okfunction={funcionOk}
      />
    </React.Fragment>
  );
}

export default withStyles(styles)(SimpleTable);
