import React from "react";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  TextField,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import ColorPicker from "material-ui-color-picker";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";

const FormularioEvento = ({ values, onChangeValue, guardar, loading }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} lg={12} sm={12}>
        <TextField
          id="Titulo"
          label="Título"
          required
          style={{ width: "100%" }}
          value={values.Titulo}
          defaultValue={values.Titulo}
          margin="normal"
          variant="outlined"
          inputProps={{ zIndex: 0 }}
          onChange={onChangeValue("Titulo", 1)}
        />
      </Grid>
      <Grid item xs={12} lg={12} sm={12}>
        <TextField
          id="Descripcion"
          label="Descripción"
          required
          style={{ width: "100%" }}
          value={values.Descripcion}
          defaultValue={values.Descripcion}
          margin="normal"
          variant="outlined"
          inputProps={{ zIndex: 0 }}
          onChange={onChangeValue("Descripcion", 1)}
          multiline
          rows="3"
        />
      </Grid>
      <Grid item xs={12} lg={12} sm={12}>
        <ColorPicker
          id="Color"
          name="Color"
          label="Color del evento"
          required
          style={{ width: "100%" }}
          defaultValue={values.Color}
          value={values.Color}
          margin="normal"
          variant="outlined"
          onChange={onChangeValue("Color")}
          inputProps={{ zIndex: 0, value: values.Color }}
          // InputProps={{ zIndex: 0, value: values.Color }}
        />
      </Grid>
      <Grid item xs={12} lg={12} sm={12}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
          <KeyboardDatePicker
            id="Fecha"
            label="Fecha"
            required
            style={{ width: "100%" }}
            value={values.Fecha ? new Date(values.Fecha) : null}
            margin="normal"
            variant="outlined"
            inputVariant="outlined"
            inputProps={{ zIndex: 0 }}
            onChange={onChangeValue("Fecha", 2)}
            fullWidth
            disableToolbar
            format="dd/MM/yyyy"
            clearable
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={12} lg={12} sm={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={values.TodoElDia}
              onChange={onChangeValue("TodoElDia", 4)}
              name="TodoElDia"
            />
          }
          label="Evento todo el día"
        />
      </Grid>
      {!values.TodoElDia && (
        <Grid item xs={12} lg={12} sm={12}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <KeyboardTimePicker
              id="HoraInicio"
              label="Hora de inicio"
              required
              style={{ width: "100%" }}
              value={values.HoraInicio}
              margin="normal"
              variant="outlined"
              inputVariant="outlined"
              inputProps={{ zIndex: 0 }}
              onChange={onChangeValue("HoraInicio", 3)}
              placeholder="H:MM AM"
              mask="__:__ _M"
            />
          </MuiPickersUtilsProvider>
        </Grid>
      )}
      {!values.TodoElDia && (
        <Grid item xs={12} lg={12} sm={12}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <KeyboardTimePicker
              id="HoraFin"
              label="Hora de fin"
              required
              style={{ width: "100%" }}
              value={values.HoraFin}
              margin="normal"
              variant="outlined"
              inputVariant="outlined"
              inputProps={{ zIndex: 0 }}
              onChange={onChangeValue("HoraFin", 3)}
              placeholder="H:MM AM"
              mask="__:__ _M"
            />
          </MuiPickersUtilsProvider>
        </Grid>
      )}
      <Grid item xs={12} lg={12} sm={12}>
        {/* <Button
                    disabled={loading}
                    variant="contained"
                    style={{ backgroundColor: "#69bd4b", color: "white" }}
                    onClick={guardar}
                >
                    {loading ? <CircularProgress size={25} color='inherit' /> : 'Guardar'}
                </Button> */}
      </Grid>
      {/* <Grid container spacing={3}> */}
      {/* </Grid> */}
    </Grid>
  );
};

export default FormularioEvento;
