import { Dialog, DialogContent } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import DataGrid, { Column, Export, FilterRow, HeaderFilter, MasterDetail, OperationDescriptions, SearchPanel, Selection } from "devextreme-react/data-grid";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import API from "../../utils/api";
import MapaLeaflet from "./mapaLeaflet";



const AsistenciasResumidasGrid = ({ FechaInicio, FechaFin, TipoGestion }) => {
    const [asistencias, setAsistencias] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedFoto, setSelectedFoto] = useState(null);
    const [tieneFotos, setTieneFotos] = useState(false);
    const [necesitaUbicaciones, setNecesitaUbicaciones] = useState(false);

    const handleFotoClick = (foto) => {
        setSelectedFoto(foto);
    };

    const handleFotoDialogClose = () => {
        setSelectedFoto(null);
    };

    const data = [
        { location: '14.10813, -90.94152' }
    ]

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            let uri = `api/asistencia/getAsistenciasResumido?inicio=${FechaInicio}&fin=${FechaFin}&tipoGestion=${TipoGestion}`;
            const request = await API.fetchGetRequest(uri);
            setAsistencias(request.data.data.AsistenciasResumidas);
            setNecesitaUbicaciones(true);
            setTieneFotos(request.data.data.TieneFotos);
            setLoading(false);
        }
        fetchData();
    }, [FechaInicio, FechaFin, TipoGestion]);

    useEffect(() => {
        //Obtenemos ubicación de cada asistente, y lo añadimos al arreglo de asistencias
        const fetchData = async () => {
            let uri = `api/asistencia/getUbicacionesConsulta?inicio=${FechaInicio}&fin=${FechaFin}&aviso=${"0"}&tipoGestion=${TipoGestion}`;
            const request = await API.fetchGetRequest(uri);
            if (request.data.response) {
                return request.data.data;
            }
            else {
                toast.error('Error al obtener ubicaciones');
                console.log(request.data.mensaje);
                return null;
            }
        }

        console.log('Se ejecuta effect');
        if (asistencias.length === 0 || !necesitaUbicaciones) {
            setNecesitaUbicaciones(false);
            return;
        }
        try {
            let toastId = toast.info(
                <div>
                    <span>Obteniendo ubicaciones...</span> <CircularProgress size={'1.0rem'} style={{ marginBottom: "0px" }} />
                </div>
                , { autoClose: false });
            fetchData().then((datos) => {
                if (datos !== null) {
                    const asistenciasActualizadas = asistencias.map((asistenciaResumen) => {
                        const nuevosAsistentes = asistenciaResumen.Asistencias.map((asistencia) => {
                            const ubicacion = datos.find((ubicacion) => ubicacion.Id === asistencia.Id);
                            if (ubicacion && ubicacion.Encontrado) {
                                return { ...asistencia, ["Ubicacion"]: ubicacion.Finca };
                            } else {
                                return { ...asistencia, ["Ubicacion"]: "-" };
                            }
                        });
                        asistenciaResumen.Asistencias = nuevosAsistentes;
                        return asistenciaResumen;
                    });
                    setAsistencias(asistenciasActualizadas);
                }
                toast.dismiss(toastId);
            });
        } catch (error) {
            toast.dismiss();
            console.log(error);
            toast.error('Error al obtener ubicaciones');
        }
        setNecesitaUbicaciones(false);
    }, [necesitaUbicaciones]);

    const cellRender = (data) => {
        const foto = data.value;
        if (!foto || foto === "") {
            return '-';
        }
        return (
            <div onClick={() => handleFotoClick(foto)} style={{ cursor: "pointer" }}>
                <img src={foto} alt="Empleado" style={{ width: "50px" }} />
            </div>
        );
    };


    const renderizarAsistencias = (props) => {
        const { Asistencias } = props.data.data;
        return (
            <React.Fragment>
                <DataGrid
                    dataSource={Asistencias}
                    showBorders={true}
                    showRowLines={true}
                    showColumnLines={true}
                >

                    <Column dataField="FechaHora" caption="Fecha/Hora" alignment={'center'} />
                    <Column
                        allowSorting={false}
                        allowExporting={false}
                        dataField="Foto"
                        alignment={'center'}
                        caption="Fotografía"
                        cellRender={cellRender}
                        visible={tieneFotos}
                    />
                    <Column dataField="Ubicacion" caption="Ubicación" alignment={'center'} />
                </DataGrid>
                <MapaLeaflet coordenadas={props.data.data.Ubicaciones} indiceMapa={Asistencias[0].Id} />
            </React.Fragment>
        );
    }

    const selectionChanged = (e) => {
        e.component.collapseAll(-1);
        e.component.expandRow(e.currentSelectedRowKeys[0]);
    }


    return (
        <React.Fragment>
            {loading ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress style={{ marginBottom: "20px" }} />
                    <br />
                    <br />
                </div>
            ) : (
                <React.Fragment>
                    <DataGrid
                        dataSource={asistencias}
                        showBorders={true}
                        onSelectionChanged={selectionChanged}
                    >
                        <FilterRow visible={true}>
                            <OperationDescriptions
                                between="Entre"
                                contains="Contiene"
                                endsWith="Termina con"
                                equal="Igual"
                                greaterThan="Mayor que"
                                greaterThanOrEqual="Mayor o igual que"
                                lessThan="Menor que"
                                lessThanOrEqual="Menor o igual que"
                                notContains="No contiene"
                                notEqual="No igual"
                                startsWith="Empieza con" />
                        </FilterRow>
                        <HeaderFilter visible={true} />
                        <SearchPanel visible={true}
                            width={240}
                            placeholder="Buscar..." />
                        <Selection mode="single" />
                        <Column dataField="CodigoEmpleado" caption="Código" />
                        <Column dataField="NombreEmpleado" caption="Nombre" />
                        <Column dataField="NumeroAsistencias" caption="# Asistencias" alignment={'left'} />

                        <MasterDetail enabled={true} component={renderizarAsistencias} />
                        <Export enabled={true} fileName="AsistenciasResumido" allowExportSelectedData={false} />
                    </DataGrid>
                    {selectedFoto && (
                        <Dialog open={true} onClose={handleFotoDialogClose}>
                            <DialogContent style={{ display: "flex" }}>
                                <img src={selectedFoto} alt="Empleado" style={{ maxHeight: "100%", objectFit: 'contain' }} />
                            </DialogContent>
                        </Dialog>
                    )}
                </React.Fragment>
            )}
        </React.Fragment >
    );
};

export default AsistenciasResumidasGrid;