import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Checkbox,
  FormControlLabel,
  Divider,
  Paper,
  Tooltip,
  Modal,
  Fade,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
} from "@material-ui/core";

import BackdropModal from "@material-ui/core/Backdrop";

import clsx from "clsx";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import {
  ChromeReaderMode,
  Edit,
  People,
  AttachFile,
  Cached,
  Delete,
  Label,
  ViewList,
  Add,
} from "@material-ui/icons";

import { green } from "@material-ui/core/colors";
import { NavLink } from "react-router-dom";

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import DataGrid, {
  Column,
  Pager,
  Paging,
  Sorting,
  ColumnFixing,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Grouping,
  GroupPanel,
  Export,
  Selection,
  Editing,
  Scrolling,
} from "devextreme-react/data-grid";

import API from "../../utils/api";
import { isSignedIn, signOut } from "../../utils/auth";
import { validaPermisoPaginaActual } from "../../utils/helpers";
import { ToastContainer, toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import "devextreme/dist/css/dx.material.teal.light.compact.css";

const width_proportion = "100%";
const titleColor = "#2B3C4D";

const useStyles = makeStyles((theme) => ({
  table: {
    width: "100%",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 210,
  },
  textFieldDireccion: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 230,
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  greenAvatar: {
    fontSize: "2.5em",
    color: "#fff",
    // borderStyle:'solid',
    // borderWidth:'1 !important',
    // borderColor:'#3f51b5 !important',
    backgroundColor: green[500],
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  paperCard: {
    padding: 20,
    width: "100%",
  },
  formControlSelects: {
    // margin: theme.spacing(1),
    // minWidth: 230,

    marginBottom: 10,
    marginTop: 10,
    width: "100%",
    marginLeft: 30,
  },
  formControlSelect: {
    // margin: theme.spacing(1),
    width: width_proportion,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  rootTabla: {
    width: "100%",
    marginTop: theme.spacing.unit * 1,
    overflowX: "auto",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paperModal: {
    position: "absolute",
    width: "70%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const EquipoMantenimiento = () => {
  const classes = useStyles();
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors: fieldsErrors },
    register,
  } = useForm();

  let applyFilterTypes = [
    {
      key: "auto",
      name: "Immediately",
    },
    {
      key: "onClick",
      name: "On Button Click",
    },
  ];

  const [codigoEquipo, setCodigoEquipo] = useState("");
  const [nombreEquipo, setNombreEquipo] = useState("");
  const [serviceTag, setServiceTag] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [numeroSerie, setNumeroSerie] = useState("");
  const [modelo, setModelo] = useState("");
  const [numeroVersion, setNumeroVersion] = useState("");
  const [fechaCreacion, setFechaCreacion] = useState(new Date());
  const [codigoTipoEquipo, setCodigoTipoEquipo] = useState(0);
  const [codigoUbicacionEquipo, setCodigoUbicacionEquipo] = useState(0);
  const [marca, setMarca] = useState("");

  const [servicePack, setServicePack] = useState("");
  const [userName, setUserName] = useState("");
  const [open, setOpen] = useState(false);
  const [cargandoMaestros, setCargandoMaestros] = useState(false);
  const [puestoLaboral, setPuestoLaboral] = useState([]);
  const [equipoMantenimiento, setEquipoMantenimiento] = useState([]);
  const [tiposEquipo, setTiposEquipo] = useState([]);
  const [maestroUbicacionEquipo, setMaestroUbicacionEquipo] = useState([]);

  const [valueAutoCompleteTipoEquipo, setValueAutoCompleteTipoEquipo] =
    useState(null);

  const [
    valueAutoCompleteUbicacionEquipo,
    setValueAutoCompleteUbicacionEquipo,
  ] = useState(null);
  const [codigoPuestoLaboral, setCodigoPuestoLaboral] = useState(0);
  const [codigoNivelPuestoLaboral, setCodigoNivelPuestoLaboral] = useState(0);
  const [puestoLaboralNombre, setPuestoLaboralNombre] = useState("");

  const [
    valueAutoCompleteNivelPuestoLaboral,
    setValueAutoCompleteNivelPuestoLaboral,
  ] = useState(null);

  const [positionDisableSorting, setPositionDisableSorting] = useState(false);
  const [showFilterRow, setShowFilterRow] = useState(true);
  const [showHeaderFilter, setShowHeaderFilter] = useState(true);
  const [currentFilter, setCurrentFilter] = useState(applyFilterTypes[0].key);
  const [autoExpandAll, setAutoExpandAll] = useState(true);

  const [buscando, setBackDrop] = useState(false);

  const handleCerrarBackDrop = () => {
    setBackDrop(false);
  };
  const handleAbrirBackDrop = () => {
    setBackDrop(true);
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  useEffect(() => {
    const cargar = async () => {
      // CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
      const llaveAgrupacion = "Menu.Principal.Web";
      const llavePagina = "Menu.Principal.Web.MantenimientoPreventivo";
      await validaPermisoPaginaActual(llaveAgrupacion, llavePagina);
      // FIN DE CONSULTA DE PERMISOS

      handleAbrirBackDrop();

      await cargarMaestroEquipoMantenimiento();
      await cargarMaestroUbicacionEquipo();
      await cargarTiposDeEquipos();

      handleCerrarBackDrop();
    };

    cargar();
  }, [cargandoMaestros]);

  const cargarMaestroEquipoMantenimiento = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/EquipoMantenimiento/";

      const request = await API.fetchGetRequest(uri);
      console.log(request.data.equipos);
      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setEquipoMantenimiento(request.data.equipos);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cargarTiposDeEquipos = async () => {
    try {
      let uri = "api/EquipoMantenimiento/TipoEquipo";

      const request = await API.fetchGetRequest(uri);
      console.log(request.data.tiposEquipo);
      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setTiposEquipo(request.data.tiposEquipo);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const cargarMaestroUbicacionEquipo = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/EquipoMantenimiento/UbicacionEquipo";

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setMaestroUbicacionEquipo(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const guardarDato = async () => {
    const data = {
      CodigoEquipo: codigoEquipo,
      Nombre: nombreEquipo,
      ServiceTag: serviceTag,
      Descripcion: descripcion,
      NumeroSerie: numeroSerie,
      Modelo: modelo,
      Marca: marca,
      NumeroVersion: numeroVersion,
      FechaCreacion: fechaCreacion,
      ServicePack: servicePack,
      // CodigoPuestoLaboral: codigoPuestoLaboral,
      // CodigoNivelPuestoLaboral: codigoNivelPuestoLaboral,
      // PuestoLaboral: puestoLaboralNombre,
      // NumeroVersion: numeroVersion,
      // FechaCreacion: fechaCreacion,
      Activo: true,
      Eliminado: false,
      UsuarioInserto: userName,
      FechaInserto: fechaCreacion,
      CodigoTipoEquipo: codigoTipoEquipo,
      CodigoUbicacionEquipo: codigoUbicacionEquipo,
      UsuarioModifico: "",
      FechaModifico: "",
    };

    console.log(data);
    // dataFicha.usuarioInserto = "prueba";
    try {
      handleAbrirBackDrop();
      let vIsSignedIn = await isSignedIn();
      if (vIsSignedIn.response) {
        let request;

        request = await API.post("api/EquipoMantenimiento/", data);
        if (request.status !== 200) {
          toast.error(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        } else {
          toast.success("Se guardó exitosamente");

          await cargarMaestroEquipoMantenimiento();

          // setPuestoLaboralNombre("");
          //setNumeroVersion("");
          setFechaCreacion(new Date());
          // setCodigoPuestoLaboral(0);
          // setCodigoNivelPuestoLaboral(0);

          //setValueAutoCompleteNivelPuestoLaboral(null);
        }
      }
    } catch (e) {
      console.log("EXCEPTION: " + e);
      toast.error(
        "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
      );
    } finally {
      handleCerrarBackDrop();
      handleCloseModal();
    }
  };

  const cuandoHayError = async (data) => {
    toast.info(
      "Existen algunos campos con error, por favor valida el formulario",
      {
        position: "top-right",
        autoClose: 3000, // Esta en milisegundos 3 segundos
      }
    );
  };

  const handleDateChangeFechaCreacion = (date) => {
    setFechaCreacion(date);
  };

  const onExporting = async (e) => {
    // const workbook = new Workbook();
    // const worksheet = workbook.addWorksheet("Main sheet");
    // exportDataGrid({
    //   component: e.component,
    //   worksheet: worksheet,
    // }).then(function () {
    //   workbook.xlsx.writeBuffer().then(function (buffer) {
    //     saveAs(
    //       new Blob([buffer], { type: "application/octet-stream" }),
    //       "DataGrid.xlsx"
    //     );
    //   });
    // });
    // e.cancel = true;
  };

  const [dialogoAbierto, abrirDialogo] = useState(false);
  const [dialogoAbiertoEditar, abrirDialogoEditar] = useState(false);
  const handleAbrirDialogoEliminar = (id) => {
    abrirDialogo(true);

    setCodigoEquipo(id);
  };

  const handleCerrarDialogo = () => {
    abrirDialogo(false);
  };

  const handleAbrirDialogoEditar = (id) => {
    abrirDialogoEditar(true);

    setCodigoEquipo(id);
  };

  const handleCerrarDialogoEditar = () => {
    abrirDialogoEditar(false);
  };

  const handleOnChangeTipoEquipo = async (event, newValue) => {
    handleAbrirBackDrop();
    if (newValue !== null) {
      setCodigoTipoEquipo(newValue.CodigoTipoEquipo);
      setValueAutoCompleteTipoEquipo(newValue);
    }
    handleCerrarBackDrop();
  };

  const handleOnChangeUbicacionEquipo = async (event, newValue) => {
    handleAbrirBackDrop();
    if (newValue !== null) {
      setCodigoUbicacionEquipo(newValue.CodigoUbicacionEquipo);
      setValueAutoCompleteUbicacionEquipo(newValue);
    }
    handleCerrarBackDrop();
  };

  const quitarEquipo = async (pId) => {
    handleCerrarDialogo();
    handleAbrirBackDrop();

    // const proyectosExcluidos = puestoLaboral.filter(
    //   (elemento) => elemento.CodigoPuestoLaboral !== pId
    // );

    console.log("Quitar" + pId);

    try {
      let uri = "api/EquipoMantenimiento/quitar/" + pId;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        toast.error(
          "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
        );
      } else {
        toast.success("Se quitó el registro con éxito", {
          position: "bottom-right",
          autoClose: 3000, // Esta en milisegundos 3 segundos
        });

        await cargarMaestroEquipoMantenimiento();
        // setPuestoLaboral("");
        // setNumeroVersion("");
        // setFechaCreacion(new Date());
        // setCodigoPuestoLaboral(0);
        // setCodigoNivelPuestoLaboral(0);

        // setValueAutoCompleteNivelPuestoLaboral(null);
      }
    } catch (error) {
      console.log("Error en  eliminación de proyectos" + error);
    }

    handleCerrarBackDrop();

    //alert("Quitar recurso del proyecto" + codigoRecurso);
  };

  const editarProyecto = async (pId) => {
    handleCerrarDialogoEditar();
    handleAbrirBackDrop();

    const equipos = equipoMantenimiento.filter(
      (elemento) => elemento.CodigoEquipo === pId
    );
    const proyectosExcluidos = equipoMantenimiento.filter(
      (elemento) => elemento.CodigoEquipo === pId
    );

    console.log("Editar el proyecto" + equipos);

    try {
      handleOpenModal();
      // setCodigoPuestoLaboral(pId);
      // setPuestoLaboralNombre(equipos[0].PuestoLaboral);
      // setNumeroVersion(proyectosExcluidos[0].NumeroVersion);
      // setFechaCreacion(proyectosExcluidos[0].FechaCreacion);
      // setCodigoNivelPuestoLaboral(
      //   proyectosExcluidos[0].CodigoNivelPuestoLaboral
      // );

      setCodigoEquipo(pId);
      setNombreEquipo(equipos[0].Nombre);
      setServiceTag(equipos[0].ServiceTag);
      setDescripcion(equipos[0].Descripcion);
      setNumeroSerie(equipos[0].NumeroSerie);
      setNumeroVersion(proyectosExcluidos[0].NumeroVersion);
      setModelo(equipos[0].Modelo);
      setCodigoTipoEquipo(equipos[0].CodigoTipoEquipo);

      // const itemNivelPuestoLaboral = maestroNivelPuestoLaboral.filter(
      //   (elemento) =>
      //     elemento.CodigoNivelPuestoLaboral ===
      //     proyectosExcluidos[0].CodigoNivelPuestoLaboral
      // );
      // setValueAutoCompleteNivelPuestoLaboral(itemNivelPuestoLaboral[0]);
    } catch (error) {
      console.log("Error en  edición de proyectos" + error);
    }

    handleCerrarBackDrop();

    //alert("Quitar recurso del proyecto" + codigoRecurso);
  };

  const cellOpciones = (rowInfo) => {
    return (
      <>
        {/* <Tooltip
          title="Descriptor de Proyectos"
          style={{ marginRight: 5, cursor: "pointer" }}
        >
          <NavLink
            onClick={(e) => {
              if (false) {
                toast.warning(
                  "No tienes permisos para gestionar los anexos el proyecto",
                  {
                    position: "top-right",
                    pauseOnHover: false,
                    autoClose: 3000,
                  }
                );
                e.preventDefault();
              }
              //     props.history.push(`/proyecto-editar/${rowInfo.data.id}`);
            }}
            to={`/puesto-laboral-descriptor/${rowInfo.data.CodigoPuestoLaboral}`}
          >
            <ViewList className={classes.sizeIcons} />
          </NavLink>
        </Tooltip> */}
        <Tooltip title="Editar" style={{ marginRight: 10, cursor: "pointer" }}>
          <Edit
            style={{ color: "#337ab7", cursor: "pointer" }}
            onClick={() => handleAbrirDialogoEditar(rowInfo.data.CodigoEquipo)}
          />
        </Tooltip>
        <Tooltip
          title="Eliminar"
          style={{ marginRight: 10, cursor: "pointer" }}
        >
          <Delete
            style={{ color: "#337ab7", cursor: "pointer" }}
            onClick={() =>
              handleAbrirDialogoEliminar(rowInfo.data.CodigoEquipo)
            }
          />
        </Tooltip>
      </>
    );
  };

  // const handleOnChangeTipoParentesco = async (event, newValue) => {
  //   handleAbrirBackDrop();
  //   if (newValue !== null) {
  //     setCodigoNivelPuestoLaboral(newValue.CodigoNivelPuestoLaboral);
  //     setValueAutoCompleteNivelPuestoLaboral(newValue);
  //   }
  //   handleCerrarBackDrop();
  // };

  return (
    <React.Fragment>
      <Backdrop className={classes.backdrop} open={buscando}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer />

      <Dialog
        open={dialogoAbierto}
        onClose={handleCerrarDialogo}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Eliminación</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro que deseas eliminar este registro?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCerrarDialogo} color="primary">
            Cancelar
          </Button>
          <Button onClick={() => quitarEquipo(codigoEquipo)} color="primary">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={dialogoAbiertoEditar}
        onClose={handleCerrarDialogoEditar}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Edición</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro que deseas editar este registro?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCerrarDialogoEditar} color="primary">
            Cancelar
          </Button>
          <Button onClick={() => editarProyecto(codigoEquipo)} color="primary">
            Editar
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={BackdropModal}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paperModal}>
            <h2 id="simple-modal-title">Datos de Equipo</h2>
            <Grid container spacing={3}>
              <form
                autoComplete="off"
                onSubmit={handleSubmit(guardarDato, cuandoHayError)}
              >
                {/* <Grid item xs={6} sm={6} md={12} lg={12}>
                  <FormControl className={classes.formControlSelects}>
                    <Controller
                      name="tipoEquipo"
                      required
                      render={({ TipoEquipo }) => (
                        <Autocomplete
                          id="TipoEquipo"
                          options={['1-Computadoras']}
                          getOptionLabel={(option) => option.NivelPuestoLaboral}
                          value={valueAutoCompleteNivelPuestoLaboral}
                          onChange={(event, newValue) =>
                            handleOnChangeTipoParentesco(event, newValue)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Nivel Puesto Laboral"
                              variant="outlined"
                            />
                          )}
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
                </Grid> */}

                <Grid item xs={6} sm={6} md={12} lg={12}>
                  <FormControl className={classes.formControlSelects}>
                    <Controller
                      name="nombreEquipo"
                      render={({ NombreEquipo }) => (
                        <TextField
                          id="nombreEquipo"
                          label="Nombre de Equipo"
                          name="nombreEquipo"
                          value={nombreEquipo}
                          onChange={(e) => setNombreEquipo(e.target.value)}
                          variant="outlined"
                          // helperText={
                          //   fieldsErrors.puestoLaboral
                          //     ? fieldsErrors.puestoLaboral.message
                          //     : ""
                          // }
                          //   inputRef={refTelefono}
                          //   {...inputPropsTelefono}

                          //   rules={{
                          //     pattern: { value: /[0-9]/, message: "Solo se aceptan números" },
                          //     minLength: { value: 8, message: "No menor a 8 dígitos" },
                          //     maxLength: { value: 8, message: "No mayor a 8 dígitos" },
                          //   }}
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
                  <FormControl className={classes.formControlSelects}>
                    <Controller
                      name="noVersion"
                      render={({ NoVersion }) => (
                        <TextField
                          id="noVersion"
                          label="Número de Versión"
                          name="noVersion"
                          value={numeroVersion}
                          onChange={(e) => setNumeroVersion(e.target.value)}
                          variant="outlined"
                          // helperText={
                          //   fieldsErrors.noVersion
                          //     ? fieldsErrors.noVersion.message
                          //     : ""
                          // }
                          //   inputRef={refTelefono}
                          //   {...inputPropsTelefono}

                          //   rules={{
                          //     pattern: { value: /[0-9]/, message: "Solo se aceptan números" },
                          //     minLength: { value: 8, message: "No menor a 8 dígitos" },
                          //     maxLength: { value: 8, message: "No mayor a 8 dígitos" },
                          //   }}
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
                  <FormControl className={classes.formControlSelects}>
                    <Controller
                      name="serviceTag"
                      render={({ ServiceTag }) => (
                        <TextField
                          id="serviceTag"
                          label="Service Tag"
                          name="serviceTag"
                          value={serviceTag}
                          onChange={(e) => setServiceTag(e.target.value)}
                          variant="outlined"
                          // helperText={
                          //   fieldsErrors.noVersion
                          //     ? fieldsErrors.noVersion.message
                          //     : ""
                          // }
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
                  <FormControl className={classes.formControlSelects}>
                    <Controller
                      name="descripcion"
                      render={({ Descripcion }) => (
                        <TextField
                          id="descripcion"
                          label="Descripcion"
                          name="descripcion"
                          value={descripcion}
                          onChange={(e) => setDescripcion(e.target.value)}
                          variant="outlined"
                          // helperText={
                          //   fieldsErrors.noVersion
                          //     ? fieldsErrors.noVersion.message
                          //     : ""
                          // }
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
                  <FormControl className={classes.formControlSelects}>
                    <Controller
                      name="numeroSerie"
                      render={({ NumeroSerie }) => (
                        <TextField
                          id="numeroSerie"
                          label="Número de Serie"
                          name="numeroSerie"
                          value={numeroSerie}
                          onChange={(e) => setNumeroSerie(e.target.value)}
                          variant="outlined"
                          // helperText={
                          //   fieldsErrors.noVersion
                          //     ? fieldsErrors.noVersion.message
                          //     : ""
                          // }
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
                  <FormControl className={classes.formControlSelects}>
                    <Controller
                      name="modelo"
                      render={({ Modelo }) => (
                        <TextField
                          id="modelo"
                          label="Modelo"
                          name="modelo"
                          value={modelo}
                          onChange={(e) => setModelo(e.target.value)}
                          variant="outlined"
                          // helperText={
                          //   fieldsErrors.noVersion
                          //     ? fieldsErrors.noVersion.message
                          //     : ""
                          // }
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
                  <FormControl className={classes.formControlSelects}>
                    <Controller
                      name="marca"
                      render={({ Marca }) => (
                        <TextField
                          id="marca"
                          label="Marca"
                          name="marca"
                          value={marca}
                          onChange={(e) => setMarca(e.target.value)}
                          variant="outlined"
                          // helperText={
                          //   fieldsErrors.noVersion
                          //     ? fieldsErrors.noVersion.message
                          //     : ""
                          // }
                        />
                      )}
                      control={control}
                    />
                  </FormControl>

                  <FormControl className={classes.formControlSelects}>
                    <Controller
                      name="sp"
                      render={({ SP }) => (
                        <TextField
                          id="sp"
                          label="Service Pack"
                          name="sp"
                          value={servicePack}
                          onChange={(e) => setServicePack(e.target.value)}
                          variant="outlined"
                          // helperText={
                          //   fieldsErrors.noVersion
                          //     ? fieldsErrors.noVersion.message
                          //     : ""
                          // }
                        />
                      )}
                      control={control}
                    />
                  </FormControl>

                  <FormControl className={classes.formControlSelects}>
                    <Controller
                      name="tipoEquipo"
                      required
                      render={({ TipoEquipo }) => (
                        <Autocomplete
                          id="subNivelPuestoLaboral"
                          options={tiposEquipo}
                          getOptionLabel={(option) => option.TipoEquipo}
                          value={valueAutoCompleteTipoEquipo}
                          onChange={(event, newValue) =>
                            handleOnChangeTipoEquipo(event, newValue)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Tipo de Equipo "
                              variant="outlined"
                            />
                          )}
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
                  <FormControl className={classes.formControlSelects}>
                    <Controller
                      name="ubicacionEquipo"
                      required
                      render={({ UbicacionEquipo }) => (
                        <Autocomplete
                          id="ubicacionEquipo"
                          options={maestroUbicacionEquipo}
                          getOptionLabel={(option) => option.UbicacionEquipo}
                          value={valueAutoCompleteTipoEquipo}
                          onChange={(event, newValue) =>
                            handleOnChangeUbicacionEquipo(event, newValue)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Ubicación Equipo"
                              variant="outlined"
                            />
                          )}
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
                  <FormControl className={classes.formControlSelects}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid container justify="flex-start">
                        <KeyboardDatePicker
                          disableToolbar
                          variant="inline"
                          format="dd/MM/yyyy"
                          margin="normal"
                          id="date-picker-inline"
                          label="Fecha de Creación"
                          value={fechaCreacion}
                          onChange={handleDateChangeFechaCreacion}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          required
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} md={12} lg={12}>
                  <Button
                    width="100%"
                    variant="contained"
                    style={{
                      backgroundColor: "#69bd4b",
                      marginBottom: 10,
                      marginTop: 10,
                      color: "white",
                      width: "93%",
                      marginLeft: 30,
                    }}
                    type="submit"
                  >
                    &nbsp;Guardar Cambios
                  </Button>
                </Grid>
              </form>
            </Grid>
          </div>
        </Fade>
      </Modal>
      <AppBar
        position="sticky"
        className={classes.appBar}
        style={{ backgroundColor: titleColor }}
      >
        <Toolbar>
          <Typography
            variant="h6"
            noWrap
            style={{ display: "flex", flexGrow: 1 }}
          >
            {`Listado de equipos para mantenimiento`}
          </Typography>

          <Tooltip title={"Crear nuevo ítem"} aria-label="Crear nuevo ítem">
            <IconButton
              aria-label="Crear nuevo ítem"
              style={{ color: "white" }}
            >
              <Add onClick={handleOpenModal} />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <Paper className={classes.rootTabla}>
        <DataGrid
          dataSource={equipoMantenimiento}
          //   defaultColumns={this.props.columns}
          showBorders={true}
          rowAlternationEnabled={true}
          //ref={(ref) => (dataGrid = ref)}
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
          onExporting={onExporting}
          className={classes.table}
        >
          <FilterRow visible={showFilterRow} applyFilter={currentFilter} />
          <HeaderFilter visible={showHeaderFilter} />
          <GroupPanel visible={false} />
          <Grouping autoExpandAll={autoExpandAll} />
          <SearchPanel visible={false} width={240} placeholder="Search..." />
          {/* <Selection mode="multiple" /> */}
          <Editing
            mode="row"
            useIcons={true}
            allowUpdating={false}
            allowDeleting={false}
          />
          <Column
            caption="Opciones"
            allowFiltering={false}
            allowSorting={false}
            cellRender={cellOpciones}
          />
          <Column dataField="CodigoEquipo" caption="Código Equipo" />
          <Column dataField="TipoEquipo" caption="Tipo Equipo" />
          <Column dataField="UbicacionEquipo" caption="Ubicación" />
          <Column dataField="Nombre" caption="Nombre" />
          <Column dataField="Descripcion" caption="Descripción" />
          <Column dataField="ServiceTag" caption="Service Tag" />
          <Column dataField="NumeroVersion" caption="Número Versión" />
          <Column dataField="NumeroVersion" caption="Número de Serie" />
          <Column dataField="NumeroSerie" caption="Número Versión" />
          <Column dataField="Marca" caption="Marca" />
          <Column dataField="Service Pack" caption="Service Pack" />
          <Column
            dataField="FechaInserto"
            caption="Fecha Creación"
            dataType="date"
            format="dd/MM/yyyy"
          />
          <Column dataField="Activo" caption="Activo" dataType="boolean" />

          <ColumnFixing enabled={true} />
          <Sorting mode="multiple" />
          <Paging defaultPageSize={50} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[50, 75, 100]}
            showInfo={true}
          />
          <Export enabled={true} allowExportSelectedData={true} />
          <Scrolling columnRenderingMode="virtual" />
        </DataGrid>
      </Paper>
    </React.Fragment>
  );
};

export default EquipoMantenimiento;
