import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Layout from "../../layout/containers/Layout";
import Formulario from "../components/formulario";
import GestionGeneral from "../components/gestion-general";
import Historico from "../components/historico";
import Tareas from "./tareas-container";

import API from "../../utils/api";
import { isSignedIn, signOut } from "../../utils/auth";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  consultarPermiso,
  validaPermisoPaginaActual,
} from "../../utils/helpers";

import CalificacionInput from "../../calificacion-gestion/containers/calificacion-input";

//clinica

import FichaClinicaEmpleado from "../../ficha-clinica/components/dato-ficha-clinica";
import DatosEmpleado from "../../ficha-clinica/components/datos-empleado";
import { registrarAnalitica } from "../../utils/firebase-config";
import AsistenciasGrid from "../../Asistencias/Components/asistenciasGrid";
function GestionContainer(props) {
  const [codigoAviso, setCodigoAviso] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [aviso, setAviso] = useState(null);
  const [estados, setEstados] = useState([]);
  const [causas, setCausas] = useState([]);
  const [vacaciones, setVacaciones] = useState([]);
  const [dataEmpleado, setDataEmpleadoClinica] = useState([]);
  const [dataPuestoLaboralSolicitud, setDataPuestoLaboralSolicitud] =
    useState(null);
  const [dataRequerimientoPersonal, setDataRequerimientoPersonal] =
    useState(null);

  const [dataSolicitudMovimientoPersonal, setDataSolicitudMovimientoPersonal] =
    useState(null);
  const [dataAnalisisSalarial, setDataAnalisisSalarial] = useState(null);
  const [fichaClinicaEmpleado, setDataFichaClinica] = useState([]);
  const [selectedItemsCausas, setSelectedItemsCausas] = useState([]);
  const [selectedItemsCausasObject, setSelectedItemsCausasObject] = useState(
    []
  );
  const [listaPlantillaNotificaciones, setlistaPlantillaNotificaciones] =
    useState([]);
  const [listaFamiliaEtiqueta, setListaFamiliaEtiqueta] = useState([]);
  const [selectedItemsUsuarios, setSelectedItemsUsuarios] = useState([]);
  const [selectedItemsUsuariosObject, setSelectedItemsUsuariosObject] =
    useState([]);
  const [usuarios, setUsuarios] = useState([]);
  const [camposPersonalizados, setCamposPersonalizados] = useState(null);
  const [camposPersonalizadosTemp, setCamposPersonalizadosTemp] = useState([]);
  const [permisoEditar, setPermisoEditar] = useState(false);
  const [location, setLocation] = useState({ lon: -1, lat: -1 });

  const [detallePermisoUsuarioActual, setDetallePermisoUsuarioActual] =
    useState({
      CodigoAvisoUsuarioPermiso: null,
      Nombre: "No asignado",
      Create: false,
      Read: false,
      Update: false,
      Delete: false,
      Download: false,
      Print: false,
      Activo: false,
      Eliminado: false,
    });

  const [idAvisoVacaciones, setIdAvisoVacaciones] = useState(280);
  const [idAvisoClinica, setIdAvisoClinica] = useState(288);
  const [idAvisoAsistencia, setIdAvisoAsistencia] = useState(650); //Cuando sea este tipo de aviso, se muestra Grid de asistentes y mapa
  const [idAvisoPuestoLaboral, setIdAvisoPuestoLaboral] = useState(325);
  const [
    idAvisoSolicitudMovimientoPersonal,
    setIdAvisoSolicitudMovimientoPersonal,
  ] = useState(326);
  const [idAvisoRequerimientoPersonal, setIdAvisoRequerimientoPersonal] =
    useState(756);
  const [idAvisoAnalisisSalarial, setIdAvisoAnalisisSalarial] = useState(347);
  //const [idAvisoClinica, setIdAvisoClinica] = useState(288); //TODO
  const [idAvisoMantenimientoPreventivo, setIdAvisoMantenimientoPreventivo] =
    useState(304); //TODO
  const [verTareasGrid, setVerTareasGrid] = useState(false);
  //const [idAvisoClinica, setIdAvisoClinica] = useState(288);
  //const [idAvisoPuestoLaboral, setIdAvisoPuestoLaboral] = useState(325)
  const [permisoDescargarPDF, setpermisoDescargarPDF] = useState(false);

  const [maestroTipoMovimientoPersonal, setMaestroTipoMovimientoPersonal] =
    useState([]);

  const makeRequest = async () => {
    setIsLoading(false);

    try {
      //CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
      const llaveAgrupacion = "Menu.Principal.Web";
      const llavePagina = "Menu.Principal.Web.Gestiones";
      await validaPermisoPaginaActual(llaveAgrupacion, llavePagina);
      //FIN DE CONSULTA DE PERMISOS
      const requestCodGestion = await API.fetchGetRequest(
        `api/vacaciones/getCodigoProblemaVacaciones`
      );

      const requestCodGestionClinica = await API.fetchGetRequest(
        `api/fichaClinica/getCodigoProblemaClinica`
      );

      const requestCodGestionPuestoLaboral = await API.fetchGetRequest(
        `api/PuestoLaboralSolicitud/getCodigoProblema`
      );

      const requestCodMantenimientoPreventivo = await API.fetchGetRequest(
        `api/MantenimientoPreventivo/getCodigoProblemaMantenimiento`
      );

      const requestMaestroTipoMovimientoPersonal = await API.fetchGetRequest(
        `api/TipoMovimientoPersonal/`
      );
      const requestCodRequerimientoPersonal = await API.fetchGetRequest(
        `api/RequerimientoPersonal/getCodigoProblema`
      );

      const codigoAvisoTemp = props.match.params.codigoGestion;

      const verificarGestionVacaciones = requestCodGestion.data.data;
      const verificarGestionClinica = requestCodGestionClinica.data.data;
      const verificarGestionPuestoLaboral =
        requestCodGestionPuestoLaboral.data.data;
      const verificarMaestroTipoMovimientoPersonal =
        requestMaestroTipoMovimientoPersonal.data.ficha;

      const verificarGestionRequerimientoPersonal =
        requestCodRequerimientoPersonal.data.data;
      //setCodigoAviso(codigoAvisoTemp);

      const verificaGestionMantenimientoPreventivo =
        requestCodMantenimientoPreventivo.data.data;

      setIdAvisoVacaciones(verificarGestionVacaciones);
      setIdAvisoClinica(verificarGestionClinica);
      setIdAvisoPuestoLaboral(verificarGestionPuestoLaboral);
      setMaestroTipoMovimientoPersonal(verificarMaestroTipoMovimientoPersonal);

      setIdAvisoMantenimientoPreventivo(verificaGestionMantenimientoPreventivo);

      setIdAvisoRequerimientoPersonal(verificarGestionRequerimientoPersonal);

      setCodigoAviso(codigoAvisoTemp);
      const session = await isSignedIn();

      const uri = `api/aviso/${codigoAvisoTemp}?codigoUsuario=${session.codigoUsuario}`;
      const request = await API.fetchGetRequest(uri);

      if (request.statusCode === 401) {
        if (await signOut()) {
          window.location.href = "/login";
        }
        return;
      }

      const requestCausas = await API.fetchGetRequest(
        `api/aviso/${codigoAvisoTemp}/causas`
      );

      //Obtiene el detalle de permiso del usuario actual sobre la gestión
      try {
        const uriDetallePermiso = `api/aviso/listarDetallePermisoUsuario?CodigoAviso=${props.match.params.codigoGestion}&CodigoUsuario=${session.codigoUsuario}`;
        const requestDetallePermiso = await API.fetchGetRequest(
          uriDetallePermiso
        );
        if (requestDetallePermiso.statusCode === 401) {
          // return;
        } else {
          console.log(
            "detallePermisoUsuarioActual",
            requestDetallePermiso.data.data
          );
          if (requestDetallePermiso.data.data !== null) {
            setDetallePermisoUsuarioActual(requestDetallePermiso.data.data);
          }
        }
      } catch (err) {
        console.log("requestDetallerPermiso err:", err);
      }

      if (request.data.response) {
        console.log("DATOS");
        console.log(request.data);
        //Valida si el rol del usuario tiene acceso a este objeto gestión
        let permisos = await consultarPermiso(
          request.data.aviso.CodigoProblema,
          "AITProblema",
          codigoAvisoTemp
        );
        if (!permisos.R) {
          toast.warn("No tienes permiso para ejecutar este elemento");
          setTimeout(() => {
            window.location.href = "/admongestiones";
          }, 3000);
        }

        const requestFamiliaEtiqueta = await API.fetchGetRequest(
          `api/familiaetiqueta/listar`
        );

        if (Array.isArray(requestFamiliaEtiqueta.data.data)) {
          let listaFamilias = requestFamiliaEtiqueta.data.data;
          listaFamilias.push({
            CodigoFamiliaEtiqueta: 0,
            Nombre: "Todas",
            Descripcion: "",
            Activo: true,
          });
          setListaFamiliaEtiqueta(listaFamilias);
        }

        if (request.data.aviso.CodigoProblema === verificarGestionVacaciones) {
          const requestPermisoPDF = await API.fetchGetRequest(
            `api/vacaciones/permisoDescarga/${codigoAvisoTemp}`
          );
          setpermisoDescargarPDF(requestPermisoPDF.data.data);
          const requestVacaciones = await API.fetchGetRequest(
            `api/vacaciones/listarVacaciones/${codigoAvisoTemp}`
          );
          setVacaciones(requestVacaciones.data.data);
        }

        if (request.data.aviso.CodigoProblema === verificarGestionClinica) {
          let uri =
            "api/fichaClinica/empleado/" +
            request.data.aviso.Solicitante.UsuarioName;
          const requestDataEmpleadoClinica = await API.fetchGetRequest(uri);
          console.log(requestDataEmpleadoClinica);
          setDataEmpleadoClinica(requestDataEmpleadoClinica.data.data[0]);

          let uriFicha =
            "api/fichaClinica/" + request.data.aviso.Solicitante.UsuarioName;
          const requestDataFichaClinica = await API.fetchGetRequest(uriFicha);
          console.log(requestDataFichaClinica);
          setDataFichaClinica(requestDataFichaClinica.data.data);
        }
        if (
          request.data.aviso.CodigoProblema ===
          verificaGestionMantenimientoPreventivo
        ) {
          setVerTareasGrid(true);
        }

        if (
          request.data.aviso.CodigoProblema === verificarGestionPuestoLaboral
        ) {
          let urix = "api/PuestoLaboralSolicitud/Aviso/" + codigoAvisoTemp;
          const requestDataEmpleadoClinica = await API.fetchGetRequest(urix);
          console.log("Es solicitud de cambio en puesto laboral");
          setDataPuestoLaboralSolicitud(
            requestDataEmpleadoClinica.data.ficha[0]
          );
        }

        if (
          request.data.aviso.CodigoProblema ===
          verificarGestionRequerimientoPersonal
        ) {
          let urix = "api/RequerimientoPersonal/Aviso/" + codigoAvisoTemp;
          const requestDataEmpleadoClinica = await API.fetchGetRequest(urix);
          console.log("Es solicitud de requerimiento de personal");
          setDataRequerimientoPersonal(
            requestDataEmpleadoClinica.data.ficha[0]
          );
        }

        const requestListaAlerta = await API.fetchGetRequest(
          `api/plantillaalerta/listar`
        );
        if (Array.isArray(requestListaAlerta.data.data)) {
          let lista = requestListaAlerta.data.data;
          lista.push({ CodigoPlantillaAlerta: 0, Nombre: "Ninguna" });
          setlistaPlantillaNotificaciones({
            listaPlantillaNotificaciones: lista,
          });
        }

        //Avisos movimiento personal
        let verificaProblemaTipoMovimientoPersonal =
          verificarMaestroTipoMovimientoPersonal.find(
            (w) => w.CodigoProblema === request.data.aviso.CodigoProblema
          );
        console.log("CODIGOPROBLEMA", request.data.aviso.CodigoProblema);

        if (
          verificaProblemaTipoMovimientoPersonal !== null &&
          verificaProblemaTipoMovimientoPersonal !== undefined
        ) {
          if (
            verificaProblemaTipoMovimientoPersonal.Descripcion === "Nivelación"
          ) {
            let uri = "api/AnalisisSalarial/Gestion/" + codigoAvisoTemp;
            const requestDatosMovmientoPersonal = await API.fetchGetRequest(
              uri
            );
            setDataAnalisisSalarial(requestDatosMovmientoPersonal.data.ficha);
            setIdAvisoAnalisisSalarial(
              verificaProblemaTipoMovimientoPersonal.CodigoProblema
            );
          } else {
            let uri =
              "api/EmpleadoSolicitudMovimiento/Gestion/" + codigoAvisoTemp;
            const requestDatosMovmientoPersonal = await API.fetchGetRequest(
              uri
            );
            setDataSolicitudMovimientoPersonal(
              requestDatosMovmientoPersonal.data.ficha
            );
            setIdAvisoSolicitudMovimientoPersonal(
              verificaProblemaTipoMovimientoPersonal.CodigoProblema
            );
          }
        }
        //OKdw

        console.log("DATOS QUE LLEGAN SIUUU", request);
        setAviso(request.data.aviso);
        setEstados(request.data.estados);
        setCausas(requestCausas.data.causasDisponibles[0].children);
        setSelectedItemsCausas(requestCausas.data.causas);
        setUsuarios(request.data.usuariosDisponibles[0].children);
        setSelectedItemsUsuarios(request.data.aviso.Asignados);
        setCamposPersonalizados(request.data.camposPersonalizados);
        setCamposPersonalizadosTemp(request.data.camposPersonalizados);
        setPermisoEditar(request.data.permisoEditar);

        //Asigna lista de causas en forma de objeto ;
        let causasObject = [];
        let causasAsig = requestCausas.data.causas;
        let causasDispo = requestCausas.data.causasDisponibles[0].children;

        await causasAsig.forEach((item, i) => {
          let fila = causasDispo.filter((obj) => {
            return obj.id === item;
          });
          causasObject.push(fila);
        });

        setSelectedItemsCausasObject(causasObject);

        //Asigna lista de usuarios en forma de objeto ;
        let asigObject = [];
        let usuariosAsig = [...request.data.aviso.Asignados];
        let usuariosDispo = request.data.usuariosDisponibles[0].children;

        await usuariosAsig.forEach((item, i) => {
          let fila = usuariosDispo.filter((obj) => {
            return obj.id === item;
          });
          asigObject.push(fila);
        });

        setSelectedItemsUsuariosObject(asigObject);

        console.log(request.data);
      } else {
        //El API alerta error.
        console.log("error load aviso", request);
      }
    } catch (e) {
      //Hubo error en el request al server
      console.log("error catch load aviso", e);
    } finally {
      //setIsLoading(false);
    }
  };

  const listaUsuarioObjectConvert = async () => {
    //Asigna lista de usuarios en forma de objeto ;
    let asigObject = [];
    let usuariosAsig = [...selectedItemsUsuarios]; //request.data.aviso.Asignados;
    let usuariosDispo = [...usuarios]; //request.data.usuariosDisponibles[0].children;

    await usuariosAsig.forEach((item, i) => {
      let fila = usuariosDispo.filter((obj) => {
        return obj.id === item;
      });
      asigObject.push(fila);
    });

    setSelectedItemsUsuariosObject(asigObject);
  };

  const listaCausaObjectConvert = async () => {
    //Asigna lista de causas en forma de objeto ;
    let causasObject = [];
    let causasAsig = selectedItemsCausas; //requestCausas.data.causas;
    let causasDispo = causas; //requestCausas.data.causasDisponibles[0].children;

    await causasAsig.forEach((item, i) => {
      let fila = causasDispo.filter((obj) => {
        return obj.id === item;
      });
      causasObject.push(fila);
    });
    setSelectedItemsCausasObject(causasObject);
  };

  const onPressInactivarCampoValor = async (campo) => {
    const session = await isSignedIn();
    const objectRequest = {
      CodigoUsuario: session.codigoUsuario,
      ...campo,
    };
    const response = await postAviso(objectRequest);
    return response;
  };

  const agregarUsuario = async (nuevoItem) => {
    let usrSeleccionados = [...selectedItemsUsuarios];

    //Valida si ya existe el código de causa
    let filtro = usrSeleccionados.filter((fila) => {
      return fila === nuevoItem;
    });

    console.log("filtro:", filtro);
    if (filtro.length > 0) {
      return;
    }

    usrSeleccionados.push(nuevoItem);

    setSelectedItemsUsuarios([...usrSeleccionados]);

    //Enviar a guardar los usuarios.
    const session = await isSignedIn();

    let objectRequest = {};
    objectRequest.CodigosUsuarios = usrSeleccionados;
    objectRequest.CodigoUsuario = session.codigoUsuario;

    await postAviso(objectRequest);
    makeRequest();
    //listaUsuarioObjectConvert();
  };

  const removerUsuario = async (item) => {
    let usuariosSeleccionados = [...selectedItemsUsuarios];

    //Valida si existe el código de causa
    let filtro = usuariosSeleccionados.filter((fila) => {
      return fila === item;
    });

    console.log("filtro:", filtro);
    if (filtro.length < 1) {
      return;
    }

    usuariosSeleccionados = usuariosSeleccionados.filter((fila) => {
      return fila !== item;
    });

    setSelectedItemsUsuarios(usuariosSeleccionados);

    //Enviar a guardar las causas.
    const session = await isSignedIn();

    let objectRequest = {};
    objectRequest.CodigosUsuarios = usuariosSeleccionados;
    objectRequest.CodigoUsuario = session.codigoUsuario;

    await postAviso(objectRequest);
    listaUsuarioObjectConvert();
  };

  const agregarCausas = async (nuevoItem) => {
    let causasSeleccionadas = selectedItemsCausas;

    //Valida si ya existe el código de causa
    let filtro = causasSeleccionadas.filter((fila) => {
      return fila === nuevoItem;
    });

    console.log("filtro:", filtro);
    if (filtro.length > 0) {
      return;
    }

    causasSeleccionadas.push(nuevoItem);

    setSelectedItemsCausas(causasSeleccionadas);

    //Enviar a guardar las causas.
    const session = await isSignedIn();

    let objectRequest = {};
    objectRequest.CodigosCausas = causasSeleccionadas;
    objectRequest.CodigoUsuario = session.codigoUsuario;

    await postAviso(objectRequest);
    listaCausaObjectConvert();
  };

  const removerCausas = async (item) => {
    let causasSeleccionadas = selectedItemsCausas;

    //Valida si existe el código de causa
    let filtro = causasSeleccionadas.filter((fila) => {
      return fila === item;
    });

    console.log("filtro:", filtro);
    if (filtro.length < 1) {
      return;
    }

    causasSeleccionadas = causasSeleccionadas.filter((fila) => {
      return fila !== item;
    });

    setSelectedItemsCausas(causasSeleccionadas);

    //Enviar a guardar las causas.
    const session = await isSignedIn();

    let objectRequest = {};
    objectRequest.CodigosCausas = causasSeleccionadas;
    objectRequest.CodigoUsuario = session.codigoUsuario;

    await postAviso(objectRequest);
    listaCausaObjectConvert();
  };

  const postAviso = async (objectRequest) => {
    await almacenarGeoposicion();

    try {
      const uri = `api/aviso/${codigoAviso}`;
      let objects = objectRequest;

      objects = { ...objects, Longitud: location.lon, Latitud: location.lat };

      const request = await API.post(uri, objects);

      if (request.statusCode === 401) {
        if (await signOut()) {
          window.location.href = "/login";
        }
        return;
      }

      if (request.data.response) {
        //OK
        //Alert.alert(request.data.mensaje);

        //Verificamos si vienen campos con nuevos valores (Ya que pueden actualizarse dependiendo de la actualización de un campo)
        //Ej. Si un campo calculado depende de uno que se esta modificando, este hay que actualizarlo.
        if (request.data.camposModificadosNuevos.length > 0) {
          request.data.camposModificadosNuevos.map((campoNuevo) => {
            let camposPersonalizadosNuevos = [...camposPersonalizados];
            let indexCampoViejoAReemplazar =
              camposPersonalizadosNuevos.findIndex(
                (f) => campoNuevo.CodigoCampo === f.CodigoCampo
              );

            camposPersonalizadosNuevos[indexCampoViejoAReemplazar].Valor =
              campoNuevo;

            setCamposPersonalizados(camposPersonalizadosNuevos);
          });
        }
      } else {
        //El API alerta error.
        window.alert(request.data.mensaje);
      }
    } catch (e) {
      //Hubo error en el request al server
      console.log("postAviso", e);
    }
  };

  const almacenarGeoposicion = async () => {
    try {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          const geolocation = {
            lat: position.coords.latitude,
            lon: position.coords.longitude,
          };
          setLocation(geolocation);
        });
      } else {
        window.alert("Su navegador no soporta geolocalización");
      }
    } catch (e) {
      setLocation({ lat: -1, long: -1 });
    }
    //await this.setState({ location });
  };

  const quitarDocAdjuntoDeEstado = async (campo, file) => {
    let campoPersonalizado = camposPersonalizados.findIndex(
      (item) => item === campo
    );
    let camposCopia = camposPersonalizados;
    camposCopia[campoPersonalizado].Valor = camposPersonalizados[
      campoPersonalizado
    ].Valor.filter((item) => item !== file);

    setCamposPersonalizados(camposCopia);
    setCamposPersonalizados(camposCopia);
    console.log("camposCopia");
    console.log(camposCopia);
  };

  const cancelarAviso = async () => {
    const session = await isSignedIn();

    const params = {
      CodigoUsuario: session.codigoUsuario,
      Activo: false,
    };

    await postAviso(params);

    //window.alert("Su aviso fue cancelado exitosamente");
    toast.info("Su aviso fue anulado exitosamente");
    setTimeout(function () {
      window.location.href = "/bandeja";
    }, 3000);
  };

  const onChangeCampo = (nombreCampo) => async (event) => {
    let av = aviso;
    if (nombreCampo === "descripcion") {
      av.Descripcion = event.target.value;
      await setAviso(av);
      guardarEditarCampo(nombreCampo);
      console.log(aviso);
    } else if (nombreCampo === "titulo") {
      av.Titulo = event.target.value;
      await setAviso(av);
      guardarEditarCampo(nombreCampo);
      console.log(aviso);
    } else if (nombreCampo === "fechaVencimiento") {
      console.log("Event.Target.Value", event.target.value);
      av.FechaVencimiento = event.target.value;
      av.CambioFecha = true;
      await setAviso(av);
      guardarEditarCampo(nombreCampo);
      console.log(aviso);
    } else if (nombreCampo === "codigoPlantillaNotificaciones") {
      av.CodigoPlantillaNotificaciones = event.target.value;
      av.CambioPlantillaNotificaciones = true;
      await setAviso(av);
      guardarEditarCampo(nombreCampo);
    }
  };

  const onChangeCampoPersonalizado = (codigoCampo) => (event) => {
    const numeroEstadosPosteriores = aviso.NumeroEstadosPosteriores;

    if (numeroEstadosPosteriores == 0 /*|| !permisoEditar*/) {
      toast.warn("No tienes permiso para editar este campo.");
      return;
    }

    let valor;
    let tipoCampo;

    let camposPer = [...camposPersonalizados];

    const findIndex = camposPer.findIndex((f) => f.CodigoCampo == codigoCampo);

    tipoCampo = camposPer[findIndex].CodigoTipoCampo;

    if (tipoCampo == 7) {
      valor = event.target.checked;
    } else if (
      tipoCampo == 5 ||
      tipoCampo == 10 ||
      tipoCampo == 12 ||
      tipoCampo == 23
    ) {
      var arr = [];
      arr.push(event.target.value);
      valor = arr;

      //Si el tipo de campo es 23 Campo MCU (Maestro Control Universal), debe validar si no tiene hijos, a quienes debe afectar
      if (tipoCampo == 23) {
        console.log("jalando");
        //@rda: CAMPOS TIPO MCU (Maco Culero, jajaja Maestro Conector Universal)
        getItemsAPIFieldByParent(codigoCampo, event.target.value);
      }
    } else {
      valor = event.target.value;
    }

    camposPer[findIndex].Valor.Valor = valor;

    setCamposPersonalizados(camposPer);

    guardarEditarCampoPersonalizado(camposPer[findIndex].CodigoCampo);
  };

  const getItemsAPIFieldByParent = async (
    codigoCampoPadre,
    valorCampoPadre
  ) => {
    console.log("Entro a esta cochinada");
    try {
      const uri = `api/aviso/getItemsAPIFieldByParent?codigoCampoPadre=${codigoCampoPadre}&valorCampoPadre=${valorCampoPadre}`;
      const request = await API.fetchGetRequest(uri);

      if (request.statusCode === 401) {
        if (await signOut()) {
          window.location.href = "/login";
        }
        return;
      }

      if (request.data.response) {
        console.log("Nuevos Items:", request.data.data);

        let camposPer = [...camposPersonalizados];

        request.data.data.map((fila, i) => {
          const findIndex = camposPer.findIndex(
            (f) => f.CodigoCampo == fila.CodigoCampo
          );
          console.log("indexPlantilla:", findIndex);
          camposPer[findIndex].Items = fila.Items;
        });
        setCamposPersonalizados(camposPer);

        // this.setState({
        //   plantilla: request.data.data,
        //   plantillaValores: plantillaValores,
        //   isLoadingPlantilla: false,
        // });
      } else {
        //El API alerta error.
        this.setState({
          isLoadingPlantilla: false,
        });
      }
    } catch (e) {}
  };

  const onChangeCampoPersonalizadosAutocomplete = async (
    event,
    newValue,
    campo
  ) => {
    console.log("Onchange");
    console.log(event.target);
    //let { plantillaValores } = this.state;
    const numeroEstadosPosteriores = aviso.NumeroEstadosPosteriores;

    if (numeroEstadosPosteriores == 0 /*|| !permisoEditar*/) {
      toast.warn("No tienes permiso para editar este campo.");
      return;
    }
    let camposPer = [...camposPersonalizados];

    let options = newValue.id;
    console.log(options);
    if (!Array.isArray(options)) {
      options = [options];
    }

    const val = {
      CodigoCampo: campo.CodigoCampo,
      CodigoTipoCampo: campo.CodigoTipoCampo,
      Valor: options,
      Requerido: campo.Requerido,
      CodigoCampoPadre: campo.CodigoCampoPadre,
      LlaveCampo: campo.LlaveCampo,
    };

    const find = camposPer.findIndex(
      (f) => f.CodigoCampo === campo.CodigoCampo
    );

    if (find >= 0) {
      camposPer[find] = val;
    } else {
      camposPer.push(val);
    }

    setCamposPersonalizados(camposPer);

    guardarEditarCampoPersonalizado(camposPer[find].CodigoCampo);
  };

  const guardarEditarCampoPersonalizado = async (codigoCampo) => {
    let objectRequest = {};
    const session = await isSignedIn();

    let campo = camposPersonalizados.find((f) => f.CodigoCampo == codigoCampo);
    let findIndex = camposPersonalizados.findIndex(
      (f) => f.CodigoCampo == codigoCampo
    );

    objectRequest = {
      CampoPersonalizado: true,
      CodigoTipoCampo: campo.CodigoTipoCampo,
      CodigoAvisoCampoValor: campo.Valor.CodigoAvisoCampoValor,
      CampoPersonalizadoValor: campo.Valor.Valor,
      CodigoUsuario: session.codigoUsuario,
      LlaveCampo: campo.LlaveCampo,
    };

    await postAviso(objectRequest);
  };

  const guardarEditarCampo = async (campo) => {
    console.log("Editar Campo", campo);
    let objectRequest = {};
    const session = await isSignedIn();
    let av = { ...aviso };

    console.log("aviso quedo", av);

    if (campo === "titulo") {
      objectRequest.Titulo = av.Titulo;
      objectRequest.CodigoUsuario = session.codigoUsuario;
      setAviso(av);
      await postAviso(objectRequest);
    } else if (campo === "descripcion") {
      objectRequest.Descripcion = av.Descripcion;
      objectRequest.CodigoUsuario = session.codigoUsuario;
      setAviso(av);
      await postAviso(objectRequest);
    } else if (campo === "fechaVencimiento") {
      objectRequest.FechaVencimiento = av.FechaVencimiento;
      objectRequest.CambioFecha = true;
      console.log("AV.FechaVencimiento: ", av.FechaVencimiento);
      objectRequest.CodigoUsuario = session.codigoUsuario;
      setAviso(av);
      await postAviso(objectRequest);
    } else if (campo === "codigoPlantillaNotificaciones") {
      objectRequest.CodigoPlantillaNotificaciones =
        av.CodigoPlantillaNotificaciones;
      objectRequest.CambioPlantillaNotificaciones =
        av.CambioPlantillaNotificaciones;
      objectRequest.CodigoUsuario = session.codigoUsuario;
      setAviso(av);
      await postAviso(objectRequest);
    }

    // await this.makeRequest();
  };

  const verificarDependencias = async (campo) => {
    //let { plantillaValores, plantilla } = this.state;

    let find = camposPersonalizados.filter(
      (f) => f.CodigoCampoPadre == campo.CodigoCampo
    );

    if (find.length > 0) {
      //Mandar a traer al api los valores de cada uno de los campos dependientes
      await find.map(async (campoALlenar, i) => {
        const uri =
          campoALlenar.LlaveCampo == "picklistSap"
            ? `api/sap/avisos/llenarDropdown/${campoALlenar.CodigoCampo}?valor=${campo.Valor}`
            : campoALlenar.LlaveCampo == "picklistSapTree"
            ? `api/sap/avisos/llenarDropdownParentChild/${campoALlenar.CodigoCampo}?valor=${campo.Valor}`
            : ""; //TODO

        let request = await API.fetchGetRequest(uri);

        let findCampoPlantillaIndex = camposPersonalizados.findIndex(
          (f) => f.CodigoCampo == campoALlenar.CodigoCampo
        );
        let findCampoPlantilla = camposPersonalizados[findCampoPlantillaIndex];
        findCampoPlantilla.Items = request.data.data;

        //Seteamos el valor default del campo a plantillaValores
        campoALlenar.Valor = request.data.defaultValue;
        const findPlantillaValoresIndex = camposPersonalizados.findIndex(
          (f) => f.CodigoCampo == campoALlenar.CodigoCampo
        );
        camposPersonalizados[findPlantillaValoresIndex] = campoALlenar;

        camposPersonalizados[findCampoPlantillaIndex] = findCampoPlantilla;

        setCamposPersonalizados(camposPersonalizados);
        // await this.setState({ plantilla });
        // await this.setState({ plantillaValores });
        console.log(request);
      });
    }
  };

  useEffect(() => {
    registrarAnalitica("Editar_Gestion");
    makeRequest();
  }, []);

  const codigoTarea = props.location.state
    ? props.location.state.codigoTarea
    : undefined;

  // useEffect(() => {
  //   setCamposPersonalizados(camposPersonalizados);
  // }, [camposPersonalizados]);

  return (
    <Layout
      titulo={"Gestión"}
      bodyContainerStyle={{
        padding: 5,
        margin: 5,
        maxWidth: "100%",
        height: "100%",
      }}
      bodyHeader={false}
    >
      {aviso == null || dataEmpleado == null
        ? console.log("NO ha cargado aviso")
        : aviso.CodigoProblema === idAvisoClinica && (
            <Container maxWidth="xl">
              <br />
              <DatosEmpleado dataEmpleado={dataEmpleado} />
              <br />
            </Container>
          )}

      <VerticalTabs
        aviso={aviso}
        vacaciones={vacaciones}
        idAvisoVacaciones={idAvisoVacaciones}
        idAvisoClinica={idAvisoClinica}
        idAvisoAsistencia={idAvisoAsistencia}
        idAvisoPuestoLaboral={idAvisoPuestoLaboral}
        idAvisoSolicitudMovimientoPersonal={idAvisoSolicitudMovimientoPersonal}
        idAvisoAnalisisSalarial={idAvisoAnalisisSalarial}
        idAvisoRequerimientoPersonal={idAvisoRequerimientoPersonal}
        permisoDescargarPDF={permisoDescargarPDF}
        codigoAviso={codigoAviso}
        camposPersonalizados={camposPersonalizados}
        causas={selectedItemsCausasObject}
        causasDisponibles={causas}
        familiaEtiqueta={listaFamiliaEtiqueta}
        agregarCausas={agregarCausas}
        removerCausas={removerCausas}
        usuarios={selectedItemsUsuariosObject}
        agregarUsuario={agregarUsuario}
        removerUsuario={removerUsuario}
        recargar={makeRequest}
        onPressInactivarCampoValor={onPressInactivarCampoValor}
        quitarDocAdjuntoDeEstado={quitarDocAdjuntoDeEstado}
        cancelarAviso={cancelarAviso}
        permisoEditar={permisoEditar}
        onChangeCampoPersonalizado={onChangeCampoPersonalizado}
        onChangeCampoPersonalizadosAutocomplete={
          onChangeCampoPersonalizadosAutocomplete
        }
        onChangeCampo={onChangeCampo}
        detallePermisoUsuarioActual={detallePermisoUsuarioActual}
        listaPlantillaNotificaciones={listaPlantillaNotificaciones}
        dataEmpleado={dataEmpleado}
        verTareasGrid={verTareasGrid}
        dataPuestoLaboralSolicitud={dataPuestoLaboralSolicitud}
        dataSolicitudMovimientoPersonal={dataSolicitudMovimientoPersonal}
        dataAnalisisSalarial={dataAnalisisSalarial}
        codigoTareaSeleccionada={codigoTarea}
        dataRequerimientoPersonal={dataRequerimientoPersonal}
      ></VerticalTabs>
    </Layout>
  );
}
export default withRouter(GestionContainer);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: "100%",
    backgroundColor: "#F6F7F9",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    backgroundColor: "white",
  },
  papers: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(16),
    },
  },
  rootGestion: {
    flex: 1,
    maxWidth: "100%",
    marginTop: 20,
  },
  rootGestionTareas: {
    flexGrow: 1,
    marginTop: 0,
  },

  tabPanelClass: {
    width: "100%",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
}));

function VerticalTabs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    console.log("codigoTareaSeleccionada", props.codigoTareaSeleccionada);
    if (props.codigoTareaSeleccionada !== undefined) {
      setValue(1);
    }
  }, [props.codigoTareaSeleccionada]);

  const handleChange = (event, newValue) => {
    console.log("newValue", newValue);
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        className={classes.tabs}
      >
        <Tab label="Gestión" {...a11yProps(0)} />
        <Tab label="Tareas" {...a11yProps(1)} />
        <Tab label="Histórico" {...a11yProps(2)} />
        {props.aviso == null
          ? console.log("NO ha cargado aviso")
          : props.aviso.CodigoProblema === props.idAvisoClinica && (
              <Tab label="Ficha Clínica" {...a11yProps(3)} />
            )}
      </Tabs>
      <TabPanel value={value} index={0} className={classes.tabPanelClass}>
        <div className={classes.rootGestion}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} spacing={4}>
              <Grid item xs={12} sm={5}>
                <CalificacionInput codigoGestion={props.codigoAviso} />
                {/* <Historico /> */}
              </Grid>

              <GestionGeneral
                aviso={props.aviso}
                vacaciones={props.vacaciones}
                idAvisoAsistencia={props.idAvisoAsistencia}
                idAvisoVacaciones={props.idAvisoVacaciones}
                idAvisoPuestoLaboral={props.idAvisoPuestoLaboral}
                idAvisoSolicitudMovimientoPersonal={
                  props.idAvisoSolicitudMovimientoPersonal
                }
                idAvisoRequerimientoPersonal={
                  props.idAvisoRequerimientoPersonal
                }
                idAvisoAnalisisSalarial={props.idAvisoAnalisisSalarial}
                permisoDescargarPDF={props.permisoDescargarPDF}
                causas={props.causas}
                causasDisponibles={props.causasDisponibles}
                familiaEtiqueta={props.familiaEtiqueta}
                agregarCausas={props.agregarCausas}
                removerCausas={props.removerCausas}
                usuarios={props.usuarios}
                agregarUsuario={props.agregarUsuario}
                removerUsuario={props.removerUsuario}
                cancelarAviso={props.cancelarAviso}
                permisoEditar={props.permisoEditar}
                recargar={props.recargar}
                onChangeCampo={props.onChangeCampo}
                detallePermisoUsuarioActual={props.detallePermisoUsuarioActual}
                listaPlantillaNotificaciones={
                  props.listaPlantillaNotificaciones
                }
                dataPuestoLaboralSolicitud={props.dataPuestoLaboralSolicitud}
                dataSolicitudMovimientoPersonal={
                  props.dataSolicitudMovimientoPersonal
                }
                dataAnalisisSalarial={props.dataAnalisisSalarial}
                dataRequerimientoPersonal={props.dataRequerimientoPersonal}
              />

              <Formulario
                aviso={props.aviso}
                vacaciones={props.vacaciones}
                camposPersonalizados={props.camposPersonalizados}
                recargar={props.recargar}
                onPressInactivarCampoValor={props.onPressInactivarCampoValor}
                quitarDocAdjuntoDeEstado={props.quitarDocAdjuntoDeEstado}
                permisoEditar={props.permisoEditar}
                onChangeCampoPersonalizado={props.onChangeCampoPersonalizado}
                onChangeCampoPersonalizadosAutocomplete={
                  props.onChangeCampoPersonalizadosAutocomplete
                }
                detallePermisoUsuarioActual={props.detallePermisoUsuarioActual}
                listaPlantillaNotificaciones={
                  props.listaPlantillaNotificaciones
                }
              />
            </Grid>
          </Grid>
        </div>
      </TabPanel>
      <TabPanel value={value} index={1} className={classes.tabPanelClass}>
        <div className={classes.rootGestionTareas}>
          <Tareas
            detallePermisoUsuarioActual={props.detallePermisoUsuarioActual}
            verTareasGrid={props.verTareasGrid}
            tareaSeleccionada={props.codigoTareaSeleccionada}
          />
        </div>
      </TabPanel>

      {/* <ToastContainer /> */}

      <TabPanel value={value} index={2} className={classes.tabPanelClass}>
        <div className={classes.rootGestionTareas}>
          <Historico />
        </div>
      </TabPanel>

      <TabPanel value={value} index={3} className={classes.tabPanelClass}>
        {props.dataEmpleado == null
          ? console.log("NO ha cargado aviso")
          : props.dataEmpleado.dpi !== "" && (
              <Container maxWidth="sm">
                <br />
                <FichaClinicaEmpleado dpiEmpleado={props.dataEmpleado.DPI} />
                <br />
              </Container>
            )}
      </TabPanel>
    </div>
  );
}
