import React from "react";

function Mensaje(props) {
  return (
    <span style={{ ...styles.mensaje, ...props.style }}>{props.children}</span>
  );
}

const styles = {
  mensaje: { fontSize: 12, color: "grey", fontStyle: "italic" },
};

export default Mensaje;
