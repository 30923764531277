import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import {
  Typography,
  Backdrop,
  CircularProgress,
  Tab,
  Tabs,
  Box,
  Divider,
  TabScrollButton,
} from "@material-ui/core";

import Avatar from "@material-ui/core/Avatar";
import Container from "@material-ui/core/Container";
import { green } from "@material-ui/core/colors";
import Chip from "@material-ui/core/Chip";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import DnsIcon from "@material-ui/icons/Dns";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import PeopleIcon from "@material-ui/icons/People";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
//iconos

import {
  MonetizationOn,
  Work,
  AssignmentInd,
  FlightTakeoff,
  DirectionsRun,
  LocalLibrary,
  School,
  WatchLater,
  Description,
} from "@material-ui/icons";

//input date

import PropTypes from "prop-types";
import clsx from "clsx";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";

import Autocomplete from "@material-ui/lab/Autocomplete";

//Own components
// import Layout from "../../layout/containers/Layout";

import API from "../../utils/api";
import { isSignedIn, profileImageChange, signOut } from "../../utils/auth";

import DatosEmpleadoLaboral from "../components/datos-empleado-laboral";
import DatosHistoricoPuestoLaboral from "../components/dato-empleado-historico-puesto";
import DatosHistoricoSalario from "../components/dato-empleado-historico-salario";
import DatosVacaciones from "../../vacaciones/containers/info-vacaciones";
import DatosHistoricoCompetencias from "../components/dato-empleado-competencias";
import DatosEducacion from "../components/dato-empleado-educacion";
import DatosCreditosInternos from "../components/dato-empleado-creditos";
import DatosExperiencia from "../components/dato_empleado-experiencia";
import { validaPermisoSecundarioPaginaActual } from "../../utils/helpers";
import PerfilEmpleado from "../components/empleado-perfil";
import EmpleadoDocumentosComponente from "../components/empleado-documentos-componente";

const a11yProps = (index) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Typography component={"span"} variant={"body2"}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const MyTabScrollButton = withStyles((theme) => ({
  root: {
    width: 22,
    overflow: "hidden",
    transition: "width 0.5s",
    "&.Mui-disabled": {
      width: 0,
    },
  },
}))(TabScrollButton);

const styles = makeStyles((theme) => ({
  item: {
    //border: "1px solid blue",
    margin: "10",
  },
  root: {
    // flexGrow: 1,
    width: "100%",
    // backgroundColor: theme.palette.background.default,
  },
  itemFlexGrow: {
    flexGrow: 1,
    // border: "1px solid red"
  },
  content: {
    // display: "flex",
    // justify-content: "space-between",
    // maxWidth: "780px",
    // margin: "0 auto",
    // padding: "10px 0",
    //width: "100%",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
  },
  paperPerfil: {
    position: "relative",
    width: "100%", //theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  greenAvatar: {
    fontSize: "2.5em",
    color: "#fff",
    // borderStyle:'solid',
    // borderWidth:'1 !important',
    // borderColor:'#3f51b5 !important',
    backgroundColor: green[500],
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  paperCard: {
    padding: 20,
    width: "100%",
  },
  greenAvatarFoto: {
    fontSize: "2.5em",
    color: "#fff",
    // borderStyle:'solid',
    // borderWidth:'1 !important',
    // borderColor:'#3f51b5 !important',
    backgroundColor: green[500],
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
  tabsVertical: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  box: {
    // flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

const Perfil = (props) => {
  const { dataEmpleado, usuario } = props;
  const [permisoVerTodo, setPermisoVerTodo] = useState(props.permisoVerTodo);
  const [tab, setTab] = useState(props.permisoVerTodo ? 0 : 7);
  const classes = styles();
  const [permisoSkill, setPermisoRolAprendizaje] = useState(false);
  const [permisoAnalisisSalarial, setPermisoAnalisisSalarial] = useState(false);

  useEffect(() => {
    async function getPermisoSkill() {
      const llaveAgrupacion = "Menu.Principal.Web";
      const llavePagina = "Menu.Principal.Web.Aprendizaje";
      let permisoSkill = await validaPermisoSecundarioPaginaActual(
        llaveAgrupacion,
        llavePagina
      );
      console.log("Tengo Permiso de aprendizaje", permisoSkill);
      setPermisoRolAprendizaje(permisoSkill);

      const llaveAgrupacionGESPILU = "GESPILU";
      const llavePaginaGESPILU = "GESPILU.AnalisisSalarial.Editar_Completo";
      let permisoGESPILU = await validaPermisoSecundarioPaginaActual(
        llaveAgrupacionGESPILU,
        llavePaginaGESPILU
      );

      setPermisoAnalisisSalarial(permisoGESPILU);
    }
    getPermisoSkill();
  }, []);

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={(_, val) => setTab(val)}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"

          // ScrollButtonComponent={MyTabScrollButton}
        >
          {
            <Tab
              label="Datos Básicos"
              value={0}
              icon={<AssignmentInd />}
              {...a11yProps(0)}
              style={{ textTransform: "none" }}
              disabled={dataEmpleado === undefined}
            />
          }

          {permisoVerTodo && (
            <Tab
              label="Documentos"
              value={1}
              icon={<Description />}
              {...a11yProps(1)}
              style={{ textTransform: "none" }}
              disabled={dataEmpleado === undefined}
            />
          )}
          {permisoVerTodo && (
            <Tab
              label="Vacaciones"
              value={2}
              icon={<FlightTakeoff />}
              {...a11yProps(2)}
              style={{ textTransform: "none" }}
              disabled={dataEmpleado === undefined}
            />
          )}
          {permisoVerTodo && (
            <Tab
              label="Puesto Laboral"
              value={3}
              icon={<Work />}
              {...a11yProps(3)}
              style={{ textTransform: "none" }}
              disabled={dataEmpleado === undefined}
            />
          )}

          <Tab
            label="Salario"
            value={4}
            icon={<MonetizationOn />}
            {...a11yProps(4)}
            style={{ textTransform: "none" }}
            disabled={dataEmpleado === undefined}
          />

          {permisoVerTodo && (
            <Tab
              label="Competencias"
              value={5}
              icon={<DirectionsRun />}
              {...a11yProps(5)}
              style={{ textTransform: "none" }}
              disabled={dataEmpleado === undefined}
            />
          )}

          <Tab
            label="Educación"
            value={6}
            icon={<School />}
            {...a11yProps(6)}
            style={{ textTransform: "none" }}
            disabled={dataEmpleado === undefined}
          />
          <Tab
            label="Créditos Internos"
            value={7}
            icon={<LocalLibrary />}
            {...a11yProps(7)}
            style={{ textTransform: "none" }}
            disabled={dataEmpleado === undefined}
          />
          <Tab
            label="Experiencia Laboral"
            value={8}
            icon={<WatchLater />}
            {...a11yProps(8)}
            style={{ textTransform: "none" }}
            disabled={dataEmpleado === undefined}
          />
        </Tabs>
        <TabPanel id="datosLaborales" value={tab} index={0}>
          <Box className={classes.box} pl={0} pt={0}>
            {dataEmpleado !== undefined && (
              <Grid container spacing={3}>
                {/* <PerfilEmpleado dataEmpleado={dataEmpleado} /> */}
                <DatosEmpleadoLaboral dataEmpleado={dataEmpleado} />
              </Grid>
            )}
          </Box>
        </TabPanel>
        <TabPanel id="documentos" value={tab} index={1}>
          <Box className={classes.box} pl={0} pt={0}>
            {dataEmpleado !== undefined && (
              <Grid container spacing={3}>
                {/* <PerfilEmpleado dataEmpleado={dataEmpleado} /> */}
                {/* <DatosEmpleadoLaboral dataEmpleado={dataEmpleado} /> */}
                <EmpleadoDocumentosComponente DPI={dataEmpleado.DPI} />
              </Grid>
            )}
          </Box>
        </TabPanel>
        <TabPanel id="vacaciones" value={tab} index={2}>
          <Box className={classes.box} pl={0} pt={0}>
            {dataEmpleado !== undefined && (
              <Box>
                <DatosVacaciones dataEmpleado={dataEmpleado} />
              </Box>
            )}
          </Box>
        </TabPanel>
        <TabPanel id="historicoPuestoLaboral" value={tab} index={3}>
          <Box className={classes.box} pl={0} pt={0}>
            {dataEmpleado !== undefined && (
              <Box className={classes.content}>
                <DatosHistoricoPuestoLaboral dataEmpleado={dataEmpleado} />
              </Box>
            )}
          </Box>
        </TabPanel>
        <TabPanel id="historicoSalario" value={tab} index={4}>
          <Box className={classes.box} pl={0} pt={0}>
            {dataEmpleado !== undefined && (
              <Box className={classes.content}>
                <DatosHistoricoSalario
                  dataEmpleado={dataEmpleado}
                  usuario={usuario}
                />
              </Box>
            )}
          </Box>
        </TabPanel>
        <TabPanel id="historicoCompetencias" value={tab} index={5}>
          <Box className={classes.box} pl={0} pt={0}>
            {dataEmpleado !== undefined && (
              <Box className={classes.content}>
                <DatosHistoricoCompetencias dataEmpleado={dataEmpleado} />
              </Box>
            )}
          </Box>
        </TabPanel>
        <TabPanel id="educacion" value={tab} index={6}>
          <Box className={classes.box} pl={0} pt={0}>
            {dataEmpleado !== undefined && (
              <Box className={classes.content}>
                <DatosEducacion dataEmpleado={dataEmpleado} />
              </Box>
            )}
          </Box>
        </TabPanel>
        <TabPanel id="creditosInternos" value={tab} index={7}>
          <Box className={classes.box} pl={0} pt={0}>
            {dataEmpleado !== undefined && (
              <Box className={classes.content}>
                <DatosCreditosInternos dataEmpleado={dataEmpleado} />
              </Box>
            )}
          </Box>
        </TabPanel>
        <TabPanel id="experiencia" value={tab} index={8}>
          <Box className={classes.box} pl={0} pt={0}>
            {dataEmpleado !== undefined && (
              <Box className={classes.content}>
                <DatosExperiencia dataEmpleado={dataEmpleado} />
              </Box>
            )}
          </Box>
        </TabPanel>
      </div>

      <ToastContainer />
    </React.Fragment>
  );
};

export default withRouter(Perfil);
