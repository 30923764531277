import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
  createRef,
} from "react";

import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Checkbox,
  FormControlLabel,
  Divider,
  Paper,
  Tooltip,
  Modal,
  Fade,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Box,
} from "@material-ui/core";

import DataGrid, {
  Column,
  Pager,
  Paging,
  Sorting,
  ColumnFixing,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Grouping,
  GroupPanel,
  Export,
  Selection,
  Editing,
  Scrolling,
  LoadPanel,
  Lookup,
  Popup,
  Form,
  FormItem,
  Label,
} from "devextreme-react/data-grid";
import { LookupTypes } from "devextreme-react/lookup";

import CustomStore from "devextreme/data/custom_store";

import BackdropModal from "@material-ui/core/Backdrop";

import {
  Delete,
  ViewList,
  Add,
  Edit,
  CloudUpload,
  ThreeSixtyRounded,
} from "@material-ui/icons";

import { green } from "@material-ui/core/colors";

// import DetalleFuncionComponente from "./detalle-funcion-puesto-componente";
import API from "../../utils/api";
import { isSignedIn, signOut } from "../../utils/auth";
import { validaPermisoPaginaActual } from "../../utils/helpers";
import { ToastContainer, toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import "devextreme/dist/css/dx.material.teal.light.compact.css";
import { Item } from "devextreme-react/form";
import { CheckBox, RadioGroup } from "devextreme-react";
import "devextreme-react/radio-group";
//carga excel
import CargaExcelComponente from "./carga-excel-escala";

const width_proportion = "100%";
const titleColor = "#2B3C4D";

const useStyles = makeStyles((theme) => ({
  paperContent: {
    padding: "6px 16px",
  },
  table: {
    width: "100%",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 210,
  },
  textFieldDireccion: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 230,
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  greenAvatar: {
    fontSize: "2.5em",
    color: "#fff",
    // borderStyle:'solid',
    // borderWidth:'1 !important',
    // borderColor:'#3f51b5 !important',
    backgroundColor: green[500],
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  paperCard: {
    padding: 20,
    width: "100%",
  },
  formControlSelects: {
    // margin: theme.spacing(1),
    minWidth: 400,

    marginBottom: 10,
    marginTop: 10,
    marginLeft: 30,
  },
  formControlSelect: {
    // margin: theme.spacing(1),
    width: width_proportion,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  rootTabla: {
    width: "100%",
    marginTop: theme.spacing.unit * 1,
    overflowX: "auto",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paperModal: {
    position: "absolute",
    width: "90%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflow: "auto", // Add this line to enable scrolling
    maxHeight: "80vh", // Set the maximum height of the modal
  },
}));
const MaestroPuestoLaboral = (props) => {
  const opcionesCreacion = [
    { id: 0, text: "Por Puesto Laboral" },
    { id: 1, text: "Por Sub Nivel de Puesto Laboral " },
  ];

  const classes = useStyles();
  const { codigoEncabezadoEscalaSalarial } = props;
  let applyFilterTypes = [
    {
      key: "auto",
      name: "Immediately",
    },
    {
      key: "onClick",
      name: "On Button Click",
    },
  ];
  const radioGroupRef = useRef(null);
  const datagridRef = createRef();
  const [opcionCreacion, setOpcionCreacion] = useState(opcionesCreacion[0].id);
  // const opcionCreacion = useRef(opcionesCreacion[0].id);
  const [opcion, setOpcion] = useState(0);
  const [itemPuestoLaboral, setItemPuestoLaboral] = useState(null);
  const [userName, setUserName] = useState("");

  const [actualizando, setActualizando] = useState(false);
  const [showFilterRow, setShowFilterRow] = useState(true);
  const [showHeaderFilter, setShowHeaderFilter] = useState(true);
  const [currentFilter, setCurrentFilter] = useState(applyFilterTypes[0].key);
  const [autoExpandAll, setAutoExpandAll] = useState(true);

  const [buscando, setBackDrop] = useState(false);

  const [openModal, setOpenModal] = useState(false);

  const handleClickOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };
  const [encabezadoEscalaSalarial, setEncabezadoEscalaSalarial] = useState(
    new CustomStore({
      key: "CodigoEscalaSalarial",
      load: () =>
        cargarDatos(
          "api/EscalaSalarial/Escala/" + codigoEncabezadoEscalaSalarial
        ),
      insert: (values) => guardarDato("api/EscalaSalarial/", null, values),
      update: (key, values) => guardarDato("api/EscalaSalarial/", key, values),
      remove: (key, values) =>
        guardarDato("api/EscalaSalarial/Eliminar/", key, values),
    })
  );

  const [datosMaestroPuestoLaboral, setDatosMaestroPuestoLaboral] = useState(
    new CustomStore({
      key: "CodigoPuestoLaboral",
      loadMode: "raw",
      load: () => cargarDatos("api/PuestoLaboral"),
    })
  );
  const [
    datosMaestroSubNivelPuestoLaboral,
    setDatosMaestroSubNivelPuestoLaboral,
  ] = useState(
    new CustomStore({
      key: "CodigoSubNivelPuestoLaboral",
      loadMode: "raw",
      load: () => cargarDatos("api/SubNivelPuestoLaboral"),
    })
  );
  const [
    datosMaestroClasificacionPuestoLaboral,
    setDatosMaestroClasificacionPuestoLaboral,
  ] = useState(
    new CustomStore({
      key: "CodigoClasificacionPuestoLaboral",
      loadMode: "raw",
      load: () => cargarDatos("api/ClasificacionPuestoLaboral"),
    })
  );
  const [datosMaestroExpertis, setDatosMaestroExpertis] = useState(
    new CustomStore({
      key: "CodigoExpertis",
      loadMode: "raw",
      load: () => cargarDatos("api/Expertis"),
    })
  );
  const [datosMaestroTipoExperiencia, setDatosMaestroTipoExperiencia] =
    useState(
      new CustomStore({
        key: "CodigoTipoExperiencia",
        loadMode: "raw",
        load: () => cargarDatos("api/TipoExperiencia"),
      })
    );
  const [datosMaestroNivelEducativo, setDatosMaestroNivelEducativo] = useState(
    new CustomStore({
      key: "CodigoNivelEducativo",
      loadMode: "raw",
      load: () => cargarDatos("api/NivelEducativo"),
    })
  );
  const [datosMaestroTipoSalario, setDatosMaestroTipoSalario] = useState(
    new CustomStore({
      key: "CodigoTipoSalario",
      loadMode: "raw",
      load: () => cargarDatos("api/TipoSalario"),
    })
  );

  // const changeSelectionPriority = (e) => {
  //   setOpcionCreacion(e.value);
  //   console.log("nueva selección" + e.value);
  // };

  // useEffect(() => {
  //   console.log("se cambio el valor del tipo " + opcionCreacion);
  //   setOpcion(opcionCreacion);
  // }, [opcionCreacion]);

  const handleChangedValue = useCallback((e) => {
    setOpcionCreacion(e.value);
    // console.log("nueva selección" + e.value);
  }, []);

  async function cargarDatos(url) {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      // console.log(session);
      setUserName(session.userName);
    }

    try {
      handleAbrirBackDrop();

      let uri = url;
      //await delay(1000000);
      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        if (request.data.response) {
          return request.data.ficha;
        } else {
          console.log("EXCEPTION: " + request.data.mensaje);
          toast.error(
            "Hubo un error al cargar los datos. " + request.data.mensaje
          );
          return [];
        }
        //setVerbos(request.data.datos);
      }
    } catch (e) {
      console.log(e);
      toast.error("Hubo un error al cargar los datos. " + e);
    } finally {
      handleCerrarBackDrop();
    }
  }

  const handleCerrarBackDrop = () => {
    setBackDrop(false);
  };
  const handleAbrirBackDrop = () => {
    setBackDrop(true);
  };

  const guardarDato = async (url, key, values) => {
    // console.log(radioGroupRef.current.props.value);
    // console.log(encabezadoEscalaSalarial);
    const opcionCreacion = radioGroupRef.current.props.value;
    const data = {
      CodigoEscalaSalarial: key,
      CodigoSubNivelPuestoLaboral: values
        ? opcionCreacion === 1
          ? values.CodigoSubNivelPuestoLaboral
          : null
        : null,
      CodigoClasificacionPuestoLaboral: values
        ? opcionCreacion === 1
          ? values.CodigoClasificacionPuestoLaboral
          : null
        : null,
      CodigoExpertis: values ? values.CodigoExpertis : null,
      CodigoNivelEducativo: values
        ? opcionCreacion === 1
          ? values.CodigoNivelEducativo
          : null
        : null,
      AniosExperiencia: values ? values.AniosExperiencia : null,
      AniosExperienciaMax: values ? values.AniosExperienciaMax : null,
      Salario: values
        ? values.Salario == null
          ? false
          : values.Salario
        : null,
      CodigoEncabezadoEscalaSalarial: codigoEncabezadoEscalaSalarial,
      CodigoPuestoLaboral: values
        ? opcionCreacion !== 0
          ? null
          : values.CodigoPuestoLaboral
        : null,
      CodigoTipoSalario: values ? values.CodigoTipoSalario : null,
      CodigoTipoExperiencia: values ? values.CodigoTipoExperiencia : null,
      Activo: values ? (values.Activo == null ? true : values.Activo) : null,
      Eliminado: false,
      UsuarioInserto: userName,
      FechaInserto: "",
      UsuarioModifico: "",
      FechaModifico: "",
    };

    console.log(data);
    console.log("bandera:" + opcionCreacion);
    // dataFicha.usuarioInserto = "prueba";
    try {
      handleAbrirBackDrop();
      let vIsSignedIn = await isSignedIn();
      if (vIsSignedIn.response) {
        let request;

        request = await API.post(url, data);
        if (request.status !== 200) {
          toast.error(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        } else {
          if (request.data.response) {
            toast.success("Se guardó exitosamente");
          } else {
            toast.error(
              "Hubo un error al realizar la operación: " + request.data.mensaje
            );
          }
        }
      }
    } catch (e) {
      console.log("EXCEPTION: " + e);
      toast.error(
        "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
      );
    } finally {
      handleCerrarBackDrop();
      setActualizando(false);
    }
  };

  const renderCheckBox = () => {
    return (
      <RadioGroup
        // editorOptions={checkBoxOptions}
        id="radio-group"
        dataSource={opcionesCreacion}
        value={opcionCreacion}
        valueExpr="id"
        displayExpr="text"
        onValueChanged={handleChangedValue}
        ref={radioGroupRef}
      />
    );
  };

  const actualizarGrid = async () => {
    try {
      console.log("actualizando grid");
      datagridRef.current.instance.refresh();
    } catch (error) {
      console.log(error);
    }
  };
  // const checkBoxOptions = useMemo(() => {
  //   return {
  //     id: "radio-group",
  //     dataSource: { opcionesCreacion },
  //     value: { opcionCreacion },
  //     valueExpr: "id",
  //     displayExpr: "text",
  //     onValueChanged: { handleChangedValue },
  //   };
  // }, []);

  return (
    <React.Fragment>
      <Backdrop className={classes.backdrop} open={buscando}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer />
      <Grid container spacing={3}>
        <Grid item xs={6} sm={6} md={12} lg={12}>
          {encabezadoEscalaSalarial !== undefined ? (
            <Paper className={classes.rootTabla}>
              <AppBar
                position="sticky"
                className={classes.appBar}
                style={{ backgroundColor: titleColor }}
              >
                <Toolbar>
                  <Typography
                    variant="h6"
                    noWrap
                    style={{ display: "flex", flexGrow: 1 }}
                  >
                    {`Listado de Escalas Salariales`}
                  </Typography>

                  <Tooltip title={"Cargar Excel"} aria-label="Cargar Excel">
                    <IconButton
                      aria-label="Cargar Excel"
                      style={{ color: "white" }}
                      onClick={handleClickOpen}
                    >
                      <CloudUpload />
                    </IconButton>
                  </Tooltip>
                </Toolbar>
              </AppBar>
              <DataGrid
                dataSource={encabezadoEscalaSalarial}
                //   defaultColumns={this.props.columns}
                showBorders={true}
                rowAlternationEnabled={true}
                //ref={(ref) => (dataGrid = ref)}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                // onExporting={onExporting}
                className={classes.table}
                id="tablaEducacionEmpleado"
                onRowUpdating={(options) => {
                  setActualizando(true);
                  options.newData = { ...options.oldData, ...options.newData };
                }}
                ref={datagridRef}
              >
                <FilterRow
                  visible={showFilterRow}
                  applyFilter={currentFilter}
                />
                <HeaderFilter visible={showHeaderFilter} />
                <GroupPanel visible={false} />
                <Grouping autoExpandAll={autoExpandAll} />
                <SearchPanel
                  visible={false}
                  width={240}
                  placeholder="Search..."
                />
                {/* <Selection mode="multiple" /> */}
                <LoadPanel
                  enabled={true}
                  shadingColor="rgba(0,0,0,0.4)"
                  showIndicator={true}
                  shading={true}
                  showPane={true}
                  closeOnOutsideClick={false}
                />
                <Editing
                  mode="popup"
                  useIcons={true}
                  allowAdding={true}
                  allowUpdating={true}
                  allowDeleting={true}
                >
                  <Popup
                    title="Información de la escala salarial"
                    showTitle={true}
                    width={700}
                    height={525}
                  />
                  <Form colCount={1}>
                    <Item
                      itemType="group"
                      // caption="Home Address"
                      colCount={1}
                      colSpan={1}
                    >
                      <Item name="showOrder" render={renderCheckBox}>
                        <Label text="Opciones" />
                      </Item>
                      {/* <RadioGroup
                        // editorOptions={checkBoxOptions}
                        id="radio-group"
                        items={opcionesCreacion}
                        value={opcionCreacion}
                        valueExpr="id"
                        displayExpr="text"
                        onValueChanged={changeSelectionPriority}
                      /> */}
                    </Item>
                    <Item
                      itemType="group"
                      // caption="Home Address"
                      colCount={1}
                      colSpan={1}
                    >
                      <Item
                        dataField="CodigoPuestoLaboral"
                        disabled={opcionCreacion !== 0}
                        visible={!(opcionCreacion !== 0)}
                      />
                      <Item
                        dataField="CodigoSubNivelPuestoLaboral"
                        disabled={opcionCreacion !== 1}
                        visible={!(opcionCreacion !== 1)}
                      />
                      <Item
                        dataField="CodigoClasificacionPuestoLaboral"
                        disabled={opcionCreacion !== 1}
                        visible={!(opcionCreacion !== 1)}
                      />
                      <Item
                        dataField="CodigoNivelEducativo"
                        disabled={opcionCreacion !== 1}
                        visible={!(opcionCreacion !== 1)}
                      />
                      <Item dataField="CodigoExpertis" />

                      <Item dataField="CodigoTipoExperiencia" />
                      <Item dataField="AniosExperiencia" />
                      <Item dataField="AniosExperienciaMax" />
                      <Item dataField="CodigoTipoSalario" />
                      <Item dataField="Salario" />
                      {/* <Item dataField="UsuarioInserto" visible={false} />
                      <Item dataField="FechaInserto" visible={false} /> */}
                      {/* <Item dataField="UsuarioModifico"> */}
                    </Item>
                  </Form>
                </Editing>
                <Column
                  dataField="CodigoPuestoLaboral"
                  caption="Puesto Laboral"
                >
                  <Lookup
                    dataSource={datosMaestroPuestoLaboral}
                    valueExpr="CodigoPuestoLaboral"
                    displayExpr="PuestoLaboral"

                    // disabled={true}
                  />
                </Column>

                <Column
                  dataField="CodigoSubNivelPuestoLaboral"
                  caption="Sub Nivel Puesto Laboral"
                  // dataType="number"
                >
                  <Lookup
                    dataSource={datosMaestroSubNivelPuestoLaboral}
                    valueExpr="CodigoSubNivelPuestoLaboral"
                    displayExpr="SubNivelPuestoLaboral"
                  />
                </Column>

                <Column
                  dataField="CodigoClasificacionPuestoLaboral"
                  caption="Clasificación Puesto Laboral"
                  // dataType="number"
                >
                  <Lookup
                    dataSource={datosMaestroClasificacionPuestoLaboral}
                    valueExpr="CodigoClasificacionPuestoLaboral"
                    displayExpr="ClasificacionPuestoLaboral"
                  />
                </Column>
                <Column
                  dataField="CodigoNivelEducativo"
                  caption="Nivel Educativo"
                  // dataType="number"
                >
                  <Lookup
                    dataSource={datosMaestroNivelEducativo}
                    valueExpr="CodigoNivelEducativo"
                    displayExpr="NivelEducativo"
                  />
                </Column>
                <Column
                  dataField="CodigoExpertis"
                  caption="Expertis"
                  // dataType="number"
                >
                  <Lookup
                    dataSource={datosMaestroExpertis}
                    valueExpr="CodigoExpertis"
                    displayExpr="Expertis"
                  />
                </Column>

                <Column
                  dataField="CodigoTipoExperiencia"
                  caption="Tipo Experiencia"
                  // dataType="number"
                >
                  <Lookup
                    dataSource={datosMaestroTipoExperiencia}
                    valueExpr="CodigoTipoExperiencia"
                    displayExpr="TipoExperiencia"
                  />
                </Column>
                <Column
                  dataField="AniosExperiencia"
                  caption="Experiencia Rango Inferior"
                  dataType="number"
                />
                <Column
                  dataField="AniosExperienciaMax"
                  caption="Experiencia Rango Superior "
                  dataType="number"
                />
                <Column dataField="CodigoTipoSalario" caption="Tipo Salario">
                  <Lookup
                    dataSource={datosMaestroTipoSalario}
                    valueExpr="CodigoTipoSalario"
                    displayExpr="TipoSalario"
                  />
                </Column>
                <Column
                  dataField="Salario"
                  caption="Salario"
                  dataType="number"
                />
                {/* <Column
                  dataField="UsuarioInserto"
                  caption="UsuarioInserto"
                  visible={false}
                />
                <Column
                  dataField="FechaInserto"
                  caption="FechaInserto"
                  visible={false}
                /> */}
                <ColumnFixing enabled={true} />
                <Sorting mode="multiple" />
                <Paging defaultPageSize={50} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[50, 100]}
                  showInfo={true}
                />
                <Export enabled={true} allowExportSelectedData={true} />
                <Scrolling columnRenderingMode="virtual" />
              </DataGrid>
            </Paper>
          ) : undefined}
        </Grid>
      </Grid>

      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          style={{
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          className={classes.paperModal}
        >
          <h2 id="simple-modal-title">Carga de Escala Salarial por Excel</h2>
          <div id="simple-modal-description">
            <CargaExcelComponente
              codigoEncabezadoEscalaSalarial={codigoEncabezadoEscalaSalarial}
              recargarDatos={actualizarGrid}
              // console.log(encabezadoEscalaSalarial)
              // cargarDatos(
              //   "api/EscalaSalarial/Escala/" + codigoEncabezadoEscalaSalarial
              // )

              cerrarModal={handleClose}
            />
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default MaestroPuestoLaboral;
