import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import DataGrid, {
  Column,
  Pager,
  Paging,
  Sorting,
  ColumnFixing,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Grouping,
  GroupPanel,
  Export,
  Selection,
  Editing,
  Scrolling,
  RowDragging,
} from "devextreme-react/data-grid";
import {
  AppBar,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  Grid,
  IconButton,
  Modal,
  Paper,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import API from "../../utils/api";
import { Button } from "devextreme-react";
import { AssignmentTurnedIn, CancelOutlined, Person } from "@material-ui/icons";
import { isSignedIn } from "../../utils/auth";
import { toast, ToastContainer } from "react-toastify";
import { verificaPermisoPaginaActual } from "../../utils/helpers";

// import PerfilComponenteHorizontal from "../../perfil/containers/perfilComponenteHorizontal";
import ResumenEmpleadoMovimientoComponente from "./resumen-empleado-movimiento-componente";

const titleColor = "#2B3C4D";
let datagridEmpleadosSCILU;

const useStyles = makeStyles((theme) => ({
  tituloPaper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  rootTabla2: {
    // width: "90%",
    marginTop: theme.spacing.unit * 1,
    maxWidth: "1280px",
    // overflowX: "auto",
  },
  table: {
    width: "100%",
  },
  gestionGeneral: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  paperUserModal: {
    position: "absolute",
    width: "75%",
    height: "600px",
    maxHeight: "600px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
  chip: {
    marginLeft: 10,
  },
  container: {
    margin: 10,
  },
  input: {
    marginTop: 15,
  },
  inputTitulo: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  iconColor: {
    color: "#2196F3",
  },
  classChips: {
    textAlign: "left",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  modal: {
    // display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: "10%",
    left: "20%",
    overflow: "scroll",
    height: "100%",
    display: "block",
    padding: theme.spacing(8, 4, 8, 8),
  },
  paperModal: {
    position: "absolute",
    width: "80%",
    height: "95%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1, 1, 1, 1),
  },
  rootTabla: {
    width: "100%",
    height: "100%",
    marginTop: theme.spacing.unit * 1,
    overflowX: "auto",
  },
  backdropDialog: {
    zIndex: theme.zIndex.drawer + 1, // Asegúrate de que sea mayor que el z-index del Dialog
    color: "#fff",
  },
}));

const GridDetalleMovimientosPersonal = (props) => {
  const classes = useStyles();

  let applyFilterTypes = [
    {
      key: "auto",
      name: "Immediately",
    },
    {
      key: "onClick",
      name: "On Button Click",
    },
  ];

  const monedaFormat = {
    style: "currency",
    currency: "GTQ",
    // useGrouping: true,
    minimumSignificantDigits: 3,
  };

  const porcentajeFormat = {
    type: "fixedPoint",
    format: "#0.##'%'",
    // useGrouping: true,
    // minimumSignificantDigits: 3,
  };

  const [buscando, setBackDrop] = useState(false);
  const [backdropAbierto, setBackDropAbierto] = useState(false);
  const [showFilterRow, setShowFilterRow] = useState(true);
  const [currentFilter, setCurrentFilter] = useState(applyFilterTypes[0].key);

  const [dataAviso, setDataAviso] = useState(
    props.dataSolicitudMovimientoPersonal
  );
  const [esUltimoPaso, setEsUltimoPaso] = useState(false);
  const [permisoAnularRegistro, setPermisoAnularRegistro] = useState(false);
  const [permisoEjecutarSCILU, setPermisoEjecutarSCILU] = useState(false);
  const [selectedEmployeeNames, setSelectedEmployeeNames] = useState(
    "No hay empleados seleccionados"
  );

  const [userName, setUserName] = useState("");
  const [aplicarEnSCILU, setAplicarEnSCILU] = useState(true);

  const [dialogoAbierto, abrirDialogo] = useState(false);
  const [dialogoEjecutarAbierto, setDialogoEjecutarAbierto] = useState(false);

  const [dpi, setDpi] = useState("");
  const [nombreEmpleado, setNombreEmpleado] = useState("");
  const [openPerfil, setOpenPerfil] = useState(false);
  const [openModalErrores, setOpenModalErrores] = useState(false);
  const [movimiento, setMovimiento] = useState({});
  const [errores, setErrores] = useState("");
  const handleOpenModalPerfil = () => {
    setOpenPerfil(true);
  };

  const handleCloseModalPerfil = () => {
    setOpenPerfil(false);
  };

  const handleOpenModalErrores = () => {
    setOpenModalErrores(true);
  };

  const handleCloseModalErrores = () => {
    setOpenModalErrores(false);
  };
  const handleCerrarBackDrop = () => {
    setBackDrop(false);
  };
  const handleAbrirBackDrop = () => {
    setBackDrop(true);
  };

  const handleCerrarBackDropDialogo = () => {
    setBackDropAbierto(false);
  };
  const handleAbrirBackDropDialogo = () => {
    setBackDropAbierto(true);
  };

  useEffect(() => {
    const cargar = async () => {
      if (props.aviso.CodigoAviso > 0) {
        // CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
        const llaveAgrupacion = "GESPILU";
        const llaveAnularRegistro =
          "GESPILU-MovimientosPersonal-AnularRegistro";
        let verArea = await verificaPermisoPaginaActual(
          llaveAgrupacion,
          llaveAnularRegistro
        );
        setPermisoAnularRegistro(verArea);

        const llaveEjecutarSCILU = "GESPILU-MovimientosPersonal-EjecutarSCILU";
        let ejecutarSCILU = await verificaPermisoPaginaActual(
          llaveAgrupacion,
          llaveEjecutarSCILU
        );
        setPermisoEjecutarSCILU(ejecutarSCILU);
        // FIN DE CONSULTA DE PERMISOS

        await cargarDatosDetalleTarea();
      }
    };

    cargar();
  }, [props.aviso.CodigoAviso]);

  const cargarDatosDetalleTarea = async () => {
    try {
      const session = await isSignedIn();
      if (!session.response) {
        window.location.href = "/login";
        return;
      } else {
        setUserName(session["userName"]);
      }

      // console.log(props.aviso);
      let uri =
        "api/ReportesGespilu/dashboard/Aviso/" + props.aviso.CodigoAviso;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        return false;
      } else {
        // setDataAviso(request.data.ficha);
        let x =
          request.data.ficha.NumeroTareas -
          request.data.ficha.NumeroTareasTerminadas;

        let y = x === 1;
        // console.log("Es Ultimo PASO = " + y);
        setEsUltimoPaso(y);

        // console.log("IndicadorPasos" + x);
      }
    } catch (e) {
      console.log(e);
    } finally {
    }
  };

  const cargarInfoDetalleTarea = async () => {
    try {
      handleAbrirBackDrop();
      const session = await isSignedIn();
      if (!session.response) {
        window.location.href = "/login";
        return;
      } else {
        setUserName(session["userName"]);
      }

      console.log(props.aviso);
      let uri =
        "api/EmpleadoSolicitudMovimiento/Gestion/" + props.aviso.CodigoAviso;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        return false;
      } else {
        setDataAviso(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const handleAbrirDialogo = () => {
    abrirDialogo(true);
  };

  const handleCerrarDialogo = () => {
    abrirDialogo(false);
  };

  const handleAbrirDialogoEjecutar = (aplicarEnSCILU) => {
    setDialogoEjecutarAbierto(true);
    setAplicarEnSCILU(aplicarEnSCILU);
  };

  const handleCerrarDialogoEjecutar = () => {
    setDialogoEjecutarAbierto(false);
  };

  //selección multiple

  const onInitialized = (e) => {
    datagridEmpleadosSCILU = e.component;
  };

  const deseleccionarItemsEnGridEmpleados = () => {
    datagridEmpleadosSCILU.clearSelection();
  };

  const getEmployeeName = (row) => {
    return `[${row.CodigoAnterior} - ${row.NombreCompleto}]`;
  };

  const getEmployeeNames = (selectedRowsData) => {
    return selectedRowsData.length
      ? selectedRowsData.map(getEmployeeName).join("; ")
      : "No hay empleados seleccionados";
  };

  const onSelectionChanged = ({ selectedRowKeys, selectedRowsData }) => {
    setSelectedEmployeeNames(getEmployeeNames(selectedRowsData));
  };

  const generarSolicitud = async () => {
    const data = {
      ListaSolicitudes: datagridEmpleadosSCILU.getSelectedRowsData(),
      UsuarioInserto: userName,
      AplicarEnSCILU: aplicarEnSCILU,
      //   CodigoUnidadOrganizacion: props.codigoUnidadOrganizacion,
      // CodigoGerencia: props.CodigoGerencia,
    };

    // console.log(data);
    // dataFicha.usuarioInserto = "prueba";
    try {
      handleAbrirBackDropDialogo();
      // handleCerrarDialogoEjecutar();

      let vIsSignedIn = await isSignedIn();
      if (vIsSignedIn.response) {
        let request;

        request = await API.post(
          "api/EmpleadoSolicitudMovimiento/EjecutarSCILU",
          data
        );
        if (request.status !== 200) {
          toast.error(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        } else {
          if (request.data.response) {
            toast.success("Se guardó exitosamente");

            await cargarInfoDetalleTarea();

            deseleccionarItemsEnGridEmpleados();
          } else {
            setErrores(request.data.mensaje);
            handleOpenModalErrores();
          }
        }
      }
    } catch (e) {
      console.log("EXCEPTION: " + e);
      toast.error(
        "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
      );
    } finally {
      handleCerrarDialogoEjecutar();
      handleCerrarBackDropDialogo();
    }
  };

  const anularMovimiento = async () => {
    const data = {
      ListaSolicitudes: datagridEmpleadosSCILU.getSelectedRowsData(),
      UsuarioInserto: userName,
      //   CodigoUnidadOrganizacion: props.codigoUnidadOrganizacion,
      // CodigoGerencia: props.CodigoGerencia,
    };

    console.log(data);
    // dataFicha.usuarioInserto = "prueba";
    try {
      handleAbrirBackDrop();
      let vIsSignedIn = await isSignedIn();
      if (vIsSignedIn.response) {
        let request;

        request = await API.post(
          "api/EmpleadoSolicitudMovimiento/CancelarSolicitud",
          data
        );
        if (request.status !== 200) {
          toast.error(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        } else {
          if (request.data.response) {
            toast.success("Se guardó exitosamente");

            await cargarInfoDetalleTarea();

            deseleccionarItemsEnGridEmpleados();
          } else {
            toast.error(request.data.mensaje);
          }
        }
      }
    } catch (e) {
      console.log("EXCEPTION: " + e);
      toast.error(
        "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
      );
    } finally {
      //   handleCerrarDialogoCrearGestion();
      handleCerrarBackDrop();
      handleCerrarDialogo();
    }
  };
  const solicitarModificacion = async () => {
    const data = {
      ListaSolicitudes: datagridEmpleadosSCILU.getSelectedRowsData(),
      UsuarioInserto: userName,
      CodigoUnidadOrganizacion: props.codigoUnidadOrganizacion,
      // CodigoGerencia: props.CodigoGerencia,
    };

    console.log(data);
    // dataFicha.usuarioInserto = "prueba";
    try {
      //   handleAbrirBackDrop();
      let vIsSignedIn = await isSignedIn();
      if (vIsSignedIn.response) {
        let request;

        request = await API.post(
          "api/EmpleadoSolicitudMovimiento/AplicarCambio",
          data
        );
        if (request.status !== 200) {
          toast.error(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        } else {
          toast.success("Se guardó exitosamente");

          //   await NuevaLista();

          deseleccionarItemsEnGridEmpleados();
        }
      }
    } catch (e) {
      console.log("EXCEPTION: " + e);
      toast.error(
        "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
      );
    } finally {
      //   handleCerrarDialogoCrearGestion();
      //   handleCerrarBackDrop();
    }
  };

  const cellOpciones = (rowInfo) => {
    return (
      <>
        <Tooltip
          title="Detalle del movimiento"
          style={{ marginRight: 10, cursor: "pointer" }}
        >
          <Person
            style={{ color: "#337ab7", cursor: "pointer" }}
            onClick={() => handleAbrirPerfil(rowInfo.data)}
          />
        </Tooltip>
      </>
    );
  };

  const handleAbrirPerfil = (rowInfo) => {
    handleOpenModalPerfil();
    setDpi(rowInfo.DPI);
    setNombreEmpleado(rowInfo.NombreCompleto);
    setMovimiento(rowInfo);
  };
  return (
    <React.Fragment>
      <ToastContainer />
      <Backdrop className={classes.backdrop} open={buscando}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* Dialogo Eliminar */}
      <Dialog
        open={dialogoAbierto}
        onClose={handleCerrarDialogo}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Anular Registros</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro que deseas anular los registros seleccionados?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCerrarDialogo} color="primary">
            Cancelar
          </Button>
          <Button onClick={anularMovimiento} color="secondary">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
      {/* Dialogo Ejecutar SCILU */}
      <div>
        <Backdrop className={classes.backdropDialogo} open={backdropAbierto}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Dialog
          open={dialogoEjecutarAbierto}
          onClose={handleCerrarDialogoEjecutar}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Ejecutar cambios en SCILU
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              ¿Estás seguro que deseas ejecutar los cambios de los registros
              seleccionados?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCerrarDialogoEjecutar}
              color="primary"
              disabled={backdropAbierto}
            >
              Cancelar
            </Button>
            <Button
              onClick={generarSolicitud}
              color="secondary"
              disabled={backdropAbierto}
            >
              {backdropAbierto ? "Cargando..." : "Ejecutar"}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <Paper className={classes.rootTabla2}>
        <Typography variant="h5">Lista de Movimientos</Typography>
        <DataGrid
          dataSource={dataAviso}
          //   defaultColumns={this.props.columns}
          showBorders={true}
          rowAlternationEnabled={true}
          //ref={(ref) => (dataGrid = ref)}
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
          // onExporting={onExporting}
          className={classes.table}
          onInitialized={onInitialized}
          onSelectionChanged={onSelectionChanged}
        >
          <FilterRow visible={showFilterRow} applyFilter={currentFilter} />
          {/* <HeaderFilter visible={showHeaderFilter} /> */}
          <GroupPanel visible={false} />
          {/* <Grouping autoExpandAll={autoExpandAll} /> */}
          <SearchPanel visible={false} width={240} placeholder="Search..." />
          {/* <Selection mode="multiple" /> */}
          <Editing
            mode="row"
            useIcons={true}
            allowUpdating={false}
            allowDeleting={false}
          />
          <Selection mode="multiple" />
          {/* <Scrolling showScrollbar="always" useNative={false}/> */}
          <Column
            caption="Opc."
            allowFiltering={false}
            allowSorting={false}
            cellRender={cellOpciones}
            fixedPosition="left"
            fixed={true}
          />
          <Column
            dataField="NombreCompleto"
            caption="Nombre Completo"
            fixed={true}
            fixedPosition="left"
          />
          <Column
            dataField="CodigoAnterior"
            caption="Código Empleado"
            // fixed={true}
            // fixedPosition="left"
          />
          <Column dataField="DPI" caption="DPI" />
          <Column
            dataField="TipoMovimientoPersonal"
            caption="Tipo Movimiento"
          />
          <Column
            dataField="EstadoMovimientoPersonal"
            caption="Estado Movimiento "
          />
          <Column
            dataField="FechaEjecucion"
            caption="Fecha de Movimiento"
            dataType="date"
            format="dd/MM/yyyy"
          />
          <Column dataField="AgrupacionDePago" caption="Agrupación de Pago" />
          <Column
            dataField="CodigoDepartamentoAnterior"
            caption="Cod. Departamento Actual"
            visible={false}
          />
          <Column
            dataField="DepartamentoAnterior"
            caption="Departamento Actual"
            // visible={false}
          />
          <Column
            dataField="CodigoDepartamento"
            caption="Cod. Departamento Nuevo"
            visible={false}
          />
          <Column
            dataField="Gerencia"
            caption="Gerencia Nueva"
            // visible={false}
          />
          <Column
            dataField="Area"
            caption="Área Nueva "
            // visible={false}
          />
          <Column
            dataField="Departamento"
            caption="Departamento Nuevo "
            // visible={false}
          />
          <Column
            dataField="SubDepartamento"
            caption="SubDepartamento Nuevo "
            // visible={false}
          />
          {/* <Column
          dataField="Departamento"
          caption="Departamento Nuevo "
          // visible={false}
        /> */}

          <Column
            dataField="SalarioAnterior"
            caption="Salario Actual"
            format={monedaFormat}
          />
          <Column
            dataField="SalarioSolicitado"
            caption="Nuevo Salario"
            format={monedaFormat}
          />
          {/* <Column dataField="SalarioSolicitado" captión="Salario Diario Solicitado"/> */}

          <Column
            dataField="CodigoAnteriorJefeInmediato"
            caption="Código Jefe"
          />
          <Column dataField="JefeInmediato" caption="Jefe Inmediato" />
          <Column dataField="MotivoMovimientoPersonal" caption="Motivo " />
          <Column dataField="RazonMovimientoPersonal" caption="Razón " />
          <Column dataField="NoContratable" caption="No Requerido " />
          <Column
            dataField="CodigoPuestoLaboralAnterior"
            caption="Código Puesto Laboral Actual"
            visible={false}
          />
          <Column
            dataField="PuestoLaboralAnterior"
            caption="Puesto Laboral Actual"
          />
          <Column
            dataField="CodigoPuestoLaboral"
            caption="Código Puesto Laboral Nuevo"
          />
          <Column dataField="PuestoLaboral" caption="Puesto Laboral Nuevo" />
          <Column
            dataField="ClasificacionPuestoLaboral"
            caption="Clasificación"
          />
          <Column dataField="TipoTurno" caption="Tipo Turno" />
          <Column
            dataField="CodigoTipoObjetoSAP"
            caption="CodigoTipoObjetoSAP"
            visible={false}
          />
          <Column dataField="TipoObjetoSAP" caption="Tipo Objeto SAP" />
          <Column dataField="ObjetoSAP" caption="Objeto SAP" />
          <Column dataField="TextoObjetoSAP" caption="Texto SAP" />
          <Column
            dataField="CodigoTemporadaAnterior"
            caption="CodigoTemporadaAnterior"
            visible={false}
          />
          <Column dataField="TemporadaAnterior" caption="Temporada Anterior" />
          <Column
            dataField="CodigoTemporada"
            caption="CodigoTemporada"
            visible={false}
          />
          <Column dataField="Temporada" caption="Temporada" />
          <Column dataField="EsReemplazo" caption="Es Reemplazo" />
          <Column datafield="NombreReemplazado" caption="Reemplazado" />
          <Column
            dataField="CentroDePago"
            caption="Centro de Pago"
            // visible={false}
          />
          <Column dataField="Comentario" caption="Comentario" />
          <ColumnFixing enabled={true} />
          <Sorting mode="multiple" />
          <Paging defaultPageSize={20} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[10, 20, 50]}
            showInfo={true}
          />
          <Export enabled={true} allowExportSelectedData={true} />
          <Scrolling columnRenderingMode="virtual" />
        </DataGrid>

        <React.Fragment>
          <div className={classes.selectedData}>
            <span className={classes.selectedDataCaption}>
              Empleados seleccionados:
            </span>{" "}
            <span>{selectedEmployeeNames}</span>
          </div>
          <Typography variant="h6">Acciones</Typography>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {/* <input type="submit" /> */}
            {esUltimoPaso && permisoEjecutarSCILU && (
              <>
                <Button
                  // fullWidth
                  variant="contained"
                  style={{
                    backgroundColor: "#69BD4B",
                    color: "white",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  // type="submit"
                  size="small"
                  onClick={() => handleAbrirDialogoEjecutar(true)}
                  startIcon={<AssignmentTurnedIn />}
                >
                  Aplicar Cambio en Nómina
                </Button>
                <Button
                  // fullWidth
                  variant="contained"
                  style={{
                    backgroundColor: "#69BD4B",
                    color: "white",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  // type="submit"
                  size="small"
                  onClick={() => handleAbrirDialogoEjecutar(false)}
                  startIcon={<AssignmentTurnedIn />}
                >
                  Aplicar Cambio solo GESPILU
                </Button>
              </>
            )}
            {permisoAnularRegistro && (
              <Button
                // fullWidth
                variant="contained"
                style={{
                  backgroundColor: "#69BD4B",
                  color: "white",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                // type="submit"
                size="small"
                onClick={handleAbrirDialogo}
                startIcon={<AssignmentTurnedIn />}
              >
                Anular Movimiento
              </Button>
            )}

            {/* <Tooltip title="Solicitar corrección">
              <Button
                // fullWidth
                variant="contained"
                style={{
                  backgroundColor: "#69BD4B",
                  color: "white",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                // type="submit"
                size="small"
                onClick={solicitarModificacion}
                startIcon={<AssignmentTurnedIn />}
              >
                Solicitar Corrección
              </Button>
            </Tooltip> */}
          </Grid>
        </React.Fragment>
      </Paper>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openPerfil}
        onClose={handleCloseModalPerfil}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openPerfil}>
          {/* <div className={classes.paperModal}> */}
          {/* <h2 id="simple-modal-title">Expediente Laboral</h2> */}

          <Paper className={classes.rootTabla}>
            <AppBar
              position="sticky"
              className={classes.appBar}
              style={{ backgroundColor: titleColor }}
            >
              <Toolbar>
                <Tooltip title={"Cerrar"} aria-label="Cerrar">
                  <IconButton
                    aria-label="Cerrar el perfil del empleado"
                    style={{ color: "white" }}
                  >
                    <CancelOutlined onClick={handleCloseModalPerfil} />
                  </IconButton>
                </Tooltip>

                <Typography
                  variant="h6"
                  noWrap
                  style={{ display: "flex", flexGrow: 1 }}
                >
                  {`Perfil de : ` + nombreEmpleado}
                </Typography>
              </Toolbar>
            </AppBar>
            {dpi !== "" && (
              <ResumenEmpleadoMovimientoComponente
                dpi={dpi}
                movimiento={movimiento}
              />
            )}
          </Paper>
          {/* </div> */}
        </Fade>
      </Modal>
      {/* Resumen de errores que devuelve Compensaciones al momento de ejecutar */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModalErrores}
        onClose={handleCloseModalErrores}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModalErrores}>
          <div className={classes.paperModal}>
            {/* <h2 id="simple-modal-title">Expediente Laboral</h2> */}

            <Paper className={classes.rootTabla}>
              <AppBar
                position="sticky"
                className={classes.appBar}
                style={{ backgroundColor: titleColor }}
              >
                <Toolbar>
                  <Tooltip title={"Cerrar"} aria-label="Cerrar">
                    <IconButton aria-label="Cerrar" style={{ color: "white" }}>
                      <CancelOutlined onClick={handleCloseModalErrores} />
                    </IconButton>
                  </Tooltip>

                  <Typography
                    variant="h6"
                    noWrap
                    style={{ display: "flex", flexGrow: 1 }}
                  >
                    {`Listado de Errores`}
                  </Typography>
                </Toolbar>
              </AppBar>
              <Paper className={classes.paper}>
                <Typography variant="body1" gutterBottom>
                  {errores}
                </Typography>
              </Paper>
            </Paper>
          </div>
        </Fade>
      </Modal>
    </React.Fragment>
  );
};

export default GridDetalleMovimientosPersonal;
