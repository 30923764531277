import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import { withStyles } from "@material-ui/core/styles";
import Layout from "../../layout/containers/Layout";
import Stepper from "../components/crear-notificacion-stepper"; // Verifica esta ruta
import FormularioEvento from "../../eventos-calendario/components/formulario-evento"; // Ruta ajustada
import API from "../../utils/api";
import { isSignedIn } from "../../utils/auth";
import {
  getCurrentDateTime,
  validaPermisoPaginaActual,
} from "../../utils/helpers";
import { format, isValid } from "date-fns";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
});

class NotificacionesList extends Component {
  state = {
    titulo: "",
    descripcion: "",
    cuerpo: "",
    codigoNotificacionEstado: 0,
    gruposSeleccionados: {},
    gruposDisponibles: [],
    fecha: "",
    imagen: null,
    seleccionoAhora: false,
    editar: false,
    CodigoNotificacion: 0,
    crearEvento: false,
    Titulo: null,
    Descripcion: null,
    Fecha: null,
    TodoElDia: false,
    HoraInicio: null,
    HoraFin: null,
    Color: "#202e3c",
    loading: false,
  };

  async componentDidMount() {
    const request = await API.fetchGetRequest("api/grupo/getGrupos");
    this.setState({ gruposDisponibles: request.data });

    const llaveAgrupacion = "Menu.Principal.Web";
    const llavePagina = "Menu.Principal.Web.Notificaciones";
    await validaPermisoPaginaActual(llaveAgrupacion, llavePagina);
  }

  async componentWillMount() {
    const llaveAgrupacion = "Menu.Principal.Web";
    const llavePagina = "Menu.Principal.Web.Notificaciones";
    await validaPermisoPaginaActual(llaveAgrupacion, llavePagina);

    if (
      typeof this.props.location.state !== "undefined" &&
      typeof this.props.location.state.CodigoNotificacion !== "undefined"
    ) {
      const requestEdit = await API.fetchGetRequest(
        "api/notificacion/getNotificacion/" +
          this.props.location.state.CodigoNotificacion
      );

      let gruposSeleccionados = {};
      requestEdit.data.ListadoGrupos.forEach((grupo) => {
        gruposSeleccionados[grupo.CodigoGrupo] = true;
      });

      this.setState({
        CodigoNotificacion: this.props.location.state.CodigoNotificacion,
        titulo: requestEdit.data.Titulo,
        descripcion: requestEdit.data.DescripcionCorta,
        cuerpo: requestEdit.data.Cuerpo,
        fecha: requestEdit.data.FechaEnvio,
        gruposSeleccionados: gruposSeleccionados,
        codigoNotificacionEstado: requestEdit.data.CodigoNotificacionEstado,
        editar: true,
      });
    }

    const request = await API.fetchGetRequest("api/grupo/getGrupos");
    this.setState({ gruposDisponibles: request.data });
  }

  agregarNotificacion = (a) => {
    this.props.history.push("/notificaciones");
  };

  guardarInputs = (llave, value) => {
    this.setState({
      [llave]: value,
    });
  };

  seleccionarGrupo = (name) => (event) => {
    let gruposSeleccionados = this.state.gruposSeleccionados;
    gruposSeleccionados[name] = event.target.checked;
    this.setState({ gruposSeleccionados });
  };

  fechaHoy = () => {
    var currentDateTime = getCurrentDateTime();
    this.setState({ fecha: currentDateTime, seleccionoAhora: true });
  };

  seleccionarOtraFecha = () => {
    this.setState({ fecha: "", seleccionoAhora: false });
  };

  handleCrearEventoChange = (event) => {
    this.setState({ crearEvento: event.target.checked });
  };

  validarCamposEvento = () => {
    const {
      Titulo,
      Descripcion,
      Fecha,
      TodoElDia,
      HoraInicio,
      HoraFin,
      Color,
    } = this.state;
    if (
      !Titulo ||
      !Descripcion ||
      !Fecha ||
      !Color ||
      (!TodoElDia && (!HoraInicio || !HoraFin))
    ) {
      return false;
    }
    return true;
  };

  guardar = async () => {
    const { history } = this.props;
    try {
      const visSignedIn = await isSignedIn();
      let gruposSeleccionados = this.state.gruposSeleccionados;
      let gruposSeleccionadosString = "";

      for (let [key, value] of Object.entries(gruposSeleccionados)) {
        if (value) {
          gruposSeleccionadosString += key + ",";
        }
      }
      gruposSeleccionadosString = gruposSeleccionadosString.replace(
        /(^,)|(,$)/g,
        ""
      );

      if (this.state.crearEvento && !this.validarCamposEvento()) {
        toast.error("Revisa que todos los campos de eventos estén llenos");
        return false;
      }

      const objData = new FormData();
      objData.append("Imagen", this.state.imagen);
      objData.append("Titulo", this.state.titulo);
      objData.append("DescripcionCorta", this.state.descripcion);
      objData.append("Cuerpo", this.state.cuerpo);
      objData.append("CodigoNotificacionTipo", 1);
      objData.append("FechaEnvio", this.state.fecha);
      objData.append("UsuarioInserto", visSignedIn["userName"]);
      objData.append("gruposSeleccionados", gruposSeleccionadosString);

      let codigoNotificacion = null;
      if (this.state.editar) {
        objData.append("UsuarioModifico", visSignedIn["userName"]);
        const request2 = await API.post(
          "api/notificacion/actualizar/" + this.state.CodigoNotificacion,
          objData
        );
        codigoNotificacion = this.state.CodigoNotificacion;
      } else {
        const request2 = await API.post("api/notificacion/agregar", objData);
        codigoNotificacion = request2.data.CodigoNotificacion;
      }

      if (this.state.crearEvento && codigoNotificacion) {
        const data = { ...this.state };
        data.Fecha = format(data.Fecha, "yyyy-MM-dd");
        if (!data.TodoElDia) {
          data.HoraInicio = format(data.HoraInicio, "HH:mm:ss");
          data.HoraFin = format(data.HoraFin, "HH:mm:ss");
        } else {
          data.HoraInicio = null;
          data.HoraFin = null;
        }
        data.usuario = visSignedIn["userName"];
        data.CodigoNotificacion = codigoNotificacion;

        this.setState({ loading: true });
        await API.post("/api/calendarioEvento", data);
      }

      toast.success("Notificación y Evento creados con éxito.");
      history.push("/notificaciones");
      return true;
    } catch (e) {
      alert(
        "No se pudo crear o actualizar su notificación, intentalo de nuevo más tarde"
      );
      return false;
    }
  };

  onChangeValue = (name, type) => (event) => {
    let value = event;
    if (type === 1) value = event.target.value; // Inputs de texto
    if (type === 4) value = event.target.checked; // Checkbox
    this.setState({ [name]: value });
  };

  render() {
    const { classes } = this.props;
    const isFormValid = this.validarCamposEvento();
    return (
      <Layout titulo="Notificaciones generales y Crear Evento">
        <Stepper
          titulo={this.state.titulo}
          descripcion={this.state.descripcion}
          cuerpo={this.state.cuerpo}
          fecha={this.state.fecha}
          codigoNotificacionEstado={this.state.codigoNotificacionEstado}
          seleccionarGrupo={this.seleccionarGrupo}
          gruposSeleccionados={this.state.gruposSeleccionados}
          fechaHoy={this.fechaHoy}
          seleccionarOtraFecha={this.seleccionarOtraFecha}
          seleccionoAhora={this.state.seleccionoAhora}
          gruposDisponibles={this.state.gruposDisponibles}
          guardarInputs={this.guardarInputs}
          imagen={this.state.imagen}
          guardar={this.guardar}
          editar={this.state.editar}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={this.state.crearEvento}
              onChange={this.handleCrearEventoChange}
              color="primary"
            />
          }
          label="Crear Evento"
        />

        {this.state.crearEvento && (
          <div>
            <Typography variant="h5" gutterBottom>
              Ingrese los datos del evento
            </Typography>
            <FormularioEvento
              values={this.state}
              onChangeValue={this.onChangeValue}
              guardar={this.guardar}
              loading={this.state.loading}
              isFormValid={isFormValid}
            />
          </div>
        )}

        <ToastContainer />
      </Layout>
    );
  }
}

const NotificacionesListContainer = withStyles(styles)(NotificacionesList);
export default withRouter(NotificacionesListContainer);
