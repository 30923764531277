import { Box, Button, Checkbox, Grid, IconButton, TextField, Typography } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import FolderIcon from "@material-ui/icons/Folder";

import Modal from "@material-ui/core/Modal";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Layout from "../../layout/containers/Layout";
import SecondNavigation from "../../super-filtro/components/second-navigation";
import API from "../../utils/api";
import { isSignedIn } from "../../utils/auth";
import ReporteUniversalGrid from "../components/reporteUniversalGrid";


const ReporteUniversalContainer = (props) => {
    const classes = styles();

    const [fechaInicio, setFechaInicio] = useState(new Date().toISOString().slice(0, 10));
    const [fechaFin, setFechaFin] = useState(new Date().toISOString().slice(0, 10));
    const [incluirTareas, setIncluirTareas] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [tipoGestion, setTipoGestion] = useState(null);
    const [listaTipoGestion, setListaTipoGestion] = useState([]);
    const [openNavegador, setOpenNavegador] = useState(false);
    const [codigoTipoGestion, setCodigoTipoGestion] = useState(0);
    const [updateCounter, setUpdateCounter] = useState(0);


    const actualizarFiltros = (e) => {
        e.preventDefault();
        setFechaInicio(document.getElementById("date-inicio").value);
        setFechaFin(document.getElementById("date-fin").value);
        setIncluirTareas(document.getElementById("incluirTareas").checked);
        setUpdateCounter(updateCounter + 1);
        setSubmitted(true);
    }

    useEffect(() => {
        //reutilizamos functión de cargarListaTipoGestion, que toma en cuenta los permisos del usuario
        try {
            cargarListaTipoGestion();
        }
        catch (error) {
            console.log(error);
            toast.error("Ocurrió un error al cargar los datos");
        }
    }, []);

    const getCodigoGestion = () => {
        return tipoGestion.CodigoProblema;
    }

    const cargarListaTipoGestion = async () => {
        const session = await isSignedIn();
        if (!session.response) {
            window.location.href = "/login";
            return;
        }
        const request = await API.fetchGetRequest(
            `api/carpetaController/listadoGestiones/${session.codigoUsuario}`
        );

        if (Array.isArray(request.data.asignados)) {
            setListaTipoGestion(request.data.asignados);
            console.log(request.data.asignados);
        }
    };

    const elegirTipoGestion = (codigo, llave) => {
        console.log("elegirTipoGestion", codigo, llave);
        let objTipoGestion = listaTipoGestion.filter((el) => {
            return el.CodigoProblema === codigo;
        });
        if (!Array.isArray(objTipoGestion) && objTipoGestion.length < 1) {
            return;
        }
        console.log("objTipoGestion", objTipoGestion);
        setTipoGestion(objTipoGestion[0]);
        setCodigoTipoGestion(codigo);
        setOpenNavegador(false);
    };

    const listarProblemasCarpeta = async (carpeta) => {
        const request = await API.fetchGetRequest(
            "api/carpetaController/listarProblemasCarpeta/" +
            carpeta.Codigo +
            "/" +
            carpeta.Llave
        );
        console.log("Datos carpet", request.data.data);
        if (Array.isArray(request.data.data)) {
            setOpenNavegador(false);
            removerTipoGestion();
        }
    };

    const removerTipoGestion = () => {
        //setObjTipoGestionSeleccionado(null);
        //setCodigoTipoGestion(null);
    };

    const getModalStyle = () => {
        const top = 50;
        const left = 50;

        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
        };
    }

    //returns a the current date for the textfield, in local time zone
    const getCurrentDate = () => {
        let now = new Date();
        let localDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        return localDate.toISOString().slice(0, 10);
    };

    return (
        <Layout titulo={`Reporte Universal de Gestiones`} maxWidth={"100%"}>
            <React.Fragment>
                <ToastContainer />
                <Box className={classes.paperPerfil} pl={0} pt={0}>
                    <Box className={classes.box} pl={0} pt={0}>
                        <br></br>

                        <Grid container spacing={0}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <form className={classes.container} noValidate onSubmit={actualizarFiltros}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={3} style={{ display: 'flex' }}>
                                            <Autocomplete
                                                id="tipo-gestion"
                                                style={{ flex: 'auto' }}
                                                options={listaTipoGestion}
                                                getOptionLabel={(option) => option.Nombre ? option.Nombre : ""}
                                                value={tipoGestion}
                                                getOptionSelected={(option, value) => {
                                                    return option.CodigoProblema === value.CodigoProblema;
                                                }}
                                                onChange={(event, newValue) => {
                                                    setTipoGestion(newValue);
                                                    if (newValue === null) setCodigoTipoGestion(0);
                                                    else setCodigoTipoGestion(newValue.CodigoProblema);
                                                }}
                                                clearOnEscape={true}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Tipo de Gestión"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                            <IconButton
                                                onClick={() => {
                                                    setOpenNavegador(true);
                                                }}
                                            >
                                                <FolderIcon color="primary" />
                                            </IconButton>
                                        </Grid>
                                        <Grid item xs={12} sm={3} style={{ display: 'flex', flexDirection: 'row' }}>
                                            <TextField
                                                id="date-inicio"
                                                label="Fecha de Inicio"
                                                type="date"
                                                defaultValue={getCurrentDate()}
                                                className={classes.textField}
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                            <TextField
                                                id="date-fin"
                                                label="Fecha de Fin"
                                                type="date"
                                                defaultValue={getCurrentDate()}
                                                variant="outlined"
                                                className={classes.textField}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3} style={{ alignSelf: "center" }}>
                                            <Checkbox id="incluirTareas" color="primary" />
                                            <label htmlFor="incluirTareas">Incluir Tareas</label>

                                        </Grid>
                                        {/* <Grid item xs={12} sm={3}>
                                            <FormControlLabel
                                                control={<Checkbox id="resumir" color="primary" />}
                                                label="Resumir Reporte"
                                            />
                                        </Grid> */}
                                        <Grid item xs={12} sm={12} style={{ textAlign: "right", marginRight: "100px" }}>
                                            <Button
                                                className="btn btn-primary"
                                                type="submit"
                                                variant="contained"
                                                style={{
                                                    backgroundColor: "#69bd4b",
                                                    color: "white",
                                                }}
                                            >Generar Reporte</Button>
                                        </Grid>
                                    </Grid>
                                </form>

                            </Grid>
                        </Grid>
                        <br></br>
                    </Box>
                    {submitted && codigoTipoGestion > 0 && (
                        <ReporteUniversalGrid key={updateCounter} FechaInicio={fechaInicio} FechaFin={fechaFin} TipoGestion={getCodigoGestion()} IncluirTareas={incluirTareas}></ReporteUniversalGrid>
                    )}
                </Box>
                <Modal
                    // style={{ zIndex: 2990 }}
                    aria-labelledby="Elegir tipo de gestión"
                    aria-describedby="Elegir tipo de gestión"
                    open={openNavegador}
                    onClose={() => {
                        setOpenNavegador(false);
                    }}
                >
                    <div
                        style={getModalStyle()}
                        className={classes.secondNavigationModal}
                    >
                        {/* <PerfectScrollbar> */}
                        <form className={classes.container} noValidate autoComplete="off">
                            <Typography variant="h6" gutterBottom>
                                Elegir tipo de gestión
                            </Typography>

                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <SecondNavigation
                                        elegirTipoGestion={elegirTipoGestion}
                                        listarProblemasCarpeta={listarProblemasCarpeta}
                                    />
                                </Grid>
                            </Grid>
                        </form>
                        {/* </PerfectScrollbar> */}
                    </div>
                </Modal>

            </React.Fragment>
        </Layout>
    );
};

const styles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexWrap: "wrap",
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: "100%",
    },
    paperPerfil: {
        position: "relative",
        width: "100%", //theme.spacing.unit * 50,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 1,
        outline: "none",
    },
    paper: {
        position: "absolute",
        width: theme.spacing.unit * 100,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 1,
        outline: "none",
    },
    buttonAdd: {
        margin: theme.spacing.unit,
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    large: {
        width: theme.spacing(12),
        height: theme.spacing(12),
    },
    greenAvatar: {
        fontSize: "2.5em",
        color: "#fff",
        // borderStyle:'solid',
        // borderWidth:'1 !important',
        // borderColor:'#3f51b5 !important',
        backgroundColor: green[500],
        width: theme.spacing(12),
        height: theme.spacing(12),
    },
    paperCard: {
        padding: 20,
        width: "100%",
    },
    box: {
        position: "relative",
        width: "100%", //theme.spacing.unit * 50,
        padding: theme.spacing.unit * 1,
        outline: "none",
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    secondNavigationModal: {
        position: "absolute",
        width: "50%",
        height: "400px",
        maxHeight: "400px",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 2,
        outline: "none",
    },
}));

export default withRouter(ReporteUniversalContainer);