import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import {
  Typography,
  Backdrop,
  CircularProgress,
  Tab,
  Tabs,
  Box,
  Divider,
  TabScrollButton,
} from "@material-ui/core";

import Avatar from "@material-ui/core/Avatar";
import Container from "@material-ui/core/Container";
import { green } from "@material-ui/core/colors";
import Chip from "@material-ui/core/Chip";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import DnsIcon from "@material-ui/icons/Dns";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import PeopleIcon from "@material-ui/icons/People";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
//iconos

import {
  Face,
  PersonPin,
  AssignmentInd,
  FlightTakeoff,
} from "@material-ui/icons";

//input date
import API from "../../utils/api";
import PropTypes from "prop-types";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";

import Autocomplete from "@material-ui/lab/Autocomplete";

//Own components
// import Layout from "../../layout/containers/Layout";

import ComponentePuestoCompetencia from "../components/competencia-puesto-componente";

const a11yProps = (index) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const MyTabScrollButton = withStyles((theme) => ({
  root: {
    width: 28,
    overflow: "hidden",
    transition: "width 0.5s",
    "&.Mui-disabled": {
      width: 0,
    },
  },
}))(TabScrollButton);

function TabPanelVertical(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={"span"} variant={"body2"}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

TabPanelVertical.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yPropsVertical(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const styles = makeStyles((theme) => ({
  item: {
    //border: "1px solid blue",
    margin: "10",
  },
  itemFlexGrow: {
    flexGrow: 1,
    // border: "1px solid red"
  },
  content: {
    display: "flex",
    // justify-content: "space-between",
    maxWidth: "400px",
    margin: "0 auto",
    padding: "10px 0",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
  },
  paperPerfil: {
    position: "relative",
    width: "100%", //theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  greenAvatar: {
    fontSize: "2.5em",
    color: "#fff",
    // borderStyle:'solid',
    // borderWidth:'1 !important',
    // borderColor:'#3f51b5 !important',
    backgroundColor: green[500],
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  paperCard: {
    padding: 20,
    width: "100%",
  },
  greenAvatarFoto: {
    fontSize: "2.5em",
    color: "#fff",
    // borderStyle:'solid',
    // borderWidth:'1 !important',
    // borderColor:'#3f51b5 !important',
    backgroundColor: green[500],
    width: theme.spacing(18),
    height: theme.spacing(18),
  },
  tabsVertical: {
    borderRight: `1px solid ${theme.palette.divider}`,
    overflow: "visible",
  },
  contenedorTabVertical: {
    //flexGrow: 1,
    //backgroundColor: theme.palette.background.paper,
    display: "flex",
    //height: 224,
  },
}));

const Perfil = (props) => {
  const [tabVertical, setTabVertical] = useState(0);
  const [tipos, setTipos] = useState([]);

  const classes = styles();

  useEffect(() => {
    const cargar = async () => {
      // CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
      await cargarTipoSkill();
    };
    cargar();
  }, [props.puestoLaboral]);

  const cargarTipoSkill = async () => {
    const request = await API.fetchGetRequest(
      `api/competencia/tiposkill/listar`
    );
    if (Array.isArray(request.data.data)) {
      console.log("Estos son los tipos de skill", request.data);
      setTipos(request.data.data);
    }
  };

  return (
    <React.Fragment>
      <Container maxWidth="xl">
        <ComponentePuestoCompetencia
          puestoLaboral={props.puestoLaboral}
          // tipoSkillSelected={item.CodigoTipoSkill}
        />
        {/* <Box className={classes.contenedorTabVertical}>
          <Tabs
            value={tabVertical}
            indicatorColor="primary"
            textColor="primary"
            onChange={(_, val) => setTabVertical(val)}
            className={classes.tabsVertical}
            orientation="vertical"
            variant="scrollable"
            aria-label="Vertical tabs example"
          >
            {tipos.map((item, i) => (
                <Tab
                label={item.Nombre}
                // icon={<PersonPin />}
                {...a11yPropsVertical(i)}
                style={{ textTransform: "none" }}
              />
            ))}
          </Tabs>
          
          {tipos.map((item, i) => (
            <TabPanelVertical id={item.Nombre} value={tabVertical} index={i}>
                <Box className={classes.box} pl={0} pt={0}>
                <ComponentePuestoCompetencia 
                    puestoLaboral={props.puestoLaboral} 
                    tipoSkillSelected={item.CodigoTipoSkill}
                />
                </Box>
            </TabPanelVertical>
            ))}
          
        </Box> */}
      </Container>

      <ToastContainer />
    </React.Fragment>
  );
};

export default withRouter(Perfil);
