import API from "../utils/api";
import { isSignedIn, signOut } from "../utils/auth";

export const formatDate = (d) => {
  // dd Mes
  var date = new Date(d);

  if (isNaN(date.getTime())) {
    return d;
  } else {
    var month = new Array();
    month[0] = "Enero";
    month[1] = "Febrero";
    month[2] = "Marzo";
    month[3] = "Abril";
    month[4] = "Mayo";
    month[5] = "Junio";
    month[6] = "Julio";
    month[7] = "Agosto";
    month[8] = "Septiembre";
    month[9] = "Octubre";
    month[10] = "Noviembre";
    month[11] = "Diciembre";

    let day = date.getDate();

    if (day < 10) {
      day = "0" + day;
    }

    //return day + " " + month[date.getMonth()] + " " + date.getFullYear();
    return day + " " + month[date.getMonth()];
  }
};

export const convertToMonthYear = (d) => {
  // Mes yyyy
  var date = new Date(d);

  if (isNaN(date.getTime())) {
    return d;
  } else {
    var month = new Array();
    month[0] = "Enero";
    month[1] = "Febrero";
    month[2] = "Marzo";
    month[3] = "Abril";
    month[4] = "Mayo";
    month[5] = "Junio";
    month[6] = "Julio";
    month[7] = "Agosto";
    month[8] = "Septiembre";
    month[9] = "Octubre";
    month[10] = "Noviembre";
    month[11] = "Diciembre";

    let day = date.getDate();

    if (day < 10) {
      day = "0" + day;
    }

    //return day + " " + month[date.getMonth()] + " " + date.getFullYear();
    return month[date.getMonth()] + " " + date.getFullYear();
  }
};

export const convertToDayMonthYear = (d) => {
  // dd de Mes de yyyy hh:mm
  var date = new Date(d);

  if (isNaN(date.getTime())) {
    return d;
  } else {
    var month = new Array();
    month[0] = "Enero";
    month[1] = "Febrero";
    month[2] = "Marzo";
    month[3] = "Abril";
    month[4] = "Mayo";
    month[5] = "Junio";
    month[6] = "Julio";
    month[7] = "Agosto";
    month[8] = "Septiembre";
    month[9] = "Octubre";
    month[10] = "Noviembre";
    month[11] = "Diciembre";

    let day = date.getDate();

    if (day < 10) {
      day = "0" + day;
    }

    let hora = date.getHours();

    if (hora < 10) {
      hora = "0" + hora;
    }

    let minutos = date.getMinutes();
    if (minutos < 10) {
      minutos = "0" + minutos;
    }

    //return day + " " + month[date.getMonth()] + " " + date.getFullYear();
    return (
      day +
      " de " +
      month[date.getMonth()] +
      " de " +
      date.getFullYear() +
      " a las " +
      hora +
      ":" +
      minutos
    );
  }
};

export const convertDDMMYYYYhhmmss = (d) => {
  // dd de Mes de yyyy hh:mm:ss
  var date = new Date(d);

  if (isNaN(date.getTime())) {
    return d;
  } else {
    var month = new Array();
    month[0] = "Enero";
    month[1] = "Febrero";
    month[2] = "Marzo";
    month[3] = "Abril";
    month[4] = "Mayo";
    month[5] = "Junio";
    month[6] = "Julio";
    month[7] = "Agosto";
    month[8] = "Septiembre";
    month[9] = "Octubre";
    month[10] = "Noviembre";
    month[11] = "Diciembre";

    let day = date.getDate();

    if (day < 10) {
      day = "0" + day;
    }

    let hora = date.getHours();

    if (hora < 10) {
      hora = "0" + hora;
    }

    let minutos = date.getMinutes();
    if (minutos < 10) {
      minutos = "0" + minutos;
    }
    let segundos = date.getSeconds();
    if (segundos < 10) {
      segundos = "0" + segundos;
    }

    //return day + " " + month[date.getMonth()] + " " + date.getFullYear();
    return (
      day +
      " de " +
      month[date.getMonth()] +
      " de " +
      date.getFullYear() +
      " a las " +
      hora +
      ":" +
      minutos +
      ":" +
      segundos
    );
  }
};

export const convertYMD = (d, separator) => {
  // dd de Mes de yyyy hh:mm
  var date = new Date(d);

  if (isNaN(date.getTime())) {
    return d;
  } else {
    let day = date.getDate();

    if (day < 10) {
      day = "0" + day;
    }

    let month = date.getMonth() + 1;
    if (month < 10) {
      month = "0" + month;
    }
    console.log(date.getFullYear() + separator + month + separator + day);

    return date.getFullYear() + separator + month + separator + day;
  }
};

export const convertDMY = (d, separator) => {
  // dd de Mes de yyyy hh:mm
  var date = new Date(d);

  if (isNaN(date.getTime())) {
    return d;
  } else {
    let day = date.getDate();

    if (day < 10) {
      day = "0" + day;
    }

    let month = date.getMonth() + 1;
    if (month < 10) {
      month = "0" + month;
    }

    return day + separator + month + separator + date.getFullYear();
  }
};

export const convertToYearMonthDay = (d) => {
  // yyyy-mm-dd
  var date = new Date(d);

  let day = date.getDate();

  if (day < 10) {
    day = "0" + day;
  }

  let month = date.getMonth() + 1;
  if (month < 10) {
    month = "0" + month;
  }

  return date.getFullYear() + "-" + month + "-" + day;
};

export const convertYYYYMMDDToDate = async (fechaYYYYMMDD) => {
  if (fechaYYYYMMDD === null) {
    return null;
  } else {
    let anio = fechaYYYYMMDD.substring(0, 4);
    let mes = fechaYYYYMMDD.substring(4, 6);
    let dia = fechaYYYYMMDD.substring(6, 8);
    var dateObject = anio + "-" + mes + "-" + dia;
    console.log(dateObject);
    return dateObject;
  }
};

export const formatMonedaDebito = (d) => {
  d = parseFloat(d).toFixed(2).toLocaleString();
  if (d > 0) {
    return "-Q " + d;
  } else {
    return "Q " + d;
  }
};

export const getFormatoMoneda = (monto) => {
  let d = parseFloat(monto).toLocaleString("gt-GT", {
    style: "currency",
    currency: "GTQ",
  });

  d = d.replace("GTQ", "");
  d = d.replace("- GTQ", "");
  d = d.replace("-GTQ", "");
  d = d.replace("-", "");
  d = d.replace(" ", "");

  if (monto > 0) {
    return "+Q " + d;
  } else {
    return "-Q " + d;
  }
};

export const formatMoney = (n, c, d, t) => {
  //(123456789.12345).formatMoney(2, ".", ",");
  var originalNumber = n;
  var c = isNaN((c = Math.abs(c))) ? 2 : c,
    d = d == undefined ? "." : d,
    t = t == undefined ? "," : t,
    s = n < 0 ? "-" : "",
    i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
    j = (j = i.length) > 3 ? j % 3 : 0;

  let money =
    s +
    (j ? i.substr(0, j) + t : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
    (c
      ? d +
        Math.abs(n - i)
          .toFixed(c)
          .slice(2)
      : "");
  money = money.replace("-", "");
  money = money.replace(" ", "");

  if (originalNumber > 0) {
    return "+Q " + money;
  } else {
    return "-Q " + money;
  }
};

export const getCurrentDateTime = () => {
  // yyyy-mm-ddThh:mm
  var now = new Date();
  var year = now.getFullYear();
  var month = now.getMonth() + 1;
  var day = now.getDate();
  var hour = now.getHours();
  var minute = now.getMinutes();
  var second = now.getSeconds();

  if (month.toString().length == 1) {
    month = "0" + month;
  }
  if (day.toString().length == 1) {
    day = "0" + day;
  }
  if (hour.toString().length == 1) {
    hour = "0" + hour;
  }
  if (minute.toString().length == 1) {
    minute = "0" + minute;
  }
  if (second.toString().length == 1) {
    second = "0" + second;
  }

  var dateTime = year + "-" + month + "-" + day + "T" + hour + ":" + minute;
  return dateTime;
};

export const convertirASegundos = (hhmm) => {
  let segundos = 0;
  if (hhmm === "" || hhmm === null || hhmm === undefined) {
    segundos = 0;
  } else if (hhmm.includes(":")) {
    //si no está vacío
    let partes = hhmm.split(":");
    if (partes.length < 2) {
      alert("El formato de tiempo en horas y minutos es incorrecto");
      segundos = 0;
    } else if (partes.length === 2) {
      //Valida si los valores ingresados son numeros
      if (isNaN(partes[0])) {
        alert("El formato ingresado en horas es incorrecto.");
        segundos = 0;
        return;
      }

      if (isNaN(partes[1])) {
        alert("El formato ingresado en minutos es incorrecto");
        segundos = 0;
        return;
      }

      let horas = Number(partes[0]);
      let minutos = Number(partes[1]);

      //Valida que los valores estén en el rango adecuado
      if (minutos > 59) {
        alert("El formato ingresado en minutos es incorrecto");
        segundos = 0;
        return;
      }

      segundos = horas * 60 * 60 + minutos * 60;
    }
  } else {
    segundos = 0;
  }
  return segundos;
};

export const convertirHHMM = (segundos) => {
  let horas = "00";
  let minutos = "00";

  if (segundos > 0) {
    horas = Math.floor(segundos / 3600);
    //Segundos restantes
    segundos = (segundos / 3600 - horas) * 3600;

    minutos = Math.floor(segundos / 60);

    if (horas < 10) {
      horas = "0" + horas;
    }

    if (minutos < 10) {
      minutos = "0" + minutos;
    }
  }

  return horas + ":" + minutos;
};

export const consultarPermiso = async (idObjeto, llave, codigoAviso = null) => {
  let respuesta = {};
  const verify = await isSignedIn();

  console.log(verify.response);
  if (!verify.response) {
    window.location.href = "/login";
  }

  let data = {
    IdObjeto: idObjeto,
    Llave: llave,
    CodigoUsuario: verify.codigoUsuario,
    roles: [],
    CodigoAviso: codigoAviso,
  };

  const request = await API.post(`api/permiso/consultarPermiso`, data);

  if (request.data.data !== null) {
    respuesta = request.data.data;
  }
  if (request.status != 200) {
    alert(
      "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
    );
  }

  return respuesta;
};

export const permisoAgrupacion = async (llaveAgrupacion) => {
  try {
    const session = await isSignedIn();
    const uri = `api/permiso/detalle/agrupacion/${llaveAgrupacion}/${session.codigoUsuario}`;
    const request = await API.fetchGetRequest(uri);
    console.log("Request", request);
    let response = [];

    if (request.statusCode === 401) {
      if (await signOut()) {
        window.location.href = "/login";
      }
      return;
    }

    if (Array.isArray(request.data)) {
      //OK
      response = request.data;
    } else {
      response = [];
    }

    return response;
  } catch (e) {
    //Hubo error en el request al server
    signOut();
  } finally {
    //setIsLoading(false);
  }
};

export const validaPermisoPaginaActual = async (
  llaveAgrupacion,
  llavePagina
) => {
  //CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
  //  const llaveAgrupacion = "Menu.Principal.Web";
  //  const llavePagina = "Menu.Principal.Web.Notificaciones";

  let listaAcceso = await permisoAgrupacion(llaveAgrupacion);

  if (!Array.isArray(listaAcceso)) {
    //window.alert("Ocurrió un problema al realizar la petición, inténtalo más tarde");
    // window.location.href="/bandeja";
  } else {
    let fila = await listaAcceso.filter((element) => {
      return element.Llave === llavePagina;
    });
    console.log("Fila");
    console.log(fila);
    if (fila === null || fila === undefined || fila.length === 0) {
      window.location.href = "/unauthorized";
    }
  }

  //FIN DE CONSULTA DE PERMISOS
};

export const verificaPermisoPaginaActual = async (
  llaveAgrupacion,
  llavePagina
) => {
  //CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
  //  const llaveAgrupacion = "Menu.Principal.Web";
  //  const llavePagina = "Menu.Principal.Web.Notificaciones";
  let acceso = true;
  let listaAcceso = await permisoAgrupacion(llaveAgrupacion);

  if (!Array.isArray(listaAcceso)) {
    //window.alert("Ocurrió un problema al realizar la petición, inténtalo más tarde");
    // window.location.href="/bandeja";
  } else {
    let fila = await listaAcceso.filter((element) => {
      return element.Llave === llavePagina;
    });
    console.log("Fila");
    console.log(fila);
    if (fila === null || fila === undefined || fila.length === 0) {
      acceso = false;
    }
    return acceso;
  }

  //FIN DE CONSULTA DE PERMISOS
};

export const validaPermisoSecundarioPaginaActual = async (
  llaveAgrupacion,
  llavePagina
) => {
  //CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
  //  const llaveAgrupacion = "Menu.Principal.Web";
  //  const llavePagina = "Menu.Principal.Web.Notificaciones";

  let listaAcceso = await permisoAgrupacion(llaveAgrupacion);

  if (!Array.isArray(listaAcceso)) {
    //window.alert("Ocurrió un problema al realizar la petición, inténtalo más tarde");
    // window.location.href="/bandeja";
  } else {
    let fila = await listaAcceso.filter((element) => {
      return element.Llave === llavePagina;
    });
    console.log("Fila");
    console.log(fila);
    if (fila === null || fila === undefined || fila.length === 0) {
      return false;
    }
    return true;
  }

  //FIN DE CONSULTA DE PERMISOS
};
// Constantes para los tipos de temas
export const TIPOS_TEMAS = [
  { value: 1, label: "Felicitación cumpleañero" },
  { value: 2, label: "Aviso cumpleaños" },
  { value: 3, label: "Felicitación por aniversario" },
  { value: 4, label: "Aviso aniversario" },
];

export const getBrowserInfo = () => {
  var ua = navigator.userAgent,
    tem,
    M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return "IE " + (tem[1] || "");
  }
  if (M[1] === "Chrome") {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
    if (tem != null) return tem.slice(1).join(" ").replace("OPR", "Opera");
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
  return M.join(" ");
};
