import { action, thunk } from "easy-peasy";
import api from "../utils/api";
import dayjs from "dayjs";
import { formatoCorrelativo, getCodigoUsuario } from "../gestion-documental/utils/utilidades";

export default {
  crearPropia: true,
  solicitudes: [],
  documentos: [],
  registros: [],
  externos: [],
  asignadas: [],
  usuarios: [],
  historico: [],
  docsObsoletos: [],
  todas: [],// todas las solicitudes por publicar
  docsOficiales: [],
  deptos: [],
  setDeptos: action((state, payload) => {
    state.deptos = payload
  }),
  setCrearPropia: action((state, payload) => {
    state.crearPropia = payload;
  }),
  setSolicitudes: action((state, payload) => {
    state.solicitudes = payload;
  }),
  setDocumentos: action((state, payload) => {
    state.documentos = payload;
  }),
  setRegistros: action((state, payload) => {
    state.registros = payload;
  }),
  setExternos: action((state, payload) => {
    state.externos = payload;
  }),
  setAsignadas: action((state, payload) => {
    state.asignadas = payload;
  }),
  setTodas: action((state, payload) => {
    state.todas = payload;
  }),
  setUsuarios: action((state, payload) => {
    state.usuarios = payload;
  }),
  setHistorico: action((state, payload) => {
    state.historico = payload;
  }),
  setDocsObsoletos: action((state, payload) => {
    state.docsObsoletos = payload;
  }),
  getDocumentos: thunk(async (actions, codigoUsuario = null) => {
    try {
      const codigo = codigoUsuario || getCodigoUsuario()
      const { data } = await api.fetchGetRequest(
        `api/documentos/obtener-documentos-directorio-por-autor/${codigo}`
      );
      actions.setDocumentos(
        data.map((i, idx) => {
          const codigo = formatoCorrelativo(i.Correlativo)
          return {
            no: idx + 1,
            ...i,
            actualizacion: dayjs(i.actualizacion).format("DD-MM-YY HH:mm"),
            vencimiento: dayjs(i.FechaVencimiento).format("DD-MM-YY HH:mm"),
            Correlativo: codigo,
          };
        })
      );
    } catch (error) {
      actions.setDocumentos([]);
    }
  }),
  getDocsOficiales: thunk(async (actions) => {
    try {
      const { data } = await api.fetchGetRequest(
        `api/documentos/obtener-documentos-oficiales-ctrldoc`
      );
      actions.setDocumentos(
        data.map((i, idx) => {
          const codigo = formatoCorrelativo(i.Correlativo)
          return {
            no: idx + 1,
            ...i,
            actualizacion: dayjs(i.actualizacion).format("DD-MM-YY HH:mm"),
            vencimiento: dayjs(i.FechaVencimiento).format("DD-MM-YY HH:mm"),
            Correlativo: codigo,
          };
        })
      );
    } catch (error) {
      actions.setDocumentos([]);
    }
  }),
  getRegistrosOficiales: thunk(async (actions) => {
    try {
      const { data } = await api.fetchGetRequest(
        `api/documentos/obtener-registros-oficiales`
      );
      actions.setRegistros(
        data.map((i, idx) => {
          const codigo = formatoCorrelativo(i.Correlativo)
          const retencion = i.TiempoRetencionRegistro.split(' ')[0]
          return {
            no: idx + 1,
            ...i,
            actualizacion: dayjs(i.actualizacion).format("DD-MM-YY HH:mm"),
            vencimiento: dayjs(i.FechaVencimiento).format("DD-MM-YY HH:mm"),
            Correlativo: codigo,
            TiempoRetencionRegistro: `${Math.round(retencion / 365)} años`
          }
        })
      )
    } catch (error) {
      actions.setRegistros([]);
    }
  }),
  getExternosOficiales: thunk(async (actions) => {
    try {
      const { data } = await api.fetchGetRequest(
        `api/documentos/obtener-docs-externos-oficiales`
      );
      actions.setExternos(
        data.map((i, idx) => {
          const codigo = formatoCorrelativo(i.Correlativo)
          return {
            no: idx + 1,
            ...i,
            actualizacion: dayjs(i.actualizacion).format("DD-MM-YY"),
            vencimiento: dayjs(i.FechaVencimiento).format("DD-MM-YY"),
            FechaIngreso: dayjs(i.FechaIngreso).format("DD-MM-YY"),
            Correlativo: codigo
          }
        })
      )
    } catch (error) {
      actions.setExternos([]);
    }
  }),
  getSolicitudes: thunk(async (actions, param) => {
    let items = [];
    try {
      if (param === 'usuario') {
        const { data } = await api.fetchGetRequest(
          `api/documentos/solicitud/usuario/${getCodigoUsuario()}`
        );
        items = data;
      } else if (param === 'asignadas') {
        const requests = [
          api.fetchGetRequest(
            `api/documentos/solicitud/autorizador/${getCodigoUsuario()}`
          ),
          api.fetchGetRequest(
            `api/documentos/solicitud/revisor/${getCodigoUsuario()}`
          ),
          api.fetchGetRequest(
            `api/documentos/solicitud/aprobador-final/${getCodigoUsuario()}`
          ),
          api.fetchGetRequest(
            `api/documentos/obsolescencia/obtener-solicitud-por-autorizador/${getCodigoUsuario()}`
          ),
        ];
        const responses = await Promise.all(requests);
        const solicitudes = [...responses[0].data];
        for (const item of responses[1].data) {
          if (!solicitudes.find((i) => i.IdSolicitud === item.IdSolicitud)) {
            solicitudes.push(item);
          }
        }
        for (const item of responses[2].data) {
          if (!solicitudes.find((i) => i.IdSolicitud === item.IdSolicitud)) {
            solicitudes.push(item);
          }
        }
        for (const item of responses[3].data) {
          if (!solicitudes.find((i) => i.IdSolicitud === item.IdSolicitud)) {
            const tmp = {
              ...item,
              EsObsoleto: true,
            };
            solicitudes.push(tmp);
          }
        }
        items = solicitudes;
      } else {
        const { data } = await api.fetchGetRequest('api/documentos/solicitud')
        items = data
      }
      items = items.map((i) => {
        const correlativo = `${i.AbreviaturaDivision}-${i.CodigoTipoDocumento}-${i.AbreviaturaProceso}-${i.CorrelativoDocumento}`
        const codigo = formatoCorrelativo(correlativo)
        return {
          ...i,
          fecha: dayjs(i.FechaSolicitud).format("DD-MM-YY"),
          codigoDoc: codigo
        };
      });
    } catch (error) {
      console.log(error);
    } finally {
      if (param === 'usuario') {
        actions.setSolicitudes(items);
      } else if (param === 'asignadas') {
        actions.setAsignadas(items);
      } else {
        actions.setTodas(items);
      }
    }
  }),
  getUsuarios: thunk(async (actions, _) => {
    try {
      const { data } = await api.fetchGetRequest("api/Usuario/buscar/x-x");
      actions.setUsuarios(data.usuarios);
    } catch (error) {
      actions.setUsuarios([]);
    }
  }),
  getDeptos: thunk(async (actions, _) => {
    try {
      const { data } = await api.fetchGetRequest("api/documentos/departamento");
      actions.setDeptos(data);
    } catch (error) {
      actions.setDeptos([]);
    }
  }),
  getHistorico: thunk(async (actions, _) => {
    try {
      const { data } = await api.fetchGetRequest(
        `api/documentos/solicitud/historico/usuario/${getCodigoUsuario()}`
      );
      actions.setHistorico(
        data.map((i) => ({ ...i, Fecha: dayjs(i.Fecha).format("DD/MM/YY") }))
      );
    } catch (error) {
      actions.setHistorico([]);
    }
  }),
  getDocsObsoletos: thunk(async (actions, codigoUsuario = null) => {
    try {
      const codigo = codigoUsuario || getCodigoUsuario();
      const { data } = await api.fetchGetRequest(
        `api/documentos/obtener-documentos-obsoletos-por-usuario/${codigo}`
      );
      actions.setDocsObsoletos(
        data.map((i, idx) => {
          const codigo = formatoCorrelativo(i.Correlativo)
          return {
            no: idx + 1,
            ...i,
            actualizacion: dayjs(i.actualizacion).format("DD-MM-YYYY HH:mm"),
            FechaObsoleto: dayjs(i.FechaObsoleto).format("DD-MM-YYYY HH:mm"),
            vencimiento: dayjs(i.FechaVencimiento).format("DD-MM-YYYY HH:mm"),
            Correlativo: codigo
          };
        })
      );
    } catch (error) {
      actions.setDocsObsoletos([]);
    }
  })
}
