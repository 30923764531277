
import React from 'react'
import { Route, Redirect } from "react-router-dom"
import { useStoreState } from "easy-peasy"
import { getDispositivo } from '../../utils/utilidades'

const GesDocRoute = ({ component: Component, keyPermiso=null, ...rest }) => {
  const { permisosGesDoc } = useStoreState(state => ({
    permisosGesDoc: state.permisosGesDoc
  }))
  const hasAccess = (permisosGesDoc.find(i => i === keyPermiso) && getDispositivo() == 'Desktop') ||
    (keyPermiso == null && getDispositivo() == 'Desktop')
  return <Route {...rest} render={(props) => (
    hasAccess
      ? <Component {...props} />
      : <Redirect to='/unauthorized' />
  )} />
}

export default GesDocRoute