import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import Badge from '@material-ui/core/Badge';

//Own Componentes
import "../css/add-button-float.css";

const styles = theme => ({
  fab: {
    margin: theme.spacing.unit
  }
});

function FloatingActionButtons(props) {
  const { classes } = props;
  return (
    <div className="FloatBottomDiv" onClick={props.onClick}>
      <Fab
        style={{
          backgroundColor:
            props.color === undefined || props.color === null
              ? "#69BD4B"
              : props.color,
          color: "white",
        }}
        aria-label="Add"
        className={classes.fab}
      >
        {props.cantidadMensajes !== null &&
        props.cantidadMensajes !== undefined &&
        props.cantidadMensajes > 0 ? (
          <Badge badgeContent={props.cantidadMensajes} color="secondary">
            <ChatBubbleIcon />
          </Badge>
        ) : (
          <ChatBubbleIcon />
        )}
      </Fab>
    </div>
  );
}

export default withStyles(styles)(FloatingActionButtons);
