import React, { useState } from 'react'
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Dialog
} from '@material-ui/core'
import { DropzoneArea } from 'material-ui-dropzone'

import LoadingButton from '../utils/LoadingButton'
import { getCodigoUsuario } from '../../utils/utilidades'
import { ToastContainer, toast } from 'react-toastify'
import api from '../../../utils/api'
import { convertBase64 } from '../../utils/media'
import PDFView from '../utils/PDFView'

const SolicitudSubirDoc = ({ data, onClose, onRefresh }) => {
  const [archivo, setArchivo] = useState(null)
  const [loading, setLoading] = useState(false)
  const [preview, setPreview] = useState(false)
  const [PDF, setPDF] = useState(null)
  const showPreview = async () => {
    try {
      let base64 = await convertBase64(archivo)
      // base64 = base64.replace('data:application/pdf;base64,', '')
      setPDF(base64)
      setPreview(true)
    } catch (error) {
      toast.error('Archivo incorrecto')
    }
  }
  const subirDoc = async () => {
    try {
      setLoading(true)
      if (!archivo) {
        toast.error('El archivo es requerido')
        return
      }
      const form = new FormData()
      form.append('Archivo', archivo)
      form.append('CodigoUsuario', getCodigoUsuario())
      form.append('IdSolicitud', data.IdSolicitud)
      await api.postFile('api/documentos/solicitud/subir-documento', form, {
        'Content-Type': 'multipart/form-data',
      })
      toast.success('El archivo ha sido guardado')
      onRefresh()
    } catch (error) {
      toast.error('Ha ocurrido un error al subir el archivo')
    } finally {
      setLoading(false)
    }
  }
  return <>
    <DialogTitle>Documento aprobado, agregar archivo PDF</DialogTitle>
    <DialogContent>
      <DropzoneArea
        filesLimit={1}
        maxFileSize={100000000}
        dropzoneText="Selecciona el archivo"
        acceptedFiles={["application/pdf"]}
        onChange={files => setArchivo(files[0])}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancelar</Button>
      <Button
        disabled={!archivo}
        onClick={showPreview}
        color="secondary"
      >
        Previsualizar
      </Button>
      <LoadingButton
        loading={loading}
        color="primary"
        onClick={subirDoc}
      >
        Guardar
      </LoadingButton>
    </DialogActions>
    <ToastContainer />
    <Dialog open={preview} onClose={() => setPreview(false)} fullScreen>
      <PDFView
        PDFbase64={PDF}
        onClose={() => setPreview(false)}
      />
    </Dialog>
  </>
}

export default SolicitudSubirDoc
