import React, { useState, useEffect, Fragment } from 'react';
import { Button, ListItem, ListItemAvatar, ListItemText, makeStyles, Paper, Avatar } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Schedule, Today, GetApp } from '@material-ui/icons';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import es from 'date-fns/locale/es';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Toolbar from './toolbar-calendario';
import { add, formatISO, startOfMonth, sub } from 'date-fns';
import API from "../../utils/api";
import ICalendarLink from "react-icalendar-link";
import { registrarAnalitica } from '../../utils/firebase-config';

const locales = {
    'es': es,
}

const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
});

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: '1rem',
        padding: '1rem 1rem',
        borderRadius: 10,
    },
    barra: {
        width: '30%',
        height: '0.2rem',
        borderRadius: 5,
        marginBottom: '1rem'
    },
    iButton: {
        textDecoration: 'none'
    }
}));

const Calendario = (props) => {

    const classes = useStyles();
    const [openModal, setOpenModal] = useState(false);
    const [eventos, setEventos] = useState([]);
    const [eventSelect, setEventSelect] = useState({});

    const obtenerEventos = (fechaI, fechaF) => {
        API.fetchGetRequest(`api/calendarioEvento/listarEventos?fechaInicio=${fechaI}&fechaFin=${fechaF}`).then(res => {
            const events = res.data.data;
            console.log('res ', res)
            events.map(item => {
                item.id = item.Id;
                item.title = item.Titulo;
                item.allDay = item.TodoElDia;
                const fecha = parse(item.Fecha, 'yyyy-MM-dd', new Date())
                item.start = fecha;
                item.end = fecha;
                if (!item.TodoElDia) {
                    item.start = parse(item.HoraInicio, 'HH:mm:ss', fecha);
                    item.end = parse(item.HoraFin, 'HH:mm:ss', fecha);
                }
                return item
            });
            console.log('events ', events)

            setEventos(events);
        });
    }

    useEffect(() => {
        let fechaI = startOfMonth(new Date());
        let fechaF = add(fechaI, { months: 1 });
        fechaI = format(fechaI, "yyyy-MM-dd");
        fechaF = format(fechaF, "yyyy-MM-dd");

        obtenerEventos(fechaI, fechaF);
    }, []);

    const changeFechas = (fechas) => {
        const { start, end } = fechas;
        console.log('start ', start);
        console.log('end ', end);
        if (start && end) {
            let fechaI = format(sub(start, { days: 1 }), "yyyy-MM-dd");
            let fechaF = format(add(end, { days: 1 }), "yyyy-MM-dd");
            console.log('fechaI ', fechaI)
            console.log('fechaF ', fechaF)
            obtenerEventos(fechaI, fechaF);
        }
    }

    const onSelectEvent = (event) => {
        registrarAnalitica('Ver_Evento_Calendario');
        console.log('event ', event);
        event.startTime = formatISO(event.start);
        event.endTime = formatISO(event.end);
        event.description = event.Descripcion;
        event.location = 'Guatemala';
        setEventSelect(event);
        setOpenModal(true);
    };

    const cerrarModal = () => {
        setEventSelect({});
        setOpenModal(false);
    }

    const eventStyleGetter = (event, start, end, isSelected) => {
        let style = {
            backgroundColor: event.Color || '#202e3c',
            color: '#FFF',
            fontWeight: "bold",
        };

        return { style: style };
    }


    return (
        <Paper className={classes.root} elevation={3}>
            <Dialog
                open={openModal}
                onClose={cerrarModal}
            >
                <DialogTitle id="alert-dialog-title">{eventSelect.title}</DialogTitle>
                <DialogContent>
                    <div className={classes.barra} style={{ backgroundColor: eventSelect.Color }} />
                    <DialogContentText id="alert-dialog-description">
                        {eventSelect.Descripcion}
                    </DialogContentText>
                    {eventSelect.allDay ? (
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <Today />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Todo el día" secondary="Hora" />
                        </ListItem>
                    ) : (
                        <Fragment>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <Schedule />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={eventSelect.HoraInicio} secondary="Hora de inicio" />
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <Schedule style={{ transform: 'rotate(245deg)' }} />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={eventSelect.HoraFin} secondary="Hora de fin" />
                            </ListItem>
                        </Fragment>
                    )}
                    <div style={{ marginTop: '1rem' }} >
                        <ICalendarLink filename='Evento' event={eventSelect} className={classes.iButton}>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<GetApp/>}
                            >
                                Agregar a outlook
                            </Button>
                        </ICalendarLink>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={cerrarModal} color="primary">
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>
            <Calendar
                events={eventos}
                // events={[]}
                views={{ month: true, week: true, day: true }}
                step={60}
                showMultiDayTimes
                defaultDate={new Date()}
                culture={'es'}
                localizer={localizer}
                onSelectEvent={(e) => onSelectEvent(e)}
                onRangeChange={changeFechas}
                style={{ height: 500 }}
                components={{
                    toolbar: Toolbar
                }}
                eventPropGetter={(eventStyleGetter)}
            />
        </Paper>
    )
}

export default Calendario;
