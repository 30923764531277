import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Checkbox,
  FormControlLabel,
  Divider,
  Paper,
  Tooltip,
  Modal,
  Fade,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Box,
} from "@material-ui/core";

import DataGrid, {
  Column,
  Pager,
  Paging,
  Sorting,
  ColumnFixing,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Grouping,
  GroupPanel,
  Export,
  Selection,
  Editing,
  Scrolling,
  LoadPanel,
} from "devextreme-react/data-grid";

import BackdropModal from "@material-ui/core/Backdrop";

import { Delete, ViewList, Add, Edit } from "@material-ui/icons";

import { green } from "@material-ui/core/colors";
import { NavLink } from "react-router-dom";
import {
  Timeline,
  TimelineItem,
  TimelineContent,
  TimelineSeparator,
  TimelineConnector,
  TimelineDot,
  TimelineOppositeContent,
} from "@material-ui/lab";

import API from "../../utils/api";
import { isSignedIn, signOut } from "../../utils/auth";
import { validaPermisoPaginaActual } from "../../utils/helpers";
import { ToastContainer, toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import "devextreme/dist/css/dx.material.teal.light.compact.css";

const width_proportion = "100%";
const titleColor = "#2B3C4D";

const useStyles = makeStyles((theme) => ({
  paperContent: {
    padding: "6px 16px",
  },
  table: {
    width: "100%",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 210,
  },
  textFieldDireccion: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 230,
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  greenAvatar: {
    fontSize: "2.5em",
    color: "#fff",
    // borderStyle:'solid',
    // borderWidth:'1 !important',
    // borderColor:'#3f51b5 !important',
    backgroundColor: green[500],
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  paperCard: {
    padding: 20,
    width: "100%",
  },
  formControlSelects: {
    // margin: theme.spacing(1),
    minWidth: 400,

    marginBottom: 10,
    marginTop: 10,
    marginLeft: 30,
  },
  formControlSelect: {
    // margin: theme.spacing(1),
    width: width_proportion,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  rootTabla: {
    width: "100%",
    marginTop: theme.spacing.unit * 1,
    overflowX: "auto",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paperModal: {
    position: "absolute",
    width: "70%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    flexGrow: 1,
    maxHeight: 60,
  },
}));
const FuncionesPuestoLaboral = (props) => {
  const classes = useStyles();
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors: fieldsErrors },
    register,
  } = useForm();

  let applyFilterTypes = [
    {
      key: "auto",
      name: "Immediately",
    },
    {
      key: "onClick",
      name: "On Button Click",
    },
  ];

  const [dataEmpleado, setDataEmpleado] = useState(props.dataEmpleado);
  const [userName, setUserName] = useState("");
  const [open, setOpen] = useState(false);
  const [cargandoMaestros, setCargandoMaestros] = useState(false);
  const [maestroExpertis, setMestroExpertis] = useState([]);
  const [maestroEducacion, setMaestroEducacion] = useState([]);
  const [maestroPuestoLaboralEducacion, setMaestroPuestoLaboralEducacion] =
    useState([]);

  const [codigoEducacion, setCodigoEducacion] = useState(0);
  const [codigoExpertis, setCodigoExpertis] = useState(0);

  const [predicado, setPredicado] = useState("");
  const [numeroVersion, setNumeroVersion] = useState("");
  const [fechaCreacion, setFechaCreacion] = useState(new Date());

  const [valueAutoCompleteExpertis, setValueAutoCompleteExpertis] =
    useState(null);
  const [valueAutoCompleteEducacion, setValueAutoCompleteEducacion] =
    useState(null);

  const [positionDisableSorting, setPositionDisableSorting] = useState(false);
  const [showFilterRow, setShowFilterRow] = useState(true);
  const [showHeaderFilter, setShowHeaderFilter] = useState(true);
  const [currentFilter, setCurrentFilter] = useState(applyFilterTypes[0].key);
  const [autoExpandAll, setAutoExpandAll] = useState(true);

  const [buscando, setBackDrop] = useState(false);
  const [selected, setSelected] = useState(null);
  const [codigoNotificacion, setCodigoNotificacion] = useState(0);
  const [idFila, setIdFila] = useState(0);
  const handleCerrarBackDrop = () => {
    setBackDrop(false);
  };
  const handleAbrirBackDrop = () => {
    setBackDrop(true);
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  useEffect(() => {
    const cargar = async () => {
      // CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
      const llaveAgrupacion = "Menu.Principal.Web";
      const llavePagina = "Menu.Principal.Web.PuestoLaboral";
      await validaPermisoPaginaActual(llaveAgrupacion, llavePagina);
      // FIN DE CONSULTA DE PERMISOS

      handleAbrirBackDrop();

      await cargarMaestroExpertis();
      await cargarMaestroEducacion();
      await cargarMaestroResponsabilidadPuestoLaboral();
      handleCerrarBackDrop();
    };

    cargar();
  }, []);

  useEffect(() => {
    const cargar = async () => {
      // CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
      const llaveAgrupacion = "Menu.Principal.Web";
      const llavePagina = "Menu.Principal.Web.PuestoLaboral";
      await validaPermisoPaginaActual(llaveAgrupacion, llavePagina);
      // FIN DE CONSULTA DE PERMISOS
      if (dataEmpleado !== undefined) {
        handleAbrirBackDrop();
        await cargarMaestroResponsabilidadPuestoLaboral();
        handleCerrarBackDrop();
      }
    };

    cargar();
  }, [dataEmpleado]);

  const cargarMaestroExpertis = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/Expertis/";

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setMestroExpertis(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cargarMaestroEducacion = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/Educacion/";

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setMaestroEducacion(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cargarMaestroResponsabilidadPuestoLaboral = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/EmpleadoCreditoInterno/" + props.dataEmpleado.DPI;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setMaestroPuestoLaboralEducacion(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const guardarDato = async () => {
    const data = {
      CodigoPuestoLaboral: props.puestoLaboral.CodigoPuestoLaboral,
      CodigoEducacion: codigoEducacion,
      CodigoExpertis: codigoExpertis,
      Activo: true,
      Eliminado: false,
      UsuarioInserto: userName,
      FechaInserto: "",
      UsuarioModifico: "",
      FechaModifico: "",
    };

    console.log(data);
    // dataFicha.usuarioInserto = "prueba";
    try {
      handleAbrirBackDrop();
      let vIsSignedIn = await isSignedIn();
      if (vIsSignedIn.response) {
        let request;

        request = await API.post("api/PuestoLaboralEducacion/", data);
        if (request.status !== 200) {
          toast.error(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        } else {
          toast.success("Se guardó exitosamente");
          await cargarMaestroResponsabilidadPuestoLaboral();
          setPredicado("");
          setCodigoEducacion(0);

          setValueAutoCompleteExpertis(null);
        }
      }
    } catch (e) {
      console.log("EXCEPTION: " + e);
      toast.error(
        "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
      );
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cuandoHayError = async (data) => {
    toast.info(
      "Existen algunos campos con error, por favor valida el formulario",
      {
        position: "top-right",
        autoClose: 3000, // Esta en milisegundos 3 segundos
      }
    );
  };

  const handleDateChangeFechaCreacion = (date) => {
    setFechaCreacion(date);
  };

  const onExporting = async (e) => {};

  const [dialogoAbierto, abrirDialogo] = useState(false);
  const [dialogoAbiertoEditar, abrirDialogoEditar] = useState(false);
  const handleAbrirDialogoEliminar = (id) => {
    abrirDialogo(true);

    //setCodigoPuestoLaboral(id);
  };

  const handleCerrarDialogo = () => {
    abrirDialogo(false);
  };

  const handleAbrirDialogoEditar = (id) => {
    abrirDialogoEditar(true);

    //setCodigoPuestoLaboral(id);
  };

  const handleCerrarDialogoEditar = () => {
    abrirDialogoEditar(false);
  };

  const quitarProyecto = async (pId) => {
    handleCerrarDialogo();
    handleAbrirBackDrop();

    const proyectosExcluidos = maestroExpertis.filter(
      (elemento) => elemento.CodigoPuestoLaboral !== pId
    );

    console.log("Quitar" + proyectosExcluidos);

    try {
      let uri = "api/PuestoLaboral/Quitar/" + pId;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        toast.error(
          "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
        );
      } else {
        toast.success("Se quitó el registro con éxito", {
          position: "bottom-right",
          autoClose: 3000, // Esta en milisegundos 3 segundos
        });

        //await cargarMaestroPuestoLaboral();

        setNumeroVersion("");
        setFechaCreacion(new Date());
        //setCodigoPuestoLaboral(0);
        setCodigoEducacion(0);

        setValueAutoCompleteExpertis(null);
      }
    } catch (error) {
      console.log("Error en  eliminación de proyectos" + error);
    }

    handleCerrarBackDrop();

    //alert("Quitar recurso del proyecto" + codigoRecurso);
  };

  const editarProyecto = async (pId) => {
    handleCerrarDialogoEditar();
    handleAbrirBackDrop();

    const proyectosExcluidos = maestroExpertis.filter(
      (elemento) => elemento.CodigoPuestoLaboral === pId
    );

    console.log("Editar el proyecto" + proyectosExcluidos);

    try {
      //setCodigoPuestoLaboral(pId);
      setPredicado(proyectosExcluidos[0].PuestoLaboral);
      setNumeroVersion(proyectosExcluidos[0].NumeroVersion);
      setFechaCreacion(proyectosExcluidos[0].FechaCreacion);
      setCodigoEducacion(proyectosExcluidos[0].CodigoNivelPuestoLaboral);

      const itemNivelPuestoLaboral = maestroPuestoLaboralEducacion.filter(
        (elemento) =>
          elemento.CodigoNivelPuestoLaboral ===
          proyectosExcluidos[0].CodigoNivelPuestoLaboral
      );
      setValueAutoCompleteExpertis(itemNivelPuestoLaboral[0]);
    } catch (error) {
      console.log("Error en  edición de proyectos" + error);
    }

    handleCerrarBackDrop();

    //alert("Quitar recurso del proyecto" + codigoRecurso);
  };

  const cellOpciones = (rowInfo) => {
    return (
      <>
        <Tooltip
          title="Descriptor de Proyectos"
          style={{ marginRight: 5, cursor: "pointer" }}
        >
          <NavLink
            onClick={(e) => {
              if (false) {
                toast.warning(
                  "No tienes permisos para gestionar los anexos el proyecto",
                  {
                    position: "top-right",
                    pauseOnHover: false,
                    autoClose: 3000,
                  }
                );
                e.preventDefault();
              }
              //     props.history.push(`/proyecto-editar/${rowInfo.data.id}`);
            }}
            to={`/puesto-laboral-descriptor/${rowInfo.data.CodigoPuestoLaboral}`}
          >
            <ViewList className={classes.sizeIcons} />
          </NavLink>
        </Tooltip>
        <Tooltip title="Editar" style={{ marginRight: 10, cursor: "pointer" }}>
          <Edit
            style={{ color: "#337ab7", cursor: "pointer" }}
            onClick={() =>
              handleAbrirDialogoEditar(rowInfo.data.CodigoPuestoLaboral)
            }
          />
        </Tooltip>
        <Tooltip
          title="Eliminar"
          style={{ marginRight: 10, cursor: "pointer" }}
        >
          <Delete
            style={{ color: "#337ab7", cursor: "pointer" }}
            onClick={() =>
              handleAbrirDialogoEliminar(rowInfo.data.CodigoPuestoLaboral)
            }
          />
        </Tooltip>
      </>
    );
  };

  const handleOnChangeExpertis = async (event, newValue) => {
    handleAbrirBackDrop();
    if (newValue !== null) {
      setCodigoExpertis(newValue.CodigoExpertis);
      setValueAutoCompleteExpertis(newValue);
    }
    handleCerrarBackDrop();
  };

  const handleOnChangeEducacion = async (event, newValue) => {
    handleAbrirBackDrop();
    if (newValue !== null) {
      setCodigoEducacion(newValue.CodigoEducacion);
      setValueAutoCompleteEducacion(newValue);
    }
    handleCerrarBackDrop();
  };

  const selectItem = (idNotificacion, idx) => {
    // const item = { ...items[index] };
    // setSelected(item);
    setCodigoNotificacion(idNotificacion);
    setIdFila(idx + 1);
  };

  const getNotificacion = async () => {
    let session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    }
    try {
      handleAbrirBackDrop();
      let codigo = codigoNotificacion; //session.codigoUsuario;

      const request = await API.fetchGetRequest(
        `api/ResponsabilidadPuestoLaboral/${codigo}`
      );

      if (request.data == null) {
        return;
      }

      setSelected(request.data.ficha);
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  useEffect(() => {
    if (codigoNotificacion > 0) {
      getNotificacion();
    }
  }, [codigoNotificacion]);

  return (
    <React.Fragment>
      <Backdrop className={classes.backdrop} open={buscando}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer />

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={BackdropModal}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paperModal}>
            <h2 id="simple-modal-title">Asignar Educación a Puesto Laboral</h2>
            <Grid container spacing={3}>
              <form
                autoComplete="off"
                onSubmit={handleSubmit(guardarDato, cuandoHayError)}
              >
                <Grid item xs={6} sm={6} md={12} lg={12}>
                  <FormControl className={classes.formControlSelects}>
                    <Controller
                      name="expertis"
                      required
                      render={({ Expertis }) => (
                        <Autocomplete
                          id="expertis"
                          options={maestroExpertis}
                          getOptionLabel={(option) => option.Expertis}
                          value={valueAutoCompleteExpertis}
                          onChange={(event, newValue) =>
                            handleOnChangeExpertis(event, newValue)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Expertis"
                              variant="outlined"
                            />
                          )}
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
                  <FormControl className={classes.formControlSelects}>
                    <Controller
                      name="educacion"
                      required
                      render={({ VerboNivelPuestoLaboral }) => (
                        <Autocomplete
                          id="educacion"
                          options={maestroEducacion}
                          getOptionLabel={(option) =>
                            option.Descripcion + "-" + option.NivelEducativo
                          }
                          value={valueAutoCompleteExpertis}
                          onChange={(event, newValue) =>
                            handleOnChangeEducacion(event, newValue)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Educación"
                              variant="outlined"
                            />
                          )}
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} md={12} lg={12}>
                  <Button
                    width="100%"
                    variant="contained"
                    style={{
                      backgroundColor: "#69bd4b",
                      marginBottom: 10,
                      marginTop: 10,
                      color: "white",
                      width: "93%",
                      marginLeft: 30,
                    }}
                    type="submit"
                  >
                    &nbsp;Asignar Carrera Educativa
                  </Button>
                </Grid>
              </form>
            </Grid>
          </div>
        </Fade>
      </Modal>

      {/* <AppBar
        position="sticky"
        className={classes.appBar}
        style={{ backgroundColor: titleColor }}
      >
        <Toolbar>
          <Typography
            variant="h6"
            noWrap
            style={{ display: "flex", flexGrow: 1 }}
          >
            {`Créditos Internos`}
          </Typography>
          <Tooltip title={"Crear nuevo ítem"} aria-label="Crear nuevo ítem">
            <IconButton
              aria-label="Crear nuevo ítem"
              style={{ color: "white" }}
            >
              <Add onClick={handleOpenModal} />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar> */}
      <Paper className={classes.rootTabla}>
        {maestroPuestoLaboralEducacion !== undefined ? (
          <DataGrid
            dataSource={maestroPuestoLaboralEducacion}
            //   defaultColumns={this.props.columns}
            showBorders={true}
            rowAlternationEnabled={true}
            //ref={(ref) => (dataGrid = ref)}
            allowColumnReordering={true}
            allowColumnResizing={true}
            columnAutoWidth={true}
            onExporting={onExporting}
            className={classes.table}
          >
            <FilterRow visible={showFilterRow} applyFilter={currentFilter} />
            <HeaderFilter visible={showHeaderFilter} />
            <GroupPanel visible={false} />
            <Grouping autoExpandAll={autoExpandAll} />
            <SearchPanel visible={false} width={240} placeholder="Search..." />
            {/* <Selection mode="multiple" /> */}
            <LoadPanel
              enabled={true}
              shadingColor="rgba(0,0,0,0.4)"
              showIndicator={true}
              shading={true}
              showPane={true}
              closeOnOutsideClick={false}
            />
            <Editing
              mode="row"
              useIcons={true}
              allowUpdating={false}
              allowDeleting={false}
            />
            {/* <Column
            caption="Opciones"
            allowFiltering={false}
            allowSorting={false}
            cellRender={cellOpcionesSolicitud}
          /> */}
            <Column
              dataField="CodigoEmpleadoCreditoInterno"
              caption="Código DB"
              dataType="number"
              visible={false}
            />
            <Column
              dataField="CodigoCreditoInterno"
              caption="Código Educación"
              dataType="number"
            />
            <Column dataField="CreditoInterno" caption="Credito Interno" />
            <Column
              dataField="FechaEmision"
              caption="Fecha Emisión"
              dataType="date"
              format="dd/MM/yyyy"
            />
            <Column
              dataField="FechaVencimiento"
              caption="Fecha Vencimiento"
              dataType="date"
              format="dd/MM/yyyy"
            />
            {/* <Column dataField="NivelEducativo" caption="Nivel Educativo" />
          <Column dataField="Expertis" caption="Expertis" /> */}

            <ColumnFixing enabled={true} />
            <Sorting mode="multiple" />
            <Paging defaultPageSize={20} />
            <Pager
              showPageSizeSelector={true}
              allowedPageSizes={[20, 50]}
              showInfo={true}
            />
            <Export enabled={true} allowExportSelectedData={true} />
            <Scrolling columnRenderingMode="virtual" />
          </DataGrid>
        ) : undefined}
      </Paper>
    </React.Fragment>
  );
};

export default FuncionesPuestoLaboral;
