import React, { useState, useEffect } from "react"
import {
  Box,
  Fab,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  CircularProgress,
  makeStyles
} from "@material-ui/core"
import { ZoomIn, ZoomOut, GetApp, Close } from "@material-ui/icons"
import { green } from "@material-ui/core/colors"
import api from "../../../utils/api"
import dayjs from "dayjs"
import { getCodigoUsuario } from "../../utils/utilidades"
import { toast } from "react-toastify"
import { useStoreState } from "easy-peasy"
import './ViewPDF.css'

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    overflow: "auto",
    height: "70vh",
    width: "100%",
  },
  appBar: {
    top: "auto",
    bottom: 0,
  },
  page: {
    // [theme.breakpoints.up("md")]: {
    //   minWidth: "50%"
    // },
    boxShadow: '6px 6px 11px 0px rgba(31,31,31,0.75)',
    margin: '10px auto'
    // [theme.breakpoints.up("xs")]: {
    //   width: "100%",
    // }
  },
  pageContainer: {
    position: 'relative'
  },
  numContainer: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    marginRight: 5,
    color: "#FFF",
    backgroundColor: green[500],
  },
  barContainter: {
    display: "flex",
    justifyContent: "space-between",
  },
  viewContainer: {
    width: '100%'
  }
}));
// const pdfjsLib = window["pdfjs-dist/build/pdf"];
// pdfjsLib.GlobalWorkerOptions.workerSrc = "/libs/pdf.worker.js";
const pdfjsLib = window.pdfjsLib

const ViewPDF = ({
    solicitud,
    docBase64,
    downloadable = false,
    obsoleto=false,
    onClose,
    closable=true
  }) => {
  const { permisosGesDoc } = useStoreState(state => ({
    permisosGesDoc: state.permisosGesDoc
  }))
  const classes = useStyles()
  const [loading, setLoading] = useState(true)
  const [pages, setPages] = useState(0)
  const [doc, setDoc] = useState(null)
  const [pdfDoc, setPdfDoc] = useState(null)
  const [scale, setScale] = useState(1)
  const [downloading, setDownloading] = useState(false)
  const loadDoc = () => {
    const loader = pdfjsLib.getDocument({ data: doc })
    loader.promise.then(
      (pdf) => {
        setPages(pdf.numPages)
        setPdfDoc(pdf)
        setLoading(false)
      },
      (reason) => {
        setPages(0)
        console.error(reason)
      }
    )
  }
  const renderDoc = () => {
    const container = document.getElementById("pdf-container")
    while (container.firstChild) {
      container.removeChild(container.firstChild)
    }
    for (var pageNumber = 1; pageNumber <= pdfDoc.numPages; pageNumber++) {
      pdfDoc.getPage(pageNumber).then(function(page) {
        var div = document.createElement("div")
        div.classList.add("page")
        container.appendChild(div)
        var viewport = page.getViewport({ scale })
        var canvas = document.createElement("canvas")
        var context = canvas.getContext("2d")
        canvas.height = viewport.height
        canvas.width = viewport.width
        div.appendChild(canvas)
        var renderContext = {
          canvasContext: context,
          viewport: viewport
        }
        page.render(renderContext)
        page.getAnnotations().then(function(annotations) {
          annotations.forEach(function(annotation) {
            if (annotation.url) {
              var link = document.createElement("a")
              link.href = annotation.url
              link.target = "_blank"
              link.classList.add("pdf-link")
              link.style.left = annotation.rect[0] * viewport.transform[0] + "px"
              link.style.top = (viewport.transform[5] - annotation.rect[3] * viewport.transform[0]) + 10 + "px"
              link.style.width = Math.round(annotation.rect[2] - annotation.rect[0]) * viewport.transform[0] + "px"
              link.style.height = Math.round(annotation.rect[3] - annotation.rect[1]) * viewport.transform[0]  + "px"
              div.appendChild(link)
            }
          })
        })
      })
    }
  }
  const downloadDoc = async () => {
    try {
      setDownloading(true);
      const body = {
        CodigoUsuario: getCodigoUsuario(),
        Accion: "Descarga",
        IdObjeto: solicitud.IdSolicitud,
        Objeto: "Solicitud",
        Fecha: dayjs().toISOString(),
        Comentario: "El documento fue descargado",
        Activo: true,
      };
      await api.post("api/documentos/guardar-accion-descargar-documento", body);
      downloadPDF();
    } catch (error) {
      console.log(error);
      toast.error("No se pudo descargar el documento");
    } finally {
      setDownloading(false);
    }
  };
  const downloadPDF = () => {
    var src = `data:application/pdf;base64,${docBase64}`;
    var link = window.document.createElement("a");
    link.href = src;
    link.download = "documento.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  useEffect(() => {
    if (pdfDoc && pages) {
      renderDoc()
    }
  }, [pdfDoc, pages, scale]);
  useEffect(() => {
    if (doc) {
      try {
        loadDoc()
      } catch (error) {
        toast.error('El PDF no pudo ser cargado')
      }
    }
  }, [doc]);
  useEffect(() => {
    if (docBase64) {
      setDoc(window.atob(docBase64));
    }
  }, [docBase64]);
  return (
    <div className={classes.viewContainer}>
      {loading && <Box style={{marginTop: 100}} display="flex" justifyContent="center"><CircularProgress /></Box>}
      <AppBar position="fixed" style={{ background: "#2B3C4D", zIndex: 1250 }}>
        <Toolbar className={classes.barContainter}>
          <div>
            <Fab
              size="small"
              className={classes.button}
              onClick={() => {
                if (scale > 0.25) {
                  setScale((value) => value - 0.25);
                }
              }}
            >
              <ZoomOut />
            </Fab>
            <Fab
              size="small"
              className={classes.button}
              onClick={() => setScale((value) => value + 0.25)}
            >
              <ZoomIn />
            </Fab>
          </div>
          <Typography variant="h6">Paginas: {pages}</Typography>
          <div>
            {
              (!obsoleto && permisosGesDoc.includes('GESDOC.DescargarDocumento')
                || (obsoleto && permisosGesDoc.includes('GESDOC.DescargarObsoleto')))
              ? <IconButton
                disabled={!docBase64 || downloading}
                color="inherit"
                onClick={downloadDoc}
                style={{ visibility: downloadable ? "visible" : "hidden" }}
              >
                <GetApp />
              </IconButton>
              : <p></p>
            }
            <IconButton
              color="inherit"
              onClick={onClose}
              style={{ visibility: closable ? "visible" : "hidden" }}
            >
              <Close />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <div id="pdf-container"></div>
    </div>
  );
};

export default ViewPDF;
