import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { IOSSwitch } from "../../helpers/components/ios-switch";
import { ToastContainer, toast } from "react-toastify";
import MultiSelect from "@kenshooui/react-multi-select";
import "react-toastify/dist/ReactToastify.css";
import "@kenshooui/react-multi-select/dist/style.css";

//Own Components
import Layout from "../../layout/containers/Layout";
import API from "../../utils/api";
import SaveButton from "../../helpers/components/save-button-float";
import { isSignedIn } from "../../utils/auth";
import {validaPermisoPaginaActual} from "../../utils/helpers";

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%"
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  buttonAdd: {
    margin: theme.spacing.unit
  }
});

class Detail extends React.Component {
  state = {
    open: false,
    CodigoRol: 0,
    rol: {
      CodigoRol: 0,
      Rol: "",
      Descripcion: "",
      Activo: true,
      Eliminado: false,
      FechaInserto: "",
      PermisosRol: []
    },
    permisos: []
  };

  async componentWillMount() {
    //CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
    const llaveAgrupacion = "Menu.Principal.Web";
    const llavePagina = "Menu.Principal.Web.Roles";
    await validaPermisoPaginaActual(llaveAgrupacion, llavePagina);
    //FIN DE CONSULTA DE PERMISOS

    await this.setState({ CodigoRol: this.props.match.params.id });
    await this.makeRequest();
  }

  makeRequest = async () => {
    const request = await API.fetchGetRequest(
      `api/desktop/rol/${this.state.CodigoRol}`
    );
    const rol = request.data.rol;
    const permisos = request.data.permisos;

    this.setState({
      rol,
      permisos
    });
  };

  guardar = async () => {
    try {
      let vIsSignedIn = await isSignedIn();

      if (vIsSignedIn.response) {
        let request;

        const data = {
          ...this.state.rol,
          UsuarioModifico: vIsSignedIn["userName"]
        };

        request = await API.post(
          `api/desktop/rol/${this.state.rol.CodigoRol}`,
          data
        );
        if (request.status != 200) {
          alert(
            "Hubo un error al guardar el registro, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        }

        if (request.statusCode === 401) {
          //alert("cerrar sesion");
        } else {
          if (request.data.response) {
            toast.info(request.data.mensaje);
          } else {
            toast.error(request.data.mensaje);
          }
        }
      }
    } catch (e) {
      alert("hubo un error");
    }
  };

  handleChange = name => event => {
    let obj = this.state.rol;

    obj[name] = event.target.value;
    this.setState({
      obj
    });
  };

  handleChangeSwitch = name => event => {
    let obj = this.state.rol;

    obj[name] = event.target.checked;
    this.setState({ obj });
  };

  handleChangePermisos = selectedItems => {
    let rol = this.state.rol;

    rol.PermisosRol = selectedItems;
    this.setState({ rol });
  };

  render() {
    const { classes } = this.props;
    let { permisos, rol } = this.state;

    return (
      <div>
        <Layout titulo={`Rol ${this.state.rol.Rol}`}>
          <Grid container spacing={8}>
            <Grid item xs />
            <Grid item xs={7}>
              <Card className={classes.card}>
                <CardContent>
                  <Typography color="textSecondary" gutterBottom>
                    Datos generales
                  </Typography>
                  <TextField
                    disabled
                    id="standard-disabled"
                    label="Codigo"
                    value={this.state.rol.CodigoRol}
                    className={classes.textField}
                    margin="normal"
                  />

                  <TextField
                    // id="standard-name"
                    label="Rol"
                    className={classes.textField}
                    value={this.state.rol.Rol}
                    onChange={this.handleChange("Rol")}
                    margin="normal"
                  />

                  <TextField
                    // id="standard-name"
                    label="Descripcion"
                    className={classes.textField}
                    value={this.state.rol.Descripcion}
                    onChange={this.handleChange("Descripcion")}
                    margin="normal"
                  />

                  <br />
                  <br />
                  <Grid container>
                    <Grid item xs={10}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        className={classes.textField}
                      >
                        Activo
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <FormControlLabel
                        className={classes.textField}
                        control={
                          <IOSSwitch
                            checked={this.state.rol.Activo}
                            onChange={this.handleChangeSwitch("Activo")}
                            value="Activo"
                          />
                        }
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <br />
              <br />
              <Card className={classes.card}>
                <CardContent>
                  <Typography color="textSecondary" gutterBottom>
                    Permisos
                  </Typography>
                  <MultiSelect
                    items={permisos}
                    selectedItems={rol.PermisosRol}
                    onChange={this.handleChangePermisos}
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs />
          </Grid>
          <SaveButton onClick={() => this.guardar()} />
          <ToastContainer />
        </Layout>
      </div>
    );
  }
}

// We need an intermediary variable for handling the recursive nesting.
const DetailContainer = withStyles(styles)(Detail);

export default withRouter(DetailContainer);
