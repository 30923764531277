import React, { useState, useEffect } from 'react';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@material-ui/core'
import { toast } from 'react-toastify'
import { useStoreState } from 'easy-peasy'
import SelectUsuario from '../usuarios/SelectUsuario';
import { getCodigoUsuario } from '../../utils/utilidades'
import LoadingButton from '../utils/LoadingButton';
import api from '../../../utils/api';

const DocumentoTransferir = ({ documento, onClose, onRefresh }) => {
  const { usuarios } = useStoreState(state => ({
    usuarios: state.usuarios
  }))
  const [me, setMe] = useState(null)
  const [loading, setLoading] = useState(false)
  const [solicitente, setSolicitante] = useState(null)
  const [nuevo, setNuevo] = useState(null)
  const changeAutor = async () => {
    if (!nuevo) {
      toast.error('Selecciona el nuevo autor')
      return
    }
    if (!solicitente) {
      toast.error('Selecciona el autor')
      return
    }
    try {
      setLoading(true)
      const body = {
        IdSolicitud: documento.IdSolicitud,
        CodigoNuevoAutor: nuevo.CodigoUsuario,
        CodigoSolicitante: solicitente.CodigoUsuario
      }
      await api.realizarPeticionPostPut('api/documentos/solicitud/crear-solicitud-cambio-autor-actualizacion-con-cambios', body, 'POST')
      onRefresh()
    } catch (error) {
      toast.error('Ha ocurrido un error')
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    if (usuarios.length) {
      const codigo = getCodigoUsuario()
      const found = usuarios.find(i => i.CodigoUsuario == codigo)
      setMe(found)
    }
  }, [usuarios])
  return <>
    <DialogTitle>Transfeir documento</DialogTitle>
    <DialogContent>
      <SelectUsuario
        onSelect={(value) => setSolicitante(value)}
        label="Autor original"
        initValue={me}
        disabled={true}
      />
      <SelectUsuario
        onSelect={(value) => setNuevo(value)}
        label="Autor nuevo"
      />
    </DialogContent>
    <DialogActions>
      <Button variant="contained" onClick={onClose}>Cancelar</Button>
      <LoadingButton
        loading={loading}
        variant="contained"
        color="primary"
        onClick={changeAutor}
      >Transferir</LoadingButton>
    </DialogActions>
  </>
}

export default DocumentoTransferir
