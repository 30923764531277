import React,{useState,useEffect} from 'react';
import Modal from "@material-ui/core/Modal";
import { Chip, Grid, makeStyles } from "@material-ui/core";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { IOSSwitch } from "../../helpers/components/ios-switch";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Loader from "react-loader-spinner";
import API from '../../utils/api';
import { setDate } from 'date-fns';
import { isSignedIn } from '../../utils/auth';
import { toast } from 'react-toastify';


 const PermisosLandmarkModal = ({open, idTipoPunto, handleClose})=>{
    const classes  = styles();

    const [listaRolesObjeto, setListaRolesObjeto] = useState([]);
    const [editandoRol, setEditandoRol] = useState(false);
    const [idRolEditando, setIdRolEditando] = useState(null);
    const [nombreRolEditando, setNombreRolEditando] = useState('');
    const [rolSeleccionado, setRolSeleccionado] = useState([]);
    const [listaRoles, setListaRoles] = useState([]);
    const [cargandoGrabacionPermisos,setCargandoGrabacionPermisos] = useState(false);
    const [C, setC] = useState(false);
    const [R, setR] = useState(false);
    const [U, setU] = useState(false);
    const [D, setD] = useState(false);

    useEffect(()=>{
            cargarListaRoles();
    },[])

    useEffect(()=>{
      if(idTipoPunto === null){
        return;
      }

      cargarListaRolesPorObjeto();
    },[idTipoPunto])

    const cargarPermisosPorId = async (idPermiso) => {
      let respuesta = {};
      const verify = await isSignedIn();
  
      console.log(verify.response);
      if (!verify.response) {
        window.location.href = "/login";
      }
  
      const request = await API.fetchGetRequest(
        `api/permisolandmark/getbyid/${idPermiso}`
      );
  
      if (request.data.data !== null) {
        respuesta = request.data.data;
        console.log("PermisoPorID", respuesta);
       
          setC(respuesta.C);
          setR(respuesta.R);
          setU(respuesta.U);
          setD(respuesta.D);          
         setEditandoRol(true);
          setIdRolEditando(respuesta.CodigoRol);
          setNombreRolEditando(respuesta.Rol);
        
      }
      if (request.statusCode != 200) {
        alert(
          "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
        );
      }
    };
  
         
      
      
      // Función para manejar cambios (generalmente usada en inputs)
      const handleChange = (name) => (event) => {
        if (          
          name === "C" ||
          name === "R" ||
          name === "U" ||
          name === "D"     
        ) {
          const value = event.target.checked
          // eslint-disable-next-line default-case
          switch(name){
            case "C" :  setC(value);
            break;
            case "R" :  setR(value);
            break;
            case "U" :  setU(value);
            break;
            case "D" :  setD(value);
            break;
            default:
              return;
            }
        } 
      };

      const cargarListaRolesPorObjeto  = async()=>{
        try {
          const request = await API.fetchGetRequest(
            `api/permisolandmark/getbytipoLandmark/${idTipoPunto}`
          );
          if (Array.isArray(request.data.data)) {
            setListaRolesObjeto(request.data.data);
          }
        } catch (error) {
          
        }
      }
      
      const otorgarPermisos = async () => {
        
    
        try {
          setCargandoGrabacionPermisos(true);
    
          let vIsSignedIn = await isSignedIn();
          let palabra = "";
          if (vIsSignedIn.response) {
            let request;
            let data;
    
            let codigoRol = [];
    
            if (editandoRol) {
              codigoRol.push(idRolEditando);
            } else {
              rolSeleccionado.forEach((item, i) => {
                codigoRol.push(item.CodigoRol);
              });
            }
    
            if (codigoRol.length < 1) {
              toast.warn("Debe seleccionar al menos un rol");
              return;
            }

            data = {
              Id: null,
             IdTipoLandmark: idTipoPunto,
             CodigoRol: codigoRol,
              C ,
              R ,
              U ,
              D ,
            }                       
              
            
            console.log("Rol seleccionado");
            console.log(rolSeleccionado);
             request = await API.post(`api/permisolandmark/grabarEditar`, data);
            if (request.status != 200) {
              alert(
                "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
              );
            } else {
              toast.success("El permiso se ha otorgado con éxito ");
               setC(false);
              setR(false);
              setU(false);
              setD(false);
             setEditandoRol(false);
              setIdRolEditando(null);
              setNombreRolEditando("");
              let rselec = [];
              setRolSeleccionado(rselec);
              cargarListaRolesPorObjeto(idTipoPunto);
              //this.handleClose();
            }
          }
        }catch (e) {
          alert("hubo un error");
          console.error("Error al otorgar permiso:", e);
        } finally {
          setCargandoGrabacionPermisos(false);
        }
      };

      
  const revocarPermisos = async (idPermiso) => {
     try {
      let vIsSignedIn = await isSignedIn();
      let palabra = "";
      if (vIsSignedIn.response) {
        let request;
        let data;

        data = {
          Id: idPermiso,         
        };

        request = await API.post(`api/permisolandmark/Eliminar`, data);
        if (request.status != 200) {
          alert(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        }

        if (request.status === 401) {
          //alert("cerrar sesion");
        } else {
          
            setRolSeleccionado([]);
            setC(false);
            setR(false);
            setU(false);
            setD(false);
           setEditandoRol(false);
            setIdRolEditando(null);
            setNombreRolEditando("");
      
          toast.success("El permiso se ha revocado con éxito ");
          cargarListaRolesPorObjeto();
          //this.handleClose();
        }
      }
    } catch (e) {
      alert("hubo un error");
    }
  };

      
      // Función para limpiar el estado
      const limpiarState = () => {
        // Lógica para limpiar el estado de la aplicación
        console.log('Limpiando el estado');
        // Aquí puedes resetear el estado a sus valores iniciales
      };

      const cargarListaRoles = async()=> {
        const request = await API.fetchGetRequest(`api/permiso/listarRoles/`);
        if (Array.isArray(request.data.data)) {
         setListaRoles(request.data.data);
        }
      }

    return(
        <Modal
        aria-labelledby="Administración de permisos"
        aria-describedby="Administración de permisos"
        open={open}
        onClose={handleClose}
      >
        
        
        <div style={getBigModalStyle()} className={classes.modalLargote}>
          <form className={classes.container} noValidate autoComplete="off">
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                style={{ height: 500 }}
              >
                <h4>Roles con acceso</h4>
                <PerfectScrollbar style={{ width: "100%", maxHeight: 700 }}>
                  {listaRolesObjeto.map((permiso, i) => (
                    <Chip
                      key={permiso.Id}
                      label={permiso.Rol}
                      onClick={() => {
                        cargarPermisosPorId(permiso.Id);
                      }}
                      onDelete={() => {
                        revocarPermisos(permiso.Id);
                      }}
                      color="primary"
                      style={{
                        margin: 5,
                      }}
                    />
                  ))}
                </PerfectScrollbar>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                style={{ height: 700 }}
              >
                {editandoRol ? (
                  <h4>{`Edita los permisos del rol ${nombreRolEditando}`}</h4>
                ) : (
                  <h4>Selecciona uno o más roles para otorgarles acceso</h4>
                )}

                <PerfectScrollbar style={{ width: "100%", maxHeight: 700 }}>
                
                  {!editandoRol && (
                    <Autocomplete
                      value={rolSeleccionado}
                      required
                      multiple
                      className={classes.textField}
                      style={{ width: "100%", margin: 0, marginTop: 25 }}
                      options={listaRoles}
                      autoHighlight
                      getOptionLabel={(option) => option.Descripcion}
                      disabled={false}
                      renderOption={(option) => (
                        <React.Fragment>
                          <span></span>
                          {option.Descripcion}
                        </React.Fragment>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Rol"
                          variant="outlined"
                          required
                        />
                      )}
                      
                      //Cuando es de selección múltiple:
                      onChange={(event, newValue) => {
                        setRolSeleccionado([...newValue]);
                      }}
                    />
                  )}

                  <Grid
                    item
                    xs={12}
                    lg={12}
                    md={12}
                    sm={12}
                    style={{ padding: 8 }}
                  >
                    <Paper>
                      <Grid
                        item
                        xs={12}
                        lg={12}
                        md={12}
                        sm={12}
                        style={{ padding: 8 }}
                      >
                        <Grid item xs={12} sm={12} lg={12}>
                          <Typography
                            variant="h6"
                            style={{ color: "#16a085" }}
                          >
                            
                          </Typography>
                        </Grid>
              
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <FormControlLabel
                            control={
                              <IOSSwitch
                                checked={R}
                                onChange={handleChange("R")}
                                name="Read"
                              />
                            }
                            label="Ver landmarks"
                          />
                          </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <FormControlLabel
                            control={
                              <IOSSwitch
                                checked={C}
                                onChange={handleChange("C")}
                                name="Create"
                              />
                            }
                            label="Crear nuevos landmarks"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <FormControlLabel
                            control={
                              <IOSSwitch
                                checked={U}
                                onChange={handleChange("U")}
                                name="Update"
                              />
                            }
                            label="Modificar landmarks"
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <FormControlLabel
                            control={
                              <IOSSwitch
                                checked={D}
                                onChange={handleChange("D")}
                                name="Delete"
                              />
                            }
                            label="Eliminar landmarks"
                          />
                        </Grid>
                      </Grid>
                    </Paper>
                    </Grid>
                  <Grid item xs={12} lg={12} sm={12}>
                    <Button
                      disabled={cargandoGrabacionPermisos}
                      variant="contained"
                      style={{ backgroundColor: "#69bd4b", color: "white" }}
                      className={classes.buttonAdd}
                      onClick={() => {
                        otorgarPermisos();
                      }}
                    >
                      {cargandoGrabacionPermisos ? (
                        <div
                          style={{
                            width: "100%",
                            marginTop: 0,
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Loader
                            type="Oval"
                            color="#FFF"
                            height="20"
                            width="20"
                            style={{ textAlign: "left", marginRight: 5 }}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                      Guardar
                    </Button>
                    <Button
                      variant="contained"
                      style={{ backgroundColor: "#c0392b", color: "white" }}
                      className={classes.buttonAdd}
                      onClick={() => {
                        setC(false);
                        setR(false);
                        setU(false);
                        setD(false);
                       setEditandoRol(false);
                        setIdRolEditando(null);
                        setNombreRolEditando("");
                        let rselec = [];
                        setRolSeleccionado(rselec);
                        handleClose();
                      }}
                    >
                      Cancelar
                    </Button>
                  </Grid>
                </PerfectScrollbar>
              </Grid>
              <Grid item xs={12} lg={12} sm={12} md={12}>
                <Button
                  style={{
                    width: "100%",
                  }}
                  className={classes.buttonAdd}
                  onClick={() => {
                    setC(false);
                    setR(false);
                    setU(false);
                    setD(false);
                   setEditandoRol(false);
                    setIdRolEditando(null);
                    setNombreRolEditando("");
                    let rselec = [];
                    setRolSeleccionado(rselec);
                    handleClose()}}
                  color="primary"
                >
                  Cerrar
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Modal>
    )
}

const styles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
  
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    textField: {
      marginLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit,
      width: "100%",
    },
    paper: {
      position: "absolute",
      width: theme.spacing.unit * 50,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing.unit * 4,
      outline: "none",
    },
    paperUserModal: {
      position: "absolute",
      width: "75%",
      height: "600px",
      maxHeight: "600px",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing.unit * 2,
      outline: "none",
    },
    modalLargote: {
      position: "absolute",
      width: "75%",
      height: "800px",
      maxHeight: "800px",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing.unit * 2,
      outline: "none",
    },
    secondNavigationModal: {
      position: "absolute",
      width: "50%",
      height: "400px",
      maxHeight: "400px",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing.unit * 2,
      outline: "none",
    },
    buttonAdd: {
      margin: theme.spacing.unit,
    },
    popover: {
      pointerEvents: "none",
    },
    paperPopover: {
      padding: theme.spacing(1),
      background: "#000",
      color: "#fff",
    },
  })
);

function getBigModalStyle() {
    const top = 50;
    const left = 50;
  
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
      maxHeight: 700,
    };
  }

  export default PermisosLandmarkModal;