import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import SearchIcon from "@material-ui/icons/Search";
import GetAppIcon from "@material-ui/icons/GetApp";
import Loader from "react-loader-spinner";
import Popover from "@material-ui/core/Popover";

import Pivot from "../containers/pivotGrid";

//Pivot Grid
import ArrayStore from "devextreme/data/array_store";
import PivotGridDataSource from "devextreme/ui/pivot_grid/data_source";

import Chart, {
  AdaptiveLayout,
  CommonSeriesSettings,
  Size,
  Tooltip,
} from "devextreme-react/chart";
import PivotGrid, { FieldChooser, Export } from "devextreme-react/pivot-grid";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";

import ReactExport from "react-data-export";

//Own components
import Layout from "../../layout/containers/Layout";
import AddButton from "../../helpers/components/add-button-float";
import API from "../../utils/api";
import { isSignedIn } from "../../utils/auth";
import { convertYYYYMMDDToDate } from "../../utils/helpers";
import {validaPermisoPaginaActual} from "../../utils/helpers";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  paperUserModal: {
    position: "absolute",
    width: "75%",
    height: "600px",
    maxHeight: "600px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  popover: {
    pointerEvents: "none",
  },
  paperPopover: {
    padding: theme.spacing(1),
    background: "#000",
    color: "#fff",
  },
});

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const dataSet1 = [
  {
    name: "Johson",
    amount: 30000,
    sex: "M",
    is_married: true,
  },
  {
    name: "Monika",
    amount: 355000,
    sex: "F",
    is_married: false,
  },
  {
    name: "John",
    amount: 250000,
    sex: "M",
    is_married: false,
  },
  {
    name: "Josef",
    amount: 450500,
    sex: "M",
    is_married: true,
  },
];

class ReportePanel extends Component {
  state = {
    actionType: "Agregar",
    cargando: false,
    dataReport: [],
    fieldsReport: [],
    displayDownloadButton: "hidden",
  };

  async componentWillMount() {

    //CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
    const llaveAgrupacion = "Menu.Principal.Web";
    const llavePagina = "Menu.Principal.Web.Reportes";
    await validaPermisoPaginaActual(llaveAgrupacion, llavePagina);
    //FIN DE CONSULTA DE PERMISOS

    // this._pivotGrid.bindChart(this._chart, {
    //   dataFieldsDisplayMode: "splitPanes",
    //   alternateDataFields: false,
    // });
  }

  async cargarReporte() {
    try {
      //Loader
      this.setState({ cargando: true });
      console.log("Hola cargarReporte");

      const request = await API.post(`api/reportesdesktop/procesosgestion1`);
      if (request.status != 200) {
        alert(
          "Hubo un error al realizar la operación, consulta con un administrador"
        );
      }

      console.log(request.status);
      if (request.statusCode === 401) {
        //alert("cerrar sesion");
        window.location.href = "/login";
      } else {
        if (Array.isArray(request.data.data)) {
          this.setState(
            {
              dataReport: request.data.data,
            },
            () => {
              //Borra elemento del listado de columnas
              this.setState({ fieldsReport: [] });

              //Obtiene columnas del reporte
              if (this.state.dataReport.length > 0) {
                var myObject = this.state.dataReport[0];
                var keyNames = Object.keys(myObject);
                keyNames.forEach((element) => {
                  //console.log(element);
                  let columnas = this.state.fieldsReport;
                  columnas.push(element);
                  this.setState({ fieldsReport: columnas }, () => {
                    //Muestra botón para descargar
                    if (this.state.fieldsReport.length > 0) {
                      this.setState({ displayDownloadButton: "visible" });
                    }
                  });
                });
              } else {
                //Oculta botón para descargar
                this.setState({ displayDownloadButton: "hidden" });
              }
            }
          );
          store = new ArrayStore(request.data.data);
          dataSource = new PivotGridDataSource({
            fields: [
              {
                caption: "NombreAccionMejora",
                width: 150,
                dataField: "NombreAccionMejora",
                area: "row",
              },
            ],
            store: store,
          });

          this._pivotGrid.bindChart(this._chart, {
            dataFieldsDisplayMode: "splitPanes",
            alternateDataFields: false,
          });
        }
      }
    } catch (error) {
      console.log(error);
      if (error.toString().includes("401")) {
        window.location.href = "/login";
      }
    } finally {
      this.setState({ cargando: false });
    }
  }

  handlePopoverOpen = (event, textoPopover) => {
    this.setState({
      anchorEl: event.currentTarget,
      popOverOpen: true,
      textoPopover: textoPopover,
    });
  };

  handlePopoverClose = () => {
    this.setState({ anchorEl: null, popOverOpen: false, textoPopover: "" });
  };

  render() {
    const { classes } = this.props;
    return (
      <Layout titulo="Reportes">
        {this.state.cargando ? (
          <div
            style={{
              position: "fixed",
              margin: "auto",
            }}
          >
            <Loader type="Oval" color="#3498db" height="50" width="50" />
          </div>
        ) : (
          <></>
        )}

        <Grid container spacing={3}>
          <Grid item xs={12} lg={6} sm={6}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              style={{ width: "100%" }}
              endIcon={<SearchIcon />}
              onClick={() => {
                this.cargarReporte();
              }}
            >
              Ver reporte
            </Button>
          </Grid>
          <Grid item xs={12} lg={6} sm={6}>
            <ExcelFile
              element={
                <Button
                  variant="contained"
                  style={{
                    marginTop: 0,
                    backgroundColor: "#69bd4b",
                    color: "white",
                    width: "100%",
                    visibility: this.state.displayDownloadButton,
                  }}
                  className={classes.buttonAdd}
                  endIcon={<GetAppIcon />}
                >
                  Descargar
                </Button>
              }
            >
              <ExcelSheet data={this.state.dataReport} name="Datos">
                {this.state.fieldsReport.map((item, i) => (
                  <ExcelColumn key={i} label={item} value={item} />
                ))}
              </ExcelSheet>
            </ExcelFile>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12} sm={12}>
            <Chart
              ref={(ref) => {
                if (this._chart === null || this._chart === undefined) {
                  this._chart = ref.instance;
                }
              }}
            >
              <Size height={200} />
              <Tooltip enabled={true} customizeTooltip={customizeTooltip} />
              <CommonSeriesSettings type="bar" />
              <AdaptiveLayout width={450} />
            </Chart>
          </Grid>

          <Grid item xs={12} lg={12} sm={12}>
            <PivotGrid
              id="pivotgrid"
              dataSource={dataSource}
              allowSortingBySummary={true}
              allowFiltering={true}
              showBorders={true}
              showColumnTotals={false}
              showColumnGrandTotals={false}
              showRowTotals={false}
              showRowGrandTotals={false}
              allowExpandAll={true}
              allowSearch={true}
              ref={(ref) => {
                if (this._pivotGrid === null || this._pivotGrid === undefined) {
                  this._pivotGrid = ref.instance;
                }
              }}
            >
              <Export enabled={true} fileName="PivotTable" />
              <FieldChooser enabled={true} height={400} />
            </PivotGrid>
          </Grid>
        </Grid>

        <ToastContainer />
        <Popover
          id="mouse-over-popover"
          className={classes.popover}
          classes={{
            paper: classes.paperPopover,
          }}
          open={this.state.popOverOpen}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          onClose={this.handlePopoverClose}
          disableRestoreFocus
        >
          <Typography>{this.state.textoPopover}</Typography>
        </Popover>
      </Layout>
    );
  }
}
let dataSource;
let store;

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "GTQ",
  minimumFractionDigits: 0,
});

function customizeTooltip(args) {
  const valueText = currencyFormatter.format(args.originalValue);
  return {
    html: `${args.seriesName} | Total<div class="">${args.originalValue}</div>`,
  };
}

const ReportePanelContainer = withStyles(styles)(ReportePanel);
export default withRouter(ReportePanelContainer);
