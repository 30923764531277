import React, { useState, useEffect } from 'react'
import {  Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Grid, Fab, makeStyles, IconButton, MenuItem,
          Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, FormControl, InputLabel, Select 
        } from '@material-ui/core'
import { Add, Delete, Edit } from '@material-ui/icons'
import { ToastContainer, toast } from 'react-toastify'

import Layout from '../../layout/containers/Layout'
import Api from "../../utils/api"
import { codificacionModel } from '../utils/codificacion-model'
import LoadingButton from '../componentes/utils/LoadingButton'


const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  }
}))


const CodificacionDocumentos = () => 
{
  const classes = useStyles()
  const [codificaciones, setCodificaciones] = useState([])
  const [dialogAdd, setDialogAdd] = useState(false)
  const [dialogEdit, setDialogEdit] = useState(false)
  const [divisionDocumento, setDivisionDocumento] = useState([])
  const [tipoDocumento, setTipoDocumento] = useState([])
  const [macroproceso, setMacroproceso] = useState([])

  const [formAdd, setFormAdd] = useState({
    divisionDocumento: "",
    tipoDocumento: "",
    macroProceso: "",
    correlativoInicio: "",
    correlativoFin: ""
  })
  const [formEdit, setFormEdit] = useState({
    divisionDocumento: '',
    tipoDocumento: '',
    macroProceso: '',
    correlativoInicio: '',
    correlativoFin: ''
  })  
  const [selected, setSelected] = useState(null)
  const [loading, setLoading] = useState(false)

  const getDivisionDocumentosYMacroprocesos = async () => {
    const { data } = await Api.fetchGetRequest('api/documentos/macroproceso')
    setDivisionDocumento(data)
    setMacroproceso(data)
  }

  const getTiposDocumentos = async () => {
    const { data } = await Api.fetchGetRequest('api/documentos/tipodocumento')
    setTipoDocumento(data)
  }

  const getCodificaciones = async () => {
    const { data } = await Api.fetchGetRequest('api/documentos/codificaciondocumento')
    setCodificaciones(data)
  }
  const changeAdd = (dato, valor) => {
    const tmp = { ...formAdd }
    tmp[dato] = valor
    setFormAdd(tmp)
  }
  const changeEdit = (dato, valor) => {
    const tmp = { ...formEdit }
    tmp[dato] = valor
    setFormEdit(tmp)
  }

  const selectEdit = (id) => {
    const item = codificaciones.find(i =>  i.Id === id)
    setFormEdit({
      divisionDocumento: item.IdDivisionDocumento,
      tipoDocumento: item.IdTipoDocumento,
      macroProceso: item.IdMacroproceso,
      correlativoInicio: item.CorrelativoInicio,
      correlativoFin: item.CorrelativoFin,
    })
    setSelected({...item})
    setDialogEdit(true)
  }

  const updateCodificacion = async () => {
    try {
      setLoading(true)
      const datos = await codificacionModel.validate(formEdit)
      const usuario = JSON.parse(localStorage.getItem('userData'))
      const body = {
        DivisionDocumento: datos.divisionDocumento,
        TipoDocumento: datos.tipoDocumento,
        MacroProceso: datos.macroProceso,
        CorrelativoInicio: datos.correlativoInicio,
        CorrelativoFin: datos.correlativoFin,
        usuarioModifico: usuario.usuarioAD
      }
      await Api.post(`api/documentos/codificaciondocumento/editar/${selected.Id}`, body)
      setDialogEdit(false)
      setSelected(null)
      getCodificaciones()
      getDivisionDocumentosYMacroprocesos()
    } catch(err) {
      if (err.name === 'ValidationError') {
        toast.error(err.errors[0])
      } else {
        toast.error('Ha ocurrido un error')
      }
    } finally {
      setLoading(false)
    }
  }

  const insertCodificacion = async () => {
    try {
      setLoading(true)
      const datos = await codificacionModel.validate(formAdd)
      const usuario = JSON.parse(localStorage.getItem('userData'))
      const body = {
        DivisionDocumento: datos.divisionDocumento,
        TipoDocumento: datos.tipoDocumento,
        MacroProceso: datos.macroProceso,
        CorrelativoInicio: datos.correlativoInicio,
        CorrelativoFin: datos.correlativoFin,
        UsuarioInserto: usuario.usuarioAD
      }
      await Api.post('api/documentos/codificaciondocumento/crear', body)
      setDialogAdd(false)
      setFormAdd({
        divisionDocumento: "",
        tipoDocumento: "",
        macroProceso: "",
        correlativoInicio: "",
        correlativoFin: ""
      })
      getCodificaciones()
      getDivisionDocumentosYMacroprocesos()
    } catch(err) {
      if (err.name === 'ValidationError') {
        toast.error(err.errors[0])
      } else {
        toast.error('Ha ocurrido un error')
      }
    } finally {
      setLoading(false)
    }
  }
  const borrarCodificacion = async (id) => {
    try {
      if (window.confirm('¿Estas seguro de borrar el registro?')) {
        const usuario = JSON.parse(localStorage.getItem('userData'))
        await Api.post(`api/documentos/codificaciondocumento/desactivar/${id}`, {
          usuarioModifico: usuario.usuarioAD
        })
        getCodificaciones()
        getDivisionDocumentosYMacroprocesos()
      }
    } catch (error) {
      console.log(error)
      toast.error('Ha ocurrido un error')
    }
  }

  useEffect(() => {
    getCodificaciones()
    getDivisionDocumentosYMacroprocesos()
    getTiposDocumentos()
  }, [])

  return (
  <Layout titulo="Codificación Documenos">    
    <Grid container justify="center">      
      <Grid item xs={12} md={6}>        
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Codificación</TableCell>
                <TableCell>Rango correlativo</TableCell>                
                <TableCell>Opciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                codificaciones.map((i, idx) => <TableRow key={idx}>
                  <TableCell>{i.DivisionDocumento}-{i.TipoDocumento}-{i.Macroproceso}</TableCell>
                  <TableCell>de {i.CorrelativoInicio} a {i.CorrelativoFin}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => selectEdit(i.Id)}>
                      <Edit />
                    </IconButton>
                    <IconButton onClick={() => borrarCodificacion(i.Id)}>
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>)
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
    {/* ------------------------------ Action button ----------------------------- */}
    <Fab onClick={() => setDialogAdd(true)} className={classes.fab} color="primary">
      <Add />
    </Fab>
    {/* --------------------------------- Guardar -------------------------------- */}
    <Dialog fullWidth maxWidth="sm" open={dialogAdd} onClose={() => setDialogAdd(false)} >
      <DialogTitle>Agregar nueva codificación de documento</DialogTitle>
      <DialogContent>
        <FormControl fullWidth style={{marginTop: '1em', marginBottom: '1em'}}>
          <InputLabel>División del documento</InputLabel>
          <Select fullWidth id="divisionDocumento" value={formAdd.divisionDocumento} onChange={ ev => changeAdd('divisionDocumento', ev.target.value) } >
            {divisionDocumento.map(i => <MenuItem key={i.Id} value={i.Id}> {i.AbreviaturaProceso} - {i.NombreMacroproceso} </MenuItem>)}
          </Select>
        </FormControl>
        <FormControl fullWidth style={{marginTop: '1em', marginBottom: '1em'}}>
          <InputLabel>Tipo de documento</InputLabel>
          <Select fullWidth id="tipoDocumento" value={formAdd.tipoDocumento} onChange={ ev => changeAdd('tipoDocumento', ev.target.value) } >
            {tipoDocumento.map(i => <MenuItem key={i.Id} value={i.Id}> {i.CodigoTipoDocumento} - {i.NombreTipoDocumento} </MenuItem>)}
          </Select>
        </FormControl>
        <FormControl fullWidth style={{marginTop: '1em', marginBottom: '1em'}}>
          <InputLabel>Proceso al que pertenece</InputLabel>
          <Select fullWidth id="macroproceso" value={formAdd.macroProceso} onChange={ ev => changeAdd('macroProceso', ev.target.value) } >
            {macroproceso.map(i => <MenuItem key={i.Id} value={i.Id}> {i.AbreviaturaProceso} - {i.NombreMacroproceso} </MenuItem>)}
          </Select>
        </FormControl>        
        <TextField
          fullWidth
          label="Correlativo inicio"
          value={formAdd.correlativoInicio}
          onChange={ev => changeAdd('correlativoInicio', ev.target.value)}
        />
        <TextField
          fullWidth
          label="Correlativo fin"
          value={formAdd.correlativoFin}
          onChange={ev => changeAdd('correlativoFin', ev.target.value)}
        />        
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDialogAdd(false)}>Cancelar</Button>
        <LoadingButton loading={loading} onClick={insertCodificacion} color="primary">Guardar</LoadingButton>
      </DialogActions>
    </Dialog>
    {/* --------------------------------- Editar --------------------------------- */}
    <Dialog fullWidth maxWidth="sm" open={dialogEdit} onClose={() => setDialogEdit(false)} >
      <DialogTitle>Editar codificación documento</DialogTitle>
      <DialogContent>
        <FormControl fullWidth style={{marginTop: '1em', marginBottom: '1em'}}>
          <InputLabel>División del documento</InputLabel>
          <Select fullWidth id="divisionDocumento" value={formEdit.divisionDocumento} onChange={ ev => changeEdit('divisionDocumento', ev.target.value) } >
            {divisionDocumento.map(i => <MenuItem key={i.Id} value={i.Id}> {i.AbreviaturaProceso} - {i.NombreMacroproceso} </MenuItem>)}
          </Select>
        </FormControl>
        <FormControl fullWidth style={{marginTop: '1em', marginBottom: '1em'}}>
          <InputLabel>Tipo de documento</InputLabel>
          <Select fullWidth id="tipoDocumento" value={formEdit.tipoDocumento} onChange={ ev => changeEdit('tipoDocumento', ev.target.value) } >
            {tipoDocumento.map(i => <MenuItem key={i.Id} value={i.Id}> {i.CodigoTipoDocumento} - {i.NombreTipoDocumento} </MenuItem>)}
          </Select>
        </FormControl>
        <FormControl fullWidth style={{marginTop: '1em', marginBottom: '1em'}}>
          <InputLabel>Proceso al que pertenece</InputLabel>
          <Select fullWidth id="macroproceso" value={formEdit.macroProceso} onChange={ ev => changeEdit('macroProceso', ev.target.value) } >
            {macroproceso.map(i => <MenuItem key={i.Id} value={i.Id}> {i.AbreviaturaProceso} - {i.NombreMacroproceso} </MenuItem>)}
          </Select>
        </FormControl>        
        <TextField
          fullWidth
          label="Correlativo inicio"
          value={formEdit.correlativoInicio}
          onChange={ev => changeEdit('correlativoInicio', ev.target.value)}
        />
        <TextField
          fullWidth
          label="Correlativo fin"
          value={formEdit.correlativoFin}
          onChange={ev => changeEdit('correlativoFin', ev.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDialogEdit(false)}>Cancelar</Button>
        <LoadingButton loading={loading} onClick={updateCodificacion} color="primary">Guardar</LoadingButton>
      </DialogActions>
    </Dialog>
    <ToastContainer />
  </Layout>
  )
}

export default CodificacionDocumentos
