import React from 'react'
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableRow,
  TableCell,
  makeStyles
} from '@material-ui/core'
import { TableBody } from 'material-ui'

const useStyles = makeStyles(theme => ({
  subtitle: {
    fontWeight: 'bold'
  },
  dato: {
    textTransform: 'uppercase'
  },
  detalle: {
    display: 'flex',
    borderBottom: '1px solid grey',
    '& p': {
      width: '50%',
      padding: '5px',
      paddingLeft: '10px'
    },
    '& .titulo': {
      fontWeight: 'bold'
    }
  }
}))

const datosRegistro = {
  'ResponsableRegistro': 'Responsable del registro:',
  'TiempoRetencionRegistro': 'Tiempo de retención:',
  'Proteccion': 'Protección:',
  'MedioAlmacenamiento': 'Medio de almacenamiento:',
  'DisposicionRegistrosViejos': 'Disposición de registros viejos:'
}
const datosExterno = {
  'Responsable': 'Responsable:',
  'TipoExterno': 'Tipo:',
  'NumeroUnidades': 'Numero de unidaes:',
  'AnioLanzamiento': 'Año de lanzamiento:',
  'DuenioOriginal': 'Dueño original:',
  'AutorOriginal': 'Autor original:',
  'FechaIngreso': 'Fecha ingreso:',
  'ISBN': 'ISBN:'
}

const SolicitudVer = ({ datos, onClose }) => {
  const classes = useStyles()
  return <>
    <DialogTitle>Detalle de documento</DialogTitle>
    <DialogContent>
      <div className={classes.detalle}>
        <p className='titulo'>Estado:</p>
        <p className={classes.dato}>{datos.Estado}</p>
      </div>
      <div className={classes.detalle}>
        <p className='titulo'>Título:</p>
        <p className={classes.dato}>{datos.NombreDocumento}</p>
      </div>
      <div className={classes.detalle}>
        <p className='titulo'>Código:</p>
        <p className={classes.dato}>{datos.Correlativo || datos.codigoDoc}</p>
      </div>
      <div className={classes.detalle}>
        <p className='titulo'>Versión:</p>
        <p className={classes.dato}>{datos.version || datos.Version}</p>
      </div>
      <div className={classes.detalle}>
        <p className='titulo'>Descripción:</p>
        <p className={classes.dato}>{datos.DescripcionDocumento}</p>
      </div>
      <div className={classes.detalle}>
        <p className='titulo'>Fecha de creación:</p>
        <p className={classes.dato}>{datos.fecha}</p>
      </div>
      <div className={classes.detalle}>
        <p className='titulo'>Autor:</p>
        <p className={classes.dato}>{datos.Autor || datos.Solicitante}</p>
      </div>
      <div className={classes.detalle}>
        <p className='titulo'>Tipo de documento:</p>
        <p className={classes.dato}>{datos.tipo || datos.NombreTipoDocumento}</p>
      </div>
      {
        Boolean(datos.entorno) && <div className={classes.detalle}>
          <p className='titulo'>Sistemas de gestión:</p>
          <p className={classes.dato}>
            {datos.entorno.map(i => i.SistemaGestion).join(', ')}
          </p>
        </div>
      }
      {
        Object.entries(datosRegistro)
          .filter(i => datos.hasOwnProperty(i[0])).map(([k, v], idx) => <div key={k} className={classes.detalle}>
            <p className='titulo'>{v}</p>
            <p className={classes.dato}>{datos[k]}</p>
          </div>)
      }
      {
        Object.entries(datosExterno)
          .filter(i => datos.hasOwnProperty(i[0])).map(([k, v], idx) => <div key={k} className={classes.detalle}>
          <p className='titulo'>{v}</p>
          <p className={classes.dato}>{datos[k]}</p>
        </div>)
      }
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cerrar</Button>
    </DialogActions>
  </>
}

export default SolicitudVer
