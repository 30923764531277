import { action, thunk } from 'easy-peasy'
import api from '../utils/api'
import dayjs from 'dayjs'

export default {
  obsoletos: [],
  setObsoletos: action((state, payload) => {
    state.obsoletos = payload
  }),
  getObsoletos: thunk(async (actions, query) => {
    let url = 'api/documentos/obtener-documentos-obsoletos?'
    if (query.gerencia) {
      url += `gerenciaId=${query.gerencia}`
    }
    if (query.nombre && !query.gerencia) {
      url += `nombre=${query.nombre}`
    }
    const { data } = await api.fetchGetRequest(url)
    actions.setFiltros({
      tipo: null,
      sistema: null,
      anho: null
    })
    actions.setObsoletos(data.map(i => {
      const fecha = dayjs(i.actualizacion)
      return {
        ...i,
        tipo: `${i.codigoTipo} - ${i.tipo}`,
        fecha: fecha.format('DD/MM/YYYY'),
        anho: fecha.year() + ''
      }
    }))
  })
}
