import React, { useEffect, useState } from "react";

import { Fab, makeStyles, Paper, Typography } from "@material-ui/core";
import { Edit } from "@material-ui/icons";

const cardStyles = makeStyles((theme) => ({
  headerSection: {
    marginTop: "0.5em",
    display: "flex",
    justifyContent: "space-between",
  },
  campo: {
    margin: 2,
  },
}));

function CardPersonales({ postulante, showUpdate }) {
  const classes = cardStyles();
  return (
    <Paper style={{ padding: 10, marginTop: 10 }}>
      <div className={classes.headerSection}>
        <Typography variant="h5" component="h1">
          Datos personales
        </Typography>
        <Fab size="small" color="primary" onClick={showUpdate}>
          <Edit />
        </Fab>
      </div>
      <p className={classes.campo}>
        <strong>DPI: </strong>
        {postulante.DPI}
      </p>
      <p className={classes.campo}>
        <strong>NIT: </strong>
        {postulante.NIT}
      </p>
      <p className={classes.campo}>
        <strong>No. Afiliación IGSS: </strong>
        {postulante.IGSS}
      </p>
      <p className={classes.campo}>
        <strong>Primer Nombre: </strong>
        {postulante.PrimerNombre}
      </p>
      <p className={classes.campo}>
        <strong>Segundo Nombre: </strong>
        {postulante.SegundoNombre}
      </p>
      <p className={classes.campo}>
        <strong>Tercer Nombre: </strong>
        {postulante.TercerNombre}
      </p>
      <p className={classes.campo}>
        <strong>Primer Apellido: </strong>
        {postulante.PrimerApellido}
      </p>
      <p className={classes.campo}>
        <strong>Segundo Apellido: </strong>
        {postulante.SegundoApellido}
      </p>
      <p className={classes.campo}>
        <strong>Apellido de Casada: </strong>
        {postulante.ApellidoCasada}
      </p>

      <p className={classes.campo}>
        <strong>Género: </strong>
        {postulante.Genero}
      </p>
      <p className={classes.campo}>
        <strong>Correo electrónico: </strong>
        {postulante.Email}
      </p>
      <p className={classes.campo}>
        <strong>Fecha de nacimiento: </strong>
        {postulante.FechaDisplay}
      </p>
      <p className={classes.campo}>
        <strong>Teléfono celular: </strong>
        {postulante.Telefono1}
      </p>
      <p className={classes.campo}>
        <strong>Teléfono residencia: </strong>
        {postulante.Telefono2}
      </p>
      <p className={classes.campo}>
        <strong>Dirección: </strong>
        {postulante.Direccion}
      </p>
      <p className={classes.campo}>
        <strong>Zona: </strong>
        {postulante.Zona}
      </p>
      <p className={classes.campo}>
        <strong>Colonia: </strong>
        {postulante.Colonia}
      </p>
      <p className={classes.campo}>
        <strong>Casa, Lote o Apto: </strong>
        {postulante.CasaLoteApto}
      </p>
      <p className={classes.campo}>
        <strong>Barrio,Aldea,Caserio o Finca: </strong>
        {postulante.BarrioAldeaCaserioFinca}
      </p>
      <p className={classes.campo}>
        <strong>Cantón o Callejón: </strong>
        {postulante.CantonCallejon}
      </p>

      <p className={classes.campo}>
        <strong>Departamento: </strong>
        {postulante.Departamento}
      </p>
      <p className={classes.campo}>
        <strong>Municipio: </strong>
        {postulante.Municipio}
      </p>
      <p className={classes.campo}>
        <strong>Estado civil: </strong>
        {postulante}
      </p>
      {/* <p className={classes.campo}>
            <strong>Licencia: </strong>
            {postulante.Licencia}
        </p> */}
      <p className={classes.campo}>
        <strong>Etnia: </strong>
        {postulante.Etnia}
      </p>
      <p className={classes.campo}>
        <strong>Número de cuenta monetaria BI: </strong>
        {postulante.NumeroCuenta}
      </p>

      {/* <p className={classes.campo}>
            <strong>Departamento de Nacimiento: </strong>
            {postulante.CodigoDepartamentoNacimiento > 0 && maestroDepartamento
                ? maestroDepartamento.find(
                        (item) =>
                            item.CodigoDepartamento === postulante.CodigoDepartamentoNacimiento
                    )
                    ? maestroDepartamento.find(
                            (item) =>
                                item.CodigoDepartamento === postulante.CodigoDepartamentoNacimiento
                        ).Nombre
                    : ''
                : ''}
        </p> */}
      <p className={classes.campo}>
        <strong>Municipio de nacimiento: </strong>
        {postulante.MunicipioNacimiento}
      </p>
    </Paper>
  );
}

export default CardPersonales;
