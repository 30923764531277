import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Grid, Chip, Container } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { IOSSwitch } from "../../helpers/components/ios-switch";
import {
  FloatingMenu,
  MainButton,
  ChildButton,
} from "react-floating-button-menu";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";
import AddAlertIcon from "@material-ui/icons/AddAlert";
import PersonIcon from "@material-ui/icons/Person";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Loader from "react-loader-spinner";
import Popover from "@material-ui/core/Popover";
import MaskedInput from "react-text-mask";
import PropTypes from "prop-types";
import NoteAddIcon from "@material-ui/icons/Note";
import Icon from '@material-ui/core/Icon';

//Appbar
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import InputBase from "@material-ui/core/InputBase";
import { fade, makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import FileCopyIcon from '@material-ui/icons/FileCopy';

//Scroll bar
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import UsuariosList from "../../plantillaProblema/components/usuarios-list";


//Own components
import Layout from "../../layout/containers/Layout";
import ListaCarpetas from "./lista-carpetas-second-navigation";
import AddButton from "../../helpers/components/add-button-float";
import API from "../../utils/api";
import { isSignedIn } from "../../utils/auth";
import { consultarPermiso, convertYYYYMMDDToDate } from "../../utils/helpers";
import { convertirASegundos, convertToYearMonthDay } from "../../utils/helpers";
import {validaPermisoPaginaActual} from "../../utils/helpers";
//CSS
import "../components/gestion-style.css";
import { DataGrid } from "devextreme-react";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

function getBigModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    maxHeight: 600,
  };
}

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0-9]/, /[0-9]/, ":", /[0-5]/, /[0-9]/]}
      placeholderChar={"\u2000"}
      showMask
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },

  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  title: {
    flexGrow: 1,
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  paperUserModal: {
    position: "absolute",
    width: "75%",
    height: "600px",
    maxHeight: "600px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  popover: {
    pointerEvents: "none",
  },
  paperPopover: {
    padding: theme.spacing(1),
    background: "#000",
    color: "#fff",
  },
});

class  SecondNavigation extends Component {
  state = {
    idObjetoPadre: null,
    nombreObjetoPadre: "Gestiones",
    llaveObjetoPadre: "AITModulo",
    idObjetoEditar: "",
    llaveObjetoEditar: "",
    botonBackDisplay: "none",
    listaObjetos: [],
    listaUsuarios: [],
    history: [],
    anchorEl: null,
    popOverOpen: false,
    llaveTablaCarpeta: "AITCarpeta",
    listaRoles: [],
    listaRolesObjeto: [],
    openPermisosModal: false,
    rolSeleccionado: [],
    C: false,
    R: false,
    U: false,
    D: false,
    E: false,
    permisoCarpetaActual: { C: false, R: false, U: false, D: false, E: false },
  };

  async componentDidMount() {
     //CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
   
     const llaveAgrupacion = "Menu.Principal.Web";
     const llavePagina = "Menu.Principal.Web.Gestiones";
     await validaPermisoPaginaActual(llaveAgrupacion, llavePagina);
     //FIN DE CONSULTA DE PERMISOS
    
    const verify = await isSignedIn();
    console.log(verify.response);
    if (!verify.response) {
      window.location.href = "/login";
    }
    await this.obtenerIdModulo();
    let history = this.state.history;
    history.push({
      idObjeto: this.state.idObjetoPadre,
      nombre: this.state.nombreObjetoPadre,
      llave: "AITModulo",
    }); //inserta la primera posición del array para navegacion
   
    this.setState({ history: history }, async () => {
      let permisos = await consultarPermiso(
        this.state.idObjetoPadre,
        "AITModulo"
      );
      this.setState({ permisoCarpetaActual: permisos });
      console.log("permisos");
      console.log(permisos);
      this.cargarLista();
    });
  }

  async obtenerIdModulo() {
    try {
      //Loader
      this.setState({ cargando: true });

      let request = await API.fetchGetRequest(
        `api/carpetaController/obtenerModulo/1`
      );
      if (request.statusCode !== 200) {
        alert(
          "Hubo un error al obtener el ID del módulo, consulta con un administrador"
        );
      }

      console.log(request.statusCode);
      if (request.statusCode === 401) {
        //alert("cerrar sesion");
        window.location.href = "/login";
      } else {
        this.setState({
          idObjetoPadre: request.data.data.Codigo,
          nombreObjetoPadre: request.data.data.Nombre,
        });
      }
    } catch (error) {
      console.log(error);
      if (error.toString().includes("401")) {
        window.location.href = "/login";
      }
    } finally {
      this.setState({ cargando: false });
    }
  }

  async cargarLista() {
    try {
      //Loader
      this.setState({ cargando: true });

      //Muestra/oculta botón para regresar en la navegación
      this.botonBack();
      
      const verify = await isSignedIn();
            if (!verify.response) {
        window.location.href = "/login";
      }
      const { idObjetoPadre, nombreObjetoPadre, llaveObjetoPadre } = this.state;
const params = {
  codigoUsuario : verify.codigoUsuario,
  soloCarpetas:false,
}
      const request = await API.fetchGetRequestWithParams(
        `api/carpetaController/listar/${idObjetoPadre}/${llaveObjetoPadre}`,params
      );
      
      if (request.statusCode != 200) {
        alert(
          "Hubo un error al realizar la operación, consulta con un administrador"
        );
      }

      console.log(request.statusCode);
      if (request.statusCode === 401) {
        //alert("cerrar sesion");
        window.location.href = "/login";
      } else {
        if (Array.isArray(request.data.data)) {
          this.setState({
            listaObjetos: request.data.data,
          });
        }
      }
    } catch (error) {
      console.log(error);
      if (error.toString().includes("401")) {
        window.location.href = "/login";
      }
    } finally {
      this.setState({ cargando: false });
    }
  }

  async cargarDatosEdicionCarpeta() {
    const request = await API.fetchGetRequest(
      "api/carpetaController/cargarDatosEdicion/" + this.state.idObjetoEditar
    );

    this.setState({
      nombre: request.data.data[0].Nombre,
      activo: request.data.data[0].Activo,
    });
  }

  async cargarListaRolesPorObjeto(idObjeto, llave) {
    const request = await API.fetchGetRequest(
      `api/permiso/listaRolesPorObjeto/${idObjeto}/${llave}`
    );
    if (Array.isArray(request.data.data)) {
      this.setState({
        listaRolesObjeto: request.data.data,
      });
    }
  }

  async cargarListaRoles() {
    const request = await API.fetchGetRequest(`api/permiso/listarRoles/`);
    if (Array.isArray(request.data.data)) {
      this.setState({
        listaRoles: request.data.data,
      });
    }
  }
  
  listarProblemasCarpeta = async (carpeta) =>{
    const request = await API.fetchGetRequest("api/carpetaController/listarProblemasCarpeta/" + carpeta.Codigo+"/"+carpeta.Llave);
    console.log("Datos carpet",request)
  }

  navegar = (idObjeto, nombre, llave) => {
   if (llave === this.state.llaveTablaCarpeta) {
      let history = this.state.history;
      console.log("Llave", llave);
      if(idObjeto===null || idObjeto===undefined){
        console.log("idObjeto", idObjeto);
        toast.warn("No pudimos cargar la información, por favor inténtalo nuevamente.")
        return;
      }
      history.push({ idObjeto: idObjeto, nombre: nombre, llave: llave });
      this.setState(
        {
          idObjetoPadre: idObjeto,
          nombreObjetoPadre: nombre,
          llaveObjetoPadre: llave,
          history: history,
        },
        async () => {
          let permisos = await consultarPermiso(idObjeto, llave);
          this.setState({ permisoCarpetaActual: permisos });
          console.log("permisos");
          console.log(permisos);
          this.cargarLista();
        }
      );
    }
  };

  regresar = () => {
    let history = this.state.history;

    let cantidadPosiciones = history.length;
    let nombreObjetoPadre;
    let idObjetoPadre;
    let llaveObjetoPadre;
    if (cantidadPosiciones > 1) {
      //Elimina el último elemento del array que corresponde al idObjetoPadre actual
      history.pop();
      //Actualiza cantidad de elementos del array
      cantidadPosiciones = history.length;
      //Encuentra el valor de la nueva última posición del array y la asigna al idObjetoPadre
      idObjetoPadre = history[cantidadPosiciones - 1].idObjeto;
      nombreObjetoPadre = history[cantidadPosiciones - 1].nombre;
      llaveObjetoPadre = history[cantidadPosiciones - 1].llave;

      //Actualiza valores del state
      this.setState(
        {
          idObjetoPadre: idObjetoPadre,
          nombreObjetoPadre: nombreObjetoPadre,
          llaveObjetoPadre: llaveObjetoPadre,
          history: history,
        },
        async () => {
          let permisos = await consultarPermiso(
            idObjetoPadre,
            llaveObjetoPadre
          );
          this.setState({ permisoCarpetaActual: permisos });
          console.log("permisos");
          console.log(permisos);
          this.cargarLista();
        }
      );
    }
  };

  botonBack = () => {
    let cantidadPosiciones = this.state.history.length;
    let display;
    if (cantidadPosiciones > 1) {
      display = "inline-block";
    } else {
      display = "none";
    }

    this.setState({ botonBackDisplay: display });
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <div className={classes.root}>
          <Container maxWidth="md">
            <AppBar position="fixed">
              <Toolbar>
                <IconButton
                  style={{
                    display: this.state.botonBackDisplay,
                    color: "#FFF",
                  }}
                  onClick={() => this.regresar()}
                >
                  <ArrowBackIosIcon />
                </IconButton>
                <Typography className={classes.title} variant="h6" noWrap>
                  {this.state.nombreObjetoPadre}
                </Typography>
                {/* {this.state.llaveObjetoPadre ===
                  this.state.llaveTablaCarpeta && (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    endIcon={<FileCopyIcon />}
                    onClick={()=>{this.props.elegirTipoGestion(this.state.idObjetoPadre)}}
                    style={{ backgroundColor: "#2ecc71" }}
                  >
                    Mover
                  </Button>
                )} */}

                {/* <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Buscar..."
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ "aria-label": "Buscar" }}
                  onChange={this.handleChange("criterioBusqueda")}
                  onKeyPress={(ev) => {
                    console.log("keyP: " + ev.key);
                    if (ev.key === "Enter") {
                      this.buscar();
                      ev.preventDefault();
                    }
                  }}
                />
              </div> */}
              </Toolbar>
            </AppBar>

            {this.state.cargando ? (
              <div
                style={{
                  width: "100%",
                  marginTop: 10,
                  textAlign: "center",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Loader
                  type="Oval"
                  color="#3498db"
                  height="50"
                  width="50"
                  style={{ textAlign: "center" }}
                />
              </div>
            ) : (
              <></>
            )}
            <PerfectScrollbar style={{maxHeight:300, height:300, overflowX:"hidden"}}>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={12} sm={12}>
                  <ListaCarpetas
                    data={this.state.listaObjetos}
                    navegar={this.navegar}
                    listarProblemasCarpeta = {this.props.listarProblemasCarpeta}
                    elegirTipoGestion = {this.props.elegirTipoGestion}
                  />
                </Grid>
              </Grid>
            </PerfectScrollbar>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
const SecondNavigationContainer = withStyles(styles)( SecondNavigation);
export default withRouter(SecondNavigationContainer);
