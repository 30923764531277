import React, { useState, useEffect } from "react";
import { Backdrop, CircularProgress, Paper, Tooltip } from "@material-ui/core";

import { Link } from "react-router-dom";

import { green } from "@material-ui/core/colors";

import DataGrid, {
  Column,
  Pager,
  Paging,
  Sorting,
  ColumnFixing,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Grouping,
  GroupPanel,
  Export,
  Selection,
  Editing,
  Scrolling,
  RowDragging,
  LoadPanel,
} from "devextreme-react/data-grid";

import { Traffic } from "@material-ui/icons";
import API from "../../utils/api";
import { isSignedIn, signOut } from "../../utils/auth";
import { validaPermisoPaginaActual } from "../../utils/helpers";

import { makeStyles } from "@material-ui/core/styles";
import "devextreme/dist/css/dx.material.teal.light.compact.css";

const width_proportion = "100%";

const useStyles = makeStyles((theme) => ({
  table: {
    width: "100%",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 210,
  },
  textFieldDireccion: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 230,
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  greenAvatar: {
    fontSize: "2.5em",
    color: "#fff",
    // borderStyle:'solid',
    // borderWidth:'1 !important',
    // borderColor:'#3f51b5 !important',
    backgroundColor: green[500],
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  paperCard: {
    padding: 20,
    width: "100%",
  },
  formControlSelects: {
    // margin: theme.spacing(1),
    minWidth: 480,

    marginBottom: 10,
    marginTop: 10,
    width: "100%",
    marginLeft: 30,
  },
  formControlSelect: {
    // margin: theme.spacing(1),
    width: width_proportion,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  rootTabla: {
    width: "100%",
    marginTop: theme.spacing.unit * 1,
    overflowX: "auto",
  },
  // modal: {
  //   display: "flex",
  //   alignItems: "center",
  //   justifyContent: "center",
  // },
  // paperModal: {
  //   position: "absolute",
  //   width: "70%",
  //   backgroundColor: theme.palette.background.paper,
  //   boxShadow: theme.shadows[5],
  //   padding: theme.spacing(2, 4, 3),
  // },
  modal: {
    // display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: "10%",
    left: "20%",
    overflow: "scroll",
    height: "90%",
    display: "block",
    padding: theme.spacing(8, 4, 8, 8),
  },
  paperModal: {
    position: "absolute",
    width: "90%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1, 1, 1, 1),
  },
  selectedData: {
    marginTop: "20px",
    padding: "20px",
    backgroundColor: "rgba(191, 191, 191, 0.15)",
  },
  selectedDataCaption: {
    fontWeight: "bold",
    fontSize: "115%",
    marginRight: "4px",
  },
}));

const EmpleadoPlazaLaboral = (props) => {
  const classes = useStyles();

  let applyFilterTypes = [
    {
      key: "auto",
      name: "Immediately",
    },
    {
      key: "onClick",
      name: "On Button Click",
    },
  ];

  const monedaFormat = {
    style: "currency",
    currency: "GTQ",
    // useGrouping: true,
    minimumSignificantDigits: 3,
  };

  const porcentajeFormat = {
    type: "fixedPoint",
    format: "#0.##'%'",
    // useGrouping: true,
    // minimumSignificantDigits: 3,
  };

  const [
    listadoEmpleadoAsignadoPorUnidad,
    setListadoEmpleadoAsignadoPorUnidad,
  ] = useState([]);

  const [showFilterRow, setShowFilterRow] = useState(true);
  const [showHeaderFilter, setShowHeaderFilter] = useState(true);
  const [currentFilter, setCurrentFilter] = useState(applyFilterTypes[0].key);
  const [autoExpandAll, setAutoExpandAll] = useState(true);

  const [buscando, setBackDrop] = useState(false);

  const handleCerrarBackDrop = () => {
    setBackDrop(false);
  };
  const handleAbrirBackDrop = () => {
    setBackDrop(true);
  };

  useEffect(() => {
    const cargar = async () => {
      // CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
      const llaveAgrupacion = "Menu.Principal.Web";
      const llavePagina = "Menu.Principal.Web.PuestoLaboral";
      await validaPermisoPaginaActual(llaveAgrupacion, llavePagina);
      // FIN DE CONSULTA DE PERMISOS

      handleAbrirBackDrop();

      await cargarEmpleadoAsignadoPorUnidadOrganizacion();

      handleCerrarBackDrop();
    };

    cargar();
  }, [props.codigoUnidadOrganizacion]);

  const cargarEmpleadoAsignadoPorUnidadOrganizacion = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      //   setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri =
        "api/AnalisisSalarial/SolicitadoPorUnidadOrganizacionHistorico/" +
        props.codigoUnidadOrganizacion;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setListadoEmpleadoAsignadoPorUnidad(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const onExporting = async (e) => {};

  const cellSemaforo = (rowInfo) => {
    let color = "#8B0000";

    if (rowInfo.data.Semaforo >= 3) {
      color = "#8B0000";
    } else if (rowInfo.data.Semaforo == 2) {
      color = "#FF8C00";
    } else {
      color = "#228B22";
    }
    return (
      <>
        <Traffic style={{ color: color }} />
      </>
    );
  };

  const cellTemplateUnidadOrganizacion = (rowInfo) => {
    return (
      <>
        <Tooltip
          title={`Ir a gestión:`}
          style={{ marginRight: 10, cursor: "pointer" }}
        >
          <Link to={`/gestion/${rowInfo.data.Referencia}`}>
            {rowInfo.data.Referencia}
          </Link>
        </Tooltip>
      </>
    );
  };

  return (
    <React.Fragment>
      <Backdrop className={classes.backdrop} open={buscando}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Paper className={classes.rootTabla}>
        <DataGrid
          dataSource={listadoEmpleadoAsignadoPorUnidad}
          //   defaultColumns={this.props.columns}
          showBorders={true}
          rowAlternationEnabled={true}
          //ref={(ref) => (dataGrid = ref)}
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
          onExporting={onExporting}
          className={classes.table}
          remoteOperations={false}
          height={440}
        >
          <FilterRow visible={showFilterRow} applyFilter={currentFilter} />
          <HeaderFilter visible={showHeaderFilter} />
          <GroupPanel visible={false} />
          <Grouping autoExpandAll={autoExpandAll} />
          <SearchPanel visible={false} width={240} placeholder="Search..." />
          {/* <Selection mode="multiple" /> */}
          {/* <Selection
            mode="multiple"
            selectAllMode="allPages"
            showCheckBoxesMode="onClick"
          /> */}
          <LoadPanel
            enabled={true}
            shadingColor="rgba(0,0,0,0.4)"
            showIndicator={true}
            shading={true}
            showPane={true}
            closeOnOutsideClick={false}
          />
          <Scrolling mode="virtual" />
          <Editing
            mode="row"
            useIcons={true}
            allowUpdating={false}
            allowDeleting={false}
          />

          <Column
            dataField="Referencia"
            caption="# Gestion"
            cellRender={cellTemplateUnidadOrganizacion}
            fixed={true}
          />
          <Column
            dataField="CodigoAnterior"
            caption="Código"
            fixed={true}
            fixedPosition="left"
          />
          <Column
            dataField="NombreCompleto"
            caption="NombreCompleto"
            fixed={true}
            fixedPosition="left"
          />
          <Column dataField="DPI" caption="DPI" />
          <Column dataField="AgrupacionDePago" caption="Agrupación de Pago" />
          <Column dataField="PuestoLaboral" caption="Puesto Laboral" />
          <Column
            dataField="CumpleEducacionTexto"
            caption="Cumple Educación?"
          />
          <Column
            dataField="CumpleExperienciaTexto"
            caption="Cumple Experiencia?"
          />
          <Column cellRender={cellSemaforo} caption="Semaforo" />
          <Column
            dataField="Salario"
            caption="SalarioActual"
            format={monedaFormat}
          />
          <Column
            dataField="NuevoSalario"
            caption="Nuevo Salario"
            format={monedaFormat}
          />
          {/*  */}
          <Column
            dataField="PorcentajeAumentoSalarial"
            caption="Porcentaje de Aumento"
            format={porcentajeFormat}
          />
          <Column dataField="ExpertisActual" caption="Expertis Actual" />
          <Column dataField="ExpertisNueva" caption="Expertis Nueva" />
          <Column
            dataField="ExperienciaRequerida"
            caption="Experiencia Requerida"
          />
          <Column
            dataField="ExperienciaEmpleado"
            caption="Experiencia Empleado"
          />
          <Column dataField="Comentario" caption="Comentario" />
          <ColumnFixing enabled={true} />
          <Sorting mode="multiple" />
          {/* <Paging defaultPageSize={20} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[10, 20, 50]}
            showInfo={true}
          /> */}
          <Export enabled={true} allowExportSelectedData={true} />
          {/* <Scrolling columnRenderingMode="virtual" /> */}
        </DataGrid>
      </Paper>
    </React.Fragment>
  );
};

export default EmpleadoPlazaLaboral;
