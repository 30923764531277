import React, { useState, useEffect } from 'react'
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemAvatar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
  makeStyles
} from '@material-ui/core'
import { lightBlue, grey } from '@material-ui/core/colors'
import api from '../../../utils/api'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { getNombreUsuario } from '../../utils/utilidades'
import { toast } from 'react-toastify'

const useStyles = makeStyles(theme => ({
  iconContainer: {
    maxWidth: 30
  },
  icon: {
    height: 20,
    width: 'auto'
  },
  lista: {
    background: grey[100]
  }
}))

const Navegacion = ({
    selectArea,
    selectGerencia,
    loadFavoritos,
    isObsoletos=false,
    loadInformado
  }) => {
  const { favoritos, docsInformado } = useStoreState(state => ({
    favoritos: state.favoritos,
    docsInformado: state.docsInformado
  }))
  const { setMacroprocesos } = useStoreActions(actions => ({
    setMacroprocesos: actions.setMacroprocesos
  }))
  const [items, setItems] = useState([])
  const [expanded, setExpanded] = useState(null)
  const nombre = getNombreUsuario()
  const classes = useStyles()
  const getData = async () => {
    try {
      const reqMacroprocesos = api.fetchGetRequest('api/documentos/macroproceso')
      const reqProcesos = api.fetchGetRequest('api/documentos/proceso')
      const urlConteos = !isObsoletos
        ? 'api/documentos/obtener-conteo-oficiales-por-gerencia-proceso'
        : 'api/documentos/obtener-conteo-obsoletos-por-gerencia-proceso'
      const reqConteos = api.fetchGetRequest(urlConteos)
      const requests = [
        reqMacroprocesos,
        reqProcesos,
        reqConteos
      ]
      let res = await Promise.all(requests)
      const menuItems = []
      setMacroprocesos(res[0].data)
      for (const macro of res[0].data) {
        const conteoMacroproceso = res[2].data.filter(i => i.gerencia === macro.IdMacroproceso)
          .map(i => i.conteo).reduce((acc, i) => acc + i, 0)
        const areas = []
        for (const proc of res[1].data) {
          if (proc.IdMacroproceso === macro.IdMacroproceso) {
            const conteoProceso = res[2].data.find(i => i.gerencia === macro.IdMacroproceso && i.proceso === proc.CodigoProceso)
            areas.push({
              ...proc,
              conteo: conteoProceso ? conteoProceso.conteo : 0
            })
          }
        }
        menuItems.push({
          ...macro,
          Icono: `/iconos/${macro.Icono}`,
          conteo: conteoMacroproceso,
          areas
        })
      }
      setItems(menuItems)
    } catch (error) {
      toast.error('Ha ocurrido un error')
    }
  }
  const handleChange = (codigo) => {
    if (codigo === 1000) {
      loadFavoritos()
    } else if (codigo === 1001) {
      loadInformado()
    } else {
      selectGerencia(codigo)
    }
    if (codigo === expanded) {
      setExpanded(null)
    } else {
      setExpanded(codigo)
    }
  }
  useEffect(() => {
    getData()
  }, [])
  return <>
    {
      !isObsoletos && <>
        <Accordion
          expanded={expanded === 1000}
          onChange={() => handleChange(1000)}
        >
          <AccordionSummary style={{background: '#2B3C4D', color: '#FFFFFF'}}>
            <ListItem style={{padding: 0}}>
              <ListItemAvatar style={{minWidth: 30}}>
                <img src='/iconos/favoritos.svg' className={classes.icon} />
              </ListItemAvatar>
              <ListItemText primary="Favoritos" />
              <ListItemIcon style={{minWidth: 30}}>
                <Chip label={favoritos.length} style={{background: lightBlue[500], color: '#FFF'}} />
              </ListItemIcon>
            </ListItem>
          </AccordionSummary>
          <AccordionDetails className={classes.lista}>
            <List style={{width: '100%'}}>
              <ListItem button onClick={loadFavoritos} id='item-a'>
                <ListItemText primary={nombre} />
                <ListItemIcon>
                  <Chip label={favoritos.length} color="primary" />
                </ListItemIcon>
              </ListItem>
            </List>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 1001}
          onChange={() => handleChange(1001)}
        >
          <AccordionSummary style={{background: '#2B3C4D', color: '#FFFFFF'}}>
            <ListItem style={{padding: 0}}>
              <ListItemAvatar style={{minWidth: 30}}>
                <img src='/iconos/informado.svg' className={classes.icon} />
              </ListItemAvatar>
              <ListItemText primary="Informado" />
              <ListItemIcon style={{minWidth: 30}}>
                <Chip
                  label={docsInformado.filter(i => !i.Leido).length}
                  style={{background: lightBlue[500], color: '#FFF'}}
                />
              </ListItemIcon>
            </ListItem>
          </AccordionSummary>
          <AccordionDetails className={classes.lista}>
            <List style={{width: '100%'}}>
              <ListItem button onClick={loadInformado} id='item-b'>
                <ListItemText primary={nombre} />
                <ListItemIcon>
                  <Chip label={docsInformado.filter(i => !i.Leido).length} color="primary" />
                </ListItemIcon>
              </ListItem>
            </List>
          </AccordionDetails>
        </Accordion>
      </>
    }
    {
      items.map(i => <Accordion
        key={i.IdMacroproceso}
        expanded={expanded === i.IdMacroproceso}
        onChange={() => handleChange(i.IdMacroproceso)}
      >
        <AccordionSummary style={{background: '#2B3C4D', color: '#FFFFFF'}}>
          <ListItem style={{padding: 0}}>
            <ListItemAvatar style={{minWidth: 30}}>
              <img src={i.Icono} className={classes.icon} />
            </ListItemAvatar>
            <ListItemText primary={i.NombreMacroproceso.trim()} />
            <ListItemIcon style={{minWidth: 30}}>
              <Chip label={i.conteo} style={{background: lightBlue[500], color: '#FFF'}} />
            </ListItemIcon>
          </ListItem>
        </AccordionSummary>
        <AccordionDetails className={classes.lista}>
          <List style={{width: '100%'}}>
            {
              i.areas.map(j => <ListItem
                button
                key={j.CodigoProceso}
                onClick={() => selectArea(j.CodigoProceso)}
                id={`item-${j.CodigoProceso}`}
              >
                <ListItemText primary={j.NombreProceso.trim()} />
                <ListItemIcon>
                  <Chip label={j.conteo} color="primary" />
                </ListItemIcon>
              </ListItem>)
            }
          </List>
        </AccordionDetails>
      </Accordion>)
    }
  </>
}

export default Navegacion
