import React from "react";
import TextField from "@material-ui/core/TextField";
import "../css/notificaciones-crear.css";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import Link from "../../helpers/components/link";
import Mensaje from "../../helpers/components/message";

const styles = theme => ({
  root: {
    display: "flex"
  },
  formControl: {
    margin: theme.spacing.unit * 3
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200
  }
});

function PasoDosFormulario(props) {
  const { classes } = props;
  return (
    <div className="FormularioContainer">
      <FormControl className={classes.formControl}>
        <FormLabel>Grupo(s) objetivo:</FormLabel>
        <FormGroup>
          {props.gruposDisponibles.map((grupo, index) => (
            <FormControlLabel
              control={
                <Checkbox
                  value={grupo.CodigoGrupo}
                  onChange={props.seleccionarGrupo(grupo.CodigoGrupo)}
                  checked={props.gruposSeleccionados[grupo.CodigoGrupo]}
                />
              }
              label={grupo.Nombre}
            />
          ))}
        </FormGroup>
        <br />
        <FormLabel>Fecha de envío:</FormLabel>
        <FormGroup>
          {props.seleccionoAhora ? (
            <>
              <Mensaje>Esta notificación se enviará cuando guarde.</Mensaje>
              <Link
                title="Otra Fecha"
                onClick={() => props.seleccionarOtraFecha()}
              />
            </>
          ) : (
            <>
              <TextField
                id="datetime-local"
                type="datetime-local"
                className={classes.textField}
                InputLabelProps={{
                  shrink: true
                }}
                defaultValue={props.fecha}
                onChange={event => {
                  props.guardarInputs("fecha", event.target.value);
                }}
              />
              <Link title="Ahora!" onClick={() => props.fechaHoy()} />
            </>
          )}
        </FormGroup>
      </FormControl>
    </div>
  );
}

export default withStyles(styles)(PasoDosFormulario);
