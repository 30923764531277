import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import PerfectScrollbar from "react-perfect-scrollbar";
import Modal from "@material-ui/core/Modal";
import API from "../../utils/api";
//Autocomplete
import Autocomplete from "@material-ui/lab/Autocomplete";

import { withRouter } from "react-router-dom";
import TagList from "../../avisos-objects/components/tag-list";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paperUserModal: {
    position: "absolute",
    width: "75%",
    height: "600px",
    maxHeight: "600px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
  container: {
    margin: 10,
  },
}));

function ModalSeleccionarEtiquetas(props) {
  const classes = useStyles();
  const [causas, setCausas] = useState([]);
  const [listaFamiliaEtiqueta, setListaFamiliaEtiqueta] = useState([]);
  const [objFamilia, setObjFamilia] = useState({
    CodigoFamiliaEtiqueta: 0,
    Nombre: "Todas",
    Descripcion: "",
    Activo: true,
  });

  const [causasDisponiblesCopia, setCausasDisponiblesCopia] = useState([]);
  const [
    codigoFamiliaEtiquetaSeleccionada,
    setCodigoFamiliaEtiquetaSeleccionada,
  ] = useState(0);
  const [textoBusquedaEtiqueta, setTextoBusquedaEtiqueta] = useState("");

  useEffect(() => {
    const makeRequest = async () => {
      const requestFamiliaEtiqueta = await API.fetchGetRequest(
        `api/familiaetiqueta/listar`
      );

      if (Array.isArray(requestFamiliaEtiqueta.data.data)) {
        let listaFamilias = requestFamiliaEtiqueta.data.data;
        listaFamilias.push({
          CodigoFamiliaEtiqueta: 0,
          Nombre: "Todas",
          Descripcion: "",
          Activo: true,
        });
        setListaFamiliaEtiqueta(listaFamilias);
      }
      let codigoGestion = null
      if (
        props.match.params.codigoGestion === null ||
        props.match.params.codigoGestion === undefined
      ){
        console.log("params",props.match.params)
        const requestarea = await API.fetchGetRequest(`api/aviso/tarea/${props.match.params.codigoTarea}`);
        codigoGestion = requestarea.data.data.AvisoTarea.CodigoAviso
      }
      else{ codigoGestion =props.match.params.codigoGestion}

      const requestCausas = await API.fetchGetRequest(
        `api/aviso/${codigoGestion}/causas`
      );
      setCausas(requestCausas.data.causasDisponibles[0].children);
      filtro(requestCausas.data.causasDisponibles[0].children);
    };

    if (props.open) {
      makeRequest();
    }
  }, [props.open]);

  const filtro = (causasParam) => {
    let filtro = causasParam.filter((fila) => {
      return (
        fila.name.toLowerCase().includes(textoBusquedaEtiqueta.toLowerCase()) &&
        (codigoFamiliaEtiquetaSeleccionada === 0
          ? true
          : fila.familycode === codigoFamiliaEtiquetaSeleccionada)
      );
    });
    setCausasDisponiblesCopia(filtro);
  };

  useEffect(() => {
    filtro(causas);
  }, [textoBusquedaEtiqueta, codigoFamiliaEtiquetaSeleccionada]);

  return (
    <Modal
      // style={{ zIndex: 2990 }}
      aria-labelledby="Asignar etiquetas"
      aria-describedby="Asignar etiquetas"
      open={props.open}
      onClose={() => {
        props.setOpen(false);
      }}
    >
      <div style={getModalStyle()} className={classes.paperUserModal}>
        <PerfectScrollbar>
          <form className={classes.container} noValidate autoComplete="off">
            <Typography variant="h6" gutterBottom>
              Agregar Etiquetas
            </Typography>

            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Autocomplete
                  className={classes.textField}
                  style={{
                    width: "100%",
                    marginLeft: 10,
                    marginTop: 25,
                  }}
                  options={listaFamiliaEtiqueta}
                  autoHighlight
                  getOptionLabel={(option) => option.Nombre}
                  disabled={false}
                  renderOption={(option) => (
                    <React.Fragment>
                      <span></span>
                      {option.Nombre}
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Familia de etiquetas"
                      variant="outlined"
                    />
                  )}
                  value={objFamilia}
                  onChange={(event, newInputValue) => {
                    setObjFamilia(newInputValue ? newInputValue : -1);
                    setCodigoFamiliaEtiquetaSeleccionada(
                      parseInt(
                        newInputValue ? newInputValue.CodigoFamiliaEtiqueta : 0
                      )
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  id="txtTextoBusquedaTag"
                  label="Tag"
                  className={classes.textField}
                  value={textoBusquedaEtiqueta}
                  onChange={(event) =>
                    setTextoBusquedaEtiqueta(event.target.value)
                  }
                  margin="normal"
                  inputProps={{ maxLength: 512 }}
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} lg={12} sm={12}>
              <Grid container spacing={1}>
                <TagList
                  registros={causasDisponiblesCopia}
                  agregarCausas={props.onClickSeleccionEtiqueta}
                  closeModal={() => {}}
                />
              </Grid>
            </Grid>
          </form>
        </PerfectScrollbar>
      </div>
    </Modal>
  );
}
export default withRouter(ModalSeleccionarEtiquetas);
