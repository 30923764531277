import React, { useState, useEffect } from "react";
import {
  AppBar,
  Tabs,
  Tab,
  Typography,
  makeStyles,
  Box,
  Fab,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { ToastContainer, toast } from "react-toastify";
import { Add } from "@material-ui/icons";
import { useStoreState, useStoreActions } from "easy-peasy";
import Layout from "../layout/containers/Layout";
import SolicitudesUsuario from "./componentes/solicitudes/SolicitudesUsuario";
import DocumentosUsuario from "./componentes/solicitudes/DocumentosUsuario";
import PlantillasList from "./componentes/solicitudes/PlantillasList";
import DocumentosObsoletosUsuario from "./componentes/solicitudes/DocumentosObsoletosUsuario";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}
const a11yProps = (index) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`
  }
}
const useStyles = makeStyles((theme) => ({
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const MisDocumentos = () => {
  const { documentos, solicitudes, asignadas, docsObsoletos } = useStoreState(
    (state) => ({
      documentos: state.documentos,
      solicitudes: state.solicitudes,
      asignadas: state.asignadas,
      docsObsoletos: state.docsObsoletos,
    })
  );
  const {
    getDocumentos,
    getSolicitudes,
    getUsuarios,
    getHistorico,
    setCrearPropia,
    getDocsObsoletos,
  } = useStoreActions((actions) => ({
    getDocumentos: actions.getDocumentos,
    getSolicitudes: actions.getSolicitudes,
    getUsuarios: actions.getUsuarios,
    getHistorico: actions.getHistorico,
    setCrearPropia: actions.setCrearPropia,
    getDocsObsoletos: actions.getDocsObsoletos,
  }));
  const history = useHistory();
  const classes = useStyles();
  const [dialogCrear, setDialogCrear] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [tabs, setTabs] = useState([
    {
      id: 1,
      titulo: "Mis documentos",
      color: "#7209b7",
      num: "-",
      hasNum: true,
    },
    {
      id: 2,
      titulo: "Borradores",
      color: "#118ab2",
      num: "-",
      hasNum: true,
    },
    {
      id: 3,
      titulo: "Solicitudes asignadas",
      color: "#55a630",
      num: "-",
      hasNum: true,
    },
    {
      id: 4,
      titulo: "Plantillas",
      color: "#fb5607",
      num: "-",
      hasNum: false,
    },
    {
      id: 5,
      titulo: "Obsoletos",
      color: "#5e747f",
      num: "-",
      hasNum: true,
    },
  ]);
  const renderTab = (tab) => {
    switch (tab) {
      case 0:
        return <DocumentosUsuario loading={loading} />;
      case 1:
        return <SolicitudesUsuario tipo="usuario" loading={loading} />;
      case 2:
        return <SolicitudesUsuario tipo="asignadas" loading={loading} />;
      case 3:
        return <PlantillasList />;
      case 4:
        return (
          <DocumentosObsoletosUsuario
            loading={loading}
            refresh={() => getDocsObsoletos()}
          />
        );
    }
  };
  useEffect(() => {
    const tmp = [...tabs];
    tmp[0].num = documentos.length ? documentos.length : "-";
    tmp[1].num = solicitudes.length ? solicitudes.length : "-";
    tmp[2].num = asignadas.length ? asignadas.length : "-";
    tmp[4].num = docsObsoletos.length ? docsObsoletos.length : "-";
    setTabs(tmp);
  }, [documentos, solicitudes, asignadas, docsObsoletos]);
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const gets = [
          getDocumentos(),
          getSolicitudes('usuario'),
          getSolicitudes('asignadas'),
          getUsuarios(),
          getHistorico(),
          getDocsObsoletos(),
        ];
        await Promise.all(gets);
      } catch (error) {
        console.log(error);
        toast.error("😅 Ha ocurrido un error al obtener los datos");
      } finally {
        setLoading(false);
      }
    })();
  }, []);
  return (
    <Layout titulo="Solicitudes">
      <div className={classes.root}>
        <AppBar position="static" color="default">
          <Tabs
            value={tabValue}
            onChange={(_, val) => setTabValue(val)}
            indicatorColor="secondary"
            textColor="inherit"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            {tabs.map((i, idx) => (
              <Tab
                key={idx}
                label={`${i.titulo}${i.hasNum ? " - (" + i.num + ")" : ""}`}
                {...a11yProps(idx)}
                style={{ background: i.color, color: "#FFF" }}
              />
            ))}
          </Tabs>
        </AppBar>
        {tabs.map((i, idx) => (
          <TabPanel key={idx} value={tabValue} index={idx}>
            {renderTab(tabValue)}
          </TabPanel>
        ))}
      </div>
      <ToastContainer />
      <Fab
        onClick={() => setDialogCrear(true)}
        style={{ backgroundColor: "#69BD4B", color: "white" }}
        className={classes.fab}
        color="primary"
      >
        <Add />
      </Fab>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={dialogCrear}
        onClose={() => setDialogCrear(false)}
      >
        <DialogTitle>Crear un nuevo borrador</DialogTitle>
        <DialogContent>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            onClick={() => {
              setCrearPropia(true);
              history.push("/documentos/solicitudes/crear");
            }}
          >
            Borrador propio
          </Button>
          <br />
          <br />
          <Button
            fullWidth
            color="primary"
            variant="contained"
            onClick={() => {
              setCrearPropia(false);
              history.push("/documentos/solicitudes/crear");
            }}
          >
            Asignar borrador a otro usuario
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogCrear(false)}>Cancelar</Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
};

export default MisDocumentos;
