import React, { useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import DateFnsUtils from "@date-io/date-fns";
import Button from "@material-ui/core/Button";
import Mensaje from "../../helpers/components/message";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { toast } from "react-toastify";

//dialog
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import API from "../../utils/api";
import { isSignedIn } from "../../utils/auth";

import ModalAgregarItem from "../../helpers/components/modal-agregar-item";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
    },
    paperTitle: {
      color: "black",
      flex: 1,
    },
    inputNumber: {
      marginTop: 16,
      marginRight: 10,
    },
    button: {
      backgroundColor: "#69BD4B",
      "&:hover": {
        backgroundColor: "green",
      },
    },
    input: {
      marginRight: 10,
    },
    //Estilos de paper usuarios
    usuarioDiv: {
      display: "flex",
      alignItems: "center",
    },
    paperUsers: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
      minHeight: 300,
    },
    divTablaUsuarios: {
      marginBottom: 25,
    },
    divUsuarios: {
      display: "flex",
      flexDirection: "row",
    },
    buttonInfect: {
      backgroundColor: "#2196F3",
    },
  })
);

function ReportarCovid() {
  const [usuarioSeleccionado, setUsuarioSeleccionado] = useState(null);
  const [fechaTamizado, setFechaTamizado] = useState(new Date());
  const [fechaInicio, setFechaInicio] = useState(new Date() - 5);
  const [fechaFin, setFechaFin] = useState(new Date());
  const [metros, setMetros] = useState(5);
  const [isLoading, setIsLoading] = useState(false);

  const [usuarios, setUsuarios] = useState([]);
  const [codigosUsuariosPorAvisar, setCodigosUsuariosPorAvisar] = useState([]);

  //Seleccionar usuario
  const [openUsuariosAsignados, setOpenUsuariosAsignados] = useState(false);
  const [itemsUsuarios, setItemsUsuarios] = useState([]);

  //Dialog marcar notificado
  const [open, setOpen] = React.useState(false);
  const [isLoadingNotificacion, setIsLoadingNotificacion] = useState(false);

  useEffect(() => {
    var dateOffset = 24 * 60 * 60 * 1000 * 5; //5 days
    var myDate = new Date();
    setFechaInicio(myDate.getTime() - dateOffset);
  }, []);

  useEffect(() => {
    setUsuarios([]);
    setCodigosUsuariosPorAvisar([]);
  }, [usuarioSeleccionado]);

  const onClickVerUsuarios = () => {
    if (usuarioSeleccionado == null) {
      toast.warn("Seleccione a un usuario");
      return;
    }
    setUsuarios([]);
    setCodigosUsuariosPorAvisar([]);

    setIsLoading(true);
    const makeRequest = async () => {
      //const request = await API.fetchGetRequest(`api/CovidPositivos/fakeapi`);

      const fechaIniBuf = new Date(fechaInicio);
      const fechaInicioParam = `${fechaIniBuf.getFullYear()}${
        fechaIniBuf.getMonth() + 1 < 10
          ? "0" + (fechaIniBuf.getMonth() + 1)
          : fechaIniBuf.getMonth() + 1
      }${
        fechaIniBuf.getDate() < 10
          ? "0" + fechaIniBuf.getDate()
          : fechaIniBuf.getDate()
      }`;

      const fechaFinBuf = new Date(fechaFin);
      const fechaFinParam = `${fechaFinBuf.getFullYear()}${
        fechaFinBuf.getMonth() + 1 < 10
          ? "0" + (fechaFinBuf.getMonth() + 1)
          : fechaFinBuf.getMonth() + 1
      }${
        fechaFinBuf.getDate() < 10
          ? "0" + fechaFinBuf.getDate()
          : fechaFinBuf.getDate()
      }`;

      const request = await API.fetchGetRequestWithParams(
        `api/contacttracing/consultaDataOrigin/${usuarioSeleccionado.CodigoUsuario}/${fechaInicioParam}/${fechaFinParam}/${metros}`,
        null
      );

      const requestData = JSON.parse(request.data.data);

      if (requestData.length > 0) {
        var nuevosUsuarios = [];
        requestData.map((m) => {
          const length = Object.keys(m.tabla.CodigoUsuario).length;
          for (var i = 0; i < length; i++) {
            nuevosUsuarios = [...nuevosUsuarios, m.tabla.CodigoUsuario[i]];
            //setCodigosUsuariosPorAvisar(nuevosUsuarios);
          }
        });

        //Usuarios No repetidos
        const nuevosUsuariosNR = nuevosUsuarios.filter(
          (v, i, a) => a.indexOf(v, i + 1) === -1
        );

        setCodigosUsuariosPorAvisar(nuevosUsuariosNR);
      }

      setUsuarios(requestData);
      setIsLoading(false);
    };

    makeRequest();
  };

  const onClickMarcarInfectado = () => {
    const makeRequest = async () => {
      const session = await isSignedIn();

      if (usuarioSeleccionado == null) {
        toast.warn("Debes seleccionar a un usuario.");
        setIsLoadingNotificacion(false);
        return;
      }

      const postData = {
        CodigoUsuario: usuarioSeleccionado.CodigoUsuario,
        FechaPositivo: fechaTamizado,
        Comentario: "Usuario Infectado",
        UsuariosCercanos: codigosUsuariosPorAvisar,
        //UsuariosCercanos: [1, 2],
        MensajeNotificacionPush:
          "Alerta: se ha detectado un contacto potencial con una persona que ha sido marcada con COVID-19",
        CodigoUsuarioOpera: session.codigoUsuario,
        UsuarioOpera: session.userName,
      };
      const request = await API.post(
        `api/covidpositivos/grabarEditar`,
        postData
      );
      setOpen(false);
      setIsLoadingNotificacion(false);
      toast.info("Los usuarios han sido notificados");
    };
    setIsLoadingNotificacion(true);
    makeRequest();
  };

  /********************************************************************
   *********** FUNCIONES DE Buscar Usuarios ***************************
   ********************************************************************
   */
  const onClickBuscarUsuario = (text) => {
    //Buscar

    const makeRequest = async () => {
      const request = await API.fetchGetRequest(`api/usuario/buscar/${text}`);

      if (request.data.response) {
        //Transformamos los items
        const items = request.data.usuarios.map((usuario) => ({
          name: usuario.NombreCompletoUsuario,
          CodigoUsuario: usuario.CodigoUsuario,
        }));

        setItemsUsuarios(items);
      }
    };

    makeRequest();
  };

  const onClickSeleccionUsuario = (item) => {
    //Debemos armar el array [codigos_usuarios];

    const makeRequest = async () => {
      const session = await isSignedIn();

      //Verificamos si ya existe en los usuarios asignados existentes.

      console.log("El usuario elegido", item);
      setUsuarioSeleccionado(item);

      setOpenUsuariosAsignados(false);
    };
    makeRequest();
  };

  /********************************************************************
   *********** FUNCIONES DEL DIALOG ***********************************
   ********************************************************************
   */
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const contenidoTabla = (m) => {
    const length = Object.keys(m.tabla.CodigoUsuario).length;
    var rows = [];
    for (var i = 0; i < length; i++) {
      rows.push(
        <TableRow key={i}>
          <TableCell component="th" scope="row">
            {m.tabla.CodigoUsuario[i]}
          </TableCell>
          <TableCell>{m.tabla.NombreCompletoUsuario[i]}</TableCell>
          <TableCell>{m.tabla.NContactos[i]}</TableCell>
          <TableCell>{m.tabla.DistanciaPromedio[i]}</TableCell>
        </TableRow>
      );
    }

    return <TableBody>{rows}</TableBody>;
  };
  const classes = useStyles();
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Typography variant="h6" gutterBottom className={classes.paperTitle}>
            Reportar colaborador con COVID19
          </Typography>
          <div className={classes.usuarioDiv}>
            <IconButton
              aria-label="delete"
              className={classes.margin}
              onClick={() => setOpenUsuariosAsignados(true)}
            >
              <PersonAddIcon fontSize="small" className={classes.iconColor} />
            </IconButton>
            {usuarioSeleccionado == null && (
              <Mensaje>Selecciona a un usuario.</Mensaje>
            )}
            {usuarioSeleccionado != null && (
              <Mensaje>{usuarioSeleccionado.name}</Mensaje>
            )}
          </div>
          <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="Fecha tamizado"
                value={fechaTamizado}
                onChange={(date) => setFechaTamizado(date)}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                className={classes.input}
              />
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="Fecha inicial"
                value={fechaInicio}
                onChange={(date) => setFechaInicio(date)}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                className={classes.input}
              />
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="Fecha final"
                value={fechaFin}
                onChange={(date) => setFechaFin(date)}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                className={classes.input}
              />
              <TextField
                id="standard-number"
                label="Metros de acercamiento"
                type="number"
                className={classes.inputNumber}
                defaultValue={5}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(event) => setMetros(event.target.value)}
              />
            </MuiPickersUtilsProvider>
          </div>
          <div>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => onClickVerUsuarios()}
            >
              Calcular Contactos
            </Button>
          </div>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paperUsers}>
          <div className={classes.divUsuarios}>
            <Typography
              variant="h6"
              gutterBottom
              className={classes.paperTitle}
            >
              Usuarios
            </Typography>
          </div>
          {isLoading && (
            <div>
              <CircularProgress />
            </div>
          )}
          {usuarios.length <= 0 && (
            <Mensaje>
              No se encontraron colaboradores con riesgo de contacto
            </Mensaje>
          )}
          {codigosUsuariosPorAvisar.length > 0 && (
            <Mensaje style={{ color: "#E74C3C", fontSize: 16 }}>
              Este usuario estuvo en contacto con al menos{" "}
              <b>{codigosUsuariosPorAvisar.length}</b> persona
              {codigosUsuariosPorAvisar.length > 1 && "s"}.{" "}
            </Mensaje>
          )}
          <div>
            {usuarios.length > 0 && (
              <Button
                variant="contained"
                color="primary"
                className={classes.buttonInfect}
                onClick={() => handleClickOpen()}
              >
                NOTIFICAR CONTAGIOS POTENCIALES
              </Button>
            )}
          </div>
          {/* {usuarios.map((m) => (
            <div className={classes.divTablaUsuarios}>
              <Typography variant="subtitle1" gutterBottom>
                Fecha: <b>{m.Fecha}</b>
              </Typography>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Código</TableCell>
                      <TableCell>Nombre</TableCell>
                      <TableCell>Número de encuentros</TableCell>
                      <TableCell>Distancia Promedio</TableCell>
                    </TableRow>
                  </TableHead>
                  {contenidoTabla(m)}
                </Table>
              </TableContainer>
            </div>
          ))} */}
        </Paper>
      </Grid>
      <ModalAgregarItem
        title={"Seleccionar Usuario"}
        inputPlaceholder={"Busca a un usuario..."}
        open={openUsuariosAsignados}
        items={itemsUsuarios}
        setOpen={(open) => {
          setOpenUsuariosAsignados(open);
          setItemsUsuarios([]);
        }}
        onClickSearch={(text) => onClickBuscarUsuario(text)}
        onClickItem={(item) => onClickSeleccionUsuario(item)}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Notificación de contagio potencial"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Está seguro de realizar esta acción? Al proceder, el sistema
            enviará automáticamente una notificación al teléfono de las personas
            que estuvieron en posible contacto con el usuario marcado.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={onClickMarcarInfectado}
            color="primary"
            autoFocus
            disabled={isLoadingNotificacion}
          >
            Proceder
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

export default ReportarCovid;
