import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";

import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Checkbox,
  FormControlLabel,
  Divider,
  Paper,
  Tooltip,
  Modal,
  Fade,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
} from "@material-ui/core";

import BackdropModal from "@material-ui/core/Backdrop";

import clsx from "clsx";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { Edit, Delete } from "@material-ui/icons";

import { green } from "@material-ui/core/colors";
import { NavLink } from "react-router-dom";

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import DataGrid, {
  Column,
  Pager,
  Paging,
  Sorting,
  ColumnFixing,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Grouping,
  GroupPanel,
  Export,
  Selection,
  Editing,
  Scrolling,
  RowDragging,
  LoadPanel,
} from "devextreme-react/data-grid";

import API from "../../utils/api";
import { isSignedIn, signOut } from "../../utils/auth";
import {
  validaPermisoPaginaActual,
  verificaPermisoPaginaActual,
} from "../../utils/helpers";
import { ToastContainer, toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import "devextreme/dist/css/dx.material.teal.light.compact.css";

const width_proportion = "100%";
const titleColor = "#2B3C4D";
let datagridEmpleadosSCILU;

const useStyles = makeStyles((theme) => ({
  table: {
    width: "100%",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 210,
  },
  textFieldDireccion: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 230,
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  greenAvatar: {
    fontSize: "2.5em",
    color: "#fff",
    // borderStyle:'solid',
    // borderWidth:'1 !important',
    // borderColor:'#3f51b5 !important',
    backgroundColor: green[500],
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  paperCard: {
    padding: 20,
    width: "100%",
  },
  formControlSelects: {
    // margin: theme.spacing(1),
    // minWidth: 480,

    marginBottom: 10,
    marginTop: 10,
    width: "100%",
    marginLeft: 30,
  },
  formControlSelect: {
    // margin: theme.spacing(1),
    width: width_proportion,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  rootTabla: {
    width: "100%",
    marginTop: theme.spacing.unit * 1,
    overflowX: "auto",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paperModal: {
    position: "absolute",
    width: "70%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  selectedData: {
    marginTop: "20px",
    padding: "20px",
    backgroundColor: "rgba(191, 191, 191, 0.15)",
  },
  selectedDataCaption: {
    fontWeight: "bold",
    fontSize: "115%",
    marginRight: "4px",
  },
}));

const EmpleadoPlazaLaboral = (props) => {
  const classes = useStyles();
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors: fieldsErrors },
    register,
  } = useForm();

  let applyFilterTypes = [
    {
      key: "auto",
      name: "Immediately",
    },
    {
      key: "onClick",
      name: "On Button Click",
    },
  ];

  const [maestroPlazaLaboral, setMaestroPlazaLaboral] = useState([]);
  const [codigoPlazaLaboral, setCodigoPlazaLaboral] = useState(0);
  const [valueAutoCompletePlazaLaboral, setValueAutoCompletePlazaLaboral] =
    useState(null);

  const [codigoPlazaLaboralEdicion, setCodigoPlazaLaboralEdicion] = useState(0);
  const [
    valueAutoCompletePlazaLaboralEdicion,
    setValueAutoCompletePlazaLaboralEdicion,
  ] = useState(null);

  const [codigoNivelOrganizacion, setCodigoNivelOrganizacion] = useState(1);
  const [maestroGerencia, setMaestroGerencia] = useState([]);
  const [textoNivelOrganizacion, setTextoNivelOrganizacion] = useState("");
  const [registroRaiz, setRegistroRaiz] = useState({});
  const [registroActual, setRegistroActual] = useState({});
  const [nombre, setNombre] = useState("");
  const [codigoUnidadOrganizacion, setCodigoUnidadOrganizacion] = useState(0);

  const [grado, setGrado] = useState(0);
  const [organigrama, setOrganigrama] = useState(null);

  const [userName, setUserName] = useState("");
  const [open, setOpen] = useState(false);
  const [openPL, setOpenPL] = useState(false);
  const [openModalAsignacionEmpleado, setOpenModalAsignacionEmpleado] =
    useState(false);

  const [cargandoMaestros, setCargandoMaestros] = useState(false);
  const [organizacionHijos, setOrganizacionHijos] = useState([]);
  const [maestroNivelPuestoLaboral, setMaestroNivelPuestoLaboral] = useState(
    []
  );
  const [maestroTipoTurno, setMaestroTipoTurno] = useState([]);

  const [codigoGerencia, setCodigoGerencia] = useState(0);
  const [valueAutoCompleteGerencia, setValueAutoCompleteGerencia] =
    useState(null);
  const [listadoEmpleadoNoAsignado, setListadoEmpleadoNoAsignado] = useState(
    []
  );
  const [
    listadoEmpleadoAsignadoPorUnidad,
    setListadoEmpleadoAsignadoPorUnidad,
  ] = useState([]);
  const [maestroJefeInmediato, setMaestroJefeInmediato] = useState([]);
  const [dpiJefeInmediato, setDpiJefeInmediato] = useState("");
  const [valueAutoCompleteJefeInmediato, setValueAutoCompleteJefeInmediato] =
    useState(null);
  const [listadoEmpleadoAsignado, setListadoEmpleadoAsignado] = useState([]);
  const [empleadosSeleccion, setempleadosSeleccion] = useState([]);
  const [positionDisableSorting, setPositionDisableSorting] = useState(false);
  const [showFilterRow, setShowFilterRow] = useState(true);
  const [showHeaderFilter, setShowHeaderFilter] = useState(true);
  const [currentFilter, setCurrentFilter] = useState(applyFilterTypes[0].key);
  const [autoExpandAll, setAutoExpandAll] = useState(true);

  const [buscando, setBackDrop] = useState(false);

  const [codigoTipoTurno, setCodigoTipoTurno] = useState(0);
  const [valueAutoCompleteTipoTurno, setValueAutoCompleteTipoTurno] =
    useState(null);
  const [dpi, setDpi] = useState("");
  const [codigoEmpleadoPlazaLaboral, setCodigoEmpleadoPlazaLaboral] =
    useState(0);

  const [selectedEmployeeNames, setSelectedEmployeeNames] = useState(
    "No hay empleados seleccionados"
  );

  const [visualizarArea, setVisualizarArea] = useState(false);
  const [visualizarGerencia, setVisualizarGerencia] = useState(false);
  const [visualizarCompleto, setVisualizarCompleto] = useState(false);
  const [editarCompleto, setEditarCompleto] = useState(false);

  const handleCerrarBackDrop = () => {
    setBackDrop(false);
  };
  const handleAbrirBackDrop = () => {
    setBackDrop(true);
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleOpenModalPuestoLaboral = () => {
    setOpenPL(true);
  };

  const handleCloseModalPuestoLaboral = () => {
    setOpenPL(false);
  };

  const handleOpenModalAsignacionEmpleado = () => {
    setOpenModalAsignacionEmpleado(true);
  };

  const handleCloseModalAsignacionEmpleado = () => {
    setOpenModalAsignacionEmpleado(false);
  };

  useEffect(() => {
    const cargar = async () => {
      // CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
      const llaveAgrupacion = "GESPILU";

      const llaveVisualizarArea = "GESPILU.Personal.Visualizar_Area";
      let verArea = await verificaPermisoPaginaActual(
        llaveAgrupacion,
        llaveVisualizarArea
      );
      setVisualizarArea(verArea);

      const llaveVisualizarGerencia = "GESPILU.Personal.Visualizar_Gerencia";
      let verGerencia = await verificaPermisoPaginaActual(
        llaveAgrupacion,
        llaveVisualizarGerencia
      );
      setVisualizarGerencia(verGerencia);

      const llaveVisualizarCompleto = "GESPILU.Personal.Visualizar_Completo";
      let verCompleto = await verificaPermisoPaginaActual(
        llaveAgrupacion,
        llaveVisualizarCompleto
      );
      setVisualizarCompleto(verCompleto);

      const llaveEditarCompleto = "GESPILU.Personal.Editar_Completo";
      let editarCompleto = await verificaPermisoPaginaActual(
        llaveAgrupacion,
        llaveEditarCompleto
      );
      setEditarCompleto(editarCompleto);
      // FIN DE CONSULTA DE PERMISOS

      handleAbrirBackDrop();

      await cargarMaestroGerencia();
      //await cargarEmpleadoAsignado();
      await cargarMaestroTipoTurno();
      await cargarMaestroPlazaLaboral();
      await cargarEmpleadoAsignadoPorUnidadOrganizacion();
      handleCerrarBackDrop();
    };

    cargar();
  }, [props.codigoUnidadOrganizacion]);

  useEffect(() => {
    const cargar = async () => {
      await MaestroJefeInmediato();
    };

    cargar();
  }, []);

  useEffect(() => {
    const cargar = async () => {
      if (registroActual !== undefined || registroActual !== null) {
        handleAbrirBackDrop();
        await cargaOrganizacionHijos();
        await cargaOrganigrama();

        handleCerrarBackDrop();
      }
    };

    cargar();
  }, [registroActual]);

  useEffect(() => {
    let data = maestroGerencia;
    if (data.length > 0) {
      var nivelActual = data.find(
        (f) => f.CodigoNivelOrganizacion === codigoNivelOrganizacion
      );

      if (nivelActual !== undefined || nivelActual !== null) {
        setTextoNivelOrganizacion(nivelActual.NivelOrganizacion);
      }
    }
  }, [codigoNivelOrganizacion]);

  const cargarMaestroGerencia = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/Empleado/MaestroGerencia";

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setMaestroGerencia(request.data.data);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cargarMaestroTipoTurno = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/TipoTurno/";

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setMaestroTipoTurno(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cargarMaestroPlazaLaboral = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri =
        "api/PlazaLaboral/PorUnidadOrganizacion/" +
        props.codigoUnidadOrganizacion;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setMaestroPlazaLaboral(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };
  const cargarDatoRaizGeneral = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/Organizacion/Nivel/1";

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setRegistroRaiz(request.data.ficha[0]);
        setRegistroActual(request.data.ficha[0]);
        console.log(request.data.ficha[0]);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cargaOrganizacionHijos = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri =
        "api/Organizacion/Hijos/" + registroActual.CodigoUnidadOrganizacion;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setOrganizacionHijos(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cargaOrganigrama = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri =
        "api/Organizacion/Arbol/" + registroActual.CodigoUnidadOrganizacion;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setOrganigrama(request.data.ficha);
        console.log(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cargarEmpleadoAsignadoPorUnidadOrganizacion = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri =
        "api/PlazaLaboral/AsignadosPorUnidadOrganizacion/" +
        props.codigoUnidadOrganizacion;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setListadoEmpleadoAsignadoPorUnidad(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };
  const cargarEmpleadoAsignado = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/PlazaLaboral/Asignados/" + codigoPlazaLaboral;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setListadoEmpleadoAsignado(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const MaestroJefeInmediato = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri =
        "api/EmpleadoJefeInmediato/PorPlazaLaboral/" + codigoPlazaLaboral;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setMaestroJefeInmediato(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cargarEmpleadoPorPlaza = async () => {
    await cargarEmpleadoAsignado();
  };

  const filtrarPorGerencia = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/Empleado/Gerencia/" + codigoGerencia;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setListadoEmpleadoNoAsignado(request.data.data);
      }
    } catch (e) {
      console.log(e);
    } finally {
      deseleccionarItemsEnGridEmpleados();
      handleCerrarBackDrop();
    }
  };

  const filtrarPorPlaza = async () => {};

  const cuandoHayError = async (data) => {
    toast.info(
      "Existen algunos campos con error, por favor valida el formulario",
      {
        position: "top-right",
        autoClose: 3000, // Esta en milisegundos 3 segundos
      }
    );
  };

  const onExporting = async (e) => {};

  const [dialogoAbierto, abrirDialogo] = useState(false);
  const [dialogoAbiertoEditar, abrirDialogoEditar] = useState(false);
  const handleAbrirDialogoEliminar = (id) => {
    abrirDialogo(true);

    setCodigoEmpleadoPlazaLaboral(id);
  };

  const handleCerrarDialogo = () => {
    abrirDialogo(false);
  };

  const handleAbrirDialogoEditar = (id) => {
    abrirDialogoEditar(true);

    setCodigoGerencia(id);
  };

  const handleAbrirDialogoPuestoLaboral = (fila) => {
    handleOpenModalPuestoLaboral();

    setDpi(fila.DPI);
    const proyectosExcluidos = listadoEmpleadoAsignadoPorUnidad.filter(
      (elemento) => elemento.DPI === fila.DPI
    );

    if (fila.JefeInmediatoAsignadoManualmente) {
      setDpiJefeInmediato(fila.DPIJefeInmediato);
      const filaJefeInmediato = maestroJefeInmediato.filter(
        (elemento) => elemento.DPI === fila.DPIJefeInmediato
      );
      setValueAutoCompleteJefeInmediato(filaJefeInmediato[0]);
    } else {
      setDpiJefeInmediato("");
      setValueAutoCompleteJefeInmediato(null);
    }
    console.log("Editar" + fila.DPI);

    const itemPuestoLaboral = maestroPlazaLaboral.filter(
      (elemento) =>
        elemento.CodigoPlazaLaboral === proyectosExcluidos[0].CodigoPlazaLaboral
    );

    setValueAutoCompletePlazaLaboralEdicion(itemPuestoLaboral[0]);
    setCodigoPlazaLaboralEdicion(itemPuestoLaboral[0].CodigoPlazaLaboral);

    if (
      proyectosExcluidos[0].CodigoTipoTurno !== null &&
      proyectosExcluidos[0].CodigoTipoTurno > 0
    ) {
      const itemTurno = maestroTipoTurno.filter(
        (elemento) =>
          elemento.CodigoTipoTurno === proyectosExcluidos[0].CodigoTipoTurno
      );

      setValueAutoCompleteTipoTurno(itemTurno[0]);
      setCodigoTipoTurno(itemTurno[0].CodigoTipoTurno);
    }
  };

  const handleCerrarDialogoEditar = () => {
    abrirDialogoEditar(false);
  };

  const quitarEmpleadoPlazaLaboral = async (pId) => {
    handleCerrarDialogo();
    handleAbrirBackDrop();

    try {
      let uri = "api/PlazaLaboral/Empleado/Quitar/" + pId;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        toast.error(
          "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
        );
      } else {
        toast.success("Se quitó el registro con éxito", {
          position: "bottom-right",
          autoClose: 3000, // Esta en milisegundos 3 segundos
        });

        await cargarEmpleadoAsignadoPorUnidadOrganizacion();

        // setCodigoGerencia(0);
        // setCodigoUnidadOrganizacion(0);
      }
    } catch (error) {
      console.log("Error en  eliminación de proyectos" + error);
    }

    handleCerrarBackDrop();

    //alert("Quitar recurso del proyecto" + codigoRecurso);
  };

  const editarEmpleadoPlazaLaboral = async (pId) => {
    handleCerrarDialogoEditar();
    handleAbrirBackDrop();

    const proyectosExcluidos = listadoEmpleadoAsignadoPorUnidad.filter(
      (elemento) => elemento.DPI === pId
    );

    console.log("Editar" + pId.toString());

    try {
      handleOpenModal();

      const itemPuestoLaboral = maestroPlazaLaboral.filter(
        (elemento) =>
          elemento.CodigoPlazaLaboral ===
          proyectosExcluidos[0].CodigoPlazaLaboral
      );

      setValueAutoCompletePlazaLaboralEdicion(itemPuestoLaboral[0]);
      setCodigoPlazaLaboralEdicion(itemPuestoLaboral[0].CodigoPlazaLaboral);

      // if (proyectosExcluidos[0].CodigoTipoTurno !== null) {
      const itemTurno = maestroTipoTurno.filter(
        (elemento) =>
          elemento.CodigoTipoTurno === proyectosExcluidos[0].CodigoTipoTurno
      );

      console.log("Editar" + proyectosExcluidos[0]);

      setValueAutoCompleteTipoTurno(itemTurno[0]);
      setCodigoTipoTurno(itemTurno[0].CodigoTipoTurno);
    } catch (error) {
      console.log("Error en  edición de proyectos" + error);
    }

    handleCerrarBackDrop();

    //alert("Quitar recurso del proyecto" + codigoRecurso);
  };

  const handleOnChangeGerencia = async (event, newValue) => {
    handleAbrirBackDrop();
    if (newValue !== null) {
      setCodigoGerencia(newValue.CodigoGerencia);
      setValueAutoCompleteGerencia(newValue);
    }
    handleCerrarBackDrop();
  };

  const agregarEmpleadoAPlaza = async (e) => {
    const data = {
      CodigoPlazaLaboral: codigoPlazaLaboral,
      ListadoEmpleado: empleadosSeleccion,
      UsuarioInserto: userName,
      CodigoTemporada: props.codigoTemporada,
    };

    console.log(data);
    // dataFicha.usuarioInserto = "prueba";
    try {
      handleAbrirBackDrop();
      let vIsSignedIn = await isSignedIn();
      if (vIsSignedIn.response) {
        let request;

        request = await API.post("api/EmpleadoPlazaLaboral/", data);
        if (request.status !== 200) {
          toast.error(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        } else {
          toast.success("Se guardó exitosamente");

          await cargarEmpleadoAsignadoPorUnidadOrganizacion();
          deseleccionarItemsEnGridEmpleados();
          // await cargarEmpleadoAsignado();
        }
      }
    } catch (e) {
      console.log("EXCEPTION: " + e);
      toast.error(
        "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
      );
    } finally {
      handleCerrarBackDrop();
      handleCloseModalAsignacionEmpleado();
    }
  };

  const cellOpciones = (rowInfo) => {
    return (
      <>
        <Tooltip
          title="Editar datos de turno y jefe inmediato"
          style={{ marginRight: 5, cursor: "pointer" }}
        >
          <Edit
            style={{ color: "#337ab7", cursor: "pointer" }}
            onClick={() => handleAbrirDialogoPuestoLaboral(rowInfo.data)}
          />
        </Tooltip>
        <Tooltip
          title="Eliminar Empleado de Plaza"
          style={{ marginRight: 5, cursor: "pointer" }}
        >
          <Delete
            style={{ color: "#337ab7", cursor: "pointer" }}
            onClick={() =>
              handleAbrirDialogoEliminar(
                rowInfo.data.CodigoEmpleadoPlazaLaboral
              )
            }
          />
        </Tooltip>
      </>
    );
  };

  const guardarEdicionRegistros = async () => {
    const data = {
      DPI: dpi,
      DPIJefeInmediato: dpiJefeInmediato,
      CodigoPlazaLaboral: codigoPlazaLaboralEdicion,
      CodigoTipoTurno: codigoTipoTurno,
      CodigoTemporada: props.codigoTemporada,
      Activo: true,
      Eliminado: false,
      UsuarioInserto: userName,
      FechaInserto: "",
      UsuarioModifico: "",
      FechaModifico: "",
    };

    console.log(data);
    // dataFicha.usuarioInserto = "prueba";
    try {
      handleAbrirBackDrop();
      let vIsSignedIn = await isSignedIn();
      if (vIsSignedIn.response) {
        let request;

        request = await API.post("api/EmpleadoPlazaLaboral/Editar", data);
        if (request.status !== 200) {
          toast.error(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        } else {
          toast.success("Se guardó exitosamente");

          await cargarEmpleadoAsignadoPorUnidadOrganizacion();

          // setValueAutoCompletePuestoLaboral(null);
        }
      }
    } catch (e) {
      console.log("EXCEPTION: " + e);
      toast.error(
        "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
      );
    } finally {
      handleCloseModalPuestoLaboral();
      handleCerrarBackDrop();
    }
  };

  const handleOnChangeTipoTurno = async (event, newValue) => {
    handleAbrirBackDrop();
    if (newValue !== null) {
      setCodigoTipoTurno(newValue.CodigoTipoTurno);
      setValueAutoCompleteTipoTurno(newValue);
    }
    handleCerrarBackDrop();
  };

  const handleOnChangePlazaLaboral = async (event, newValue) => {
    handleAbrirBackDrop();
    if (newValue !== null) {
      setCodigoPlazaLaboral(newValue.CodigoPlazaLaboral);
      setValueAutoCompletePlazaLaboral(newValue);
    }
    handleCerrarBackDrop();
  };

  const handleOnChangePlazaLaboralEdicion = async (event, newValue) => {
    handleAbrirBackDrop();
    if (newValue !== null) {
      setCodigoPlazaLaboralEdicion(newValue.CodigoPlazaLaboral);
      setValueAutoCompletePlazaLaboralEdicion(newValue);
    }
    handleCerrarBackDrop();
  };

  const asignarEmpleados = () => {
    console.log(datagridEmpleadosSCILU.getSelectedRowsData());
    setempleadosSeleccion(datagridEmpleadosSCILU.getSelectedRowsData());
    handleOpenModalAsignacionEmpleado();
  };

  const onInitialized = (e) => {
    datagridEmpleadosSCILU = e.component;
  };

  const deseleccionarItemsEnGridEmpleados = () => {
    datagridEmpleadosSCILU.clearSelection();
  };

  const getEmployeeName = (row) => {
    return `[${row.CodigoAnterior} - ${row.NombreCompleto}]`;
  };

  const getEmployeeNames = (selectedRowsData) => {
    return selectedRowsData.length
      ? selectedRowsData.map(getEmployeeName).join("; ")
      : "No hay empleados seleccionados";
  };

  const onSelectionChanged = ({ selectedRowKeys, selectedRowsData }) => {
    setSelectedEmployeeNames(getEmployeeNames(selectedRowsData));
  };

  const handleOnChangeJefeInmediato = async (event, newValue) => {
    handleAbrirBackDrop();
    if (newValue !== null) {
      setDpiJefeInmediato(newValue.DPI);
      setValueAutoCompleteJefeInmediato(newValue);
    }
    handleCerrarBackDrop();
  };

  return (
    <React.Fragment>
      <Backdrop className={classes.backdrop} open={buscando}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* Dialogo Eliminar */}
      <Dialog
        open={dialogoAbierto}
        onClose={handleCerrarDialogo}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Eliminación</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro que deseas eliminar este registro?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCerrarDialogo} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={() =>
              quitarEmpleadoPlazaLaboral(codigoEmpleadoPlazaLaboral)
            }
            color="primary"
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
      {/* Dialogo Editar No se esta usando */}
      <Dialog
        open={dialogoAbiertoEditar}
        onClose={handleCerrarDialogoEditar}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Edición</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro que deseas editar este registro?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCerrarDialogoEditar} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={() => editarEmpleadoPlazaLaboral(dpi)}
            color="primary"
          >
            Editar
          </Button>
        </DialogActions>
      </Dialog>
      {/* Editar  */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openPL}
        onClose={handleCloseModalPuestoLaboral}
        closeAfterTransition
        BackdropComponent={BackdropModal}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openPL}>
          <div className={classes.paperModal}>
            <h2 id="simple-modal-title">Editar registro</h2>
            <br />
            <Grid container spacing={3}>
              <form
                autoComplete="off"
                onSubmit={handleSubmit(guardarEdicionRegistros, cuandoHayError)}
              >
                <Grid item xs={6} sm={6} md={12} lg={12}>
                  <FormControl className={classes.formControlSelects}>
                    <Controller
                      name="plazaLaboralEdicion"
                      required
                      render={({ PlazaLaboralEdicion }) => (
                        <Autocomplete
                          id="plazaLaboralEdicion"
                          options={maestroPlazaLaboral}
                          getOptionSelected={(option, value) =>
                            option.CodigoPlazaLaboral ===
                            value.CodigoPlazaLaboral
                          }
                          getOptionLabel={(option) =>
                            option.PuestoLaboral || ""
                          }
                          value={valueAutoCompletePlazaLaboralEdicion}
                          onChange={(event, newValue) =>
                            handleOnChangePlazaLaboralEdicion(event, newValue)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Plaza Laboral"
                              variant="outlined"
                            />
                          )}
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
                  <FormControl className={classes.formControlSelects}>
                    <Controller
                      name="puestoLaboral"
                      required
                      render={({ PuestoLaboral }) => (
                        <Autocomplete
                          id="puestoLaboral"
                          options={maestroTipoTurno}
                          getOptionLabel={(option) => option.TipoTurno}
                          value={valueAutoCompleteTipoTurno}
                          onChange={(event, newValue) =>
                            handleOnChangeTipoTurno(event, newValue)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Tipo Turno"
                              variant="outlined"
                            />
                          )}
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
                  <FormControl className={classes.formControlSelects}>
                    <Controller
                      name="jefeInmediato"
                      required
                      render={({ JefeInmediato }) => (
                        <Autocomplete
                          id="jefeInmediato"
                          options={maestroJefeInmediato}
                          getOptionLabel={(option) => option.NombreCompleto}
                          value={valueAutoCompleteJefeInmediato}
                          onChange={(event, newValue) =>
                            handleOnChangeJefeInmediato(event, newValue)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Jefe Inmediato"
                              variant="outlined"
                            />
                          )}
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} md={12} lg={12}>
                  <Button
                    width="100%"
                    variant="contained"
                    style={{
                      backgroundColor: "#69bd4b",
                      marginBottom: 10,
                      marginTop: 10,
                      color: "white",
                      width: "93%",
                      marginLeft: 30,
                    }}
                    type="submit"
                  >
                    &nbsp;Guardar Cambios
                  </Button>
                </Grid>
              </form>
            </Grid>
          </div>
        </Fade>
      </Modal>
      {/* Seleccion de plaza a asignar */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModalAsignacionEmpleado}
        onClose={handleCloseModalAsignacionEmpleado}
        closeAfterTransition
        BackdropComponent={BackdropModal}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModalAsignacionEmpleado}>
          <div className={classes.paperModal}>
            <h2 id="simple-modal-title">
              Seleccionar Empleados a Plaza Laboral
            </h2>
            <Grid container spacing={3}>
              <form
                autoComplete="off"
                onSubmit={handleSubmit(agregarEmpleadoAPlaza, cuandoHayError)}
              >
                <Grid item xs={6} sm={6} md={12} lg={12}>
                  <FormControl className={classes.formControlSelects}>
                    <Controller
                      name="plazaLaboral"
                      required
                      render={({ PlazaLaboral }) => (
                        <Autocomplete
                          id="plazaLaboral"
                          options={maestroPlazaLaboral}
                          getOptionSelected={(option, value) =>
                            option.CodigoPlazaLaboral ===
                            value.CodigoPlazaLaboral
                          }
                          getOptionLabel={(option) =>
                            option.PuestoLaboral || ""
                          }
                          value={valueAutoCompletePlazaLaboral}
                          onChange={(event, newValue) =>
                            handleOnChangePlazaLaboral(event, newValue)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Plaza Laboral"
                              variant="outlined"
                            />
                          )}
                        />
                      )}
                      control={control}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} md={12} lg={12}>
                  <Button
                    width="100%"
                    variant="contained"
                    style={{
                      backgroundColor: "#69bd4b",
                      marginBottom: 10,
                      marginTop: 10,
                      color: "white",
                      width: "93%",
                      marginLeft: 30,
                    }}
                    type="submit"
                  >
                    &nbsp;Asignar Empleado(s)
                  </Button>
                </Grid>
              </form>
            </Grid>
          </div>
        </Fade>
      </Modal>
      <AppBar
        position="sticky"
        className={classes.appBar}
        style={{ backgroundColor: titleColor }}
      >
        <Toolbar>
          <Typography
            variant="h6"
            noWrap
            style={{ display: "flex", flexGrow: 1 }}
          >
            {`Empleado a Plaza Laboral`}
          </Typography>
        </Toolbar>
      </AppBar>
      <Paper className={classes.rootTabla}>
        <Typography variant="h5">Empleados Asignados</Typography>
        <DataGrid
          dataSource={listadoEmpleadoAsignadoPorUnidad}
          //   defaultColumns={this.props.columns}
          showBorders={true}
          rowAlternationEnabled={true}
          //ref={(ref) => (dataGrid = ref)}
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
          onExporting={onExporting}
          className={classes.table}
        >
          <FilterRow visible={showFilterRow} applyFilter={currentFilter} />
          <HeaderFilter visible={showHeaderFilter} />
          <GroupPanel visible={false} />
          <Grouping autoExpandAll={autoExpandAll} />
          <SearchPanel visible={false} width={240} placeholder="Search..." />
          {/* <Selection mode="multiple" /> */}
          <LoadPanel
            enabled={true}
            shadingColor="rgba(0,0,0,0.4)"
            showIndicator={true}
            shading={true}
            showPane={true}
            closeOnOutsideClick={false}
          />
          <Editing
            mode="row"
            useIcons={true}
            allowUpdating={false}
            allowDeleting={false}
          />

          <Column
            caption="Opciones"
            allowFiltering={false}
            allowSorting={false}
            cellRender={cellOpciones}
            visible={editarCompleto}
          />
          <Column
            dataField="CodigoEmpleadoPlazaLaboral"
            caption="Código DB"
            visible={false}
          />
          <Column dataField="DPI" caption="DPI" />
          {/* <Column dataField="CodigoAnterior" caption="Código" /> */}
          <Column dataField="NombreCompleto" caption="NombreCompleto" />
          <Column dataField="PuestoLaboral" caption="Puesto Laboral" />
          <Column dataField="Salario" caption="Salario" />
          <Column dataField="TipoTurno" caption="Tipo Turno" />
          <Column dataField="JefeInmediato" caption="JefeInmediato" />
          <Column
            dataField="JefeInmediatoAsignadoManualmente"
            caption="Jefe Asignado Manualmente"
          />
          <ColumnFixing enabled={true} />
          <Sorting mode="multiple" />
          <Paging defaultPageSize={30} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[30, 50, 100]}
            showInfo={true}
          />
          <Export enabled={true} allowExportSelectedData={true} />
          <Scrolling columnRenderingMode="virtual" />
        </DataGrid>
      </Paper>
      <br />

      {editarCompleto && (
        <>
          <Typography variant="h5">Buscar Empleados en SCILU</Typography>
          <br />

          <Grid container spacing={3}>
            <form
              autoComplete="off"
              onSubmit={handleSubmit(filtrarPorGerencia, cuandoHayError)}
            >
              <Grid item xs={6} sm={6} md={12} lg={12}>
                <FormControl className={classes.formControlSelects}>
                  <Controller
                    name="gerencia"
                    required
                    render={({ Gerencia }) => (
                      <Autocomplete
                        id="gerencia"
                        options={maestroGerencia}
                        getOptionSelected={(option, value) =>
                          option.CodigoGerencia === value.CodigoGerencia
                        }
                        getOptionLabel={(option) => option.Gerencia || ""}
                        value={valueAutoCompleteGerencia}
                        onChange={(event, newValue) =>
                          handleOnChangeGerencia(event, newValue)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Gerencia"
                            variant="outlined"
                          />
                        )}
                      />
                    )}
                    control={control}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={6} md={12} lg={12}>
                <Button
                  width="100%"
                  variant="contained"
                  style={{
                    backgroundColor: "#69bd4b",
                    marginBottom: 10,
                    marginTop: 10,
                    color: "white",
                    width: "93%",
                    marginLeft: 30,
                  }}
                  type="submit"
                >
                  &nbsp;Filtrar
                </Button>
              </Grid>
            </form>
          </Grid>
          <Paper className={classes.rootTabla}>
            <DataGrid
              dataSource={listadoEmpleadoNoAsignado}
              //   defaultColumns={this.props.columns}
              showBorders={true}
              rowAlternationEnabled={true}
              //ref={(ref) => (dataGrid = ref)}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              onExporting={onExporting}
              className={classes.table}
              onInitialized={onInitialized}
              onSelectionChanged={onSelectionChanged}
            >
              <FilterRow visible={showFilterRow} applyFilter={currentFilter} />
              <HeaderFilter visible={showHeaderFilter} />
              <GroupPanel visible={false} />
              <Grouping autoExpandAll={autoExpandAll} />
              <SearchPanel
                visible={false}
                width={240}
                placeholder="Search..."
              />
              {/* <Selection mode="multiple" /> */}
              <LoadPanel
                enabled={true}
                shadingColor="rgba(0,0,0,0.4)"
                showIndicator={true}
                shading={true}
                showPane={true}
                closeOnOutsideClick={false}
              />
              <Editing
                mode="row"
                useIcons={true}
                allowUpdating={false}
                allowDeleting={false}
              />

              {/* <RowDragging
            data={1}
            group="tasksGroup"
            onAdd={agregarEmpleadoAPlaza}
          /> */}
              <Selection mode="multiple" />
              <Column dataField="DPI" caption="DPI" />
              <Column dataField="CodigoAnterior" caption="Codigo" />
              <Column dataField="NombreCompleto" caption="Nombre Completo" />
              <Column dataField="PuestoLaboral" caption="Puesto Laboral" />
              <Column dataField="Expertis" caption="Expertis" />
              <Column
                dataField="FechaAltaReal"
                caption="Fecha de Alta"
                dataType="date"
                format="dd/MM/yyyy"
              />
              <Column dataField="EstadoEmpleado" caption="Estado Actual" />

              <ColumnFixing enabled={true} />
              <Sorting mode="multiple" />
              <Paging defaultPageSize={20} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[10, 20, 50]}
                showInfo={true}
              />
              <Export enabled={true} allowExportSelectedData={true} />
              <Scrolling columnRenderingMode="virtual" />
            </DataGrid>
            <div className={classes.selectedData}>
              <span className={classes.selectedDataCaption}>
                Empleados seleccionados:
              </span>{" "}
              <span>{selectedEmployeeNames}</span>
            </div>
          </Paper>
          <br />
          <br />
          <Grid container spacing={3}>
            <form
              autoComplete="off"
              onSubmit={handleSubmit(asignarEmpleados, cuandoHayError)}
            >
              <Grid item xs={6} sm={6} md={12} lg={12}>
                <Button
                  width="100%"
                  variant="contained"
                  style={{
                    backgroundColor: "#69bd4b",
                    marginBottom: 10,
                    marginTop: 10,
                    color: "white",
                    width: "93%",
                    marginLeft: 30,
                  }}
                  type="submit"
                >
                  &nbsp;Asignar
                </Button>
              </Grid>
            </form>
          </Grid>
        </>
      )}
    </React.Fragment>
  );
};

export default EmpleadoPlazaLaboral;
