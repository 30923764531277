import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  Grid,
  Typography,
  Card,
  CardContent,
  IconButton,
  makeStyles,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Paper,
  Tabs as MuiTabs,
  Tab as MuiTab,
  Chip,
  Tooltip,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  withStyles
} from '@material-ui/core'
import { Star, PictureAsPdf, Search, FilterList, Print, Visibility, ExpandMore } from '@material-ui/icons'
import { red, grey, green, blue } from '@material-ui/core/colors'
import { Alert } from '@material-ui/lab'
import { ToastContainer, toast } from 'react-toastify'
import { useStoreActions, useStoreState } from 'easy-peasy'
import dayjs from 'dayjs'

import Layout from '../layout/containers/Layout'
import Navegacion from './componentes/documentos/Navegacion'
import Filtros from './componentes/documentos/Filtros'
import { getPDFSolicitudBase64 } from './utils/media'
import { getCodigoUsuario, getUsuarioInserta } from './utils/utilidades'
import api from '../utils/api'
import Loading from './componentes/utils/Loading'
import ViewPDF from './componentes/documentos/ViewPDF'

const Tabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: red[500],
  },
})(MuiTabs);

const Tab = withStyles((theme) => ({
  root: {
    minWidth: 140,
    marginRight: theme.spacing(3),
    backgroundColor: blue[800],
    '&:hover': {
      color: blue[900],
      opacity: 1,
    },
    '&$selected': {
      fontWeight: theme.typography.fontWeightMedium,
      backgroundColor: blue[500]
    },
    '&:focus': {
      color: blue[900],
    },
  },
  selected: {},
}))((props) => <MuiTab disableRipple {...props} />);

const a11yProps = (index) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`
  }
}

const useStyles = makeStyles(theme => ({
  semaforo: {
    display: 'inline-block',
    height: 32,
    width: 32,
    borderRadius: '50%'
  },
  searchContainer: {
    width: 250,
    background: '#FFF'
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  searchInput: {
    font: 'inherit',
    color: 'currentColor',
    padding: '6px 0 7px 0',
    height: '1.2em',
    border: 0,
    boxSizing: 'content-box',
    outline: 0,
    fontSize: '1.2em'
  },
  iconButton: {
    padding: 10,
  },
  items: {
    marginTop: 10,
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(20em, 1fr))',
    // gridAutoRows: '1fr',
    gap: 10
  },
  success: {
    color: green[500],
  },
  pending: {
    color: grey[500],
  },
  icon: {
    height: 40
  },
  card: {
    background: grey[300]
  },
  detalleTitulo: {
    background: grey[400]
  },
  detalle: {
    background: grey[200]
  }
}))

const DirectorioDocumentos = () => {
  const classes = useStyles()
  const {
    filtros,
    favoritos,
    docsGerencia,
    docsBusqueda,
    docsInformado,
    permisosGesDoc,
    tipos,
    macroprocesos
  } = useStoreState(state => ({
    docsGerencia: state.docsGerencia,
    docsBusqueda: state.docsBusqueda,
    filtros: state.filtros,
    favoritos: state.favoritos,
    docsInformado: state.docsInformado,
    permisosGesDoc: state.permisosGesDoc,
    tipos: state.tipos,
    macroprocesos: state.macroprocesos
  }))
  const { getMasters, getDocs, getFavoritos, getDocsInformado } = useStoreActions(actions => ({
    getMasters: actions.getMasters,
    getDocs: actions.getDocs,
    getFavoritos: actions.getFavoritos,
    getDocsInformado: actions.getDocsInformado
  }))
  const listaTipos = (documentos, tipos) => {
    const items = [
      {
        Id: 0,
        NombreTipoDocumento: 'TODOS',
        Color: '#7fb800',
        Conteo: documentos.length
      },
      ...tipos.map(i => ({
        Id: i.Id,
        NombreTipoDocumento: i.NombreTipoDocumento,
        Color: i.Color,
        Conteo: documentos.filter(j => j.IdTipo === i.Id).length
      }))
    ]
    return items.map(i => ({...i, Conteo: i.Conteo === 0 ? '-' : i.Conteo}))
  }
  const [documentos, setDocumentos] = useState([])
  const [open, setOpen] = useState(false)
  const [dialogFiltros, setDialogFiltros] = useState(false)
  const [selected, setSelected] = useState(null)
  const [titulo, setTitulo] = useState({
    icono: '',
    texto: ''
  })
  const [gerenciaSelected, setGerenciaSelected] = useState(null)
  const [search, setSearch] = useState('')
  const [loaded, setLoaded] = useState(false)
  const [loadFav, setLoadFav] = useState(false)
  const [sugerencias, setSugerencias] = useState([])
  const [PDF, setPDF] = useState(null)
  const [dialogPrint, setDialogPrint] = useState(false)
  const [print, setPrint] = useState('')
  const [showInformado, setShowInformado] = useState(false)
  const [tabValue, setTabValue] = useState(0)
  const [saving, setSaving] = useState(false)
  const tiposDocs = listaTipos(documentos, tipos)
  const tipoSelected = tiposDocs[tabValue]
  const switchSelected = (codigo) => {
    if (selected) {
      const btnSelected = document.getElementById(`item-${selected}`)
      if (btnSelected) {
        btnSelected.style.background = 'none'
      }
    }
    if (codigo) {
      const btn = document.getElementById(`item-${codigo}`)
      btn.style.background = grey[300]
      setSelected(codigo)
    } else {
      setSelected(null)
    }
  }
  const selectArea = (codigo) => {
    switchSelected(codigo)
    setDocumentos(docsGerencia.filter(i => i.proceso === codigo))
    setShowInformado(false)
  }
  const selectGerencia = async (codigo) => {
    switchSelected(null)
    const macro = macroprocesos.find(i => i.IdMacroproceso === codigo)
    if (macro) {
      setTitulo({
        icono: macro.Icono,
        texto: macro.NombreMacroproceso
      })
    }
    if (gerenciaSelected === codigo) {
      setDocumentos(marcarFavoritos(docsGerencia))
      setShowInformado(false)
      return
    }
    try{
      setGerenciaSelected(codigo)
      await getDocs({
        gerencia: codigo,
        nombre: null
      })
      setLoaded(true)
      setLoadFav(false)
    }catch(error){
      toast.error('Ha ocurrido un error al obtener los datos')
    }
  }
  const saveSugerencias = (item) => {
    const items = JSON.parse(localStorage.getItem('busquedas')) || []
    items.push(item)
    const busquedas = Array.from(new Set(items))
    localStorage.setItem('busquedas', JSON.stringify(busquedas))
    setSugerencias(busquedas)
  }
  const buscarNombre = () => {
    try {
      const busqueda = search.trim()
      if (busqueda.length) {
        getDocs({
          gerencia: null,
          nombre: busqueda
        })
        saveSugerencias(busqueda)
        setLoaded(true)
        setLoadFav(false)
      }
    } catch (error) {
      toast.error('Ha ocurrido un error al obtener los datos')
    }
  }
  const filtrarDocs = () => {
    let items = [...documentos]
    if (filtros.sistema) {
      items = items.filter(i => i.entorno.find(j =>
        j.IdSistemaGestion === filtros.sistema.Id))
    }
    if (filtros.tipo) {
      items = items.filter(i => i.tipo === filtros.tipo.NombreTipoDocumento)
    }
    if (filtros.anho) {
      items = items.filter(i => i.anho === filtros.anho)
    }
    if (filtros.vigencia) {
      items = items.filter(i => i.semaforo.vigencia === filtros.vigencia)
    }
    setDocumentos(marcarFavoritos(items))
    setDialogFiltros(false)
  }
  const marcarFavoritos = (_docs) => {
    const items = [..._docs]
    for (let i = 0; i < items.length; i++) {
      for (let j = 0; j < favoritos.length; j++) {
        if (items[i].IdSolicitud == favoritos[j].IdSolicitud) {
          items[i].esFavorito = true
          break
        }
      }
    }
    return items
  }
  const loadFavoritos = () => {
    switchSelected('a')
    setTitulo({
      icono: 'favoritos.svg',
      texto: 'Favoritos'
    })
    setDocumentos([...favoritos])
    setShowInformado(false)
    setLoadFav(true)
    setLoaded(true)
  }
  const loadInformado = () => {
    switchSelected('b')
    setTitulo({
      icono: 'informado.svg',
      texto: 'Informado'
    })
    const informados = docsInformado.filter(i => !i.Leido)
    setDocumentos(marcarFavoritos(informados))
    setShowInformado(true)
    setLoadFav(false)
    setLoaded(true)
  }
  const switchFavorito = async (IdSolicitud) => {
    try {
      setSaving(true)
      const items = [...documentos]
      const index = items.findIndex(i => i.IdSolicitud === IdSolicitud)
      if (index >= 0) {
        const esFavorito = items[index].esFavorito
        items[index].esFavorito = !esFavorito
        setDocumentos(items)
        const body = {
          CodigoUsuario: getCodigoUsuario(),
          IdVersionDocumento: items[index].IdVersionDocumento
        }
        const url = `api/documentos/documento-favorito/${esFavorito ? 'quitar' : 'agregar'}`
        await api.realizarPeticionPostPut(url, body, 'POST')
        await getFavoritos(getCodigoUsuario())
        await getDocsInformado(getCodigoUsuario())
        if (loadFav) {
          setDocumentos([...favoritos])
        }
      }
    } catch (error) {
      console.log(error)
      toast.error('Ha ocurrido un error 😅')
    } finally {
      setSaving(false)
    }
  }
  const getPDF = async (IdSolicitud) => {
    try {
      setPDF(null)
      let base64str = await getPDFSolicitudBase64(IdSolicitud)
      if (base64str) {
        base64str = base64str.replace(
          "data:application/octet-stream;base64,",
          ""
        )
        setPDF(base64str)
      }
    } catch (error) {
      toast.error('Ocurrio un error al obtener el documento 😅')
    }
  }
  const guardarAccionDocumento = async (idSolicitd, accion, descripcion) => {
    const body = {
      CodigoUsuario: getCodigoUsuario(),
      Accion: accion,
      IdObjeto: idSolicitd,
      Objeto: "Solicitud",
      Fecha: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      Comentario: descripcion,
      Activo: true,
    };
    await api.post("api/documentos/guardar-accion-imprimir-documento", body);
  }
  const showPDF = (item) => {
    setSelected({...item})
    getPDF(item.IdSolicitud)
    guardarAccionDocumento(item.IdSolicitud, 'Lectura', 'PDF mostrado')
    setOpen(true)
  }
  const hidePDF = () => {
    setPDF(null)
    setOpen(false)
  }
  const printDoc = async (id) => {
    try {
      const item = documentos.find((i) => i.IdSolicitud === id);
      let base64str = await getPDFSolicitudBase64(item.IdSolicitud);
      base64str = base64str.replace(
        "data:application/octet-stream;base64,",
        "data:application/pdf;base64,"
      );
      setPrint(base64str);
      guardarAccionDocumento(id, 'Impresion', 'Se presionó el boton imprimir')
      setDialogPrint(true);
    } catch (error) {
      toast.error('Ocurrio un error al obtener el archivo 😓')
    }
  }
  const marcarLeido = async (idInformado) => {
    const index = documentos.findIndex(i => i.IdInformado === idInformado)
    try {
      if (!documentos[index].Leido && window.confirm('¿Estas seguro de marcar el documento como leído?')) {
        const items = [...documentos]
        items[index].Leido = true
        setDocumentos(items)
        const idSolicitd = items[index].IdSolicitud
        const body = {
          UsuarioModifico: getUsuarioInserta(),
          IdInformado: idInformado,
        }
        await api.realizarPeticionPostPut("api/documentos/marcar-informado", body, 'POST')
        await registroLectura(idSolicitd)
        await getDocsInformado(getCodigoUsuario())
        loadInformado()
      }
    } catch (error) {
      toast.error('Ha ocurrido un error')
    }
  }
  const registroLectura = async (idSolicitd) => {
    const body = {
      CodigoUsuario: getCodigoUsuario(),
      Accion: 'Lectura',
      IdObjeto: idSolicitd,
      Objeto: 'Solicitud',
      Fecha: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      Comentario: 'Marcó el documento como leído',
      Activo: true,
    };
    await api.post('api/documentos/guardar-accion-imprimir-documento', body);
  }
  useEffect(() => {
    if (search === '' && docsBusqueda.length && docsGerencia.length) {
      setDocumentos(marcarFavoritos(docsGerencia))
      setShowInformado(false)
      return
    }
    if (search === '' && docsBusqueda.length) {
      setDocumentos([])
      setShowInformado(false)
    }
  }, [search])
  useEffect(() => {
    getMasters()
    getFavoritos(getCodigoUsuario())
    getDocsInformado(getCodigoUsuario())
    const items = JSON.parse(localStorage.getItem('busquedas')) || []
    setSugerencias(items)
  }, [])
  useEffect(() => {
    if (docsGerencia.length) {
      setDocumentos(marcarFavoritos(docsGerencia))
      setShowInformado(false)
    }
  }, [docsGerencia, favoritos])
  useEffect(() => {
    if (docsBusqueda.length) {
      setDocumentos(marcarFavoritos(docsBusqueda))
      setShowInformado(false)
    }
  }, [docsBusqueda, favoritos])

  return <Layout
    titulo="Directorio de documentos"
    maxWidth="xl"
    bodyContainerStyle={{paddingTop: 0, paddingBottom: 10}}
  >
    <Grid container style={{margin: 10}}>
      <Grid item xs={12} style={{marginBottom: 10}}>
        {
          titulo.icono !== '' && <Box display="flex" justifyContent="center" alignItems="center">
            <Typography variant="h6" align="center">
              <img src={`/iconos/${titulo.icono}`} className={classes.icon} /> {titulo.texto}
            </Typography>
          </Box>
        }
      </Grid>
      <Grid item xs={12} md={2} style={{height: '80vh', overflowY: 'scroll'}}>
        <Navegacion
          hasFavoritos
          selectArea={selectArea}
          selectGerencia={selectGerencia}
          loadFavoritos={loadFavoritos}
          loadInformado={loadInformado}
        />
      </Grid>
      <Grid item xs={12} md={10}>
        <Box display="flex" style={{
            boxShadow: '0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%), 0 3px 1px -2px rgb(0 0 0 / 20%)',
            padding: 10,
            background: '#E5E5E5'
          }}>
          <div style={{flex: '1 0 0'}} />
          <IconButton onClick={() => setDialogFiltros(true)}>
            <FilterList />
          </IconButton>
          <Box display="flex" alignItems="center" className={classes.searchContainer}>
            <input
              className={classes.searchInput}
              list="historial"
              value={search}
              onChange={ev => setSearch(ev.target.value)}
              onKeyPress={ev => ev.key === 'Enter' && buscarNombre()}
              placeholder="Buscar (titulo o código)"
            />
            <IconButton
              onClick={buscarNombre}
              className={classes.iconButton}
              aria-label="search"
            >
              <Search />
            </IconButton>
            <datalist id="historial">
              {
                sugerencias.map(i => <option
                  key={i}
                  value={i}
                />)
              }
            </datalist>
          </Box>
        </Box>
        <Box display="flex" justifyContent="center">
          <Tabs
              value={tabValue}
              onChange={(_, val) => setTabValue(val)}
              indicatorColor="secondary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
              {
                tiposDocs.map((i, idx) => <Tab
                  key={idx}
                  label={<>
                    {i.NombreTipoDocumento} <span style={{fontSize: 11}}>({i.Conteo})</span>
                  </>}
                  {...a11yProps(idx)}
                  style={{
                    color: '#FFFFFF',
                    minWidth: 140,
                    marginRight: 5
                  }}
                />)
              }
          </Tabs>
        </Box>
        {
          !loaded && <Alert
            fullWidth
            severity="info"
            variant="filled"
            style={{marginTop: 5}}
          >
            Selecciona una gerencia o realiza una busqueda
          </Alert>
        }
        {
          (loaded && !documentos.length) && <Alert
            fullWidth
            severity="info"
            variant="filled"
            style={{marginTop: 5}}
          >
            No se encontrarón coincidencias
          </Alert>
        }
        <div className={classes.items}>
          {
            documentos
              .filter(i => tipoSelected.Id === 0 ? true : i.IdTipo === tipoSelected.Id)
              .map((i, idx) => <Card key={idx} className={classes.card} variant="outlined">
                <CardContent>
                  <Typography variant="subtitle1" style={{fontWeight: 'bold'}}>
                    {i.titulo}
                  </Typography>
                  <Typography variant="subtitle1" style={{fontWeight: 'bold'}}>
                    {i.Correlativo}
                  </Typography>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMore />} className={classes.detalleTitulo}>
                      <Typography variant="button">Detalles</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{display: 'block'}} className={classes.detalle}>
                      <Typography variant="body2">
                        <strong>Sistema de gestión: </strong>{i.entorno.map((j, idx2) => <Chip
                          key={idx2}
                          size="small"
                          color="primary"
                          style={{marginRight: 3}}
                          label={j.SistemaGestion}
                        />)}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Tipo: </strong>{i.tipo}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Fecha de actualización: </strong>{i.fecha}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Edición: </strong>{i.version}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Autor: </strong>{i.Autor}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </CardContent>
                <Box display="flex" justifyContent="space-between" alignItems="center" style={{margin: 10}}>
                  <Button
                    variant="contained"
                    style={{
                      color: '#FFF',
                      background: blue[500]
                    }}
                    startIcon={<PictureAsPdf />}
                    onClick={() => showPDF(i)}
                  >
                    Ver
                  </Button>
                  <IconButton
                    style={{color: i.esFavorito ? '#fcbf49' : '#adb5bd'}}
                    onClick={() => switchFavorito(i.IdSolicitud)}
                  >
                    <Star />
                  </IconButton>
                  {
                    permisosGesDoc.includes("GESDOC.DirectorioImprimir") && <Tooltip title="Imprimir documento">
                      <IconButton onClick={() => printDoc(i.IdSolicitud)}>
                        <Print />
                      </IconButton>
                    </Tooltip>
                  }
                  {
                    (showInformado && i.hasOwnProperty('Leido')) && (
                      <Tooltip title="Marcar como leido">
                        <IconButton
                          className={i.Leido ? classes.success : classes.pending}
                          onClick={() => marcarLeido(i.IdInformado)}
                        >
                          <Visibility />
                        </IconButton>
                      </Tooltip>
                    )
                  }
                  <Tooltip title={i.semaforo.tiempo}>
                    <div className={classes.semaforo} style={{background: i.semaforo.color}}></div>
                  </Tooltip>
                </Box>
              </Card>)
          }
        </div>
      </Grid>
    </Grid>
    <Dialog open={open} onClose={hidePDF} fullScreen>
      <ViewPDF
        downloadable
        docBase64={PDF}
        onClose={hidePDF}
        solicitud={selected}
      />
    </Dialog>
    <Dialog
      fullWidth
      open={dialogFiltros}
      onClose={() => setDialogFiltros(false)}
      maxWidth="md"
    >
      <Filtros
        onFilter={() => filtrarDocs()}
        onClose={() => setDialogFiltros(false)}
      />
    </Dialog>
    <Dialog
      open={dialogPrint}
      onClose={() => setDialogPrint(false)}
      maxWidth="xl"
      fullWidth
    >
      <DialogTitle>Documento</DialogTitle>
      <DialogContent style={{ height: "80vh" }}>
        <iframe src={print} width="100%" height="100%" />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => setDialogPrint(false)}
          style={{background: red[500], color: '#FFF'}}
        >
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
    <ToastContainer />
    <Loading loading={saving} />
  </Layout>
}

export default DirectorioDocumentos
