import React from 'react'
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider,
    KeyboardDatePicker,} from '@material-ui/pickers';
const DatePicker = ({value, onChange, className,label}) => {
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>

            <KeyboardDatePicker
                disableToolbar
                variant = "inline"
                label={label}
                id="date-picker-inline"
                className = {className}
                value = {value!==null?value:Date.now()}
                onChange = {(value) => onChange(value) }
                format="dd/MM/yyyy"
                margin="normal"
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
                >
            </KeyboardDatePicker>
        </MuiPickersUtilsProvider>
    )
}

export default DatePicker
