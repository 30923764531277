import React, { useEffect } from 'react'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { useStoreState } from 'easy-peasy'

const SelectDepartamento = ({value, onChange, error, helperText, initial=null}) => {
  const { deptos } = useStoreState(state => ({
    deptos: state.deptos
  }))
  useEffect(() => {
    if (deptos.length && initial) {
      onChange(deptos.find(i => i.Id === initial))
    }
  }, [deptos])
  return <Autocomplete
    fullWidth
    defaultValue={null}
    options={deptos}
    value={value}
    onChange={(ev, value) => onChange(value)}
    getOptionLabel={i => i.NombreDepartamento}
    renderInput={(params) => <TextField
      {...params}
      fullWidth
      label="Departamento"
      error={error}
      helperText={helperText}
    />}
  />
}

export default SelectDepartamento