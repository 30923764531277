import { Backdrop, CircularProgress, Paper, Tooltip } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { Delete, Edit, ViewList } from "@material-ui/icons";

import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import "devextreme/dist/css/dx.material.teal.light.compact.css";
import { NavLink } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Organigrama from "../../organizacion/components/organigrama-componente";
import API from "../../utils/api";
import { isSignedIn, signOut } from "../../utils/auth";
import { validaPermisoPaginaActual } from "../../utils/helpers";

const width_proportion = "100%";
const titleColor = "#2B3C4D";

const useStyles = makeStyles((theme) => ({
  paperContent: {
    padding: "6px 16px",
  },
  table: {
    width: "100%",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 210,
  },
  textFieldDireccion: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 230,
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  greenAvatar: {
    fontSize: "2.5em",
    color: "#fff",
    // borderStyle:'solid',
    // borderWidth:'1 !important',
    // borderColor:'#3f51b5 !important',
    backgroundColor: green[500],
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  paperCard: {
    padding: 20,
    width: "100%",
  },
  formControlSelects: {
    // margin: theme.spacing(1),
    minWidth: 400,

    marginBottom: 10,
    marginTop: 10,
    marginLeft: 30,
  },
  formControlSelect: {
    // margin: theme.spacing(1),
    width: width_proportion,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  rootTabla: {
    width: "100%",
    marginTop: theme.spacing.unit * 1,
    overflowX: "auto",
  },
  rootOrg: {
    width: "100%",
    marginTop: theme.spacing.unit * 1,
    minHeight: 900,
    // maxWidth:600,
    // overflowX: "auto",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paperModal: {
    position: "absolute",
    //width: "70%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));
const OrgranigramaPuesto = (props) => {
  const classes = useStyles();
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors: fieldsErrors },
    register,
  } = useForm();

  let applyFilterTypes = [
    {
      key: "auto",
      name: "Immediately",
    },
    {
      key: "onClick",
      name: "On Button Click",
    },
  ];

  const [userName, setUserName] = useState("");

  const [buscando, setBackDrop] = useState(false);
  const [selected, setSelected] = useState(null);
  const [codigoNotificacion, setCodigoNotificacion] = useState(0);
  const [idFila, setIdFila] = useState(0);

  const [organigramaNodos, setOrganigramaNodos] = useState([]);
  const [organigrama, setOrganigrama] = useState(null);
  const [maestroSubNivelPuestoLaboral, setMaestroSubNivelPuestoLaboral] =
    useState([]);
  const [idPuesto, setIdPuesto] = useState(null);

  //En ciertos escenarios no se carga, como si se genera organigrama desde el mismo descriptor de puesto
  const [cargarBotonDescriptor, setCargarBotonDescriptor] = useState(false);

  const handleCerrarBackDrop = () => {
    setBackDrop(false);
  };
  const handleAbrirBackDrop = () => {
    setBackDrop(true);
  };

  useEffect(() => {
    const cargar = async () => {
      // CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
      const llaveAgrupacion = "Menu.Principal.Web";
      const llavePagina = "Menu.Principal.Web.PuestoLaboral";
      await validaPermisoPaginaActual(llaveAgrupacion, llavePagina);
      // FIN DE CONSULTA DE PERMISOS
      if (
        props.codigoPuestoLaboral !== undefined &&
        props.codigoPuestoLaboral !== null
      ) {
        handleAbrirBackDrop();
        await cargarMaestroSubNivelPuestoLaboral();
        await cargaOrganigrama();
        //await cargarMaestroExpertis();
        //await cargarMaestroEducacion();
        //await cargarMaestroResponsabilidadPuestoLaboral();
        handleCerrarBackDrop();
      }
    };

    cargar();
  }, [props.codigoPuestoLaboral]);

  useEffect(() => {
    if (props.cargarBotonDescriptor === false) {
      setCargarBotonDescriptor(false);
    }
  }, []);

  const cargaOrganigrama = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();
      console.log(props);
      let uri =
        "api/Organizacion/ArbolPuesto/" +
        props.codigoPuestoLaboral +
        (props.marcarNodoActual === true ? "/true" : "");

      // console.log("AQUIIIIIIIIIIIIIIIIIIIIIIIII");
      // console.log(props.codigoPuestoLaboral);
      // console.log(uri);

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setOrganigrama(request.data.ficha);
        setOrganigramaNodos(request.data.lista);
        //setIdPuesto(props.puestoLaboral.CodigoPuestoLaboral);
        console.log(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cargarMaestroSubNivelPuestoLaboral = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/SubNivelPuestoLaboral/";

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setMaestroSubNivelPuestoLaboral(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const [dialogoAbierto, abrirDialogo] = useState(false);
  const handleAbrirDialogoEliminar = (id) => {
    abrirDialogo(true);

    //setCodigoPuestoLaboral(id);
  };

  const getNotificacion = async () => {
    let session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    }
    try {
      handleAbrirBackDrop();
      let codigo = codigoNotificacion; //session.codigoUsuario;

      const request = await API.fetchGetRequest(
        `api/ResponsabilidadPuestoLaboral/${codigo}`
      );

      if (request.data == null) {
        return;
      }

      setSelected(request.data.ficha);
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  useEffect(() => {
    if (codigoNotificacion > 0) {
      getNotificacion();
    }
  }, [codigoNotificacion]);

  return (
    <React.Fragment>
      <Backdrop className={classes.backdrop} open={buscando}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer />

      <Paper className={classes.rootOrg}>
        <Organigrama
          nodos={organigramaNodos}
          maestroSubNivelPuestoLaboral={maestroSubNivelPuestoLaboral}
          handleAbrirBackDrop={handleAbrirBackDrop}
          handleCerrarBackDrop={handleCerrarBackDrop}
          cargarBotonDescriptor={cargarBotonDescriptor}
          imprimir={props.imprimir !== undefined ? props.imprimir : false}
          codigoPuestoLaboral={props.codigoPuestoLaboral}
          //idPuesto={idPuesto}
        />
      </Paper>
    </React.Fragment>
  );
};

export default OrgranigramaPuesto;
