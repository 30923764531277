import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";
import Loader from "react-loader-spinner";
import Popover from "@material-ui/core/Popover";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Modal from "@material-ui/core/Modal";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import AppsIcon from "@material-ui/icons/Apps";
import FolderIcon from "@material-ui/icons/Folder";
import ViewListIcon from "@material-ui/icons/ViewList";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import SearchIcon from "@material-ui/icons/Search";
import { Chip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

import axios from "axios";

//Icons
import GetAppIcon from "@material-ui/icons/GetApp";
import BarChartIcon from "@material-ui/icons/BarChart";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CloseIcon from "@material-ui/icons/Close";

//Accordeon
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

//Scroll bar
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";

//Appbar
import { fade } from "@material-ui/core/styles";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Divider from "@material-ui/core/Divider";

//Own components
import Layout from "../../layout/containers/Layout";
import ButtonAdd from "../../helpers/components/button-add";
import API from "../../utils/api";
import { isSignedIn, signOut } from "../../utils/auth";
import { convertToYearMonthDay } from "../../utils/helpers";
import CardAvisos from "../../ejecucion-avisos/components/card-aviso";
import { validaPermisoPaginaActual } from "../../utils/helpers";
import Dashboard from "./dashboard";
import {
  clearSuperfiltroHistory,
  getSuperfiltroHistory,
  setSuperfiltroHistory,
} from "../../utils/historialSuperfiltro";
//Lista Etiquetas
import TagList from "../../avisos-objects/components/tag-list";
import SecondNavigation from "../components/second-navigation";
import GenericButtonFloat from "../../helpers/components/generic-button-float";
import ResultTablePagination from "../components/result-table-pagination";
import DevExpressDataGrid from "../components/devexpress-data-grid";

import Tooltip from "@material-ui/core/Tooltip";

import moment from "moment";

//CSS
import "../../ejecucion-avisos/components/gestion-style.css";
import { DATE_COL_DEF, removeUndefinedProps } from "@material-ui/data-grid";
import { registrarAnalitica } from "../../utils/firebase-config";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
  },
  paper: {
    position: "relative",
    width: "100%", //theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  paperUserModal: {
    position: "absolute",
    width: "75%",
    height: "600px",
    maxHeight: "600px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  popover: {
    pointerEvents: "none",
  },
  paperPopover: {
    padding: theme.spacing(1),
    background: "#000",
    color: "#fff",
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
  rootExpansionPanel: {
    width: "100%",
    marginTop: 30,
    marginBottom: 50,
  },
  ExpansionPanelRow: {},
  alignCenter: {
    alignItems: "center",
  },
  dowloadButton: {
    backgroundColor: "white",
    color: "#2196F3",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#f3f3f3",
      color: "#2196F3",
    },
  },
  titleGestiones: {
    display: "flex",
    flexDirection: "row",
  },
  heading: {
    marginLeft: 15,
  },
  secondNavigationModal: {
    position: "absolute",
    width: "50%",
    height: "400px",
    maxHeight: "400px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
  drawer: {
    width: 300,
    maxWidth: 300,
  },
});

const titleColor = "#2B3C4D";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

class SuperFiltro extends Component {
  state = {
    esClinica: false,
    codigoClinica: 419,
    tipo: 0,
    grupoGestionesResult: [],
    listaGestiones: [],
    listaUsuarios: [],
    listaTipoGestion: [],

    value: 0,
    openDrawer: false,
    codigoUsuarioActual: 0,
    usuarioActual: "",

    fechaInicio: null,

    fechaFin: null,
    codigoGestion: "",
    codigoUsuarioCreador: 0,
    codigoUsuarioAsignado: 0,
    tipoGestion: 0,
    texto: "",
    pendientes: true,
    terminados: true,
    slaVencido: true,
    slaVigente: true,

    codigoUsuarioActual: null,
    usuarioActual: null,
    usuarioCreadorObj: null,
    usuarioAfectado: null,
    buscando: false,
    buscandoMensaje: "",

    codigoTipoGestion: null,
    objTipoGestionSeleccionado: null,
    tipoGestionActual: null,

    listaGestionesCarpeta: [],
    carpetaSelected: null,

    expanded: false,

    //Estado de Dashboard de respuestas.
    detalleActivo: "gestiones", //'gestiones', 'dashboard'
    isLoadingDashboard: true,
    dashboardDatos: [],
    fechaVencimientoInicio: null,
    fechaVencimientoFin: null,

    //Etiquetas
    etiquetas: [],
    listaFamiliasEtiquetas: [],
    listaEtiquetas: [],
    listaEtiquetasCopia: [],
    listaEtiquetasAsignadas: [],
    objFamilia: {
      CodigoFamiliaEtiqueta: 0,
      Nombre: "Todas",
      Descripcion: "",
      Activo: true,
    },
    codigoEtiqueta: null,
    openModalEtiquetas: false,
    textoBusquedaEtiqueta: "",
    codigoFamiliaEtiquetaSeleccionada: 0,

    // historial
    historial: null,

    //navegación de carpetas
    openNavegador: false,

    //layout
    openToolbar: true,

    //Tipo de visualización
    visualizationType: "list", //detail | list
  };

  async componentDidMount() {
    //CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
    const llaveAgrupacion = "Menu.Principal.Web";
    const llavePagina = "Menu.Principal.Web.Gestiones";
    await validaPermisoPaginaActual(llaveAgrupacion, llavePagina);
    //FIN DE CONSULTA DE PERMISOS
    registrarAnalitica('Filtro_Avanzado');
    const session = await isSignedIn();

    let fechaInicio = new Date();
    fechaInicio.setDate(fechaInicio.getDate() - 30);

    if (session.response) {
      const requestCodGestionClinica = await API.fetchGetRequest(
        `api/fichaClinica/getCodigoProblemaClinica`
      );
      const verificarGestionClinica = requestCodGestionClinica.data.data;

      if (
        this.props.match.params.tipoGestion !== undefined &&
        this.props.match.params.tipoGestion !== null
      ) {
        console.log("codigoTipoGestion", this.props.match.params.tipoGestion);
        await this.setState({
          codigoTipoGestion: this.props.match.params.tipoGestion,
          esClinica:
            verificarGestionClinica.toString() ===
            this.props.match.params.tipoGestion,
        });
      }

      this.setState(
        {
          codigoClinica: verificarGestionClinica,
          codigoUsuarioActual: session["codigoUsuario"],
          //codigoUsuarioAsignado: parseInt(session["codigoUsuario"]),
          codigoUsuarioAsignado: 0,
          usuarioAfectado: parseInt(session["codigoUsuario"]),
          fechaInicio: convertToYearMonthDay(fechaInicio),
          fechaFin: convertToYearMonthDay(new Date()),
          fechaVencimientoInicio: null,
          fechaVencimientoFin: null,
        },
        async () => {
          await this.cargarListaUsuarios();
          await this.cargarListaTipoGestion();
          //Después de cargar listas verifica si se recibió el código tipo de gestión por URL, si ese es el caso mostrar resultado de la búsqueda
          if (
            this.state.codigoTipoGestion !== null &&
            this.state.codigoTipoGestion !== undefined
          ) {
            if (this.state.codigoTipoGestion === "sharedWithMe") {
              this.setState({
                codigoTipoGestion: 0,
                codigoUsuarioAsignado: parseInt(session["codigoUsuario"]),
              });

              let actual = this.state.listaUsuarios.filter((usuario) => {
                return (
                  usuario.CodigoUsuario === parseInt(session["codigoUsuario"])
                  // parseInt(this.state.codigoUsuarioAsignado)
                );
              });
              if (actual.length < 1) {
                console.log("actual a ver", actual);
              } else {
                actual = actual[0];
                console.log("actual a ver", actual);
              }
              this.setState({
                usuarioActual: actual,
                codigoUsuarioAsignado: actual.CodigoUsuario,
              });
              this.buscar();
            }

            //2022/03/07 arda: se comenta la línea que realiza la búsqueda porque no se desea que al cargar la página se realice una búsqueda de forma automática
            //En lugar de buscar automáticamente, se realiza una limpieza de los controles de formulario de búsqueda
            this.limpiar();
            // this.buscar();
          }

          if (
            this.props.match.params.tipoGestion !== undefined &&
            this.props.match.params.tipoGestion !== null
          ) {
            this.buscar();
          }

          //Etiquetas
          this.cargarListaFamiliaEtiquetas();
          this.cargarListaEtiquetas();

          //Verificamos si tenemos filtros en el historial

          let historial = null; //await getSuperfiltroHistory();
          this.setState({ historial: historial });
          console.log("datos historial", historial);

          if (historial != null && historial.length == undefined) {
            //10/02/2022 - @rda: se comentó este código porque al parecer secohan admitió que esto era una mierda XD
            // this.buscar(true);
          } else {
            this.setState({
              usuarioActual: null,
              codigoUsuarioAsignado: 0,
              codigoUsuarioCreador: 0,
              usuarioCreadorObj: null,
            });
          }
        }
      );
    } else {
      window.location.href = "/login";
    }
  }

  cargarListaUsuarios = async () => {
    let criterio = "x-x";
    const request = await API.fetchGetRequest(`api/Usuario/buscar/${criterio}`);
    try {
      if (Array.isArray(request.data.usuarios)) {
        this.setState(
          {
            listaUsuarios: request.data.usuarios,
          },
          () => {
            //Obtiene usuario actual
            if (
              this.state.codigoTipoGestion !== null &&
              this.state.codigoTipoGestion !== undefined
            ) {
              this.setState({ codigoUsuarioAsignado: 0 });
              return;
            }
            let actual = this.state.listaUsuarios.filter((usuario) => {
              return (
                usuario.CodigoUsuario ===
                parseInt(this.state.codigoUsuarioActual)
              );
            });
            if (actual.length < 1) {
              return;
            } else {
              actual = actual[0];
            }
          }
        );
      } else {
        this.setState({
          listaUsuarios: [],
        });
      }
    } catch (error) {
      console.log("cargarListaTareas error:" + error);
    }
  };

  async cargarListaFamiliaEtiquetas() {
    const request = await API.fetchGetRequest(`api/familiaetiqueta/listar`);
    if (Array.isArray(request.data.data)) {
      let listaFamilias = request.data.data;
      listaFamilias.push({
        CodigoFamiliaEtiqueta: 0,
        Nombre: "Todas",
        Descripcion: "",
        Activo: true,
      });

      this.setState({
        listaFamiliasEtiquetas: listaFamilias,
      });
    }
  }

  async cargarListaEtiquetas() {
    const request = await API.fetchGetRequest(`api/causa/listarTodas`);
    if (Array.isArray(request.data.data)) {
      this.setState({
        listaEtiquetas: request.data.data,
      });
    }
  }

  listarProblemasCarpeta = async (carpeta) => {
    const request = await API.fetchGetRequest(
      "api/carpetaController/listarProblemasCarpeta/" +
        carpeta.Codigo +
        "/" +
        carpeta.Llave
    );
    console.log("Datos carpet", request.data.data);
    if (Array.isArray(request.data.data)) {
      this.setState({
        listaGestionesCarpeta: request.data.data,
        carpetaSelected: carpeta.Codigo,
        openNavegador: false,
      });
      this.removerTipoGestion();
    }
  };

  busquedaEtiqueta = async () => {
    console.log("Búsqueda etiqueta");
    let textoBusquedaEtiqueta = this.state.textoBusquedaEtiqueta;

    // if (textoBusquedaEtiqueta.length > 0) {
    let filtro = this.state.listaEtiquetas.filter((fila) => {
      return (
        fila.name.toLowerCase().includes(textoBusquedaEtiqueta.toLowerCase()) &&
        (this.state.codigoFamiliaEtiquetaSeleccionada === 0
          ? true
          : fila.codigoFamiliaEtiqueta ===
            this.state.codigoFamiliaEtiquetaSeleccionada)
      );
    });
    this.setState({ listaEtiquetasCopia: filtro });
    // } else {
    //   this.setState({listaEtiquetasCopia:this.state.listaEtiquetas});
    // }
  };

  setSearchEtiqueta = (name) => (event) => {
    this.setState({ textoBusquedaEtiqueta: event.target.value }, () => {
      this.busquedaEtiqueta();
    });
  };

  asignarEtiqueta = async (codigoEtiqueta) => {
    let etiquetas = this.state.etiquetas;
    let listaEtiquetasAsignadas = this.state.listaEtiquetasAsignadas;
    let listaEtiquetas = this.state.listaEtiquetas;

    //Verifica si la etiqueta ya existe
    let existente = etiquetas.filter((el) => {
      return el === codigoEtiqueta;
    });

    if (existente.length > 0) {
      toast.info("Se agregó la etiqueta");
    } else {
      //agrega el código de etiqueta seleccionada al array
      etiquetas.push(codigoEtiqueta);

      //busca las etiqueta seleccionada dentro de la lista de etiquetas y le hace push
      let etiquetaObject = listaEtiquetas.filter((el) => {
        return el.id === codigoEtiqueta;
      });
      if (etiquetaObject.length > 0) {
        listaEtiquetasAsignadas = [
          ...listaEtiquetasAsignadas,
          etiquetaObject[0],
        ];
        this.setState({ etiquetas, listaEtiquetasAsignadas });
      }
    }
  };

  eliminarEtiquetaAsignada = async (id) => {
    let etiquetas = this.state.etiquetas;
    let listaEtiquetasAsignadas = this.state.listaEtiquetasAsignadas;

    //Crea nuevo array sin el item que se va a remover
    let etiquetasNuevo = etiquetas.filter((element) => element !== id);
    let listaEtiquetasAsignadasNuevo = listaEtiquetasAsignadas.filter((obj) => {
      return obj.id !== id;
    });

    this.setState({
      etiquetas: etiquetasNuevo,
      listaEtiquetasAsignadas: listaEtiquetasAsignadasNuevo,
    });
  };

  eliminarProblemaSeleccionado = async (id) => {
    let lista = this.state.listaGestionesCarpeta;
    const result = lista.filter((el) => el.idProblema != id);
    this.setState({
      listaGestionesCarpeta: result,
    });
  };

  limpiarFiltroGestiones = async () => {
    this.setState({
      codigoTipoGestion: null,
      openNavegador: false,
      objTipoGestionSeleccionado: null,
      listaGestionesCarpeta: [],
      carpetaSelected: 0,
    });
  };

  cargarListaTipoGestion = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    }
    const request = await API.fetchGetRequest(
      `api/carpetaController/listadoGestiones/${session.codigoUsuario}`
    );

    if (Array.isArray(request.data.asignados)) {
      this.setState({ listaTipoGestion: request.data.asignados }, () => {
        //Obtiene tipo de gestion actual en caso de haber sido enviada por url
        if (
          this.state.codigoTipoGestion !== null &&
          this.state.codigoTipoGestion !== undefined
        ) {
          let actual = this.state.listaTipoGestion.filter((tipo) => {
            return (
              tipo.CodigoProblema === parseInt(this.state.codigoTipoGestion)
            );
          });
          if (actual.length < 1) {
            return;
          } else {
            actual = actual[0];
          }
          this.setState({
            tipoGestionActual: actual,
            tipoGestion: this.state.codigoTipoGestion,
          });
        }
      });
    }
  };

  buscar = async (restaurarFiltro) => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    }

    try {
      this.setState({
        buscando: true,
        buscandoMensaje:
          "Estamos realizando tu búsqueda, esto podría tomar algunos minutos dependiendo del volumen de datos que estás consultando.",
        listaGestiones: [],
        grupoGestionesResult: [],
        detalleActivo: "gestiones",
        openToolbar: false,
      });

      let params = {};
      if (restaurarFiltro === true) {
        let historial = await getSuperfiltroHistory();
        this.setState({ historial: historial });
        console.log("datos historial", historial);
        if (historial != null && historial.length == undefined) {
          params = historial;
          clearSuperfiltroHistory();
        }
      } else {
        console.log("usuario", this.state.codigoUsuarioAsignado);
        params = {
          codigoUsuario: session.codigoUsuario,
          usuarioInserto: session.userName,
          codigoGestion:
            this.state.codigoGestion == "" ? 0 : this.state.codigoGestion,
          texto: this.state.texto,
          codigoTipoGestion:
            this.state.codigoTipoGestion === null
              ? 0
              : this.state.codigoTipoGestion,
          codigoUsuarioAsignado:
            this.state.codigoUsuarioAsignado === null
              ? 0
              : this.state.codigoUsuarioAsignado,
          codigoUsuarioCreador:
            this.state.codigoUsuarioCreador === null
              ? 0
              : this.state.codigoUsuarioCreador,
          pendientes: this.state.pendientes === true ? "1" : "0",
          terminados: this.state.terminados === true ? "1" : "0",
          slaVencido: this.state.slaVencido === true ? "1" : "0",
          slaVigente: this.state.slaVigente === true ? "1" : "0",

          fechaInicio:
            this.state.fechaInicio === null ? "" : this.state.fechaInicio,
          fechaFin: this.state.fechaFin === null ? "" : this.state.fechaFin,

          fechaVencimientoInicio:
            this.state.fechaVencimientoInicio === null
              ? ""
              : this.state.fechaVencimientoInicio,
          fechaVencimientoFin:
            this.state.fechaVencimientoFin === null
              ? ""
              : this.state.fechaVencimientoFin,
          listaTiposGestion: this.state.listaGestionesCarpeta,
          usuarioCreadorObj: this.state.usuarioCreadorObj,
          usuarioActual: this.state.usuarioActual,
          listaEtiquetasAsignadas: this.state.listaEtiquetasAsignadas,
        };
        //Guardamos filtro para recuperarlo al regresar de una gestión
        this.setState({ historial: params });
        setSuperfiltroHistory(params);
      }
      console.log("Parametros", params);
      let uri = "api/aviso/filtro?";

      let etiquetas = this.state.etiquetas;
      etiquetas.forEach((x) => {
        uri += `etiquetas=${x}&`;
      });
      let listaTiposGestion = this.state.listaGestionesCarpeta;
      console.log("Estas son las listas", listaTiposGestion);
      listaTiposGestion.forEach((x) => {
        uri += `listaTipoGestion=${x.idProblema}&`;
      });
      console.log("Parametros", params);
      const request = await API.getWithParamsNoQuestionSymbol(uri, params);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        //El servidor nos devolvio que todo ok.
        if (Array.isArray(request.data.data)) {
          // try {
          //   //reemplaza el valor de la llave FechaVencimiento
          //   for (let i = 0; i < request.data.data.length; i++) {
          //     //Prueba de fechas
          //     // let fecha = new Date("2021-03-19T00:00:00");
          //     // let nuevaFecha = moment(fecha).format("DD/MM/YYYY");
          //     // console.log("nueva fecha", nuevaFecha);
          //     // console.log(
          //     //   "fechaVencimiento original",
          //     //   request.data.data[i].FechaVencimiento
          //     // );
          //     // console.log(
          //     //   "fechaVencimiento con formato",
          //     //   moment(new Date(request.data.data[i].FechaVencimiento)).format(
          //     //     "DD/MM/YYYY"
          //     //   )
          //     // );

          //     request.data.data[i].FechaVencimiento =
          //       request.data.data[i].FechaVencimiento == null
          //         ? null
          //         : moment(
          //             new Date(request.data.data[i].FechaVencimiento)
          //           ).format("DD/MM/YYYY");
          //   }

          // } catch (err) {
          //   console.error("format date ", err);
          // }

          //Encuentra tipos de gestión(problemas) diferentes
          console.log("Respuesta filtro", request.data.data);
          let gruposTipoGestion = [
            ...new Set(request.data.data.map((x) => x.Problema)),
          ];
          gruposTipoGestion = [
            ...new Set(gruposTipoGestion.map((x) => x.CodigoProblema)),
          ];

          //crea array de objetos tipo problema "Tipo de gestión"
          let listaObjetosTipoGestion = [];
          await gruposTipoGestion.map((item, i) => {
            let gestiones = request.data.data.filter((element) => {
              return element.CodigoProblema === item;
            });
            if (gestiones.length > 0) {
              let problemaObject = gestiones[0].Problema;
              problemaObject.Gestiones = gestiones;
              listaObjetosTipoGestion.push(problemaObject);
            }
          });

          this.setState({
            grupoGestionesResult: listaObjetosTipoGestion,
            listaGestiones: request.data.data,
          });

          console.log("Grupos", gruposTipoGestion);
          console.log("Grupos Objeto", listaObjetosTipoGestion);
        }

        //Enviar al server las imagenes/documentos del aviso.
        // toast.info("Filtro aplicado exitosamente");
        //this.props.history.push("/bandeja");
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ buscando: false, buscandoMensaje: "" });
    }
  };

  limpiar = () => {
    const codigoTipoGestion = this.props.match.params.tipoGestion;
    const { fechaInicio, fechaFin } = this.state;
    this.setState({
      // fechaInicio:
      //   codigoTipoGestion !== null && codigoTipoGestion !== 0
      //     ? fechaInicio
      //     : "",
      // fechaFin:
      //   codigoTipoGestion !== null && codigoTipoGestion !== 0 ? fechaFin : "",
      fechaInicio: null,
      fechaFin: null,
      fechaVencimientoInicio: "",
      fechaVencimientoFin: "",
      codigoGestion: "",
      texto: "",
      objTipoGestionSeleccionado: null,
      codigoTipoGestion:
        codigoTipoGestion !== null && codigoTipoGestion !== 0
          ? codigoTipoGestion
          : "",
      listaEtiquetasAsignadas: [],
      listaGestionesCarpeta: [],
      etiquetas: [],
      usuarioActual: null,
      codigoUsuarioAsignado: 0,
      codigoUsuarioCreador: 0,
      usuarioCreadorObj: null,
      // pendientes:
      //   codigoTipoGestion !== null &&
      //   codigoTipoGestion !== 0 &&
      //   codigoTipoGestion !== undefined
      //     ? 1
      //     : 0,
      // terminados:
      //   codigoTipoGestion !== null &&
      //   codigoTipoGestion !== 0 &&
      //   codigoTipoGestion !== undefined
      //     ? 1
      //     : 0,
      // slaVencido:
      //   codigoTipoGestion !== null &&
      //   codigoTipoGestion !== 0 &&
      //   codigoTipoGestion !== undefined
      //     ? 1
      //     : 0,
      // slaVigente:
      //   codigoTipoGestion !== null &&
      //   codigoTipoGestion !== 0 &&
      //   codigoTipoGestion !== undefined
      //     ? 1
      //     : 0,

      buscandoMensaje: "",
    });

    // this.setState({
    //   fechaInicio: null,
    //   fechaFin: null,
    //   fechaVencimientoInicio:  null,
    //   fechaVencimientoFin:  null,
    // });
  };

  handleChange = (name) => (event) => {
    if (
      name == "pendientes" ||
      name == "terminados" ||
      name == "slaVencido" ||
      name == "slaVigente"
    ) {
      this.setState({ [name]: event.target.checked });
    } else {
      this.setState({
        [name]: event.target.value,
      });
    }
  };

  handleChangeCheck = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  handlePopoverOpen = (event, textoPopover) => {
    this.setState({
      anchorEl: event.currentTarget,
      popOverOpen: true,
      textoPopover: textoPopover,
    });
  };

  handlePopoverClose = () => {
    this.setState({ anchorEl: null, popOverOpen: false, textoPopover: "" });
  };

  handleChangeExpandAccordeon = (panel) => (event, isExpanded) => {
    this.setState({
      expanded: isExpanded ? panel : false,
    });
    // setExpanded(isExpanded ? panel : false);
  };

  descargar = async (listaGestiones) => {
    const auth = await isSignedIn();
    try {
      var body = {
        ids: listaGestiones,
      };

      const filename = "Gestiones";
      const baseApi = await API.baseApi();
      axios(
        baseApi + "api/aviso/reporte/descargar/porCodigos",
        {
          method: "POST",
          data: body,
          responseType: "blob", // important
          headers: { Authorization: "bearer " + auth.accessToken },
        },
        {
          headers: { Authorization: "bearer " + auth.accessToken },
        }
      ).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        // works in IE11
        if (typeof window.navigator.msSaveBlob === "function") {
          window.navigator.msSaveBlob(response.data, `${filename}.xlsx`);
        } else {
          link.setAttribute("download", `${filename}.xlsx`);
          document.body.appendChild(link);
          link.click();
        }
      });
    } catch (e) {}
  };

  mostrarDashboard = async (listaGestiones) => {
    this.setState({
      isLoadingDashboard: true,
      detalleActivo: "dashboard",
    });
    var body = {
      ids: listaGestiones,
    };

    try {
      const request = await API.post(`api/aviso/dashboard/porCodigos`, body);
      if (request.data.response) {
        this.setState({
          dashboardDatos: request.data.data,
        });
      }
    } catch (e) {}

    this.setState({
      isLoadingDashboard: false,
    });
  };

  elegirTipoGestion = (codigo, llave) => {
    let objTipoGestion = this.state.listaTipoGestion.filter((el) => {
      return el.CodigoProblema === codigo;
    });
    if (!Array.isArray(objTipoGestion) && objTipoGestion.length < 1) {
      return;
    }
    let objTipoGestionSeleccionado = objTipoGestion[0];
    this.setState({
      codigoTipoGestion: codigo,
      openNavegador: false,
      objTipoGestionSeleccionado,
      listaGestionesCarpeta: [],
      carpetaSelected: 0,
    });
  };

  removerTipoGestion = () => {
    this.setState({
      objTipoGestionSeleccionado: null,
      codigoTipoGestion: null,
    });
  };

  handleDrawerOpen = async () => {
    this.setState({ openToolbar: true });
    let historial = this.state.historial;
    if (historial != null && historial.length == undefined) {
      console.log("console historial", historial);

      this.setState({
        fechaInicio: historial.fechaInicio,
        fechaFin: historial.fechaFin,
        fechaVencimientoInicio: historial.fechaVencimientoInicio,
        fechaVencimientoFin: historial.fechaVencimientoFin,
        codigoGestion:
          historial.codigoGestion === 0 ? null : historial.codigoGestion,
        texto: historial.texto,
        codigoTipoGestion:
          historial.codigoTipoGestion === 0
            ? null
            : historial.codigoTipoGestion,
        codigoUsuarioAsignado:
          historial.codigoUsuarioAsignado === 0
            ? null
            : historial.codigoUsuarioAsignado,
        codigoUsuarioCreador:
          this.state.codigoUsuarioCreador === 0
            ? null
            : historial.codigoUsuarioCreador,
        codigoUsuarioAsignado: historial.codigoUsuarioAsignado,
        listaGestionesCarpeta: historial.listaTiposGestion,
        usuarioCreadorObj: historial.usuarioCreadorObj,
        usuarioActual: historial.usuarioActual,
        pendientes: historial.pendientes == 0 ? false : true,
        terminados: historial.terminados == 0 ? false : true,
        slaVencido: historial.slaVencido == 0 ? false : true,
        slaVigente: historial.slaVigente == 0 ? false : true,
        listaEtiquetasAsignadas: historial.listaEtiquetasAsignadas,
      });
    } else {
      console.log("No hay historial", historial);
    }
  };

  handleDrawerClose = () => {
    this.setState({ openToolbar: false });
  };

  toggleConfigurationDrawer = (side, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    this.setState({ [side]: open });
  };

  redireccionar = (codigoGestion) => {
    this.props.history.push(`/gestion/${codigoGestion}`);
    //window.location.href="/gestion/" + codigoGestion;
  };

  render() {
    const { classes } = this.props;

    const { detalleActivo, dashboardDatos, isLoadingDashboard, esClinica } =
      this.state;

    return (
      <Layout
        titulo={esClinica ? "Consultas Médicas" : "Filtros avanzados"}
        maxWidth="xl"
      >
        {/* <Container maxWidth={"xl"} disableGutters={true} > */}
        <div style={{ width: "100%", maxWidth: "100% !important" }}>
          <Paper style={{ width: "100%", maxWidth: "100% !important" }}>
            <AppBar
              position="static"
              color="default"
              style={{ width: "100% !important" }}
            ></AppBar>
            <div
              style={{ width: "100% !important" }}
              // axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              // index={this.state.valorTab}
              // onChangeIndex={this.handleChangeIndexTabs}
            >
              <Grid container spacing={3} style={{ width: "100% !important" }}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <div
                    className={classes.root}
                    style={{ width: "100% !important" }}
                  >
                    <CssBaseline />
                    <AppBar
                      position="sticky"
                      className={clsx(classes.appBar, {
                        [classes.appBarShift]: this.state.openToolbar,
                      })}
                      style={{ backgroundColor: titleColor }}
                    >
                      <Toolbar>
                        <Typography
                          variant="h6"
                          noWrap
                          style={{ display: "flex", flexGrow: 1 }}
                        >
                          {`Resultado de la búsqueda`}
                        </Typography>

                        <Tooltip
                          title={"Abrir panel de búsqueda"}
                          aria-label="Abrir panel de búsqueda"
                        >
                          <IconButton
                            aria-label="Abrir panel de búsqueda"
                            style={{ color: "white" }}
                          >
                            <SearchIcon onClick={this.handleDrawerOpen} />
                          </IconButton>
                        </Tooltip>

                        <Tooltip
                          title={
                            this.state.visualizationType === "list"
                              ? "Cambiar a vista de lista"
                              : "Cambiar a vista de tabla"
                          }
                          aria-label="cambiar vista"
                        >
                          <IconButton
                            aria-label="Cambiar vista"
                            style={{ color: "white" }}
                          >
                            {this.state.visualizationType === "list" ? (
                              <ViewListIcon
                                onClick={() => {
                                  this.setState({
                                    visualizationType: "detail",
                                  });
                                }}
                              />
                            ) : (
                              <ViewModuleIcon
                                onClick={() => {
                                  this.setState({ visualizationType: "list" });
                                }}
                              />
                            )}
                          </IconButton>
                        </Tooltip>
                      </Toolbar>
                    </AppBar>
                    <Drawer
                      className={classes.drawer}
                      variant="temporary"
                      anchor="right"
                      open={this.state.openToolbar}
                      onClose={this.toggleConfigurationDrawer(
                        "openToolbar",
                        false
                      )}
                      classes={{
                        paper: classes.drawerPaper,
                      }}
                    >
                      <div
                        className={classes.drawerHeader}
                        style={{ backgroundColor: titleColor, color: "#fff" }}
                      >
                        <IconButton
                          // onClick={this.handleDrawerClose}
                          onClick={this.toggleConfigurationDrawer(
                            "openToolbar",
                            false
                          )}
                        >
                          {this.state.openToolbar === true ? (
                            <CloseIcon style={{ color: "#fff" }} />
                          ) : (
                            <ChevronRightIcon />
                          )}
                        </IconButton>
                        <strong>Filtros</strong>
                      </div>
                      <Divider />

                      <div style={{ padding: 20, width: 400, maxWidth: 400 }}>
                        {/* <PerfectScrollbar
                          style={{
                            width: "100%",
                            height: 375,
                            maxHeight: 375,
                          }}
                        > */}
                        <div>
                          <Grid
                            container
                            spacing={1}
                            style={{ margin: "0 !important" }}
                          >
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <TextField
                                id="fechaInicio"
                                label="Fecha inicio"
                                type="date"
                                //defaultValue={new Date()-10}
                                style={{ width: "100%" }}
                                value={this.state.fechaInicio}
                                onChange={this.handleChange("fechaInicio")}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <TextField
                                id="fechaFin"
                                label="Fecha fin"
                                type="date"
                                //defaultValue={new Date()}
                                style={{ width: "100%" }}
                                value={this.state.fechaFin}
                                onChange={this.handleChange("fechaFin")}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            spacing={1}
                            style={{ paddingLeft: 0, margin: "0 !important" }}
                          >
                            {!esClinica && (
                              <>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                  <TextField
                                    id="fechaVencimientoInicio"
                                    label="Fecha vencimiento inicio"
                                    type="date"
                                    //defaultValue={new Date()-10}
                                    style={{ width: "100%" }}
                                    value={this.state.fechaVencimientoInicio}
                                    onChange={this.handleChange(
                                      "fechaVencimientoInicio"
                                    )}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                  <TextField
                                    id="fechaVencimientoFin"
                                    label="Fecha vencimiento fin"
                                    type="date"
                                    //defaultValue={new Date()}
                                    style={{ width: "100%" }}
                                    value={this.state.fechaVencimientoFin}
                                    onChange={this.handleChange(
                                      "fechaVencimientoFin"
                                    )}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                </Grid>
                              </>
                            )}
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                              id="txtCodigoGestion"
                              label={
                                !esClinica
                                  ? "Código de gestión"
                                  : "Código de Consulta"
                              }
                              type="number"
                              // variant="outlined"
                              className={classes.textField}
                              value={this.state.codigoGestion}
                              onChange={this.handleChange("codigoGestion")}
                              margin="normal"
                              inputProps={{ maxLength: 5 }}
                              style={{ width: "100%" }}
                            />
                          </Grid>

                          {!esClinica && (
                            <>
                              <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextField
                                  id="txtTexto"
                                  label="Texto"
                                  // variant="outlined"
                                  className={classes.textField}
                                  value={this.state.texto}
                                  onChange={this.handleChange("texto")}
                                  margin="normal"
                                  inputProps={{ maxLength: 512 }}
                                  style={{ width: "100%" }}
                                />
                              </Grid>

                              <Grid item xs={12} sm={12} md={12} lg={12}>
                                {/* <Autocomplete
                          className={classes.textField}
                          style={{
                            width: "100%",
                            marginLeft: 10,
                            marginTop: 25,
                          }}
                          options={this.state.listaTipoGestion}
                          autoHighlight
                          getOptionLabel={(option) => option.Nombre}
                          // inputValue={
                          //   Array.isArray(this.state.usuarioActual)
                          //     ? this.state.usuarioActual[0].NombreCompletoUsuario
                          //     : ""
                          // }
                          disabled={false}
                          renderOption={(option) => (
                            <React.Fragment>
                              <span></span>
                              {option.Nombre}
                            </React.Fragment>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Tipo de gestión"
                              variant="outlined"
                            />
                          )}
                          value={this.state.tipoGestionActual}
                          onChange={(event, newInputValue) => {
                            this.setState({
                              tipoGestionActual: newInputValue
                                ? newInputValue
                                : -1,
                              tipoGestion: parseInt(
                                newInputValue ? newInputValue.CodigoProblema : 0
                              ),
                            });
                          }}
                          // onChange={(event, newValue) => {
                          //   this.setState({
                          //     usuarioActual: newValue ? newValue : -1,
                          //     codigoUsuarioAsignado:  newValue? newValue.CodigoUsuario:0,
                          //   });
                          // }}
                        /> */}

                                <div>
                                  <div
                                    style={{
                                      display: "flex",
                                      //justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography variant="h6">
                                      Tipo de gestión
                                    </Typography>
                                    <IconButton
                                      onClick={() => {
                                        this.setState({ openNavegador: true });
                                      }}
                                    >
                                      <FolderIcon color="primary" />
                                    </IconButton>
                                    <IconButton
                                      onClick={() => {
                                        this.limpiarFiltroGestiones();
                                      }}
                                    >
                                      <DeleteIcon color="error" />
                                    </IconButton>
                                  </div>
                                  <div>
                                    {this.state.objTipoGestionSeleccionado !==
                                      null && (
                                      <Chip
                                        key={
                                          "tipoGestion" +
                                          this.state.objTipoGestionSeleccionado
                                            .CodigoProblema
                                        }
                                        style={{ margin: 5 }}
                                        icon={<AppsIcon />}
                                        size="small"
                                        label={
                                          this.state.objTipoGestionSeleccionado
                                            .Nombre
                                        }
                                        color="Primary"
                                        // variant="outlined"
                                        onDelete={() => {
                                          this.removerTipoGestion();
                                        }}
                                      />
                                    )}
                                    {this.state.objTipoGestionSeleccionado ==
                                      null &&
                                      this.state.listaGestionesCarpeta.length ==
                                        0 && (
                                        <Chip
                                          key={"tipoGestionTodas"}
                                          style={{ margin: 5 }}
                                          icon={<AppsIcon />}
                                          size="small"
                                          label={"Todos"}
                                          // color="Primary"
                                          // variant="outlined"
                                        />
                                      )}
                                  </div>
                                  <div>
                                    {this.state.listaGestionesCarpeta.map(
                                      (element, i) => (
                                        <Chip
                                          key={"tag" + element.idProblema}
                                          style={{ margin: 5 }}
                                          icon={<AppsIcon />}
                                          size="small"
                                          label={element.nombreProblema}
                                          color="Primary"
                                          // variant="outlined"
                                          onDelete={() => {
                                            this.eliminarProblemaSeleccionado(
                                              element.idProblema
                                            );
                                          }}
                                        />
                                      )
                                    )}
                                  </div>
                                </div>
                              </Grid>

                              <div>
                                <div
                                  style={{
                                    display: "flex",
                                    //justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <Typography variant="h6">
                                    Etiquetas
                                  </Typography>
                                  <IconButton
                                    onClick={() => {
                                      this.setState({
                                        openModalEtiquetas: true,
                                      });
                                    }}
                                  >
                                    <AddIcon color="primary" />
                                  </IconButton>
                                </div>
                                <div>
                                  {this.state.listaEtiquetasAsignadas.map(
                                    (element, i) => (
                                      <Chip
                                        key={"tag" + element.id}
                                        style={{ margin: 5 }}
                                        icon={<LocalOfferIcon />}
                                        size="small"
                                        label={element.name}
                                        color="Primary"
                                        // variant="outlined"
                                        onDelete={() => {
                                          this.eliminarEtiquetaAsignada(
                                            element.id
                                          );
                                        }}
                                      />
                                    )
                                  )}
                                </div>
                              </div>
                              <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Autocomplete
                                  value={this.state.usuarioActual}
                                  onChange={(event, newValue) => {
                                    this.setState({
                                      usuarioActual: newValue ? newValue : -1,
                                      codigoUsuarioAsignado: newValue
                                        ? newValue.CodigoUsuario
                                        : 0,
                                    });
                                  }}
                                  // inputValue={this.state.usuarioActualInput}
                                  // onInputChange={(event, newInputValue) => {
                                  //   this.setState({usuarioActualInput: newInputValue});
                                  // }}
                                  id="controllable-states-demo"
                                  options={this.state.listaUsuarios}
                                  getOptionLabel={(option) =>
                                    option.NombreCompletoUsuario
                                  }
                                  style={{
                                    width: "100%",
                                    margin: 10,
                                    marginTop: 25,
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Usuario asignado"
                                      // variant="outlined"
                                    />
                                  )}
                                />
                              </Grid>
                            </>
                          )}
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Autocomplete
                              value={this.state.usuarioCreadorObj}
                              onChange={(event, newValue) => {
                                this.setState({
                                  usuarioCreadorObj: newValue ? newValue : -1,
                                  codigoUsuarioCreador: newValue
                                    ? newValue.CodigoUsuario
                                    : 0,
                                });
                              }}
                              // inputValue={this.state.usuarioActualInput}
                              // onInputChange={(event, newInputValue) => {
                              //   this.setState({usuarioActualInput: newInputValue});
                              // }}
                              id="controllable-states-demo"
                              options={this.state.listaUsuarios}
                              getOptionLabel={(option) =>
                                option.NombreCompletoUsuario
                              }
                              style={{
                                width: "100%",
                                margin: 10,
                                marginTop: 25,
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={
                                    esClinica
                                      ? "Paciente"
                                      : "Usuario afectado / solicitante"
                                  }
                                  // variant="outlined"
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={this.state.pendientes}
                                  onChange={this.handleChange("pendientes")}
                                  name="Pendientes"
                                  color="primary"
                                />
                              }
                              label="Pendientes"
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={this.state.terminados}
                                  onChange={this.handleChange("terminados")}
                                  name="terminados"
                                  color="primary"
                                />
                              }
                              label="Terminados"
                            />
                          </Grid>
                          {!esClinica && (
                            <>
                              <Grid item xs={12} sm={12} md={12} lg={12}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={this.state.slaVencido}
                                      onChange={this.handleChange("slaVencido")}
                                      name="slaVencido"
                                      color="primary"
                                    />
                                  }
                                  label="SLA Vencido"
                                />
                              </Grid>
                              <Grid item xs={12} sm={12} md={12} lg={12}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={this.state.slaVigente}
                                      onChange={this.handleChange("slaVigente")}
                                      name="slaVigente"
                                      color="primary"
                                    />
                                  }
                                  label="SLA Vigente"
                                />
                              </Grid>
                            </>
                          )}
                        </div>
                        {/* </PerfectScrollbar> */}
                        <Grid container>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Button
                              variant="contained"
                              style={{
                                backgroundColor: "#69bd4b",
                                marginBottom: 10,
                                marginTop: 10,
                                color: "white",
                                width: "93%",
                              }}
                              className={classes.buttonAdd}
                              onClick={() => {
                                this.buscar();
                              }}
                            >
                              Buscar
                            </Button>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Button
                              variant="contained"
                              style={{
                                backgroundColor: "#e74c3c",
                                marginBottom: 10,
                                marginTop: 10,
                                color: "white",
                                width: "93%",
                              }}
                              className={classes.buttonAdd}
                              onClick={() => {
                                this.limpiar();
                              }}
                            >
                              Limpiar
                            </Button>
                          </Grid>
                        </Grid>
                        <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />
                      </div>
                    </Drawer>
                    <main
                      className={clsx(classes.content, {
                        [classes.contentShift]: this.state.openToolbar,
                      })}
                      style={{ minHeight: 400 }}
                    >
                      <div className={classes.drawerHeader} />
                      <Grid item xs={12} lg={12} sm={12}>
                        {/* AQUI EL CONTENIDO PRINCIPAL DE RESULTADOS */}
                        {/* <PerfectScrollbar
                    style={{
                      width: "100%",
                      height: 375,
                      maxHeight: 375,
                    }}
                  > */}
                        {this.state.buscando ? (
                          <div
                            style={{
                              width: "100%",
                              marginTop: 10,
                              textAlign: "center",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Loader
                              type="Oval"
                              color="#3498db"
                              height="50"
                              width="50"
                              style={{ textAlign: "center" }}
                            />
                            <div>
                              <p
                                style={{
                                  textAlign: "center",
                                  color: "#34495e",
                                  fontSize: 14,
                                }}
                              >
                                {this.state.buscandoMensaje}
                              </p>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}

                        {/* DASHBOARD ANTES DESAPARECIDO */}
                        {detalleActivo === "dashboard" && (
                          <Dashboard
                            onPressAtras={() => {
                              this.setState({ detalleActivo: "gestiones" });
                            }}
                            dashboardDatos={dashboardDatos}
                            isLoading={isLoadingDashboard}
                          />
                        )}

                        {
                          this.state.grupoGestionesResult.length > 0 &&
                          this.state.visualizationType === "detail" ? (
                            this.state.grupoGestionesResult.map((grupo, i) => (
                              <Container maxWidth="md" key={"panel" + i}>
                                <div className={classes.rootExpansionPanel}>
                                  <Accordion
                                    expanded={
                                      this.state.expanded ===
                                      `G${grupo.CodigoProblema}`
                                    }
                                    onChange={this.handleChangeExpandAccordeon(
                                      `G${grupo.CodigoProblema}`
                                    )}
                                  >
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel1bh-content"
                                      id="panel1bh-header"
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          width: "100%",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            width: "100%",
                                          }}
                                        >
                                          <div>
                                            <ButtonAdd
                                              style={{ width: 36, height: 36 }}
                                              onClick={(event) => {
                                                let url = `/aviso/nuevo/${grupo.CodigoProblema}`;
                                                // window.location.href=url;
                                                this.props.history.push(url);
                                              }}
                                            />
                                          </div>
                                          <div>
                                            <Typography
                                              className={classes.heading}
                                            >
                                              {grupo.Nombre + " "}
                                              <span style={{ color: "gray" }}>
                                                {/* ({grupo.Gestiones.length}) */}
                                              </span>
                                            </Typography>
                                            <Button
                                              variant="contained"
                                              size="small"
                                              className={classes.dowloadButton}
                                              startIcon={<GetAppIcon />}
                                              onClick={async () => {
                                                const data =
                                                  grupo.Gestiones.map(
                                                    (m) => m.CodigoAviso
                                                  );
                                                this.descargar(data);
                                              }}
                                            >
                                              Descargar
                                            </Button>

                                            <Button
                                              variant="contained"
                                              size="small"
                                              className={classes.dowloadButton}
                                              startIcon={<BarChartIcon />}
                                              onClick={async () => {
                                                const data =
                                                  grupo.Gestiones.map(
                                                    (m) => m.CodigoAviso
                                                  );
                                                this.mostrarDashboard(data);
                                              }}
                                            >
                                              Respuestas
                                            </Button>
                                          </div>
                                        </div>
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            width: 130,
                                            marginRight: 20,
                                          }}
                                        >
                                          <span style={{ color: "gray" }}>
                                            (
                                            {grupo.Gestiones.length +
                                              " Registro(s)"}
                                            )
                                          </span>
                                        </div>
                                      </div>
                                    </AccordionSummary>
                                    <AccordionDetails
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      {grupo.Gestiones.map((item, index) => (
                                        <CardAvisos
                                          key={"Aviso" + item.CodigoAviso}
                                          CodigoAviso={item.CodigoAviso}
                                          Nombre={item.Titulo}
                                          NombreCarpeta={
                                            // item.Problema.Nombre
                                            item.NombreProblema
                                          }
                                          Fecha={item.FechaInsertoAmigable}
                                          NombreEstado={
                                            // ((item || {}).Estado || {}).Nombre
                                            item.NombreEstado
                                          }
                                          Activo={item.Activo}
                                          Atrasado={item.Atrasado}
                                          SLA={item.SLA}
                                          TiempoTranscurridoHastaCompletada={
                                            item.TiempoTranscurridoHastaCompletada
                                          }
                                          Quedan={
                                            item.TiempoRestante
                                            // item.VencimientoTiempoEsperaEstimado !=
                                            // null
                                            //   ? item.VencimientoTiempoEsperaEstimado
                                            //   : item.CausasTiempoEsperaEstimado !=
                                            //     null
                                            //   ? item.CausasTiempoEsperaEstimado
                                            //   : item.ProblemaTiempoEsperaEstimado
                                          }
                                          recargar={this.buscar}
                                          porcentajeAvance={
                                            item.PorcentajeAvance
                                          }
                                        />
                                      ))}
                                    </AccordionDetails>
                                  </Accordion>
                                </div>
                              </Container>
                            ))
                          ) : (
                            <></>
                          )
                          // !this.state.buscando && (
                          //     <div
                          //       style={{
                          //         fontStyle: "italic",
                          //         color: "#b2bec3",
                          //       }}
                          //     >
                          //       No hay resultados para mostrar.
                          //     </div>
                          //   )
                        }
                        {/* VISTA DE TABLA */}
                        {this.state.listaGestiones.length > 0 &&
                          this.state.visualizationType === "list" && (
                            // <ResultTablePagination
                            //   rows={this.state.listaGestiones}
                            //   redireccionar={this.redireccionar}
                            // />
                            <DevExpressDataGrid
                              filas={this.state.listaGestiones}
                              esClinica={this.state.esClinica}
                            />
                          )}
                        {this.state.listaGestiones.length < 1 &&
                          !this.state.buscando && (
                            <div
                              style={{
                                fontStyle: "italic",
                                color: "#b2bec3",
                              }}
                            >
                              No hay resultados para mostrar.
                            </div>
                          )}
                        {/* </PerfectScrollbar> */}
                      </Grid>
                    </main>

                    {/* <Drawer
                      anchor="right"
                      open={this.state.rightDrawer}
                      onClose={this.toggleConfigurationDrawer(
                        "rightDrawer",
                        false
                      )}
                      className={classes.drawer}
                      classes={{
                        paper: classes.drawerPaper,
                      }}
                    >
                      <AppBar
                        position="sticky"
                        style={{ backgroundColor: titleColor }}
                      >
                        <Toolbar>
                          <Typography variant="h6">
                            Configuración del campo &nbsp;&nbsp;
                          </Typography>
                          <IconButton
                            color="inherit"
                            aria-label="Cerrar"
                            onClick={this.toggleConfigurationDrawer(
                              "rightDrawer",
                              false
                            )}
                          >
                            <CloseIcon style={{ right: 0 }} />
                          </IconButton>
                        </Toolbar>
                      </AppBar>
                      <Paper className={classes.paper}>
                        <Grid container spacing={1}>
                          <Grid
                            item
                            xs={10}
                            lg={10}
                            sm={10}
                            style={{ margin: "auto", paddingTop: 15 }}
                          >
                            <form
                              className={classes.container}
                              noValidate
                              autoComplete="off"
                            >
                              <Grid
                                item
                                xs={12}
                                lg={12}
                                sm={12}
                                style={{ paddingTop: 30 }}
                              >
                                <TextField
                                  disabled
                                  id="txtCodigoCampo"
                                  label="ID Campo"
                                  className={classes.textField}
                                  value={this.state.codigoCampo}
                                  onChange={this.handleChange("codigoCampo")}
                                  margin="normal"
                                  inputProps={{ maxLength: 512 }}
                                />
                              </Grid>

                              
                            </form>
                          </Grid>
                        </Grid>
                      </Paper>
                      <div style={{ marginBottom: "30px" }}></div>
                 
                    </Drawer> */}
                  </div>
                </Grid>
              </Grid>
            </div>
          </Paper>
        </div>
        {/* </Container> */}

        {/* SUPERFILTRO */}
        {/*  */}
        {/* <GenericButtonFloat onClick={this.handleDrawerOpen}>
          <SearchIcon />
        </GenericButtonFloat> */}
        <ToastContainer />
        <Popover
          id="mouse-over-popover"
          className={classes.popover}
          classes={{
            paper: classes.paperPopover,
          }}
          open={this.state.popOverOpen}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          onClose={this.handlePopoverClose}
          disableRestoreFocus
        >
          <Typography>{this.state.textoPopover}</Typography>
        </Popover>

        <Modal
          // style={{ zIndex: 2990 }}
          aria-labelledby="Asignar etiquetas"
          aria-describedby="Asignar etiquetas"
          open={this.state.openModalEtiquetas}
          onClose={() => {
            this.setState({ openModalEtiquetas: false });
          }}
        >
          <div style={getModalStyle()} className={classes.paperUserModal}>
            <PerfectScrollbar style={{ paddingRight: 30 }}>
              <form className={classes.container} noValidate autoComplete="off">
                <Typography variant="h6" gutterBottom>
                  Agregar Etiquetas
                </Typography>

                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Autocomplete
                      className={classes.textField}
                      style={{
                        width: "100%",
                        marginLeft: 10,
                        marginTop: 25,
                      }}
                      options={this.state.listaFamiliasEtiquetas}
                      autoHighlight
                      getOptionLabel={(option) => option.Nombre}
                      disabled={false}
                      renderOption={(option) => (
                        <React.Fragment>
                          <span></span>
                          {option.Nombre}
                        </React.Fragment>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Familia de etiquetas"
                          // variant="outlined"
                        />
                      )}
                      value={this.state.objFamilia}
                      onChange={(event, newInputValue) => {
                        this.setState(
                          {
                            objFamilia: newInputValue ? newInputValue : -1,
                            codigoFamiliaEtiquetaSeleccionada: parseInt(
                              newInputValue
                                ? newInputValue.CodigoFamiliaEtiqueta
                                : 0
                            ),
                          },
                          () => {
                            this.busquedaEtiqueta();
                          }
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextField
                      id="txtTextoBusquedaTag"
                      label="Tag"
                      className={classes.textField}
                      value={this.state.textoBusquedaEtiqueta}
                      onChange={this.setSearchEtiqueta()}
                      margin="normal"
                      inputProps={{ maxLength: 512 }}
                      style={{ width: "100%" }}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} lg={12} sm={12}>
                  <Grid container spacing={1}>
                    <TagList
                      registros={this.state.listaEtiquetasCopia}
                      agregarCausas={this.asignarEtiqueta}
                      closeModal={() => {
                        this.setState({ openModalEtiquetas: false });
                      }}
                    />
                  </Grid>
                </Grid>
              </form>
            </PerfectScrollbar>
          </div>
        </Modal>

        <Modal
          // style={{ zIndex: 2990 }}
          aria-labelledby="Elegir tipo de gestión"
          aria-describedby="Elegir tipo de gestión"
          open={this.state.openNavegador}
          onClose={() => {
            this.setState({ openNavegador: false });
          }}
        >
          <div
            style={getModalStyle()}
            className={classes.secondNavigationModal}
          >
            {/* <PerfectScrollbar> */}
            <form className={classes.container} noValidate autoComplete="off">
              <Typography variant="h6" gutterBottom>
                Elegir tipo de gestión
              </Typography>

              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <SecondNavigation
                    elegirTipoGestion={this.elegirTipoGestion}
                    listarProblemasCarpeta={this.listarProblemasCarpeta}
                  />
                </Grid>
              </Grid>
            </form>
            {/* </PerfectScrollbar> */}
          </div>
        </Modal>
      </Layout>
    );
  }
}
const SuperFiltroContainer = withStyles(styles)(SuperFiltro);
export default withRouter(SuperFiltroContainer);
