import React from 'react'
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Box,
  Typography,
  Tooltip
} from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import { GetApp, Print } from '@material-ui/icons'
import LoadingButton from '../utils/LoadingButton'

const EliminarObsoleto = ({ onClose, onPrint, onDownload, onDelete }) => {
  return <>
    <DialogTitle>Eliminar documento obsoleto</DialogTitle>
    <DialogContent>
      <Typography style={{color: red[500]}}>
        El documento se eliminará definitivamente, puedes descargarlo o imprimirlo
      </Typography>
      <Box display="flex" justifyContent="space-around" style={{margin: "15px 0"}}>
        <Tooltip title="Imprimir documento">
          <IconButton onClick={onPrint}>
            <Print />
          </IconButton>
        </Tooltip>
        <Tooltip title="Descargar documento">
          <IconButton onClick={onDownload}>
            <GetApp />
          </IconButton>
        </Tooltip>
      </Box>
      <LoadingButton
        fullWidth
        variant="contained"
        style={{background: red[500], color: '#FFF'}}
        onClick={onDelete}
      >
        Eliminar definitivamente
      </LoadingButton>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancelar</Button>
    </DialogActions>
  </>
}

export default EliminarObsoleto
