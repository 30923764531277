import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";


//Own components
import Layout from "../../layout/containers/Layout";
import EtiquetaListTable from "../components/etiqueta-skill-table";
import AddButton from "../../helpers/components/add-button-float";
import API from "../../utils/api";
import { isSignedIn } from "../../utils/auth";
import {validaPermisoPaginaActual} from "../../utils/helpers";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%"
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none"
  },
  buttonAdd: {
    margin: theme.spacing.unit
  }
});

class EtiquetaSkillList extends Component {
  state = {
    open: false,
    actionType: "", //Crear, Actualizar
    codigoEtiqueta: "",
    nombreEtiqueta: "",
    activoEtiqueta: "",
    listaEtiquetas: [],
   
  };

  async componentWillMount() {

        //CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
        const llaveAgrupacion = "Menu.Principal.Web";
        const llavePagina = "Menu.Principal.Web.Aprendizaje";
        await validaPermisoPaginaActual(llaveAgrupacion, llavePagina);
        //FIN DE CONSULTA DE PERMISOS

    this.cargarLista();
   
  }
  async cargarLista() {
    const request = await API.fetchGetRequest("api/competencia/etiqueta/listar");

    this.setState({
      listaEtiquetas: request.data.data
    });
  }


  async cargarDatosEdicion(codigo) {
    const request = await API.fetchGetRequest(
      "api/competencia/etiqueta/cargarDatosEdicion/" + this.state.codigoEtiqueta
    );

    this.setState({
      codigoEtiqueta: request.data.data.CodigoEtiqueta,
      nombreEtiqueta: request.data.data.Nombre,
      activoEtiqueta: request.data.data.Activo,
    
    });
  }

  editar = codigo => {
    this.setState(
      {
        codigoEtiqueta: codigo,
        actionType: "Actualizar"
      },
      async () => {
        await this.cargarDatosEdicion();
        this.handleOpenAddModal();
      }
    );

    
  };

  handleOpenAddModal = () => {
      
    this.setState({
      open: true
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
      codigoEtiqueta: "",
      nombreEtiqueta: "",
      activoEtiqueta: "",
      actionType: ""
    });
  };

  handleChange = name => event => {
    if (name == "activoEtiqueta" || name == "activoEtiqueta") {
      this.setState({ [name]: event.target.checked });
    } else {
      this.setState({
        [name]: event.target.value
      });
    }
  };


  guardar= async () => {
    const { nombreEtiqueta } = this.state;

    if (nombreEtiqueta.trim() == "") {
      toast.warn("Ingrese un nombre válido");
      return;
    }

    try {
      let vIsSignedIn = await isSignedIn();
      let palabra = "";
      if (vIsSignedIn.response) {
        let request;
        let data;

        if (this.state.actionType == "Agregar") {
          data = {
            CodigoEtiqueta: this.state.codigoEtiqueta,
            Nombre: this.state.nombreEtiqueta,
            Activo: true,
            CodigoUsuarioOpera:vIsSignedIn.codigoUsuario,
            UsuarioOpera: vIsSignedIn.userName,
            };
          palabra = "grabado";
        } else {
          data = {
            CodigoEtiqueta: this.state.codigoEtiqueta,
            Nombre: this.state.nombreEtiqueta,
            Activo: this.state.activoEtiqueta,
            CodigoUsuarioOpera:vIsSignedIn.codigoUsuario,
            UsuarioOpera: vIsSignedIn.userName,            
          };
          palabra = "editado";
        }

        request = await API.post(`api/competencia/etiqueta/grabarEditar`, data);
        if (request.status != 200) {
          alert(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        }

        if (request.statusCode === 401) {
          //alert("cerrar sesion");
        } else {
          toast.success("El registro se " + palabra + " exitosamente.");
        
          this.handleClose();
          this.cargarLista();
        }
      }
    } catch (e) {
      alert("hubo un error");
      console.log("Este es el error",e)
    }
  };

  
  eliminar = async codigo => {
    let vIsSignedIn = await isSignedIn();

    if (vIsSignedIn.response) {
      const request = await API.post(
        `api/competencia/etiqueta/eliminar/${codigo}/${vIsSignedIn.userName}`
      );
      if (request.status != 200) {
        alert(
          "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
        );
      }

      if (request.statusCode === 401) {
        //alert("cerrar sesion");
      } else {
        toast.success("El registro se eliminó exitosamente.");
        this.cargarLista();
      }
    } else {
      toast.error("La sessión no se encuentra activa");
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <Layout titulo="Etiquetas para Competencias">
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12} sm={12}>
            <EtiquetaListTable
              data={this.state.listaEtiquetas}
              editar={this.editar}
              eliminar={this.eliminar}
            />
          </Grid>
        </Grid>

        <AddButton
          onClick={() => {
            this.setState({ actionType: "Agregar" }, this.handleOpenAddModal());
          }}
        />

        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.open}
          onClose={this.handleClose}
        >
          <div style={getModalStyle()} className={classes.paper}>
            <form className={classes.container} noValidate autoComplete="off">
              <Typography variant="title" gutterBottom>
                {this.state.actionType == "Agregar" ? (
                  <div>Crear Etiqueta</div>
                ) : (
                  <div>Editar Etiqueta</div>
                )}
              </Typography>
              <TextField
                required
                id="txtNombre"
                label="Nombre Etiqueta"
                className={classes.textField}
                value={this.state.nombreEtiqueta}
                onChange={this.handleChange("nombreEtiqueta")}
                margin="normal"
                variant="outlined"
                inputProps={{ maxLength: 512 }}
              />
              {this.state.actionType === "Actualizar" ? (
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.activoEtiqueta}
                        onChange={this.handleChange("activoEtiqueta")}
                        value="activo"
                        color="primary"
                      />
                    }
                    label="Activo"
                  />
                  <br />
                </div>
              ) : (
                <div></div>
              )}
              <Grid container spacing={3}>
                <Grid
                  item
                  xs={12}
                  lg={12}
                  sm={12}
                  
                >
                  <Button
                    variant="contained"
                    style={{ backgroundColor: "#69bd4b", color: "white" }}
                    className={classes.buttonAdd}
                    onClick={() => this.guardar()}
                  >
                    Guardar
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </Modal>
        
        <ToastContainer />
      </Layout>
    );
  }
}
const EtiquetaSkillContainer = withStyles(styles)(EtiquetaSkillList);
export default withRouter(EtiquetaSkillContainer);

