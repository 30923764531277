import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";

import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';

import API from "../../utils/api";
import { isSignedIn } from "../../utils/auth";
import TextField from "@material-ui/core/TextField";

 
const useStyles = makeStyles((theme) => ({
    tituloPaper: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    root : {
        border: `2px solid grey`,
        background: 'linear-gradient(90deg, rgba(121,121,121,1) 0%, rgba(230,230,230,1) 0%, rgba(255,255,255,1) 100%)'
    },
    container: {
      display: "flex",
      flexDirection: "row",
      flex: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    //Estilos de Usuarios Asignados
   avatar: {
        color: "#fff",
        backgroundColor: "green",
    },

  }));
  
export default function Bitacora(props) {
    const classes = useStyles();
    const [codigoTarea, setCodigoTarea] = useState(null);
    const [mensajes, setMensajes] = useState([]);
    const [permisoEditar, setPermisoEditar] = useState(null);  
    const [mensajetxt, setMensajeTxt] = useState(null);

    useEffect(() => {
        setCodigoTarea(props.codigoTarea)
      }, [props.codigoTarea]);

    useEffect(() => {
        setMensajes(props.mensajes)
      }, [props.mensajes]);

    useEffect(() => {
        setPermisoEditar(props.permisoEditar)
    }, [props.permisoEditar]);
    
    const keyPress = async(e)=>{
        if(e.keyCode == 13){
           InsertarMensaje(e.target.value)
        }
     }

    const InsertarMensaje = async(newmessage) => {

        if (newmessage === null || newmessage.trim() === "") {
            return;
        }
        try {
            let vIsSignedIn = await isSignedIn();
            if (vIsSignedIn.response) {
                let request;
                const llavePadre = "AITAvisoTarea"
                const data = {
                    CodigoMensaje: null,
                    CodigoPadre: props.codigoTarea,
                    LlavePadre: llavePadre,
                    Mensaje: newmessage,
                    CodigoUsuarioOpera: vIsSignedIn.codigoUsuario,
                    UsuarioOpera: vIsSignedIn.userName,
                };
                console.log(data)
                request = await API.post(`api/chat/grabarEditar`, data);
                if (request.status != 200) {
                    alert("Hubo un error al guardar el registro, por favor revisa los datos de tu formulario o consulta con un administrador");
                    return;
                }
                if (request.status === 401) {
                    alert("Hubo un error al guardar el registro, por favor revisa los datos de tu formulario o consulta con un administrador");
                    return;
                } else {
                    const requestChat = await API.fetchGetRequest(
                        `api/chat/obtenerMensajes/${props.codigoTarea}/${llavePadre}`
                    );
                    setMensajes(requestChat.data.data);
              }
            }
        } catch (e) {
            alert("hubo un error");
        }finally{
            setMensajeTxt("");
        }
    }
        
  

    const devolverAvatar = (ruta,imagen,nombre) =>{
        if (imagen === null)  {
               return (
              <Avatar
                avatarStyle={{ borderWidth: 2, borderColor: 'white', borderTopLeftRadius: 1, borderStyle:'solid' }}
                alt={nombre}
                className={[classes.avatar, "avatarCircle"]}
              >
                {nombre.substring(0, 2)}
              </Avatar>
            )
        } else{ return(
              <Avatar
                avatarStyle={{ borderWidth: 2, borderColor: 'white', borderTopLeftRadius: 1, borderStyle:'solid' }}
                alt={nombre}
                src={ruta+""+imagen}
                className={[classes.avatar, "avatarCircle"]}
              />
            )
        }
    }

    return(
        <React.Fragment>
            <Typography variant="h5" gutterBottom style = {{textAlign : 'left'}}>
            Bitácora
            </Typography>
            <Paper className={classes.paper}>    
                {mensajes.map(row=>
                    <div style={{marginTop:5}}>
                    <Card className={classes.root} style = {{textAlign : 'left', alignSelf : 'stretch', backgroundColor: "#f6f6f6" }}>
                        <CardHeader
                            avatar={devolverAvatar(row.urlBase,row.perfil,row.NombreCompletoUsuario)}
                            title={row.NombreCompletoUsuario}
                            subheader={"("+row.Fecha +" "+row.Hora+"): "+row.Mensaje}
                        />
                    </Card>
                    </div>   
                )}
                
                <div style={{marginTop:10}}>
                <TextField
                // AQUI VA A IR el ingreso de mensajes
                    className={classes.input}
                    label="Escriba el mensaje"
                    value = {mensajetxt}
                    variant="outlined"
                    fullWidth
                    rows={1}
                    disabled={!permisoEditar}
                    onKeyDown={(e) => {keyPress(e)}}
                    onChange={(e) => setMensajeTxt(e.target.value)}
                    InputProps={{endAdornment: 
                    <Button 
                        variant="contained"
                        color="primary"
                        endIcon={<Icon>send</Icon>}
                        onClick={()=> InsertarMensaje(mensajetxt)}
                        disabled={!permisoEditar}>
                            Enviar
                    </Button>}}
                />
                </div>
            </Paper>
        </React.Fragment>


    );

}