import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";

import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";

import UsuarioInfo from "../../helpers/components/usuario-info";
import UserInfo from "../../usuario-info/containers/user-info";

import API from "../../utils/api";

const useStyles = makeStyles((theme) => ({
  tituloPaper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  gestionGeneral: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  container: {
    margin: 10,
  },
  input: {
    marginTop: 15,
  },
  classChips: {
    textAlign: "left",
    "& > *": {
      margin: theme.spacing(1.5),
    },
  },
}));

function Historico(props) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [historico, setHistorico] = useState([]);

  useEffect(() => {
    const makeRequest = async () => {
      const request = await API.fetchGetRequest(
        `api/aviso/${props.match.params.codigoGestion}/historico`
      );

      if (request.data.response) {
        setHistorico(request.data.historico);
        console.log("HISTORY", request.data.historico);
      }
      setIsLoading(false);
    };
    makeRequest();
  }, []);

  return (
    <div>
      <div class={classes.tituloPaper}>
        <div class={classes.gestionGeneral}>
          <Typography variant="h5" gutterBottom>
            Histórico
          </Typography>
        </div>
      </div>
      <Paper className={classes.paper}>
        <div className={classes.container}>
          <div className={classes.input}>
            <div className={classes.classChips}>
              {isLoading && (
                <div>
                  <CircularProgress />
                </div>
              )}

              {!isLoading && (
                <React.Fragment>
                  {historico.map((row, i) => (
                    <UserInfo userName={row.UserName}>
                      <UsuarioInfo
                        iniciales={row.NombreCompletoUsuario.substring(
                          0,
                          2
                        ).toUpperCase()}
                        imagenPerfil={row.ImagenPerfil}
                        nombre={row.NombreCompletoUsuario}
                        descripcion={`${row.Estado} ${row.Fecha}`}
                        codigoUsuario={row.CodigoUsuario}
                        userName={row.UserName}
                      />
                    </UserInfo>
                  ))}
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </Paper>
    </div>
  );
}

export default withRouter(Historico);
