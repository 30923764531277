import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import { toast } from "react-toastify";
import { withStyles } from "@material-ui/core/styles";

import AddButton from "../../helpers/components/add-button-float";
import TablaMensajes from '../components/tabla-mensajes';
import ModalFormMensaje from '../components/modal-form-mensaje';
import API from "../../utils/api";
import { isSignedIn } from "../../utils/auth";


const styles = (theme) => ({
    container: {
        display: "flex",
        flexWrap: "wrap",
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: "100%",
    },
    paper: {
        position: "absolute",
        width: theme.spacing.unit * 50,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
        outline: "none",
    },
    buttonAdd: {
        margin: theme.spacing.unit,
    },
});

class ListadoMensajes extends Component {

    state = {
        data: [],
        open: false,
        loading: false,
        item: null
    }

    componentDidMount() {
        this.obtenerMensajes();
    }
    
    obtenerMensajes = () => {
        API.fetchGetRequest("api/mensajes-notificaciones").then(response => {
            const { data } = response;
            this.setState({ data });
        });
    }

    editarMensaje = (id) => {
        API.fetchGetRequest(`/api/mensajes-notificaciones/${id}`).then(res => {
            const { data } = res;
            this.setState({ item: data, open: true });
        })
        
    }

    eliminarMensaje = async(id) => {
        const session = await isSignedIn();
        const data = {usuario: session["userName"]};
        API.post(`/api/mensajes-notificaciones/eliminar/${id}`, data).then(res => {
            toast.success("Se ha eliminado este mensaje personalizado");
            this.obtenerMensajes();
        }).catch(error => {
            toast.error("Error al eliminar el mensaje, intentelo de nuevo.")
        })
    }

    openModal = () => {
        this.setState({ open: true, item: null });
    }

    closeModal = () => {
        this.setState({ open: false, item: null });
    }

    crearMensaje = async (body) => {
        if (!body.Mensaje || !body.Tipo) {
            toast.error("Faltan campos")
        } else {
            const session = await isSignedIn();
            body.Tipo = body.Tipo.value;
            body.usuario = session["userName"];

            let request = null;

            if (this.state.item) {
                request = API.post(`/api/mensajes-notificaciones/${this.state.item.Id}`, body);
            } else {
                request = API.post('/api/mensajes-notificaciones', body);
            }

            this.setState({ loading: true });
            request.then(res => {
                toast.success("Mensaje guardado correctamente");
                this.obtenerMensajes();
                setTimeout(() => {
                    this.closeModal();
                }, 1000);
            }).catch(err => {
                toast.error("Error al guardar el mensaje, intentelo de nuevo.")
            }).finally(() => this.setState({ loading: false }))
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <div style={{width: '100%'}}>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={12} sm={12}>
                        <TablaMensajes
                            data={this.state.data}
                            editarMensaje={this.editarMensaje}
                            eliminarMensaje={this.eliminarMensaje}
                            />
                    </Grid>
                </Grid>
                <ModalFormMensaje
                    values={this.state.item}
                    loading={this.state.loading}
                    openModal={this.state.open}
                    onClose={this.closeModal}
                    submit={this.crearMensaje}
                />
                <AddButton
                    onClick={() => this.openModal()}
                />
            </div>
        );
    }
}

const ListadoMensajesContainer = withStyles(styles)(ListadoMensajes);
export default ListadoMensajesContainer;
