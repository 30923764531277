import React, { useState, useEffect } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import Avatar from "@material-ui/core/Avatar";
import API from "../../utils/api";
import { isSignedIn, profileImageChange, signOut } from "../../utils/auth";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "row",
    },
    avatar: {
      color: "white",
      backgroundColor: "#69BD4B",
    },
    nombres: {
      marginLeft: 8,
      display: "flex",
      flexDirection: "column",
    },
    nombreTexto: {
      color: "#3498DB",
      fontSize: 14,
    },
    nombreDescripcion: {
      color: "#5B5B5B",
      fontSize: 12,
    },
  })
);

export default function UsuarioInfo(props) {
  const classes = useStyles();
  const { userName } = props;
  const [foto, setFoto] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        await cargarFoto(userName);
      } catch {}
    }

    fetchData();
  }, []);

  const cargarFoto = async (dpiEmpleado) => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    }

    try {
      let uri = "api/Empleado/Foto/" + dpiEmpleado;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        setFoto(null);
        return false;
      } else {
        if (request.data.data !== null) {
          setFoto(request.data.data);
        }
      }
    } catch (e) {
      setFoto(null);
      console.log(e);
    } finally {
    }
  };

  return (
    <div className={classes.container}>
      {/* {props.imagenPerfil === null ||
      props.imagenPerfil.endsWith("perfil/") ? (
        <Avatar className={classes.avatar}>{props.iniciales}</Avatar>) 
        : (<Avatar src={props.imagenPerfil} className={classes.avatar}>
        </Avatar>
      )} */}
      {foto !== null ? (
        <Avatar
          alt={props.nombre}
          src={foto}
          className={[classes.greenAvatarFoto]}
          style={{
            border: "5px solid lightgray",
          }}
        />
      ) : props.imagenPerfil === null ||
        props.imagenPerfil === undefined ||
        props.imagenPerfil.endsWith("perfil/") ? (
        <Avatar className={classes.avatar}>{props.iniciales}</Avatar>
      ) : (
        <Avatar src={props.imagenPerfil} className={classes.avatar}></Avatar>
      )}
      <div className={classes.nombres}>
        <span className={classes.nombreTexto}>{props.nombre}</span>
        <span className={classes.nombreDescripcion}>{props.descripcion}</span>
      </div>
    </div>
  );
}
