import { CircularProgress, Divider, Icon, Typography, makeStyles } from "@material-ui/core";
import { DataGrid } from "devextreme-react";
import { Column, Paging } from "devextreme-react/data-grid";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API from "../../utils/api";

const ReporteCatGrids = ({ FechaInicio, FechaFin }) => {
    const classes = styles();
    const [reporteGerencia, setReporteGerencia] = useState([]);
    const [reporteMecanizado, setReporteMecanizado] = useState([]);
    const [reporteManual, setReporteManual] = useState([]);
    const [reporteTransportes, setReporteTransportes] = useState([]);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            let toastId = toast.info(
                <div>
                    <span>Generando reportes...</span>{" "}
                    <CircularProgress size={"1.0rem"} style={{ marginBottom: "0px" }} />
                </div>,
                { autoClose: false }
            );
            let uri = `api/asistencia/getReportesCat?inicio=${FechaInicio}&fin=${FechaFin}`;
            const request = await API.fetchGetRequest(uri);
            console.log(request.data.data);
            setReporteGerencia(request.data.data.ReporteGerencia);
            setReporteMecanizado(request.data.data.ReporteCosechaMeca);
            setReporteManual(request.data.data.ReporteCosechaManual);
            setReporteTransportes(request.data.data.ReporteTransporte);
            //setAsistencias(request.data.data.Asistencias);
            //console.log(request.data.data.Asistencias);
            //setNecesitaUbicaciones(true);
            //if (TipoGestion === 650) {
            //  setCargarEmpleadosPorFrente(true);
            // }
            setLoading(false);
            toast.dismiss(toastId);
            console.log(request.data.data);
        };
        fetchData();
    }, [FechaInicio, FechaFin]);

    const cellTemplatePorcentaje = (data) => {
        console.log(data);
        let backgroundColor;
        if (data.value >= 90) {
            backgroundColor = '🟢';
        } else if (data.value >= 70) {
            backgroundColor = '🟡';
        } else {
            backgroundColor = '🔴';
        }

        return (
            <div>
                {data.text}% {backgroundColor}
            </div>
        );
    };

    return (
        loading ? <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress style={{ marginBottom: "20px" }} />
            <br />
            <br />
        </div> :
            <>
                <div>
                    <Divider className={classes.divider} />
                    <Typography variant="h6" component="h6" className={classes.tituloGrid}>
                        Reporte de Gerencia <Icon>assignment_ind</Icon>
                    </Typography>
                    <DataGrid
                        id="repGerencial"
                        dataSource={reporteGerencia}
                        //keyExpr="Id"
                        showColumnLines
                        showRowLines
                        showBorders
                        columnAutoWidth
                    >
                        <Paging enabled={false} />
                        <Column dataField="Proceso" caption="Proceso" />
                        <Column dataField="TotalEmpleados" caption="Total Inventario" />
                        <Column dataField="Asistencias" caption="Asistencias" />
                        <Column dataField="AsistenciasTraslado" caption="Traslados" />
                        <Column dataField="InasistenciasDemasMotivos" caption="Inasistencias" />
                        <Column dataField="AsistenciasDescanso" caption="Descanso Programado" />
                        <Column dataField="Total" caption="Total" />
                        <Column dataField="PorcentajeAsistencia" caption="% Asistencia" cellRender={cellTemplatePorcentaje} />

                    </DataGrid>

                    <Divider className={classes.divider} />

                    <Typography variant="h6" component="h6" className={classes.tituloGrid}>
                        Cosecha Mecanizada
                    </Typography>
                    <DataGrid
                        id="repMeca"
                        dataSource={reporteMecanizado}
                        //keyExpr="Id"
                        showColumnLines
                        showRowLines
                        showBorders
                        columnAutoWidth
                    >
                        <Paging enabled={false} />
                        <Column dataField="Frente" caption="Frente" />
                        <Column dataField="TotalEmpleados" caption="Total Inventario" />
                        <Column dataField="TurnoDiurno" caption="Diurno" />
                        <Column dataField="TurnoMixto" caption="Mixto" />
                        <Column dataField="TurnoNocturno" caption="Nocturno" />
                        <Column dataField="Asistencias" caption="Asistencias" />
                        <Column dataField="AsistenciasTraslado" caption="Traslados" />
                        <Column dataField="InasistenciasDemasMotivos" caption="Inasistencias" />
                        <Column dataField="AsistenciasDescanso" caption="Descanso Programado" />
                        <Column dataField="Total" caption="Total" />
                        <Column dataField="PorcentajeAsistencia" caption="% Asistencia" cellRender={cellTemplatePorcentaje} />
                    </DataGrid>

                    <Divider className={classes.divider} />

                    <Typography variant="h6" component="h6" className={classes.tituloGrid}>
                        Cosecha Manual
                    </Typography>
                    <DataGrid
                        id="repManual"
                        dataSource={reporteManual}
                        //keyExpr="Id"
                        showColumnLines
                        showRowLines
                        showBorders
                        columnAutoWidth
                    >
                        <Paging enabled={false} />
                        <Column dataField="Puesto" caption="Puesto" />
                        <Column dataField="TotalEmpleados" caption="Total Inventario" />
                        <Column dataField="TurnoDiurno" caption="Diurno" />
                        <Column dataField="TurnoNocturno" caption="Nocturno" />
                        <Column dataField="Asistencias" caption="Asistencias" />
                        <Column dataField="InasistenciasDemasMotivos" caption="Inasistencias" />
                        <Column dataField="AsistenciasDescanso" caption="Descanso Programado" />
                        <Column dataField="Total" caption="Total" />
                        <Column dataField="PorcentajeAsistencia" caption="% Asistencia" cellRender={cellTemplatePorcentaje} />
                    </DataGrid>

                    <Divider className={classes.divider} />

                    <Typography variant="h6" component="h6" className={classes.tituloGrid}>
                        Transportes
                    </Typography>
                    <DataGrid
                        id="repTrans"
                        dataSource={reporteTransportes}
                        //keyExpr="Id"
                        showColumnLines
                        showRowLines
                        showBorders
                        columnAutoWidth
                    >
                        <Paging enabled={false} />
                        <Column dataField="Puesto" caption="Puesto" />
                        <Column dataField="TotalEmpleados" caption="Total Inventario" />
                        <Column dataField="TurnoDiurno" caption="Diurno" />
                        <Column dataField="TurnoNocturno" caption="Nocturno" />
                        <Column dataField="Asistencias" caption="Asistencias" />
                        <Column dataField="InasistenciasDemasMotivos" caption="Inasistencias" />
                        <Column dataField="AsistenciasDescanso" caption="Descanso Programado" />
                        <Column dataField="Total" caption="Total" />
                        <Column dataField="PorcentajeAsistencia" caption="% Asistencia" cellRender={cellTemplatePorcentaje} />
                    </DataGrid>
                </div>
            </>
    );
}

const styles = makeStyles((theme) => ({
    divider: {
        marginTop: 30,
        marginBottom: 30,
    },
    tituloGrid: {
        textAlign: "center",
    },
}));

export default ReporteCatGrids;
