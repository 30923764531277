import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Typography, makeStyles } from "@material-ui/core";
import DataGrid, { Column, Editing, Lookup, Texts } from 'devextreme-react/data-grid';
import IconButton from "@material-ui/core/IconButton";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layout from "../../layout/containers/Layout";
import API from "../../utils/api";
import { isSignedIn } from "../../utils/auth";
import PermisosLandmarkModal from "./permisosLandmarkModal";
import { Lock } from "material-ui-icons";

const LandmarksAdminContainer = () => {
    const classes = styles();

    const [showCredentialsModal, setShowCredentialsModal] = useState(false);
    const [landmarkTypes, setLandmarkTypes] = useState([]);
    const [tokenGio, setTokenGio] = useState(null);
    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [credencialesGio, setCredencialesGio] = React.useState({});
    const [listaTipoGestion, setListaTipoGestion] = React.useState([]);
    const [modalPermisoVisible, setModalPermisoVisible] = React.useState(false);
    const [modalPermisoTipoPunto, setModalPermisoTipoPunto] = React.useState(null);

    const verificarTokenGio = async () => {
        const token = localStorage.getItem("tokenGioApi");
        const tokenExpiration = localStorage.getItem("tokenGioApiExpira");
        const tokenExpirationDate = new Date(tokenExpiration * 1000);
        console.log(tokenExpirationDate);
        console.log(token);
        // token comes with text undefined when it is null
        if (token === 'undefined' || tokenExpirationDate < new Date()) {
            // prompt for credentials and use them to get a token
            console.log("token is null or expired");
            setShowCredentialsModal(true);
            return null;
        }
        return token;
    }

    const getLandmarkTypes = async (token) => {
        const landmarkTypes = await API.getLandmarkTypes(token);
        console.log(landmarkTypes);
        setLandmarkTypes(landmarkTypes);
        toast.success("Datos obtenidos correctamente.");
    }


    useEffect(() => {
        verificarTokenGio().then((token) => {
            setTokenGio(token);
            cargarListaTipoGestion();
        });
    }
        , []);

    useEffect(() => {
        getData();
    }, [tokenGio]);

    const getTokenAsync = async () => {
        API.getTokenGio(username, password).then((respuesta) => {
            if (!respuesta.success) {
                toast.error(respuesta.message);
                return;
            } else {
                toast.success(respuesta.message);
                setShowCredentialsModal(false);
                setTokenGio(respuesta.token);
            }
        }
        );
    }

    const getData = async () => {
        if (tokenGio === null) {
            return;
        }
        getLandmarkTypes(tokenGio);
    }

    const cargarListaTipoGestion = async () => {
        const session = await isSignedIn();
        if (!session.response) {
            window.location.href = "/login";
            return;
        }
        const request = await API.fetchGetRequest(
            `api/carpetaController/listadoGestiones/${session.codigoUsuario}`
        );

        if (Array.isArray(request.data.asignados)) {
            setListaTipoGestion(request.data.asignados);
            console.log(request.data.asignados);
        }
    };

    const guardarTipoPunto = async (e) => {
        const session = await isSignedIn();
        console.log(e);
        let data = {
            IdTipoPunto: e.data.IdTipoPunto.toString(),
            Activo: e.data.Activo,
            Color: e.data.Color,
            Descripcion: e.data.Descripcion,
            Icono: e.data.Icono,
            Nombre: e.data.Nombre,
            IdTipoGestion: e.data.IdTipoGestion,
            UsuarioModifico: session.codigoUsuario
        }

        let response = await API.putLandmarkType(tokenGio, data.IdTipoPunto, data);

        if (response.statusCode === 200) {
            toast.success("Datos guardados correctamente.");
        } else {
            toast.error("Error al guardar los datos.");
        }
        console.log(response);
    }

    const crearTipoPunto = async (e) => {
        const session = await isSignedIn();
        console.log(e);
        let data = {
            Activo: e.data.Activo,
            Color: e.data.Color,
            Descripcion: e.data.Descripcion,
            Icono: e.data.Icono,
            Nombre: e.data.Nombre,
            IdTipoGestion: e.data.IdTipoGestion,
            UsuarioCreo: session.codigoUsuario
        }

        let response = await API.postLandmarkType(tokenGio, data);

        if (response.statusCode === 200) {
            toast.success("Landmark creado correctamente.");
        } else {
            toast.error("Error al guardar los datos.");
        }
        console.log(response);
    }

    const handleButtonClick = (e, rowData) => {
        console.log('Button clicked for row:', rowData);
        setModalPermisoTipoPunto(rowData.IdTipoPunto);
        setModalPermisoVisible(true);
      };
      
      const ButtonCell = (props) => {
        const { data } = props;
        return (
        //   <button onClick={(e) => handleButtonClick(e, data)}>
        //    permisos
        //   </button>
        <IconButton
        aria-label="Disponibilidad de habitaciones"
        //style={{ color: "white" }}
      >
        <Lock
          onClick={(e) => handleButtonClick(e, data)}
        />
        </IconButton>
        );
      };
      

    return (
        <Layout titulo={`Administración de landmarks`} maxWidth={"100%"}>
            <React.Fragment>
                <ToastContainer />
                <Box className={classes.paperPerfil} pl={0} pt={0}>
                    <Typography variant="h6" gutterBottom> Administración de tipos de puntos </Typography>
                    <DataGrid
                        dataSource={landmarkTypes}
                        keyExpr="IdTipoPunto" // replace "id" with the actual key field of your data
                        showBorders={true}
                        onRowUpdated={(e) => { guardarTipoPunto(e) }}
                        onRowInserted={(e) => { crearTipoPunto(e) }}

                    >
                        <Editing
                            mode="row"
                            allowUpdating={true}
                            allowDeleting={false}
                            allowAdding={true}
                            useIcons={true}
                        >
                            <Texts saveRowChanges="Guardar" cancelRowChanges="Cancelar" deleteRow="Eliminar" addRow="Agregar" editRow="Editar" />
                        </Editing>
                        <Column dataField="Activo" width='auto' /> {/* replace "field1" with the actual field names of your data */}
                        <Column dataField="Color" />
                        <Column dataField="Descripcion" />
                        <Column dataField="Icono" />
                        <Column dataField="Nombre" />
                        <Column dataField="IdTipoGestion">
                            <Lookup dataSource={[{ CodigoProblema: 0, Nombre: 'Sin gestión' }, ...listaTipoGestion]} valueExpr="CodigoProblema" displayExpr="Nombre" />
                        </Column>
                        <Column
                            caption="Permisos"
                            cellRender={(cellData) => <ButtonCell {...cellData} />}
                            width={100}
                        />

                    </DataGrid>


                    <Dialog open={showCredentialsModal} onClose={() => setShowCredentialsModal(false)} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Credenciales</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Ingresa tus credenciales para acceder al sistema de mapas.
                            </DialogContentText>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="username"
                                label="Usuario"
                                type="text"
                                fullWidth
                                onChange={(e) => setUsername(e.target.value)}
                            />
                            <TextField
                                margin="dense"
                                id="password"
                                label="Contraseña"
                                type="password"
                                fullWidth
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setShowCredentialsModal(false)} color="primary">
                                Cancelar
                            </Button>
                            <Button onClick={() => getTokenAsync()} color="primary">
                                Enviar
                            </Button>
                        </DialogActions>

                    </Dialog>


                </Box>
            </React.Fragment>
            <PermisosLandmarkModal 
            handleClose={()=>{setModalPermisoVisible(false)}}
            open={modalPermisoVisible}
            idTipoPunto={modalPermisoTipoPunto}
            />
        </Layout>
    );
}

const styles = makeStyles((theme) => ({
    paperPerfil: {
        position: "relative",
        width: "100%", //theme.spacing.unit * 50,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 1,
        outline: "none",
    },
    box: {
        position: "absolute",
        width: "100%", //theme.spacing.unit * 50,
        padding: theme.spacing.unit * 1,
        outline: "none",
    },
}));

export default LandmarksAdminContainer;