export const getSuperfiltroHistory = async () => {
    return new Promise((resolve, reject) => {
      let res = [];
      try {
        let SuperfiltroHistory = sessionStorage.getItem("SuperfiltroHistory");
        if (SuperfiltroHistory) {
          res = JSON.parse(SuperfiltroHistory);
        }
        resolve(res);
      } catch (err) {
        reject(res);
        console.error("Error al obtener el historial del superfiltro.", err);
      }
    });
  
   
  };
  
  export const setSuperfiltroHistory = async (newHistory) => {
    try {
      if (newHistory != null) {
        sessionStorage.setItem("SuperfiltroHistory", JSON.stringify(newHistory));
      }
    } catch (err) {
      console.error("Error al asignar el historial del superfiltro.", err);
    }
  };
  
  export const clearSuperfiltroHistory = async()=>{
      try{
          await sessionStorage.removeItem('SuperfiltroHistory');
      }catch(err){
          console.error("No se ha podido remover el historial del superfiltro.");
      }
  
  }
  
