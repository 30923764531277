import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useHistory } from "react-router-dom";

import {
  ExpandLess,
  ExpandMore
} from "@material-ui/icons";

//Componente creado manualmente para poder cargar contenido sin que se vea el refresh de la página
// import History from "../../History";

const useStyles = makeStyles((theme) => {
  let styles = {
    popover: {
      pointerEvents: "none",
    },
    paper: {
      padding: theme.spacing(1),
      background: "#000",
      color: "#fff",
    },
    // drawer: {
    //   [theme.breakpoints.up("sm")]: {
    //     width: drawerWidth,
    //     flexShrink: 0,
    //   },
    // },
    drawerStyle: { backgroundColor: "#2b3c4d" },
    menuText: {
      fontSize: 12
    },
    drawerOption: {
      color: "white"
    },
    // toolbar: {
    //   ...theme.mixins.toolbar,
    //   backgroundColor: "#202e3c",
    //   backgroundImage: "url(/static/logo.png)",
    //   backgroundRepeat: "no-repeat",
    //   backgroundSize: 180,
    //   backgroundPosition: "center",
    // },
    menuCollapse: {
      paddingLeft: theme.spacing(3),
    },
    subMenuCollapse: {
      paddingLeft: theme.spacing(4),
    },
  };
  return styles;
});

export default function OptionComponent(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handlePopoverOpen(event) {
    if (props.estadoBarra === false) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
  }

  function handlePopoverClose() {
    setAnchorEl(null);
  }
  const open = Boolean(anchorEl);

  let history = useHistory();

  return (
    <React.Fragment>
      <ListItem
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        button
        onClick={props.onClick}
        className={props.nivelSubmenu === 2 ? classes.subMenuCollapse : ''}
      >
        <ListItemIcon className={classes.drawerOption}>
          {props.icon}
        </ListItemIcon>

        {/* <ListItemText className={[classes.drawerOption, classes.menuText]} primary={props.label} /> */}
        <ListItemText className={`${classes.drawerOption} ${classes.menuText}`} primary={props.label} />

        {props.collapse === props.label ? (
          <ExpandLess className={classes.drawerOption} />
        ) : (
          <ExpandMore className={classes.drawerOption} />
        )}
      </ListItem>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography>{props.label}</Typography>
      </Popover>
    </React.Fragment>
  );
}
