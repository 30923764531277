import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

//Own Componentes
import './button-add-css.css';

const styles = theme => ({
    fab: {
        margin: theme.spacing.unit,
    }
});

function FloatingActionButtons(props) {
    const { classes } = props;
    return (
        <div className="FloatBottomDiv" onClick={props.onClick}>
            <Fab style={{ backgroundColor: "#69BD4B", color: 'white' }} aria-label="Add" className={classes.fab}>
                <AddIcon />
            </Fab>
        </div>
    );
}

FloatingActionButtons.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FloatingActionButtons);