import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  Grid,
  Typography,
  Card,
  CardContent,
  IconButton,
  makeStyles,
  Dialog,
  Paper,
  InputBase,
  Chip
} from '@material-ui/core'
import { Block, PictureAsPdf, Search, FilterList } from '@material-ui/icons'
import { amber, blue } from '@material-ui/core/colors'
import { Alert } from '@material-ui/lab'
import { ToastContainer, toast } from 'react-toastify'
import { useStoreActions, useStoreState } from 'easy-peasy'

import Layout from '../layout/containers/Layout'
import Navegacion from './componentes/documentos/Navegacion'
import Filtros from './componentes/documentos/Filtros'
import { getPDFSolicitudBase64 } from './utils/media'
import ViewPDF from './componentes/documentos/ViewPDF'

const useStyles = makeStyles(theme => ({
  semaforo: {
    display: 'inline-block',
    height: 32,
    width: 32,
    borderRadius: '50%'
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  items: {
    marginTop: 10,
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(20em, 1fr))',
    gridAutoRows: '1fr',
    gap: 10
  }
}))

const DocumentosObsoletos = () => {
  const classes = useStyles()
  const { obsoletos, filtros } = useStoreState(state => ({
    obsoletos: state.obsoletos,
    filtros: state.filtros
  }))
  const { getMasters, getObsoletos } = useStoreActions(actions => ({
    getMasters: actions.getMasters,
    getObsoletos: actions.getObsoletos
  }))
  const [documentos, setDocumentos] = useState([])
  const [open, setOpen] = useState(false)
  const [dialogFiltros, setDialogFiltros] = useState(false)
  const [selected, setSelected] = useState(null)
  const [search, setSearch] = useState('')
  const [loaded, setLoaded] = useState(false)
  const [PDF, setPDF] = useState(null)
  const selectArea = (codigo) => {
    setDocumentos(obsoletos.filter(i => i.proceso === codigo))
  }
  const selectGerencia = async (codigo) => {
    console.log()
    try{
      await getObsoletos({
        gerencia: codigo,
        nombre: null
      })
      setLoaded(true)
    }catch(error){
      console.log(error)
      toast.error('Ha ocurrido un error al obtener los datos')
    }
  }
  const buscarNombre = () => {
    try {
      if (search.trim().length) {
        getObsoletos({
          gerencia: null,
          nombre: search.trim()
        })
        setLoaded(true)
      }
    } catch (error) {
      toast.error('Ha ocurrido un error al obtener los datos')
    }
  }
  const filtrarDocs = () => {
    let items = [...obsoletos]
    if (filtros.sistema) {
      items = items.filter(i => i.entorno.find(j =>
        j.IdSistemaGestion === filtros.sistema.Id))
    }
    if (filtros.tipo) {
      items = items.filter(i => i.tipo === filtros.tipo.NombreTipoDocumento)
    }
    if (filtros.anho) {
      items = items.filter(i => i.anho === filtros.anho)
    }
    if (filtros.vigencia) {
      items = items.filter(i => i.semaforo.vigencia === filtros.vigencia)
    }
    setDialogFiltros(false)
  }
  const getPDF = async (IdSolicitud) => {
    setPDF(null)
    let base64str = await getPDFSolicitudBase64(IdSolicitud)
    if (base64str) {
      base64str = base64str.replace('data:application/octet-stream;base64,', '')
      setPDF(base64str)
    }
  }
  const showPDF = (item) => {
    setSelected({...item})
    getPDF(item.IdSolicitud)
    setOpen(true)
  }
  const hidePDF = () => {
    setPDF(null)
    setOpen(false)
  }
  useEffect(() => {
    getMasters()
  }, [])
  useEffect(() => {
    setDocumentos(obsoletos)
  }, [obsoletos])

  return <Layout titulo="Directorio de documentos obsoletos" maxWidth="xl">
    <Grid container spacing={3} style={{margin: 10}}>
      <Grid xs={12} md={2} style={{height: '75vh', overflowY: 'scroll'}}>
        <Navegacion
          isObsoletos
          selectArea={selectArea}
          selectGerencia={selectGerencia}
        />
      </Grid>
      <Grid xs={12} md={10}>
        <Box display="flex" justifyContent="flex-end" style={{
            boxShadow: '0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%), 0 3px 1px -2px rgb(0 0 0 / 20%)',
            padding: 10,
            background: '#E5E5E5'
          }}>
          <IconButton onClick={() => setDialogFiltros(true)}>
            <FilterList />
          </IconButton>
          <Paper className={classes.root}>
            <InputBase
              className={classes.input}
              placeholder="Buscar (título o código)"
              value={search}
              onChange={ev => setSearch(ev.target.value)}
              onKeyPress={ev => ev.key === 'Enter' && buscarNombre()}
            />
            <IconButton
              onClick={buscarNombre}
              className={classes.iconButton}
              aria-label="search"
            >
              <Search />
            </IconButton>
          </Paper>
        </Box>
        {
          !loaded && <Alert fullWidth severity="info" variant="filled">
            Selecciona una gerencia o realiza una busqueda
          </Alert>
        }
        {
          (loaded && !documentos.length) && <Alert fullWidth severity="info" variant="filled">
            No se encontrarón coincidencias
          </Alert>
        }
        <div className={classes.items}>
          {
            documentos.map((i, idx) => <Card key={i.index} variant="outlined">
                <CardContent>
                  <Typography variant="subtitle1" style={{fontWeight: 'bold'}}>
                    {i.titulo}
                  </Typography>
                  <Typography variant="subtitle1" style={{fontWeight: 'bold'}}>
                    {i.Correlativo}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Sistema de gestión: </strong>{i.entorno.map((j, idx2) => <Chip
                      key={idx2}
                      size="small"
                      color="primary"
                      style={{marginRight: 3}}
                      label={j.SistemaGestion}
                    />)}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Tipo: </strong>{i.tipo}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Fecha de actualización: </strong>{i.fecha}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Edición: </strong>{i.version}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Autor: </strong>{i.Autor}
                  </Typography>
                  <Chip
                    icon={<Block />}
                    style={{background: amber[500]}}
                    label="Documento obsoleto"
                  />
                </CardContent>
                <Box display="flex" justifyContent="space-between" alignItems="center" style={{margin: 10}}>
                  <Button
                    variant="contained"
                    style={{
                      color: '#FFF',
                      background: blue[500]
                    }}
                    startIcon={<PictureAsPdf />}
                    onClick={() => showPDF(i)}
                  >
                    Ver
                  </Button>
                </Box>
              </Card>)
          }
        </div>
      </Grid>
    </Grid>
    <Dialog open={open} onClose={hidePDF} fullScreen>
      <ViewPDF
        docBase64={PDF}
        solicitud={selected}
        downloadable
        onClose={hidePDF}
      />
    </Dialog>
    <Dialog
      fullWidth
      open={dialogFiltros}
      onClose={() => setDialogFiltros(false)}
      maxWidth="md"
    >
      <Filtros
        onFilter={() => filtrarDocs()}
        onClose={() => setDialogFiltros(false)}
      />
    </Dialog>
    <ToastContainer />
  </Layout>
}

export default DocumentosObsoletos
