import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import {
  Backdrop,
  Box,
  CircularProgress,
  Divider,
  Tab,
  Tabs,
  TabScrollButton,
  Typography,
} from "@material-ui/core";

import { green } from "@material-ui/core/colors";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
//iconos

//input date

import PropTypes from "prop-types";

//Own components
// import Layout from "../../layout/containers/Layout";

import API from "../../utils/api";
import { isSignedIn, signOut } from "../../utils/auth";

import DescriptorComponent from "../components/descriptor-componente";
import PerfilComponent from "../components/perfil-componente";
import ContenedorAnalisisSalarial from "../containers/analisis-salarial-container";
import ContenedorCompetencias from "../containers/competencias-container";
import ContenedorEducacion from "../containers/educacion-puesto-container";
import ContenedorFunciones from "../containers/funciones-container";
import ContenedorPerfilContratacion from "../containers/perfil-contratacion-container";
import ContenedorResponsabilidades from "../containers/responsabilidades-container";
import FirmasComponent from "./firmas-componente";
import PuestoLaboralObjetivo from "./objetivo-puesto-componente";
import RelacionesComponent from "./relaciones-componente";
import BitacoraComponente from "./bitacora-componente";
// import Contenedor from "./informacion-laboral-tab-container";

const a11yProps = (index) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {/* <Typography>{children}</Typography> */}
          {children}
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const MyTabScrollButton = withStyles((theme) => ({
  root: {
    width: 28,
    overflow: "hidden",
    transition: "width 0.5s",
    "&.Mui-disabled": {
      width: 0,
    },
  },
}))(TabScrollButton);

function TabPanelVertical(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={"span"} variant={"body2"}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

TabPanelVertical.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yPropsVertical(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const styles = makeStyles((theme) => ({
  item: {
    //border: "1px solid blue",
    margin: "10",
  },
  itemFlexGrow: {
    flexGrow: 1,
    // border: "1px solid red"
  },
  content: {
    display: "flex",
    // justify-content: "space-between",
    maxWidth: "400px",
    margin: "0 auto",
    padding: "10px 0",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
  },
  paperPerfil: {
    position: "relative",
    width: "100%", //theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 1,
    outline: "none",
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  greenAvatar: {
    fontSize: "2.5em",
    color: "#fff",
    // borderStyle:'solid',
    // borderWidth:'1 !important',
    // borderColor:'#3f51b5 !important',
    backgroundColor: green[500],
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  paperCard: {
    padding: 20,
    width: "100%",
  },
  greenAvatarFoto: {
    fontSize: "2.5em",
    color: "#fff",
    // borderStyle:'solid',
    // borderWidth:'1 !important',
    // borderColor:'#3f51b5 !important',
    backgroundColor: green[500],
    width: theme.spacing(18),
    height: theme.spacing(18),
  },
  tabsVertical: {
    borderRight: `1px solid ${theme.palette.divider}`,
    overflow: "visible",
  },
  contenedorTabVertical: {
    //flexGrow: 1,
    //backgroundColor: theme.palette.background.paper,
    display: "flex",
    //height: 224,
  },
  oculto: {
    //display: "none",
    margin: "10mm 10mm 10mm 10mm",
    width: "21.59cm",
    height: "27.94cm",
    zoom: "90%",
    "@media print": {
      //display: "block",
      //margin: "25mm 25mm 25mm 25mm",
    },
  },
}));

const DescriptorPuestoLaboral = (props) => {
  const { codigoPuestoLaboral } = props;
  const [puestoLaboral, setPuestoLaboral] = useState(null);
  const [puestoLaboralNombre, setPuestoLaboralNombre] = useState("");
  const [buscando, setBuscando] = useState(false);
  const [encontrado, setEncontrado] = useState(false);

  const [tab, setTab] = useState(0);
  const [tabVertical, setTabVertical] = useState(0);
  const classes = styles();

  const componentRef = React.useRef(null);
  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const reactToPrintTrigger = React.useCallback(() => {
    // Good
    return <button>Imprimir</button>;
  }, []);

  const handleCerrarBackDrop = () => {
    setBuscando(false);
  };
  const handleAbrirBackDrop = () => {
    setBuscando(true);
  };

  useEffect(() => {
    async function fetchData() {
      await cargarDatosPuestoLaboral();
    }

    fetchData();
  }, [codigoPuestoLaboral]);

  //   useEffect(() => {
  //     async function fetchData() {
  //       await buscar();
  //     }

  //     fetchData();
  //   }, [codigoPuestoLaboral]);

  const cargarDatosPuestoLaboral = async () => {
    let session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    }
    try {
      setBuscando(true);
      let codigo = codigoPuestoLaboral; //session.codigoUsuario;

      const request = await API.fetchGetRequest(`api/PuestoLaboral/${codigo}`);

      if (request.data.ficha == null) {
        return;
      }

      let user = request.data.ficha;

      //setCodigoUsuario(user.CodigoUsuario);
      setPuestoLaboral(request.data.ficha);
      setPuestoLaboralNombre(user.PuestoLaboral);
    } catch (e) {
      console.log(e);
    } finally {
      setBuscando(false);
    }
  };

  const buscar = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    }

    try {
      setBuscando(true);
      setEncontrado(false);

      let uri = "api/fichaClinica/empleado/" + codigoPuestoLaboral;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        console.log(request.data.data);
        if (request.data.response) {
          setEncontrado(true);
        } else {
          setTabVertical(0);
          //toast.error("No se encontro al empleado");
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setBuscando(false);
    }
  };

  return (
    <React.Fragment>
      <Backdrop className={classes.backdrop} open={buscando}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth={"100%"}>
        {puestoLaboral !== null && (
          <Paper id="resultado" className={classes.paperPerfil}>
            <Box
              spacing={0}
              //bgcolor="#6ea02f"
              alignItems="center"
              justifyContent="center"
              align="center"
            >
              <Typography gutterBottom variant="h4">
                <b>{puestoLaboralNombre}</b>
              </Typography>
            </Box>
            <Divider variant="middle" />
            <Tabs
              value={tab}
              indicatorColor="primary"
              textColor="primary"
              onChange={(_, val) => setTab(val)}
              variant="scrollable"
              scrollButtons="on"
              aria-label="scrollable force tabs example"
              ScrollButtonComponent={MyTabScrollButton}
            >
              <Tab
                label="Descriptor de Puesto"
                //icon={<Face />}
                {...a11yProps(0)}
                style={{ textTransform: "none" }}
                value={0}
              />
              <Tab
                label="Perfil de Puesto"
                //icon={<Face />}
                {...a11yProps(1)}
                style={{ textTransform: "none" }}
                value={1}
              />
              <Tab
                label="Objetivo"
                //icon={<Face />}
                {...a11yProps(2)}
                style={{ textTransform: "none" }}
                value={2}
              />
              <Tab
                label="Funciones Técnicas"
                //icon={<Face />}
                {...a11yProps(3)}
                style={{ textTransform: "none" }}
                value={3}
              />
              <Tab
                label="Funciones de Negocio"
                //icon={<Face />}
                {...a11yProps(4)}
                style={{ textTransform: "none" }}
                value={4}
              />
              <Tab
                label="Competencias"
                //icon={<Face />}
                {...a11yProps(5)}
                style={{ textTransform: "none" }}
                value={5}
              />
              <Tab
                label="Escala Salarial"
                //icon={<Face />}
                {...a11yProps(6)}
                style={{ textTransform: "none" }}
                value={6}
              />
              <Tab
                label="Educación"
                //icon={<Face />}
                {...a11yProps(7)}
                style={{ textTransform: "none" }}
                value={7}
              />
              <Tab
                label="Perfil de Contratación"
                //icon={<Face />}
                {...a11yProps(8)}
                style={{ textTransform: "none" }}
                value={8}
              />
              <Tab
                label="Relaciones"
                //icon={<Face />}
                {...a11yProps(9)}
                style={{ textTransform: "none" }}
                value={9}
              />
              <Tab
                label="Firmas"
                //icon={<Face />}
                {...a11yProps(10)}
                style={{ textTransform: "none" }}
                value={10}
              />
              <Tab
                label="Bitácora"
                //icon={<Face />}
                {...a11yProps(11)}
                style={{ textTransform: "none" }}
                value={11}
              />
            </Tabs>
            <TabPanel id="descriptor" value={tab} index={0}>
              <Box className={classes.box} pl={0} pt={0}>
                <Grid container className={classes.datoContainer}>
                  <DescriptorComponent puestoLaboral={puestoLaboral} />
                </Grid>
                {/* <div className={classes.oculto} ref={componentRef}>
                  <DescriptorImpresion puestoLaboral={puestoLaboral} />
                </div> */}
                {/* <ReactToPrint
                  content={reactToPrintContent}
                  documentTitle="AwesomeFileName"
                  //onAfterPrint={handleAfterPrint}
                  //onBeforeGetContent={handleOnBeforeGetContent}
                  //onBeforePrint={handleBeforePrint}
                  removeAfterPrint
                  trigger={reactToPrintTrigger}
                /> */}
              </Box>
            </TabPanel>
            <TabPanel id="perfil" value={tab} index={1}>
              <Box className={classes.box} pl={0} pt={0}>
                <Grid container className={classes.datoContainer}>
                  <PerfilComponent puestoLaboral={puestoLaboral} />
                </Grid>
              </Box>
            </TabPanel>
            <TabPanel id="datosPersonales" value={tab} index={2}>
              <Box className={classes.box} pl={0} pt={0}>
                <Grid container spacing={3}>
                  <PuestoLaboralObjetivo puestoLaboral={puestoLaboral} />
                </Grid>
              </Box>
            </TabPanel>
            <TabPanel id="funciones" value={tab} index={3}>
              <Box className={classes.box} pl={0} pt={0}>
                <ContenedorFunciones puestoLaboral={puestoLaboral} />
              </Box>
            </TabPanel>
            <TabPanel id="responsabilidades" value={tab} index={4}>
              <Box className={classes.box} pl={0} pt={0}>
                <ContenedorResponsabilidades puestoLaboral={puestoLaboral} />
              </Box>
            </TabPanel>
            <TabPanel id="competencias" value={tab} index={5}>
              <Box className={classes.box} pl={0} pt={0}>
                <ContenedorCompetencias puestoLaboral={puestoLaboral} />
              </Box>
            </TabPanel>
            <TabPanel id="escalaSalarial" value={tab} index={6}>
              <Box className={classes.box} pl={0} pt={0}>
                <ContenedorAnalisisSalarial puestoLaboral={puestoLaboral} />
              </Box>
            </TabPanel>
            <TabPanel id="educacion" value={tab} index={7}>
              <Box className={classes.box} pl={0} pt={0}>
                <ContenedorEducacion puestoLaboral={puestoLaboral} />
              </Box>
            </TabPanel>
            <TabPanel id="contratacion" value={tab} index={8}>
              <Box className={classes.box} pl={0} pt={0}>
                <ContenedorPerfilContratacion puestoLaboral={puestoLaboral} />
              </Box>
            </TabPanel>
            <TabPanel id="relaciones" value={tab} index={9}>
              <Box className={classes.box} pl={0} pt={0}>
                <RelacionesComponent puestoLaboral={puestoLaboral} />
              </Box>
            </TabPanel>
            <TabPanel id="firmas" value={tab} index={10}>
              <Box className={classes.box} pl={0} pt={0}>
                <FirmasComponent puestoLaboral={puestoLaboral} />
              </Box>
            </TabPanel>
            <TabPanel id="bitacora" value={tab} index={11}>
              <Box className={classes.box} pl={0} pt={0}>
                <BitacoraComponente puestoLaboral={puestoLaboral} />
              </Box>
            </TabPanel>
          </Paper>
        )}
      </Container>

      <ToastContainer />
    </React.Fragment>
  );
};

export default withRouter(DescriptorPuestoLaboral);
