import { Grid } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";

import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import API from "../../utils/api";

import Formulario from "../../gestion/components/formulario";
import GestionGeneral from "../../gestion/components/gestion-general";
import { isSignedIn } from "../../utils/auth";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    width: "100%",
    marginTop: 5,
  },
  rootGestion: {
    flexGrow: 1,
    marginTop: 20,
  },
}));

export default function DetalleGestionGeneral(props) {
  const classes = useStyles();
  const [aviso, setAviso] = useState(null);
  const [vacaciones, setVacaciones] = useState(null);
  const [idAvisoVacaciones, setIdAvisoVacaciones] = useState();
  const [idAvisoPuestoLaboral, setIdAvisoPuestoLaboral] = useState(325);
  const [
    idAvisoSolicitudMovimientoPersonal,
    setIdAvisoSolicitudMovimientoPersonal,
  ] = useState(326);
  const [permisoDescargarPDF, setpermisoDescargarPDF] = useState(false);
  const [maestroTipoMovimientoPersonal, setMaestroTipoMovimientoPersonal] =
    useState([]);
  const [idAvisoMantenimientoPreventivo, setIdAvisoMantenimientoPreventivo] =
    useState(304);
  const [idAvisoRequerimientoPersonal, setIdAvisoRequerimientoPersonal] =
    useState(756);
  const [detallePermisoUsuarioActual, setDetallePermisoUsuarioActual] =
    useState(null);
  const [listaPlantillaNotificaciones, setlistaPlantillaNotificaciones] =
    useState(null);

  const [usuarios, setUsuarios] = useState([]);

  const [listaPermisoAvisoUsuario, setListaPermisoAvisoUsuario] = useState([]);
  const [listaPlantillaAlerta, setListaPlantillaAlerta] = useState([]);

  // personalizados
  const [dataEmpleado, setDataEmpleadoClinica] = useState([]);
  const [dataSolicitudMovimientoPersonal, setDataSolicitudMovimientoPersonal] =
    useState(null);
  const [dataPuestoLaboralSolicitud, setDataPuestoLaboralSolicitud] =
    useState(null);
  const [dataRequerimientoPersonal, setDataRequerimientoPersonal] =
    useState(null);
  useEffect(() => {
    onClickFormularioGestion();
  }, []);

  const onClickFormularioGestion = async () => {
    const session = await isSignedIn();
    let permisos = {
      CodigoAvisoUsuarioPermiso: 0,
      Nombre: "Automatic",
      Create: false,
      Read: false,
      Update: false,
      Delete: false,
      Download: false,
      Print: false,
      Activo: true,
      Eliminado: false,
      God: false,
    };
    let codigoAvisoTarea = props.codigoTarea;
    let uri = `api/aviso/tarea/obtenerFormularioGestion/${codigoAvisoTarea}`;
    const request = await API.fetchGetRequest(uri);
    console.log("Este es el form", request.data);
    const requestListaAlerta = await API.fetchGetRequest(
      `api/plantillaalerta/listar`
    );
    if (Array.isArray(requestListaAlerta.data.data)) {
      let lista = requestListaAlerta.data.data;
      lista.push({ CodigoPlantillaAlerta: 0, Nombre: "Ninguna" });
      setlistaPlantillaNotificaciones({
        listaPlantillaNotificaciones: lista,
      });
    }
    //Asigna lista de usuarios en forma de objeto ;
    let asigObject = [];
    let usuariosAsig = [...request.data.aviso.Asignados];
    let usuariosDispo = request.data.usuariosDisponibles[0].children;

    usuariosAsig.forEach((item, i) => {
      let fila = usuariosDispo.filter((obj) => {
        return obj.id === item;
      });
      asigObject.push(fila);
    });
    try {
      const uriDetallePermiso = `api/aviso/listarDetallePermisoUsuario?CodigoAviso=
                                ${request.data.aviso.CodigoAviso}&CodigoUsuario=${session.codigoUsuario}`;
      const requestDetallePermiso = await API.fetchGetRequest(
        uriDetallePermiso
      );
      if (requestDetallePermiso.statusCode === 401) {
        // return;
      } else {
        if (requestDetallePermiso.data.data !== null) {
          permisos.Read = requestDetallePermiso.data.data.Read;
          setDetallePermisoUsuarioActual(permisos);
        }
      }
    } catch (err) {
      console.log("requestDetallerPermiso err:", err);
    }
    const requestCodGestion = await API.fetchGetRequest(
      `api/vacaciones/getCodigoProblemaVacaciones`
    );

    const requestCodGestionPuestoLaboral = await API.fetchGetRequest(
      `api/PuestoLaboralSolicitud/getCodigoProblema`
    );

    const requestCodMantenimientoPreventivo = await API.fetchGetRequest(
      `api/MantenimientoPreventivo/getCodigoProblemaMantenimiento`
    );

    const requestMaestroTipoMovimientoPersonal = await API.fetchGetRequest(
      `api/TipoMovimientoPersonal/`
    );

    const requestCodRequerimientoPersonal = await API.fetchGetRequest(
      `api/RequerimientoPersonal/getCodigoProblema`
    );

    const verificarGestionVacaciones = requestCodGestion.data.data;
    const verificarGestionPuestoLaboral =
      requestCodGestionPuestoLaboral.data.data;
    const verificarMaestroTipoMovimientoPersonal =
      requestMaestroTipoMovimientoPersonal.data.ficha;
    const verificaGestionMantenimientoPreventivo =
      requestCodMantenimientoPreventivo.data.data;
    const verificarGestionRequerimientoPersonal =
      requestCodRequerimientoPersonal.data.data;

    const codigoAvisoTemp = request.data.aviso.CodigoAviso;
    setIdAvisoVacaciones(verificarGestionVacaciones);
    setIdAvisoPuestoLaboral(verificarGestionPuestoLaboral);
    setMaestroTipoMovimientoPersonal(verificarMaestroTipoMovimientoPersonal);

    setIdAvisoMantenimientoPreventivo(verificaGestionMantenimientoPreventivo);
    setIdAvisoRequerimientoPersonal(verificarGestionRequerimientoPersonal);

    if (request.data.aviso.CodigoProblema === verificarGestionVacaciones) {
      const requestPermisoPDF = await API.fetchGetRequest(
        `api/vacaciones/permisoDescarga/${codigoAvisoTemp}`
      );
      setpermisoDescargarPDF(requestPermisoPDF.data.data);
      const requestVacaciones = await API.fetchGetRequest(
        `api/vacaciones/listarVacaciones/${codigoAvisoTemp}`
      );
      setVacaciones(requestVacaciones.data.data);
    }

    if (request.data.aviso.CodigoProblema === verificarGestionPuestoLaboral) {
      let urix = "api/PuestoLaboralSolicitud/Aviso/" + codigoAvisoTemp;
      const requestDataEmpleadoClinica = await API.fetchGetRequest(urix);
      // console.log("Es solicitud de cambio en puesto laboral");
      setDataPuestoLaboralSolicitud(requestDataEmpleadoClinica.data.ficha[0]);
    }

    if (
      request.data.aviso.CodigoProblema ===
      verificarGestionRequerimientoPersonal
    ) {
      let urix = "api/RequerimientoPersonal/Aviso/" + codigoAvisoTemp;
      const requestDataEmpleadoClinica = await API.fetchGetRequest(urix);
      console.log("Es solicitud de requerimiento de personal");
      setDataRequerimientoPersonal(requestDataEmpleadoClinica.data.ficha[0]);
    }
    //Avisos movimiento personal
    let verificaProblemaTipoMovimientoPersonal =
      verificarMaestroTipoMovimientoPersonal.find(
        (w) => w.CodigoProblema === request.data.aviso.CodigoProblema
      );
    console.log("CODIGOPROBLEMA", request.data.aviso.CodigoProblema);

    if (
      verificaProblemaTipoMovimientoPersonal !== null &&
      verificaProblemaTipoMovimientoPersonal !== undefined
    ) {
      let uri = "api/EmpleadoSolicitudMovimiento/Gestion/" + codigoAvisoTemp;
      const requestDatosMovmientoPersonal = await API.fetchGetRequest(uri);
      setDataSolicitudMovimientoPersonal(
        requestDatosMovmientoPersonal.data.ficha
      );
      setIdAvisoSolicitudMovimientoPersonal(
        verificaProblemaTipoMovimientoPersonal.CodigoProblema
      );
    }

    setUsuarios(asigObject);
    setAviso(request.data);
    //setPermisos(permisos)
  };

  const funcionOk = async () => {
    console.log("No hay funcion");
  };

  const [expanded, setExpanded] = useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return aviso != null ? (
    <div>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        style={{ backgroundColor: "#E2F2FF" }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Encabezado Gestión</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.rootGestion}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} spacing={4}>
                <GestionGeneral
                  aviso={aviso.aviso}
                  vacaciones={vacaciones}
                  idAvisoVacaciones={idAvisoVacaciones}
                  idAvisoPuestoLaboral={idAvisoPuestoLaboral}
                  idAvisoSolicitudMovimientoPersonal={
                    idAvisoSolicitudMovimientoPersonal
                  }
                  idAvisoRequerimientoPersonal={idAvisoRequerimientoPersonal}
                  permisoDescargarPDF={false}
                  causas={[]}
                  causasDisponibles={[]}
                  familiaEtiqueta={[]}
                  agregarCausas={funcionOk}
                  removerCausas={funcionOk}
                  usuarios={usuarios}
                  agregarUsuario={funcionOk}
                  removerUsuario={funcionOk}
                  cancelarAviso={funcionOk}
                  permisoEditar={false}
                  recargar={funcionOk}
                  onChangeCampo={funcionOk}
                  detallePermisoUsuarioActual={detallePermisoUsuarioActual}
                  listaPlantillaNotificaciones={listaPlantillaNotificaciones}
                  dataPuestoLaboralSolicitud={dataPuestoLaboralSolicitud}
                  dataSolicitudMovimientoPersonal={
                    dataSolicitudMovimientoPersonal
                  }
                  dataRequerimientoPersonal={dataRequerimientoPersonal}
                />
              </Grid>

              <Grid item xs={12} sm={5}>
                <Formulario
                  aviso={aviso.aviso}
                  camposPersonalizados={aviso.camposPersonalizados}
                  recargar={funcionOk}
                  onPressInactivarCampoValor={false}
                  quitarDocAdjuntoDeEstado={false}
                  permisoEditar={false}
                  onChangeCampoPersonalizado={funcionOk}
                  detallePermisoUsuarioActual={detallePermisoUsuarioActual}
                />
              </Grid>
            </Grid>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  ) : (
    <React.Fragment>
      <div class={classes.tituloPaper}>
        <div class={classes.gestionGeneral}>
          <Typography variant="h5" gutterBottom>
            Gestión
          </Typography>
        </div>
      </div>
      <Paper className={classes.paper}>
        <div>
          <CircularProgress />
        </div>
      </Paper>
    </React.Fragment>
  );
}
