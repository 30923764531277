import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";

import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Paper,
  Tooltip,
  Modal,
  Fade,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  FormControlLabel,
  Checkbox,
  FormLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";

import DataGrid, {
  Column,
  ColumnFixing,
  Editing,
} from "devextreme-react/data-grid";

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

import API from "../../utils/api";
import { isSignedIn, signOut } from "../../utils/auth";
import { validaPermisoPaginaActual } from "../../utils/helpers";
import { ToastContainer, toast } from "react-toastify";
import { Autocomplete } from "@material-ui/lab";
import { LoadPanel } from "devextreme-react";

const width_proportion = "100%";

const useStyles = makeStyles((theme) => ({
  table: {
    width: "100%",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 210,
  },
  textFieldDireccion: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 230,
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  greenAvatar: {
    fontSize: "2.5em",
    color: "#fff",
    // borderStyle:'solid',
    // borderWidth:'1 !important',
    // borderColor:'#3f51b5 !important',
    backgroundColor: green[500],
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  paperCard: {
    padding: 20,
    width: "100%",
  },
  formControlSelects: {
    // margin: theme.spacing(1),
    // minWidth: 480,

    marginBottom: 10,
    marginTop: 10,
    width: "90%",
    marginLeft: 20,
  },
  formControlSelect: {
    // margin: theme.spacing(1),
    width: width_proportion,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  rootTabla: {
    width: "100%",
    marginTop: theme.spacing.unit * 1,
    overflowX: "auto",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paperModal: {
    position: "absolute",
    width: "70%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const FormPromocionPersonal = (props) => {
  const classes = useStyles();

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors: fieldsErrors },
    register,
  } = useForm();

  let applyFilterTypes = [
    {
      key: "auto",
      name: "Immediately",
    },
    {
      key: "onClick",
      name: "On Button Click",
    },
  ];

  const monedaFormat = {
    style: "currency",
    currency: "GTQ",
    // useGrouping: true,
    minimumSignificantDigits: 3,
  };

  const porcentajeFormat = {
    type: "fixedPoint",
    format: "#0.##'%'",
    // useGrouping: true,
    // minimumSignificantDigits: 3,
  };

  const [buscando, setBackDrop] = useState(false);

  const [showFilterRow, setShowFilterRow] = useState(true);
  const [showHeaderFilter, setShowHeaderFilter] = useState(true);
  const [currentFilter, setCurrentFilter] = useState(applyFilterTypes[0].key);
  const [autoExpandAll, setAutoExpandAll] = useState(true);

  const [empleadosSeleccion, setEmpleadosSeleccion] = useState(
    props.empleadosSeleccion
  );
  const [detallePromocion, setDetallePromocion] = useState([]);
  const [codigoTipoMovimiento, setCodigoTipoMovimiento] = useState(6);
  const [maestroJefeInmediato, setMaestroJefeInmediato] = useState(
    props.maestroJefeInmediato
  );
  const [dpiJefeInmediato, setDpiJefeInmediato] = useState("");
  const [valueAutoCompleteJefeInmediato, setValueAutoCompleteJefeInmediato] =
    useState(null);

  const [maestroPersonaReemplaza, setMaestroPersonaReemplaza] = useState(
    props.maestroPersonaReemplaza
  );
  const [dpiReemplazado, setDpiReemplazado] = useState("");
  const [valueAutoCompleteReemplazado, setValueAutoCompleteReemplazado] =
    useState(null);

  const [maestroTipoTurno, setMaestroTipoTurno] = useState(
    props.maestroTipoTurno
  );
  const [codigoTipoTurno, setCodigoTipoTurno] = useState(0);
  const [valueAutoCompleteTipoTurno, setValueAutoCompleteTipoTurno] =
    useState(null);

  const [maestroPlazas, setMaestroPlazas] = useState(props.maestroPlazas);
  const [codigoPlazaLaboral, setCodigoPlazaLaboral] = useState(0);
  const [valueAutoCompletePlazaLaboral, setValueAutoCompletePlazaLaboral] =
    useState(null);

  const [maestroTipoObjetoSAP, setMaestroTipoObjetoSAP] = useState(
    props.maestroTipoObjetoSAP
  );
  const [codigoTipoObjetoSAP, setCodigoTipoObjetoSAP] = useState(0);
  const [valueAutoCompleteTipoObjetoSAP, setValueAutoCompleteTipoObjetoSAP] =
    useState(null);

  const [maestroObjetoSAP, setMaestroObjetoSAP] = useState([]);
  const [objetoSAP, setObjetoSAP] = useState("");
  const [textoObjetoSAP, setTextoObjetoSAP] = useState("");
  const [valueAutoCompleteObjetoSAP, setValueAutoCompleteObjetoSAP] =
    useState(null);

  const [comentario, setComentario] = useState("");
  const [fechaEjecucion, setFechaEjecucion] = useState(new Date());
  const [reemplazaPlaza, setNoContratable] = useState(false);

  const [salarioAnterior, setSalarioAnterior] = useState(0);
  const [salarioSolicitado, setSalarioSolicitado] = useState(0);

  const [userName, setUserName] = useState(props.userName);
  const [tipoNomina, setTipoNomina] = useState("planilla");

  useEffect(() => {
    const cargar = async () => {
      handleAbrirBackDrop();

      if (codigoTipoObjetoSAP > 0) {
        await cargarMaestroObjetoSAP();
        setValueAutoCompleteObjetoSAP(null);
        setObjetoSAP("");
        setTextoObjetoSAP("");
      }

      handleCerrarBackDrop();
    };

    cargar();
  }, [codigoTipoObjetoSAP]);

  useEffect(() => {
    const cargar = async () => {
      handleAbrirBackDrop();

      if (codigoPlazaLaboral > 0 && tipoNomina === "planilla") {
        await obtenerDatosPromocion();
      }

      handleCerrarBackDrop();
    };

    cargar();
  }, [codigoPlazaLaboral, tipoNomina]);

  const handleCerrarBackDrop = () => {
    setBackDrop(false);
  };
  const handleAbrirBackDrop = () => {
    setBackDrop(true);
  };

  const handleChangeRadio = (event) => {
    setTipoNomina(event.target.value);
  };

  const cuandoHayError = async (data) => {
    toast.info(
      "Existen algunos campos con error, por favor valida el formulario",
      {
        position: "top-right",
        autoClose: 3000, // Esta en milisegundos 3 segundos
      }
    );
  };

  function validarRangoDeFechas(date) {
    var hoy = new Date();
    var fechaAnterior = new Date(new Date().setDate(hoy.getDate() - 30));
    var fechaPosterior = new Date(new Date().setDate(hoy.getDate() + 45));
    return !(date > fechaAnterior && date < fechaPosterior);
  }

  const guardarDato = async () => {
    if (
      tipoNomina === "planilla" ? salarioAnterior > salarioSolicitado : false
    ) {
      toast.error("No puede existir un cambio de salario menor");

      return;
    }

    const data = {
      ListadoEmpleado: empleadosSeleccion,
      DPIJefeInmediato: dpiJefeInmediato,
      CodigoTurno: codigoTipoTurno,
      CodigoTipoMovimientoPersonal: codigoTipoMovimiento,
      CodigoEstadoMovimientoPersonal: 1,
      CodigoPlazaLaboral: codigoPlazaLaboral,
      FechaEjecución: fechaEjecucion,
      Referencia: 0,
      Activo: true,
      Eliminado: false,
      UsuarioInserto: userName,
      FechaInserto: "",
      UsuarioModifico: "",
      FechaModifico: "",
      CodigoTipoObjetoSAP: codigoTipoObjetoSAP,
      TipoObjetoSAP: objetoSAP,
      TextoObjetoSAP: textoObjetoSAP,
      Comentario: comentario,
      EsReemplazo: reemplazaPlaza,
      DPIReemplazado: reemplazaPlaza ? dpiReemplazado : "",
      SalarioSolicitado: tipoNomina === "planilla" ? salarioSolicitado : 0,
      SalarioAnterior: salarioAnterior,
    };

    console.log(data);
    // dataFicha.usuarioInserto = "prueba";
    try {
      handleAbrirBackDrop();
      let vIsSignedIn = await isSignedIn();
      if (vIsSignedIn.response) {
        let request;

        request = await API.post("api/EmpleadoSolicitudMovimiento/", data);
        if (request.status !== 200) {
          toast.error(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        } else {
          if (request.data.response) {
            toast.success("Se guardó exitosamente");

            await props.actualizarListaSolicitudes();

            // setCodigoGerencia(0);
            // setCodigoUnidadOrganizacion(0);

            // deseleccionarItemsEnGridEmpleados();
          } else {
            toast.error(request.data.mensaje);
          }
        }
      }
    } catch (e) {
      console.log("EXCEPTION: " + e);
      toast.error(
        "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
      );
    } finally {
      handleCerrarBackDrop();
      props.handleCloseModalPromocion();
    }
  };

  const obtenerDatosPromocion = async () => {
    const data = {
      ListadoEmpleado: empleadosSeleccion,
      CodigoPlazaLaboral: codigoPlazaLaboral,
    };

    console.log(data);
    // dataFicha.usuarioInserto = "prueba";
    try {
      handleAbrirBackDrop();
      let vIsSignedIn = await isSignedIn();
      if (vIsSignedIn.response) {
        let request;

        request = await API.post(
          "api/EmpleadoSolicitudMovimiento/ProyeccionPromocion",
          data
        );
        if (request.status !== 200) {
          toast.error(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        } else {
          if (request.data.response) {
            setDetallePromocion(request.data.ficha);
            setSalarioAnterior(request.data.ficha[0].SalarioAnterior);
            setSalarioSolicitado(request.data.ficha[0].SalarioSolicitado);
          } else {
            toast.info(request.data.mensaje);
            setDetallePromocion([]);
          }
        }
      }
    } catch (e) {
      console.log("EXCEPTION: " + e);
      toast.error(
        "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
      );
    } finally {
      handleCerrarBackDrop();
      //   props.handleCloseModalPuestoLaboral();
    }
  };

  const cargarMaestroObjetoSAP = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/TipoObjetoSAP/" + codigoTipoObjetoSAP;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setMaestroObjetoSAP(request.data.ficha);
      }
    } catch (e) {
      console.log(e);
      toast.error("Hubo un error al cargar objetos SAP");
    } finally {
      handleCerrarBackDrop();
    }
  };

  const handleDateChangeFechaCreacion = (date) => {
    setFechaEjecucion(date);
  };

  const handleOnChangeTipoTurno = async (event, newValue) => {
    handleAbrirBackDrop();
    if (newValue !== null) {
      setCodigoTipoTurno(newValue.CodigoTipoTurno);
      setValueAutoCompleteTipoTurno(newValue);
    }
    handleCerrarBackDrop();
  };

  const handleOnChangeJefeInmediato = async (event, newValue) => {
    handleAbrirBackDrop();
    if (newValue !== null) {
      setDpiJefeInmediato(newValue.DPI);
      setValueAutoCompleteJefeInmediato(newValue);
    }
    handleCerrarBackDrop();
  };

  const handleOnChangeReemplazado = async (event, newValue) => {
    handleAbrirBackDrop();
    if (newValue !== null) {
      setDpiReemplazado(newValue.DPI);
      setValueAutoCompleteReemplazado(newValue);
    }
    handleCerrarBackDrop();
  };

  const handleOnChangePlazaLaboral = async (event, newValue) => {
    handleAbrirBackDrop();
    if (newValue !== null) {
      setCodigoPlazaLaboral(newValue.id);
      setValueAutoCompletePlazaLaboral(newValue);
    }
    handleCerrarBackDrop();
  };

  const handleOnChangeTipoObjetoSAP = async (event, newValue) => {
    handleAbrirBackDrop();
    if (newValue !== null) {
      setCodigoTipoObjetoSAP(newValue.CodigoTipoObjetoSAP);
      setValueAutoCompleteTipoObjetoSAP(newValue);
    }
    handleCerrarBackDrop();
  };

  const handleOnChangeObjetoSAP = async (event, newValue) => {
    handleAbrirBackDrop();
    if (newValue !== null) {
      setObjetoSAP(
        codigoTipoObjetoSAP === 3 ? newValue.Grafo : newValue.CodigoCentroCosto
      );
      setTextoObjetoSAP(
        codigoTipoObjetoSAP === 3
          ? newValue.TextoExplicativo
          : newValue.Descripcion
      );
      setValueAutoCompleteObjetoSAP(newValue);
    }
    handleCerrarBackDrop();
  };

  const handleOnSavedGrid = async (e) => {
    try {
      handleAbrirBackDrop();

      console.log(e.changes[0].data.SalarioSolicitado);

      setSalarioSolicitado(e.changes[0].data.SalarioSolicitado);
    } catch (ex) {
      toast.error("Hubo un error al actualizar el nuevo salario");
    } finally {
      handleCerrarBackDrop();
    }
  };

  return (
    <React.Fragment>
      <Backdrop className={classes.backdrop} open={buscando}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <h2 id="simple-modal-title">Promoción de empleados</h2>
      <br />
      <Grid container spacing={3}>
        <form
          autoComplete="off"
          onSubmit={handleSubmit(guardarDato, cuandoHayError)}
        >
          <Grid item xs={6} sm={6} md={12} lg={12}>
            <FormControl className={classes.formControlSelects}>
              <Controller
                name="tipoTurno"
                required
                render={({ TipoTurno }) => (
                  <Autocomplete
                    id="tipoTurno"
                    options={maestroTipoTurno}
                    getOptionLabel={(option) => option.TipoTurno}
                    value={valueAutoCompleteTipoTurno}
                    onChange={(event, newValue) =>
                      handleOnChangeTipoTurno(event, newValue)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Tipo Turno"
                        variant="outlined"
                      />
                    )}
                  />
                )}
                control={control}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={6} md={12} lg={12}>
            <FormControl className={classes.formControlSelects}>
              <Controller
                name="plazasLaborales"
                required
                render={({ PlazasLaborales }) => (
                  <Autocomplete
                    id="jefe"
                    options={maestroPlazas}
                    getOptionLabel={(option) => option.name}
                    value={valueAutoCompletePlazaLaboral}
                    onChange={(event, newValue) =>
                      handleOnChangePlazaLaboral(event, newValue)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Plaza a Trasladar"
                        variant="outlined"
                      />
                    )}
                  />
                )}
                control={control}
              />
            </FormControl>
            <FormControl
              component="fieldset"
              className={classes.formControlSelects}
            >
              <FormLabel component="legend">Tipo de Nómina</FormLabel>
              <RadioGroup
                aria-label="gender"
                name="gender1"
                value={tipoNomina}
                onChange={handleChangeRadio}
              >
                <FormControlLabel
                  value="planilla"
                  control={<Radio />}
                  label="Planilla"
                />
                <FormControlLabel
                  value="nomina"
                  control={<Radio />}
                  label="Nómina"
                />
              </RadioGroup>
            </FormControl>
            {tipoNomina === "nomina" ? (
              <Typography sx={{ fontWeight: "bold" }}>
                La propuesta salarial se trabaja por carta salarial
              </Typography>
            ) : (
              detallePromocion.length > 0 && (
                <DataGrid
                  dataSource={detallePromocion}
                  //   defaultColumns={this.props.columns}
                  showBorders={true}
                  rowAlternationEnabled={true}
                  //ref={(ref) => (dataGrid = ref)}
                  allowColumnReordering={true}
                  allowColumnResizing={true}
                  columnAutoWidth={true}
                  onSaved={handleOnSavedGrid}
                >
                  <Editing
                    mode="cell"
                    allowUpdating={true}
                    // allowAdding={true}
                    // allowDeleting={true}
                  />
                  {/* <Selection mode="multiple" /> */}
                  {/* <LoadPanel
                  enabled={true}
                  shadingColor="rgba(0,0,0,0.4)"
                  showIndicator={true}
                  shading={true}
                  showPane={true}
                  closeOnOutsideClick={false}
                /> */}
                  <Column
                    dataField="CodigoAnterior"
                    caption="Código"
                    fixedPosition="left"
                    // allowEditing={false}
                  />
                  <Column
                    dataField="NombreCompleto"
                    caption="NombreCompleto"
                    // allowEditing={false}
                  />
                  <Column
                    dataField="SalarioAnterior"
                    caption="Salario"
                    format={monedaFormat}
                    // allowEditing={false}
                  />
                  <Column
                    dataField="SalarioSolicitado"
                    caption="Salario Nuevo"
                    format={monedaFormat}
                    // allowEditing={true}
                  />
                  <Column
                    dataField="PorcentajeAumentoSalarial"
                    caption="% Aumento"
                    format={porcentajeFormat}
                    // allowEditing={false}
                  />
                  <Column
                    dataField="Comentario"
                    caption="Comentario"
                    // allowEditing={false}
                  />
                  <ColumnFixing enabled={true} />
                </DataGrid>
              )
            )}
            <FormControl className={classes.formControlSelects}>
              <Controller
                name="jefe"
                required
                render={({ Jefe }) => (
                  <Autocomplete
                    id="jefe"
                    options={maestroJefeInmediato}
                    getOptionLabel={(option) => option.NombreCompleto}
                    value={valueAutoCompleteJefeInmediato}
                    onChange={(event, newValue) =>
                      handleOnChangeJefeInmediato(event, newValue)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Jefe Inmediato"
                        variant="outlined"
                      />
                    )}
                  />
                )}
                control={control}
              />
            </FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={reemplazaPlaza}
                  onChange={(e) => setNoContratable(e.target.checked)}
                  value="¿Reemplaza una plaza?"
                  color="primary"
                />
              }
              label="¿Reemplaza una plaza?"
              labelPlacement="start"
            />

            <FormControl className={classes.formControlSelects}>
              <Controller
                name="reemplazante"
                required
                render={({ reemplazante }) => (
                  <Autocomplete
                    disabled={!reemplazaPlaza}
                    id="jefe"
                    options={maestroPersonaReemplaza}
                    getOptionLabel={(option) => option.NombreEmpleado}
                    value={valueAutoCompleteReemplazado}
                    onChange={(event, newValue) =>
                      handleOnChangeReemplazado(event, newValue)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Persona a reemplazar"
                        variant="outlined"
                      />
                    )}
                  />
                )}
                control={control}
              />
            </FormControl>
            <FormControl className={classes.formControlSelects}>
              <Controller
                name="tipoObjetoSAP"
                required
                render={({ TipoObjetoSAP }) => (
                  <Autocomplete
                    id="tipoObjetoSAP"
                    options={maestroTipoObjetoSAP}
                    getOptionLabel={(option) => option.TipoObjetoSAP}
                    value={valueAutoCompleteTipoObjetoSAP}
                    onChange={(event, newValue) =>
                      handleOnChangeTipoObjetoSAP(event, newValue)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Tipo Objeto SAP"
                        variant="outlined"
                      />
                    )}
                  />
                )}
                control={control}
              />
            </FormControl>
            <FormControl className={classes.formControlSelects}>
              <Controller
                name="objetoSAP"
                required
                render={({ ObjetoSAP }) => (
                  <Autocomplete
                    id="objetoSAP"
                    options={maestroObjetoSAP}
                    getOptionLabel={(option) =>
                      codigoTipoObjetoSAP === 3
                        ? option.Grafo +
                          "-" +
                          option.TextoExplicativo +
                          "-" +
                          option.GrupoPlanificador
                        : option.CodigoCentroCosto + "-" + option.Descripcion
                    }
                    value={valueAutoCompleteObjetoSAP}
                    onChange={(event, newValue) =>
                      handleOnChangeObjetoSAP(event, newValue)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Objeto SAP"
                        variant="outlined"
                      />
                    )}
                  />
                )}
                control={control}
              />
            </FormControl>

            <FormControl className={classes.formControlSelects}>
              <Typography>
                Para personal de planilla, los cambios deben realizarse
                únicamente día lunes
              </Typography>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justifyContent="flex-start">
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Fecha para Ejecución "
                    value={fechaEjecucion}
                    onChange={handleDateChangeFechaCreacion}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    shouldDisableDate={validarRangoDeFechas}
                    required
                    autoOk={true}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </FormControl>
            <FormControl className={classes.formControlSelects}>
              <Controller
                name="comentario"
                render={({ Comentario }) => (
                  <TextField
                    id="comentario"
                    label="Comentario"
                    name="comentario"
                    value={comentario}
                    onChange={(e) => setComentario(e.target.value)}
                    variant="outlined"
                    helperText={
                      fieldsErrors.comentario
                        ? fieldsErrors.comentario.message
                        : ""
                    }
                    multiline
                    maxRows={4}
                  />
                )}
                control={control}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={6} md={12} lg={12}>
            <Button
              width="100%"
              variant="contained"
              style={{
                backgroundColor: "#69bd4b",
                marginBottom: 10,
                marginTop: 10,
                color: "white",
                width: "93%",
                marginLeft: 30,
              }}
              type="submit"
            >
              &nbsp;Guardar Cambios
            </Button>
          </Grid>
        </form>
      </Grid>
    </React.Fragment>
  );
};

export default FormPromocionPersonal;
