import React, { useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import MaterialTable from "material-table";
import ConfirmDialog from "../../ConfirmDialog/ConfirmDialog";
import { TIPOS_TEMAS } from '../../utils/helpers';

const getLabelTipo = (value) => {
    const tipo = TIPOS_TEMAS.find(item => item.value === value)
    if (tipo)
        return `${value}. ${tipo.label}`;
    return `${value}`
}

const TablaEventos = ({ data, editarTema, eliminarTema }) => {

    const [confirmOpen, setConfirmOpen] = useState(false);
    const [codigo, setCodigo] = useState(null);

    const columns = [
        {
            title: "Nombre",
            field: "Nombre",
        },
        {
            title: "Fecha creación",
            field: "FechaInserto",
            type: 'date'
        },
        {
            title: "Fecha modificación",
            field: "FechaModifico",
            type: 'date'
        },
        {
            title: "Tipo",
            field: "Tipo",
            render: rowData => getLabelTipo(rowData.Tipo)
        },
        {
            title: "Activo",
            field: "TemaActivo",
            render: rowData => rowData.TemaActivo ? 'Activo' : 'No activo'
        },
    ]

    const confirmarAccion = (id) => {
        setConfirmOpen(true);
        setCodigo(id);
    };

    const cerrarConfirm = () => {
        setConfirmOpen(false);
    };

    return (
        <Container maxWidth="md">
            <MaterialTable
                title=""
                columns={columns}
                data={data}
                options={{ pageSize: 10 }}
                actions={[
                    {
                        icon: 'edit',
                        tooltip: 'Editar',
                        onClick: (event, rowData) => editarTema(rowData.Id)
                    },
                    {
                        icon: 'delete',
                        tooltip: 'Eliminar',
                        onClick: (event, rowData) => {
                            confirmarAccion(rowData.Id);
                        }
                    },
                ]}
            />
            <ConfirmDialog
                id="confirmar"
                keepMounted
                open={confirmOpen}
                onClose={cerrarConfirm}
                value=""
                texto='¿Confirma que desea eliminar este registro? esta acción no podrá revertirse.'
                okfunction={() => eliminarTema(codigo)}
            />
        </Container>
    );
}

export default TablaEventos;
