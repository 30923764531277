import React, { useState, useEffect } from 'react';
import { Button, CircularProgress, Grid, Typography, TextField, Switch, FormControlLabel } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Autocomplete } from '@material-ui/lab';
import { TIPOS_TEMAS } from '../../utils/helpers';

const ModalFormMensaje = ({ id, values, loading, onClose, openModal, submit }) => {

    const [mensaje, setMensaje] = useState(null);
    const [tipo, setTipo] = useState(null);
    const [activo, setActivo] = useState(true);

    useEffect(() => {
        if (values) {
            setMensaje(values.Mensaje);
            setActivo(values.MensajeActivo);
            setTipo(TIPOS_TEMAS.find(item => item.value === values.Tipo) || null);
        } else {
            setMensaje(null);
            setActivo(true);
            setTipo(null);
        }
    }, [values])

    const guardar = () => {
        const body = {
            Mensaje: mensaje,
            Tipo: tipo,
            MensajeActivo: activo
        }

        submit(body)
    }

    return (
        <Dialog
            open={openModal}
            onClose={onClose}
        >
            <DialogTitle id="alert-dialog-title">{values ? 'Editar mensaje' : 'Crear mensaje'}</DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={12} sm={12}>
                        <TextField
                            required
                            id="Mensaje"
                            label="Mensaje"
                            value={mensaje}
                            onChange={event => setMensaje(event.target.value)}
                            multiline
                            rows={4}
                            fullWidth
                            type="string"
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} lg={12} sm={12}>
                        <Autocomplete
                            options={TIPOS_TEMAS}
                            value={tipo}
                            required
                            onChange={(event, value) => setTipo(value)}
                            fullWidth
                            getOptionLabel={(option) => option === null ? "" : option.label}
                            renderInput={(params) =>
                                <TextField {...params} required
                                    id="Tipo"
                                    label="Tipo"
                                    fullWidth
                                    type="string"
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={12} lg={12} sm={12}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={activo}
                                    onChange={event => setActivo(event.target.checked)}
                                    color="primary"
                                    name="temaActivo"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                            }
                            label="Mensaje activo"
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary" disabled={loading}>
                    Cerrar
                </Button>
                <Button onClick={guardar} color="primary" disabled={loading}>
                    {loading ? <CircularProgress size="small" /> : 'Guardar'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ModalFormMensaje;
