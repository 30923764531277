import React, { useState, useEffect, useRef } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  LinearProgress,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Paper,
  InputBase,
  makeStyles,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import {
  Update,
  Description,
  Message,
  GetApp,
  Print,
  Shuffle,
  Link,
  Visibility,
  Block,
  FileCopyOutlined,
  Search,
  Close
} from "@material-ui/icons";
import { toast } from "react-toastify";
import { useStoreState, useStoreActions } from "easy-peasy";
import dayjs from "dayjs";

import VigenciaSinCambios from "./VigenciaSinCambios";
import SolicitudComentarios from "./SolicitudComentarios";
import ActualizacionCambios from "./ActualizacionCambios";
import { getPDFSolicitudBase64 } from "../../utils/media";
import DocumentoTransferir from "../documentos/DocumentoTransferir";
import api from "../../../utils/api";
import SolicitudVer from "./SolicitudVer";
import EnviarObsoletos from "../documentos/EnviarObsoletos";
import { getCodigoUsuario } from "../../utils/utilidades";
import SolicitudControlCopia from "./SolicitudControlCopia";
import PDFView from "../utils/PDFView";
import ViewPDF from "../documentos/ViewPDF";

const heads = [
  "No.",
  "Código de documento",
  "Edición del documento",
  "Titúlo",
  "Fecha de creación",
  "Fecha de caducidad",
  "Autor",
  "Estado",
  "Responsable",
  "Retención",
  "Protección",
  "Almacenamiento",
  "Opciones",
];

const useStyles = makeStyles((theme) => ({
  vigente: {
    color: "#90be6d",
  },
  porVencer: {
    color: "#f3722c",
  },
  vencido: {
    color: "#f94144",
  },
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 300,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  btnContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr'
  },
  tableContent: {
    height: '75vh'
  }
}));

const ListaRegistros = ({ loading }) => {
  const classes = useStyles();
  const { registros, permisosGesDoc } = useStoreState((state) => ({
    registros: state.registros,
    permisosGesDoc: state.permisosGesDoc,
  }));
  const { getRegistrosOficiales, getSolicitudes } = useStoreActions((actions) => ({
    getRegistrosOficiales: actions.getRegistrosOficiales,
    getSolicitudes: actions.getSolicitudes,
  }));
  const estado = {
    vigente: classes.vigente,
    "por vencer": classes.porVencer,
    vencido: classes.vencido,
  };
  const [docs, setDocs] = useState([]);
  const [selected, setSelected] = useState(null);
  const [dialogVigencia, setDialogVigencia] = useState(false);
  const [dialogComentarios, setDialogComentarios] = useState(false);
  const [dialogDoc, setDialogDoc] = useState(false);
  const [dialogPrint, setDialogPrint] = useState(false);
  const [dialogTransferir, setDialogTransferir] = useState(false);
  const [dialogDetalles, setDialogDetalles] = useState(false);
  const [dialogObsoleto, setDialogObsoleto] = useState(false);
  const [dialogControlDeCopias, setDialogControlDeCopias] = useState(false);
  const [PDF, setPDF] = useState("");
  const [print, setPrint] = useState("");
  const [cambios, setCambios] = useState("pendiente");
  const [isFiltered, setIsFiltered] = useState(false)
  const buscarRef = useRef()

  const TipoActualizacion = ({ setTipo, onClose }) => {
    return (
      <>
        <DialogTitle>Actualización</DialogTitle>
        <DialogContent>
          <Button
            fullWidth
            size="large"
            color="primary"
            variant="contained"
            onClick={() => setTipo("no cambios")}
            style={{ margin: "10px 0" }}
          >
            No tiene cambios
          </Button>
          <Button
            fullWidth
            size="large"
            color="primary"
            variant="contained"
            onClick={() => setTipo("cambios")}
            style={{ margin: "10px 0" }}
          >
            Tiene cambios
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancelar</Button>
        </DialogActions>
      </>
    );
  };
  const renderActualizacion = (tipo) => {
    switch (tipo) {
      case "cambios":
        return (
          <ActualizacionCambios
            data={selected}
            onClose={() => {
              setDialogVigencia(false);
              setCambios("pendiente");
            }}
            onRefresh={() => {
              getRegistrosOficiales();
              getSolicitudes('usuario');
              setDialogVigencia(false);
            }}
          />
        );
      case "no cambios":
        return (
          <VigenciaSinCambios
            doc={selected}
            onClose={() => {
              setDialogVigencia(false);
              setCambios("pendiente");
            }}
            onRefresh={() => {
              getRegistrosOficiales();
              setDialogVigencia(false);
            }}
          />
        );
      default:
        return (
          <TipoActualizacion
            setTipo={setCambios}
            onClose={() => {
              setDialogVigencia(false);
              setCambios("pendiente");
            }}
          />
        );
    }
  };
  const copiarLink = (registro) => {
    const env = api.getENV();
    const urlenvs = {
      local: "http://localhost:3000/doc",
      dev: "https://desagestionapp.launion.com.gt/doc",
      prod: "https://gestionapp.launion.com.gt/doc"
    }
    navigator.clipboard.writeText(`${urlenvs[env]}/proceso/${registro.proceso}/codigo/${registro.Correlativo}`)
    toast.info("Link copiado al portapapeles")
  }
  const showVigencia = (idx) => {
    const doc = { ...docs[idx] };
    setSelected(doc);
    setDialogVigencia(true);
  };
  const verDoc = async (id) => {
    try {
      setDialogDoc(true);
      const item = docs.find((i) => i.IdSolicitud === id);
      await getPDF(item.IdSolicitud);
    } catch (error) {
      toast.error("No se pudo cargar el documento");
    }
  };
  const showComentarios = (id) => {
    const item = docs.find((i) => i.IdSolicitud === id);
    setSelected({ ...item });
    setDialogComentarios(true);
  };
  const getPDF = async (idSolicitud) => {
    try {
      setPDF(null)
      let base64str = await getPDFSolicitudBase64(idSolicitud)
      if (base64str) {
        base64str = base64str.replace(
          "data:application/octet-stream;base64,",
          ""
        )
        setPDF(base64str)
      }
    } catch (error) {
      toast.error('Ocurrio un error al obtener el documento 😅')
    }
  }
  const downloadDoc = async (id) => {
    const item = docs.find((i) => i.IdSolicitud === id);
    let base64str = await getPDFSolicitudBase64(item.IdSolicitud);
    base64str = base64str.replace("data:application/octet-stream;base64,", "");
    const linkSource = `data:application/pdf;base64,${base64str}`;
    const downloadLink = document.createElement("a");
    const fileName = `${item.titulo}.pdf`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();

    const body = {
      CodigoUsuario: getCodigoUsuario(),
      Accion: "Descarga",
      IdObjeto: id,
      Objeto: "Solicitud",
      Fecha: dayjs().toISOString(),
      Comentario: "El documento fue descargado",
      Activo: true,
    };
    await api.post("api/documentos/guardar-accion-descargar-documento", body);
  };
  const printDoc = async (id) => {
    try {
      const item = docs.find((i) => i.IdSolicitud === id);
      let base64str = await getPDFSolicitudBase64(item.IdSolicitud);
      base64str = base64str.replace(
        "data:application/octet-stream;base64,",
        "data:application/pdf;base64,"
      );
      setPrint(base64str);
      setDialogPrint(true);
      const body = {
        CodigoUsuario: getCodigoUsuario(),
        Accion: "Impresión",
        IdObjeto: id,
        Objeto: "Solicitud",
        Fecha: dayjs().format("YYYY-MM-DD HH:mm:ss"),
        Comentario: "Se presionó el boton imprimir",
        Activo: true,
      };
      await api.post("api/documentos/guardar-accion-imprimir-documento", body);
    } catch (error) {
      toast.error('Ocurrio un error al obtener el archivo 😓')
    }
  };
  const showTransferir = (idx) => {
    const doc = { ...docs[idx] };
    setSelected(doc);
    setDialogTransferir(true);
  };
  const showDetalles = (idx) => {
    const doc = { ...docs[idx] };
    doc.DescripcionDocumento = doc.descripcion;
    doc.NombreDocumento = doc.titulo;
    doc.Estado = doc.EstadoVigencia;
    doc.fecha = doc.actualizacion;
    doc.Solicitante = doc.Autor;
    setSelected(doc);
    setDialogDetalles(true);
  };
  const showObsoleto = (idx) => {
    const doc = { ...docs[idx] };
    setSelected(doc);
    setDialogObsoleto(true);
  };

  const showControlDeCopias = (idSolicitud) => {
    const item = docs.find((i) => i.IdSolicitud === idSolicitud);
    setSelected({ ...item });
    setDialogControlDeCopias(true);
  };

  const hidePDF = () => {
    setPDF(null);
    setDialogDoc(false);
  };
  const filterDocs = () => {
    const busqueda = buscarRef.current.value.toLowerCase().trim()
    if (busqueda) {
      const items = registros.filter(i => i.titulo.toLowerCase().includes(busqueda) || i.Correlativo.toLowerCase().includes(busqueda))
      setDocs(items)
      setIsFiltered(true)
    }
  }
  const clearFilter = () => {
    setDocs(registros)
    setIsFiltered(false)
    buscarRef.current.value = ''
  }
  useEffect(() => {
    setDocs(registros);
  }, [registros]);
  return (
    <div>
      <Box display="flex" justifyContent="flex-end">
        <Paper component="form" className={classes.root}>
          <InputBase
            className={classes.input}
            placeholder="Buscar"
            onKeyPress={ev => {
              if (ev.key === 'Enter') {
                ev.preventDefault()
                filterDocs()
              }
            }}
            inputRef={buscarRef}
          />
          {
            isFiltered && <IconButton
              onClick={clearFilter}
              className={classes.iconButton}
            >
              <Close />
            </IconButton>
          }
          <IconButton
            onClick={filterDocs}
            className={classes.iconButton}
          >
            <Search />
          </IconButton>
        </Paper>
      </Box>
      <TableContainer className={classes.tableContent}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {heads.map((i) => (
                <TableCell key={i}>{i}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {docs.map((i, idx) => (
              <TableRow key={idx}>
                <TableCell>{idx + 1}</TableCell>
                <TableCell>{i.Correlativo}</TableCell>
                <TableCell>{i.version}</TableCell>
                <TableCell>
                  <strong>{i.titulo}</strong>
                </TableCell>
                <TableCell>{i.actualizacion}</TableCell>
                <TableCell>{i.vencimiento}</TableCell>
                <TableCell>{i.Autor}</TableCell>
                <TableCell className={estado[i.EstadoVigencia]}>
                  {i.EstadoVigencia.toUpperCase()}
                </TableCell>
                <TableCell>
                  {i.ResponsableRegistro}
                </TableCell>
                <TableCell>
                  {i.TiempoRetencionRegistro}
                </TableCell>
                <TableCell>
                  {i.Proteccion.toUpperCase()}
                </TableCell>
                <TableCell>
                  {i.MedioAlmacenamiento.toUpperCase()}
                </TableCell>
                <TableCell>
                  <div className={classes.btnContainer}>
                  <Tooltip title="Actualizar vigencia">
                    <IconButton onClick={() => showVigencia(idx)}>
                      <Update />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Ver documento">
                    <IconButton onClick={() => verDoc(i.IdSolicitud)}>
                      <Description />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Ver observaciones de documento">
                    <IconButton onClick={() => showComentarios(i.IdSolicitud)}>
                      <Message />
                    </IconButton>
                  </Tooltip>
                  {permisosGesDoc.includes("GESDOC.DescargarDocumento") && (
                    <Tooltip title="Descargar documento">
                      <IconButton onClick={() => downloadDoc(i.IdSolicitud)}>
                        <GetApp />
                      </IconButton>
                    </Tooltip>
                  )}
                  {permisosGesDoc.includes("GESDOC.ImprimirDocumento") && (
                    <Tooltip title="Imprimir documento">
                      <IconButton onClick={() => printDoc(i.IdSolicitud)}>
                        <Print />
                      </IconButton>
                    </Tooltip>
                  )}
                  {permisosGesDoc.includes("GESDOC.TransferirDocumento") && (
                    <Tooltip title="Transferir documento">
                      <IconButton onClick={() => showTransferir(idx)}>
                        <Shuffle />
                      </IconButton>
                    </Tooltip>
                  )}
                  <Tooltip title="Copiar link del documento">
                    <IconButton onClick={() => copiarLink(i)}>
                      <Link />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Ver detalles">
                    <IconButton onClick={() => showDetalles(idx)}>
                      <Visibility />
                    </IconButton>
                  </Tooltip>
                  {permisosGesDoc.includes("GESDOC.EnviarObsoleto") && (
                    <Tooltip title="Enviar a documentos obsoletos">
                      <IconButton onClick={() => showObsoleto(idx)}>
                        <Block />
                      </IconButton>
                    </Tooltip>
                  )}
                  {permisosGesDoc.includes("GESDOC.CopiasControladas") && (
                    <Tooltip title="Control de copias">
                      <IconButton
                        onClick={() => showControlDeCopias(i.IdSolicitud)}
                      >
                        <FileCopyOutlined />
                      </IconButton>
                    </Tooltip>
                  )}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {loading && <LinearProgress />}
        {!loading && !docs.length && (
          <Alert severity="info" variant="filled">
            No tienes documentos registrados
          </Alert>
        )}
      </TableContainer>
      <Dialog fullWidth open={dialogVigencia} maxWidth="md">
        {renderActualizacion(cambios)}
      </Dialog>
      <Dialog open={dialogDoc} onClose={hidePDF} fullScreen>
        <ViewPDF
          docBase64={PDF}
          onClose={hidePDF}
        />
      </Dialog>
      <Dialog
        open={dialogPrint}
        onClose={() => setDialogPrint(false)}
        maxWidth="xl"
        fullWidth
      >
        <PDFView
          PDFbase64={print}
          onClose={() => setDialogPrint(false)}
        />
      </Dialog>
      <Dialog
        open={dialogDetalles}
        onClose={() => setDialogDetalles(false)}
        maxWidth="sm"
        fullWidth
      >
        <SolicitudVer
          datos={selected}
          onClose={() => setDialogDetalles(false)}
        />
      </Dialog>
      <Dialog
        fullWidth
        open={dialogComentarios}
        onClose={() => setDialogComentarios(false)}
        maxWidth="md"
      >
        <SolicitudComentarios
          datos={selected}
          onClose={() => setDialogComentarios(false)}
        />
      </Dialog>
      <Dialog fullWidth open={dialogTransferir} maxWidth="sm">
        <DocumentoTransferir
          documento={selected}
          onClose={() => setDialogTransferir(false)}
          onRefresh={() => {
            getRegistrosOficiales();
            setDialogTransferir(false);
          }}
        />
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={dialogObsoleto}
        onClose={() => setDialogObsoleto(false)}
      >
        <EnviarObsoletos
          documento={selected}
          onClose={() => setDialogObsoleto(false)}
          onRefresh={() => {
            setDialogObsoleto(false);
            getRegistrosOficiales();
          }}
        />
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={dialogControlDeCopias}
        onClose={() => setDialogControlDeCopias(false)}
      >
        <SolicitudControlCopia
          datos={selected}
          onClose={() => setDialogControlDeCopias(false)}
        />
      </Dialog>
    </div>
  );
};

export default ListaRegistros
