import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  Select,
  TextField,
  MenuItem,
  InputLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  Box,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useStoreState, useStoreActions } from "easy-peasy";
import Api from "../utils/api";
import { solicitudModel, registroModel, externoModel } from "./utils/modelos";
import { getUsuarioInserta } from "./utils/utilidades";
import LoadingButton from "./componentes/utils/LoadingButton";
import FormularioRegistro from "./componentes/solicitudes/FormularioRegistro";
import FormularioExterno from "./componentes/solicitudes/FormularioExterno";
import Layout from "../layout/containers/Layout";
import SelectCentroDocumental from "./componentes/solicitudes/SelectCentroDocumental";
import SelectUsuario from "./componentes/usuarios/SelectUsuario";
import SelectDepartamento from "./componentes/solicitudes/SelectDepartamento";

const SolicitudCrear = () => {
  const history = useHistory();
  const { usuarios, crearPropia, deptos } = useStoreState((state) => ({
    usuarios: state.usuarios,
    crearPropia: state.crearPropia,
    deptos: state.deptos
  }));
  const { getUsuarios, getDeptos } = useStoreActions((actions) => ({
    getUsuarios: actions.getUsuarios,
    getDeptos: actions.getDeptos
  }));
  const [sistema, setSistema] = useState([]);
  const [macroproceso, setMacroproceso] = useState("");
  const [proceso, setProceso] = useState("");
  const [depto, setDepto] = useState(null)
  const [idVersion, setIdVersion] = useState(1);
  const [tipo, setTipo] = useState("");
  const [division, setDivision] = useState("");
  const [solicitante, setSolicitante] = useState(null);
  const [titulo, setTitulo] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [observaciones, setObservaciones] = useState("");
  const [encargado, setEncargado] = useState(null);
  const [loading, setLoading] = useState(false);
  const [procesos, setProcesos] = useState([]);
  const [macroprocesos, setMacroprocesos] = useState([]);
  const [centros, setCentros] = useState([]);
  const [usoDoc, setUsoDoc] = useState("iso");
  const [externo, setExterno] = useState(null);
  const [registro, setRegistro] = useState(null);
  const [informados, setInformados] = useState([]);
  const [flags, setFlags] = useState({
    isRegistro: false,
    isGeneral: false,
  });
  const [masters, setMasters] = useState({
    tipos: [],
    divisiones: [],
    sistemas: [],
    macroprocesos: [],
    procesos: [],
  });
  const tieneDeptos = proceso && deptos.filter(i => i.IdProceso === parseInt(proceso)).length > 0
  const getMasters = async () => {
    try {
      const reqTipos = Api.fetchGetRequest("api/documentos/tipodocumento");
      const reqDivisiones = Api.fetchGetRequest("api/documentos/division");
      const reqSistemas = Api.fetchGetRequest("api/documentos/sistemagestion");
      const reqMacroprocesos = Api.fetchGetRequest(
        "api/documentos/macroproceso"
      );
      const reqProcesos = Api.fetchGetRequest("api/documentos/proceso");
      const requests = [
        reqTipos,
        reqDivisiones,
        reqSistemas,
        reqMacroprocesos,
        reqProcesos,
      ];
      const response = await Promise.all(requests);
      setMasters({
        tipos: response[0].data,
        divisiones: response[1].data,
        sistemas: response[2].data,
        macroprocesos: [],
        procesos: [],
      });
      setMacroprocesos(response[3].data);
      setProcesos(response[4].data);
    } catch (error) {
      toast.error("Ha ocurrido un error al obtener los datos");
    }
  };
  const initUsuarios = async () => {
    if (usuarios.length) {
      const usuario = JSON.parse(localStorage.getItem("userData"));
      const item = usuarios.find(
        (i) => i.CodigoUsuario === parseInt(usuario.codigoUsuario)
      );
      setSolicitante(item);
    }
  };
  const insertSolicitud = async (
    solicitud,
    endpoint,
    complemento = null,
    tipoComplemento = null,
    idVersion = 1,
    IdDepartamento = 0
  ) => {
    const data = {
      CodigoUsuarioAutoriza: solicitud.CodigoUsuarioAutoriza,
      Observaciones: observaciones,
      Solicitud: {
        ...solicitud,
        IdDepartamentoDocumento: IdDepartamento
      },
      IdVersion: idVersion,
    };
    if (complemento && tipoComplemento) {
      data[tipoComplemento] = complemento;
    }
    await Api.post(endpoint, data);
  };
  const saveSolicitud = async () => {
    try {
      // if (solicitante.CodigoUsuario == encargado.CodigoUsuario) {
      //   toast.error('El solicitante no puede ser tambien el autorizador')
      //   return
      // }
      setLoading(true);
      if (tieneDeptos && !depto) {
        return toast.error('Selecciona un departamento')
      }
      const solicitud = {
        CodigoUsuarioSolicitante: solicitante
          ? solicitante.CodigoUsuario
          : null,
        IdMacroprocesoDocumento: macroproceso || null,
        SistemasDeGestion: sistema.map((i) => i.Id),
        IdTipoDocumento: tipo || null,
        IdDivisionDocumento: division || null,
        IdProcesoDocumento: proceso || null,
        NombreDocumento: titulo,
        DescripcionDocumento: descripcion,
        CodigoUsuarioAutoriza: encargado ? encargado.CodigoUsuario : null,
        UsuarioInserto: getUsuarioInserta(),
        CentroDocumental: centros.map((i) => i.IdCentroDocumental),
        UsuariosInformados: informados.map((i) => i.CodigoUsuario)
      };
      const datosSolicitud = await solicitudModel.validate(solicitud);
      const IdDepartamento = depto ? depto.Id : 0
      if (flags.isRegistro) {
        const datosRegistro = await registroModel.validate(registro);
        // if (!datosRegistro.RegistroAcceso.length) {
        //   toast.error("Selecciona a los usuarios con acceso");
        // }
        await insertSolicitud(
          datosSolicitud,
          "api/documentos/solicitud/crear-registro",
          datosRegistro,
          "Registro",
          idVersion,
          IdDepartamento
        );
      } else if (flags.isGeneral && usoDoc === "externo") {
        const datosDocExterno = await externoModel.validate(externo);
        await insertSolicitud(
          datosSolicitud,
          "api/documentos/solicitud/crear-documento-externo",
          datosDocExterno,
          "DocumentoExterno",
          idVersion,
          IdDepartamento
        );
      } else {
        await insertSolicitud(
          datosSolicitud,
          "api/documentos/solicitud/crear",
          null,
          null,
          idVersion,
          IdDepartamento
        );
      }
      toast.success("Solicitud creada");
      // await Api.realizarPeticionPostPut('api/documentos/solicitud/crear', datos, 'POST')
      goBack();
    } catch (err) {
      if (err.name && err.name === "ValidationError") {
        toast.error(err.errors[0]);
      } else {
        toast.error("Ha ocurrido un error");
      }
    } finally {
      setLoading(false);
    }
  };
  const goBack = () => {
    history.push("/documentos/mis-documentos");
  };
  const changeExterno = (data) => {
    setExterno({
      ...data,
      CodigoResponsable: data.CodigoResponsable
        ? parseInt(data.CodigoResponsable.CodigoUsuario)
        : null,
    });
  };
  const changeRegistro = (data) => {
    setRegistro({
      ...data,
      CodigoResponsable: data.CodigoResponsable
        ? parseInt(data.CodigoResponsable.CodigoUsuario)
        : null,
    });
  };
  useEffect(() => {
    if (division) {
      const tmp = { ...masters };
      setMasters({
        ...tmp,
        macroprocesos: macroprocesos.filter((i) => i.IdDivision === division),
      });
    }
  }, [division]);
  useEffect(() => {
    if (macroproceso) {
      const tmp = { ...masters };
      setMasters({
        ...tmp,
        procesos: procesos.filter((i) => i.IdMacroproceso === macroproceso),
      });
    }
  }, [macroproceso]);
  useEffect(() => {
    if (tipo) {
      const tipoSelected = masters.tipos.find((i) => i.Id === tipo);
      setFlags({
        isRegistro:
          tipoSelected &&
          tipoSelected.NombreTipoDocumento.includes("Registros"),
        isGeneral:
          tipoSelected &&
          tipoSelected.NombreTipoDocumento.includes("Docs. Generales")
      })
    }
  }, [tipo]);
  useEffect(() => {
    initUsuarios();
  }, [usuarios]);
  useEffect(() => {
    if (!usuarios.length) {
      getUsuarios();
    }
    getMasters();
    getDeptos()
  }, []);

  return (
    <Layout title="Nueva solicitud">
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel>División</InputLabel>
            <Select
              fullWidth
              value={division}
              onChange={(ev) => setDivision(ev.target.value)}
            >
              {masters.divisiones.map((i, idx) => (
                <MenuItem key={idx} value={i.Id}>
                  {i.NombreDivision}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel>Macroproceso</InputLabel>
            <Select
              fullWidth
              value={macroproceso}
              onChange={(ev) => setMacroproceso(ev.target.value)}
            >
              {masters.macroprocesos.map((i, idx) => (
                <MenuItem key={idx} value={i.IdMacroproceso}>
                  {i.NombreMacroproceso}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel>Proceso</InputLabel>
            <Select
              fullWidth
              value={proceso}
              onChange={(ev) => setProceso(ev.target.value)}
            >
              {masters.procesos.map((i, idx) => (
                <MenuItem key={idx} value={i.Id}>
                  {i.NombreProceso}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {
          tieneDeptos && <Grid item xs={12} md={6}>
            <SelectDepartamento
              value={depto}
              onChange={value => setDepto(value)}
            />
          </Grid>
        }
        <Grid item xs={12} md={6}>
          <Autocomplete
            multiple
            options={masters.sistemas}
            getOptionLabel={(op) => op.NombreSistemaGestion}
            defaultValue={[]}
            value={sistema}
            onChange={(ev, value) => {
              setSistema([...value]);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Sistema de gestión"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel>Tipo de documento</InputLabel>
            <Select
              fullWidth
              value={tipo}
              onChange={(ev) => setTipo(ev.target.value)}
            >
              {masters.tipos.map((i, idx) => (
                <MenuItem key={idx} value={i.Id}>
                  {i.NombreTipoDocumento}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectCentroDocumental
            value={centros}
            onChange={(v) => setCentros(v)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectUsuario
            selectMany={true}
            label="Acceso a los documentos"
            onSelect={(value) => setInformados(value)}
          />
        </Grid>
        {flags.isGeneral && (
          <Grid item xs={12} md={6}>
            <FormControl component="fieldset">
              <FormLabel>Uso del documento</FormLabel>
              <RadioGroup
                value={usoDoc}
                onChange={(ev) => setUsoDoc(ev.target.value)}
              >
                <FormControlLabel
                  value="interno"
                  control={<Radio />}
                  label="Documento interno"
                />
                <FormControlLabel
                  value="externo"
                  control={<Radio />}
                  label="Documento externo"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        )}
        {flags.isRegistro && (
          <FormularioRegistro onChange={(data) => changeRegistro(data)} />
        )}
        {flags.isGeneral && usoDoc === "externo" && (
          <FormularioExterno onChange={(data) => changeExterno(data)} />
        )}
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            value={titulo}
            onChange={(ev) => setTitulo(ev.target.value)}
            label="Título del documento"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            value={descripcion}
            onChange={(ev) => setDescripcion(ev.target.value)}
            multiline
            rows={4}
            label="Descripción del documento"
          />
        </Grid>
        {/* <Grid item xs={12}>
        <TextField
          fullWidth
          label="Fecha"
          type="date"
          InputLabelProps={{ shrink: true }}
        />
      </Grid> */}
        <Grid item xs={12} md={6}>
          <Autocomplete
            disabled={crearPropia}
            options={usuarios}
            getOptionLabel={(option) =>
              option.NombreCompletoUsuario ? option.NombreCompletoUsuario : ""
            }
            value={solicitante}
            onChange={(ev, value) => {
              setSolicitante(value);
            }}
            defaultValue=""
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Autor de documento"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Autocomplete
            options={usuarios}
            getOptionLabel={(option) =>
              option.NombreCompletoUsuario ? option.NombreCompletoUsuario : ""
            }
            defaultValue=""
            value={encargado}
            onChange={(ev, value) => {
              setEncargado(value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Autorizador de código de documento"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel>Version inicial</InputLabel>
            <Select
              fullWidth
              value={idVersion}
              onChange={(ev) => setIdVersion(ev.target.value)}
            >
              {Array.from({ length: 30 }, (_, i) => i + 1).map((i) => (
                <MenuItem key={i} value={i}>
                  Versión {i}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            value={observaciones}
            onChange={(ev) => setObservaciones(ev.target.value)}
            multiline
            rows={4}
            label="Observaciones"
          />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center">
            <Button
              onClick={() => goBack()}
              disabled={loading}
              variant="contained"
              style={{ margin: "0 1em" }}
            >
              Cancelar
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              loading={loading}
              onClick={saveSolicitud}
              style={{ margin: "0 1em" }}
            >
              Enviar
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
      <ToastContainer />
    </Layout>
  );
};

export default SolicitudCrear;
