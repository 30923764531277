import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Checkbox,
  FormControlLabel,
  Divider,
  Paper,
  Tooltip,
  Modal,
  Fade,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Box,
} from "@material-ui/core";

import DataGrid, {
  Column,
  Pager,
  Paging,
  Sorting,
  ColumnFixing,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Grouping,
  GroupPanel,
  Export,
  Selection,
  Editing,
  Scrolling,
  LoadPanel,
  Lookup,
  RequiredRule,
} from "devextreme-react/data-grid";

import BackdropModal from "@material-ui/core/Backdrop";

import { Delete, ViewList, Add, Edit } from "@material-ui/icons";

import { green } from "@material-ui/core/colors";
import { NavLink } from "react-router-dom";
import {
  Timeline,
  TimelineItem,
  TimelineContent,
  TimelineSeparator,
  TimelineConnector,
  TimelineDot,
  TimelineOppositeContent,
} from "@material-ui/lab";

import API from "../../utils/api";
import { isSignedIn, signOut } from "../../utils/auth";
import { validaPermisoPaginaActual } from "../../utils/helpers";
import { ToastContainer, toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import "devextreme/dist/css/dx.material.teal.light.compact.css";
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";

const width_proportion = "100%";
const titleColor = "#2B3C4D";

const useStyles = makeStyles((theme) => ({
  paperContent: {
    padding: "6px 16px",
  },
  table: {
    width: "100%",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 210,
  },
  textFieldDireccion: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 230,
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  greenAvatar: {
    fontSize: "2.5em",
    color: "#fff",
    // borderStyle:'solid',
    // borderWidth:'1 !important',
    // borderColor:'#3f51b5 !important',
    backgroundColor: green[500],
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  paperCard: {
    padding: 20,
    width: "100%",
  },
  formControlSelects: {
    // margin: theme.spacing(1),
    minWidth: 400,

    marginBottom: 10,
    marginTop: 10,
    marginLeft: 30,
  },
  formControlSelect: {
    // margin: theme.spacing(1),
    width: width_proportion,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  rootTabla: {
    width: "100%",
    marginTop: theme.spacing.unit * 1,
    // overflowX: "auto",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paperModal: {
    position: "absolute",
    width: "70%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));
const FuncionesPuestoLaboral = (props) => {
  const classes = useStyles();
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors: fieldsErrors },
    register,
  } = useForm();

  let applyFilterTypes = [
    {
      key: "auto",
      name: "Immediately",
    },
    {
      key: "onClick",
      name: "On Button Click",
    },
  ];

  const dataEmpleadoPlazaLaboral = new DataSource({
    key: "CodigoEmpleadoPlazaLaboral",
    load: () =>
      cargarDatos(
        "api/EmpleadoPlazaLaboral/HistoricoEmpleado/" + props.dataEmpleado.DPI
      ),
    insert: (values) =>
      guardarDato("api/EmpleadoPlazaLaboral/Individual/", null, values),
    update: (key, values) =>
      guardarDato("api/EmpleadoPlazaLaboral/Individual/", key, values),
    remove: (key, values) =>
      guardarDato("api/EmpleadoPlazaLaboral/Eliminar/", key, values),
  });
  const [userName, setUserName] = useState("");
  const [open, setOpen] = useState(false);
  const [cargandoMaestros, setCargandoMaestros] = useState(false);
  const [maestroExpertis, setMestroExpertis] = useState([]);
  const [maestroEducacion, setMaestroEducacion] = useState([]);
  const [maestroPuestoLaboralEducacion, setMaestroPuestoLaboralEducacion] =
    useState([]);

  const [codigoEducacion, setCodigoEducacion] = useState(0);
  const [codigoExpertis, setCodigoExpertis] = useState(0);
  const [predicado, setPredicado] = useState("");
  const [numeroVersion, setNumeroVersion] = useState("");
  const [fechaCreacion, setFechaCreacion] = useState(new Date());

  const [valueAutoCompleteExpertis, setValueAutoCompleteExpertis] =
    useState(null);
  const [valueAutoCompleteEducacion, setValueAutoCompleteEducacion] =
    useState(null);

  const [positionDisableSorting, setPositionDisableSorting] = useState(false);
  const [showFilterRow, setShowFilterRow] = useState(true);
  const [showHeaderFilter, setShowHeaderFilter] = useState(true);
  const [currentFilter, setCurrentFilter] = useState(applyFilterTypes[0].key);
  const [autoExpandAll, setAutoExpandAll] = useState(true);

  const [buscando, setBackDrop] = useState(false);
  const [selected, setSelected] = useState(null);
  const [codigoNotificacion, setCodigoNotificacion] = useState(0);
  const [idFila, setIdFila] = useState(0);
  const [historicoPuestoPorEmpleado, setHistoricoPuestoPorEmpleado] = useState(
    dataEmpleadoPlazaLaboral
  );
  const [temporadaSeleccionada, setTemporadaSeleccionada] = useState(null);
  const [datosMaestroTemporada, setDatosMaestroTemporada] = useState(
    new CustomStore({
      key: "CodigoTemporada",
      loadMode: "raw",
      load: () => cargarDatos("api/Temporada"),
    })
  );

  const [datosMaestroPlazaLaboral, setDatosMaestroPlazaLaboral] = useState(
    new CustomStore({
      key: "CodigoPlazaLaboral",
      loadMode: "raw",
      load: () => cargarDatos("api/PlazaLaboral"),
    })
  );

  const [datosMaestroExpertis, setDatosMaestroExpertis] = useState(
    new CustomStore({
      key: "CodigoExpertis",
      loadMode: "raw",
      load: () => cargarDatos("api/Expertis"),
    })
  );

  function handleChangeTemporada(rowData, value) {
    datosMaestroTemporada.byKey(value).then((dataItem) => {
      setTemporadaSeleccionada(dataItem);
      rowData.FechaInicio = dataItem.FechaInicio;
      rowData.FechaFin = dataItem.FechaFin;
    });

    this.defaultSetCellValue(rowData, value);
    // console.log(value);
    // setTemporadaSeleccionada(datosMaestroTemporada.find(w=>w.CodigoTemporada === value));
  }

  const handleCerrarBackDrop = () => {
    setBackDrop(false);
  };
  const handleAbrirBackDrop = () => {
    setBackDrop(true);
  };

  useEffect(() => {
    const cargar = async () => {
      // CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
      const llaveAgrupacion = "Menu.Principal.Web";
      const llavePagina = "Menu.Principal.Web.PuestoLaboral";
      await validaPermisoPaginaActual(llaveAgrupacion, llavePagina);
      // FIN DE CONSULTA DE PERMISOS

      handleAbrirBackDrop();

      // await cargarMaestroExpertis();
      // await cargarMaestroEducacion();
      // await cargarHistoricoPlazaLaboralEmpleado();
      handleCerrarBackDrop();
    };

    cargar();
  }, []);

  const cargarDatos = async (url) => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      console.log(session);
      setUserName(session.userName);
    }

    try {
      handleAbrirBackDrop();

      let uri = url;
      //await delay(1000000);
      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        if (request.data.response) {
          return request.data.ficha;
        } else {
          console.log("EXCEPTION: " + request.data.mensaje);
          toast.error(
            "Hubo un error al cargar los datos. " + request.data.mensaje
          );
          return [];
        }
        //setVerbos(request.data.datos);
      }
    } catch (e) {
      console.log(e);
      toast.error("Hubo un error al cargar los datos. " + e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const guardarDato = async (url, key, values) => {
    const data = {
      CodigoEmpleadoPlazaLaboral: key,
      DPI: values ? props.dataEmpleado.DPI : null,
      CodigoPlazaLaboral: values ? values.CodigoPlazaLaboral : null,
      CodigoTemporada: values ? values.CodigoTemporada : null,
      CodigoExpertis: values ? values.CodigoExpertis : null,
      FechaInicio: values ? values.FechaInicio : null,
      FechaFin: values ? values.FechaFin : null,
      Activo: values ? (values.Activo ? true : false) : null,
      Eliminado: false,
      UsuarioInserto: userName,
      FechaInserto: "",
      UsuarioModifico: "",
      FechaModifico: "",
    };

    console.log(data);
    // dataFicha.usuarioInserto = "prueba";
    try {
      handleAbrirBackDrop();
      let vIsSignedIn = await isSignedIn();
      if (vIsSignedIn.response) {
        let request;

        request = await API.post(url, data);
        if (request.status !== 200) {
          toast.error(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        } else {
          if (request.data.response) {
            toast.success("Se guardó exitosamente");
          } else {
            toast.error(
              "Hubo un error al realizar la operación: " + request.data.mensaje
            );
          }
        }
      }
    } catch (e) {
      console.log("EXCEPTION: " + e);
      toast.error(
        "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
      );
    } finally {
      handleCerrarBackDrop();
    }
  };

  const onExporting = async (e) => {};

  const getNotificacion = async () => {
    let session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    }
    try {
      handleAbrirBackDrop();
      let codigo = codigoNotificacion; //session.codigoUsuario;

      const request = await API.fetchGetRequest(
        `api/ResponsabilidadPuestoLaboral/${codigo}`
      );

      if (request.data == null) {
        return;
      }

      setSelected(request.data.ficha);
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  useEffect(() => {
    if (codigoNotificacion > 0) {
      getNotificacion();
    }
  }, [codigoNotificacion]);

  return (
    <React.Fragment>
      <Backdrop className={classes.backdrop} open={buscando}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer />

      {historicoPuestoPorEmpleado !== undefined ? (
        <Paper className={classes.rootTabla}>
          <DataGrid
            dataSource={historicoPuestoPorEmpleado}
            //   defaultColumns={this.props.columns}
            showBorders={true}
            rowAlternationEnabled={true}
            //ref={(ref) => (dataGrid = ref)}
            allowColumnReordering={true}
            allowColumnResizing={true}
            columnAutoWidth={true}
            onExporting={onExporting}
            className={classes.table}
            id="tablaExperienciaEmpleado"
            onRowUpdating={(options) => {
              options.newData = { ...options.oldData, ...options.newData };
            }}
          >
            <FilterRow visible={showFilterRow} applyFilter={currentFilter} />
            <HeaderFilter visible={showHeaderFilter} />
            <GroupPanel visible={false} />
            <Grouping autoExpandAll={autoExpandAll} />
            <SearchPanel visible={false} width={240} placeholder="Search..." />
            <Selection mode="multiple" />
            <LoadPanel
              enabled={true}
              shadingColor="rgba(0,0,0,0.4)"
              showIndicator={true}
              shading={true}
              showPane={true}
              closeOnOutsideClick={false}
            />
            <Editing
              mode="row"
              useIcons={true}
              allowAdding={true}
              allowUpdating={true}
              allowDeleting={true}
            />

            {/* <Column
            caption="Opciones"
            allowFiltering={false}
            allowSorting={false}
            cellRender={cellOpcionesHistorico}
          /> */}
            <Column
              dataField="CodigoEmpleadoPlazaLaboral"
              caption="Código DB"
              visible={false}
            />
            <Column dataField="DPI" caption="DPI" visible={false} />
            {/* <Column
              dataField="NombreCompleto"
              caption="NombreCompleto"
              allowEditing={false}
            /> */}
            <Column
              dataField="CodigoTemporada"
              caption="Temporada"
              setCellValue={handleChangeTemporada}
            >
              <Lookup
                dataSource={datosMaestroTemporada}
                valueExpr="CodigoTemporada"
                displayExpr="Temporada"
                // value={temporadaSeleccionada}
                // onValueChanged={handleChangeTemporada}
                // applyValueMode="instantly"
              />
              <RequiredRule message="Seleccione la temporada" />
            </Column>
            {/* <Column dataField="CodigoAnterior" caption="Código" /> */}

            <Column dataField="CodigoPlazaLaboral" caption="Puesto Laboral">
              <Lookup
                dataSource={datosMaestroPlazaLaboral}
                valueExpr="CodigoPlazaLaboral"
                displayExpr="PuestoLaboral"
              />
              <RequiredRule message="Seleccione el puesto laboral" />
            </Column>
            {/* <Column dataField="CodigoTipoTurno" caption="Tipo Turno">
              <Lookup
                dataSource={maestroExpertis}
                valueExpr="CodigoPlazaLaboral"
                displayExpr="Puesto Laboral"
              />
            </Column> */}
            <Column dataField="CodigoExpertis" caption="Expertis">
              <Lookup
                dataSource={datosMaestroExpertis}
                valueExpr="CodigoExpertis"
                displayExpr="Expertis"
              />
              <RequiredRule message="Seleccione la experiencia" />
            </Column>
            <Column
              dataField="FechaInicio"
              caption="Fecha Inicio"
              dataType="date"
              // format="dd/MM/yyyy"
            />
            <Column
              dataField="FechaFin"
              caption="Fecha Fin"
              dataType="date"
              // format="dd/MM/yyyy"
            />
            <Column dataField="Activo" caption="Activo" />

            <ColumnFixing enabled={true} />
            <Sorting mode="multiple" />
            <Paging defaultPageSize={20} />
            <Pager
              showPageSizeSelector={true}
              allowedPageSizes={[10, 20, 50]}
              showInfo={true}
            />
            <Export enabled={true} allowExportSelectedData={true} />
            <Scrolling columnRenderingMode="virtual" />
          </DataGrid>
        </Paper>
      ) : undefined}
    </React.Fragment>
  );
};

export default FuncionesPuestoLaboral;
