import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { green, pink } from "@material-ui/core/colors";
import { useForm, Controller } from "react-hook-form";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";

import Layout from "../../layout/containers/Layout";
import ButtonAdd from "../../helpers/components/button-add";
import API from "../../utils/api";
import { isSignedIn, signOut } from "../../utils/auth";
import { validaPermisoPaginaActual } from "../../utils/helpers";
import { ToastContainer, toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";

const width_proportion = "100%";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 210,
  },
  textFieldDireccion: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginTop: theme.spacing.unit * 4,
    minWidth: 800,
    margin: theme.spacing(1),
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  greenAvatar: {
    fontSize: "2.5em",
    color: "#fff",
    // borderStyle:'solid',
    // borderWidth:'1 !important',
    // borderColor:'#3f51b5 !important',
    backgroundColor: green[500],
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  paperCard: {
    padding: 20,
    width: "100%",
  },
  formControlSelects: {
    margin: theme.spacing(1),
    minWidth: 230,
  },
  formControlSelect: {
    // margin: theme.spacing(1),
    width: width_proportion,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

let idEmpleado = 0;

const ObjetivoPuestoLaboral = (props) => {
  const {
    handleSubmit,
    control,
    formState: { errors: fieldsErrors },
    reset,
    register,
  } = useForm();

  const [userName, setUserName] = useState("");
  //const [empleadoDireccion, setEmpleadoDireccion] = useState([{}]);
  const [objetivo, setObjetivo] = useState("");

  const [cargandoMaestros, setCargandoMaestros] = useState(false);

  const [buscando, setBackDrop] = useState(false);

  const handleCerrarBackDrop = () => {
    setBackDrop(false);
  };
  const handleAbrirBackDrop = () => {
    setBackDrop(true);
  };

  const classes = useStyles();

  useEffect(() => {
    const cargar = async () => {
      if (props.puestoLaboral !== undefined) {
        handleAbrirBackDrop();

        await cargarObjetivo(props.puestoLaboral.CodigoPuestoLaboral);

        handleCerrarBackDrop();
      }
    };

    cargar();
  }, [props.puestoLaboral]);

  const cargarObjetivo = async (idPuesto) => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/PuestoLaboral/Objetivo/" + idPuesto;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setObjetivo(request.data.ficha.Objetivo);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cuandoHayError = async (data) => {
    toast.info(
      "Existen algunos campos con error, por favor valida el formulario",
      {
        position: "top-right",
        autoClose: 3000, // Esta en milisegundos 3 segundos
      }
    );
  };

  const guardarFicha = async () => {
    const data = {
      CodigoPuestoLaboralObjetivo: 0,
      CodigoPuestoLaboral: props.puestoLaboral.CodigoPuestoLaboral,
      Objetivo: objetivo,
      activo: true,
      eliminado: false,
      usuarioInserto: userName,
      fechaInserto: "",
      usuarioModifico: "",
      fechaModifico: "",
    };

    console.log(data);
    // dataFicha.usuarioInserto = "prueba";
    try {
      handleAbrirBackDrop();
      let vIsSignedIn = await isSignedIn();
      if (vIsSignedIn.response) {
        let request;

        request = await API.post("api/PuestoLaboral/Objetivo/Guardar", data);
        if (request.status !== 200) {
          toast.error(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        } else {
          toast.success("Se guardó exitosamente");
        }
      }
    } catch (e) {
      console.log("EXCEPTION:" + e);
      toast.error(
        "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
      );
    } finally {
      handleCerrarBackDrop();
    }
  };

  return (
    <React.Fragment>
      <Backdrop className={classes.backdrop} open={buscando}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer />
      <Grid container spacing={3}>
        <form
          autoComplete="off"
          onSubmit={handleSubmit(guardarFicha, cuandoHayError)}
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FormControl className={classes.textFieldDireccion}>
              <Controller
                name="objetivo"
                render={({ OBJETIVO }) => (
                  <TextField
                    id="objetivo"
                    label="Objetivo"
                    name="celular"
                    value={objetivo}
                    error={fieldsErrors.objetivo ? true : false}
                    onChange={(e) => setObjetivo(e.target.value)}
                    variant="outlined"
                    multiline
                    rowsMax={4}
                    helperText={
                      fieldsErrors.objetivo
                        ? fieldsErrors.objetivo.message
                        : null
                    }
                  />
                )}
                control={control}
                // rules={{
                //   required: "Es requerido",
                // }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={9} sm={12} md={12} lg={12}>
            <Button
              width="100%"
              variant="contained"
              style={{
                backgroundColor: "#69bd4b",
                marginBottom: 10,
                marginTop: 10,
                color: "white",
                width: "93%",
                marginLeft: 30,
              }}
              type="submit"
            >
              &nbsp;Guardar Cambios
            </Button>
            <br />
            <br />
          </Grid>
        </form>
      </Grid>
    </React.Fragment>
  );
};

export default ObjetivoPuestoLaboral;
