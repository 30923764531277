import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";
import Chip from "@material-ui/core/Chip";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

//Own components
import TableTitle from "../../helpers/components/table-title";
import TableDescription from "../../helpers/components/table-description";
import Link from "../../helpers/components/link";
import { convertToDayMonthYear } from "../../utils/helpers";

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 2,
    overflowX: "auto"
  },
  table: {
    minWidth: 700
  },
  noVistas:{
    color: "#2196F3",
    fontWeight: "bold"
  }
});

function SimpleTable(props) {
  const { classes, data } = props;

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: "30%" }}>Notificación</TableCell>
            <TableCell style={{ width: "23%" }}>Grupo(s)</TableCell>
            <TableCell style={{ width: "22%" }}>Envío</TableCell>
            <TableCell style={{ width: "15%" }}>Estado</TableCell>
            <TableCell style={{ width: "10" }}>Vistas únicas</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, i) => (
            <TableRow
              key={i}
              
            >
              <TableCell onClick={() => props.editar(row.CodigoNotificacion)} style={{cursor: "pointer"}}>
                <TableTitle>{row.Titulo}</TableTitle>
                <br />
                <TableDescription>{row.DescripcionCorta}</TableDescription>
              </TableCell>
              <TableCell onClick={() => props.editar(row.CodigoNotificacion)}>
                {row.ListadoGrupos.map((grupo, index) => (
                  <>
                    <Chip
                      style={{backgroundColor:"#2196F3", color:"white"}}
                      label={grupo}
                      className={classes.chip}
                    />
                    &nbsp;
                  </>
                ))}
              </TableCell>
              <TableCell>{convertToDayMonthYear(row.FechaEnvio)}</TableCell>
              <TableCell>{row.NombreEstado}</TableCell>
              <TableCell>
                <Button onClick={() => props.onClickNoVistas(row.CodigoNotificacion)} className={classes.noVistas}>
                  {row.NoVistas}
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}

export default withStyles(styles)(SimpleTable);
