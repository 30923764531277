import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";

import Layout from "../../layout/containers/Layout";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Map from "./map";
import ReportarCovid from "./reportar-covid";
import {validaPermisoPaginaActual} from "../../utils/helpers";


 function ContactTracingLayout(props) {
 useEffect(async()=>{
    const permisos = async()=>{
      //CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
      const llaveAgrupacion = "Menu.Principal.Web";
      const llavePagina = "Menu.Principal.Web.ContactTracing";
      await validaPermisoPaginaActual(llaveAgrupacion, llavePagina);
      //FIN DE CONSULTA DE PERMISOS
    }

    permisos();
 },[])
  return (
    <Layout
      titulo={"CT"}
      bodyContainerStyle={{
        padding: 0,
        margin: 0,
        maxWidth: "none",
        height: "100%",
      }}
      bodyHeader={false}
    >
      <VerticalTabs></VerticalTabs>
    </Layout>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: "100%",
    backgroundColor: "#F6F7F9",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    backgroundColor: "white",
  },
  papers: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(16),
    },
  },
  rootGestion: {
    flexGrow: 1,
    //marginTop: 20,
  },

  tabPanelClass: {
    width: "100%",
  },
  tabPanelClassMap: { width: "100%" },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

function VerticalTabs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        className={classes.tabs}
      >
        <Tab label="REPORTAR COVID" {...a11yProps(0)} />
        {/*  <Tab label="REPORTE" {...a11yProps(1)} /> */}
      </Tabs>
      <TabPanel value={value} index={0} className={classes.tabPanelClassMap}>
        <div className={classes.rootGestion}>
          <ReportarCovid />
        </div>
      </TabPanel>
      {/* <TabPanel value={value} index={1} className={classes.tabPanelClass}>
        <Map />
      </TabPanel> */}
      <ToastContainer />
    </div>
  );
}

export default withRouter(ContactTracingLayout);
