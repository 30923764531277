import L from "leaflet";
import "leaflet/dist/leaflet.css";
import React, { useEffect, useRef } from "react";
import API from "../../utils/api";

//Dos opciones: recibe un arreglo de coordenadas de la siguiente forma:
// [
//   {
//     Coordenadas: [[14.10813, -90.94152]],
//     FechaHora: "2021-01-13T14:00:00",
//     Foto: "https://AWS.../foto.jpg",
//   }, ...
// ]
//O recibe un código de aviso, y obtiene las coordenadas de la base de datos. Además, cambia el tooltip

const MapaLeaflet = ({ coordenadas, aviso }) => {
    const mapRef = useRef(null);


    useEffect(() => {
        if (coordenadas === undefined || coordenadas.length === 0) return;
        // var container = L.DomUtil.get('map');
        // if (container != null) {
        //     container._leaflet_id = null;
        // }
        const map = L.map(mapRef.current).setView([0, 0], 2);

        L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
            attribution: 'Ingenio La Unión',
            maxZoom: 18,
        }).addTo(map);

        const markers = coordenadas.map((coordenada) => {
            let tooltipContent = `
              ${coordenada.Foto && coordenada.Foto !== '' ? `<img src="${coordenada.Foto}" alt="Photo" style="max-width: 100%; height: auto;">` : ``}
              <p>${coordenada.FechaHora}</p>
            </div>
          `;

            return L.marker(coordenada.Coordenadas[0], { icon: defaultIcon })
                .bindTooltip(tooltipContent, { sticky: true, direction: "top" })
                .addTo(map);
        });

        map.fitBounds(L.featureGroup(markers).getBounds());

        return () => {
            map.remove();
        };
    }, [coordenadas]);

    useEffect(() => {
        console.log(aviso);
        if (aviso === undefined || aviso === 0) return;
        try {
            const fetchData = async () => {
                let uri = `api/asistencia/getMapaAviso?aviso=${aviso}`;
                const request = await API.fetchGetRequest(uri);
                //setCoordenadas(request.data.data);
                return request.data.data;
            }
            fetchData().then((datos) => {

                // var container = L.DomUtil.get('map');
                // if (container != null) {
                //     container._leaflet_id = null;
                // }
                const map = L.map(mapRef.current).setView([0, 0], 2);

                L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
                    attribution: 'Ingenio La Unión',
                    //maxZoom: 4,
                }).addTo(map);

                const tooltipContent = `
            <div>
              <p>${datos.Ubicacion}</p>
            </div>
          `;
                const latlng = [datos.Latitud, datos.Longitud];
                let marker = L.marker(latlng, { icon: defaultIcon })
                    .bindTooltip(tooltipContent, { sticky: true, direction: "top" })
                    .addTo(map);

                map.setView(latlng, 12); // Zoom to the marker
                console.log(datos);

                return () => {
                    map.remove();
                };
            });
        } catch (error) {
            console.log(error);
        }

    }, [aviso]);

    const defaultIcon = new L.icon({
        iconUrl: require('../../../node_modules/leaflet/dist/images/marker-icon.png'), // your path may vary ...
        iconAnchor: [12, 41],
    });

    return <div id="map" style={{ height: "400px" }} ref={mapRef} />;
};

export default MapaLeaflet;