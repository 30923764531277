import React, { useEffect, useState } from "react";
import {
  makeStyles,
  AppBar,
  Toolbar,
  Typography,
  CssBaseline,
  Container,
  Grid,
  Card,
  CardActions,
  CardContent,
  Button,
  Modal,
  TextField,
  Box,
  Avatar, // <-- Importa Avatar
} from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../utils/api";
import { isSignedIn } from "../utils/auth";
import logo from "../image-repository/logo.png";

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  root: {
    margin: theme.spacing(2),
    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
    transition: "0.3s",
    "&:hover": {
      boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)",
    },
    borderRadius: 15,
  },
  media: {
    height: 140,
    objectFit: "cover",
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
  },
  title: {
    fontSize: 14,
    color: theme.palette.primary.main,
  },
  pos: {
    marginBottom: 12,
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  textField: {
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  cardGrid: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    margin: 0,
  },
  container: {
    backgroundColor: "#f0f0f0",
    paddingTop: theme.spacing(10),
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    paddingLeft: 0,
    paddingRight: 0,
  },
  header: {
    textAlign: "center",
    marginBottom: theme.spacing(4),
  },
  cardHeader: {
    backgroundColor: "#202e3c",
    padding: theme.spacing(2),
    color: theme.palette.primary.contrastText,
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
  },
  cardContent: {
    padding: theme.spacing(2),
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#202e3c",
    width: "100%",
    position: "fixed",
    top: 0,
    left: 0,
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  logo: {
    height: 40,
    [theme.breakpoints.down("sm")]: {
      height: 30,
    },
  },
  title: {
    flexGrow: 1,
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
    },
  },
  gridContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: "100%",
  },
}));

const CalificacionObligatoria = () => {
  const classes = useStyles();
  const history = useHistory();

  const [listaGestiones, setListaGestiones] = useState([]);
  const [open, setOpen] = useState(false);
  const [gestionSeleccionada, setGestionSeleccionada] = useState(null);
  const [calificacion, setCalificacion] = useState(10);
  const [comentario, setComentario] = useState("");
  const [hover, setHover] = useState(-1);

  useEffect(() => {
    getObtenerCalificacion();
  }, []);

  const getObtenerCalificacion = async () => {
    try {
      const session = await isSignedIn();
      const response = await api.fetchGetRequest(
        `api/aviso/getGestionesPendientesCalificar/${session.codigoUsuario}`
      );
      if (!response.data.response) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = response.data.asignados;
      if (Array.isArray(data) && data.length > 0) {
        data.sort(
          (a, b) =>
            new Date(a.FechaInserto).getTime() -
            new Date(b.FechaInserto).getTime()
        );
        setListaGestiones(data);
      } else {
        setListaGestiones([]);
        history.push("/home");
      }
    } catch (error) {
      console.error(
        "Error al obtener las gestiones pendientes de calificar:",
        error
      );
      setListaGestiones([]);
      history.push("/home");
    }
  };

  const handleOpenModal = (gestion) => {
    setGestionSeleccionada(gestion);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    setGestionSeleccionada(null);
  };

  const handleGuardar = async () => {
    if (calificacion === null || calificacion < 1) {
      toast.warn("Por favor selecciona una cantidad de estrellas.");
      return;
    }

    try {
      const session = await isSignedIn();

      const data = {
        CodigoAviso: gestionSeleccionada.CodigoAviso,
        Calificacion: calificacion,
        Comentario: comentario,
        CodigoUsuarioOpera: session.codigoUsuario,
      };

      const request = await api.post(`api/aviso/calificar`, data);
      if (request.status !== 200) {
        alert(
          "Hubo un error al guardar el registro, por favor revisa los datos de tu formulario o consulta con un administrador"
        );
        return;
      }

      if (request.status === 401) {
        //alert("cerrar sesion");
      } else {
        setOpen(false);
        await getObtenerCalificacion();
      }
    } catch (e) {
      alert("hubo un error");
    }
  };

  return (
    <div style={{ backgroundColor: "#f0f0f0", minHeight: "100vh" }}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <a href="/">
            <img src={logo} className={classes.logo} alt="logo" />
          </a>
          {/* <Typography variant="h6" className={classes.title}>
            Tienes {listaGestiones.length} gestiones pendientes de calificar
          </Typography> */}
        </Toolbar>
      </AppBar>

      <Container maxWidth={false} className={classes.container}>
        <Typography variant="h3" className={classes.header}>
          Tienes gestiones pendientes de calificar, Total{" "}
          {listaGestiones.length}{" "}
        </Typography>
        <Grid container spacing={2} className={classes.gridContainer}>
          {listaGestiones.length > 0 ? (
            listaGestiones.map((gestion) => (
              // <Grid item xs={12} sm={6} md={4} lg={3} key={gestion.CodigoAviso}>
              //   <Card className={classes.root}>
              //     <div className={classes.cardHeader}>
              //       <Typography variant="h6">{gestion.Titulo}</Typography>
              //       <Typography variant="body2">
              //         {new Date(gestion.FechaInserto).toLocaleDateString()}
              //       </Typography>
              //     </div>
              //     <CardContent className={classes.cardContent}>
              //       <Typography
              //         className={classes.title}
              //         color="textSecondary"
              //         gutterBottom
              //       >
              //         {gestion.Nombre}
              //       </Typography>
              //       <Typography variant="body2" component="p">
              //         {gestion.Descripcion}
              //       </Typography>
              //     </CardContent>
              //     <CardActions>
              //       <Button
              //         size="small"
              //         color="primary"
              //         onClick={() => handleOpenModal(gestion)}
              //       >
              //         Calificar
              //       </Button>
              //     </CardActions>
              //   </Card>
              // </Grid>

              // FUNCIONA EXCELENTE
              // <Grid item xs={12} sm={6} md={4} lg={3} key={gestion.CodigoAviso}>
              //   <Card className={classes.root}>
              //     <div className={classes.cardHeader}>
              //       <Typography variant="h6">{gestion.Titulo}</Typography>
              //       <Typography variant="body2">
              //         {new Date(gestion.FechaInserto).toLocaleDateString()}
              //       </Typography>
              //       <Typography variant="body2" style={{ color: "#FFFFFF" }}>
              //         Código Aviso: {gestion.CodigoAviso}
              //       </Typography>
              //     </div>
              //     <CardContent className={classes.cardContent}>
              //       <Typography
              //         className={classes.title}
              //         color="textSecondary"
              //         gutterBottom
              //       >
              //         {gestion.Nombre}
              //       </Typography>
              //       <Typography variant="body2" component="p">
              //         {gestion.Descripcion}
              //       </Typography>
              //     </CardContent>
              //     <CardActions>
              //       <Button
              //         size="small"
              //         color="primary"
              //         onClick={() => handleOpenModal(gestion)}
              //       >
              //         Calificar
              //       </Button>
              //     </CardActions>
              //   </Card>
              // </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3} key={gestion.CodigoAviso}>
                <Card className={classes.root}>
                  <div className={classes.cardHeader}>
                    <Typography variant="h6">{gestion.Titulo}</Typography>
                    <Typography variant="body2">
                      {new Date(gestion.FechaInserto).toLocaleDateString()}
                    </Typography>
                    <Typography variant="body2" style={{ color: "#FFFFFF" }}>
                      Código Aviso: {gestion.CodigoAviso}
                    </Typography>
                    <Typography variant="body2" style={{ color: "#FFFFFF" }}>
                      Califica a: {gestion.NombreUsuarioInserto}
                    </Typography>
                    {/* {gestion.ImagenPerfilUsuario && (
                      <Avatar
                        alt={gestion.NombreUsuarioInserto}
                        src={gestion.ImagenPerfilUsuario}
                        className={classes.avatar}
                      />
                    )} */}
                  </div>
                  <CardContent className={classes.cardContent}>
                    <Typography
                      className={classes.title}
                      color="textSecondary"
                      gutterBottom
                    >
                      {gestion.Nombre}
                    </Typography>
                    <Typography variant="body2" component="p">
                      {gestion.Descripcion}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => handleOpenModal(gestion)}
                    >
                      Calificar
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))
          ) : (
            <Typography variant="h6">
              No hay gestiones pendientes de calificar.
            </Typography>
          )}
        </Grid>

        <Modal
          open={open}
          onClose={handleCloseModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className={classes.paper}>
            <Typography variant="h5" gutterBottom>
              Encuesta de satisfacción
            </Typography>
            <Typography component="legend">
              Indica tu satisfacción de 1 a 10 estrellas.
            </Typography>
            <Box
              component="fieldset"
              mb={3}
              borderColor="transparent"
              style={{ textAlign: "center" }}
            >
              <Rating
                name="customized-10"
                defaultValue={10}
                max={10}
                onChange={(event, newValue) => {
                  setCalificacion(newValue);
                }}
                onChangeActive={(event, newHover) => {
                  setHover(newHover);
                }}
              />
              <Box ml={2}>
                {
                  {
                    1: "Terrible",
                    2: "Malo+",
                    3: "Meh",
                    4: "Ok",
                    5: "Bueno",
                    6: "Hmm...",
                    7: "Muy bueno",
                    8: "Wow",
                    9: "Me gustó",
                    10: "Más que Increíble",
                  }[hover !== -1 ? hover : calificacion]
                }
              </Box>
            </Box>
            <TextField
              id="txtComentario"
              label="Comentario"
              className={classes.textField}
              value={comentario}
              onChange={(e) => setComentario(e.target.value)}
              margin="normal"
              variant="outlined"
              multiline
              rows={4}
              required
            />
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={handleGuardar}
            >
              Enviar
            </Button>
          </div>
        </Modal>
        <ToastContainer />
      </Container>
    </div>
  );
};

export default CalificacionObligatoria;
