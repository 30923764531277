import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import { Delete, Edit, Visibility } from "@material-ui/icons";

import React from "react";

function FileList({ files, onDownload, onDelete, contenedorURL }) {
  return (
    <List>
      {files.map((file, index) => (
        <ListItem key={index}>
          {/* <IconButton
            edge="start"
            aria-label="delete"
            onClick={() => onDelete(file)}
            size="large"
            style={{ color: red[500] }}
          >
            <Delete />
          </IconButton> */}
          <ListItemText primary={file.Alias} />
          <ListItemText
            primary={new Date(file.FechaInserto).toLocaleDateString("es-ES")}
          />
          <ListItemSecondaryAction>
            {/* <IconButton
              edge="end"
              aria-label="edit"
              onClick={() => onDownload(file.url)}
              size="large"
            >
              <Edit />
            </IconButton> */}
            <IconButton
              edge="end"
              aria-label="download"
              onClick={() => onDownload(contenedorURL + file.Archivo)}
              size="large"
            >
              <Visibility />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  );
}

export default FileList;
