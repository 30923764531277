import React from "react";
import TextField from "@material-ui/core/TextField";

//Iconos
import AccessTimeIcon from '@material-ui/icons/AccessTime';

function InputHora(props){
    const {CodigoCampo, Nombre} = props;
    return(
        <div style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
          <AccessTimeIcon style={{color: "#2196F3", marginRight: 10}} />
          <TextField
            disabled
            id={`txt${CodigoCampo}`}
            label={Nombre}
            style={{width:"100%"}}
            value=""
            margin="normal"
            variant="outlined"
            inputProps={{zIndex: 0,}}
          />
        </div> 
    )
}

export default InputHora;