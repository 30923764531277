import {
  Backdrop, Button, CircularProgress, Grid
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { toast, ToastContainer } from "react-toastify";
import API from "../../utils/api";
import { isSignedIn } from "../../utils/auth";

import SaveIcon from '@material-ui/icons/Save';

import FormControl from "@material-ui/core/FormControl";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';


const width_proportion = "100%";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 210,
  },
  textFieldDireccion: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginTop: theme.spacing.unit * 4,
    minWidth: 800,
    margin: theme.spacing(1),
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  greenAvatar: {
    fontSize: "2.5em",
    color: "#fff",
    // borderStyle:'solid',
    // borderWidth:'1 !important',
    // borderColor:'#3f51b5 !important',
    backgroundColor: green[500],
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  paperCard: {
    padding: 20,
    width: "100%",
  },
  formControlSelects: {
    margin: theme.spacing(1),
    minWidth: 230,
  },
  formControlSelect: {
    // margin: theme.spacing(1),
    width: width_proportion,
  },
  formControl: {
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  datoContent: {
    alignItems: 'center',
    "border-style": "groove",
    "border-width": "thin",
    margin: "0px"
  },
  labelContainer: {
    display: 'flex',
    flex: 0.40,
    alignItems: 'center',
    fontWeight: '600',
    fontSize: '1rem',
  },
  datoContainer: {
    marginTop: '0.30rem',
    width: '1400px',
    margin: 'auto'
  },
  datoTxt: {
    flex: 0.60,
    fontSize: '1rem',
    fontWeight: '600',
    color: '#000000a6'
  },
  icon: {
    fontSize: '1.25rem',
    color: '#147085',
    marginRight: '0.5rem',
  },
  titulo: {
    "text-align": "center",
    fontWeight: "700",
  },
  datoContentActividad: {
    alignItems: 'center',
    //"border-style": "groove",
    //"border-width": "thin"
  },
  button: {
    textAlign: "right",
    marginBottom: "10px",
  }
}));

const Firmas = (props) => {

  const [userName, setUserName] = useState("");
  const [buscando, setBackDrop] = useState(false);
  const [catalogoFirmas, setCatalogoFirmas] = useState([]);
  const [firmas, setFirmas] = useState(null);
  const [firmaElabora, setFirmaElabora] = useState("");
  const [firmaRevisa, setFirmaRevisa] = useState("");
  const [firmaAutoriza, setFirmaAutoriza] = useState("");
  const [firmaGdt, setFirmaGdt] = useState("");


  const handleCerrarBackDrop = () => {
    setBackDrop(false);
  };
  const handleAbrirBackDrop = () => {
    setBackDrop(true);
  };

  const classes = useStyles();

  useEffect(() => {
    const cargar = async () => {
      handleAbrirBackDrop();
      await cargarRelaciones(props.puestoLaboral.CodigoPuestoLaboral);
      handleCerrarBackDrop();
    };

    cargar();
  }, [props.puestoLaboral]);

  const cargarRelaciones = async (idPuesto) => {
    let session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    }
    try {
      handleAbrirBackDrop();
      const request = await API.fetchGetRequest(`api/PuestoLaboral/Firmas/${idPuesto}`);

      if (request.data.data == null) {
        return;
      }
      console.log(request);
      setCatalogoFirmas(request.data.data.Catalogo);
      setFirmas(request.data.data.Firmas);
      if (request.data.data.Firmas != null) {
        setFirmaAutoriza(request.data.data.Firmas.DPIAutoriza);
        setFirmaElabora(request.data.data.Firmas.DPIElabora);
        setFirmaRevisa(request.data.data.Firmas.DPIRevisa);
        setFirmaGdt(request.data.data.Firmas.DPIRevisaGdt);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const postData = async (data) => {
    let session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    }
    try {
      handleAbrirBackDrop();
      const request = await API.post(
        "api/PuestoLaboral/Firmas/guardar",
        data
      );
      if (request.status !== 200) {
        toast.error(
          "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
        );
      } else {
        toast.success("Se guardó exitosamente");
      }
      console.log(request);
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const getDefault = (firmaFuente) => {
    let firma = catalogoFirmas.find(element => element.Usuario === firmaFuente);
    if (firma != null) {
      return firma;
    }
    return null;
  }


  return (
    <React.Fragment>
      <Backdrop className={classes.backdrop} open={buscando}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer />

      <div className={classes.button}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<SaveIcon />}
          onClick={() => {
            postData({
              //CodigoPuestoLaboral: props.puestoLaboral.CodigoPuestoLaboral,
              //Relaciones: relacionesInternas.concat(relacionesExternas),
              UsuarioInserto: userName,
              CodigoPuestoLaboral: props.puestoLaboral.CodigoPuestoLaboral,
              DPIElabora: firmaElabora,
              DPIAutoriza: firmaAutoriza,
              DPIRevisa: firmaRevisa,
              DPIRevisaGdt: firmaGdt,
              // CodigoJornadaLaboral: CodigoJornada,
              // CodigoLicenciaConducir: CodigoLicencia,
              // CodigoUbicacionLaboral: CodigoUbicacion,
              // DisponibilidadViajar: DisponibilidadViajar,
              // ExperienciaMinimaTxt: ExperienciaMinimaTxt,
              // ExperienciaRequeridaTxt: ExperienciaRequeridaTxt,
              // UsuarioInserto: userName,
              // CodigoPuestoLaboral: props.puestoLaboral.CodigoPuestoLaboral
            });
          }}
        >Guardar</Button>
      </div>
      <Grid container spacing={3} style={{ marginTop: "auto" }}>
        <Grid item md={6} className={classes.datoContent} style={{ backgroundColor: "gainsboro" }}>
          <Typography variant="h6" className={classes.titulo}>Elabora</Typography>
        </Grid>
        <Grid item md={6} className={classes.datoContent} style={{ backgroundColor: "gainsboro" }}>
          <Typography variant="h6" className={classes.titulo}>Revisa</Typography>
        </Grid>

        <Grid item md={6} className={classes.datoContent}>
          <FormControl className={classes.formControl}>
            {/* <InputLabel htmlFor="grouped-select">Puesto Laboral...</InputLabel>
            <Select value={firmaElabora} id="grouped-select" fullWidth={true} onChange={(event) => (setFirmaElabora(event.target.value))}>
              {catalogoFirmas.map((puesto, idx) => (
                <MenuItem key={idx} value={puesto.CodigoPuestoLaboral}>{puesto.CodigoPuestoLaboral + ' - ' + puesto.PuestoLaboral}</MenuItem>
              ))}
            </Select> */}

            <Autocomplete
              //disablePortal
              id="combo-box-demo"
              options={catalogoFirmas || null}
              value={getDefault(firmaElabora)}
              //inputValue={catalogoFirmas[1] || ''}
              //getOptionSelected={(option, value) => { console.log(value); return option.CodigoPuestoLaboral === value.CodigoPuestoLaboral }}
              getOptionLabel={(option) => option.NombreCompletoUsuario}
              onChange={(event, newValue) => (setFirmaElabora(newValue.Usuario))}
              //sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Elabora..." />}
            />
          </FormControl>
        </Grid>

        <Grid item md={6} className={classes.datoContent}>
          <FormControl className={classes.formControl}>

            <Autocomplete
              //disablePortal
              id="combo-box-demo"
              options={catalogoFirmas || null}
              value={getDefault(firmaRevisa)}
              getOptionLabel={(option) => option.NombreCompletoUsuario }
              onChange={(event, newValue) => (setFirmaRevisa(newValue.Usuario))}
              //sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Revisa..." />}
            />
          </FormControl>
        </Grid>


        <Grid item md={6} className={classes.datoContent} style={{ backgroundColor: "gainsboro" }}>
          <Typography variant="h6" className={classes.titulo}>Autoriza</Typography>
        </Grid>
        <Grid item md={6} className={classes.datoContent} style={{ backgroundColor: "gainsboro" }}>
          <Typography variant="h6" className={classes.titulo}>Gestión de Talento</Typography>
        </Grid>

        <Grid item md={6} className={classes.datoContent}>
          <FormControl className={classes.formControl}>
            <Autocomplete
              //disablePortal
              id="combo-box-demo"
              options={catalogoFirmas || null}
              value={getDefault(firmaAutoriza)}
              getOptionLabel={(option) => option.NombreCompletoUsuario}
              onChange={(event, newValue) => (setFirmaAutoriza(newValue.Usuario))}
              //sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Autoriza..." />}
            />
          </FormControl>
        </Grid>

        <Grid item md={6} className={classes.datoContent}>
          <FormControl className={classes.formControl}>
            <Autocomplete
              //disablePortal
              id="combo-box-demo"
              options={catalogoFirmas || null}
              value={getDefault(firmaGdt)}
              getOptionLabel={(option) => option.NombreCompletoUsuario}
              onChange={(event, newValue) => (setFirmaGdt(newValue.Usuario))}
              //sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Revisa GdT..." />}
            />
          </FormControl>
        </Grid>










      </Grid>



    </React.Fragment >
  );
};

export default Firmas;
