import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";

import CircularProgress from "@material-ui/core/CircularProgress";
import Mensaje from "../../helpers/components/message";
import TareasCardPercentaje from "./tareas-card-porcentaje";

import TareaBox from "./tarea-box";
import API from "../../utils/api";

import ModalCrearTarea from "../containers/modal-crear-tarea";

import DataGrid, {
  Column,
  Pager,
  Paging,
  Sorting,
  ColumnFixing,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Grouping,
  GroupPanel,
  Export,
  Selection,
  Editing,
  Scrolling,
  Button,
} from "devextreme-react/data-grid";
import { Grid } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import DevexpressPercentColumn from "../../super-filtro/components/devexpress-percent-column";
import { Tooltip } from "@material-ui/core";
import { PlayForWork, Visibility } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  tituloPaper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

function ListadoTareas(props) {
  const classes = useStyles();

  const [tarea, setTarea] = useState(null);
  const [tareas, setTareas] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [permisoCrearNuevaTarea, setPermisoCrearNuevaTarea] = useState(true);
  const [porcentajeAvance, setPorcentajeAvance] = useState(0);

  const [totalTareas, setTotalTareas] = useState(0);
  const [tareasTerminadas, setTareasTerminadas] = useState(0);

  const [openCrearTarea, setOpenCrearTarea] = useState(false);

  let applyFilterTypes = [
    {
      key: "auto",
      name: "Immediately",
    },
    {
      key: "onClick",
      name: "On Button Click",
    },
  ];

  //const [tipoGrid, setTipoGrid] = useState(true);
  const [positionDisableSorting, setPositionDisableSorting] = useState(false);
  const [showFilterRow, setShowFilterRow] = useState(true);
  const [showHeaderFilter, setShowHeaderFilter] = useState(true);
  const [currentFilter, setCurrentFilter] = useState(applyFilterTypes[0].key);
  const [autoExpandAll, setAutoExpandAll] = useState(true);

  useEffect(() => {
    makeRequestGestion();
  }, []);

  useEffect(() => {
    if (props.refresh != 1) {
      makeRequest();
    }
  }, [props.refresh]);

  const makeRequest = () => {
    if (props.codigoTareaSubTareas !== null) {
      if (props.codigoTareaSubTareas === 0) {
        makeRequestGestion();
      } else {
        makeResquestSubTareas();
      }
    } else {
      makeRequestGestion();
    }
    // setTipoGrid(props.verTareasGrid)
  };

  const makeRequestGestion = async () => {
    const request = await API.fetchGetRequest(
      `api/aviso/tareas/${props.match.params.codigoGestion}?nivel=1`
    );
    if (request.data.response) {
      if (request.data.data.length > 0) {
        setPermisoCrearNuevaTarea(request.data.data[0].PermiteAgregarTareas);
      }
      let avance = 0;
      request.data.data.forEach(
        (element) => (avance += element.PorcentajeAvance)
      );
      setPorcentajeAvance(Math.round(avance / request.data.data.length));
      setTareas(request.data.data);
    }
    setIsLoading(false);
  };

  const makeResquestSubTareas = async () => {
    setIsLoading(true);
    const request = await API.fetchGetRequest(
      `api/aviso/tareas/0?codigoAvisoTarea=${props.codigoTareaSubTareas}`
    );

    if (request.data.response) {
      setTareas(request.data.data);
      let avance = 0;
      request.data.data.forEach(
        (element) => (avance += element.PorcentajeAvance)
      );
      setPorcentajeAvance(Math.round(avance / request.data.data.length));
      setTarea(request.data.tarea);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setTarea(null);
    if (props.codigoTareaSubTareas !== null) {
      if (props.codigoTareaSubTareas === 0) {
        makeRequestGestion();
      } else {
        makeResquestSubTareas();
      }
    }
  }, [props.codigoTareaSubTareas]);

  useEffect(() => {
    const totalTareas = tareas.length;
    const tareasTerminadas = tareas.filter(
      (f) => f.CodigoEstadoTarea === 3 || f.CodigoEstadoTarea === 4
    ).length;

    setTotalTareas(totalTareas);
    setTareasTerminadas(tareasTerminadas);
  }, [tareas]);

  const cellOpciones = (rowInfo) => {
    return (
      <>
        <Tooltip
          title="Ver detalle"
          style={{ marginRight: 10, cursor: "pointer" }}
        >
          <Visibility
            style={{ color: "#337ab7", cursor: "pointer" }}
            onClick={
              () => props.onClickTarea(rowInfo.data)
              // handleAbrirDialogoEliminar(rowInfo.data.CodigoPlazaLaboral)
            }
          />
        </Tooltip>
        {rowInfo.data.CantidadTareas > 0 && (
          <Tooltip
            title="Ver subtareas"
            style={{ marginRight: 10, cursor: "pointer" }}
          >
            <PlayForWork
              style={{ color: "#337ab7", cursor: "pointer" }}
              onClick={
                () => props.onClickSubTareas(rowInfo.data)
                // handleAbrirDialogoEliminar(rowInfo.data.CodigoPlazaLaboral)
              }
            />
          </Tooltip>
        )}
      </>
    );
  };

  const listarUsuarios = (rowInfo) => {
    let list = rowInfo.data.Usuarios;
    if (Array.isArray(list)) {
      return (
        <Grid>
          {list.map((item, i) => (
            <div>
              <Chip
                label={item}
                size="small"
                color="primary"
                style={{ marginLeft: 5 }}
              />
              <br />
              <br />
            </div>
          ))}
        </Grid>
      );
    }
  };

  return (
    <div>
      {/* <div class={classes.tituloPaper}>
        <div class={classes.container}>
          <Typography variant="h5" gutterBottom>
            Tareas
          </Typography>
        </div>
      </div> */}
      <TareasCardPercentaje
        percent={
          totalTareas == 0
            ? 100
            : Math.round((tareasTerminadas / totalTareas) * 100)
        }
        tareasTerminadas={tareasTerminadas}
        porcentajeAvance={porcentajeAvance}
        totalTareas={totalTareas}
        percentColor={"#69BD4B"}
        permisoCrearTarea={permisoCrearNuevaTarea}
        onClickCrearTarea={() => setOpenCrearTarea(true)}
      />
      {isLoading && (
        <div>
          <CircularProgress />
        </div>
      )}
      {tareas.length <= 0 && !isLoading && (
        <div>
          <Mensaje>No hay tareas disponibles.</Mensaje>
        </div>
      )}

      {!props.verTareasGrid &&
        tareas.map((tarea, i) => {
          console.log("tareas datos", tarea);
          var usuarios = "";
          var estilo = "Pendiente";
          //var estilo = "Completo";
          // var icono = "md-checkmark";
          var icono = "md-time";
          // var colorEstado = "#2ECC71";
          var colorEstado = "#F2CB00";

          if (tarea.CodigoEstadoTarea == 1 || tarea.CodigoEstadoTarea == 2) {
            estilo = "Pendiente";
            icono = "md-time";
          } else if (tarea.CodigoEstadoTarea == 3) {
            estilo = "Completo";
            icono = "md-checkmark";
            colorEstado = "#2ECC71";
          } else if (tarea.CodigoEstadoTarea == 4) {
            estilo = "Cancelado";
            colorEstado = "#e74c3c";
          }

          if (tarea.Usuarios != null && tarea.Usuarios.length > 0) {
            usuarios = tarea.Usuarios.join(", ");
          }

          const colorBox =
            estilo == "Completo"
              ? "#69BD4B"
              : estilo == "Cancelado"
              ? "#e74c3c"
              : "#979797";

          return (
            <TareaBox
              key={i}
              numero={tarea.Orden}
              codigo={tarea.CodigoAvisoTarea}
              onClick={() => props.onClickTarea(tarea)}
              onClickSubTareas={() => props.onClickSubTareas(tarea)}
              tarea={tarea}
              estilo={estilo}
              titulo={tarea.Titulo}
              nombreAsignado={usuarios}
              usuarios={tarea.Usuarios}
              estado={tarea.Estado.Nombre}
              icono={icono}
              colorEstado={colorEstado}
              colorBox={colorBox}
              tareaSeleccionada={props.tareaSeleccionada}
              porcentajeAvance={tarea.PorcentajeAvance}
              colorSemaforo={tarea.colorSemaforo}
            />
          );
        })}

      {props.verTareasGrid && (
        <DataGrid
          dataSource={tareas}
          //   defaultColumns={this.props.columns}
          showBorders={true}
          rowAlternationEnabled={true}
          //ref={(ref) => (dataGrid = ref)}
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
          // onExporting={onExporting}
          className={classes.table}
        >
          <FilterRow visible={showFilterRow} applyFilter={currentFilter} />
          <HeaderFilter visible={showHeaderFilter} />
          <GroupPanel visible={false} />
          <Grouping autoExpandAll={autoExpandAll} />
          <SearchPanel visible={false} width={240} placeholder="Search..." />
          {/* <Selection mode="multiple" /> */}
          <Editing
            mode="row"
            useIcons={true}
            allowUpdating={false}
            allowDeleting={false}
          />
          <Column
            caption="Opciones"
            allowFiltering={false}
            allowSorting={false}
            cellRender={cellOpciones}
          />
          <Column
            dataField="Titulo"
            caption="Título"
          />
           <Column
            dataField="PorcentajeAvance"
            caption="Avance %"
            dataType="number"
            format="percent"
            alignment="right"
            allowGrouping={false}
            cellRender={DevexpressPercentColumn}
            cssClass="bullet"
          />
          <Column
            dataField="UsuariosAsignados"
            caption="Usuarios Asignados"
            cellRender={listarUsuarios}
          >
            <HeaderFilter visible={false} />
            <FilterRow visible={false} />
          </Column>

          {/* 
          <Column
            dataField="CodigoPlazaLaboral"
            caption="Código Plaza Laboral"
            visible={false}
          />
          <Column
            dataField="CodigoPlazaLaboralPadre"
            caption="Código Plaza Laboral Padre"
            visible={false}
          />
          <Column
            dataField="CodigoUnidadOrganizacion"
            caption="Código Unidad Organización"
            visible={false}
          />
          <Column
            dataField="CodigoPuestoLaboral"
            caption="Código Puesto Laboral"
            visible={false}
          />
          <Column dataField="PuestoLaboral" caption="Puesto Laboral" />
          <Column dataField="Disponibilidad" caption="Disponibilidad" />
          <Column dataField="Grado" caption="Grado" />
          <Column
            dataField="CodigoPlazaLaboralPadre"
            caption="Código Puesto Laboral Padre"
            visible={false}
          />
          <Column dataField="PuestoLaboralPadre" caption="Puesto Laboral Padre" /> */}
          <ColumnFixing enabled={true} />
          <Sorting mode="multiple" />
          <Paging defaultPageSize={20} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[10, 20, 50]}
            showInfo={true}
          />
          <Export enabled={true} allowExportSelectedData={true} />
          <Scrolling columnRenderingMode="virtual" />
        </DataGrid>
      )}

      <ModalCrearTarea
        open={openCrearTarea}
        handleClose={() => setOpenCrearTarea(false)}
        codigoAvisoTarea={props.codigoTareaSubTareas}
        nivel={tarea === null ? 1 : tarea.Nivel + 1}
        makeRequest={() => makeRequest()}
      />
    </div>
  );
}
export default withRouter(ListadoTareas);
