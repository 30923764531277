import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import EncabezadoAvance from "./encabezado-avance";
import ProgressBar from "./progress-bar"
import TreeIcon from "../../PlantillaFlujo/tree-icon";
import EventIcon from '@material-ui/icons/Event';

import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { Card } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    marginBottom: 15,
    cursor: "pointer",
  },
  container: {
    display: "flex",
    flexDirection: "row",
  },
  content: {
    display: "flex",
    flex: 1,
  },
  bar: {
    marginRight: 10,
    width: 5,
  },
  title: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    textAlign: "left",
  },
  classChips: {
    textAlign: "left",
    marginBottom: 5,
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  arrow: {
    margin: 10,
    alignItems: "center",
    color: "#2196F3",
    display: "flex",
  },
  state: {
    textAlign: "left",
    margin: 4,
  },
}));

export default function ListadoTareas(props) {
  const classes = useStyles();
  const [usuarios, setUsuarios] = useState([]);
  const [isPressed, setIsPressed] = useState(false);

  useEffect(() => {
    setUsuarios(props.usuarios);
  }, [props.usuarios]);

  useEffect(() => {
    if (props.tareaSeleccionada == props.codigo) {
      setIsPressed(true);
    } else {
      setIsPressed(false);
    }
  }, [props.tareaSeleccionada]);

  function onClickCard() {
    props.onClick();
  }

  function onClickSubTareas(e){
    e.stopPropagation();
    props.onClickSubTareas();
  }

  return (
    <div>
    <ProgressBar bgcolor={"#6a1b9a"} completed={props.porcentajeAvance} />
    <Paper
      className={classes.paper}
      onClick={() => onClickCard()}
      style={{ backgroundColor: isPressed ? "#e2f2ff" : "white" }}
    >
      
      <div className={classes.container}>
        
        <div
          className={classes.bar}
          style={{ backgroundColor: props.color }}
        ></div>
        <div className={classes.content}>
          <div>
            <div className={classes.title}>
              <Avatar
                alt={props.numero}
                style={{ backgroundColor: props.colorEstado, color: "white" }}
              >
                {props.numero}
              </Avatar>
              <p style={{ marginLeft: 5 }}> {props.titulo}</p>
            </div>
            <div className={classes.classChips}>
              {usuarios.map((usuario, i) => (
                <Chip
                  size="small"
                  avatar={
                    <Avatar>{usuario.substring(0, 1).toUpperCase()}</Avatar>
                  }
                  label={
                    usuario.length > 19
                      ? usuario.substring(0, 19) + ".."
                      : usuario
                  }
                />
              ))}
            </div>
            {/*<EncabezadoAvance percent={props.porcentajeAvance} /> */}
            {(props.tarea.FechaVencimientoFriendly!="N/A") && (
            <div className={classes.classChips}>
              <Chip
                icon={<EventIcon />}
                size="small"
                style={{ backgroundColor: props.tarea.colorSemaforo}}
                label={props.tarea.FechaVencimientoFriendly=="N/A" ? "N/A Fecha Vencimiento" : props.tarea.FechaVencimientoFriendly}
              />
            </div>
            )}
            {(props.tarea.CantidadTareas>0) && (
            <div className={classes.classChips}>
            <Chip
                  style={{ margin: 5 }}
                  icon={<TreeIcon />}
                  size="small"
                  label={"Subtareas:"+props.tarea.CantidadTareasCompletadas+"/"+props.tarea.CantidadTareas}
                  onClick={(e)=>onClickSubTareas(e)}
                />
            </div>
            )}
          </div>
        </div>
        <div className={classes.arrow}>
          <NavigateNextIcon />
        </div>
      </div>
    </Paper>
    </div>
  );
}