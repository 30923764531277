import React, { Fragment } from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { formatRelative, parse, parseISO } from 'date-fns';
import { useHistory } from "react-router-dom";
import { es } from 'date-fns/locale';

import StepTarea from './step-tarea';
import UserInfo from '../../usuario-info/containers/user-info';
import UsuarioInfo from '../../helpers/components/usuario-info';

const useStyles = makeStyles(theme => ({
    gestionContainer: {
        borderBottom: '1px solid #BCBCBC',
        marginBottom: '1rem'
    },
    titulo: {
        color: '#000000a6',
        fontWeight: '600',
        cursor: 'pointer'
    },
    subtitulo: {
        color: '#000000',
        fontWeight: '500',
        cursor: 'pointer'
    },
    classChips: {
        textAlign: "left",
        "& > *": {
            margin: theme.spacing(0.5),
        },
    },
}));

const GestionItem = ({ item }) => {

    const classes = useStyles();
    const history = useHistory();

    const getIniciales = (nombre) => {
        const _nombre = nombre ? nombre.toUpperCase() : 'NA'
        return `${_nombre[0]}${_nombre[1]}`
    }

    return (
        <Grid container className={classes.gestionContainer}>
            <Grid item container direction='column' md={3}>
                <Typography onClick={() => history.push(`/gestion/${item.CodigoAviso}`)} variant="h5" className={classes.titulo}>{item.Titulo}</Typography>
                <Typography onClick={() => history.push(`/gestion/${item.CodigoAviso}`)} variant="h6" className={classes.subtitulo}>#{item.CodigoAviso}</Typography>
                <Grid container direction='column' style={{ margin: '0.5rem 0' }}>
                    <Typography variant="subtitle1">
                        Usuario afectado / solicitante
                    </Typography>
                    <div className={classes.classChips}>
                        <UsuarioInfo
                            userName={item.Usuario ? item.Usuario.Usuario : ''}
                            imagenPerfil={item.Usuario ? item.Usuario.ImagenPerfil : ''}
                            iniciales={getIniciales(item.Usuario ? item.Usuario.UsuarioAD : '')}
                            nombre={item.Usuario ? item.Usuario.NombreCompletoUsuario : ''}
                            descripcion={`Creó la gestión ${formatRelative(parseISO(item.FechaInserto), new Date(), { locale: es })}`}
                        />
                    </div>
                    {/* <UserInfo userName={item.Usuario ? item.Usuario.UsuarioAD : ''}> */}
                    {/* </UserInfo> */}
                </Grid>
            </Grid>
            <Grid item md={9} style={{ overflowX: 'auto' }}>
                <StepTarea data={item.Tareas} />
            </Grid>
        </Grid>
    )
}

export default GestionItem;