import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";
import Loader from "react-loader-spinner";
import Popover from "@material-ui/core/Popover";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Modal from "@material-ui/core/Modal";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import AppsIcon from "@material-ui/icons/Apps";
import FolderIcon from "@material-ui/icons/Folder";
import ViewListIcon from "@material-ui/icons/ViewList";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import SearchIcon from "@material-ui/icons/Search";
import { Chip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { IOSSwitch } from "../../helpers/components/ios-switch";
import CheckIcon from "@material-ui/icons/Check";

import axios from "axios";

//Icons
import GetAppIcon from "@material-ui/icons/GetApp";
import BarChartIcon from "@material-ui/icons/BarChart";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CloseIcon from "@material-ui/icons/Close";

//Accordeon
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

//Scroll bar
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";

//Appbar
import { fade } from "@material-ui/core/styles";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Divider from "@material-ui/core/Divider";

//Own components
import Layout from "../../layout/containers/Layout";
import ButtonAdd from "../../helpers/components/button-add";
import API from "../../utils/api";
import { isSignedIn, signOut } from "../../utils/auth";
import { convertToYearMonthDay } from "../../utils/helpers";
import CardAvisos from "../../ejecucion-avisos/components/card-aviso";
import { validaPermisoPaginaActual } from "../../utils/helpers";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";

import {
  clearSuperfiltroHistory,
  getSuperfiltroHistory,
  setSuperfiltroHistory,
} from "../../utils/historialSuperfiltro";
//Lista Etiquetas
// import TagList from "../../avisos-objects/components/tag-list";
// import SecondNavigation from "../components/second-navigation";
// import GenericButtonFloat from "../../helpers/components/generic-button-float";
// import ResultTablePagination from "../components/result-table-pagination";

import DevExpressDataGridHabitaciones from "../habitaciones/devexpress-data-grid-habitaciones";

import Tooltip from "@material-ui/core/Tooltip";

import moment from "moment";

//CSS
import "../../ejecucion-avisos/components/gestion-style.css";
import { DATE_COL_DEF, removeUndefinedProps } from "@material-ui/data-grid";

import ReservaForm from "../reserva/reserva-form";
import HabitacionForm from "../habitaciones/habitacion-form";
import AddButton from "../../helpers/components/add-button-float";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
  },
  paper: {
    position: "relative",
    width: "100%", //theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  paperUserModal: {
    position: "absolute",
    width: "75%",
    height: "600px",
    maxHeight: "600px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  popover: {
    pointerEvents: "none",
  },
  paperPopover: {
    padding: theme.spacing(1),
    background: "#000",
    color: "#fff",
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
  rootExpansionPanel: {
    width: "100%",
    marginTop: 30,
    marginBottom: 50,
  },
  ExpansionPanelRow: {},
  alignCenter: {
    alignItems: "center",
  },
  dowloadButton: {
    backgroundColor: "white",
    color: "#2196F3",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#f3f3f3",
      color: "#2196F3",
    },
  },
  titleGestiones: {
    display: "flex",
    flexDirection: "row",
  },
  heading: {
    marginLeft: 15,
  },
  secondNavigationModal: {
    position: "absolute",
    width: "50%",
    height: "400px",
    maxHeight: "400px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
  drawer: {
    width: 300,
    maxWidth: 300,
  },
});

const titleColor = "#2B3C4D";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

class Habitaciones extends Component {
  state = {
    value: 0,
    openDrawer: false,
    idHabitacion: 0,
    nombre: null,
    numero: 0,
    usuarioCreacion: null,
    usuarioModificacion: null,
    fechaCreacion: null,
    fechaModificacion: null,
    activo: null,
    eliminado: null,
    listaHabitaciones: [],
    buscando: false,
    expanded: false,


    // listaUsuarios: [],
    // usuarioSolicitanteActual: null,
    // usuarioAutorizaActual: null,
    // usuarioHotelAutorizaActual: null,
    // idUsuarioSolicitante: 0,
    // idUsuarioAutoriza: 0,
    // idUsuarioHotelAutoriza: 0,
    // checkout: null,
    eliminada: false,

    //layout
    openToolbar: false,
    reservaId: null,
    fechaDisponibilidad: null,
    listaDisponibilidad: [],
    openModalDisponibilidad: false,
  };

  async componentDidMount() {
    //CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
    const llaveAgrupacion = "Menu.Principal.Web";
    const llavePagina = "Menu.Principal.Web.Mantenimiento.Habitaciones";
    await validaPermisoPaginaActual(llaveAgrupacion, llavePagina);
    //FIN DE CONSULTA DE PERMISOS

    const session = await isSignedIn();

    let fechaInicio = new Date();
    fechaInicio.setDate(fechaInicio.getDate() - 30);

    if (session.response) {
      this.setState(
        {
          fechaInicio: convertToYearMonthDay(fechaInicio),
          fechaFin: convertToYearMonthDay(new Date()),
        },
        async () => {
          await this.cargarListaHabitaciones();
        }
      );
    } else {
      window.location.href = "/login";
    }
  }

  cerrarModalHabitaciones = () => {
    console.log("CerrarModalHabitaciones");
    this.setState({
      openModalEtiquetas: false,
      idHabitacion: null,
      nombre: null,
      numero: null,
    });
    this.cargarListaHabitaciones();
  };

  cargarDatosEdicion = async (habitacionId) => {
    this.setState({ openModalEtiquetas: true, idHabitacion: habitacionId });
  };

  inhabilitarHabitacion = async (habitacionId) => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    }

    try {
      let uri = `api/admonhotel/desactivarHabitacion/${habitacionId}/${session.CodigoUsuario}`;

      const request = await API.post(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        //El servidor nos devolvio que todo ok.
        this.buscar();
      }
    } catch (e) {
      console.log(e);
    } finally {
    }
  };

  remover = async (reservaId) => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    }

    try {
      let uri = `api/admonhotel/anularReserva/${reservaId}/${session.CodigoUsuario}`;

      const request = await API.post(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        //El servidor nos devolvio que todo ok.
        this.buscar();
      }
    } catch (e) {
      console.log(e);
    } finally {
    }
  };

  async cargarListaHabitaciones() {
    console.log("que ondas");
    const request = await API.fetchGetRequest(
      `api/admonHotel/habitacionesList?todas=true`
    );
    if (Array.isArray(request.data.data)) {
      let listaHabit = request.data.data;
      // listaHabit.push({
      //   id: 0,
      //   Nombre: "Todas",
      //   Descripcion: "",
      //   Activo: true,
      // });

      this.setState({
        listaHabitaciones: listaHabit,
      });
    }
  }

  limpiar = () => {
    this.setState({
      usuarioSolicitanteActual: null,
      usuarioAutorizaActual: null,
      usuarioHotelAutorizaActual: null,
      idUsuarioSolicitante: 0,
      idUsuarioAutoriza: 0,
      idUSuarioHotelAutoriza: 0,

      buscando: false,
      expanded: false,
      //layout
      openToolbar: false,
    });
  };

  handleChange = (name) => (event) => {
    if (
      name == "pendientes" ||
      name == "terminados" ||
      name == "slaVencido" ||
      name == "slaVigente"
    ) {
      this.setState({ [name]: event.target.checked });
    } else {
      this.setState({
        [name]: event.target.value,
      });
    }
  };

  handleChangeCheck = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  handleChangeSwitch = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  handlePopoverOpen = (event, textoPopover) => {
    this.setState({
      anchorEl: event.currentTarget,
      popOverOpen: true,
      textoPopover: textoPopover,
    });
  };

  handlePopoverClose = () => {
    this.setState({ anchorEl: null, popOverOpen: false, textoPopover: "" });
  };

  handleDrawerOpen = async () => {
    this.setState({ openToolbar: true });
  };

  handleDrawerClose = () => {
    this.setState({ openToolbar: false });
  };

  toggleConfigurationDrawer = (side, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    this.setState({ [side]: open });
  };

  redireccionar = (codigoGestion) => {
    this.props.history.push(`/gestion/${codigoGestion}`);
    //window.location.href="/gestion/" + codigoGestion;
  };

  render() {
    const { classes } = this.props;

    return (
      <Layout titulo={"Habitaciones Hotel"} maxWidth="xl">
        {/* <Container maxWidth={"xl"} disableGutters={true} > */}
        <div style={{ width: "100%", maxWidth: "100% !important" }}>
          <Paper style={{ width: "100%", maxWidth: "100% !important" }}>
            <AppBar
              position="static"
              color="default"
              style={{ width: "100% !important" }}
            ></AppBar>
            <div
              style={{ width: "100% !important" }}
              // axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              // index={this.state.valorTab}
              // onChangeIndex={this.handleChangeIndexTabs}
            >
              <Grid container spacing={3} style={{ width: "100% !important" }}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <div
                    className={classes.root}
                    style={{ width: "100% !important" }}
                  >
                    <CssBaseline />
                    
                    <main
                      className={clsx(classes.content, {
                        [classes.contentShift]: this.state.openToolbar,
                      })}
                      style={{ minHeight: 400 }}
                    >
                      <div className={classes.drawerHeader} />
                      <Grid item xs={12} lg={12} sm={12}>
                        {/* AQUI EL CONTENIDO PRINCIPAL DE RESULTADOS */}
                        {/* <PerfectScrollbar
                    style={{
                      width: "100%",
                      height: 375,
                      maxHeight: 375,
                    }}
                  > */}
                        {this.state.buscando ? (
                          <div
                            style={{
                              width: "100%",
                              marginTop: 10,
                              textAlign: "center",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Loader
                              type="Oval"
                              color="#3498db"
                              height="50"
                              width="50"
                              style={{ textAlign: "center" }}
                            />
                          </div>
                        ) : (
                          <></>
                        )}

                        {/* VISTA DE TABLA */}
                        {this.state.listaHabitaciones.length > 0 && (
                          <DevExpressDataGridHabitaciones
                            filas={this.state.listaHabitaciones}
                            inhabilitarHabitacion={this.inhabilitarHabitacion}
                            cargarDatosEdicion={this.cargarDatosEdicion}
                            remover={this.remover}
                          />
                        )}
                        {this.state.listaHabitaciones.length < 1 &&
                          !this.state.buscando && (
                            <div
                              style={{
                                fontStyle: "italic",
                                color: "#b2bec3",
                              }}
                            >
                              No hay resultados para mostrar.
                            </div>
                          )}
                        {/* </PerfectScrollbar> */}
                      </Grid>
                    </main>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Paper>
        </div>
        {/* </Container> */}

        {/* SUPERFILTRO */}
        {/*  */}
        {/* <GenericButtonFloat onClick={this.handleDrawerOpen}>
          <SearchIcon />
        </GenericButtonFloat> */}
        <ToastContainer />
        <Popover
          id="mouse-over-popover"
          className={classes.popover}
          classes={{
            paper: classes.paperPopover,
          }}
          open={this.state.popOverOpen}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          onClose={this.handlePopoverClose}
          disableRestoreFocus
        >
          <Typography>{this.state.textoPopover}</Typography>
        </Popover>

        <Modal
          // style={{ zIndex: 2990 }}
          aria-labelledby="Asignar etiquetas"
          aria-describedby="Asignar etiquetas"
          open={this.state.openModalEtiquetas}
          onClose={() => {
            this.setState({ openModalEtiquetas: false });
          }}
        >
          <div style={getModalStyle()} className={classes.paperUserModal}>
            <PerfectScrollbar style={{ paddingRight: 30 }}>
              <form className={classes.container} noValidate autoComplete="off">
                <Typography variant="h6" gutterBottom>
                  Agregar habitación
                </Typography>

                <HabitacionForm
                  habitacionId={this.state.idHabitacion}
                  cerrarModalHabitaciones={this.cerrarModalHabitaciones}
                />
              </form>
            </PerfectScrollbar>
          </div>
        </Modal>

        <Modal
          // style={{ zIndex: 2990 }}
          aria-labelledby="Disponibilidad"
          aria-describedby="Disponibilidad"
          open={this.state.openModalDisponibilidad}
          onClose={() => {
            this.setState({ openModalDisponibilidad: false });
          }}
        >
          <div style={getModalStyle()} className={classes.paperUserModal}>
            <PerfectScrollbar style={{ paddingRight: 30 }}>
              <form className={classes.container} noValidate autoComplete="off">
                <Typography variant="h6" gutterBottom>
                  Verificar disponibilidad
                </Typography>

                <Grid container>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextField
                      id="fechaDisponibilidad"
                      label="Fecha a consultar"
                      type="date"
                      //defaultValue={new Date()-10}
                      style={{ width: "100%" }}
                      value={this.state.fechaDisponibilidad}
                      onChange={this.handleChange("fechaDisponibilidad")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#69bd4b",
                        marginBottom: 10,
                        marginTop: 10,
                        color: "white",
                        width: "100%",
                      }}
                      className={classes.buttonAdd}
                      onClick={() => {
                        this.consultarDisponibilidad();
                      }}
                    >
                      Buscar
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    {this.state.listaDisponibilidad.map((item, index) => (
                      <Paper key={`disp${item.Id}`} style={{ padding: 25 }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <CheckIcon
                            style={{ color: "green", marginRight: 8 }}
                          />
                          <h3>{item.Nombre}</h3>
                        </div>
                      </Paper>
                    ))}
                  </Grid>
                </Grid>
              </form>
            </PerfectScrollbar>
          </div>
        </Modal>
        {/* <ButtonAdd
          style={{ width: 36, height: 36 }}
          onClick={(event) => {
            this.setState({ openModalEtiquetas: true });
          }}
        /> */}

        <AddButton
          style={{ zIndex: 1000 }}
          onClick={(event) => {
            this.setState({ openModalEtiquetas: true });
          }}
        />
      </Layout>
    );
  }
}
const HabitacionesScreen = withStyles(styles)(Habitaciones);
export default withRouter(HabitacionesScreen);
