import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import MaskedInput from "react-text-mask";
import { toast } from "react-toastify";

//Select
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Autocomplete from "@material-ui/lab/Autocomplete";

//Upload file
import { DropzoneArea } from "material-ui-dropzone";

//Checkbox
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
//import "../components/styles.css";

//Drawer

import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import CloseIcon from "@material-ui/icons/Close";
import FunctionsIcon from "@material-ui/icons/Functions";
import Alert from "@material-ui/lab/Alert";
import Loader from "react-loader-spinner";
import API from "../../utils/api";
import { isSignedIn, signOut } from "../../utils/auth";
import DataGrid from "../components/data-grid";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
  },
  paper: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
  paperUserModal: {
    position: "absolute",
    width: "75%",
    height: "600px",
    maxHeight: "600px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
  paperModal: {
    position: "absolute",
    width: "90%",
    height: "600px",
    maxHeight: "600px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
  paperNuevoForm: {
    position: "absolute",
    width: "50%",
    height: "300px",
    maxHeight: "300px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
  bigPaper: {
    position: "absolute",
    width: "95%",
    height: "95%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    alignItems: "center",
  },
});

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0-9]/, /[0-9]/, ":", /[0-5]/, /[0-9]/]}
      placeholderChar={"\u2000"}
      showMask
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

class GenericForm extends Component {
  state = {
    codigoFormDeCampo: null,
    plantilla: [],
    plantillaValores: [],
    camposObligatoriosVacios: [],
    isLoadingPlantilla: false,
    location: { lat: 0, lon: 0 },
    codigoLocal: 0,
    codigoGrid: 0,
  };

  async componentDidMount() {
    if (
      !isNaN(this.props.CodigoFormDeCampo) &&
      !isNaN(this.props.CodigoCampoBase) &&
      !isNaN(this.props.CodigoLocal)
    ) {
      //CodigoLocal es un identificador temporal (en memoria) para los registros que se crean en tiempo de ejecución
      const session = await isSignedIn();
      if (session.response) {
        //Cargar lista subentidades
        this.setState(
          {
            codigoFormDeCampo: parseInt(this.props.CodigoFormDeCampo),
            codigoCampoBase: parseInt(this.props.CodigoCampoBase),
            codigoUsuarioActual: session["codigoUsuario"],
            codigoLocal: this.props.CodigoLocal,
            codigoGrid: this.props.CodigoGrid,
          },
          async () => {
            await this.getPlantillaCampos();
            this.getLocation();
          }
        );
      } else {
        window.location.href = "/login";
      }
    }
  }

  getPlantillaCampos = async () => {
    const { problema, codigoFormDeCampo } = this.state;

    try {
      const uri = `api/aviso/plantilla/${codigoFormDeCampo}`;
      const request = await API.fetchGetRequest(uri);

      if (request.statusCode === 401) {
        if (await signOut()) {
          window.location.href = "/login";
        }
        return;
      }

      if (request.data.response) {
        //OK
        let plantillaValores = [];

        request.data.data.map((campo, i) => {
          let valor = null;

          if (campo.CodigoTipoCampo == 3) {
            //Es un campo de tipo fecha y ponemos default hoy
            const hoy = new Date();
            const date = hoy.toISOString().slice(0, 10);
            valor = date;
          }

          campo.Valor = valor;

          if (campo.ValorPorDefecto != null && campo.CodigoTipoCampo !== 20) {
            campo.Valor = campo.ValorPorDefecto;
          }

          plantillaValores.push(campo);
        });

        this.setState({
          plantilla: request.data.data,
          plantillaValores: plantillaValores,
          isLoadingPlantilla: false,
        });
      } else {
        //El API alerta error.
        this.setState({
          isLoadingPlantilla: false,
        });
      }
    } catch (e) {}
  };

  getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        let location = this.state.location;
        location.lon = position.coords.longitude;
        location.lat = position.coords.latitude;
        this.setState({ location: location });
      });
    } else {
      toast.warn = "Su navegador no soporta geolocalización";
    }
  };

  handleChangeFiles(campo, images) {
    console.log(images);
    console.log(campo);
    let { plantillaValores } = this.state;

    const find = plantillaValores.findIndex(
      (f) => f.CodigoCampo == campo.CodigoCampo
    );

    if (find >= 0) {
      let val = plantillaValores[find];

      let imagenes = images.map((image) => image.path);
      val.AttrVista = imagenes;

      //let files = [];
      let files = images;

      //images.map((image, idx) => {
      //let pathParts = ""; // image.path.split("/");
      // files.push({
      //   uri: image.path,
      //   type: image.type,
      //   name: image.name, //[pathParts.length - 1],
      // });
      // });

      val.Valor = files;

      plantillaValores[find] = val;
    }

    this.setState({ plantillaValores });
  }

  onSelectedItemsCamposDinamicos = (campo) => (event) => {
    console.log("Onchange");
    let { plantillaValores } = this.state;
    console.log(event.target);

    let options = event.target.value;
    console.log(options);
    if (!Array.isArray(options)) {
      options = [options];
    }

    const val = {
      CodigoCampo: campo.CodigoCampo,
      CodigoTipoCampo: campo.CodigoTipoCampo,
      Valor: options,
      Requerido: campo.Requerido,
      CodigoCampoPadre: campo.CodigoCampoPadre,
      LlaveCampo: campo.LlaveCampo,
    };

    const find = plantillaValores.findIndex(
      (f) => f.CodigoCampo == campo.CodigoCampo
    );

    if (find >= 0) {
      plantillaValores[find] = val;
    } else {
      plantillaValores.push(val);
    }

    this.setState({ plantillaValores });
    this.verificarDependencias(val);
  };

  onSelectedItemsCamposDinamicosAutocomplete = async (
    event,
    newValue,
    campo
  ) => {
    console.log("Onchange");
    let { plantillaValores } = this.state;
    console.log(event.target);

    let options = newValue.id;
    console.log(options);
    if (!Array.isArray(options)) {
      options = [options];
    }

    const val = {
      CodigoCampo: campo.CodigoCampo,
      CodigoTipoCampo: campo.CodigoTipoCampo,
      Valor: options,
      Requerido: campo.Requerido,
      CodigoCampoPadre: campo.CodigoCampoPadre,
      LlaveCampo: campo.LlaveCampo,
    };

    const find = plantillaValores.findIndex(
      (f) => f.CodigoCampo == campo.CodigoCampo
    );

    if (find >= 0) {
      plantillaValores[find] = val;
    } else {
      plantillaValores.push(val);
    }

    this.setState({ plantillaValores });
  };

  verificarDependencias = async (campo) => {
    let { plantillaValores, plantilla } = this.state;

    if (campo.CodigoTipoCampo == 23) {
      //@rda: CAMPOS TIPO MCU (Maco Culero, jajaja Maestro Conector Universal)
      let valor = null;
      if (Array.isArray(campo.Valor) && campo.Valor.length > 0) {
        valor = campo.Valor[0];
      }
      this.getItemsAPIFieldByParent(campo.CodigoCampo, campo.Valor);
    } else {
      //CAMPOS DE SAP
      let find = plantillaValores.filter(
        (f) => f.CodigoCampoPadre == campo.CodigoCampo
      );

      if (find.length > 0) {
        //Mandar a traer al api los valores de cada uno de los campos dependientes
        await find.map(async (campoALlenar, i) => {
          const uri =
            campoALlenar.LlaveCampo == "picklistSap"
              ? `api/sap/avisos/llenarDropdown/${campoALlenar.CodigoCampo}?valor=${campo.Valor}`
              : campoALlenar.LlaveCampo == "picklistSapTree"
              ? `api/sap/avisos/llenarDropdownParentChild/${campoALlenar.CodigoCampo}?valor=${campo.Valor}`
              : "";

          let request = await API.fetchGetRequest(uri);

          let findCampoPlantillaIndex = plantilla.findIndex(
            (f) => f.CodigoCampo == campoALlenar.CodigoCampo
          );
          let findCampoPlantilla = plantilla[findCampoPlantillaIndex];
          findCampoPlantilla.Items = request.data.data;

          //Seteamos el valor default del campo a plantillaValores
          campoALlenar.Valor = request.data.defaultValue;
          const findPlantillaValoresIndex = plantillaValores.findIndex(
            (f) => f.CodigoCampo == campoALlenar.CodigoCampo
          );
          plantillaValores[findPlantillaValoresIndex] = campoALlenar;

          plantilla[findCampoPlantillaIndex] = findCampoPlantilla;

          await this.setState({ plantilla });
          await this.setState({ plantillaValores });
          console.log(request);
        });
      }
    }
  };

  getItemsAPIFieldByParent = async (codigoCampoPadre, valorCampoPadre) => {
    const { problema, codigoPlantilla } = this.state;

    try {
      const uri = `api/aviso/getItemsAPIFieldByParent?codigoCampoPadre=${codigoCampoPadre}&valorCampoPadre=${valorCampoPadre}`;
      const request = await API.fetchGetRequest(uri);

      if (request.statusCode === 401) {
        if (await signOut()) {
          window.location.href = "/login";
        }
        return;
      }

      if (request.data.response) {
        console.log("Nuevos Items:", request.data.data);
        let { plantilla, plantillaValores } = this.state;

        request.data.data.map((fila, i) => {
          let indexPlantillaValor = this.state.plantillaValores.findIndex(
            (x) => x.CodigoCampo === fila.CodigoCampo
          );
          let indexPlantilla = this.state.plantilla.findIndex(
            (x) => x.CodigoCampo === fila.CodigoCampo
          );
          console.log("indexPlantilla:", indexPlantilla);
          console.log("indexPlantillaValores:", indexPlantillaValor);
          plantillaValores[indexPlantillaValor].Items = fila.Items;
          plantilla[indexPlantilla].Items = fila.Items;
        });
        this.setState({ plantilla, plantillaValores });
      } else {
        //El API alerta error.
        this.setState({
          isLoadingPlantilla: false,
        });
      }
    } catch (e) {}
  };

  makeRequestGuardar = async () => {
    try {
      var response = false;
      this.setState({
        procesando: true,
        response: false,
        creacionAvisoCompletada: false,
      });

      //this.setState({ isPosting: true });
      const {
        plantilla,
        plantillaValores,
        codigoCampoBase,
        location,
        codigoLocal,
        codigoGrid,
      } = this.state;

      const validacionRequeridos =
        await this.validarCamposPersonalizadosRequeridos();

      if (!validacionRequeridos) {
        toast.warn("Llena todos los campos requeridos");
        this.setState({ procesando: false, response: false });
        response = false;
        return response;
      }

      //   let plantillaValoresTemp = plantillaValores;

      //   //Para los campos de tipo 5 - Picklist debe enviarse el texto correspondiente a su id
      //   let pickList = plantillaValoresTemp.filter((fila) => {
      //     return fila.CodigoTipoCampo === 5;
      //   });

      //  await pickList.forEach((campo) => {
      //     if (Array.isArray(campo.Valor)) {
      //       if (campo.Valor.length > 0) {
      //         //Obtiene nombre de la lista de campos
      //         let plant = plantilla.find((x) => {
      //           return x.CodigoCampo === campo.CodigoCampo;
      //         });

      //         let nameList = plant.Items;
      //         let name = nameList.find(
      //           (element) => element.id === campo.Valor[0]
      //         ).name;

      //         let newValue = {
      //           code: campo.Valor[0],
      //           name: name,
      //         };

      //         let index = plantillaValoresTemp.findIndex((el) => {
      //           return el === campo;
      //         });

      //         plantillaValoresTemp[index] = { ...campo, Valor: newValue };
      //         console.log(plantillaValoresTemp);
      //         this.setState({ plantillaValores: plantillaValoresTemp });
      //       }
      //     }else{
      //       let plant = plantilla.find((x) => {
      //         return x.CodigoCampo === campo.CodigoCampo;
      //       });

      //       let newValue = {
      //         code: null,
      //         name: "sin datos",
      //       };

      //       let index = plantillaValoresTemp.findIndex((el) => {
      //         return el === campo;
      //       });

      //       plantillaValoresTemp[index] = { ...campo, Valor: newValue };
      //       console.log(plantillaValoresTemp);
      //       this.setState({ plantillaValores: plantillaValoresTemp });
      //     }
      //   });

      await this.formateaPickList();
      //Arma fila
      let fila = await this.armarFila();

      let plantillaValoresTemp = plantillaValores;

      //const session = await isSignedIn();

      // if (session.response) {
      //   const objeto = {
      //     UsuarioInserto: session["userName"],
      //     CodigoUsuarioAfectado: usuarioAfectado,
      //     Longitud: location.lon,
      //     Latitud: location.lat,
      //     PlantillaValores: plantillaValores,
      //   };
      // }
      console.log("Lo que va a guardar", plantillaValoresTemp);
      this.props.grabarFila(
        codigoCampoBase,
        plantillaValoresTemp,
        fila,
        codigoGrid
      );
      this.setState({ isPosting: false });
      toast.info("El elemento fue agregado a la lista.");
      this.props.closeModal();
    } catch (err) {
      console.log("Grabar: " + err);
    } finally {
      this.setState({ procesando: false });
    }
  };

  armarFila = async () => {
    return new Promise((resolve, reject) => {
      try {
        let plantillaValores = this.state.plantillaValores;
        let plantilla = this.state.plantilla;
        let codigoLocal = `L${this.state.codigoLocal}`;

        let object = { codigo: codigoLocal };
        plantillaValores.forEach((elemento) => {
          let campoDePlantilla = plantilla.find((x) => {
            return x.CodigoCampo === elemento.CodigoCampo;
          });
          let nombre = campoDePlantilla.Nombre;
          let valor = "";

          if (
            elemento.CodigoTipoCampo === 5 ||
            elemento.CodigoTipoCampo === 22 ||
            elemento.CodigoTipoCampo === 23
          ) {
            valor = elemento.Valor.name;
          } else if (elemento.CodigoTipoCampo === 19) {
            //Checklist
            let nombresChecklist = "";
            if (Array.isArray(elemento.Valor)) {
              if (elemento.Valor.length > 0) {
                //Obtiene nombre de la lista de campos
                let plant = plantilla.find((x) => {
                  return x.CodigoCampo === elemento.CodigoCampo;
                });

                elemento.Valor.forEach((selectedItem) => {
                  let nameList = plant.Items;
                  let name = nameList.find(
                    (element) => element.id === selectedItem
                  ).name;

                  nombresChecklist += name + ", ";
                });
                //quita la última coma

                valor =
                  nombresChecklist.length > 0
                    ? nombresChecklist.substring(0, nombresChecklist.length - 2)
                    : "";
              }
            }
          } else {
            valor = elemento.Valor;
          }

          object = { ...object, [nombre]: valor };

          //También setea el código local a cada elemento de plantillaValores
          let indexCurrent = plantillaValores.findIndex((x) => {
            return x === elemento;
          });
          plantillaValores[indexCurrent] = {
            ...elemento,
            codigoLocal: codigoLocal,
          };
        });
        this.setState({ plantillaValores: plantillaValores });
        resolve(object);
      } catch (err) {
        reject(err);
      }
    });
  };

  formateaPickList = async () => {
    return new Promise((resolve, reject) => {
      try {
        let plantillaValoresTemp = this.state.plantillaValores;
        let plantilla = this.state.plantilla;

        //Para los campos de tipo 5 - Picklist debe enviarse el texto correspondiente a su id
        let pickList = plantillaValoresTemp.filter((fila) => {
          return (
            fila.CodigoTipoCampo === 5 ||
            fila.CodigoTipoCampo === 22 ||
            fila.CodigoTipoCampo === 23
          );
        });

        pickList.forEach((campo) => {
          if (Array.isArray(campo.Valor)) {
            if (campo.Valor.length > 0) {
              //Obtiene nombre de la lista de campos
              let plant = plantilla.find((x) => {
                return x.CodigoCampo === campo.CodigoCampo;
              });

              let nameList = plant.Items;
              let name = nameList.find(
                (element) => element.id === campo.Valor[0]
              ).name;

              let newValue = {
                code: campo.Valor[0],
                name: name,
              };

              let index = plantillaValoresTemp.findIndex((el) => {
                return el === campo;
              });

              plantillaValoresTemp[index] = { ...campo, Valor: newValue };
              console.log(plantillaValoresTemp);
              this.setState({ plantillaValores: plantillaValoresTemp });
            }
          } else {
            let plant = plantilla.find((x) => {
              return x.CodigoCampo === campo.CodigoCampo;
            });

            let newValue = {
              code: null,
              name: "sin datos",
            };

            let index = plantillaValoresTemp.findIndex((el) => {
              return el === campo;
            });

            plantillaValoresTemp[index] = { ...campo, Valor: newValue };
            console.log(plantillaValoresTemp);
            this.setState({ plantillaValores: plantillaValoresTemp });
          }
        });
        resolve(true);
      } catch (err) {
        reject(err);
      }
    });
  };

  uploadFiles = async (campo, recargarDatos, iterarKey, files) => {
    // console.log("uploading");
    // const session = await isSignedIn();
    // //let codigoTarea  = tarea.AvisoTarea.CodigoAvisoTarea;
    // if (campo.Valor != null && files.length > 0) {
    //   let data = new FormData();
    //   //data.append("CodigoObjeto", codigoTarea);
    //   //data.append("NombreTablaObjeto", props.TablaPadre);
    //   data.append("CodigoObjeto", props.CodigoPadre);
    //   data.append("NombreTablaObjeto", props.TablaPadre);
    //   data.append("UsuarioInserto", session["userName"]);
    //   data.append("CodigoCampo", campo.CodigoCampo);
    //   data.append(
    //     "CodigoFormularioCampoValor",
    //     campo.CodigoFormularioCampoValor
    //   );
    //   data.append("CodigoTipoCampo", campo.CodigoTipoCampo);
    //   await files.forEach(async (element2, j) => {
    //     await data.append("files[]", element2);
    //   });
    //   let request = await API.post(
    //     "/api/formulario/agregarMultimedia",
    //     data,
    //     {
    //       "content-type": "multipart/form-data",
    //     }
    //   );
    //   await console.log(request);
    //   await recargarDatos();
    //   iterarKey();
    // }
  };

  validarCamposPersonalizadosRequeridos = async () => {
    //Retorna true si todos los campos requeridos han sido llenados, retorna false si aún falta rellenar datos.
    this.setState({ openAlert: false });

    let { plantillaValores } = this.state;
    let response = true;

    let camposObligatoriosVacios = [];

    await this.setState({
      camposObligatoriosVacios: camposObligatoriosVacios,
    });

    console.log("los valores que va a validar", plantillaValores);

    await plantillaValores.map((campo, i) => {
      if (
        campo.CodigoTipoCampo != 3 &&
        campo.CodigoTipoCampo != 7 &&
        campo.Requerido
      ) {
        //Los campo tipo checkbox y datetime no se validan

        if (campo.CodigoTipoCampo == 10) {
          console.log(campo.Valor, campo.Valor.length);
        }
        if (
          campo.Valor == null ||
          (campo.CodigoTipoCampo == 1 && campo.Valor.trim() == "") ||
          (campo.CodigoTipoCampo == 4 && campo.Valor.trim() == "") ||
          (campo.CodigoTipoCampo == 10 && campo.Valor.length == 0) ||
          (campo.CodigoTipoCampo == 12 && campo.Valor.length == 0) ||
          (campo.CodigoTipoCampo == 5 && campo.Valor.length == 0) ||
          (campo.CodigoTipoCampo == 22 && campo.Valor.length == 0) ||
          (campo.CodigoTipoCampo == 23 && campo.Valor.length == 0) ||
          (campo.CodigoTipoCampo == 2 && campo.Valor.length == 0)
        ) {
          response = false;
          camposObligatoriosVacios.push(campo.Nombre);
        }
      }
    });

    await this.setState({ camposObligatoriosVacios });
    if (camposObligatoriosVacios.length > 0) {
      // window.alert(
      //   "debe llenar los siguientes campos: <br> " + camposObligatoriosVacios
      // );
      this.setState({ openAlert: true });
      document.getElementById("alert").scrollIntoView();
    }

    return response;
  };

  dibujarCampo = (row, css) => {
    if (!row.Visible) {
      return;
    }
    let component;
    switch (row.CodigoTipoCampo) {
      case 1:
        component = (
          <TextField
            required={row.Requerido}
            id={`txt${row.CodigoCampo}`}
            label={row.Nombre}
            style={{ width: "100%" }}
            margin="normal"
            variant="outlined"
            inputProps={{ zIndex: 0 }}
            defaultValue={row.ValorPorDefecto}
            onChange={(event) => {
              const { value } = event.target;
              let { plantillaValores } = this.state;

              const val = {
                CodigoCampo: row.CodigoCampo,
                CodigoTipoCampo: row.CodigoTipoCampo,
                Valor: value,
                Requerido: row.Requerido,
                CodigoCampoPadre: row.CodigoCampoPadre,
              };

              const find = plantillaValores.findIndex(
                (f) => f.CodigoCampo == row.CodigoCampo
              );

              if (find >= 0) {
                plantillaValores[find] = val;
              } else {
                plantillaValores.push(val);
              }

              this.setState({ plantillaValores });
            }}
            onBlur={() => {
              //Esta función se manda a llamar cuando algún otro campo depende del cambio del campo actual.
              let { plantillaValores } = this.state;

              let campo = plantillaValores.find(
                (f) => f.CodigoCampo == row.CodigoCampo
              );

              this.verificarDependencias(campo);
            }}
          />
        );

        break;

      case 2:
        component = (
          <TextField
            required={row.Requerido}
            id={`txt${row.CodigoCampo}`}
            type="number"
            label={row.Nombre}
            style={{ width: "100%" }}
            margin="normal"
            variant="outlined"
            inputProps={{ zIndex: 0 }}
            onChange={(event) => {
              const { value } = event.target;
              let { plantillaValores } = this.state;

              const val = {
                CodigoCampo: row.CodigoCampo,
                CodigoTipoCampo: row.CodigoTipoCampo,
                Valor: value,
                Requerido: row.Requerido,
                CodigoCampoPadre: row.CodigoCampoPadre,
              };

              const find = plantillaValores.findIndex(
                (f) => f.CodigoCampo == row.CodigoCampo
              );

              if (find >= 0) {
                plantillaValores[find] = val;
              } else {
                plantillaValores.push(val);
              }

              this.setState({ plantillaValores });
            }}
            onBlur={() => {
              //Esta función se manda a llamar cuando algún otro campo depende del cambio del campo actual.
              let { plantillaValores } = this.state;

              let campo = plantillaValores.find(
                (f) => f.CodigoCampo == row.CodigoCampo
              );

              this.verificarDependencias(campo);
            }}
          />
        );
        break;

      case 3:
        component = (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container spacing={0}>
              <TextField
                required={row.Requerido}
                id={row.Nombre}
                label={row.Nombre}
                type="date"
                defaultValue={new Date()}
                style={{ width: "100%" }}
                // value={this.state["campo" + row.CodigoCampo.toString()]}
                // onChange={this.handleChange(
                //   "campo" + row.CodigoCampo.toString()
                // )}
                onChange={(event) => {
                  const { value } = event.target;
                  let { plantillaValores } = this.state;

                  const val = {
                    CodigoCampo: row.CodigoCampo,
                    CodigoTipoCampo: row.CodigoTipoCampo,
                    Valor: value,
                    Requerido: row.Requerido,
                    CodigoCampoPadre: row.CodigoCampoPadre,
                  };

                  const find = plantillaValores.findIndex(
                    (f) => f.CodigoCampo == row.CodigoCampo
                  );

                  if (find >= 0) {
                    plantillaValores[find] = val;
                  } else {
                    plantillaValores.push(val);
                  }

                  this.setState({ plantillaValores });
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        );
        break;
      case 4:
        component = (
          <TextField
            required={row.Requerido}
            id={`txt${row.CodigoCampo}`}
            label={row.Nombre}
            style={{ width: "100%" }}
            margin="normal"
            variant="outlined"
            inputProps={{ zIndex: 0 }}
            multiline
            rows="3"
            margin="normal"
            variant="outlined"
            inputProps={{ zIndex: 0 }}
            defaultValue={row.ValorPorDefecto}
            onChange={(event) => {
              const { value } = event.target;
              let { plantillaValores } = this.state;

              const val = {
                CodigoCampo: row.CodigoCampo,
                CodigoTipoCampo: row.CodigoTipoCampo,
                Valor: value,
                Requerido: row.Requerido,
                CodigoCampoPadre: row.CodigoCampoPadre,
              };

              const find = plantillaValores.findIndex(
                (f) => f.CodigoCampo == row.CodigoCampo
              );

              if (find >= 0) {
                plantillaValores[find] = val;
              } else {
                plantillaValores.push(val);
              }

              this.setState({ plantillaValores });
            }}
          />
        );
        break;

      case 5:
      case 23:
        let find = this.state.plantillaValores.find(
          (f) => f.CodigoCampo == row.CodigoCampo
        );

        let selectedItems = [];
        if (find && find.Valor) {
          selectedItems = find.Valor;
        } else {
          selectedItems = [];
        }
        component = (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControl style={{ width: "100%" }} required={row.Requerido}>
                <InputLabel htmlFor="">{row.Nombre}</InputLabel>
                <Select
                  // multiple
                  name={"select" + row.Nombre}
                  input={
                    <Input
                      name={"select" + row.Nombre}
                      id={"select" + row.Nombre}
                    />
                  }
                  value={selectedItems[0]}
                  onChange={this.onSelectedItemsCamposDinamicos(row)}
                >
                  {row.Items.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText />
              </FormControl>
            </Grid>
          </Grid>
        );
        break;

      case 6:
        component = (
          <FormControl style={{ width: "100%" }} required={row.Requerido}>
            <label>{row.Nombre}</label>
            <DropzoneArea
              dropzoneText={row.Nombre}
              onChange={this.handleChangeFiles.bind(this, row)}
              acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
              maxFileSize={1073741824}
              filesLimit={100}
            />
          </FormControl>
        );
        break;
      case 7:
        component = (
          <FormControlLabel
            required={row.Requerido}
            control={
              <Checkbox
                required={row.Requerido}
                onChange={(event) => {
                  const { checked } = event.target;
                  let { plantillaValores } = this.state;

                  const val = {
                    CodigoCampo: row.CodigoCampo,
                    CodigoTipoCampo: row.CodigoTipoCampo,
                    Valor: checked,
                    Requerido: row.Requerido,
                    CodigoCampoPadre: row.CodigoCampoPadre,
                  };

                  const find = plantillaValores.findIndex(
                    (f) => f.CodigoCampo == row.CodigoCampo
                  );

                  if (find >= 0) {
                    plantillaValores[find] = val;
                  } else {
                    plantillaValores.push(val);
                  }

                  this.setState({ plantillaValores });
                }}
                //checked={this.state["campo" + row.CodigoCampo.toString()]}
                // onChange={this.handleChangeCheck(
                //   "campo" + row.CodigoCampo.toString()
                // )}
                // checked={true}
                // onChange={this.handleChange("activo")}

                value="activo"
                color="primary"
              />
            }
            label={row.Nombre}
          />
        );
        break;

      case 8:
        component = (
          <FormControl style={{ width: "100%" }} required={row.Requerido}>
            <label>{row.Nombre}</label>
            <DropzoneArea
              dropzoneText={row.Nombre}
              onChange={this.handleChangeFiles.bind(this, row)}
              maxFileSize={1073741824}
              acceptedFiles={[
                "image/jpeg",
                "image/png",
                "image/bmp",
                ".sql",
                ".txt",
                ".pdf",
                ".doc",
                ".docx",
                ".xls",
                ".xlsx",
                ".csv",
                ".ppt",
                ".pptx",
                ".pages",
                ".odt",
                ".rtf",
                ".ai",
                ".psd",
                ".mp3",
                ".mp4",
                ".avi",
                ".eps",
                ".ait",
                ".svg",
                ".svgz",
              ]}
              filesLimit={100}
            />
          </FormControl>
        );
        break;

      case 10: {
        //Picklist SAP
        let find = this.state.plantillaValores.find(
          (f) => f.CodigoCampo == row.CodigoCampo
        );

        let selectedItems = [];
        if (find && find.Valor) {
          selectedItems = find.Valor;
        } else {
          selectedItems = [];
        }

        let itemsCopy = [];
        row.Items.forEach((item, index) => {
          itemsCopy.push({ id: 0, name: item.name });
          item.children.forEach((hijo, i) => {
            itemsCopy.push({ id: hijo.id, name: hijo.name });
          });
        });
        console.log("itemscopy", itemsCopy);

        component = (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControl style={{ width: "100%" }} required={row.Requerido}>
                <InputLabel htmlFor="grouped-select">{row.Nombre}</InputLabel>
                <Select
                  //defaultValue=
                  value={selectedItems}
                  onChange={this.onSelectedItemsCamposDinamicos(row)}
                  input={
                    <Input
                      name={"select" + row.Nombre}
                      id={"select" + row.Nombre}
                    />
                  }
                >
                  {itemsCopy.map((item) => (
                    <MenuItem
                      key={item.id}
                      value={item.id}
                      disabled={item.id === 0 ? true : false}
                      className={item.id === 0 ? css.group : css.item}
                      style={{ opacity: 1 }}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        );
        break;
      }
      case 12: {
        let find = this.state.plantillaValores.find(
          (f) => f.CodigoCampo == row.CodigoCampo
        );

        let selectedItems = [];
        if (find && find.Valor) {
          selectedItems = find.Valor;
        } else {
          selectedItems = [];
        }

        let itemsCopy = [];
        row.Items.forEach((item, index) => {
          itemsCopy.push({ id: 0, name: item.name });
          item.children.forEach((hijo, i) => {
            itemsCopy.push({ id: hijo.id, name: hijo.name });
          });
        });

        component = (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControl style={{ width: "100%" }} required={row.Requerido}>
                <InputLabel htmlFor="grouped-select">{row.Nombre}</InputLabel>
                <Select
                  defaultValue=""
                  id="grouped-select"
                  value={selectedItems}
                  onChange={this.onSelectedItemsCamposDinamicos(row)}
                  input={
                    <Input
                      name={"select" + row.Nombre}
                      id={"select" + row.Nombre}
                    />
                  }
                >
                  {/* {row.Items.map((item) => (
                        <React.Fragment>
                          <ListSubheader>{item.name}</ListSubheader>
    
                          {item.children.map((obj) => (
                            <MenuItem key={obj.id} value={obj.id}>
                              {obj.name}
                            </MenuItem>
                          ))}
                        </React.Fragment>
                      ))} */}
                  {itemsCopy.map((item) => (
                    <MenuItem
                      key={item.id}
                      value={item.id}
                      disabled={item.id === 0 ? true : false}
                      className={item.id === 0 ? css.group : css.item}
                      style={{ opacity: 1 }}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        );
        break;
      }
      case 13:
        component = (
          <TextField
            required={row.Requerido}
            id={`txt${row.CodigoCampo}`}
            label={row.Nombre}
            style={{ width: "100%" }}
            margin="normal"
            variant="outlined"
            inputProps={{ zIndex: 0 }}
            defaultValue={row.ValorPorDefecto}
            onChange={(event) => {
              const { value } = event.target;
              let { plantillaValores } = this.state;

              const val = {
                CodigoCampo: row.CodigoCampo,
                CodigoTipoCampo: row.CodigoTipoCampo,
                Valor: value,
                Requerido: row.Requerido,
                CodigoCampoPadre: row.CodigoCampoPadre,
              };

              const find = plantillaValores.findIndex(
                (f) => f.CodigoCampo == row.CodigoCampo
              );

              if (find >= 0) {
                plantillaValores[find] = val;
              } else {
                plantillaValores.push(val);
              }

              this.setState({ plantillaValores });
            }}
          />
        );

        break;
      //Campo de tipo Hora
      case 14:
        component = (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel htmlFor="formatted-text-mask-input">
                {row.Nombre} (HH:mm)
              </InputLabel>
              <Input
                required={row.Required}
                //value={this.state["campo" + row.CodigoCampo.toString()]}
                onChange={(event) => {
                  const { value } = event.target;
                  let { plantillaValores } = this.state;
                  let partes, valor;

                  partes = value.split(":");
                  console.log("partes:", partes.length);

                  valor = {
                    horas: partes[0].replace("  ", "00"),
                    minutos: partes[1].replace("  ", "00"),
                  };

                  const val = {
                    CodigoCampo: row.CodigoCampo,
                    CodigoTipoCampo: row.CodigoTipoCampo,
                    Valor: valor,
                    Requerido: row.Requerido,
                    CodigoCampoPadre: row.CodigoCampoPadre,
                    LlaveCampo: row.LlaveCampo,
                  };

                  const find = plantillaValores.findIndex(
                    (f) => f.CodigoCampo == row.CodigoCampo
                  );

                  if (find >= 0) {
                    plantillaValores[find] = val;
                  } else {
                    plantillaValores.push(val);
                  }

                  this.setState({ plantillaValores });
                }}
                id={row.CodigoCampo}
                inputComponent={TextMaskCustom}
              />
            </FormControl>
          </Grid>
        );
        break;
      //Campo de tipo Separador 01
      case 15:
        component = (
          // <Separador CodigoCampo={row.CodigoCampo} Nombre={row.Nombre} />
          <Divider style={{ marginTop: 10, marginBottom: 10 }} />
        );
        break;
      case 16: //Un titulo
        component = (
          <div className={css.inputField}>
            <Typography variant="h6" gutterBottom>
              {row.Nombre}
            </Typography>
          </div>
        );
        break;
      case 17: //Una descripción
        component = (
          <div className={css.inputField}>
            <Typography variant="body2" gutterBottom>
              {row.Nombre}
            </Typography>
          </div>
        );
        break;
      case 18: //Imagen fija
        component = (
          <div
            className={css.inputField}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <img alt="img" src={row.Nombre} style={{ maxWidth: "100%" }} />
          </div>
        );
        break;
      case 19:
        {
          let find = this.state.plantillaValores.find(
            (f) => f.CodigoCampo == row.CodigoCampo
          );

          let selectedItems = [];
          if (find && find.Valor) {
            selectedItems = find.Valor;
          } else {
            selectedItems = [];
          }
          component = (
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl style={{ width: "100%" }} required={row.Requerido}>
                  <InputLabel htmlFor="">{row.Nombre}</InputLabel>
                  <Select
                    multiple
                    name={"select" + row.Nombre}
                    input={
                      <Input
                        name={"select" + row.Nombre}
                        id={"select" + row.Nombre}
                      />
                    }
                    value={selectedItems}
                    onChange={this.onSelectedItemsCamposDinamicos(row)}
                  >
                    {row.Items.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText />
                </FormControl>
              </Grid>
            </Grid>
          );
        }
        break;
      case 20:
        {
          let find = this.state.plantillaValores.find(
            (f) => f.CodigoCampo == row.CodigoCampo
          );

          let selectedItems = [];
          if (find && find.Valor) {
            selectedItems = find.Valor;
          } else {
            selectedItems = [];
          }
          component = (
            <Grid container spacing={3}>
              <div>
                <strong>{row.Nombre}</strong>
              </div>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <DataGrid columns={row.Items} rows={selectedItems} />
              </Grid>
            </Grid>
          );
        }
        break;
      case 21: {
        // Campo calculado
        component = (
          <div className={css.flexRow}>
            <FunctionsIcon style={{ color: "rgb(33, 150, 243)" }} />
            <TextField
              disabled={true}
              id={`txt${row.CodigoCampo}`}
              type="number"
              label={
                row.Nombre +
                ": Este campo se calcula cuando se guarda la gestión."
              }
              style={{ width: "100%" }}
              margin="normal"
              variant="outlined"
              inputProps={{ zIndex: 0 }}
              placeholder={"Este campo se calcula cuando se guarda la gestión."}
              startAdornment={
                <InputAdornment position="start">
                  <FunctionsIcon />
                </InputAdornment>
              }
            />
          </div>
        );
        break;
      }
      case 22: {
        //Picklist API
        let find = this.state.plantillaValores.find(
          (f) => f.CodigoCampo == row.CodigoCampo
        );

        let selectedItems = [];
        if (find && find.Valor) {
          selectedItems = find.Valor;
        } else {
          selectedItems = [];
        }
        console.log("DATA");
        console.log(row.Items);
        component = (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControl style={{ width: "100%" }} required={row.Requerido}>
                {/* <InputLabel htmlFor="">{row.Nombre}</InputLabel> */}
                {/* <Select
                  // multiple
                  name={"select" + row.Nombre}
                  input={
                    <Input
                      name={"select" + row.Nombre}
                      id={"select" + row.Nombre}
                    />
                  }
                  value={selectedItems[0]}
                  onChange={this.onSelectedItemsCamposDinamicos(row)}
                >
                  {row.Items.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select> */}
                <Autocomplete
                  name={"select" + row.Nombre}
                  id={"select" + row.Nombre}
                  options={row.Items}
                  getOptionLabel={(option) => option.name}
                  value={selectedItems[0]}
                  onChange={(event, newValue) =>
                    this.onSelectedItemsCamposDinamicosAutocomplete(
                      event,
                      newValue,
                      row
                    )
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={row.Nombre}
                      variant="outlined"
                    />
                  )}
                />
                <FormHelperText />
              </FormControl>
            </Grid>
          </Grid>
        );
        break;
      }
      default:
        component = <></>;
        break;
    }
    return component;
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        {this.state.cargando && this.state.problema !== null ? (
          <div
            style={{
              width: "100%",
              marginTop: 10,
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loader
              type="Oval"
              color="#3498db"
              height="50"
              width="50"
              style={{ textAlign: "center" }}
            />
          </div>
        ) : (
          <></>
        )}
        -
        <Container maxWidth="md">
          <div
            style={{
              color: "#2196F3",
              display: "flex",
              alignContent: "center",
              fontSize: "1m",
              marginTop: 10,
            }}
          >
            <Typography variant="h5" gutterBottom>
              Crea un nuevo elemento
            </Typography>
          </div>
          <div
            style={{
              color: "#2196F3",
              display: "flex",
              alignContent: "center",
              fontSize: "1m",
              marginTop: 10,
            }}
          >
            <br />
          </div>
          <Grid container spacing={3}>
            {this.state.plantilla.map((row, i) => (
              <Grid item xs={12} lg={12} sm={12} key={i}>
                {this.dibujarCampo(row, classes)}
              </Grid>
            ))}
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} lg={12} sm={12}>
              <Button
                disabled={this.state.procesando}
                variant="contained"
                style={{ backgroundColor: "#69bd4b", color: "white" }}
                className={classes.buttonAdd}
                onClick={() => this.makeRequestGuardar()}
              >
                Guardar
              </Button>
            </Grid>
          </Grid>
          <Collapse in={this.state.openAlert} style={{ width: "100%" }}>
            <Alert
              severity="warning"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    this.setState({ openAlert: false });
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              <div>Debe llenar los siguientes campos obligatorios:</div>
              {this.state.camposObligatoriosVacios.map((item, index) => (
                <div>{item}</div>
              ))}
            </Alert>
          </Collapse>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <div id="alert"></div>
        </Container>
      </React.Fragment>
    );
  }
}
const GenericFormContainer = withStyles(styles)(GenericForm);
export default GenericFormContainer;
