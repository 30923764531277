import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import {
  FloatingMenu,
  MainButton,
  ChildButton,
} from "react-floating-button-menu";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";
import AddAlertIcon from "@material-ui/icons/AddAlert";
import PersonIcon from "@material-ui/icons/Person";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Loader from "react-loader-spinner";
import Popover from "@material-ui/core/Popover";
import MaskedInput from "react-text-mask";
import PropTypes from "prop-types";

//Scroll bar
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import UsuariosList from "../../plantillaProblema/components/usuarios-list";

//Appbar
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import InputBase from "@material-ui/core/InputBase";
import { fade, makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";

//Own components
import Layout from "../../layout/containers/Layout";
import ObjetosTabla from "../components/objetos-tabla";
import AddButton from "../../helpers/components/add-button-float";
import API from "../../utils/api";
import { isSignedIn } from "../../utils/auth";
import { convertYYYYMMDDToDate } from "../../utils/helpers";
import { convertirASegundos, convertToYearMonthDay } from "../../utils/helpers";
import {
  getNavigationHistory,
  setNavigationHistory,
} from "../../utils/navigationGestion";

//CSS
import "../components/gestion-style.css";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0-9]/, /[0-9]/, ":", /[0-5]/, /[0-9]/]}
      placeholderChar={"\u2000"}
      showMask
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  paperUserModal: {
    position: "absolute",
    width: "75%",
    height: "600px",
    maxHeight: "600px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  popover: {
    pointerEvents: "none",
  },
  paperPopover: {
    padding: theme.spacing(1),
    background: "#000",
    color: "#fff",
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
});

class NavegacionAvisos extends Component {
  state = {
    actionType: "Agregar",
    openDirectoryModal: false,
    openProblema: false,
    openAsignarUsuarios: false,
    openCausa: false,
    isOpenFabMenu: false,
    actionType: "", //Crear, Actualizar
    idObjetoPadre: null,
    nombreObjetoPadre: "Administrador de Avisos",
    idObjetoEditar: "",
    llaveObjetoEditar: "",
    nombre: "",
    activo: "",
    fechaInicio: "",
    fechaFin: "",
    rangoRojo: "",
    rangoAmarillo: "",
    codigoUsuarioResponsable: "",
    nombreUsuarioResponsable: "",
    botonBackDisplay: "none",
    listaObjetos: [],
    listaUsuarios: [],
    history: [],
    anchorEl: null,
    popOverOpen: false,
    textoPopover: "",
    problema: {
      codigoProblema: "",
      nombre: "",
      descripcion: "",
      popular: "",
      tiempoEsperaEstimado: "",
      activo: "",
      tiempoEsperaSegundos: 0,
      fechaInicio: null,
      fechaFin: null,
      esEditable: "",
      codigoPlantilla: "",
      codigoPlantillaTareas: "",
    },
    criterioBusqueda: "",
  };

  async componentWillMount() {
    // //Add instructions for check navigation history in sessionStorage
    // let historial = await getNavigationHistory();
    // console.log("historial",historial);
    // if (Array.isArray(historial) && historial.length > 0) {
    //   //Obtiene el último elemento del array
    //   let actualElement = historial[historial.length - 1];
    //   if (actualElement) {
    //     this.setState(
    //       {
    //         idObjetoPadre: actualElement.idObjeto,
    //         nombreObjetoPadre: actualElement.nombre,
    //         history: historial,
    //       },
    //       () => {
    //         this.cargarLista();
    //       }
    //     );
    //   }
    // } else {
    //   //Si no hay datos guardados en la session de navegación debe crearlos
    //   await this.obtenerIdModulo();
    //   let history = this.state.history;
    //   history.push({
    //     idObjeto: this.state.idObjetoPadre,
    //     nombre: "Administrador de Avisos",
    //   }); //inserta la primera posición del array para navegacion
    //   this.setState({ history: history }, () => {
    //     //Almacena historia de navegación en sessión
    //     setNavigationHistory(history);
    //     this.cargarLista();
    //   });
    // }

    await this.obtenerIdModulo();
    let history = this.state.history;
    history.push({
      idObjeto: this.state.idObjetoPadre,
      nombre: "Administrador de Avisos",
    }); //inserta la primera posición del array para navegacion
    this.setState({ history: history }, () => {
      this.cargarLista();
    });
  }

  async obtenerIdModulo() {
    try {
      //Loader
      this.setState({ cargando: true });
      let autenticate = await isSignedIn();
      if (!autenticate.response) {
        window.location.href = "/login";
        return;
      }
      let request = await API.fetchGetRequest(
        `api/procesoGestionWeb/getModuleCode/MODULO_AVISOS`
      );
      if (request.statusCode !== 200) {
        alert(
          "Hubo un error al obtener el ID del módulo, consulta con un administrador"
        );
      }

      console.log(request.status);
      if (request.statusCode === 401) {
        //alert("cerrar sesion");
        window.location.href = "/login";
      } else {
        this.setState({
          idObjetoPadre: request.data.data,
        });
      }
    } catch (error) {
      console.log(error);
      if (error.toString().includes("401")) {
        window.location.href = "/login";
      }
    } finally {
      this.setState({ cargando: false });
    }
  }

  // async cargarLista() {
  //   try {
  //     //Loader
  //     this.setState({ cargando: true });

  //     //Muestra/oculta botón para regresar en la navegación
  //     this.botonBack();

  //     let data = {
  //       idObjeto: null,
  //       idObjetoPadre: this.state.idObjetoPadre,
  //       llave: "PROBLEMA",
  //     };
  //     console.log(data);
  //     const request = await API.post(
  //       `api/procesoGestionWeb/listarObjetosHijos`,
  //       data
  //     );
  //     if (request.status != 200) {
  //       alert(
  //         "Hubo un error al realizar la operación, consulta con un administrador"
  //       );
  //     }

  //     console.log(request.status);
  //     if (request.statusCode === 401) {
  //       //alert("cerrar sesion");
  //       window.location.href = "/login";
  //     } else {
  //       if (Array.isArray(request.data.data)) {
  //         this.setState({
  //           listaObjetos: request.data.data,
  //         });
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     if (error.toString().includes("401")) {
  //       window.location.href = "/login";
  //     }
  //   } finally {
  //     this.setState({ cargando: false });
  //   }
  // }

  async cargarLista() {
    try {
      //Loader
      this.setState({ cargando: true });

      //Muestra/oculta botón para regresar en la navegación
      this.botonBack();

      let data = {
        idObjeto: null,
        idObjetoPadre: this.state.idObjetoPadre,
        llave: "CARPETA_MODULO,PROBLEMA",
      };
      console.log(data);
      const request = await API.post(
        `api/procesoGestionWeb/listarObjetosHijos`,
        data
      );
      if (request.status != 200) {
        alert(
          "Hubo un error al realizar la operación, consulta con un administrador"
        );
      }

      console.log(request.status);
      if (request.statusCode === 401) {
        //alert("cerrar sesion");
        window.location.href = "/login";
      } else {
        if (Array.isArray(request.data.data)) {
          this.setState({
            listaObjetos: request.data.data,
          });
        }
      }
    } catch (error) {
      console.log(error);
      if (error.toString().includes("401")) {
        window.location.href = "/login";
      }
    } finally {
      this.setState({ cargando: false });
    }
  }

  async buscar() {
    try {
      //Loader
      this.setState({ cargando: true });
      console.log("Busqueda:", this.state.criterioBusqueda);
      if (this.state.criterioBusqueda.trim() === "") {
        this.cargarLista();
        return;
      }

      //Muestra/oculta botón para regresar en la navegación
      this.botonBack();

      let data = {
        codigoEntidad: null,
        criterio: this.state.criterioBusqueda,
      };
      console.log(data);
      const request = await API.post(`api/ejecucionaviso/buscarAviso`, data);
      if (request.status != 200) {
        alert(
          "Hubo un error al realizar la operación, consulta con un administrador"
        );
      }

      console.log(request.status);
      if (request.statusCode === 401) {
        //alert("cerrar sesion");
        window.location.href = "/login";
      } else {
        if (Array.isArray(request.data.data)) {
          this.setState({
            listaObjetos: request.data.data,
          });
        }
      }
    } catch (error) {
      console.log(error);
      if (error.toString().includes("401")) {
        window.location.href = "/login";
      }
    } finally {
      this.setState({ cargando: false });
    }
  }

  handleChange = (name) => (event) => {
    if (name == "activo") {
      this.setState({ [name]: event.target.checked });
    } else {
      this.setState({
        [name]: event.target.value,
      });
    }
  };

  irConfiguracion = (codigo) => {
    this.props.history.push("/aviso/nuevo/" + codigo);
  };

  navegar = (idObjeto, nombre, llave) => {
    if (llave === "PROBLEMA") {
      this.props.history.push("/aviso/nuevo/" + idObjeto);
    } else if (llave === "CARPETA_MODULO") {
      let history = this.state.history;
      history.push({ idObjeto: idObjeto, nombre: nombre });
      this.setState(
        {
          idObjetoPadre: idObjeto,
          nombreObjetoPadre: nombre,
          history: history,
        },
        () => {
          this.cargarLista();
        }
      );
    }
  };

  regresar = () => {
    let history = this.state.history;

    let cantidadPosiciones = history.length;
    let nombreObjetoPadre;
    let idObjetoPadre;
    if (cantidadPosiciones > 1) {
      //Elimina el último elemento del array que corresponde al idObjetoPadre actual
      history.pop();
      //Actualiza cantidad de elementos del array
      cantidadPosiciones = history.length;
      //Encuentra el valor de la nueva última posición del array y la asigna al idObjetoPadre
      idObjetoPadre = history[cantidadPosiciones - 1].idObjeto;
      nombreObjetoPadre = history[cantidadPosiciones - 1].nombre;

      //Actualiza valores del state
      this.setState(
        {
          idObjetoPadre: idObjetoPadre,
          nombreObjetoPadre: nombreObjetoPadre,
          history: history,
        },
        () => {
          this.cargarLista();
        }
      );
    }
  };

  botonBack = () => {
    let cantidadPosiciones = this.state.history.length;
    let display;
    if (cantidadPosiciones > 1) {
      display = "inline-block";
    } else {
      display = "none";
    }

    this.setState({ botonBackDisplay: display });
  };

  handlePopoverOpen = (event, textoPopover) => {
    this.setState({
      anchorEl: event.currentTarget,
      popOverOpen: true,
      textoPopover: textoPopover,
    });
  };

  handlePopoverClose = () => {
    this.setState({ anchorEl: null, popOverOpen: false, textoPopover: "" });
  };

  render() {
    const { classes } = this.props;
    return (
      <Layout titulo="Administración de Avisos">
        <div className={classes.root}>
          <AppBar position="static">
            <Toolbar>
              <IconButton
                style={{
                  display: this.state.botonBackDisplay,
                  color: "#FFF",
                }}
                onClick={() => this.regresar()}
              >
                <ArrowBackIosIcon />
              </IconButton>
              <Typography className={classes.title} variant="h6" noWrap>
                {this.state.nombreObjetoPadre} 
              </Typography>

              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Buscar..."
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ "aria-label": "Buscar" }}
                  onChange={this.handleChange("criterioBusqueda")}
                  onKeyPress={(ev) => {
                    console.log("keyP: " + ev.key);
                    if (ev.key === "Enter") {
                      this.buscar();
                      ev.preventDefault();
                    }
                  }}
                />
              </div>
            </Toolbar>
          </AppBar>
        </div>

        {this.state.cargando ? (
          <div
            style={{
              width: "100%",
              marginTop: 10,
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loader
              type="Oval"
              color="#3498db"
              height="50"
              width="50"
              style={{ textAlign: "center" }}
            />
          </div>
        ) : (
          <></>
        )}

        <Grid container spacing={3}>
          <Grid item xs={12} lg={12} sm={12}>
            <ObjetosTabla
              data={this.state.listaObjetos}
              editar={this.editar}
              eliminar={this.eliminar}
              navegar={this.navegar}
              irConfiguracion={this.irConfiguracion}
              verCausas={this.verCausas}
            />
          </Grid>
        </Grid>

        <ToastContainer />
        <Popover
          id="mouse-over-popover"
          className={classes.popover}
          classes={{
            paper: classes.paperPopover,
          }}
          open={this.state.popOverOpen}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          onClose={this.handlePopoverClose}
          disableRestoreFocus
        >
          <Typography>{this.state.textoPopover}</Typography>
        </Popover>
      </Layout>
    );
  }
}
const NavegacionAvisosContainer = withStyles(styles)(NavegacionAvisos);
export default withRouter(NavegacionAvisosContainer);
