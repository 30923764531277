import React, { useState, useEffect, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";
import Loader from "react-loader-spinner";
import Popover from "@material-ui/core/Popover";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Modal from "@material-ui/core/Modal";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import AppsIcon from "@material-ui/icons/Apps";
import FolderIcon from "@material-ui/icons/Folder";
import ViewListIcon from "@material-ui/icons/ViewList";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import SearchIcon from "@material-ui/icons/Search";
import { Chip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { IOSSwitch } from "../../helpers/components/ios-switch";

import axios from "axios";

//Icons
import GetAppIcon from "@material-ui/icons/GetApp";
import BarChartIcon from "@material-ui/icons/BarChart";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CloseIcon from "@material-ui/icons/Close";

//Accordeon
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

//Scroll bar
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";

//Appbar
import { fade } from "@material-ui/core/styles";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Divider from "@material-ui/core/Divider";

//Own components
import Layout from "../../layout/containers/Layout";
import ButtonAdd from "../../helpers/components/button-add";
import API from "../../utils/api";
import { isSignedIn, signOut } from "../../utils/auth";
import { convertToYearMonthDay } from "../../utils/helpers";
import CardAvisos from "../../ejecucion-avisos/components/card-aviso";
import { validaPermisoPaginaActual } from "../../utils/helpers";

import {
  clearSuperfiltroHistory,
  getSuperfiltroHistory,
  setSuperfiltroHistory,
} from "../../utils/historialSuperfiltro";

export default function ReservaForm({
  reservaId,
  cerrarModalReservas,
  recargarBusqueda,
}) {
  const [listaHabitaciones, setListaHabitaciones] = useState([]);
  const [listaUsuarios, setListaUsuarios] = useState([]);
  const [habitacionId, setHabitacionId] = useState(null);
  const [fechaInicio, setFechaInicio] = useState(null);
  const [fechaFin, setFechaFin] = useState(null);
  const [checkout, setCheckout] = useState(null);
  const [codigoGestion, setCodigoGestion] = useState(null);
  const [usuarioSolicitanteActual, setUsuarioSolicitanteActual] =
    useState(null);
  const [usuarioAutorizaActual, setUsuarioAutorizaActual] = useState(null);
  const [usuarioHotelAutorizaActual, setUsuarioHotelAutorizaActual] =
    useState(null);
  const [habitacionActual, setHabitacionActual] = useState(null);
  const [usuarioSolicitanteId, setUsuarioSolicitanteId] = useState(null);
  const [usuarioAutorizaId, setUsuarioAutorizaId] = useState(null);
  const [usuarioHotelAutorizaId, setUsuarioHotelAutorizaId] = useState(null);
  const [observaciones, setObservaciones] = useState("");
  const [id, setId] = useState(null);
  const classes = {};

  useEffect(() => {
    start();
  }, []);

  //Carga datos de la reserva, hasta cuando carga en el estado la lista de usuarios, para poder setear los elementos de los autocompletes.
  useEffect(() => {
    if (listaUsuarios.length > 0 && reservaId !== null) {
      cargarDatosReserva();
    }
  }, [listaUsuarios]);

  const start = async () => {
    await cargarListaHabitaciones();
    await cargarListaUsuarios();
  };

  const cargarListaHabitaciones = async () => {
    console.log("que ondas");
    const request = await API.fetchGetRequest(
      `api/admonHotel/habitacionesList`
    );
    if (Array.isArray(request.data.data)) {
      let listaHabit = request.data.data;
      listaHabit.push({
        id: 0,
        Nombre: "Todas",
        Descripcion: "",
        Activo: true,
      });

      setListaHabitaciones(listaHabit);
    }
  };

  const cargarListaUsuarios = async () => {
    const request = await API.fetchGetRequest(`api/Usuario/buscar/x-x`);
    console.log(request);
    if (Array.isArray(request.data.usuarios)) {
      setListaUsuarios(request.data.usuarios);
    }
  };

  const cargarDatosReserva = async () => {
    const request = await API.fetchGetRequest(
      `api/admonHotel/obtenerReserva/${reservaId}`
    );
    console.log(request);
    if (request.data.data !== null) {
      const datos = request.data.data;
      setId(reservaId);
      setHabitacionId(datos.HabitacionId);

      //setea objeto para autocomplete
      console.log("listaHabitaciones", listaHabitaciones);
      const objHabitacion = listaHabitaciones.filter((item) => {
        return item.Id == datos.HabitacionId;
      });
      console.log("habitacionId", datos.HabitacionId);
      console.log("objHabitacion", objHabitacion);
      if (objHabitacion.length > 0) {
        setHabitacionActual(objHabitacion[0]);
      }

      console.log("ListaUsuarios", listaUsuarios);
      const objUsuarioSolicitante = listaUsuarios.filter((item) => {
        return item.CodigoUsuario == datos.IdUsuarioSolicitante;
      });
      console.log("idUsuarioSolicitante", datos.IdUsuarioSolicitante);
      console.log("objUsuarioSolicitante", objUsuarioSolicitante);
      if (objUsuarioSolicitante.length > 0) {
        setUsuarioSolicitanteActual(objUsuarioSolicitante[0]);
      }

      const objUsuarioAutoriza = listaUsuarios.filter((item) => {
        return item.CodigoUsuario == datos.IdUsuarioAutoriza;
      });
      console.log("IdUsuarioAutoriza", datos.IdUsuarioAutoriza);
      console.log("objUsuarioAutoriza", objUsuarioAutoriza);
      if (objUsuarioAutoriza.length > 0) {
        setUsuarioAutorizaActual(objUsuarioAutoriza[0]);
      }

      const objUsuarioHotelAutoriza = listaUsuarios.filter((item) => {
        return item.CodigoUsuario == datos.IdUsuarioHotelAutoriza;
      });
      console.log("IdUsuarioHotelAutoriza", datos.IdUsuarioHotelAutoriza);
      console.log("objUsuarioHotelAutoriza", objUsuarioHotelAutoriza);
      if (objUsuarioHotelAutoriza.length > 0) {
        setUsuarioHotelAutorizaActual(objUsuarioHotelAutoriza[0]);
      }

      setCodigoGestion(
        datos.CodigoAviso ? parseInt(datos.CodigoAviso.toString()) : null
      );
      setFechaInicio(
        datos.FechaInicio ? datos.FechaInicio.substring(0, 10) : ""
      );
      setFechaFin(datos.FechaFin ? datos.FechaFin.substring(0, 10) : "");
      setCheckout(datos.Checkout ? datos.Checkout.substring(0, 10) : "");
      setUsuarioSolicitanteId(datos.IdUsuarioSolicitante);
      setUsuarioAutorizaId(datos.IdUsuarioAutoriza);
      setUsuarioHotelAutorizaId(datos.IdUsuarioHotelAutoriza);

      setObservaciones(datos.Observaciones);
    }
  };

  const handleChangeFechaInicio = (event) => {
    setFechaInicio(event.target.value);
  };

  const handleChangeFechaFin = (event) => {
    setFechaFin(event.target.value);
  };

  const handleChangeCodigoGestion = (event) => {
    setCodigoGestion(event.target.value);
  };

  const handleChangeCheckout = (event) => {
    setCheckout(event.target.value);
  };

  const handleChangeObservaciones = (event) => {
    setObservaciones(event.target.value);
  };

  const save = async () => {
    try {
      const session = await isSignedIn();
      const userName = session.userName;

      //Valida
      // if (habitacionId === null || habitacionId === 0 || isNaN(habitacionId)) {
      //   toast.warn("Selecciona una habitación");
      //   return;
      // }

      if (fechaInicio === "" || fechaInicio === null) {
        toast.warn("Escribe la fecha de incio");
        return;
      }

      if (fechaFin === "" || fechaFin === null) {
        toast.warn("Escribe la fecha final");
        return;
      }

      let data = {
        Id: id,
        HabitacionId: habitacionId,
        CodigoAviso: codigoGestion,
        FechaInicio: fechaInicio,
        FechaFin: fechaFin,
        IdUsuarioSolicitante: usuarioSolicitanteId,
        IdUsuarioAutoriza: usuarioAutorizaId,
        IdUsuarioHotelAutoriza: usuarioHotelAutorizaId,
        Checkout: checkout,
        Observaciones: observaciones,
        usuarioOpera: userName,
      };

      let uri = `api/admonHotel/reservar`;
      let request = await API.post(uri, data);
      if (request.status != 200) {
        alert(
          "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
        );
      }

      if (request.statusCode === 401) {
        //alert("cerrar sesion");
      } else {
        toast.success("Los datos se guardaron exitosamente.");
        limpiar();
        cerrarModalReservas();
        recargarBusqueda();
      }
    } catch (error) {}
  };

  const limpiar = () => {
    setId(null);
    setHabitacionId(null);
    setHabitacionActual(0);
    setUsuarioSolicitanteActual(0);
    setUsuarioAutorizaActual(0);
    setUsuarioHotelAutorizaActual(0);
    setCodigoGestion(null);
    setFechaInicio("");
    setFechaFin("");
    setCheckout("");
    setUsuarioSolicitanteId(null);
    setUsuarioAutorizaId(null);
    setUsuarioHotelAutorizaId(null);
    setObservaciones("");
  };

  return (
    <Fragment>
      <Grid container spacing={1} style={{ margin: "0 !important" }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Autocomplete
            className={classes.textField}
            style={{
              width: "100%",
              marginLeft: 10,
              marginTop: 25,
            }}
            options={listaHabitaciones}
            autoHighlight
            getOptionLabel={(option) => option.Nombre}
            disabled={false}
            renderOption={(option) => (
              <React.Fragment>
                <span></span>
                {option.Nombre}
              </React.Fragment>
            )}
            renderInput={(params) => (
              <TextField {...params} label="Habitación" variant="outlined" />
            )}
            value={habitacionActual}
            onChange={(event, newInputValue) => {
              setHabitacionActual(newInputValue ? newInputValue : -1);
              setHabitacionId(parseInt(newInputValue ? newInputValue.Id : 0));
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <TextField
            id="fechaInicio"
            label="Fecha inicio"
            type="date"
            //defaultValue={new Date()-10}
            style={{ width: "100%" }}
            value={fechaInicio}
            onChange={handleChangeFechaInicio}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <TextField
            id="fechaFin"
            label="Fecha fin"
            type="date"
            //defaultValue={new Date()}
            style={{ width: "100%" }}
            value={fechaFin}
            onChange={handleChangeFechaFin}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <TextField
            id="checkout"
            label="Checkout"
            type="date"
            //defaultValue={new Date()}
            style={{ width: "100%" }}
            value={checkout}
            onChange={handleChangeCheckout}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <TextField
          id="txtCodigoGestion"
          label={"Código de gestión"}
          type="number"
          // variant="outlined"
          className={classes.textField}
          value={codigoGestion}
          onChange={handleChangeCodigoGestion}
          margin="normal"
          inputProps={{ maxLength: 5 }}
          style={{ width: "100%" }}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Autocomplete
          className={classes.textField}
          style={{
            width: "100%",
            marginLeft: 10,
            marginTop: 25,
          }}
          options={listaUsuarios}
          autoHighlight
          getOptionLabel={(option) => option.NombreCompletoUsuario}
          disabled={false}
          renderOption={(option) => (
            <React.Fragment>
              <span></span>
              {option.NombreCompletoUsuario}
            </React.Fragment>
          )}
          renderInput={(params) => (
            <TextField {...params} label="Solicitante" variant="outlined" />
          )}
          value={usuarioSolicitanteActual}
          onChange={(event, newInputValue) => {
            setUsuarioSolicitanteActual(newInputValue ? newInputValue : -1);
            setUsuarioSolicitanteId(
              parseInt(newInputValue ? newInputValue.CodigoUsuario : 0)
            );
          }}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Autocomplete
          className={classes.textField}
          style={{
            width: "100%",
            marginLeft: 10,
            marginTop: 25,
          }}
          options={listaUsuarios}
          autoHighlight
          getOptionLabel={(option) => option.NombreCompletoUsuario}
          disabled={false}
          renderOption={(option) => (
            <React.Fragment>
              <span></span>
              {option.NombreCompletoUsuario}
            </React.Fragment>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Usuario Autoriza"
              variant="outlined"
            />
          )}
          value={usuarioAutorizaActual}
          onChange={(event, newInputValue) => {
            setUsuarioAutorizaActual(newInputValue ? newInputValue : -1);
            setUsuarioAutorizaId(
              newInputValue ? newInputValue.CodigoUsuario : 0
            );
          }}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Autocomplete
          className={classes.textField}
          style={{
            width: "100%",
            marginLeft: 10,
            marginTop: 25,
          }}
          options={listaUsuarios}
          autoHighlight
          getOptionLabel={(option) => option.NombreCompletoUsuario}
          disabled={false}
          renderOption={(option) => (
            <React.Fragment>
              <span></span>
              {option.NombreCompletoUsuario}
            </React.Fragment>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Usuario Hotel Autoriza"
              variant="outlined"
            />
          )}
          value={usuarioHotelAutorizaActual}
          onChange={(event, newInputValue) => {
            setUsuarioHotelAutorizaActual(newInputValue ? newInputValue : -1);
            setUsuarioHotelAutorizaId(
              newInputValue ? newInputValue.CodigoUsuario : 0
            );
          }}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <TextField
          id="txtObservaciones"
          label={"Observaciones"}
          multiline
          // variant="outlined"
          className={classes.textField}
          value={observaciones}
          onChange={handleChangeObservaciones}
          margin="normal"
          inputProps={{ maxLength: 250 }}
          style={{ width: "100%" }}
        />
      </Grid>

      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#69bd4b",
              marginBottom: 10,
              marginTop: 10,
              color: "white",
              width: "100%",
            }}
            className={classes.buttonAdd}
            onClick={() => {
              save();
            }}
          >
            Guardar
          </Button>
        </Grid>
      </Grid>
    </Fragment>
  );
}
