import axios from "axios";
import { isSignedIn, signOut } from "./auth";
//import { environment } from '../app.json';

let environment = "prod";

let BASE_API = "";
let BASE_APDIGITALES = "";
let BASE_API_GIO = "";
if (environment == "local") {
  BASE_API = "http://localhost:49930/";
  BASE_APDIGITALES = "https://desaapdigitales.launion.com.gt/api/";
  //BASE_API = "http://192.168.64.106:45455/";
  // BASE_API = "http://192.168.1.7:45455/";
} else if (environment == "dev") {
  BASE_API = "https://desagestionapp.launion.com.gt/api/";
  BASE_APDIGITALES = "https://desaapdigitales.launion.com.gt/api/";
  BASE_API_GIO =
    "https://bik7viamt7.execute-api.us-east-1.amazonaws.com/v1/maestros/";
} else if (environment == "qa") {
  BASE_API = "https://qagestionapp.launion.com.gt/api/";
  BASE_APDIGITALES = "https://desaapdigitales.launion.com.gt/api/";
  BASE_API_GIO =
    "https://bik7viamt7.execute-api.us-east-1.amazonaws.com/v1/maestros/";
} else if (environment == "prod") {
  BASE_API = "https://api.launion.com.gt/";
  BASE_APDIGITALES = "https://apdigitales.launion.com.gt/api/";
  BASE_API_GIO =
    "https://bik7viamt7.execute-api.us-east-1.amazonaws.com/v1/maestros/";
}

class Api {
  state = {
    token: {},
  };

  baseApi() {
    return BASE_API;
  }

  getENV() {
    return environment;
  }

  APIS = function (name) {
    switch (name) {
      case "digitalizacion":
        return BASE_APDIGITALES;
      default:
        return BASE_API;
    }
  };

  async Login(user, pass, type) {
    const fetchEndpoint = `${BASE_API}token`;

    var details = {
      userName: user,
      password: pass,
      auth_type: type,
      grant_type: "password",
    };

    var formBody = [];
    for (var property in details) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    const query = await fetch(fetchEndpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: formBody,
    });

    const request = await query.json();
    console.log("API[Login]: DATOS DE SESSION (TOKEN REQUEST)", request);
    return request;
  }

  async getUsuarios() {
    const fetchEndpoint = `${BASE_API}api/usuario/getUsuarios`;

    const request = await this.realizarPeticionGet(fetchEndpoint);
    console.log("API[getUsuarios]: ", request);

    if (request.statusCode == 401) {
      window.location.href = "/login";
    }

    return request;
  }

  //**METODOS GENERALES***********//

  async fetchGetRequest(uri, base_api = BASE_API) {
    const fetchEndpoint = `${base_api}${uri}`;

    const request = await this.realizarPeticionGet(fetchEndpoint);
    console.log(`API[${uri}]: `, request);

    return request;
  }

  async realizarPeticionGet(endPoint) {
    let auth = await isSignedIn();
    const fetchEndpoint = endPoint;

    const query = await fetch(fetchEndpoint, {
      headers: {
        Authorization: "bearer " + auth.accessToken,
      },
    });

    const request = await this.processResponse(query);
    if (request.statusCode == 401) {
      window.location.href = "/login";
    }

    return request;
  }

  async post(uri, formData) {
    //Usamos Axios
    let auth = await isSignedIn();

    const fetchEndpoint = `${BASE_API}${uri}`;

    const request = await axios.post(fetchEndpoint, formData, {
      headers: { Authorization: "bearer " + auth.accessToken },
    });
    console.log(`REQUEST POST AXIOS [${uri}]`, request);

    return request;
  }

  async postNoAuth(uri, formData) {
    //Usamos Axios

    const fetchEndpoint = `${BASE_API}${uri}`;

    const request = await axios.post(fetchEndpoint, formData);
    console.log(`REQUEST POST AXIOS [${uri}]`, request);

    return request;
  }

  async postFile(uri, formData) {
    let auth = await isSignedIn();
    const url = `${BASE_API}${uri}`;
    const request = await axios.post(url, formData, {
      headers: {
        Authorization: `Bearer ${auth.accessToken}`,
        "content-type": "multipart/form-data",
      },
    });
    return request;
  }

  async post_custom_uri(uri, formData, base_api = BASE_API) {
    //Usamos Axios
    let auth = await isSignedIn();

    const fetchEndpoint = `${base_api}${uri}`;

    const request = await axios.post(fetchEndpoint, formData, {
      headers: { Authorization: "bearer " + auth.accessToken },
    });
    console.log(`REQUEST POST AXIOS [${uri}]`, request);

    return request;
  }

  async put(uri, formData, base_api = BASE_API) {
    let auth = await isSignedIn();

    const fetchEndpoint = `${base_api}${uri}`;

    const request = await axios.put(fetchEndpoint, formData, {
      headers: { Authorization: "bearer " + auth.accessToken },
    });
    console.log(`REQUEST POST AXIOS [${uri}]`, request);

    return request;
  }

  async realizarPeticionPostPut(fetchEndpoint, sendData, requestType) {
    fetchEndpoint = `${BASE_API}${fetchEndpoint}`;

    let auth = await isSignedIn();

    var formBody = [];
    for (var property in sendData) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(sendData[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    const query = await fetch(fetchEndpoint, {
      method: requestType,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        Authorization: "Bearer " + auth.accessToken,
      },
      body: formBody,
    });

    const request = await this.processResponse(query);
    if (!(request.statusCode >= 200 && request.statusCode <= 299)) {
      throw new Error(`${requestType} - error sending request`);
    }
    return request;
  }

  ////////////////////////Generales

  async processResponse(response) {
    const statusCode = response.status;
    let data;
    try {
      data = response.json();
    } catch (e) {
      data = {};
    }

    let promiseResponse = await Promise.all([statusCode, data]).then((res) => ({
      statusCode: res[0],
      data: res[1],
    }));

    if (promiseResponse.statusCode === 401) {
      await signOut();
    }

    return promiseResponse;
  }

  async fetchGetRequestWithParams(uri, params, base_api = BASE_API) {
    const urlParameters =
      params != null
        ? "?" +
          Object.keys(params)
            .map(
              (k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k])
            )
            .join("&")
        : "";

    const fetchEndpoint = `${base_api}${uri}${urlParameters}`;

    const request = await this.realizarPeticionGet(fetchEndpoint);
    console.log(`API[${fetchEndpoint}]: `, request);

    if (request.statusCode === 401) {
      if (await signOut()) {
        window.location.href = "/login";
      }

      return false;
    }

    return request;
  }

  async getWithParamsNoQuestionSymbol(uri, params) {
    const urlParameters =
      params != null
        ? "" +
          Object.keys(params)
            .map(
              (k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k])
            )
            .join("&")
        : "";

    const fetchEndpoint = `${BASE_API}${uri}${urlParameters}`;

    const request = await this.realizarPeticionGet(fetchEndpoint);
    console.log(`API[${fetchEndpoint}]: `, request);

    if (request.statusCode === 401) {
      if (await signOut()) {
        window.location.href = "/login";
      }

      return false;
    }

    return request;
  }

  //**METODOS GENERALES GIO / EXTRACCIÓN DE MAQUINARIA***********//

  async getTokenGio(username, password) {
    try {
      //post to AWS
      console.log("username", username);
      console.log("password", password);
      const response = await fetch(BASE_API_GIO + "auth/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          Username: username,
          Password: password,
        }),
      });
      const responseJson = await response.json();
      console.log(responseJson);
      if (responseJson.statusCode == 200) {
        //save token to local storage
        console.log("responseJson.body.Token", responseJson.body);
        localStorage.setItem("tokenGioApi", responseJson.body.Token);
        //save expires to local storage
        localStorage.setItem(
          "tokenGioApiExpira",
          Math.floor(responseJson.body.Expira)
        );
        let respuesta = {
          success: true,
          message: "Token obtenido correctamente.",
          token: responseJson.body.Token,
        };
        //return token
        return respuesta;
      } else {
        console.log("Respuesta Login: ", responseJson);
        return { success: false, message: "Ocurrió un error al autenticarte." };
      }
    } catch (e) {
      console.log(e);
      return { success: false, message: "Ocurrió un error al autenticarte." };
    }
  }

  async getLandmarkTypes(token) {
    try {
      const response = await fetch(BASE_API_GIO + "tipo_punto", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          AuthorizationToken: token,
        },
      });
      const responseJson = await response.json();
      console.log(responseJson);
      return responseJson.body;
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  async postLandmarkType(token, data) {
    try {
      console.log("data", data);
      const response = await fetch(BASE_API_GIO + "tipo_punto", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          AuthorizationToken: token,
        },
        body: JSON.stringify(data),
      });
      const responseJson = await response.json();
      console.log(responseJson);
      return responseJson;
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  async putLandmarkType(token, id, data) {
    try {
      console.log("data", data);
      const response = await fetch(BASE_API_GIO + "tipo_punto/" + id, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          AuthorizationToken: token,
        },
        body: JSON.stringify(data),
      });
      const responseJson = await response.json();
      console.log(responseJson);
      return responseJson;
    } catch (e) {
      console.log(e);
      return [];
    }
  }
}

export const createLandmark = async (landmark, username, media) => {
  try {
    //let token = await getToken();
    const response = await fetch(BASE_API_GIO + "punto", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        //AuthorizationToken: token,
        ApiKey: "pxth3NsYoShn6eJN",
      },
      body: JSON.stringify(landmark),
    });
    const responseJson = await response.json();
    console.log("landmark creado:", responseJson);

    let imagen = null;
    if (media) {
      //subirImagenS3(media);
      //Post imagen using responseJson.body.IdPunto
      // imagen = {
      //   IdPunto: responseJson.body.IdPunto.toString(),
      //   NombreArchivo: media.name,
      //   TipoArchivo: media.type,
      //   UsuarioCreo: username,
      // };
      // console.log('imagen', imagen);
      // const response2 = await fetch(AWS_URL + 'adjunto_punto', {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //     AuthorizationToken: token,
      //   },
      //   body: JSON.stringify(imagen),
      // });
      // const responseJson2 = await response2.json();
      // console.log(responseJson2);
    }

    //Add landmark to landmarks
    // const newLandmark = {
    //   ...landmark,
    //   adjuntos: media ? [AWS_IMAGE_URL + imagen.NombreArchivo] : [],
    // };
    // console.log('newLandmark', newLandmark);
    // return newLandmark;
    return responseJson.body.IdPunto;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export default new Api();
