import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//Own Components
import Layout from "../../layout/containers/Layout";
import API from "../../utils/api";
import SaveButton from "../../helpers/components/save-button-float";
import { isSignedIn } from "../../utils/auth";
import {validaPermisoPaginaActual} from "../../utils/helpers";

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%"
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  buttonAdd: {
    margin: theme.spacing.unit
  },
  iOSSwitchBase: {
    "&$iOSChecked": {
      color: theme.palette.common.white,
      "& + $iOSBar": {
        backgroundColor: "#52d869"
      }
    },
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.sharp
    })
  },
  iOSChecked: {
    transform: "translateX(15px)",
    "& + $iOSBar": {
      opacity: 1,
      border: "none"
    }
  },
  iOSBar: {
    borderRadius: 13,
    width: 42,
    height: 26,
    marginTop: -13,
    marginLeft: -21,
    border: "solid 1px",
    borderColor: theme.palette.grey[400],
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"])
  },
  iOSIcon: {
    width: 24,
    height: 24
  },
  iOSIconChecked: {
    boxShadow: theme.shadows[1]
  }
});

class UsuarioDetail extends React.Component {
  state = {
    open: false,
    data: [],
    CodigoUsuario: "",
    NombreCompletoUsuario: "",
    Usuario: "",
    CorreoElectronico: "",
    UsuarioAD: "",
    AutenticacionWindows: true,
    Activo: true,
    Clave: "",
    roles: [],
    rolesUsuario: []
  };

  async componentWillMount() {
        //CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
        const llaveAgrupacion = "Menu.Principal.Web";
        const llavePagina = "Menu.Principal.Web.Usuarios";
        await validaPermisoPaginaActual(llaveAgrupacion, llavePagina);
        //FIN DE CONSULTA DE PERMISOS

    await this.setState({ CodigoUsuario: this.props.match.params.id });
    await this.makeRequest();
  }

  makeRequest = async () => {
    const request = await API.fetchGetRequest(
      `api/desktop/usuario/${this.state.CodigoUsuario}`
    );
    const usuario = request.data.usuario;
    const rolesUsuario = request.data.rolesUsuario;

    this.setState({
      NombreCompletoUsuario: usuario.NombreCompletoUsuario,
      Usuario: usuario.Usuario,
      CorreoElectronico: usuario.CorreoElectronico,
      UsuarioAD: usuario.UsuarioAD,
      Clave: usuario.Clave,
      Activo: usuario.Activo,
      AutenticacionWindows: usuario.AutenticacionWindows,
      rolesUsuario
    });
  };

  guardarUsuario = async () => {
    try {
      let vIsSignedIn = await isSignedIn();

      if (vIsSignedIn.response) {
        let request;

        const data = {
          NombreCompletoUsuario: this.state.NombreCompletoUsuario,
          CorreoElectronico: this.state.CorreoElectronico,
          UsuarioAD: this.state.UsuarioAD,
          Clave: this.state.Clave,
          AutenticacionWindows: this.state.AutenticacionWindows,
          Activo: this.state.Activo,
          UsuarioModifico: vIsSignedIn.userName,
          UsuarioInserto: vIsSignedIn.userName,
          RolesUsuario: this.state.rolesUsuario
        };

        request = await API.post(
          `api/desktop/usuario/${this.state.CodigoUsuario}`,
          data
        );
        if (request.status != 200) {
          alert(
            "Hubo un error al guardar el registro, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        }

        if (request.statusCode === 401) {
          //alert("cerrar sesion");
        } else {
          if (request.data.response) {
            toast.info(request.data.mensaje);
          } else {
            toast.error(request.data.mensaje);
          }
        }
      }
    } catch (e) {
      alert("hubo un error");
    }
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleChangeSwitch = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  handleChangeRol = name => event => {
    const rolesUsuario = this.state.rolesUsuario;
    let rolesUsuarioCopia = rolesUsuario.filter(item => {
      if (item.CodigoRol === name) {
        item.Activo = event.target.checked;
      }
      return item;
    });

    this.setState({ rolesUsuario: rolesUsuarioCopia });
  };

  render() {
    const { classes } = this.props;
    let { data } = this.state;

    return (
      <div>
        <Layout titulo={`Usuario (${this.state.Usuario})`}>
          <Grid container spacing={24}>
            <Grid item xs />
            <Grid item xs={7}>
              <Card className={classes.card}>
                <CardContent>
                  <Typography color="textSecondary" gutterBottom>
                    Datos generales
                  </Typography>
                  <TextField
                    disabled
                    id="standard-disabled"
                    label="Usuario"
                    value={this.state.Usuario}
                    className={classes.textField}
                    margin="normal"
                  />

                  <TextField
                    id="standard-name"
                    label="Nombres"
                    className={classes.textField}
                    value={this.state.NombreCompletoUsuario}
                    onChange={this.handleChange("NombreCompletoUsuario")}
                    margin="normal"
                  />

                  <TextField
                    id="standard-name"
                    label="Correo"
                    className={classes.textField}
                    value={this.state.CorreoElectronico}
                    onChange={this.handleChange("CorreoElectronico")}
                    margin="normal"
                  />
                  <TextField
                    id="standard-name"
                    label="Usuario AD"
                    className={classes.textField}
                    value={this.state.UsuarioAD}
                    onChange={this.handleChange("UsuarioAD")}
                    margin="normal"
                  />
                  <TextField
                    id="standard-password-input"
                    label="Clave"
                    value={this.state.Clave}
                    className={classes.textField}
                    onChange={this.handleChange("Clave")}
                    type="password"
                    margin="normal"
                  />

                  <Grid container style={{ marginTop: 15 }}>
                    <Grid item xs={10}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        className={classes.textField}
                      >
                        Autenticación Windows
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <FormControlLabel
                        className={classes.textField}
                        control={
                          <Switch
                            classes={{
                              switchBase: classes.iOSSwitchBase,
                              bar: classes.iOSBar,
                              icon: classes.iOSIcon,
                              iconChecked: classes.iOSIconChecked,
                              checked: classes.iOSChecked
                            }}
                            disableRipple
                            checked={this.state.AutenticacionWindows}
                            onChange={this.handleChangeSwitch(
                              "AutenticacionWindows"
                            )}
                            value="AutenticacionWindows"
                          />
                        }
                      />
                    </Grid>
                  </Grid>

                  <br />
                  <Grid container>
                    <Grid item xs={10}>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        className={classes.textField}
                      >
                        Activo
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <FormControlLabel
                        className={classes.textField}
                        control={
                          <Switch
                            classes={{
                              switchBase: classes.iOSSwitchBase,
                              bar: classes.iOSBar,
                              icon: classes.iOSIcon,
                              iconChecked: classes.iOSIconChecked,
                              checked: classes.iOSChecked
                            }}
                            disableRipple
                            checked={this.state.Activo}
                            onChange={this.handleChangeSwitch("Activo")}
                            value="Activo"
                          />
                        }
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <br />
              <br />
              <Card className={classes.card}>
                <CardContent>
                  <Typography color="textSecondary" gutterBottom>
                    Rol
                  </Typography>

                  <FormControl
                    component="fieldset"
                    className={classes.formControl}
                  >
                    <FormGroup>
                      {this.state.rolesUsuario.map((rol, i) => {
                        return (
                          <FormControlLabel
                            key={i}
                            control={
                              <Checkbox
                                checked={rol.Activo}
                                onChange={this.handleChangeRol(rol.CodigoRol)}
                                style={{ color: "#3498DB" }}
                              />
                            }
                            label={rol.Rol}
                          />
                        );
                      })}
                    </FormGroup>
                  </FormControl>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs />
          </Grid>
          <SaveButton onClick={() => this.guardarUsuario()} />
          <ToastContainer />
        </Layout>
      </div>
    );
  }
}

// We need an intermediary variable for handling the recursive nesting.
const UsuarioDetailContainer = withStyles(styles)(UsuarioDetail);

export default withRouter(UsuarioDetailContainer);
