// import React, { useState, useEffect } from 'react';
import React, { useCallback, useRef, useState, useEffect } from "react";
import Form, {
  ButtonItem,
  GroupItem,
  SimpleItem,
  Label,
  CompareRule,
  EmailRule,
  PatternRule,
  RangeRule,
  RequiredRule,
  StringLengthRule,
  AsyncRule,
  CustomRule,
  FormTypes,
} from "devextreme-react/form";

import dayjs from "dayjs";

import CardPersonales from "./card-datos-personales";

import API from "../../utils/api";
import { toast } from "react-toastify";
import { Button, CircularProgress, Typography } from "@material-ui/core";
import { Cancel, Edit } from "@material-ui/icons";

const noDigitsPattern = /^[^0-9]+$/;
const onlyDigitsPatternDPI = /^[0-9]{13}$/;
const onlyDigitsPatternTelefono = /^[0-9]{8}$/;
const onlyDigitsPattern = /^[0-9]+$/;

function PerfilEmpleado({
  dataEmpleado,
  onClose,
  onSaved,
  esCreacionPostulante = false,
  dpiPostulante = null,
  userPostulanteId = null,
}) {
  const formRef = useRef(null);

  const [datos, setDatos] = useState(dataEmpleado);
  const [munis, setMunis] = useState([]);
  const [munisNacimiento, setMunisNacimiento] = useState([]);
  const [inited, setInited] = useState(false);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fotoFile, setFotoFile] = useState([]);
  const [cvFile, setCvFile] = useState([]);
  const [maestroLicenciaConducir, setMaestroLicenciaConducir] = useState([]);
  const [maestroEstadoCivil, setMaestroEstadoCivil] = useState([]);
  const [maestroEtnia, setMaestroEtnia] = useState([]);
  const [maestroDepartamento, setMaestroDepartamento] = useState([]);
  const [maestroMunicipio, setMaestroMunicipio] = useState([]);
  const [maestroGenero, setMaestroGenero] = useState([]);
  const [departamento, setDepartamento] = useState(null);
  const [departamentoNacimiento, setDepartamentoNacimiento] = useState(null);
  const [municipioNacimiento, setMunicipioNacimiento] = useState(null);

  const [modoEdicion, setModoEdicion] = useState(esCreacionPostulante);
  const onValueChangedDepartamento = (e) => {
    // console.log(e);
    setDepartamento(e.value);
  };

  const onValueChangedDepartamentoNacimiento = (e) => {
    // console.log(e);
    setDepartamentoNacimiento(e.value);
  };

  const onValueChangedMunicipioNacimiento = (e) => {
    setMunicipioNacimiento(e.value);
  };

  const generoEditorOptions = {
    dataSource: maestroGenero,
    valueExpr: "codigoGenero",
    displayExpr: "nombre",
    // displayExpr: 'nombre',
    // placeholder: 'Seleccione',
  };

  const departamentoEditorOptions = {
    dataSource: maestroDepartamento,
    valueExpr: "codigoDepartamento",
    displayExpr: "nombre",
    placeholder: "Seleccione departamento",
    onValueChanged: onValueChangedDepartamento,
    costumItemCreateEvent: "change",
  };

  const municipioEditorOptions = {
    dataSource: munis,
    valueExpr: "codigoMunicipio",
    displayExpr: "nombre",
    placeholder: "Seleccione municipio",
  };

  const departamentoNacimientoEditorOptions = {
    dataSource: maestroDepartamento,
    valueExpr: "codigoDepartamento",
    displayExpr: "nombre",
    placeholder: "Seleccione departamento de nacimiento",
    onValueChanged: onValueChangedDepartamentoNacimiento,
    costumItemCreateEvent: "change",
  };

  const municipioNacimientoEditorOptions = {
    dataSource: munisNacimiento,
    valueExpr: "codigoMunicipio",
    displayExpr: "nombre",
    placeholder: "Seleccione municipio de nacimiento",
  };
  const estadoCivilEditorOptions = {
    dataSource: maestroEstadoCivil,
    valueExpr: "codigoEstadoCivil",
    displayExpr: "estadoCivil",
    placeholder: "Seleccione estado civil",
  };
  const etniaEditorOptions = {
    dataSource: maestroEtnia,
    valueExpr: "codigoEtnia",
    displayExpr: "etnia",
    placeholder: "Seleccione etnia",
  };
  const licenciaEditorOptions = {
    dataSource: maestroLicenciaConducir,
    valueExpr: "codigoLicenciaConducir",
    displayExpr: "licenciaConducir",
    placeholder: "Seleccione licencia",
  };

  const birthDateEditorOptions = {
    showClearButton: true,
    useMaskBehavior: true,
    displayFormat: "dd/MM/yyyy",
  };

  const uploadFotoOptions = {
    selectButtonText: "Seleccionar foto",
    labelText: "o arrastre la foto aquí",
    accept: "image/jpeg, image/jpg",
    uploadMode: "useForm",
  };

  const uploadCVOptions = {
    selectButtonText: "Seleccionar documento PDF",
    labelText: "o arrastre el documento aquí",
    accept: "application/pdf",
    uploadMode: "useForm",
  };
  const submitButtonOptions = {
    text: "Guardar Datos",
    useSubmitBehavior: true,
    visible: modoEdicion,
    stylingMode: "contained",
    icon: "save",
    // type: 'success',
  };
  const getMaestroGenero = async () => {
    try {
      setLoading(true);
      const { data } = await API.fetchGetRequest("api/Genero");
      if (data.response) {
        setMaestroGenero(data.ficha);
      } else {
        toast.error("Error al cargar datos");
        console.log("error");
      }
    } catch (error) {
      toast.error("Error al cargar datos");
      console.log("error");
    } finally {
      setLoading(false);
    }
  };
  const getMaestroLicenciaConducir = async () => {
    try {
      setLoading(true);
      const { data } = await API.fetchGetRequest("api/LicenciaConducir");
      if (data.response) {
        setMaestroLicenciaConducir(data.ficha);
      } else {
        toast.error("Error al cargar datos");
        console.log("error");
      }
    } catch (error) {
      toast.error("Error al cargar datos");
      console.log("error");
    } finally {
      setLoading(false);
    }
  };
  const getMaestroEstadoCivil = async () => {
    try {
      setLoading(true);
      const { data } = await API.fetchGetRequest("api/EstadoCivil");
      if (data.response) {
        setMaestroEstadoCivil(data.ficha);
      } else {
        toast.error("Error al cargar datos");
        console.log("error");
      }
    } catch (error) {
      toast.error("Error al cargar datos");
      console.log("error");
    } finally {
      setLoading(false);
    }
  };
  const getMaestroEtnia = async () => {
    try {
      setLoading(true);
      const { data } = await API.fetchGetRequest("api/Etnia");
      if (data.response) {
        setMaestroEtnia(data.ficha);
      } else {
        toast.error("Error al cargar datos");
        console.log("error");
      }
    } catch (error) {
      toast.error("Error al cargar datos");
      console.log("error");
    } finally {
      setLoading(false);
    }
  };
  const getMaestroDepartamento = async () => {
    try {
      setLoading(true);
      const { data } = await API.fetchGetRequest("api/Departamento");
      if (data.response) {
        setMaestroDepartamento(data.ficha);
        // console.log(data.ficha);
      } else {
        toast.error("Error al cargar datos");
        console.log("error");
      }
    } catch (error) {
      toast.error("Error al cargar datos");
      console.log("error");
    } finally {
      setLoading(false);
    }
  };
  const getMaestroMunicipio = async () => {
    try {
      setLoading(true);
      const { data } = await API.fetchGetRequest("api/Municipio");
      if (data.response) {
        setMaestroMunicipio(data.ficha);
      } else {
        toast.error("Error al cargar datos");
        console.log("error");
      }
    } catch (error) {
      toast.error("Error al cargar datos");
      console.log("error");
    } finally {
      setLoading(false);
    }
  };

  const getMunicipios = () => {
    if (departamento && maestroDepartamento.length > 0) {
      // const item = maestroDepartamento.find((i) => i.codigoDepartamento === departamento);
      // const id = item.codigoDepartamento;
      setMunis(
        maestroMunicipio.filter(
          (x) => x.codigoDepartamento === parseInt(departamento)
        )
      );
    }
  };

  const getMunicipiosNacimiento = () => {
    if (departamentoNacimiento && maestroDepartamento.length > 0) {
      setMunisNacimiento(
        maestroMunicipio.filter(
          (x) => x.codigoDepartamento === parseInt(departamentoNacimiento)
        )
      );
    }
  };

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();

      const saveChanges = async () => {
        try {
          e.preventDefault();
          setLoading(true);
          console.log(datos);

          // console.log(esCreacionPostulante);
          //   datos.nombres = `${datos.primerNombre} ${datos.segundoNombre ?? ""} ${
          //     datos.tercerNombre ?? ""
          //   }`;
          //   datos.apellidos = `${datos.primerApellido} ${
          //     datos.segundoApellido ?? ""
          //   } ${datos.apellidoCasada ?? ""}`;
          datos.departamento = maestroDepartamento.find(
            (w) => w.codigoDepartamento === datos.codigoDepartamento
          )
            ? (datos.departamento = maestroDepartamento.find(
                (w) => w.codigoDepartamento === datos.codigoDepartamento
              ).nombre)
            : "departamento no encontrado";
          datos.municipio = maestroMunicipio.find(
            (w) => w.codigoMunicipio === datos.codigoMunicipio
          )
            ? (datos.municipio = maestroMunicipio.find(
                (w) => w.codigoMunicipio === datos.codigoMunicipio
              )).nombre
            : "municipio no encontrado";
          datos.sexo = maestroGenero.find(
            (w) => w.codigoGenero === datos.codigoGenero
          )
            ? maestroGenero.find((w) => w.codigoGenero === datos.codigoGenero)
                .nombre
            : "no encontrado";
          datos.estadoCivil = maestroEstadoCivil.find(
            (w) => w.codigoEstadoCivil === datos.codigoEstadoCivil
          )
            ? maestroEstadoCivil.find(
                (w) => w.codigoEstadoCivil === datos.codigoEstadoCivil
              ).estadoCivil
            : "no encontrado";
          datos.etnia = maestroEtnia.find(
            (w) => w.codigoEtnia === datos.codigoEtnia
          )
            ? maestroEtnia.find((w) => w.codigoEtnia === datos.codigoEtnia)
                .etnia
            : "no encontrado";
          const newPostulante = { ...datos };
          let files = null;

          console.log(newPostulante);
          const url = esCreacionPostulante
            ? "/Postulantes"
            : "/Postulantes/Update";
          const { data } = await API.post(url, newPostulante);

          setLoading(false);
          //   setMessage({
          //     state: true,
          //     variant: "success",
          //     text: "😃 Datos Actualizados!",
          //   });
          if (modoEdicion) {
            handleEditMode();
          }
          onSaved();
        } catch (error) {
          //   setMessage({
          //     state: true,
          //     variant: "error",
          //     text: "😱 Ha ocurrido un error al guardar tus datos",
          //   });
        } finally {
          handleEditMode();
          setLoading(false);
        }
      };

      e.preventDefault();
      saveChanges().catch(console.error);
      // console.log(init);
      // setMessage({
      //   state: true,
      //   variant: 'success',
      //   text: '😃 Datos Actualizados!',
      // });
    },
    [fotoFile, cvFile]
  );

  const updateData = useCallback((e) => {
    // console.log(e);
    // setDatos(e.value);
  }, []);

  const handleEditMode = () => {
    setModoEdicion(!modoEdicion);
  };

  useEffect(() => {
    const cargarDatos = async () => {
      await getMaestroMunicipio();
      await getMaestroDepartamento();
      await getMaestroLicenciaConducir();
      await getMaestroEstadoCivil();
      await getMaestroEtnia();
      await getMaestroGenero();
    };

    try {
      cargarDatos();
    } catch (error) {
      console.log("Error");
    }
    // cargarDatos().catch(console.log('Error'));
  }, []);

  useEffect(() => {
    if (datos && datos.codigoDepartamento && maestroDepartamento.length > 0) {
      setDepartamento(datos.codigoDepartamento);
    }
    if (
      datos &&
      datos.codigoDepartamentoNacimiento &&
      maestroDepartamento.length > 0
    ) {
      setDepartamentoNacimiento(datos.codigoDepartamentoNacimiento);
    }
  }, [maestroDepartamento, datos]);

  useEffect(() => {
    if (departamento > 0) {
      getMunicipios();
    }
  }, [departamento]);

  useEffect(() => {
    if (departamentoNacimiento > 0) {
      getMunicipiosNacimiento();
    }
  }, [departamentoNacimiento]);

  function screenByWidth(width) {
    return width < 720 ? "sm" : "md";
  }

  return (
    <>
      {loading ? (
        <>
          <Typography variant="h5" component="h1">
            Datos Personales
          </Typography>
          <CircularProgress color="success" />
        </>
      ) : (
        <>
          {modoEdicion ? (
            <>
              {!esCreacionPostulante && (
                <Button
                  onClick={handleEditMode}
                  variant="contained"
                  color="success"
                  startIcon={modoEdicion ? <Cancel /> : <Edit />}
                >
                  {modoEdicion ? "Cancelar" : "Editar"}
                </Button>
              )}
              <form onSubmit={handleSubmit}>
                <Form
                  ref={formRef}
                  formData={datos}
                  readOnly={!modoEdicion}
                  // onOptionChanged={onOptionChanged}
                  showColonAfterLabel={true}
                  showValidationSummary={true}
                  onFieldDataChanged={updateData}
                  minColWidth={233}
                  colCount="auto"
                  // screenByWidth={screenByWidth}
                  // colCountByScreen={false}
                >
                  <GroupItem caption="Datos de Identificación">
                    <SimpleItem dataField="dpi">
                      <Label text="DPI" />
                      <RequiredRule message="DPI es requerido" />
                      <PatternRule
                        message="Deben ser 13 digitos"
                        pattern={onlyDigitsPatternDPI}
                      />
                    </SimpleItem>
                    <SimpleItem dataField="nit">
                      <Label text="NIT (Sin guión)" />
                      <PatternRule
                        message="No se permiten letras"
                        pattern={onlyDigitsPattern}
                      />
                    </SimpleItem>
                    <SimpleItem dataField="igss">
                      <Label text="No. Afiliación IGSS" />
                      <PatternRule
                        message="No se permiten letras"
                        pattern={onlyDigitsPattern}
                      />
                    </SimpleItem>
                  </GroupItem>
                  <GroupItem caption="Datos Personales">
                    <SimpleItem dataField="primerNombre">
                      <RequiredRule message="Primer Nombre es requerido" />
                      <PatternRule
                        message="No se permiten numeros"
                        pattern={noDigitsPattern}
                      />
                    </SimpleItem>
                    <SimpleItem dataField="segundoNombre">
                      <PatternRule
                        message="No se permiten numeros"
                        pattern={noDigitsPattern}
                      />
                    </SimpleItem>
                    <SimpleItem dataField="tercerNombre">
                      <PatternRule
                        message="No se permiten numeros"
                        pattern={noDigitsPattern}
                      />
                    </SimpleItem>
                    <SimpleItem dataField="primerApellido">
                      <RequiredRule message="Primer Apellido es requerido" />
                      <PatternRule
                        message="No se permiten numeros"
                        pattern={noDigitsPattern}
                      />
                    </SimpleItem>
                    <SimpleItem dataField="segundoApellido">
                      <PatternRule
                        message="No se permiten numeros"
                        pattern={noDigitsPattern}
                      />
                    </SimpleItem>
                    <SimpleItem dataField="apellidoCasada">
                      <Label text="Apellido de Casada" />
                      <PatternRule
                        message="No se permiten numeros"
                        pattern={noDigitsPattern}
                      />
                    </SimpleItem>
                    <SimpleItem dataField="telefono1">
                      <Label text="Telefono Celular" />
                      <RequiredRule message="Teléfono es requerido" />
                      <PatternRule
                        message="El teléfono debe tener un formato correcto"
                        pattern={onlyDigitsPatternTelefono}
                      />
                    </SimpleItem>
                    <SimpleItem dataField="telefono2">
                      <Label text="Telefono Residencial" />
                      <RequiredRule message="Teléfono es requerido" />
                      <PatternRule
                        message="El teléfono debe tener un formato correcto"
                        pattern={onlyDigitsPatternTelefono}
                      />
                    </SimpleItem>
                    <SimpleItem
                      dataField="email"
                      editorType="dxTextBox"
                      caption="Correo electrónico"
                    >
                      <Label text="Correo electrónico" />
                      <EmailRule message="Email is invalid" />
                      {/* <AsyncRule
                      message="Email is already registered"
                      validationCallback={asyncValidation}
                    /> */}
                    </SimpleItem>
                    <SimpleItem dataField="direccion">
                      <Label text="Dirección" />
                      <RequiredRule message="Dirección es requerida" />
                    </SimpleItem>
                    <SimpleItem dataField="zona" />
                    <SimpleItem dataField="colonia" />
                    <SimpleItem dataField="casaLoteApto">
                      <Label text="Casa, Lote o Apto" />
                    </SimpleItem>
                    <SimpleItem
                      dataField="barrioAldeaCaserioFinca"
                      caption="Barrio, Aldea, Caserio o Finca"
                    >
                      <Label text="Barrio, Aldea, Caserio o Finca" />
                    </SimpleItem>
                    <SimpleItem
                      dataField="cantonCallejon"
                      caption="Cantón o Callejón"
                    />
                    <SimpleItem
                      dataField="codigoDepartamento"
                      editorType="dxSelectBox"
                      editorOptions={departamentoEditorOptions}
                    >
                      <Label text="Departamento" />
                      <RequiredRule message="Departamento es requerido" />
                    </SimpleItem>
                    <SimpleItem
                      dataField="codigoMunicipio"
                      editorType="dxSelectBox"
                      editorOptions={municipioEditorOptions}
                    >
                      <Label text="Municipio" />
                      <RequiredRule message="Municipio es requerido" />
                    </SimpleItem>
                    <SimpleItem
                      dataField="codigoEstadoCivil"
                      editorType="dxSelectBox"
                      editorOptions={estadoCivilEditorOptions}
                      caption="Estado Civil"
                    >
                      <Label text="Estado Civil" />
                      <RequiredRule message="Estado civil es requerido" />
                    </SimpleItem>
                    <SimpleItem
                      dataField="codigoEtnia"
                      editorType="dxSelectBox"
                      editorOptions={etniaEditorOptions}
                      caption="Etnia"
                    >
                      <Label text="Etnia" />
                      <RequiredRule message="Etnia es requerida" />
                    </SimpleItem>
                    <SimpleItem
                      dataField="codigoGenero"
                      editorType="dxSelectBox"
                      editorOptions={generoEditorOptions}
                      caption="Genero"
                    >
                      <Label text="Género" />
                      <RequiredRule message="Género es requerido" />
                    </SimpleItem>
                    <SimpleItem
                      dataField="codigoLicencia"
                      editorType="dxSelectBox"
                      editorOptions={licenciaEditorOptions}
                    >
                      <Label text="Licencia de Conducir" />
                      <RequiredRule message="Licencia es requerida" />
                    </SimpleItem>
                    <SimpleItem
                      editorType="dxDateBox"
                      dataField="nacimiento"
                      editorOptions={birthDateEditorOptions}
                    >
                      <Label text="Fecha de Nacimiento" />
                      <RequiredRule message="Fecha de Nacimiento es requerida" />
                    </SimpleItem>
                    <SimpleItem
                      dataField="codigoDepartamentoNacimiento"
                      editorType="dxSelectBox"
                      editorOptions={departamentoNacimientoEditorOptions}
                    >
                      <Label text="Departamento de nacimiento " />
                      <RequiredRule message="Departamento de nacimiento es requerido" />
                    </SimpleItem>
                    <SimpleItem
                      dataField="codigoMunicipioNacimiento"
                      editorType="dxSelectBox"
                      editorOptions={municipioNacimientoEditorOptions}
                    >
                      <Label text="Municipio de Nacimiento" />
                      <RequiredRule message="Municipio de nacimiento es requerido" />
                    </SimpleItem>
                    <SimpleItem dataField="numeroCuenta">
                      <Label text="Número de Cuenta Monetaria Banco Industrial" />
                    </SimpleItem>
                  </GroupItem>
                  <ButtonItem buttonOptions={submitButtonOptions} />
                </Form>
              </form>
            </>
          ) : (
            <CardPersonales postulante={datos} showUpdate={handleEditMode} />
          )}
        </>
      )}
    </>
  );
}

export default PerfilEmpleado;
