import React, { Component } from "react";
import PropTypes from "prop-types";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import API from "../../utils/api";
import { signIn, isSignedIn } from "../../utils/auth";
import SimpleToast from "../../helpers/components/simple-toast";
import { Redirect } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import logo from "../../image-repository/logo.png";
import Loader from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const styles = (theme) => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: "#2B3C4D",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
});

class SignIn extends Component {
  state = {
    userName: "",
    password: "",
    redirect: false,
    isLogin: false,
  };

  async componentWillMount() {
    let vIsSignedIn = await isSignedIn();

    if (vIsSignedIn.response) {
      this.setState({ redirect: true });
    }
  }

  handleLogin = async () => {
      this.setState({ isLogin: true });

    try {
      await API.Login(this.state.userName, this.state.password, 1).then(
        async (result) => {
          if (await signIn(result)) {
            this.setState({ redirect: true });
          } else {
            this.setState({ isLogin: false, redirect: false });
            toast.error(
              "Su usuario o contraseña no son correctos, intentalo de nuevo."
            );
          }
        }
      );
    } catch (e) {
      alert(
        "En este momento no se puede realizar tu solicitud, intentalo más tarde o llama a tu administrador"
      );
    } finally {
      this.setState({ isLogin: false });
    }
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  _handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.handleLogin();
    }
  };

  submitHandler(e) {
    e.preventDefault();
  }

  render() {
    const { classes } = this.props;
    if (this.state.redirect) {
      return <Redirect to={"/home"} />;
    }
    return (
      <>
        <AppBar position="static">
          <Toolbar style={{ backgroundColor: "#2B3C4D" }}>
            <img src={logo} className="ImgLogo" />
          </Toolbar>
        </AppBar>
        <main className={classes.main}>
          <CssBaseline />
          <Paper className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Ingreso a la aplicación
            </Typography>
            <div>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="usuario">Usuario (CUI)</InputLabel>
                <Input
                  id="usuario"
                  name="usuario"
                  autoComplete="usuario"
                  autoFocus
                  onChange={this.handleChange("userName")}
                />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="password">Contraseña</InputLabel>
                <Input
                  name="password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  onChange={this.handleChange("password")}
                  onKeyPress={this._handleKeyPress}
                />
              </FormControl>
              {
                // <FormControlLabel
                //   control={<Checkbox value="remember" color="primary" />}
                //   label="Recordarme"
                // />
              }
              <Button
                fullWidth
                variant="contained"
                style={{
                  backgroundColor: "#69BD4B",
                  color: "white",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={this.handleLogin}
                //className={classes.submit}
              >
                {this.state.isLogin ? (
                  <Loader type="Oval" color="white" height="25" width="25" />
                ) : (
                  <></>
                )}
                &nbsp;Iniciar sesión
              </Button>
            </div>
          </Paper>
        </main>
        <ToastContainer />
      </>
    );
  }
}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SignIn);
