import React, { useState, useEffect, useRef } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import DeleteForeverRoundedIcon from "@material-ui/icons/DeleteForeverRounded";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Chip from "@material-ui/core/Chip";
import FaceIcon from "@material-ui/icons/Face";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CancelIcon from "@material-ui/icons/Cancel";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    marginTop: "10px",
  },
  details: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    textAlign: "center",
  },
  content: {
    flex: "1 0 auto",
    width: "100%",
  },
  cover: {
    width: 151,
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  icon: {
    height: 38,
    width: 38,
  },
  text: {},
}));

export default function GrupoCard(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [usuariosCopia, setUsuariosCopia] = useState([]);
  const [textoBusqueda, setTextoBusqueda] = useState("");

  // Equivalente a didmount
  // useEffect(() => {
  //   setUsuariosCopia(props.usuarios);
  // }, []);

  //equivalente a didupdate (solo cuando cambie el valor de variable textobusqueda)
  useEffect(() => {
    if (textoBusqueda.length > 0) {
      let filtro = props.usuarios.filter((fila) => {
        return fila.NombreCompletoUsuario.toLowerCase().includes(
          textoBusqueda.toLowerCase()
        );
      });
      setUsuariosCopia(filtro);
    } else {
      setUsuariosCopia(props.usuarios);
    }
  }, [textoBusqueda]);

  //Equivalente a didmount cuando cambia el valor de props.usuarios
  useEffect(() => {
    setUsuariosCopia(props.usuarios);
  }, [props.usuarios]);

  const setSearch = (name) => (event) => {
    setTextoBusqueda(event.target.value);
  };

  return (
    <div className={classes.root}>
      <Card className={classes.root}>
        <div className={classes.details}>
          <CardContent className={classes.content}>
            <div>
              {/* <h3>Miembros del grupo</h3> */}
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                <TextField
                  id="txtBusqueda"
                  label="Buscar"
                  className={classes.textField}
                  value={textoBusqueda}
                  onChange={setSearch("nombre")}
                  margin="normal"
                  inputProps={{
                    maxLength: 200,
                  }}
                  style={{ width: "90%" }}
                  // onKeyPress={(ev) => {
                  //   console.log("keyP: " + ev.key);
                  //   if (ev.key === "Enter") {
                  //     filtrar();
                  //     ev.preventDefault();
                  //   }
                  // }}
                />
                <IconButton
                  aria-label="Agregar"
                  onClick={() => {
                    props.openModalUsuarios(props.codigo);
                  }}
                >
                  <PersonAddIcon />
                </IconButton>
              </div>
              <div style={{ marginTop: 30 }}>
                {/* {usuariosCopia.map((item, i) => (
                  <Chip
                    icon={<FaceIcon />}
                    label={item.NombreCompletoUsuario}
                    clickable
                    color="primary"
                    variant="outlined"
                    onDelete={() => {
                      props.removerUsuario(item.CodigoGrupoUsuario);
                    }}
                    deleteIcon={<CancelIcon />}
                    style={{
                      margin: 3,
                    }}
                  />
                ))} */}

                <Paper className={classes.root}>
                  <Table className={classes.table}>
                    {/* <TableHead>
          <TableRow>
            <TableCell style={{ width: "80%" }}>Nombre</TableCell>
 
            <TableCell style={{ width: "20%" }}>
              
            </TableCell>
            
          </TableRow>
        </TableHead> */}
                    <TableBody>
                      {usuariosCopia.map((row, i) => (
                        <TableRow>
                          <TableCell
                            key={row.CodigoGrupoUsuario}
                            // onClick={() => {
                            //   props.navegar(
                            //     row.IdObjeto,
                            //     row.Nombre,
                            //     row.Llave
                            //   );
                            // }}
                            style={{ cursor: "pointer", width: "5%" }}
                          >
                            {row.NombreCompletoUsuario}
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            style={{
                              textAlign: "right",
                              width: "25%",
                            }}
                          >
                            <CancelIcon
                              onClick={() => {
                                props.removerUsuario(row.CodigoGrupoUsuario);
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Paper>
              </div>
            </div>
          </CardContent>
        </div>
      </Card>
    </div>
  );
}
