import React, { useState, useEffect } from 'react'
import {
  Button,
  Grid,
  Fab,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  makeStyles,
  LinearProgress
} from '@material-ui/core'
import { green } from '@material-ui/core/colors'
import { Edit, Add, Delete } from '@material-ui/icons'
import Layout from '../layout/containers/Layout'
import api from '../utils/api'
import { toast, ToastContainer } from 'react-toastify'
import LoadingButton from './componentes/utils/LoadingButton'
import { getUsuarioInserta } from './utils/utilidades'

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'absolute',
    background: green[500],
    color: '#FFF',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  }
}))

const SistemaGestionEdit = ({ title, onSubmit, onClose, onRefresh, initial=null }) => {
  const [nombre, setNombre] = useState('')
  const [loading, setLoading] = useState(false)
  const submit = async () => {
    try {
      setLoading(true)
      await onSubmit(nombre)
      onRefresh()
      toast.success('Registro guardado 😃')
    } catch (error) {
      toast.error('Ha ocurrido un error')
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    if (initial) {
      setNombre(initial.NombreSistemaGestion)
    }
  }, [])
  return <>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <TextField
          fullWidth
          label="Nombre"
          onChange={ev => setNombre(ev.target.value)}
          value={nombre}
        />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cerrar</Button>
      <LoadingButton
        loading={loading}
        onClick={submit}
        color="primary"
        variant="contained"
      >
        Guardar
      </LoadingButton>
    </DialogActions>
  </>
}

const Entornos = () => {
  const classes = useStyles()
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(false)
  const [selected, setSelected] = useState(null)
  const [dialogAdd, setDialogAdd] = useState(false)
  const [dialogEdit, setDialogEdit] = useState(false)
  const getSistemas = async () => {
    try {
      setLoading(true)
      const { data } = await api.fetchGetRequest('api/documentos/sistemagestion')
      setItems(data)
    } catch (error) {
      toast.error('Ha ocurrido un error')
    } finally {
      setLoading(false)
    }
  }
  const saveSistemaGestion = async (nombre) => {
    const body = {
      NombreSistemaGestion: nombre,
      UsuarioInserto: getUsuarioInserta()
    }
    await api.realizarPeticionPostPut('api/documentos/sistemagestion/crear',
      body,
      'POST')
  }
  const updateSistemaGestion = async (nombre) => {
    const id = selected.Id
    const body = {
      NombreSistemaGestion: nombre,
      UsuarioModifico: getUsuarioInserta()
    }
    await api.realizarPeticionPostPut(`api/documentos/sistemagestion/editar/${id}`,
      body,
      'POST')
  }
  const deleteSistema = async (idx) => {
    if (window.confirm('¿Estas seguro de borrar el sistema de gestión?')) {
      const id = items[idx].Id
      const body = {
        UsuarioModifico: getUsuarioInserta()
      }
      await api.realizarPeticionPostPut(`api/documentos/sistemagestion/eliminar/${id}`,
        body,
        'POST')
      toast.success('Elemento eliminado 🔥')
      refresh()
    }
  }
  const showEdit = (idx) => {
    const item = {...items[idx]}
    setSelected(item)
    setDialogEdit(true)
  }
  const refresh = () => {
    setDialogAdd(false)
    setDialogEdit(false)
    getSistemas()
  }
  useEffect(() => {
    getSistemas()
  }, [])
  return <Layout titulo="Sistemas de Gestión">
    <Grid container justify="center">
      <Grid item xs={12} md={6}>
        {/* <Typography variant="h5" component="h1">Entornos</Typography> */}
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Codigo</TableCell>
                <TableCell>Sistema</TableCell>
                <TableCell>Opciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                items.map((i, idx) =>
                <TableRow key={idx}>
                  <TableCell>{idx + 1}</TableCell>
                  <TableCell>{i.NombreSistemaGestion}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => showEdit(idx)}>
                      <Edit />
                    </IconButton>
                    <IconButton onClick={() => deleteSistema(idx)}>
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>)
              }
            </TableBody>
          </Table>
        </TableContainer>
        {
          loading && <LinearProgress />
        }
      </Grid>
    </Grid>
    <Fab onClick={() => setDialogAdd(true)} className={classes.fab}>
      <Add />
    </Fab>
    <Dialog fullWidth maxWidth="md" open={dialogAdd} onClose={() => setDialogAdd(false)} >
      <SistemaGestionEdit
        title="Agregar sistema de gestión"
        onClose={() => setDialogAdd(false)}
        onRefresh={refresh}
        onSubmit={saveSistemaGestion}
      />
    </Dialog>
    <Dialog fullWidth maxWidth="md" open={dialogEdit} onClose={() => setDialogEdit(false)} >
      <SistemaGestionEdit
        title="Editar sistema de gestión"
        onClose={() => setDialogEdit(false)}
        onRefresh={refresh}
        onSubmit={updateSistemaGestion}
        initial={selected}
      />
    </Dialog>
    <ToastContainer />
  </Layout>
}

export default Entornos