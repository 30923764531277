import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Grid from "@material-ui/core/Grid";
import { IOSSwitch } from "../../helpers/components/ios-switch";

//Own Components
import Layout from "../../layout/containers/Layout";
import API from "../../utils/api";
import Tabla from "../components/tabla-list";
import AddButton from "../../helpers/components/add-button-float";
import { isSignedIn } from "../../utils/auth";
import {validaPermisoPaginaActual} from "../../utils/helpers";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%"
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none"
  },
  buttonAdd: {
    margin: theme.spacing.unit
  }
});

class List extends React.Component {
  state = {
    open: false,
    data: [],
    agrupacion: {
      CodigoPermisoAgrupacion: null,
      Activo: false,
      PermisoAgrupacion: "",
      Descripcion: ""
    },
    actionType: "" //Crear, Actualizar
  };

  async componentWillMount() {
    //CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
    const llaveAgrupacion = "Menu.Principal.Web";
    const llavePagina = "Menu.Principal.Web.Agrupaciones";
    await validaPermisoPaginaActual(llaveAgrupacion, llavePagina);
    //FIN DE CONSULTA DE PERMISOS


    await this.makeRequest();
  }

  makeRequest = async () => {
    const request = await API.fetchGetRequest(
      "api/desktop/permisoagrupacion/listado"
    );

    this.setState({
      data: request.data.list
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
      actionType: ""
    });
  };

  handleOpenAddModal = () => {
    this.setState({
      actionType: "Agregar",
      open: true
    });
  };

  handleChange = name => event => {
    let agrupacion = this.state.agrupacion;

    agrupacion[name] = event.target.value;
    this.setState({
      agrupacion
    });
  };

  guardar = async () => {
    const { PermisoAgrupacion, Descripcion } = this.state.agrupacion;

    if (PermisoAgrupacion.trim() == "" || Descripcion.trim() == "") {
      toast.warn("Llene todos los campos");
      return;
    }

    try {
      let vIsSignedIn = await isSignedIn();

      if (vIsSignedIn.response) {
        let request;
        if (this.state.actionType == "Agregar") {
          const data = {
            PermisoAgrupacion,
            Descripcion,
            UsuarioInserto: vIsSignedIn.userName
          };

          request = await API.post(`api/desktop/permisoagrupacion`, data);
          if (request.status != 200) {
            alert(
              "Hubo un error al guardar el registro, por favor revisa los datos de tu formulario o consulta con un administrador"
            );
            return;
          }
        } else {
          const data = {
            ...this.state.agrupacion,
            UsuarioModifico: vIsSignedIn["userName"]
          };
          request = await API.post(
            `api/desktop/permisoagrupacion/${data.CodigoPermisoAgrupacion}`,
            data
          );

          if (request.status != 200) {
            alert("Hubo un error al actualizar tu registro");
            return;
          }

          toast.info(request.data.mensaje);
        }

        if (request.status === 401) {
          //alert("cerrar sesion");
        } else {
          //this.props.history.push(`/agrupaciones`);
        }
      }
    } catch (e) {
      alert("hubo un error");
    }
  };

  editItem = async item => {
    await this.setState({
      open: true,
      dispositivo: item.Device,
      dispositivoDescripcion: item.Descripcion,
      dispositivoDeviceId: item.DeviceId,
      dispositivoActivo: item.Activo ? "1" : "0",
      actionType: "Actualizar",
      editData: item
    });
  };

  redirectToDetail = item => {
    //this.props.history.push("/rol/" + id);
    console.log("ITEM ACTUAL", item);
    this.setState({ actionType: "Actualizar", open: true, agrupacion: item });
  };

  handleChangeSwitch = name => event => {
    let agrupacion = this.state.agrupacion;

    agrupacion[name] = event.target.checked;
    this.setState({ agrupacion });
  };

  render() {
    const { classes } = this.props;
    let { data } = this.state;

    return (
      <div>
        <Layout titulo="Agrupaciones">
          <Tabla
            data={data}
            editItem={this.editItem}
            redirectToDetail={this.redirectToDetail}
          />
          <AddButton onClick={() => this.handleOpenAddModal()} />

          <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={this.state.open}
            onClose={this.handleClose}
          >
            <div style={getModalStyle()} className={classes.paper}>
              <form className={classes.container} noValidate autoComplete="off">
                <Typography variant="title" gutterBottom>
                  {this.state.actionType == "Agregar" ? (
                    <div>Crear</div>
                  ) : (
                    <div>Editar</div>
                  )}
                </Typography>
                <TextField
                  id="txtAgrupacion"
                  label="Agrupacion"
                  className={classes.textField}
                  value={this.state.agrupacion.PermisoAgrupacion}
                  onChange={this.handleChange("PermisoAgrupacion")}
                  margin="normal"
                  variant="outlined"
                />
                <TextField
                  id="txtDescripcion"
                  label="Descripcion"
                  className={classes.textField}
                  value={this.state.agrupacion.Descripcion}
                  onChange={this.handleChange("Descripcion")}
                  margin="normal"
                  variant="outlined"
                />
                <br />
                <br />
                {this.state.actionType === "Actualizar" ? (
                  <>
                    <Grid container>
                      <Grid item xs={9}>
                        <Typography
                          color="textSecondary"
                          gutterBottom
                          className={classes.textField}
                        >
                          Activo
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        {/* <FormControlLabel
                        className={classes.textField}
                        control={
                          <Switch
                            classes={{
                              switchBase: classes.iOSSwitchBase,
                              bar: classes.iOSBar,
                              icon: classes.iOSIcon,
                              iconChecked: classes.iOSIconChecked,
                              checked: classes.iOSChecked
                            }}
                            disableRipple
                            checked={this.state.permiso.Activo}
                            onChange={this.handleChangeSwitch("Activo")}
                            value="Activo"
                          />
                        }
                      /> */}
                        <FormControlLabel
                          className={classes.textField}
                          control={
                            <IOSSwitch
                              checked={this.state.agrupacion.Activo}
                              onChange={this.handleChangeSwitch("Activo")}
                              value="Activo"
                            />
                          }
                        />
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <></>
                )}

                <Button
                  variant="contained"
                  style={{ backgroundColor: "#69bd4b", color: "white" }}
                  className={classes.buttonAdd}
                  onClick={() => this.guardar()}
                >
                  Guardar
                </Button>
              </form>
            </div>
          </Modal>
          <ToastContainer />
        </Layout>
      </div>
    );
  }
}

// We need an intermediary variable for handling the recursive nesting.
const ListContainer = withStyles(styles)(List);

export default withRouter(ListContainer);
