import React, { Component } from "react";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";
//import Chip from "@material-ui/core/Chip";
import DeleteIcon from "@material-ui/icons/Delete";
import Fab from "@material-ui/core/Fab";
import { withStyles, makeStyles } from "@material-ui/core/styles";
//snackbar
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import { amber, green } from "@material-ui/core/colors";
import WarningIcon from "@material-ui/icons/Warning";
import EditIcon from '@material-ui/icons/Edit';
import SentimentVeryDissatisfied from "@material-ui/icons/SentimentVeryDissatisfied";
//para personalizar los colores de snackbar
import clsx from "clsx";
import SnackbarContent from "@material-ui/core/SnackbarContent";


//Own components
import TableTitle from "../helpers/components/table-title";
import TableDescription from "../helpers/components/table-description";
import Link from "../helpers/components/link";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";


const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 2,
    overflowX: "auto"
  },
  table: {
    minWidth: 700
  },
  fab: {
    margin: theme.spacing()
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  buttonWhite: {
    backgroundColor: "#fff"
  }
});

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  },
  table: {
    minWidth: 700
  },

  fab: {
    margin: theme.spacing()
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  buttonWhite: {
    backgroundColor: "#fff"
  }
}));

//Código para personalizar snackbar (colores contextuales: succes, error, etc.)
const useStyles1 = makeStyles(theme => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.main
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: "flex",
    alignItems: "center"
  },

  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: "100%"
  }
}));

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
  cargando:false
};

function MySnackbarContentWrapper(props) {
  const classes = useStyles1();
  const { className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>
      ]}
      {...other}
    />
  );
}

MySnackbarContentWrapper.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(["error", "info", "success", "warning"]).isRequired
};

//Fin de bloque de personalización de snackbar

class PlantillaListAlertaTable extends Component {
  state = {
    confirmOpen: false,
    confirmTexto: "",
    codigo:""
  };

confirmarAccion = id => {
  this.setState({
    confirmTexto:
      `¿Confirma que desea eliminar este registro? esta acción no podrá revertirse.`,
    confirmOpen: true,
    codigo: id
  });
};

funcionOk = () => {
  this.eliminar();
};

cerrarConfirm = () => {
  this.setState({ confirmTexto: "", confirmOpen: false });
};

eliminar = async () => {
       await this.props.eliminar(this.state.codigo);
       this.setState({codigo: "" });
     };

render() {
  const classes = useStyles;

  if (this.props.data) {
    return (
      <React.Fragment>
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "80%" }}>Nombre</TableCell>
                {/* <TableCell style={{ width: "10%" }}>Entidad</TableCell> */}
                <TableCell style={{ width: "10%" }}>Activo</TableCell>
                <TableCell style={{ width: "5%" }}>Editar</TableCell>
                <TableCell style={{ width: "5%" }}>Eliminar</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.data.map((row, i) => (
                <TableRow key={i}>
                  <TableCell style={{cursor:"pointer"}} onClick={() => this.props.redireccionar(row.CodigoPlantillaAlerta)}>
                    <TableTitle>{row.Nombre}</TableTitle>
                  </TableCell>
                  {/* <TableCell>{row.nombreEntidad}</TableCell> */}
                  <TableCell>{row.Activo ? "Sí" : "No"}</TableCell>
                  <TableCell>
                    <Fab
                      onClick={() =>  this.props.editar(row.CodigoPlantillaAlerta)}
                      color="primary"
                      aria-label="Ver detalle"
                      className={classes.fab}
                      size="small"
                    >
                      <EditIcon/>
                    </Fab>
                  </TableCell>
                  <TableCell>
                    <Fab
                      onClick={() => this.confirmarAccion(row.CodigoPlantillaAlerta)}
                      color="secondary"
                      aria-label="delete"
                      className={classes.fab}
                      size="small"
                    >
                      <DeleteIcon />
                    </Fab>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
        <ConfirmDialog
          id="confirmar"
          keepMounted
          open={this.state.confirmOpen}
          onClose={this.cerrarConfirm}
          value=""
          texto={this.state.confirmTexto}
          okfunction={this.funcionOk}
        />
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          open={this.state.snackBarOpen}
          autoHideDuration={6000}
          onClose={this.handleCloseSnackbar}
        >
          <MySnackbarContentWrapper
            onClose={this.handleCloseSnackbar}
            variant={this.state.varianteSnackbar}
            message={this.state.errorValidacion}
          />
        </Snackbar>
      </React.Fragment>
    );
  }else{
    return (
      <div style={{ color: "#2980b9", marginTop: "10px" }}>
        ¡No se encontraron registros!{" "}
        <SentimentVeryDissatisfied fontSize="small" />
      </div>
    );
  }
}
}

export default withStyles(styles)(PlantillaListAlertaTable);
