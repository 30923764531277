import React, { useState } from 'react'
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField
} from '@material-ui/core'
import {
  green,
  amber
} from '@material-ui/core/colors'
import { ToastContainer, toast } from 'react-toastify'

import LoadingButton from '../utils/LoadingButton'
import api from '../../../utils/api'
import { getCodigoUsuario } from '../../utils/utilidades'

const SolicitudRevisar = ({ datos, onClose, onRefresh }) => {
  const [observaciones, setObservaciones] = useState('')
  const [loading, setLoading] = useState(false)

  const aprobarSolicitud = async () => {
    try {
      setLoading(true)
      const codigoUsuario = getCodigoUsuario()
      const form = new FormData()
      form.append('IdSolicitud', datos.IdSolicitud)
      form.append('CodigoUsuario', codigoUsuario)
      form.append('Comentario', observaciones)
      await api.post('api/documentos/solicitud/aprobar-documento', form)
      toast.success('Solicitud aprobada')
      onRefresh()
    } catch (error) {
      toast.error('Ha ocurrido un error')
    } finally {
      setLoading(false)
    }
  }

  const solicitarCambios = async () => {
    if (!window.confirm('¿Estas seguro de solicitar cambios?')) {
      return
    }
    const obs = observaciones.trim()
    if (!obs) {
      toast.error('Las observaciones son requeridas')
      return
    }
    try {
      setLoading(true)
      const codigoUsuario = getCodigoUsuario()
      const form = new FormData()
      form.append('IdSolicitud', datos.IdSolicitud)
      form.append('CodigoUsuario', codigoUsuario)
      form.append('Comentario', obs)
      form.append('Estado', 'cambios revision')
      await api.post('api/documentos/solicitud/solicitar-cambios', form)
      toast.success('Se envio la solicitud de cambios')
      onRefresh()
    } catch (error) {
      toast.error('Ha ocurrido un error')
    } finally {
      setLoading(false)
    }
  }

  return <>
    <DialogTitle>Revisar documento</DialogTitle>
    <DialogContent>
      <TextField
        fullWidth
        value={observaciones}
        onChange={ev => setObservaciones(ev.target.value)}
        multiline
        rows={4}
        label="Observaciones"
      />
      <LoadingButton
        fullWidth
        color="primary"
        loading={loading}
        onClick={aprobarSolicitud}
        style={{marginTop: 10, backgroundColor: green[500], color: 'white'}}
      >
        Aprobar revisión
      </LoadingButton>
      <LoadingButton
        fullWidth
        color="error"
        loading={loading}
        onClick={solicitarCambios}
        style={{marginTop: 10, backgroundColor: amber[500], color: 'white'}}
      >
        Solicitar cambios
      </LoadingButton>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancelar</Button>
    </DialogActions>
    <ToastContainer />
  </>
}

export default SolicitudRevisar
