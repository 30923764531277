import { Badge, Grid, IconButton, Typography } from "@material-ui/core";
import { Publish, ViewList } from "@material-ui/icons";
import React from "react";

const DetalleDocumentosComponente = ({
  archivos,
  archivosBasicos,
  classes,
  //   openUpload,
  openListaArchivos,
  contenedorURL,
}) => {
  console.log(archivosBasicos);
  console.log(archivos);
  return (
    <div style={{ maxHeight: 600, overflowY: "scroll" }}>
      <Grid container>
        {archivosBasicos.map((item, index) => (
          <Grid key={index} item xs={12}>
            <div className={classes.recurso}>
              <Typography variant="subtitle1" style={{ fontWeight: 500 }}>
                {item.Documento}
              </Typography>
              {/* {item.Plantilla && (
                <a href={process.env.PUBLIC_URL + item.Plantilla} download>
                  Descargar plantilla
                </a>
              )} */}
              {/* {i.tipo === 'link' && i.link ? (
                    <a target="_blank" href={i.link} rel="noopener noreferrer">
                      {i.link}
                    </a>
                  ) : undefined} */}
              <div className={classes.recursoActions}>
                {/* <IconButton
                  //   onClick={() => openUpload(item, index)}
                  size="large"
                >
                  <Publish />
                </IconButton> */}

                {archivos && (
                  <IconButton
                    onClick={() => openListaArchivos(item, index)}
                    size="large"
                  >
                    <Badge
                      // badgeContent={1}
                      badgeContent={
                        archivos.filter(
                          (j) => j.CodigoDocumento === item.CodigoDocumento
                        )[0]
                          ? archivos.filter(
                              (j) => j.CodigoDocumento === item.CodigoDocumento
                            )[0].grupo
                            ? archivos.filter(
                                (j) =>
                                  j.CodigoDocumento === item.CodigoDocumento
                              )[0].grupo.length
                            : 0
                          : 0
                      }
                      color="primary"
                    >
                      <ViewList />
                    </Badge>
                  </IconButton>
                )}
              </div>
            </div>
          </Grid>
        ))}
      </Grid>
      {/* {!archivos.length && (
        <Alert variant="filled" severity="info">
          No hay archivos asignados
        </Alert>
      )} */}
      {/* {fetching && <LinearProgress />} */}
    </div>
  );
};

export default DetalleDocumentosComponente;
