import React, { useState, useEffect } from 'react'
import {
  Button,
  DialogContent,
  DialogActions,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core'
import LoadingButton from '../utils/LoadingButton'
import api from '../../../utils/api'
import { getUsuarioInserta, range } from '../../utils/utilidades'
import { toast } from 'react-toastify'

const TiempoUpdate = ({ datos, min, max, onClose, onRefresh }) => {
  const dias = range(min, max)
  const [valor, setValor] = useState('')
  const [loading, setLoading] = useState(false)
  const actualizar = async () => {
    try {
      setLoading(true)
      const body = {
        ...datos,
        UsuarioModifico: getUsuarioInserta()
      }
      await api.realizarPeticionPostPut(`api/documentos/tiempo-recordatorio/editar/${datos.Id}`, body, 'POST')
      onRefresh()
    } catch (error) {
      console.log(error)
      toast.error('Ha ocurrio un error')
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    setValor(datos.Valor)
  }, [])
  return <>
    <DialogTitle>
      Actualizar tiempo de aviso
    </DialogTitle>
    <DialogContent>
      <FormControl fullWidth>
        <InputLabel>Tiempo</InputLabel>
        <Select
          value={valor}
          onChange={ev => setValor(ev.target.value)}
        >
          {
            dias.map((i, idx) => <MenuItem key={idx} value={i}>
              {i > 1 ? `${i} días` : `${i} día`}
            </MenuItem>)
          }
        </Select>
      </FormControl>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancelar</Button>
      <LoadingButton
        loading={loading}
        color="primary"
        onClick={actualizar}
      >
        Actualizar
      </LoadingButton>
    </DialogActions>
  </>
}

export default TiempoUpdate
