import React from "react";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  greenAvatar: {
    fontSize: "2.5em",
    color: "#fff",
    // borderStyle:'solid',
    // borderWidth:'1 !important',
    // borderColor:'#3f51b5 !important',
    backgroundColor: green[500],
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  greenAvatarFoto: {
    fontSize: "2.5em",
    color: "#fff",
    // borderStyle:'solid',
    // borderWidth:'1 !important',
    // borderColor:'#3f51b5 !important',
    backgroundColor: green[500],
    width: theme.spacing(25),
    height: theme.spacing(25),
  },
  paperCard: {
    padding: 20,
    width: "100%",
  },
});

const PerfilRH = (props) => {
  const { classes, dataEmpleado } = props;

  let user = dataEmpleado;

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} sm container>
          <Grid item xs container direction="column" spacing={2}>
            <Grid item xs>
            
              <Typography variant="body2" gutterBottom>
                <b>DPI:</b> {user.DPI}
              </Typography>
              <Typography variant="body2" gutterBottom>
                <b>Código Empleado:</b> {user.CodigoAnterior}
              </Typography>
              <Typography variant="body2" gutterBottom>
                <b>Afiliación IGSS:</b> {user.IGSS}
              </Typography>
              <Typography variant="body2" gutterBottom>
                <b>Estado Civil:</b> {user.EstadoCivil}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} sm container>
            <Grid item xs>
              
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

// class PerfilRH extends Component {
//   render() {
//     const { classes, dataEmpleado } = this.props;

//     let user = dataEmpleado;

//     return (
//       <React.Fragment>
//         <Grid container spacing={2}>
//           <Grid item>
//             <Avatar alt="" className={classes.greenAvatar} style={{cursor:'pointer'}}>
//               {!user.NombreEmpleado ? "NA" : user.NombreEmpleado.substring(0,2)}
//             </Avatar>
//           </Grid>
//           <Grid item xs={12} sm container>
//             <Grid item xs container direction="column" spacing={2}>
//               <Grid item xs>
//                 <Typography gutterBottom variant="subtitle1">
//                   <b>{user.NombreEmpleado}</b>
//                 </Typography>
//                 <Typography variant="body2" gutterBottom>
//                  <b>DPI:</b> {user.DPI}
//                 </Typography>
//                 <Typography variant="body2" gutterBottom>
//                   <b>Código Empleado:</b> {user.CodigoAnterior}
//                 </Typography>
//                 <Typography variant="body2" gutterBottom>
//                 <b>Afiliación IGSS:</b> {user.IGSS}
//                 </Typography>
//                 <Typography variant="body2" gutterBottom>
//                 <b>Estado civil:</b> {user.EstadoCivil}
//                 </Typography>
//                 <Typography variant="body2" gutterBottom>
//                 <b>Puesto Laboral:</b> {user.PuestoLaboral}
//                 </Typography>
//                 <Typography variant="body2" gutterBottom>
//                 <b>Fecha de Alta:</b> {!user.FechaAltaReal ? "" : new Date(user.FechaAltaReal).toLocaleDateString()}
//                 </Typography>
//               </Grid>

//             </Grid>
//             <Grid item>
//             <Typography variant="body2" gutterBottom>
//             <b>No. Patronal:</b> {user.NumeroPatronalIGSS}
//                 </Typography>
//               <Typography variant="body2" gutterBottom>
//               <b>Gerencia:</b> {user.Gerencia}
//                 </Typography>
//                 <Typography variant="body2" gutterBottom>
//                 <b>Empresa:</b> {user.Empresa}
//                 </Typography>
//                 <Typography variant="body2" gutterBottom>
//                 <b>Área:</b> {user.AreaEmpresa}
//                 </Typography>
//                 <Typography variant="body2" gutterBottom>
//                 <b>Departamento:</b> {user.DepartamentoEmpresa}
//                 </Typography>
//                 <Typography variant="body2" gutterBottom>
//                 <b>Subdepartamento:</b> {user.SubDepartamentoEmpresa}
//                 </Typography>
//                 <Typography variant="body2" gutterBottom>
//                 <b>Segmeto de Op:</b> {user.SegmentoDeOperacion}
//                 </Typography>
//             </Grid>
//           </Grid>
//         </Grid>

//       </React.Fragment>
//     );
//   }
// }
const PerfilContainer = withStyles(styles)(PerfilRH);
export default PerfilContainer;
