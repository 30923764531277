import React from 'react'
import { useStoreState } from 'easy-peasy'
import {
  Description,
  Search,
  ListAlt,
  Business,
  FormatListNumberedOutlined,
  Store,
  Shuffle,
  Email,
  Block,
  Lock,
  VpnKey,
  Book,
  EventSeat,
  Help,
  Subject
} from '@material-ui/icons'
import OptionComponent from '../components/option-component'
import { Collapse } from '@material-ui/core'
import OptionComponentCollapse from "../components/option-component-collapse"

const items = [
  {
    id: 0,
    icon: <Description style={{ color: "white" }} />,
    label: "Borradores",
    type: "secondarysub",
    to: "/documentos/mis-documentos",
    permiso: "GESDOC.Autor",
  },
  {
    id: 1,
    icon: <Search style={{ color: "white" }} />,
    label: "Directorio de docs.",
    type: "secondarysub",
    to: "/documentos/directorio",
    permiso: "GESDOC.DirectorioDocumentos",
  },
  {
    id: 2,
    icon: <ListAlt style={{ color: "white" }} />,
    label: "Plantillas",
    type: "secondarysub",
    to: "/documentos/plantillas",
    permiso: "GESDOC.AdminPlantillas",
  },
  {
    id: 3,
    icon: <Business style={{ color: "white" }} />,
    label: "Sistemas de gestión",
    type: "secondarysub",
    to: "/documentos/entornos",
    permiso: "GESDOC.AdminSistemasGestion",
  },
  {
    id: 4,
    icon: <FormatListNumberedOutlined style={{ color: "white" }} />,
    label: "Correlativos",
    type: "secondarysub",
    to: "/documentos/correlativos",
    permiso: "GESDOC.Correlativos",
  },
  {
    id: 5,
    icon: <Store style={{ color: "white" }} />,
    label: "Centros documentales",
    type: "secondarysub",
    to: "/documentos/centros-documentales",
    permiso: "GESDOC.AdminCentrosDocumentales",
  },
  {
    id: 6,
    icon: <Shuffle style={{ color: "white" }} />,
    label: "Transferir docs.",
    type: "secondarysub",
    to: "/documentos/transferir",
    permiso: "GESDOC.TransferenciaDocumentos",
  },
  {
    id: 7,
    icon: <Email style={{ color: "white" }} />,
    label: "Notificaciones",
    type: "secondarysub",
    to: "/documentos/notificaciones",
    permiso: "GESDOC.AdminNotificaciones",
  },
  {
    id: 8,
    icon: <Block style={{ color: "white" }} />,
    label: "Docs. Obsoletos",
    type: "secondarysub",
    to: "/documentos/obsoletos",
    permiso: "GESDOC.DirectorioObsoletos",
  },
  {
    id: 9,
    icon: <Lock style={{ color: "white" }} />,
    label: "Roles de usuario",
    type: "secondarysub",
    to: "/documentos/roles",
    permiso: "GESDOC.Usuarios"
  },
  {
    id: 10,
    icon: <VpnKey style={{ color: "white" }} />,
    label: "Permisos",
    type: "secondarysub",
    to: "/documentos/permisos",
    permiso: "GESDOC.Usuarios"
  },
  {
    id: 11,
    icon: <EventSeat style={{ color: "white" }} />,
    label: "Control Doc",
    type: "secondarysub",
    to: "/documentos/control",
    permiso: "GESDOC.PublicarDocumento"
  },
  {
    id: 12,
    icon: <Subject style={{ color: "white" }} />,
    label: "Blog preguntas frecuentes",
    type: "secondarysub",
    to: "/documentos/blog-preguntas",
    permiso: "GESDOC.PublicarDocumento"
  },
  {
    id: 13,
    icon: <Help style={{ color: "white" }} />,
    label: "Preguntas frecuentes",
    type: "secondarysub",
    to: "/documentos/preguntas",
    permiso: "GESDOC.Menu"
  }
]

const MenuGesDoc = ({ collapse, open, toogleCollapse, handleDrawerOpen }) => {
  const { permisosGesDoc } = useStoreState(state => ({
    permisosGesDoc: state.permisosGesDoc
  }))

  return <>
    {
      permisosGesDoc.find(i => i === 'GESDOC.Menu')
        && <OptionComponentCollapse
          onClick={() => {
            toogleCollapse("Gestión documental");
            handleDrawerOpen();
          }}
          label="Gestión documental"
          icon={<Book style={{ color: "white" }} />}
          collapse={collapse}
          estadoBarra={open}
        />
    }
    <Collapse
      in={collapse === "Gestión documental"}
      timeout="auto"
      unmountOnExit
    >
      {
        items.map(j => <OptionComponent
          key={j.id}
          name={j.label}
          icon={j.icon}
          url={j.to}
          estadoBarra={open}
          permiso={
            permisosGesDoc.filter(i => i === j.permiso)
              .length > 0
          }
          esSubmenu={true}
        />)
      }
    </Collapse>
  </>
}

export default MenuGesDoc
