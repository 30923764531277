import React, { useState, useEfect } from "react";
import LayoutPublic from "../layout/containers/layout-public";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import SkipNextIcon from "@material-ui/icons/SkipNext";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    width: 151,
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
}));

function RedirectApp(props) {
  const codigoAvisoTemp = props.match.params.codigoGestion;
  const classes = useStyles();
  const theme = useTheme();
  return (
    <LayoutPublic
      titulo={"AppILU"}
      bodyContainerStyle={{
        padding: 0,
        margin: 0,
        maxWidth: "none",
        height: "100%",
      }}
    >
      <Container maxWidth="sm" style={{ padding: 25 }}>
        <a
          rel="alternate"
          id="deep"
          href={`appilu://home/gestion/asignados/${codigoAvisoTemp}`}
          style={{ textDecoration: "none" }}
        >
          <Card className={classes.root} style={{ padding: 20 }}>
            <div
              className={classes.details}
              style={{ display: "flex", flexGrow: 1 }}
            >
              <CardContent className={classes.content}>
                <Typography component="h5" variant="h5">
                  Abrir con AppILU
                </Typography>
                <Typography variant="subtitle1" color="textSecondary">
                  Android / IOS
                </Typography>
              </CardContent>
              <div className={classes.controls} style={{ minHeight: 75 }}></div>
            </div>
            <CardMedia
              className={classes.cover}
              image="https://api.launion.com.gt/img/general/Icon-512.png"
              title="Appilu Logo"
            />
          </Card>
        </a>
      </Container>

      <div
        className="container"
        style={{
          position: "absolute",
          bottom: 0,
          backgroundColor: "black",
          color: "white",
          minWidth: "100%",
          padding:25,
        }}
      >
        <div style={{ width: "100%" }}>
          <div style={{ width: "50%", display: "flex" }}>
            <div style={{ width: "50%" }}>
              <a style={{textDecoration:"none"}}  
              href="https://d30ov6hdgkm04k.cloudfront.net/">
                <img
                  src="https://api.launion.com.gt/img/general/appenterprise.png"
                  style={{ width: "100%" }}
                />
              </a>
            </div>

            <div style={{ width: "50%" }}>
              <a style={{textDecoration:"none"}} href="https://play.google.com/store/apps/details?id=com.app_ilu1">
                <img
                  src="https://api.launion.com.gt/img/general/playstore.png"
                  style={{ width: "100%" }}
                />
              </a>
            </div>
          </div>
        </div>
        <div style={{ textAlign: "center", withWidth: "100%", marginTop: 20 }}>
          © Copyright 2021 Ingenio La Unión.
        </div>
      </div>
    </LayoutPublic>
  );
}

export default RedirectApp;
