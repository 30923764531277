import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Loader from "react-loader-spinner";
import GestionesToolbar from "../components/gestiones-toolbar";

import {
  Divider,
  Grid,
  IconButton,
  Popover,
  Typography,
  withStyles,
} from "@material-ui/core";
import { AddCircle } from "@material-ui/icons";
import Layout from "../../layout/containers/Layout";
import API from "../../utils/api";
import { isSignedIn, signOut } from "../../utils/auth";
import { validaPermisoPaginaActual, verificaPermisoPaginaActual } from "../../utils/helpers";
import Calendario from "../components/calendario";
import FeedNoticias from "../components/feed-noticias";
import { clearNavigationHistory } from "../../utils/navigationGestion";
import { ToastContainer } from "react-toastify";
//  import {registrarAnalitica} from "../../utils/analitica";
import {registrarAnalitica} from "../../utils/firebase-config";

const styles = (theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paperPopover: {
    padding: theme.spacing(1),
    background: "#000",
    color: "#fff",
  },
});

class Dashboard extends Component {
  state = {
    creados: 0,
    asignados: 0,
    tareaspendientes: 0,
    compartidos: 0,
    noticias: [],
    mostrarMoxipanel:false,
  };

  async componentWillMount() {

    //analitica 
       
     registrarAnalitica('Home');

    //CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
    const llaveAgrupacion = "Menu.Principal.Web";
    const llavePagina = "Menu.Principal.Web.Gestiones";
    await validaPermisoPaginaActual(llaveAgrupacion, llavePagina);
    //FIN DE CONSULTA DE PERMISOS


    //Verifica si debe mostrar el botón moxipanel
    const mostrarMoxipanel = await verificaPermisoPaginaActual(llaveAgrupacion,"Menu.Principal.Web.panelsoporte");
    console.log("Mostrar moxipanel", mostrarMoxipanel);
    this.setState({mostrarMoxipanel})
    const session = await isSignedIn();
    if (session.response) {
      //Cargar lista subentidades
      this.setState(
        {
          codigoUsuarioActual: session["codigoUsuario"],
          usuarioAfectado: parseInt(session["codigoUsuario"]),
          // nombreUsuarioActual: session["Luis Ardani Ramirez Morales"],
        },
        async () => {
          await this.makeRequest();
        }
      );
      this.obtenerUltimasNotificias(session["codigoUsuario"]);
    } else {
      window.location.href = "/login";
    }
  }

  obtenerUltimasNotificias = (codigo) => {
    API.fetchGetRequest(
      `api/notificacion/getTopNotificaciones/${codigo}/5`
    ).then((response) => {
      const { data } = response;
      console.log("data ", data);
      this.setState({ noticias: data });
    });
  };

  limpiarHistorialNavegacion = async () => {
    //Remueve data de historial de navegación
    clearNavigationHistory();
  };

  makeRequest = async () => {
    try {
      const session = await isSignedIn();
      const uri = `api/aviso/home/${session.codigoUsuario}`;
      const request = await API.fetchGetRequest(uri);

      if (request.data.response) {
        //OK
        this.setState({
          compartidos: 0,
          creados: request.data.creados[0].Cantidad,
          asignados: request.data.asignados[0].Cantidad,
          tareaspendientes: request.data.tareasAsignadas[0].Cantidad,
          isLoading: false,
          error: false,
        });
      } else {
        //El API alerta error.
        this.setState({
          isLoading: false,
          error: true,
        });
      }
    } catch (e) {
      //Hubo error en el request al server
      this.setState({
        isLoading: false,
        error: true,
      });
    }
  };

  goNotificaciones = (id) => {
    this.props.history.push(`/notificacionesPorUsuario/${id}`);
  };

  nuevaGestion = () => {
    this.limpiarHistorialNavegacion();
    this.props.history.push("/admongestiones");
  };

  soporte = async () => {
    try {
      registrarAnalitica('Solicitar_Soporte');
      const { data } = await API.fetchGetRequest('api/aviso/idgestionSoporte');
      console.log('data ', data);
      this.limpiarHistorialNavegacion();
      this.props.history.push(`/aviso/nuevo/${data.data}`);
    } catch (e) {
      this.setState({ isLoading: false, error: true });
    }
  };


  panelSoporte = async () => {
    try {
      registrarAnalitica('Panel_Soporte');
      this.limpiarHistorialNavegacion();
      this.props.history.push(`/moxipanel`);
    } catch (e) {
      this.setState({ isLoading: false, error: true });
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <Layout bodyHeader={false} titulo="Inicio" maxWidth={"false"}>
        <div
          style={{
            color: "#2196F3",
            display: "flex",
            alignContent: "center",
            fontSize: "1m",
            marginTop: 10,
          }}
        ></div>
        {this.state.cargando ? (
          <div
            style={{
              width: "100%",
              marginTop: 10,
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loader
              type="Oval"
              color="#3498db"
              height="50"
              width="50"
              style={{ textAlign: "center" }}
            />
          </div>
        ) : (
          <></>
        )}

        <Grid container style={{ marginBottom: '0.5rem' }}>
          <Grid item md={12} sm={12} xs={12}>
            <div style={{ width: "100%" }}>
              <FeedNoticias
                data={this.state.noticias}
                goNotificaciones={this.goNotificaciones}
              />
            </div>
          </Grid>
        </Grid>

        <div
          style={{
            width: "100%",
            display: "flex",
            marginBottom: "0.5rem",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h5" color="textPrimary">
            Gestiones
          </Typography>
        </div>
        <GestionesToolbar
          creados={this.state.creados}
          pendientes={this.state.tareaspendientes}
          push={this.props.history.push}
          nuevaGestion={this.nuevaGestion}
          soporte={this.soporte}
          panelSoporte = {this.panelSoporte}
          showPanelSoporte = {this.state.mostrarMoxipanel}
        />
        <div style={{ width: "100%", margin: "2rem 0" }}>
          <Divider variant="middle" />
        </div>
        <Grid container>
          <Grid item md={12} sm={12} xs={12}>
            <div style={{ width: "100%" }}>
              <Typography variant="h5" color="textPrimary">
                Calendario de eventos
              </Typography>
              <Calendario changeFechas={this.changeFechas} />
            </div>
          </Grid>
        </Grid>
        <ToastContainer />
        <Popover
          id="mouse-over-popover"
          className={classes.popover}
          classes={{
            paper: classes.paperPopover,
          }}
          open={this.state.popOverOpen}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          onClose={this.handlePopoverClose}
          disableRestoreFocus
        >
          <Typography>{this.state.textoPopover}</Typography>
        </Popover>
      </Layout>
    );
  }
}

const DashboardHome = withStyles(styles)(Dashboard);
export default withRouter(DashboardHome);
