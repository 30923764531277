import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { green, pink } from "@material-ui/core/colors";
import { useForm, Controller } from "react-hook-form";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";

import Layout from "../../layout/containers/Layout";
import ButtonAdd from "../../helpers/components/button-add";
import API from "../../utils/api";
import { isSignedIn, signOut } from "../../utils/auth";
import { validaPermisoPaginaActual } from "../../utils/helpers";
import { ToastContainer, toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";

const width_proportion = "100%";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 210,
  },
  textFieldDireccion: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: "100%",
    margin: theme.spacing(1),
    minWidth: 230,
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  greenAvatar: {
    fontSize: "2.5em",
    color: "#fff",
    // borderStyle:'solid',
    // borderWidth:'1 !important',
    // borderColor:'#3f51b5 !important',
    backgroundColor: green[500],
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  paperCard: {
    padding: 20,
    width: "100%",
  },
  formControlSelects: {
    margin: theme.spacing(1),
    minWidth: 230,
  },
  formControlSelect: {
    // margin: theme.spacing(1),
    width: width_proportion,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

let idEmpleado = 0;

const EmpleadoEditarInfo = (props) => {
  const {
    handleSubmit,
    control,
    formState: { errors: fieldsErrors },
    reset,
    register,
  } = useForm();

  const [userName, setUserName] = useState("");
  const [maestroDepartamentos, setMaestroDepartamentos] = useState([]);
  const [maestroMunicipios, setMaestroMunicipios] = useState([]);
  //const [empleadoDireccion, setEmpleadoDireccion] = useState([{}]);
  const [celular, setCelular] = useState("");
  const [referencia, setReferencia] = useState("");
  const [cargandoMaestros, setCargandoMaestros] = useState(false);

  const [CodigoDepartamento, setCodigoDepartamento] = useState(0);
  const [CodigoMunicipio, setCodigoMunicipio] = useState(0);
  const [Direccion, setDireccion] = useState("");
  const [Zona, setZona] = useState("");
  const [Colonia, setColonia] = useState("");
  const [CasaLoteApto, setCasaLoteApto] = useState("");
  const [BarrioAldeaCaserioFinca, setBarrioAldeaCaserioFinca] = useState("");
  const [CantonCallejon, setCantonCallejon] = useState("");

  const [valueAutoCompleteDepartamento, setValueAutoCompleteDepartamento] =
    useState(null);
  const [valueAutoCompleteMunicipio, setValueAutoCompleteMunicipio] =
    useState(null);

  const [buscando, setBackDrop] = useState(false);

  const handleCerrarBackDrop = () => {
    setBackDrop(false);
  };
  const handleAbrirBackDrop = () => {
    setBackDrop(true);
  };

  const classes = useStyles();

  useEffect(() => {
    const cargar = async () => {
      if (!cargandoMaestros) {
        handleAbrirBackDrop();

        await cargarMaestroDepartamentos();
        //await cargarMaestroMunicipio();

        setCargandoMaestros(true);
      } else {
        if (props.dataEmpleado !== undefined) {
          idEmpleado = props.dataEmpleado.DPI;

          await cargarDatosEmpleado(props.dataEmpleado.DPI);
        }
        handleCerrarBackDrop();
      }
    };

    cargar();
  }, [cargandoMaestros]);

  const cargarMaestroDepartamentos = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      setUserName(session["userName"]);
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/Empleado/MaestroDepartamentoGT/";

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setMaestroDepartamentos(request.data.data);
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cargarMaestroMunicipio = async (idDepartamento, idMunicipio = 0) => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/Empleado/MaestroMunicipio/" + idDepartamento;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setMaestroMunicipios(request.data.data);

        if (idMunicipio !== 0) {
          let valorMunicipio = request.data.data.filter(
            (x) => x.CodigoMunicipio === idMunicipio
          )[0];
          console.log(valorMunicipio);
          setValueAutoCompleteMunicipio(valorMunicipio);
        } else {
          let valorMunicipio = request.data.data[0];
          console.log(valorMunicipio);
          setValueAutoCompleteMunicipio(valorMunicipio);
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const cargarDatosEmpleado = async (dpiEmpleado) => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    }

    try {
      handleAbrirBackDrop();

      let uri = "api/Empleado/Direccion/" + dpiEmpleado;

      const request = await API.fetchGetRequest(uri);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        setCodigoDepartamento(request.data.data.CodigoDepartamento);
        setCodigoMunicipio(request.data.data.CodigoMunicipio);
        setDireccion(request.data.data.Direccion);
        setZona(request.data.data.Zona);
        setColonia(request.data.data.Colonia);
        setCasaLoteApto(request.data.data.CasaLoteApto);
        setBarrioAldeaCaserioFinca(request.data.data.BarrioAldeaCaserioFinca);
        setCantonCallejon(request.data.data.CantonCallejon);
        setCelular(request.data.data.TelefonoCelular);
        setReferencia(request.data.data.TelefonoReferencia);

        let valorDepartamento = maestroDepartamentos.filter(
          (x) => x.CodigoDepartamento === request.data.data.CodigoDepartamento
        )[0];

        setValueAutoCompleteDepartamento(valorDepartamento);

        await cargarMaestroMunicipio(
          request.data.data.CodigoDepartamento,
          request.data.data.CodigoMunicipio
        );
        // let valorMunicipio = maestroMunicipios.filter(
        //   (x) => x.CodigoMunicipio === request.data.data.CodigoDepartamento
        // )[0];
        // console.log(valorMunicipio);
        // setValueAutoCompleteMunicipio(valorMunicipio);

        //
      }
    } catch (e) {
      console.log(e);
    } finally {
      handleCerrarBackDrop();
    }
  };

  const handleOnChangeDepartamento = async (event, newValue) => {
    handleAbrirBackDrop();
    if (newValue !== null) {
      setCodigoDepartamento(newValue.CodigoDepartamento);
      setValueAutoCompleteDepartamento(newValue);

      await cargarMaestroMunicipio(newValue.CodigoDepartamento);
    }
    handleCerrarBackDrop();
  };
  const handleOnChangeMunicipio = async (event, newValue) => {
    handleAbrirBackDrop();
    if (newValue !== null) {
      setCodigoMunicipio(newValue.CodigoMunicipio);
      setValueAutoCompleteMunicipio(newValue);
    }
    handleCerrarBackDrop();
  };

  const cuandoHayError = async (data) => {
    toast.info(
      "Existen algunos campos con error, por favor valida el formulario",
      {
        position: "top-right",
        autoClose: 3000, // Esta en milisegundos 3 segundos
      }
    );
  };

  const guardarFicha = async () => {
    const data = {
      DPI: props.dataEmpleado.DPI,
      CodigoEmpleado: props.dataEmpleado.CodigoEmpleado,
      CodigoMunicipio: CodigoMunicipio,
      Direccion: Direccion,
      Zona: Zona,
      Colonia: Colonia,
      CasaLoteApto: CasaLoteApto,
      BarrioAldeaCaserioFinca: BarrioAldeaCaserioFinca,
      CantonCallejon: CantonCallejon,
      TelefonoCelular: celular,
      TelefonoReferencia: referencia,
      activo: true,
      eliminado: false,
      usuarioInserto: userName,
      fechaInserto: "",
      usuarioModifico: "",
      fechaModifico: "",
    };

    console.log(data);
    // dataFicha.usuarioInserto = "prueba";
    try {
      handleAbrirBackDrop();
      let vIsSignedIn = await isSignedIn();
      if (vIsSignedIn.response) {
        let request;

        request = await API.post("api/empleado/direccion", data);
        if (request.status !== 200) {
          toast.error(
            "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
          );
        } else {
          props.dataEmpleado.TelefonoCelular = celular;
          props.dataEmpleado.TelefonoReferencia = referencia;
          if(props.onChangeDataEmpleado!==undefined){
          props.onChangeDataEmpleado(props.dataEmpleado);
          }
          console.log(props.dataEmpleado);
          toast.success("Se guardó exitosamente");
        }
      }
    } catch (e) {
      console.log("EXCEPTION:"+e);
      toast.error(
        "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
      );
    } finally {
      handleCerrarBackDrop();
    }
  };

  return (
    <React.Fragment>
      <Backdrop className={classes.backdrop} open={buscando}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer />
      <Grid container spacing={3}>
        <form
          autoComplete="off"
          onSubmit={handleSubmit(guardarFicha, cuandoHayError)}
        >
          <Grid item xs={6} sm={6} md={12} lg={12}>
            <br />
            <FormControl className={classes.formControlSelects}>
              <Controller
                name="Departamento"
                render={({ departamento }) => (
                  <Autocomplete
                    id="departamento"
                    options={maestroDepartamentos}
                    getOptionLabel={(option) => option.Nombre}
                    value={valueAutoCompleteDepartamento}
                    onChange={(event, newValue) =>
                      handleOnChangeDepartamento(event, newValue)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Departamento"
                        variant="outlined"
                      />
                    )}
                  />
                )}
                control={control}
              />
            </FormControl>
            <FormControl className={classes.formControlSelects}>
              <Controller
                name="Municipio"
                render={({ municipio }) => (
                  <Autocomplete
                    id="municipio"
                    options={maestroMunicipios}
                    getOptionLabel={(option) => option.Nombre}
                    value={valueAutoCompleteMunicipio}
                    onChange={(event, newValue) =>
                      handleOnChangeMunicipio(event, newValue)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Municipio"
                        variant="outlined"
                      />
                    )}
                  />
                )}
                control={control}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FormControl className={classes.textFieldDireccion}>
              <Controller
                name="Direccion"
                render={({ direccion }) => (
                  <TextField
                    id="Direccion"
                    label="Dirección"
                    name="Direccion"
                    value={Direccion}
                    error={fieldsErrors.Direccion ? true : false}
                    onChange={(e) => setDireccion(e.target.value)}
                    variant="outlined"
                    helperText={
                      fieldsErrors.Direccion
                        ? fieldsErrors.Direccion.message
                        : null
                    }
                  />
                )}
                control={control}
              />
            </FormControl>
            <FormControl className={classes.textFieldDireccion}>
              <Controller
                name="Zona"
                render={({ zona }) => (
                  <TextField
                    id="Zona"
                    label="Zona"
                    name="Zona"
                    value={Zona}
                    error={fieldsErrors.Zona ? true : false}
                    onChange={(e) => setZona(e.target.value)}
                    variant="outlined"
                    helperText={
                      fieldsErrors.Zona ? fieldsErrors.Zona.message : null
                    }
                  />
                )}
                control={control}
              />
            </FormControl>
            <FormControl className={classes.textFieldDireccion}>
              <Controller
                name="Colonia"
                render={({ colonia }) => (
                  <TextField
                    id="Colonia"
                    label="Colonia"
                    name="Colonia"
                    value={Colonia}
                    error={fieldsErrors.Colonia ? true : false}
                    onChange={(e) => setColonia(e.target.value)}
                    variant="outlined"
                    helperText={
                      fieldsErrors.Colonia ? fieldsErrors.Colonia.message : null
                    }
                  />
                )}
                control={control}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FormControl className={classes.textFieldDireccion}>
              <Controller
                name="CasaLoteApto"
                render={({ casaLoteApto }) => (
                  <TextField
                    id="CasaLoteApto"
                    label="Casa, lote o Apto."
                    name="CasaLoteApto"
                    value={CasaLoteApto}
                    error={fieldsErrors.CasaLoteApto ? true : false}
                    onChange={(e) => setCasaLoteApto(e.target.value)}
                    variant="outlined"
                    helperText={
                      fieldsErrors.CasaLoteApto
                        ? fieldsErrors.CasaLoteApto.message
                        : null
                    }
                  />
                )}
                control={control}
              />
            </FormControl>
            <FormControl className={classes.textFieldDireccion}>
              <Controller
                name="BarrioAldeaCaserioFinca"
                render={({ barrioAldeaCaserioFinca }) => (
                  <TextField
                    id="BarrioAldeaCaserioFinca"
                    label="Barrio, aldea, caserío o finca"
                    name="BarrioAldeaCaserioFinca"
                    value={BarrioAldeaCaserioFinca}
                    error={fieldsErrors.BarrioAldeaCaserioFinca ? true : false}
                    onChange={(e) => setBarrioAldeaCaserioFinca(e.target.value)}
                    variant="outlined"
                    helperText={
                      fieldsErrors.BarrioAldeaCaserioFinca
                        ? fieldsErrors.BarrioAldeaCaserioFinca.message
                        : null
                    }
                  />
                )}
                control={control}
              />
            </FormControl>
            <FormControl className={classes.textFieldDireccion}>
              <Controller
                name="CantonCallejon"
                render={({ cantonCallejon }) => (
                  <TextField
                    id="CantonCallejon"
                    label="Cantón o callejón"
                    name="CantonCallejon"
                    value={CantonCallejon}
                    error={fieldsErrors.CantonCallejon ? true : false}
                    onChange={(e) => setCantonCallejon(e.target.value)}
                    variant="outlined"
                    helperText={
                      fieldsErrors.CantonCallejon
                        ? fieldsErrors.CantonCallejon.message
                        : null
                    }
                  />
                )}
                control={control}
              />
            </FormControl>
           
          </Grid>
          <Grid item xs={6} sm={6} md={12} lg={12}>
            <FormControl className={classes.textFieldDireccion}>
              <Controller
                name="celular"
                render={({ cel }) => (
                  <TextField
                    id="celular"
                    label="Teléfono Celular"
                    name="celular"
                    value={celular}
                    error={fieldsErrors.celular ? true : false}
                    onChange={(e) => setCelular(e.target.value)}
                    variant="outlined"
                    helperText={
                      fieldsErrors.celular ? fieldsErrors.celular.message : null
                    }
                  />
                )}
                control={control}
                // rules={{
                //   required: "Es requerido",
                // }}
              />
            </FormControl>
            <FormControl className={classes.textFieldDireccion}>
              <Controller
                name="referencia"
                render={({ ref }) => (
                  <TextField
                    id="referencia"
                    label="Teléfono de Referencia"
                    name="referencia"
                    value={referencia}
                    error={fieldsErrors.referencia ? true : false}
                    onChange={(e) => setReferencia(e.target.value)}
                    variant="outlined"
                    helperText={
                      fieldsErrors.referencia
                        ? fieldsErrors.referencia.message
                        : null
                    }
                  />
                )}
                control={control}
                // rules={{
                //   required: "Es requerido",
                // }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={9} sm={12} md={12} lg={12}>
            <Button
              width="100%"
              variant="contained"
              style={{
                backgroundColor: "#69bd4b",
                marginBottom: 10,
                marginTop: 10,
                color: "white",
                width: "93%",
                marginLeft: 30,
              }}
              type="submit"
            >
              &nbsp;Guardar Cambios
            </Button>
            <br />
            <br />
          </Grid>
        </form>
      </Grid>
    </React.Fragment>
  );
};

export default EmpleadoEditarInfo;
