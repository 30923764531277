import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";
import Loader from "react-loader-spinner";
import Popover from "@material-ui/core/Popover";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Modal from "@material-ui/core/Modal";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import AppsIcon from "@material-ui/icons/Apps";
import FolderIcon from "@material-ui/icons/Folder";
import ViewListIcon from "@material-ui/icons/ViewList";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import SearchIcon from "@material-ui/icons/Search";
import { Chip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

import axios from "axios";

//Icons
import GetAppIcon from "@material-ui/icons/GetApp";
import BarChartIcon from "@material-ui/icons/BarChart";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CloseIcon from "@material-ui/icons/Close";

//Accordeon
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

//Scroll bar
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";

//Appbar
import { fade } from "@material-ui/core/styles";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Divider from "@material-ui/core/Divider";

//Own components
import Layout from "../../layout/containers/Layout";
import ButtonAdd from "../../helpers/components/button-add";
import API from "../../utils/api";
import { isSignedIn, signOut } from "../../utils/auth";
import { convertToYearMonthDay } from "../../utils/helpers";
import CardAvisos from "../../ejecucion-avisos/components/card-aviso";
import { validaPermisoPaginaActual } from "../../utils/helpers";
import Dashboard from "./dashboard";
import {
  clearSuperfiltroHistory,
  getSuperfiltroHistory,
  setSuperfiltroHistory,
} from "../../utils/historialSuperfiltro";
//Lista Etiquetas
import TagList from "../../avisos-objects/components/tag-list";
import SecondNavigation from "../components/second-navigation";
import GenericButtonFloat from "../../helpers/components/generic-button-float";
import ResultTablePagination from "../components/result-table-pagination";
import DevExpressDataGridInocuidad from "../components/devexpress-data-grid-inocuidad";

import Tooltip from "@material-ui/core/Tooltip";

import moment from "moment";

//CSS
import "../../ejecucion-avisos/components/gestion-style.css";
import { DATE_COL_DEF, removeUndefinedProps } from "@material-ui/data-grid";
import { registrarAnalitica } from "../../utils/firebase-config";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
  },
  paper: {
    position: "relative",
    width: "100%", //theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  paperUserModal: {
    position: "absolute",
    width: "75%",
    height: "600px",
    maxHeight: "600px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  popover: {
    pointerEvents: "none",
  },
  paperPopover: {
    padding: theme.spacing(1),
    background: "#000",
    color: "#fff",
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
  rootExpansionPanel: {
    width: "100%",
    marginTop: 30,
    marginBottom: 50,
  },
  ExpansionPanelRow: {},
  alignCenter: {
    alignItems: "center",
  },
  dowloadButton: {
    backgroundColor: "white",
    color: "#2196F3",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#f3f3f3",
      color: "#2196F3",
    },
  },
  titleGestiones: {
    display: "flex",
    flexDirection: "row",
  },
  heading: {
    marginLeft: 15,
  },
  secondNavigationModal: {
    position: "absolute",
    width: "50%",
    height: "400px",
    maxHeight: "400px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
  drawer: {
    width: 300,
    maxWidth: 300,
  },
});

const titleColor = "#2B3C4D";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

class ReporteInocuidad extends Component {
  state = {
    value: 0,
    openDrawer: false,
    fechaInicio: null,
    fechaFin: null,
    codigoGestion: "",
    listaTipoGestion: [],
    listaGestiones: [],
    tipoGestionActual: null,
    tipoGestion: null,
    buscando: false,
    expanded: false,
    //layout
    openToolbar: false,
  };

  async componentDidMount() {
    //CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
    registrarAnalitica('Reporte_Inocuidad');
    const llaveAgrupacion = "Menu.Principal.Web";
    const llavePagina = "Menu.Principal.Web.ReporteInocuidad";
    await validaPermisoPaginaActual(llaveAgrupacion, llavePagina);
    //FIN DE CONSULTA DE PERMISOS

    const session = await isSignedIn();

    let fechaInicio = new Date();
    fechaInicio.setDate(fechaInicio.getDate() - 30);

    if (session.response) {
      this.setState(
        {
          fechaInicio: convertToYearMonthDay(fechaInicio),
          fechaFin: convertToYearMonthDay(new Date()),
        },
        async () => {
          await this.cargarListaTipoGestion();
        }
      );
    } else {
      window.location.href = "/login";
    }
  }

  cargarListaUsuarios = async () => {
    let criterio = "x-x";
    const request = await API.fetchGetRequest(`api/Usuario/buscar/${criterio}`);
    try {
      if (Array.isArray(request.data.usuarios)) {
        this.setState(
          {
            listaUsuarios: request.data.usuarios,
          },
          () => {
            //Obtiene usuario actual
            if (
              this.state.codigoTipoGestion !== null &&
              this.state.codigoTipoGestion !== undefined
            ) {
              this.setState({ codigoUsuarioAsignado: 0 });
              return;
            }
            let actual = this.state.listaUsuarios.filter((usuario) => {
              return (
                usuario.CodigoUsuario ===
                parseInt(this.state.codigoUsuarioActual)
              );
            });
            if (actual.length < 1) {
              return;
            } else {
              actual = actual[0];
            }
          }
        );
      } else {
        this.setState({
          listaUsuarios: [],
        });
      }
    } catch (error) {
      console.log("cargarListaTareas error:" + error);
    }
  };

  async cargarListaFamiliaEtiquetas() {
    const request = await API.fetchGetRequest(`api/familiaetiqueta/listar`);
    if (Array.isArray(request.data.data)) {
      let listaFamilias = request.data.data;
      listaFamilias.push({
        CodigoFamiliaEtiqueta: 0,
        Nombre: "Todas",
        Descripcion: "",
        Activo: true,
      });

      this.setState({
        listaFamiliasEtiquetas: listaFamilias,
      });
    }
  }

  async cargarListaEtiquetas() {
    const request = await API.fetchGetRequest(`api/causa/listarTodas`);
    if (Array.isArray(request.data.data)) {
      this.setState({
        listaEtiquetas: request.data.data,
      });
    }
  }

  listarProblemasCarpeta = async (carpeta) => {
    const request = await API.fetchGetRequest(
      "api/carpetaController/listarProblemasCarpeta/" +
        carpeta.Codigo +
        "/" +
        carpeta.Llave
    );
    console.log("Datos carpet", request.data.data);
    if (Array.isArray(request.data.data)) {
      this.setState({
        listaGestionesCarpeta: request.data.data,
        carpetaSelected: carpeta.Codigo,
        openNavegador: false,
      });
      this.removerTipoGestion();
    }
  };

  busquedaEtiqueta = async () => {
    console.log("Búsqueda etiqueta");
    let textoBusquedaEtiqueta = this.state.textoBusquedaEtiqueta;

    // if (textoBusquedaEtiqueta.length > 0) {
    let filtro = this.state.listaEtiquetas.filter((fila) => {
      return (
        fila.name.toLowerCase().includes(textoBusquedaEtiqueta.toLowerCase()) &&
        (this.state.codigoFamiliaEtiquetaSeleccionada === 0
          ? true
          : fila.codigoFamiliaEtiqueta ===
            this.state.codigoFamiliaEtiquetaSeleccionada)
      );
    });
    this.setState({ listaEtiquetasCopia: filtro });
    // } else {
    //   this.setState({listaEtiquetasCopia:this.state.listaEtiquetas});
    // }
  };

  setSearchEtiqueta = (name) => (event) => {
    this.setState({ textoBusquedaEtiqueta: event.target.value }, () => {
      this.busquedaEtiqueta();
    });
  };

  asignarEtiqueta = async (codigoEtiqueta) => {
    let etiquetas = this.state.etiquetas;
    let listaEtiquetasAsignadas = this.state.listaEtiquetasAsignadas;
    let listaEtiquetas = this.state.listaEtiquetas;

    //Verifica si la etiqueta ya existe
    let existente = etiquetas.filter((el) => {
      return el === codigoEtiqueta;
    });

    if (existente.length > 0) {
      toast.info("Se agregó la etiqueta");
    } else {
      //agrega el código de etiqueta seleccionada al array
      etiquetas.push(codigoEtiqueta);

      //busca las etiqueta seleccionada dentro de la lista de etiquetas y le hace push
      let etiquetaObject = listaEtiquetas.filter((el) => {
        return el.id === codigoEtiqueta;
      });
      if (etiquetaObject.length > 0) {
        listaEtiquetasAsignadas = [
          ...listaEtiquetasAsignadas,
          etiquetaObject[0],
        ];
        this.setState({ etiquetas, listaEtiquetasAsignadas });
      }
    }
  };

  eliminarEtiquetaAsignada = async (id) => {
    let etiquetas = this.state.etiquetas;
    let listaEtiquetasAsignadas = this.state.listaEtiquetasAsignadas;

    //Crea nuevo array sin el item que se va a remover
    let etiquetasNuevo = etiquetas.filter((element) => element !== id);
    let listaEtiquetasAsignadasNuevo = listaEtiquetasAsignadas.filter((obj) => {
      return obj.id !== id;
    });

    this.setState({
      etiquetas: etiquetasNuevo,
      listaEtiquetasAsignadas: listaEtiquetasAsignadasNuevo,
    });
  };

  eliminarProblemaSeleccionado = async (id) => {
    let lista = this.state.listaGestionesCarpeta;
    const result = lista.filter((el) => el.idProblema != id);
    this.setState({
      listaGestionesCarpeta: result,
    });
  };

  limpiarFiltroGestiones = async () => {
    this.setState({
      codigoTipoGestion: null,
      openNavegador: false,
      objTipoGestionSeleccionado: null,
      listaGestionesCarpeta: [],
      carpetaSelected: 0,
      fechaInicio: null,
      fechaFin: null,
      codigoGestion: "",
      listaTipoGestion: [],
      listaGestiones: [],
      tipoGestionActual: null,
      tipoGestion: null,
    });
  };

  cargarListaTipoGestion = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    }
    const request = await API.fetchGetRequest(
      `api/aviso/listaTipoGestionReporteInocuidad`
    );

    if (request.status === 401) {
      //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
      if (await signOut()) {
        window.location.url = "/Login";
      }
      return false;
    } else {
      //El servidor nos devolvio que todo ok.
      if (Array.isArray(request.data.data)) {
        this.setState({ listaTipoGestion: request.data.data });
      }
    }
  };

  buscar = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    }

    try {
      //Valida que se haya elegido un tipo de gestión
      if (
        this.state.tipoGestion === 0 ||
        this.state.tipoGestion === null ||
        this.state.tipoGestion === undefined
      ) {
        toast.warn("Por favor elige un tipo de gestión");
        return;
      }

      this.setState({
        buscando: true,
        listaGestiones: [],
        grupoGestionesResult: [],
        detalleActivo: "gestiones",
        openToolbar: false,
      });

      let params = {};
      params = {
        fechaInicio:
          this.state.fechaInicio === null ? "" : this.state.fechaInicio,
        fechaFin: this.state.fechaFin === null ? "" : this.state.fechaFin,
        codigoTipoGestion: this.state.tipoGestion,
        codigoGestion: this.state.codigoGestion,
      };
      console.log("Parametros", params);
      let uri = "api/aviso/reporteInocuidad?";

      console.log("Parametros", params);
      const request = await API.getWithParamsNoQuestionSymbol(uri, params);

      if (request.status === 401) {
        //El servidor nos devolvió que no estamos autorizados (no hay login correcto)
        if (await signOut()) {
          window.location.url = "/Login";
        }
        return false;
      } else {
        //El servidor nos devolvio que todo ok.
        if (Array.isArray(request.data.data)) {
          this.setState({
            listaGestiones: request.data.data,
          });
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ buscando: false });
    }
  };

  limpiar = () => {
    this.setState({
      fechaInicio: "",
      fechaFin: "",
      codigoGestion: "",
      listaGestiones: [],
    });
  };

  handleChange = (name) => (event) => {
    if (
      name == "pendientes" ||
      name == "terminados" ||
      name == "slaVencido" ||
      name == "slaVigente"
    ) {
      this.setState({ [name]: event.target.checked });
    } else {
      this.setState({
        [name]: event.target.value,
      });
    }
  };

  handleChangeCheck = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  handlePopoverOpen = (event, textoPopover) => {
    this.setState({
      anchorEl: event.currentTarget,
      popOverOpen: true,
      textoPopover: textoPopover,
    });
  };

  handlePopoverClose = () => {
    this.setState({ anchorEl: null, popOverOpen: false, textoPopover: "" });
  };

  descargar = async (listaGestiones) => {
    const auth = await isSignedIn();
    try {
      var body = {
        ids: listaGestiones,
      };

      const filename = "Gestiones";
      const baseApi = await API.baseApi();
      axios(
        baseApi + "api/aviso/reporte/descargar/porCodigos",
        {
          method: "POST",
          data: body,
          responseType: "blob", // important
          headers: { Authorization: "bearer " + auth.accessToken },
        },
        {
          headers: { Authorization: "bearer " + auth.accessToken },
        }
      ).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        // works in IE11
        if (typeof window.navigator.msSaveBlob === "function") {
          window.navigator.msSaveBlob(response.data, `${filename}.xlsx`);
        } else {
          link.setAttribute("download", `${filename}.xlsx`);
          document.body.appendChild(link);
          link.click();
        }
      });
    } catch (e) {}
  };

  handleDrawerOpen = async () => {
    this.setState({ openToolbar: true });
  };

  handleDrawerClose = () => {
    this.setState({ openToolbar: false });
  };

  toggleConfigurationDrawer = (side, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    this.setState({ [side]: open });
  };

  redireccionar = (codigoGestion) => {
    this.props.history.push(`/gestion/${codigoGestion}`);
    //window.location.href="/gestion/" + codigoGestion;
  };

  render() {
    const { classes } = this.props;

    return (
      <Layout titulo={"Reporte de Inocuidad"} maxWidth="xl">
        {/* <Container maxWidth={"xl"} disableGutters={true} > */}
        <div style={{ width: "100%", maxWidth: "100% !important" }}>
          <Paper style={{ width: "100%", maxWidth: "100% !important" }}>
            <AppBar
              position="static"
              color="default"
              style={{ width: "100% !important" }}
            ></AppBar>
            <div
              style={{ width: "100% !important" }}
              // axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              // index={this.state.valorTab}
              // onChangeIndex={this.handleChangeIndexTabs}
            >
              <Grid container spacing={3} style={{ width: "100% !important" }}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <div
                    className={classes.root}
                    style={{ width: "100% !important" }}
                  >
                    <CssBaseline />
                    <AppBar
                      position="sticky"
                      className={clsx(classes.appBar, {
                        [classes.appBarShift]: this.state.openToolbar,
                      })}
                      style={{ backgroundColor: titleColor }}
                    >
                      <Toolbar>
                        <Typography
                          variant="h6"
                          noWrap
                          style={{ display: "flex", flexGrow: 1 }}
                        >
                          {`Resultado de la búsqueda`}
                        </Typography>

                        <Tooltip
                          title={"Abrir panel de búsqueda"}
                          aria-label="Abrir panel de búsqueda"
                        >
                          <IconButton
                            aria-label="Abrir panel de búsqueda"
                            style={{ color: "white" }}
                          >
                            <SearchIcon onClick={this.handleDrawerOpen} />
                          </IconButton>
                        </Tooltip>

                        {/* <Tooltip
                          title={
                            this.state.visualizationType === "list"
                              ? "Cambiar a vista de lista"
                              : "Cambiar a vista de tabla"
                          }
                          aria-label="cambiar vista"
                        >
                          <IconButton
                            aria-label="Cambiar vista"
                            style={{ color: "white" }}
                          >
                            {this.state.visualizationType === "list" ? (
                              <ViewListIcon
                                onClick={() => {
                                  this.setState({
                                    visualizationType: "detail",
                                  });
                                }}
                              />
                            ) : (
                              <ViewModuleIcon
                                onClick={() => {
                                  this.setState({ visualizationType: "list" });
                                }}
                              />
                            )}
                          </IconButton>
                        </Tooltip> */}
                      </Toolbar>
                    </AppBar>
                    <Drawer
                      className={classes.drawer}
                      variant="temporary"
                      anchor="right"
                      open={this.state.openToolbar}
                      onClose={this.toggleConfigurationDrawer(
                        "openToolbar",
                        false
                      )}
                      classes={{
                        paper: classes.drawerPaper,
                      }}
                    >
                      <div
                        className={classes.drawerHeader}
                        style={{ backgroundColor: titleColor, color: "#fff" }}
                      >
                        <IconButton
                          // onClick={this.handleDrawerClose}
                          onClick={this.toggleConfigurationDrawer(
                            "openToolbar",
                            false
                          )}
                        >
                          {this.state.openToolbar === true ? (
                            <CloseIcon style={{ color: "#fff" }} />
                          ) : (
                            <ChevronRightIcon />
                          )}
                        </IconButton>
                        <strong>Filtros</strong>
                      </div>
                      <Divider />

                      <div style={{ padding: 20, width: 400, maxWidth: 400 }}>
                        {/* <PerfectScrollbar
                          style={{
                            width: "100%",
                            height: 375,
                            maxHeight: 375,
                          }}
                        > */}
                        <div>
                          <Grid
                            container
                            spacing={1}
                            style={{ margin: "0 !important" }}
                          >
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <Autocomplete
                                className={classes.textField}
                                style={{
                                  width: "100%",
                                  marginLeft: 10,
                                  marginTop: 25,
                                }}
                                options={this.state.listaTipoGestion}
                                autoHighlight
                                getOptionLabel={(option) => option.Nombre}
                                // inputValue={
                                //   Array.isArray(this.state.usuarioActual)
                                //     ? this.state.usuarioActual[0].NombreCompletoUsuario
                                //     : ""
                                // }
                                disabled={false}
                                renderOption={(option) => (
                                  <React.Fragment>
                                    <span></span>
                                    {option.Nombre}
                                  </React.Fragment>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Tipo de gestión"
                                    variant="outlined"
                                  />
                                )}
                                value={this.state.tipoGestionActual}
                                onChange={(event, newInputValue) => {
                                  this.setState({
                                    tipoGestionActual: newInputValue
                                      ? newInputValue
                                      : -1,
                                    tipoGestion: parseInt(
                                      newInputValue
                                        ? newInputValue.CodigoProblema
                                        : 0
                                    ),
                                  });
                                }}
                                // onChange={(event, newValue) => {
                                //   this.setState({
                                //     usuarioActual: newValue ? newValue : -1,
                                //     codigoUsuarioAsignado:  newValue? newValue.CodigoUsuario:0,
                                //   });
                                // }}
                              />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <TextField
                                id="fechaInicio"
                                label="Fecha inicio"
                                type="date"
                                //defaultValue={new Date()-10}
                                style={{ width: "100%" }}
                                value={this.state.fechaInicio}
                                onChange={this.handleChange("fechaInicio")}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <TextField
                                id="fechaFin"
                                label="Fecha fin"
                                type="date"
                                //defaultValue={new Date()}
                                style={{ width: "100%" }}
                                value={this.state.fechaFin}
                                onChange={this.handleChange("fechaFin")}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Grid>
                          </Grid>

                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                              id="txtCodigoGestion"
                              label={"Código de gestión"}
                              type="number"
                              // variant="outlined"
                              className={classes.textField}
                              value={this.state.codigoGestion}
                              onChange={this.handleChange("codigoGestion")}
                              margin="normal"
                              inputProps={{ maxLength: 5 }}
                              style={{ width: "100%" }}
                            />
                          </Grid>
                          {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                              <div>
                                <div
                                  style={{
                                    display: "flex",
                                    //justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <Typography variant="h6">
                                    Etiquetas
                                  </Typography>
                                  <IconButton
                                    onClick={() => {
                                      this.setState({
                                        openModalEtiquetas: true,
                                      });
                                    }}
                                  >
                                    <AddIcon color="primary" />
                                  </IconButton>
                                </div>
                                <div>
                                  {this.state.listaEtiquetasAsignadas.map(
                                    (element, i) => (
                                      <Chip
                                        key={"tag" + element.id}
                                        style={{ margin: 5 }}
                                        icon={<LocalOfferIcon />}
                                        size="small"
                                        label={element.name}
                                        color="Primary"
                                        // variant="outlined"
                                        onDelete={() => {
                                          this.eliminarEtiquetaAsignada(
                                            element.id
                                          );
                                        }}
                                      />
                                    )
                                  )}
                                </div>
                              </div>
                              </Grid> */}
                        </div>
                        {/* </PerfectScrollbar> */}
                        <Grid container>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Button
                              variant="contained"
                              style={{
                                backgroundColor: "#69bd4b",
                                marginBottom: 10,
                                marginTop: 10,
                                color: "white",
                                width: "93%",
                              }}
                              className={classes.buttonAdd}
                              onClick={() => {
                                this.buscar();
                              }}
                            >
                              Buscar
                            </Button>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6}>
                            <Button
                              variant="contained"
                              style={{
                                backgroundColor: "#e74c3c",
                                marginBottom: 10,
                                marginTop: 10,
                                color: "white",
                                width: "93%",
                              }}
                              className={classes.buttonAdd}
                              onClick={() => {
                                this.limpiar();
                              }}
                            >
                              Limpiar
                            </Button>
                          </Grid>
                        </Grid>
                        <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />
                      </div>
                    </Drawer>
                    <main
                      className={clsx(classes.content, {
                        [classes.contentShift]: this.state.openToolbar,
                      })}
                      style={{ minHeight: 400 }}
                    >
                      <div className={classes.drawerHeader} />
                      <Grid item xs={12} lg={12} sm={12}>
                        {/* AQUI EL CONTENIDO PRINCIPAL DE RESULTADOS */}
                        {/* <PerfectScrollbar
                    style={{
                      width: "100%",
                      height: 375,
                      maxHeight: 375,
                    }}
                  > */}
                        {this.state.buscando ? (
                          <div
                            style={{
                              width: "100%",
                              marginTop: 10,
                              textAlign: "center",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Loader
                              type="Oval"
                              color="#3498db"
                              height="50"
                              width="50"
                              style={{ textAlign: "center" }}
                            />
                          </div>
                        ) : (
                          <></>
                        )}

                        {/* VISTA DE TABLA */}
                        {this.state.listaGestiones.length > 0 && (
                          <DevExpressDataGridInocuidad
                            filas={this.state.listaGestiones}
                          />
                        )}
                        {this.state.listaGestiones.length < 1 &&
                          !this.state.buscando && (
                            <div
                              style={{
                                fontStyle: "italic",
                                color: "#b2bec3",
                              }}
                            >
                              No hay resultados para mostrar.
                            </div>
                          )}
                        {/* </PerfectScrollbar> */}
                      </Grid>
                    </main>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Paper>
        </div>
        {/* </Container> */}

        {/* SUPERFILTRO */}
        {/*  */}
        {/* <GenericButtonFloat onClick={this.handleDrawerOpen}>
          <SearchIcon />
        </GenericButtonFloat> */}
        <ToastContainer />
        <Popover
          id="mouse-over-popover"
          className={classes.popover}
          classes={{
            paper: classes.paperPopover,
          }}
          open={this.state.popOverOpen}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          onClose={this.handlePopoverClose}
          disableRestoreFocus
        >
          <Typography>{this.state.textoPopover}</Typography>
        </Popover>

        <Modal
          // style={{ zIndex: 2990 }}
          aria-labelledby="Asignar etiquetas"
          aria-describedby="Asignar etiquetas"
          open={this.state.openModalEtiquetas}
          onClose={() => {
            this.setState({ openModalEtiquetas: false });
          }}
        >
          <div style={getModalStyle()} className={classes.paperUserModal}>
            <PerfectScrollbar style={{ paddingRight: 30 }}>
              <form className={classes.container} noValidate autoComplete="off">
                <Typography variant="h6" gutterBottom>
                  Agregar Etiquetas
                </Typography>

                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Autocomplete
                      className={classes.textField}
                      style={{
                        width: "100%",
                        marginLeft: 10,
                        marginTop: 25,
                      }}
                      options={this.state.listaFamiliasEtiquetas}
                      autoHighlight
                      getOptionLabel={(option) => option.Nombre}
                      disabled={false}
                      renderOption={(option) => (
                        <React.Fragment>
                          <span></span>
                          {option.Nombre}
                        </React.Fragment>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Familia de etiquetas"
                          // variant="outlined"
                        />
                      )}
                      value={this.state.objFamilia}
                      onChange={(event, newInputValue) => {
                        this.setState(
                          {
                            objFamilia: newInputValue ? newInputValue : -1,
                            codigoFamiliaEtiquetaSeleccionada: parseInt(
                              newInputValue
                                ? newInputValue.CodigoFamiliaEtiqueta
                                : 0
                            ),
                          },
                          () => {
                            this.busquedaEtiqueta();
                          }
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextField
                      id="txtTextoBusquedaTag"
                      label="Tag"
                      className={classes.textField}
                      value={this.state.textoBusquedaEtiqueta}
                      onChange={this.setSearchEtiqueta()}
                      margin="normal"
                      inputProps={{ maxLength: 512 }}
                      style={{ width: "100%" }}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} lg={12} sm={12}>
                  <Grid container spacing={1}>
                    <TagList
                      registros={this.state.listaEtiquetasCopia}
                      agregarCausas={this.asignarEtiqueta}
                      closeModal={() => {
                        this.setState({ openModalEtiquetas: false });
                      }}
                    />
                  </Grid>
                </Grid>
              </form>
            </PerfectScrollbar>
          </div>
        </Modal>

        <Modal
          // style={{ zIndex: 2990 }}
          aria-labelledby="Elegir tipo de gestión"
          aria-describedby="Elegir tipo de gestión"
          open={this.state.openNavegador}
          onClose={() => {
            this.setState({ openNavegador: false });
          }}
        >
          <div
            style={getModalStyle()}
            className={classes.secondNavigationModal}
          >
            {/* <PerfectScrollbar> */}
            <form className={classes.container} noValidate autoComplete="off">
              <Typography variant="h6" gutterBottom>
                Elegir tipo de gestión
              </Typography>

              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <SecondNavigation
                    elegirTipoGestion={this.elegirTipoGestion}
                    listarProblemasCarpeta={this.listarProblemasCarpeta}
                  />
                </Grid>
              </Grid>
            </form>
            {/* </PerfectScrollbar> */}
          </div>
        </Modal>
      </Layout>
    );
  }
}
const ReporteInocuidadContainer = withStyles(styles)(ReporteInocuidad);
export default withRouter(ReporteInocuidadContainer);
