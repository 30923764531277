import React, { useState, useEffect } from "react";
// import Carousel from "react-spring-3d-carousel";
import { makeStyles } from "@material-ui/core";
import CardNoticia from "./card-noticia";
import VerticalCarousel from "./vertical-carousel";
import { config } from "react-spring";
import { v4 as uuidv4 } from "uuid";
import MiCarrusel from "../components/mi-carrusel";

const useStyles = makeStyles((theme) => ({
  filaContainer: {
    width: "100%",
    display: "flex",
    marginTop: "0.5rem",
    flexDirection: "column",
    marginTop: "1rem",
    padding: "0 1rem",
    paddingTop: "2.5rem",
  },
}));

const FeedNoticias = ({ data, goNotificaciones }) => {
  const classes = useStyles();
  const [slides, setSlides] = useState([]);
  const [slide, setslide] = useState(0);

  useEffect(() => {
    if (data && data.length) {
      const arr = [];
      data.forEach((item, index) => {
        arr.push({
          key: uuidv4(),
          content: (
            <CardNoticia
              id={item.CodigoNotificacion}
              titulo={item.Titulo}
              descripcion={item.DescripcionCorta}
              foto={item.NotificationImage}
              fecha={item.FechaEnvio}
              onClick={goNotificaciones}
            />
          ),
        });
      });
      setSlides(arr);
    }
  }, [data]);

  return (
    <div>
      <MiCarrusel cardProp={slides} />
    </div>
  );
};

export default FeedNoticias;
