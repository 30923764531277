import {
  AppBar,
  Backdrop,
  CircularProgress,
  fade,
  FormControl,
  Grid,
  Paper,
  TextField,
  Toolbar,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";

import DataGrid, {
  Column,
  Pager,
  Paging,
  Sorting,
  ColumnFixing,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Grouping,
  GroupPanel,
  Export,
  Selection,
  Editing,
  Scrolling,
} from "devextreme-react/data-grid";
import { useHistory } from "react-router-dom";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState, useEffect, useRef } from "react";
import { toast, ToastContainer } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { isSignedIn } from "../../utils/auth";
import API from "../../utils/api";
import { DropzoneArea } from "material-ui-dropzone";
import { AttachFile, TableChart } from "@material-ui/icons/AttachFile";
import XLSX from "xlsx";
import { validaPermisoPaginaActual } from "../../utils/helpers";

const width_proportion = "100%";
const titleColor = "#2B3C4D";

const useStyles = makeStyles((theme) => ({
  formControlSelects: {
    // margin: theme.spacing(1),
    // minWidth: 230,

    marginBottom: 10,
    marginTop: 10,
    width: "100%",
    marginLeft: 30,
  },
  formControlCheck: {
    // margin: theme.spacing(1),
    // minWidth: 230,

    marginBottom: 10,
    marginTop: 10,
    // width: "100%",
    marginLeft: 30,
  },
  rootTabla: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: "100%",
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
  paperUserModal: {
    position: "absolute",
    width: "50%",
    height: "200px",
    maxHeight: "200px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 2,
    outline: "none",
  },
  buttonAdd: {
    margin: theme.spacing.unit,
  },
  popover: {
    pointerEvents: "none",
  },
  paperPopover: {
    padding: theme.spacing(1),
    background: "#000",
    color: "#fff",
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
  item: {
    paddingLeft: 3 * theme.spacing.unit,
  },
  group: {
    fontWeight: theme.typography.fontWeightMedium,
    opacity: 1,
  },
  inputField: {
    textAlign: "start",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    alignItems: "center",
  },
  previewChip: {
    minWidth: 160,
    maxWidth: 210,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const CrearMantenimientoPreventivo = () => {
  const classes = useStyles();
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors: fieldsErrors },
    register,
  } = useForm();
  const history = useHistory();
  let applyFilterTypes = [
    {
      key: "auto",
      name: "Immediately",
    },
    {
      key: "onClick",
      name: "On Button Click",
    },
  ];

  const [showFilterRow, setShowFilterRow] = useState(true);
  const [showHeaderFilter, setShowHeaderFilter] = useState(true);
  const [currentFilter, setCurrentFilter] = useState(applyFilterTypes[0].key);
  const [autoExpandAll, setAutoExpandAll] = useState(true);

  const [titulo, setTitulo] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [esCargaConCodigo, setEsCargaConCodigo] = useState(false);
  const [buscando, setBackDrop] = useState(false);
  const [archivo, setArchivo] = useState(null);
  const [excelCargado, setExcelCargado] = useState(false);
  const [excelValidado, setExcelValidado] = useState([]);
  const [userName, setUserName] = useState("");

  const scrollRef = useRef(null);

  /* whenever the list changes we need to scroll our
     last list item into view */
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behaviour: "smooth" });
    }
  }, [excelCargado]);

  const handleCerrarBackDrop = () => {
    setBackDrop(false);
  };
  const handleAbrirBackDrop = () => {
    setBackDrop(true);
  };

  const cuandoHayError = async (data) => {
    toast.info(
      "Existen algunos campos con error, por favor valida el formulario",
      {
        position: "top-right",
        autoClose: 3000, // Esta en milisegundos 3 segundos
      }
    );
  };

  // useEffect(() => {
  //   const cargar = async () => {
  //     // CONSULTA PERMISOS POR ROL, PARA PERMANECER EN LA PÁGINA
  //     const llaveAgrupacion = "Menu.Principal.Web";
  //     const llavePagina = "Menu.Principal.Web.MantenimientoPreventivo";
  //     await validaPermisoPaginaActual(llaveAgrupacion, llavePagina);
  //     // FIN DE CONSULTA DE PERMISOS

  //     handleAbrirBackDrop();

  //     const session = await isSignedIn();
  //   if (!session.response) {
  //     window.location.href = "/login";
  //     return;
  //   } else {
  //     setUserName(session["userName"]);
  //   }

  //     handleCerrarBackDrop();
  //   };

  //   cargar();
  // }, []);

  const guardarDato = async () => {
    const session = await isSignedIn();
    if (!session.response) {
      window.location.href = "/login";
      return;
    } else {
      const data = {
        Titulo: titulo,
        Descripcion: descripcion,
        ArchivoExcel: archivo,
        ExcelValidado: excelValidado,
        CodigoSolicitante: session["codigoUsuario"],
        CodigoUsuarioAfectado: session["codigoUsuario"],
        // NumeroVersion: numeroVersion,
        // FechaCreacion: fechaCreacion,
        // Activo: true,
        // Eliminado: false,
        UsuarioInserto: session["userName"],
        // FechaInserto: "",
        // UsuarioModifico: "",
        // FechaModifico: "",
      };

      console.log(data);
      // dataFicha.usuarioInserto = "prueba";
      try {
        handleAbrirBackDrop();
        let vIsSignedIn = await isSignedIn();
        if (vIsSignedIn.response) {
          let request;

          request = await API.post("api/MantenimientoPreventivo/", data);

          if (request.status !== 200) {
            toast.error(
              "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
            );
          } else {
            console.log(request);
            if (!request.data.response) {
              toast.error("Error. Contacte a TI. " + request.data.mensaje);
            } else {
              console.log(request.data.data);

              toast.info("Se crea la gestión");
              setTimeout(() => {
                history.push("/superfiltro/304");
              }, 3000);
            }
          }
        }
      } catch (e) {
        console.log("EXCEPTION: " + e);
        toast.error(
          "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
        );
      } finally {
        handleCerrarBackDrop();
      }
    }
  };

  const handleChangeFiles = (campo) => {
    console.log("handleExcelDrop");
    console.log(campo);
    setArchivo(campo);
  };

  const handleChangeCheck = (event) => {
    setEsCargaConCodigo(event.target.checked);
  };
  const cargarExcel = async () => {
    try {
      // https://stackoverflow.com/questions/63495018/convert-excel-to-json-using-react-dropzone-client-side-probably
      handleAbrirBackDrop();
      setExcelCargado(false);
      // setFileNames(acceptedFiles.map(file => file.name));
      archivo.forEach((file) => {
        console.log("handleExcelDrop:forEach(file)");
        // See https://stackoverflow.com/questions/30859901/parse-xlsx-with-node-and-create-json
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString; // !! converts object to boolean
        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = async (e) => {
          // Do what you want with the file contents
          var bstr = e.target.result;
          var workbook = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
          var sheet_name_list = workbook.SheetNames[0];
          var jsonFromExcel = XLSX.utils.sheet_to_json(
            workbook.Sheets[sheet_name_list],
            {
              raw: false,
              dateNF: "DD-MM-YYYY",
              // header:"A",
              defval: "",
            }
          );
          console.log("jsonFromExcel object=");
          console.log(jsonFromExcel);

          let vIsSignedIn = await isSignedIn();
          if (vIsSignedIn.response) {
            let request;
            const data = {
              EsCargaConCodigo: esCargaConCodigo,
              JsonFromExcel: jsonFromExcel,
            };

            request = await API.post(
              "api/MantenimientoPreventivo/ValidacionExcel",
              data
            );
            if (request.status !== 200) {
              toast.error(
                "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
              );
            } else {
              if (!request.data.response) {
                toast.error("Error. Contacte a TI. " + request.data.mensaje);
              } else {
                console.log(request.data.data);
                setExcelValidado(request.data.data);
                setExcelCargado(true);
                toast.success("Se cargo exitosamente");
              }
            }
          }
        };
        if (rABS) reader.readAsBinaryString(file);
        else reader.readAsArrayBuffer(file);
      });
    } catch (error) {
      console.log("EXCEPTION: " + error);
      setExcelCargado(false);
      toast.error(
        "Hubo un error al realizar la operación, por favor revisa los datos de tu formulario o consulta con un administrador"
      );
    } finally {
      handleCerrarBackDrop();
    }
  };
  const handlePreviewIcon = (fileObject, classes) => {
    const { type } = fileObject.file;
    const iconProps = {
      className: classes.image,
    };
    switch (type) {
      case "application/msexcel":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return <TableChart {...iconProps} />;
      default:
        return <AttachFile {...iconProps} />;
    }
  };

  return (
    <React.Fragment>
      <Backdrop className={classes.backdrop} open={buscando}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer />

      <AppBar
        position="sticky"
        className={classes.appBar}
        style={{ backgroundColor: titleColor }}
      >
        <Toolbar>
          <Typography
            variant="h6"
            noWrap
            style={{ display: "flex", flexGrow: 1 }}
          >
            {`Carga Masiva de Planificación`}
          </Typography>
        </Toolbar>{" "}
      </AppBar>
      <Paper className={classes.rootTabla} spacing={2}>
        <Grid container spacing={3}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit(cargarExcel, cuandoHayError)}
          >
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControl className={classes.formControlSelects}>
                <Controller
                  name="titulo"
                  render={({ Titulo }) => (
                    <TextField
                      id="titulo"
                      label="Título"
                      name="titulo"
                      value={titulo}
                      onChange={(e) => setTitulo(e.target.value)}
                      variant="outlined"
                      helperText={
                        fieldsErrors.titulo ? fieldsErrors.titulo.message : ""
                      }
                    />
                  )}
                  control={control}
                />
              </FormControl>
              <FormControl className={classes.formControlSelects}>
                <Controller
                  name="descripcion"
                  render={({ Descripcion }) => (
                    <TextField
                      id="descripcion"
                      label="Descripción"
                      name="descripcion"
                      value={descripcion}
                      onChange={(e) => setDescripcion(e.target.value)}
                      variant="outlined"
                      helperText={
                        fieldsErrors.descripcion
                          ? fieldsErrors.descripcion.message
                          : ""
                      }
                      multiline
                      rows="3"
                    />
                  )}
                  control={control}
                />
              </FormControl>
              <FormControl
                className={classes.formControlSelects}
                required={true}
              >
                <label>Carga de Archivo</label>
                <DropzoneArea
                  dropzoneText={`Cargue un archivo de excel`}
                  onChange={handleChangeFiles}
                  maxFileSize={1073741824}
                  acceptedFiles={[".xls", ".xlsx"]}
                  filesLimit={1}
                  getPreviewIcon={handlePreviewIcon}
                  showPreviews={true}
                  showPreviewsInDropzone={false}
                  useChipsForPreview
                  previewGridProps={{
                    container: { spacing: 1, direction: "row" },
                  }}
                  previewChipProps={{ classes: { root: classes.previewChip } }}
                  previewText="Archivo seleccionado"
                />
              </FormControl>
              <FormControlLabel className={classes.formControlCheck}
                control={
                  <Checkbox
                    checked={esCargaConCodigo}
                    onChange={handleChangeCheck}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="Cargar con código"
              />
              <Button
                width="100%"
                variant="contained"
                style={{
                  backgroundColor: "#69bd4b",
                  marginBottom: 10,
                  marginTop: 10,
                  color: "white",
                  width: "93%",
                  marginLeft: 30,
                }}
                type="submit"
              >
                &nbsp;Cargar Excel
              </Button>
            </Grid>
          </form>
        </Grid>
        {excelCargado && (
          <Paper className={classes.rootTabla}>
            <DataGrid
              dataSource={excelValidado}
              //   defaultColumns={this.props.columns}
              showBorders={true}
              rowAlternationEnabled={true}
              //ref={(ref) => (dataGrid = ref)}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              //onExporting={onExporting}
              className={classes.table}
            >
              <FilterRow visible={showFilterRow} applyFilter={currentFilter} />
              <HeaderFilter visible={showHeaderFilter} />
              <GroupPanel visible={false} />
              <Grouping autoExpandAll={autoExpandAll} />
              <SearchPanel
                visible={false}
                width={240}
                placeholder="Search..."
              />
              {/* <Selection mode="multiple" /> */}
              <Editing
                mode="row"
                useIcons={true}
                allowUpdating={false}
                allowDeleting={false}
              />
              {/* <Column
              caption="Opciones"
              allowFiltering={false}
              allowSorting={false}
              cellRender={cellOpciones}
            /> */}
              <Column
                dataField="TieneError"
                caption="Tiene Error"
                dataType="boolean"
              />
              <Column dataField="Comentario" caption="Comentario" />
              <Column
                dataField="CodigoEquipo"
                caption="Código Equipo DB"
                dataType="number"
                visible={false}
              />

              <Column dataField="Nombre" caption="Nombre de Equipo" />
              <Column
                dataField="NombreExcel"
                caption="Nombre de Equipo en Archivo"
              />
              <Column dataField="Usuario" caption="Usuario" />
              <Column dataField="NombreUsuario" caption="Responsable" />
              <Column
                dataField="FechaVencimiento"
                caption="Fecha de Vencimiento"
                dataType="date"
                format="dd/MM/yyyy"
              />

              <ColumnFixing enabled={true} />
              <Sorting mode="multiple" />
              <Paging defaultPageSize={20} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[10, 20, 50]}
                showInfo={true}
              />
              <Export enabled={true} allowExportSelectedData={true} />
              <Scrolling columnRenderingMode="virtual" />
            </DataGrid>
            <Grid container spacing={3}>
              <form
                autoComplete="off"
                onSubmit={handleSubmit(guardarDato, cuandoHayError)}
              >
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Button
                    width="100%"
                    variant="contained"
                    style={{
                      backgroundColor: "#69bd4b",
                      marginBottom: 10,
                      marginTop: 10,
                      color: "white",
                      width: "93%",
                      marginLeft: 30,
                    }}
                    type="submit"
                  >
                    &nbsp;Crear gestión
                  </Button>
                </Grid>
              </form>
            </Grid>
          </Paper>
        )}
      </Paper>
    </React.Fragment>
  );
};

export default CrearMantenimientoPreventivo;
